
import { blogList, blog, checkList } from "../../endpoints";
import HttpService from "../../http";
import { IBlogResponse, IBlogResponses, ICheckListResponse } from "../../../interfaces";
import { IBlogSummaryResponse } from "../../../interfaces/content-marketing/blog/score";
import { IBlogSampleResponse } from "../../../interfaces/content-marketing/blog/import";
const blogService = () => {

    const { httpRequest } = HttpService();
    const getBlogList = async (search: object): Promise<IBlogResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogResponses>(
                "GET",
                `${blogList}`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addBlog = async (payload: object): Promise<IBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogResponse>(
            "POST",
            `${blog}`,
            payload)
            .then(resolve)
            .catch(reject);
    });



    const getBlog = async (query: object): Promise<IBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogResponse>(
            "GET",
            `${blog}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteBlog = async (payload: object): Promise<IBlogResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogResponse>("DELETE", `${blog}`, payload)
                .then(resolve)
                .catch(reject);
        });

    const updateBlog = async (payload: object): Promise<IBlogResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogResponse>("PUT", `${blog}`, payload)
                .then(resolve)
                .catch(reject);
        });

    const updateBlogStatus = async (payload: object): Promise<IBlogResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogResponse>("PUT", `${blog}/status`, payload)
                .then(resolve)
                .catch(reject);
        });


    const publishBlog = async (payload: object): Promise<IBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogResponse>(
            "POST",
            `${blog}/publish`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const scheduleBlog = async (payload: object): Promise<IBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogResponse>(
            "POST",
            `${blog}/schedule`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getBlogSummary = async (search: object): Promise<IBlogSummaryResponse> =>
    new Promise((resolve, reject) => {
        httpRequest<IBlogSummaryResponse>(
            "GET",
            `${blog}/summary`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCheckListData = async (query: object): Promise<ICheckListResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ICheckListResponse>(
                "GET",
                `${checkList}`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });

    const saveChecks = async (payload: object): Promise<IBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogResponse>(
            "POST",
            "config-cms/check-list-blog-mapping",
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const downloadBlogExcel = async (search: object): Promise<IBlogSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogSampleResponse>(
                "GET",
                `${blog}/export-blogs`,
                search
            )
                .then(resolve)
                .catch(reject);
        });


    return { getBlogList, getBlog, addBlog, updateBlog, deleteBlog, publishBlog, scheduleBlog,getBlogSummary, getCheckListData, saveChecks, updateBlogStatus, downloadBlogExcel};

};

export { blogService };