import React, { SyntheticEvent, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Autocomplete, AutocompleteChangeReason, Box, Chip, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import CustomDialog from "../../../../components/mui/dialog";
import { ICourseConfigurationField, ICourseConfigurationFormFelds, IErrorResponse, IOutletProps, ITeam } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import SearchSelect from "../../../../components/mui/search-select";
import CustomLabel from "../../../../components/mui/custom-label";
import { useSelector } from "react-redux";
import { capitalize } from "../../../../utilities/helper";
import { courseConfigurationService } from "../../../../services/configuration/course";
import { SkillMatrixService } from "../../../../services";
import { courseValidation } from "../../../../validations/configuration/course";

const ManageCourseConfiguration: React.FC = () => {
  const { courseConfigurationId } = useParams<{ courseConfigurationId: string }>();
  const [searchParams] = useSearchParams();
  const { getSkillParamPartial } = SkillMatrixService();
  const { addCourseConfiguration, getcourseConfiguration, updateCourseConfiguration } = courseConfigurationService();
  const outlet = useOutletContext<IOutletProps>();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleKeyDownNonNumeric = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = (event.target as HTMLInputElement).value;
    if (event.key === " " && currentValue.trim().length === 0) {
      event.preventDefault();
    }
  };

  const departmentTypes = useSelector(
    (state: { team: { list: ITeam[] } }) =>
      state.team.list
  );

  const onChangeMultipleSelect = (
    event: SyntheticEvent<Element, Event>,
    value: { name: string; _id: string; }[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedStatus: string[] = [...getValues("_skillParameter")];
    let newSelectedStatus: string[];
    let removedStatus: string[];

    switch (reason) {
      case "selectOption":
        newSelectedStatus = value.map((status) => status._id);
        newSelectedStatus = newSelectedStatus.filter(
          (status) => !updatedSelectedStatus.includes(status)
        );
        updatedSelectedStatus = [
          ...updatedSelectedStatus,
          ...newSelectedStatus,
        ];
        break;
      case "removeOption":
        removedStatus = value.map((status) => status._id);
        updatedSelectedStatus = updatedSelectedStatus.filter(
          (status) => !removedStatus.includes(status)
        );
        break;
    }
    setValue("_skillParameter", updatedSelectedStatus);
    trigger("_skillParameter");
  };

  const handleMultipleDeleteChip = (deleteElement: string) => {
    const updatedStatus = getValues("_skillParameter").filter((status) => status !== deleteElement);
    setValue("_skillParameter", updatedStatus);
  };

  const hitQuery = !(courseConfigurationId === "new" || courseConfigurationId === "view");

  const courseConfiguration = useQuery({
    queryKey: [hitQuery],
    queryFn: () => getcourseConfiguration({ _id: courseConfigurationId }),
    enabled: hitQuery
  });

  const skillParameterRaw = useQuery({
    queryKey: ["skill-param-partial"],
    queryFn: () => getSkillParamPartial(),
  });

  const skillParameter = skillParameterRaw.data?.data;

  const { handleSubmit, control, setValue, formState: { errors }, getValues, trigger } = useForm<ICourseConfigurationField>({
    resolver: joiResolver(courseValidation),
    defaultValues: {
      name: "",
      shortName: "",
      description: "",
      _department: "",
      _skillParameter: []
    }
  });

  useEffect(() => {
    if (courseConfigurationId !== "new") {
      if (courseConfiguration.data?.data) {
        setValue("name", courseConfiguration.data.data.name || "");
        setValue("shortName", courseConfiguration.data.data.shortName || "");
        setValue("description", courseConfiguration.data.data.description || "");
        setValue("_department", courseConfiguration.data.data._department || "");
        setValue("_skillParameter", courseConfiguration.data.data._skillParameter || [].map(data => data) as string[]);
        trigger("_department");
        trigger("_skillParameter");
      }
    }
  }, [courseConfigurationId, courseConfiguration.data, setValue]);

  const onSubmit = async (data: ICourseConfigurationField) => {
    try {
      if (courseConfigurationId === "new") {
        const add = await addCourseConfiguration(data);
        snackbar(add.message, "info");
      } else {
        const update = await updateCourseConfiguration({ _id: courseConfigurationId, ...data });
        snackbar(update.message, "info");
      }
      navigate({
        pathname: "/configurations/course",
        search: searchParams.toString()
      });
      outlet?.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/configurations/course",
      search: searchParams.toString()
    });
  };

  const fields: ICourseConfigurationFormFelds[] = [
    {
      label: "Name",
      name: "name",
      type: "input",
      placeholder: "Type your course name here",
      required: true,
    },
    {
      label: "Short Name",
      name: "shortName",
      type: "input",
      placeholder: "Type your course short name here",
      required: true,
    },
    {
      label: "Department",
      name: "_department",
      type: "searchSelect",
      options: departmentTypes,
      displayFieldKey: "name",
      storeFieldKey: "_id",
      capitalize: true,
      required: true,
    },
    {
      label: "Skill Parameter",
      name: "_skillParameter",
      type: "multipleSearchSelect",
      placeholder: "Search...",
      capitalize: true,
      required: true,
    },
    {
      label: "Description",
      name: "description",
      type: "input",
      placeholder: "Type your description here",
      required: true,
    }
  ];

  return (
    <Box>
      <CustomDialog
        size="md"
        title={courseConfigurationId !== "new" ? "Edit Course" : "Add Course"}
        isOpen={!!courseConfigurationId}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {fields.map(field => {
            if (field.name === "description") {
              return (
                <Grid key={field.label} item xs={12} md={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        inputProps={{ maxLength: 100 }}
                        onKeyDown={handleKeyDownNonNumeric}
                        onChange={onChange}
                        value={value}
                        multiline
                        rows={5}
                      />
                    )}
                  />
                </Grid>);
            } else if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={6} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        inputProps={{ maxLength: 100 }}
                        onKeyDown={handleKeyDownNonNumeric}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>);
            } else if (field.type === "searchSelect") {
              return (
                <Grid key={field.label} item xs={6} md={6}>
                  <SearchSelect
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    options={field.options}
                    displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                    storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                    displayUserName={field.displayUserName}
                    capitalize={field.capitalize}
                    keyUpperCase={field.keyUpperCase}
                    trigger={trigger}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Grid>
              );
            }
            else if (field.type === "multipleSearchSelect") {
              return (
                <Grid key={field.label} item xs={6} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <>
                        <Autocomplete
                          className="disable-text"
                          options={skillParameter || []
                            .map((element) => element)}
                          getOptionLabel={(option) =>
                            capitalize(option.name)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label={<CustomLabel label={field.label} required={field?.required} />}
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                            />
                          )}
                          {...prop.field}
                          value={[]}
                          onChange={onChangeMultipleSelect}
                          multiple
                        />
                        {getValues("_skillParameter").map((element) => (
                          <Chip
                            key={element}
                            label={
                              (skillParameter || []).find(
                                (skill) => skill._id === element
                              )?.name
                            }
                            color="primary"
                            variant="outlined"
                            onDelete={() => handleMultipleDeleteChip(element)}
                            sx={{ margin: "5px" }}
                          />
                        ))}
                      </>
                    )}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};


export default ManageCourseConfiguration;
