import HttpService from "../../http";
import { category, categoryList } from "../../endpoints";
import {
  IContentCategorysResponse,
  IContentCategoryResponse,
} from "../../../interfaces/content/category";

const CategoryService = () => {
  const { httpRequest } = HttpService();
  const getContentCategorys = async (query: object): Promise<IContentCategorysResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IContentCategorysResponse>(
        "GET",
        `${categoryList}`,
        {},
        query
      )
        .then(resolve)
        .catch(reject);
    });

  const addContentCategory = async (
    payload: object
  ): Promise<IContentCategoryResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IContentCategoryResponse>("POST", `${category}`, payload)
        .then(resolve)
        .catch(reject);
    });

  const getContentCategory = async (
    query: object
  ): Promise<IContentCategoryResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IContentCategoryResponse>("GET", `${category}`, {}, query)
        .then(resolve)
        .catch(reject);
    });

    const getContentCategoryPartial = async (
      query: object
    ): Promise<IContentCategoryResponse> =>
      new Promise((resolve, reject) => {
        httpRequest<IContentCategoryResponse>("GET", `${category}/partial`, {}, query)
          .then(resolve)
          .catch(reject);
      });

  const updateContentCategory = async (
    payload: object
  ): Promise<IContentCategoryResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IContentCategoryResponse>("PUT", `${category}`, payload)
        .then(resolve)
        .catch(reject);
    });

  const deleteContentCategory = async (
    payload: object
  ): Promise<IContentCategoryResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IContentCategoryResponse>("DELETE", `${category}`,payload)
        .then(resolve)
        .catch(reject);
    });


    const importCategories = async (payload : object) :Promise<IContentCategoryResponse>=> new Promise((resolve,reject) => {
      httpRequest<IContentCategoryResponse>(
      "POST",
      `${category}/import`,
      payload )
      .then(resolve)
      .catch(reject);
  });

  return {
    getContentCategorys,
    getContentCategoryPartial,
    addContentCategory,
    getContentCategory,
    updateContentCategory,
    deleteContentCategory,
    importCategories
  };
};

export default CategoryService;
