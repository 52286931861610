
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import useResource from "../../../hooks/useResource";
import { ChangeEvent, useEffect, useState, MouseEvent, SetStateAction } from "react";
import { AlternativeComparisonService } from "../../../services/alternative-comparison";
import useSnackbar from "../../../hooks/useSnackbar";
import { IErrorResponse, IPagination, IProjectData } from "../../../interfaces";
import useDebounce from "../../../hooks/useDebounce";
import { useSelector } from "react-redux";
import { Box, Button, Checkbox, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import GetActions from "../../../components/get-actions";
import CustomTypography from "../../../components/mui/max-length-limit";
import { capitalize, createIndex } from "../../../utilities/helper";
import { handleSelect, handleSelectAll } from "../../content-layout/common/helper";
import Header from "../../../components/header";
import BlogFilters from "../../content-marketing-layout/blog/filters";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BulkAssignment from "../bulk-assignment";
import CustomTable from "../../../components/mui/table";
import WarningDialog from "../../../components/mui/warning-dialog";
import { IAlternativeComparison, IAlternativeComparisonState, IAlternativeRow } from "../../../interfaces/alternative-comparison";

const AltetnativeComparison = () => {
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAlternativeComparisons, deleteComparison, updateAlternativeComparisonStatus } = AlternativeComparisonService();
  const { snackbar } = useSnackbar();
  const [state, setState] = useState<IAlternativeComparisonState>({
    selectAll: [],
    deleteWarning: false,
    _alternativeComparison: "",
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    assignmentDialog: {
      anchorEl: null,
      isOpen: false
    },
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      category: []
    },
    filterCount: 0,
    searching: "",
    _deleteId: ""
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
    (state) => state.cmsProduct.list
  ) || [];

  const applyDefaultFilter = () => {
    const selectedFilterProductId = localStorage.getItem("selectedFilterProductId") || "";
    const selectedProduct = productList.find((item) => item._id === selectedFilterProductId);
    const product = [];
  
    if (selectedProduct) {
      product.push({ key: selectedProduct._id, value: selectedProduct.name });
    } else {
      product.push({ key: "", value: "" });
    }
    if(product[0].value !== ""){
      searchParams.set("product", JSON.stringify(product));
    }
  
  };
  
  useEffect(() => {
    applyDefaultFilter();
  }, []);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
    const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
    const quarter: { key: string, value: string }[] = searchParams.get("quarter") ? JSON.parse(String(searchParams.get("quarter"))) : [];
    const date: {  key: string, value: string, startDate: string, endDate: string  }[] = searchParams.get("week") ? JSON.parse(String(searchParams.get("week"))) : [];
    const writers: { key: string, value: string }[] = searchParams.get("writers") ? JSON.parse(String(searchParams.get("writers"))) : [];
    const reviewers: { key: string, value: string }[] = searchParams.get("reviewers") ? JSON.parse(String(searchParams.get("reviewers"))) : [];
    const illustrators: { key: string, value: string }[] = searchParams.get("illustrators") ? JSON.parse(String(searchParams.get("illustrators"))) : [];
    const publishers: { key: string, value: string }[] = searchParams.get("publishers") ? JSON.parse(String(searchParams.get("publishers"))) : [];
    const type: { key: string, value: string }[] = searchParams.get("alternativeType") ? JSON.parse(String(searchParams.get("alternativeType"))) : [];

    filterCount += category.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += product.length ? 1 : 0;
    filterCount += subCategory.length ? 1 : 0;
    filterCount += quarter.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    filterCount += writers.length ? 1 : 0;
    filterCount += reviewers.length ? 1 : 0;
    filterCount += illustrators.length ? 1 : 0;
    filterCount += publishers.length ? 1 : 0;
    filterCount += type.length ? 1 : 0;


    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map(item => item.key),
        status: status.map(item => item.key),
        productId: product.map(item => item.key),
        quarterId: quarter.map(item => item.key),
        subCategoryId: subCategory.map(item => item.key),
        startDate: date[0] && date[0].startDate,
        endDate: date[0] && date[0].endDate,
        writers: writers.map(item => item.key),
        reviewers: reviewers.map(item => item.key),
        illustrators: illustrators.map(item => item.key),
        publishers: publishers.map(item => item.key),
        type: type.map(item => item.key)
      },
      filterCount
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleDelete = (_deleteId = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _deleteId
    }
    ));
  };

  const onDelete = async () => {
    try {
      const response = await deleteComparison({ _id: [state._deleteId] });
      snackbar(response?.message, "info");
      handleDelete();
      setState((prevState) => ({
        ...prevState,
        selectAll: prevState.selectAll.filter((ele) => ele !== state._deleteId),
      }));
      alternativeData.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }

  };
  const handleStatus = async (event: SelectChangeEvent<string>, _id: string) => {
    const { name, value } = event.target;

    try {
      const payload = {
        _id,
        [name]: value
      };
      const response = await updateAlternativeComparisonStatus(payload);
      snackbar(response.message, "info");
      alternativeData.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const createRows = (alternative: IAlternativeComparison, index: number, selectedAll: string[], pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Delete", method: () => handleDelete(alternative._id), disabled: !resourceAllocate("cms-alternative-comparison.remove") },
      ]}
    />;
    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          onChange={e => handleStatus(e, alternative?._id)}
          value={alternative?.status}
          disabled={!resourceAllocate("cms-alternative-comparison-status.edit")}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="DRAFT">Draft</MenuItem>
          <MenuItem value="IN_WRITING">In Writing</MenuItem>
          <MenuItem value="IN_REVIEW">In Review</MenuItem>
          <MenuItem value="REASSIGN">Re-assign</MenuItem>
          <MenuItem value="IN_PUBLISHING">In Publishing</MenuItem>
          <MenuItem value="SCHEDULED">Scheduled</MenuItem>
          <MenuItem value="COMPLETED">Completed</MenuItem>
        </Select>
      </FormControl>;

    const assignments = capitalize(
      (() => {
        switch (assignmentRole) {
          case "writer":
          case "reviewer":
          case "illustrator":
          case "publisher":
            return alternative && alternative._assignment && alternative._assignment[assignmentRole] && alternative._assignment[assignmentRole]._user && alternative._assignment[assignmentRole]._user.name || "";
          default:
            return "";
        }
      })()
    );

    const title = <CustomTypography
        limit={30}
        label={capitalize(alternative && alternative.title || "")}
        onClick={resourceAllocate("cms-alternative-comparison.edit") ? () => navigate({
          pathname: `manage/${alternative._id}`,
          search: resourceAllocate("cms-alternative-comparison-assignment.read") ? "type=basic-detail&"+ searchParams.toString() : resourceAllocate("cms-alternative-comparison-content.read") ? "type=content&"+ searchParams.toString() : resourceAllocate("cms-alternative-comparison-score.read") ? "type=score&"+ searchParams.toString() : ""
        }) : undefined}
        color={resourceAllocate("cms-alternative-comparison.edit") ? "primary" : undefined}
      />;

    return {
      all: <Checkbox onChange={e => handleSelect(e, alternative._id, state, setState)} checked={selectedAll.includes(alternative._id)} />,
      id: createIndex(pagination, index),
      title: title,
      type: capitalize(alternative && alternative.type) || "",
      quarter: alternative && alternative._quarter && alternative._quarter.name || "",
      product: alternative && alternative._product && alternative._product.name || "",
      assignments,
      status,
      action
    };
  };

  const alternativeData = useQuery({
    queryKey: ["alternatives-data", state.pagination.page, state.searching, state.filters, state.assignmentDialog.isOpen],
    queryFn: () => getAlternativeComparisons({
      pagination: true, 
      page: state.pagination.page, 
      limit: state.pagination.limit,
      ...state.filters, 
      populate: ["_quarter", "_product"]
    })
  });

  const onLimitChange = (event: SelectChangeEvent<string>) => { 
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        limit: Number(event.target.value)
      }
    }));
  };

  useEffect(() => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    alternativeData.refetch();
  }, [state.pagination.limit]);

  useEffect(() => {
    if (alternativeData?.data?.data?.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: alternativeData?.data?.meta?.page,
          totalPages: alternativeData?.data?.meta?.totalPages,
          totalRecords: alternativeData?.data?.meta?.totalRecords
        }
      }));
    }
  }, [alternativeData?.data?.meta]);


  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const openAssignmentForm = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    assignmentDialog: {
      ...prevState.assignmentDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.assignmentDialog.isOpen
    }
  }));

  const closeAssignmentForm = () => {
    setState(prevState => ({
      ...prevState,
      assignmentDialog: {
        ...prevState.assignmentDialog,
        isOpen: false
      }
    }));
  };
  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const [assignmentRole, setAssignmentRole] = useState("writer");
  const handleAssignmentRoleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setAssignmentRole(event.target.value);
  };
  const isChecked =  !!(alternativeData?.data?.data.length && (state.selectAll.length === alternativeData?.data?.data.length));
  const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(alternativeData.data?.data.length));
  const columns = [
    {
      id: "all",
      label: <Checkbox onChange={e => handleSelectAll(e, alternativeData?.data?.data ? alternativeData?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />
    },
    {
      id: "title",
      label: "Title"
    },
    {
      id: "quarter",
      label: "Quarter"
    },
    {
      id: "assignments",
      label: (
        <Select
          defaultValue="writer"
          size="small"
          onChange={handleAssignmentRoleChange}
          value={assignmentRole}
        >
          <MenuItem value="writer">Writer</MenuItem>
          <MenuItem value="reviewer">Reviewer</MenuItem>
          <MenuItem value="illustrator">Illustrator</MenuItem>
          <MenuItem value="publisher">Publisher</MenuItem>
        </Select>
      )
    },
    {
      id: "product",
      label: "Product"
    },
    {
      id: "type",
      label: "Type"
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "action",
      label: "Action"
    },
  ];
  let rows: IAlternativeRow[] = [];
  if (alternativeData?.data?.data.length) {
    rows = alternativeData.data.data.map((data, index) => createRows(data, index, state.selectAll, state.pagination));
  }

  return (
    <div id="comparison">

      <Typography variant="h6">All Alternatives/Comparisons</Typography>
      <Typography className="sub-title" variant="caption">Here you can manage all the alternatives/comparisons.</Typography>
      <Header
        className='my-2'
        btnText="Add New Alternative/Comparison"
        onBtnClick={resourceAllocate("cms-alternative-comparison.write") ? () => navigate({ pathname: "manage/new", search: "type=basic-detail" }) : undefined}
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        onImport={resourceAllocate("cms-alternative-comparison.write") ? () => navigate({ pathname: "/comparison/import", search: searchParams.toString() }) : undefined}

      >
        <BlogFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
        {resourceAllocate("cms-alternative-comparison-assignment.edit") && <Button
          style={{ backgroundColor: "rgb(231 231 231)", borderColor: "grey", boxShadow: "none" }}
          disabled={!state.selectAll.length}
          className='ml-2 h-100'
          onClick={openAssignmentForm}
        >
          <Typography variant="body2" style={{ color: state.selectAll.length ? "#000" : "grey" }}>
            Assign To
          </Typography>
          {state.assignmentDialog.isOpen
            ? <ArrowDropUpIcon
              style={{ color: state.selectAll.length ? "#000" : "grey" }} />
            : <ArrowDropDownIcon
              style={{ color: state.selectAll.length ? "#000" : "grey" }} />}
        </Button>}
        <BulkAssignment
          anchorEl={state.assignmentDialog.anchorEl}
          isOpen={state.assignmentDialog.isOpen}
          OnClose={closeAssignmentForm}
          ids={state.selectAll}
          setState={setState}
        />
      </Header>

      <Box display="flex" className="mb-2" justifyContent="space-between">

        <Box>
          {state.selectAll.length ? <Box>
            <Typography className="ml-2" variant="body1">
              {state.selectAll.length > 1 ? `${state.selectAll.length} entries selected`: `${state.selectAll.length} entry selected`}
            </Typography>
          </Box> : null}
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Alternatives/Comparisons:</Typography>
          <Typography className="ml-3" variant="body1" >{alternativeData?.data?.meta?.totalRecords}</Typography>
        </Box>

      </Box>
     
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 290px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
          limitPerPage={true}
          onLimitChange={onLimitChange}
          limitValue={state.pagination.limit.toString()}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Alternative/Comparison"
        description="Are you sure you want to delete this alternative/comparison?"
      />

      <Outlet context={{ ...Outlet, reFetch: alternativeData.refetch }} />
    </div>
  );
};

export default AltetnativeComparison;