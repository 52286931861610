import HttpService from "../http";
import { courseAssignment } from "../endpoints";
import { ISkillDevelopmentResponse, ISkillDevelopmentResponses, ISkillDevelopmentResponseSingle } from "../../interfaces/skill-development";

const skillDevelopmentServices = () => {

    const { httpRequest } = HttpService();
    const getSkillDevelopmentList = async (search: object): Promise<ISkillDevelopmentResponses> => new Promise((resolve, reject) => {
        httpRequest<ISkillDevelopmentResponses>(
            "GET",  
            `${courseAssignment}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });


    const addSkillDevelopment = async (payload: object): Promise<ISkillDevelopmentResponse> => new Promise((resolve, reject) => {
      httpRequest<ISkillDevelopmentResponse>(
          "POST",
          `${courseAssignment}`,
          payload
      )
          .then(resolve)
          .catch(reject);
  });

  const updateSkillDevelopment = async (payload: object): Promise<ISkillDevelopmentResponse> => new Promise((resolve, reject) => {
    httpRequest<ISkillDevelopmentResponse>(
        "PUT",
        `${courseAssignment}`,
        payload,
    )
        .then(resolve)
        .catch(reject);
});  
  const updateSkillDevelopmentStatusScoringCompletion = async (payload: object): Promise<ISkillDevelopmentResponse> => new Promise((resolve, reject) => {
    httpRequest<ISkillDevelopmentResponse>(
        "PUT",
        `${courseAssignment}/update-status-completion-scoring`,
        payload,
    )
        .then(resolve)
        .catch(reject);
});

    const getSkillDevelopment = async (query: object): Promise<ISkillDevelopmentResponseSingle> => new Promise((resolve, reject) => {
        httpRequest<ISkillDevelopmentResponseSingle>(
            "GET",
            `${courseAssignment}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteSkillDevelopment = async (query: object): Promise<ISkillDevelopmentResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillDevelopmentResponse>(
            "DELETE",
            `${courseAssignment}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { getSkillDevelopment, getSkillDevelopmentList, updateSkillDevelopment, addSkillDevelopment, deleteSkillDevelopment, updateSkillDevelopmentStatusScoringCompletion };

};

export { skillDevelopmentServices };