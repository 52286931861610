import { ChangeEvent, useEffect } from "react";
import { IErrorResponse } from "../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  Button,
  Link,
  InputLabel,
} from "@mui/material";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import {
  IAssessmentMedia,
  IAssessmentMediaField,
} from "../../../../interfaces/configuration/assessment-media";
import { AssessmentMediaService } from "../../../../services/configuration/assessment-media";
import { assessmentMediaValidation } from "../../../../validations/configuration/assessment-media";
import HttpService from "../../../../services/http";
import CustomLabel from "../../../../components/mui/custom-label";
interface outletProps {
  reFetch: () => void;
}

const ManageAssessmentMedia = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const { httpFormRequest } = HttpService();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { addAssessmentMedia, getAssessmentMedia, updateAssessmentMedia } =
    AssessmentMediaService();
  const image = useQuery({
    queryKey: [id],
    queryFn: () => getAssessmentMedia({ _id: id }),
    enabled: id !== "new",
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<IAssessmentMedia>({
    resolver: joiResolver(assessmentMediaValidation),
    defaultValues: {
      name: "",
      description: "",
      imageUrl: "",
    },
  });

  useEffect(() => {
    if (id !== "new" && image.data) {
      setValue("name", image.data.data.name);
      setValue("description", image.data.data.description);
      setValue("imageUrl", image.data.data.imageUrl);
      trigger("imageUrl");
    }
  }, [id, image.data]);

  const onSubmit = async (data: IAssessmentMedia) => {
    try {
      if (id === "new") {
        const payload = { ...data };
        const add = await addAssessmentMedia(payload);
        snackbar(add.message, "info");
        navigate({
          pathname: "/assessment/assessments-media",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet.reFetch();
      } else {
        const payload = { ...data, _id: id };
        const update = await updateAssessmentMedia(payload);
        snackbar(update.message, "info");
        navigate({
          pathname: "/assessment/assessments-media",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/assessment/assessments-media",
      search: searchParam.toString(),
    });
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const uploaded = await httpFormRequest<{ data: string }>(
        e.target.files,
        e.target.files ? e.target.files[0].name : "",
        ["png", "jpeg", "jpg", "webp"],
        10
      );
      setValue("imageUrl", uploaded.data.split("uploads")[1]);
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const fields: IAssessmentMediaField[] = [
    {
      label: "Name",
      name: "name",
      type: "input",
      placeholder: "Type name here",
      required: true,
    },
    {
      label: "Description",
      name: "description",
      type: "multiline",
      placeholder: "Type description here",
      required: true,
    },
    {
      label: "Image",
      name: "imageUrl",
      type: "upload",
      placeholder: "Uplaod image",
      required: true,
    },
  ];

  return (
    <Box>
      <CustomDialog
        title={id !== "new" ? "Edit Image" : "New Image"}
        isOpen={!!id}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {fields.map((field) => {
            if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                      label={<CustomLabel required={field.required} label={field.label} />}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "multiline") {
              return (
                <Grid
                  key={field.label}
                  item
                  xs={12}
                  md={6}
                >
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={<CustomLabel required={field.required} label={field.label} />}
                        className="disable-text"
                        variant={"outlined"}
                        size={"small"}
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        multiline
                        minRows={2}
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else {
              const hasValue = getValues(field.name);
              const shouldShowLabel = hasValue;

              return (
                <Grid key={field.label} item xs={12} md={12}>
                  <div>
                    <div
                      style={{
                        color: "rgb(118, 118, 118)",
                        marginBottom: "3px",
                      }}
                    >
                      {shouldShowLabel && <label>{field.label}</label>}
                    </div>
                    <Grid container spacing={2}>
                      {hasValue && (
                        <Grid item xs>
                          <Link
                            href={
                              String(process.env.REACT_APP_S3_BASE_URL) +
                              getValues(field.name)?.toString()
                            }
                            target="_blank"
                            underline="none"
                            color="inherit"
                          >
                            <Button variant="outlined" fullWidth>
                              Preview
                            </Button>
                          </Link>
                        </Grid>
                      )}
                      {!hasValue && (
                        <Grid item xs>
                          <InputLabel id={`upload-${field.name}`}>
                            <Button component="label" fullWidth>
                              Upload {field.label}
                              <input
                                hidden
                                type="file"
                                id={`upload-${field.name}`}
                                onChange={(e) => uploadFile(e)}
                                accept="image/png, image/jpeg, image/webp"
                              />
                            </Button>
                          </InputLabel>
                          {errors[field.name] && <span style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                        </Grid>
                      )}
                      {hasValue && (
                        <Grid item xs>
                          <Button
                            onClick={() => {
                              setValue(field.name, "");
                              trigger(field.name);
                            }}
                            variant="outlined"
                            color="error"
                            fullWidth
                          >
                            Delete
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Grid>
              );
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageAssessmentMedia;
