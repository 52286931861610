import ImportFile from "../../../../../../../../components/mui/import-candidate";
import useSnackbar from "../../../../../../../../hooks/useSnackbar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { ChangeEvent, useState } from "react";
import ImportFileError from "../../../../../../../../components/mui/import-candidate/erros";
import { useOutletContext } from "react-router-dom";
import { IErrorResponse } from "../../../../../../../../interfaces";
import validateTestQuestions, { IImportErrorMessage } from "./validate-import";
import { TestsService } from "../../../../../../../../services/configuration/tests";
interface IOutletProps {
  reFetch: () => void;
}

interface IQuestionData {
  question: string;
  _category: string;
  _test: string;
  options:{
    option: string;
    weightage: number;
  }[];
}

interface IQuestionImport {
  fileName: string;
  data: IQuestionData[];
  showError: boolean;
  errorMessage: IImportErrorMessage[];
}
const ImportTestQuestion = () => {
  const { validateQuestion } = validateTestQuestions();
  const { testId } = useParams() as { testId: string };
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<IOutletProps>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { getTestQuestionSample, importTestQuestions } = TestsService();

  const [state, setState] = useState<IQuestionImport>({
    fileName: "",
    data: [],
    showError: false,
    errorMessage: [],
  });

  const closeImport = () => {
    navigate({ pathname: `/assessment/tests/test-info/manage/${testId}`, search: searchParams.toString() });
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // create a file
    const formData = new FormData();
    // define file type
    const fileType = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    // get selected file
    const selectedFile = event.target.files ? event.target.files[0] : false;

    if (selectedFile && fileType.includes(selectedFile.type)) {
      // appending file to form data to send it to server
      formData.append("file", selectedFile);

      // creating a file Reader
      const reader = new FileReader();
      // read the data as araryBuffer
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = async (e) => {
        if (e.target) {
          // extracting the array of buffer representings the contents of file
          const data = e.target.result;
          // use the XLSX library to read the data and interpret it as an Excel sheet
          const readedData = XLSX.read(data, { type: "binary" });
          // get the 1st sheet name
          const wsname = readedData.SheetNames[0];
          // get the contents of first sheet
          const ws = readedData.Sheets[wsname];
          // convert the sheet data to Array of strngs and number 
          let dataParse: (string | number)[][] = XLSX.utils.sheet_to_json(ws, {
            header: 1,
          });

          // remove the first row which is an header
          dataParse = dataParse?.slice(1);

          const validateData = await validateQuestion(dataParse) as { data: IQuestionData[]; errorMessage: IImportErrorMessage[]; };

          setState((prev) => ({
            ...prev,
            ...validateData,
            fileName: selectedFile.name,
          }));
        }
      };
    } else {
      snackbar("Upload excel or xls file", "warning");
    }
  };
  const onExport = async () => {
    try {
      const response = await getTestQuestionSample({_test: testId});
      const byteArray = new Uint8Array(response?.data?.data);
    
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    
      const file = new File([blob], "test-question.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    
      const link = document.createElement("a");
      document.body.appendChild(link);
    
      link.href = window.URL.createObjectURL(file);
      link.download = "test-question.xlsx";
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      snackbar("Error in downloading sample", "error");
    }
  };
  

  const onSubmit = async () => {
    try {
      if (state.data.length) {
        state.data.forEach((item: IQuestionData) => {
          item._test = testId;
        });
        const importTest = await importTestQuestions({ questions: state.data });
        snackbar(importTest.message, "info");
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }

    if (state.errorMessage.length) {
      setState((prev) => ({
        ...prev,
        showError: true,
      }));
    } else {
      closeErrorDialog();
    }
  };

  const closeErrorDialog = () => {
    setState((prev) => ({
      ...prev,
      fileName: "",
      data: [],
      showError: false,
      errorMessage: [],
    }));

    navigate(-1);
  };

  return (
    <>
      <ImportFile
        isOpen={true}
        onClose={closeImport}
        onUpload={onUpload}
        onsubmit={onSubmit}
        fileName={state.fileName}
        onDownload={onExport}
        title="Before you start upload, please make sure:"
        content={[
          "Download the sample excel file.",
          "Read all the fields in the 1st row and do not change the text.",
          "Start uploading the data from 2nd row and onwards.",
          "In each row, there will be data for one question.",
          "All variants are mapped to their field by field title.",
          "Once sheet is prepared, upload it.",
          "Now sit back and relax!",
        ]}
      />

      <ImportFileError
        title="Excel file Errors"
        isOpen={state.showError}
        onClose={closeErrorDialog}
        errorMessage={state.errorMessage}
      />
    </>
  );
};

export default ImportTestQuestion;
