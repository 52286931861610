import Joi from "joi";
import { required } from "../../shared";
export const CategoryValidation = Joi.object({
    name: Joi.string()
        .trim()
        .min(3)
        .max(60)
        .required()
        .label("Title")
        .messages({
            "string.empty": required
        }),
    _productId: Joi.string()
        .required()
        .label("Product")
        .messages({
            "string.empty": required
        }),
});
