import Joi from "joi";
import { required } from "../../shared";

export const courseConfigurationSectionValidation = Joi.object({
  name: Joi.string()
    .required()
    .label("Name")
    .max(25)
    .messages({
      "string.empty": required,
      "string.max": "The name must not exceed 25 characters."
    }),
  description: Joi.string()
    .required()
    .label("Description")
    .max(100)
    .messages({
      "string.empty": required,
      "string.max": "The name must not exceed 100 characters."
    }),
  _skillCategory: Joi.array()
    .required()
    .min(1)
    .label("Skill Category")
    .messages({
      "array.empty": required,
      "array.min": "Please select at least one value."
    })
});