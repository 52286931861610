import { FC, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { emailTemplateKeywordsService } from "../../services/configuration/email-template-keywords";
import { useQuery } from "@tanstack/react-query";
import { IDynamicKeyword, ITinyEditorProps, IUploadedFile } from "../../interfaces/tiny-editor";

const addKeyword = (keyword: string) =>
    `<span contenteditable="false" style="background-color: #ed6c0259; display: inline-block; padding: 4px; border-radius: 4px;"><em>{{${keyword}}}</em></span>`;


const TinyEditor: FC<ITinyEditorProps> = ({ value, height, onChange, type }) => {
    const triggerFileUpload = (
        type: "file" | "img",
        onUploadComplete: (file: IUploadedFile) => void
    ) => {
        const myfile = document.getElementById("myfile") as HTMLInputElement;
        if (!myfile) return;
    
        myfile.value = ""; // Clear previous selection
        myfile.click();
    
        const handleChange = async (e: Event) => {
            const target = e.target as HTMLInputElement;
            const validate = type === "file"
                ? (/.*\.(pdf|doc|docx|xls|xlsx)$/i).test(target.value)
                : (/.*\.(jpg|jpeg|png|webp)$/i).test(target.value);
    
            if (validate && target.files?.length) {
                const formdata = new FormData();
                formdata.append("file", target.files[0]);
    
                // ✅ Replace this with your actual upload logic
                const uploadedFile: IUploadedFile = {
                    fileName: target.files[0].name,
                    url: URL.createObjectURL(target.files[0]),
                };
    
                onUploadComplete(uploadedFile);
            }
    
            myfile.removeEventListener("change", handleChange); // Clean up
        };
    
        myfile.addEventListener("change", handleChange);
    };
    

    useEffect(() => {
        const handleFocusIn = (e: FocusEvent) => {
            const target = e.target as HTMLElement;
            if (target.closest(".mce-window") || target.closest(".tox-dialog")) {
                e.stopImmediatePropagation();
            }
        };

        document.addEventListener("focusin", handleFocusIn);

        return () => {
            document.removeEventListener("focusin", handleFocusIn);
        };
    }, []);

    const { getPartialEmailTemplateKeywords } = emailTemplateKeywordsService();
    const templateKeywordResponse = useQuery({
        queryKey: ["email-template-keyword-partial", type],
        queryFn: () =>
            getPartialEmailTemplateKeywords({
                type: type ? type : []
            })
    });
    
    return (
        templateKeywordResponse.data?.data ? (
        <>
            <input type="file" id="myfile" hidden />
            <Editor
                onEditorChange={onChange}
                apiKey={String(process.env.REACT_APP_TINY_EDITOR_API_KEY)}
                initialValue={value}

                init={{
                    // images_upload_handler: upload,
                    height: height ? height : 300,

                    readonly: false,
                    document_base_url: "http://www.example.com/path1/",
                    link_assume_external_targets: true,
                    plugins: [
                        "advlist", "autolink", "lists", "link", "image", "charmap", "preview",
                        "anchor", "searchreplace", "visualblocks", "code", "fullscreen",
                        "insertdatetime", "table", "code", "help", "wordcount"
                    ],
                    toolbar: `keywords  undo redo | blocks | 
                        bold italic forecolor | alignleft aligncenter 
                        alignright alignjustify | bullist numlist outdent indent | 
                        removeformat | help`,



                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "file") {
                            triggerFileUpload("file", (fileUpdated) => {
                                callback(fileUpdated.url, { text: fileUpdated.fileName });
                            });
                        }
                    
                        if (meta.filetype === "image") {
                            triggerFileUpload("img", (fileUpdated) => {
                                callback(fileUpdated.url, { text: fileUpdated.fileName });
                            });
                        }
                    },                    
                    
                    setup: (editor) => {
                        // Handle clickable links
                        editor.on("click", (e) => {
                            const target = e.target.closest("a");
                            if (target) {
                                const href = target.getAttribute("href");
                                if (href && href !== "#") {
                                    window.open(href, "_blank");
                                }
                            }
                        });
                    
                        // Add Keywords dropdown button
                        editor.ui.registry.addMenuButton("keywords", {
                            text: "Keywords",
                            fetch: (callback) => {                   
                                const templateKeywords = templateKeywordResponse?.data?.data || [];
                    
                                const keywordItems = templateKeywords.map((keyword: IDynamicKeyword) => ({
                                          type: "togglemenuitem" as const,
                                          text: keyword.name.trim(),
                                          onAction: () => {
                                              editor.insertContent(addKeyword(keyword.value.trim()));
                                          },
                                      }));
                    
                                callback(keywordItems);
                            },
                        });
                    }
                }}
                    
            />
        </>
        ) : <></>
    );
};

export default TinyEditor;