import InlineDialog from "../../../components/mui/inlineDialogue";
import { ChangeEvent, FC, useEffect } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
	useNavigate,
	useOutletContext,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IAddMediaImage, IErrorResponse, IMediaImageField } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import { MediaImageService } from "../../../services";
import HttpService from "../../../services/http";
import { MediaImageValidation } from "../../../validations";



interface IoutletProps {
	reFetch: () => void;
	refetchMedia: () => void;
}

const AddMediaImage: FC = () => {
	const navigate = useNavigate();
	const outlet = useOutletContext<IoutletProps>();
	const { id } = useParams();
	const [searchParam] = useSearchParams();
	const { snackbar } = useSnackbar();
	const { addMediaImage, getMediaImage, updateMediaImage } = MediaImageService();
	const { httpFormRequest } = HttpService();

	const hitQuery = !(id === "new");
	const mediaImage = useQuery({
		queryKey: ["media-image", id],
		queryFn: () => getMediaImage({ _id: id }),
		enabled: hitQuery,
	});

	const {
		handleSubmit,
		control,
		setValue,
		trigger,
		formState: { errors },
	} = useForm<IAddMediaImage>({
		resolver: joiResolver(MediaImageValidation),
		defaultValues: {
			name: "",
			imageUrl: ""
		},
	});

	const fields: IMediaImageField[] = [
		{
			name: "imageUrl",
			label: "Image URL",
			placeholder: "Upload Image",
			type: "upload",
		},
		{
			name: "name",
			label: "Name",
			placeholder: "Type Name",
			type: "text",
		},
	];

	useEffect(() => {
		if (id !== "new") {
			if (mediaImage?.data?.data) {
				const mediaImageData = mediaImage?.data?.data;
				setValue("name", mediaImageData && mediaImageData.name || "");
				trigger("name");
				setValue("imageUrl", mediaImageData && mediaImageData.imageUrl || "");
				trigger("imageUrl");
			}
		}
	}, [mediaImage.data]);


	const onSubmit = async (data: IAddMediaImage) => {
		try {
			if (id === "new") {
				const add = await addMediaImage(data);
				snackbar(add.message, "info");
				navigate({
					pathname: "/media-images",
					search: searchParam.toString(),
				});
			} else {
				const update = await updateMediaImage({ ...data, _id: id });
				snackbar(update.message, "info");
				navigate({
					pathname: "/media-images",
					search: searchParam.toString(),
				});
			}
			outlet?.refetchMedia && outlet?.refetchMedia();
		} catch (error) {
			const err = error as IErrorResponse;
			snackbar(err.data.message, "error");
			console.log(err);
		}
	};

	const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
		try {
			if (e.target.files && e.target.files.length > 0) {
				const uploaded = await httpFormRequest<{ data: string }>(
					e.target.files,
					e.target.files[0].name,
					["webp"],
					1
				);
				const keyExist = type === "imageUrl";
				if (keyExist) {
					setValue("imageUrl", uploaded?.data);
					trigger("imageUrl");
					setValue("name", e.target.files[0].name);
				}
			}
		} catch (error) {
			console.log("error in uploading file", error);
		}
	};
	const handleClose = () => {
		navigate({
			pathname: "/media-images",
			search: searchParam.toString(),
		});
	};

	return (
		<Box>
			<InlineDialog
				onClose={() => handleClose()}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Grid container spacing={4}>
					{
						fields.map((field) => {
							if (field.type === "upload") {
								return (
									<>

										<Grid key={field.label} item xs={6} >
											<Controller
												control={control}
												name={field.name}
												render={(prop) => (
													<Box
														style={{ cursor: "pointer" }}
														onClick={() => {
															const fileInput = document.getElementById(`fileInput-${field.name}`);
															if (fileInput) {
																fileInput.click();
															}
														}}
													>
														<TextField
															label={field.label}
															className="disable-text"
															variant="outlined"
															size="small"
															placeholder={field.placeholder}
															style={{ cursor: "pointer" }}
															{...prop.field}
															value={prop.field.value || ""}
															error={!!errors[field.name]}
															helperText={errors[field.name]?.message}
															InputProps={{
																readOnly: true,
																endAdornment: (
																	<Tooltip title="Upload">
																		<IconButton
																			color="primary"
																			component="label"
																		>
																			<UploadIcon color="action" />
																		</IconButton>
																	</Tooltip>
																),
															}}
														/>
														<input
															id={`fileInput-${field.name}`}
															type="file"
															accept="image/*"
															style={{ display: "none" }}
															onChange={(e) => {
																uploadFile(e, field.name);
															}}
														/>
													</Box>
												)}
											/>

										</Grid>
									</>

								);
							} else {
								return (
									<Grid key={field.label} item xs={6}>
										<Controller
											control={control}
											name={field.name}
											render={(prop) => <TextField
												label={field.label}
												variant="outlined"
												size="small"
												placeholder={field.placeholder}
												{...prop.field}
												value={prop.field.value || ""}
												error={!!errors[field.name]}
												helperText={errors[field.name]?.message}
											/>}
										/>
									</Grid>
								);
							}
						})
					}

				</Grid>
			</InlineDialog>
		</Box>
	);
};

export default AddMediaImage;
