import { useEffect, useState, MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import { useSearchParams, Outlet } from "react-router-dom";
import useResource from "../../../../hooks/useResource";
import useSnackbar from "../../../../hooks/useSnackbar";
import { courseSubSectionService } from "../../../../services/configuration/course-sub-category";
import { ICourseConfigurationSubSectionRow, ICourseSubSection, ICourseSubSectionState } from "../../../../interfaces/configuration/course-sub-category";
import useDebounce from "../../../../hooks/useDebounce";
import { IErrorResponse, IPagination } from "../../../../interfaces";
import { capitalize, createIndex } from "../../../../utilities/helper";
import WarningDialog from "../../../../components/mui/warning-dialog";
import CustomTable from "../../../../components/mui/table";
import Header from "../../../../components/header";
import GetActions from "../../../../components/get-actions";
import CourseConfigurationSubFilters from "./filters";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";

const CourseConfigurationSubSectionList = () => {
  let rows: ICourseConfigurationSubSectionRow[] = [];
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { courseSectionId, courseId } = useParams();

  const {
    deletecourseSubSection,
    updatecourseSubSection,
    getcourseSubSectionList
  } = courseSubSectionService();

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<ICourseSubSectionState>({
    courseConfigurationSubSection: "",
    pagination: { page: 1, limit: 20, totalPages: 1 },
    multiDeleteWarning: false,
    selectAll: [],
    filterDialog: { anchorEl: null, isOpen: false },
    filters: {
      search: "",
      _skillItems: [],
      activeStatus: [],
    },
    deleteWarning: false,
    filterCount: 0,
    searching: "",});

  const columns = [
    {
      id: "id",
      label: "S No.",
    },    
    {
      id: "name",
      label: "Name",
    },
    {
      id: "_skillItem",
      label: "Skill Item",
    },    
    {
      id: "estimatedDuration",
      label: "Estimated Duration",
    },
    {
      id: "isActive",
      label: "Active",
    },
    {
      id: "action",
      label: "Action",
    }
  ];

  const searchRecord = useDebounce(search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const getcourseConfigurationSubSection = useQuery({
    queryKey: [
      "course-Configuration-Sub-Section-List",
      state.pagination.page,
      state.filters,
    ],
    queryFn: () =>
      getcourseSubSectionList({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        _courseSection: courseSectionId,
        ...state.filters,
      }),
  });

  useEffect(() => {
    if (
      getcourseConfigurationSubSection.data &&
      getcourseConfigurationSubSection.data.data &&
      getcourseConfigurationSubSection.data.data.length
    ) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getcourseConfigurationSubSection.data.meta.page,
          totalPages: getcourseConfigurationSubSection.data.meta.totalPages,
          totalRecords: getcourseConfigurationSubSection.data.meta.totalRecords,
        },
      }));
    }
  }, [getcourseConfigurationSubSection.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const _skillItems: { key: string; value: string }[] = searchParams.get(
      "_skillItems"
    )
      ? JSON.parse(String(searchParams.get("_skillItems")))
      : [];
    const activeStatus: { key: string; value: string }[] = searchParams.get("activeStatus")
      ? JSON.parse(String(searchParams.get("activeStatus")))
      : [];

    filterCount += _skillItems.length ? 1 : 0;
    filterCount += activeStatus.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        _skillItems: _skillItems?.map((item) => item.key) ?? [],
        activeStatus: activeStatus?.map((item) => item.key) ?? [],
      },
      filterCount,
    }));
  }, [searchParams]);

  const isActiveChange = async (event: ChangeEvent<HTMLInputElement>, checked: string, _id: string) => {
    try {
      const isActive = await updatecourseSubSection({ _id, isActive: checked });
      snackbar(isActive.message, "info");
      getcourseConfigurationSubSection.refetch();
    } catch(error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const handleDelete = (courseConfigurationSubSection = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      courseConfigurationSubSection,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deletecourseSubSection({
        _id: state.courseConfigurationSubSection,
      });
      snackbar(deleted.message, "info");
      handleDelete();
      getcourseConfigurationSubSection.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);


  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };
  
  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const createRow = (
    index: number,
    courseConfigurationSubSection: ICourseSubSection,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: `manage/${courseConfigurationSubSection._id}`,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("configuration-course-sub-section.edit"),
          },
          {
            name: "Delete",
            method: () => handleDelete(courseConfigurationSubSection?._id),
            disabled: !(resourceAllocate("configuration-course-sub-section.remove"))
          },
        ]}
      />
    );  
    const skillItem = <Tooltip title={courseConfigurationSubSection?._skillItem?.map(item => item.name).join(", ") || "N/A"}>
    <span>
      {courseConfigurationSubSection && courseConfigurationSubSection._skillItem && courseConfigurationSubSection._skillItem.length > 0 ? (
        <>
          {capitalize(courseConfigurationSubSection._skillItem[0]?.name || "")}{" "}
          {courseConfigurationSubSection._skillItem.length > 1 && (
            <span style={{ color: "var(--primary-color)" }}>+{courseConfigurationSubSection._skillItem.length - 1}</span>
          )}
        </>
      ) : (
        "N/A"
      )}
    </span>
  </Tooltip>;

    return {
      id: createIndex(pagination, index),
      name:courseConfigurationSubSection?.name ? capitalize(courseConfigurationSubSection?.name || "") : "N/A",
      _skillItem: skillItem,
      estimatedDuration: capitalize(courseConfigurationSubSection?.estimatedDuration) || "N/A",
      createdBy: courseConfigurationSubSection?.createdBy?.name || "N/A",
      isActive: <Switch
          disabled={!resourceAllocate("configuration-course-sub-section.write")}
          onChange={(event ,checked) => isActiveChange(event, String(checked), courseConfigurationSubSection._id)}
          checked={!!courseConfigurationSubSection.isActive}
        />,
      action,
    };
  };

  useEffect(() => {
    if (getcourseConfigurationSubSection.data && getcourseConfigurationSubSection.data.data && getcourseConfigurationSubSection.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [getcourseConfigurationSubSection.data && getcourseConfigurationSubSection.data.data]);
  
  if (
    getcourseConfigurationSubSection.data &&
    getcourseConfigurationSubSection.data.data &&
    getcourseConfigurationSubSection.data.data.length
  ) {
    rows =
      getcourseConfigurationSubSection.data.data.map((courseConfigurationSubSection, i) =>
        createRow(i, courseConfigurationSubSection, state.pagination)
      );
  }

  const handleClick = () => {
    navigate("/configurations/course/course-section/" + courseId);
  };

  return (
    <>
      <Box className="header">
        <Box display="flex" gap={2}>
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography variant="h5">
            {capitalize(getcourseConfigurationSubSection.data?.meta?.courseSectionName|| "")}
          </Typography>
        </Box>
      </Box>
      <Header
        className="my-2"
        btnText="Add Course Sub Section Configuration"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        onBtnClick={
          resourceAllocate("configuration-course-sub-section.write")
            ? () =>
                navigate({
                  pathname: "manage/new",
                  search: searchParams.toString(),
                })
            : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <CourseConfigurationSubFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnCloseFilter={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 250px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Course Configuration Sub Section"
        description="Are you sure you want to delete this course sub section configuration?"
      />

      <Outlet context={{ reFetch: getcourseConfigurationSubSection.refetch }} />
    </>
  );
};
export default CourseConfigurationSubSectionList;
