import { useQuery } from "@tanstack/react-query";
import { MouseEvent } from "react";
import {
  Box,
  DialogContent,
  TextField,
  Grid,
} from "@mui/material";
import { ChangeEvent, useState, useEffect } from "react";
import {
  Outlet,
  useSearchParams,
} from "react-router-dom";
import {
  ISelfEvaluationFeedback,
  ISelfEvaluationFeedbackState,
  ITeamEvaluationFeedbackRow,
} from "../../../../interfaces/pms/self-evaluation";
import useResource from "../../../../hooks/useResource";
import useDebounce from "../../../../hooks/useDebounce";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import GetActions from "../../../../components/get-actions";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../components/shared/mui-tabs";
import FeedbackParameters from "../../self-evaluation/feedback/filter";
import { FeedbackRatingService } from "../../../../services/pms/feedback";
import { capitalize, createIndex, formatDate } from "../../../../utilities/helper";
import CustomTypography from "../../../../components/mui/max-length-limit";
import { IPagination } from "../../../../interfaces";

const TeamEvaluationFeedback = () => {
  let rows: ITeamEvaluationFeedbackRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const { resourceAllocate } = useResource();
  const { getSelfFeedbacks } = FeedbackRatingService();
  const [search, setSearch] = useState<string>("");
  const [feedback, setFeedback] = useState<ISelfEvaluationFeedback | null>(null);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [state, setState] = useState<ISelfEvaluationFeedbackState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filters: {
      search: "",
      createdAt: {
        startDate: "",
        endDate: "",
      },
      _parameter: [],
    },
    filterCount: 0,
  });

  const feedbacks = useQuery({
    queryKey: ["my-feedbacks-data", state.pagination.page, state.filters],
    queryFn: () =>
      getSelfFeedbacks({
        createdBy: true,
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        startDate: state.filters.createdAt?.startDate,
        endDate: state.filters.createdAt?.endDate,
        _parameter: state.filters._parameter
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (feedbacks.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: feedbacks.data.meta.page,
          totalPages: feedbacks.data.meta.totalPages,
          totalRecords: feedbacks.data.meta.totalRecords,
        },
      }));
    }
  }, [feedbacks.data?.meta]);

  useEffect(() => {
    if (feedbacks.data && feedbacks.data.data && feedbacks.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [feedbacks.data && feedbacks.data.data]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];
      const _parameter: { key: string; value: string }[] = searchParams.get(
        "_parameter"
      )
        ? JSON.parse(String(searchParams.get("_parameter")))
        : [];
    filterCount += date.length ? 1 : 0;
    filterCount += _parameter.length ? 1 : 0;
    let createdAt:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        search,
        createdAt,
        _parameter: _parameter.map((item) => item.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  useEffect(() => {
    if (!searchParams.get("date")) {
      const today = new Date();
      const lastYear = new Date();
      lastYear.setFullYear(today.getFullYear() - 1);

      const defaultDateFilter = [
        {
          key: "custom",
          value: "Custom",
          startDate: lastYear.toISOString(),
          endDate: today.toISOString(),
        },
      ];

      searchParams.set("date", JSON.stringify(defaultDateFilter));
      setSearchParams(searchParams);
    }
  }, []);

  const columns = [
    {
      id: "id",
      label: "S. No",
    },
    {
      id: "user",
      label: "Name",
    },
    {
      id: "parameter",
      label: "Parameter",
    },
    {
      id: "feedback",
      label: "Feedback",
    },
    {
      id: "createdAt",
      label: "Date",
    },
    {
      id: "actions",
      label: "Action",
    },
  ];

  const createRow = (index: number, feedback: ISelfEvaluationFeedback, pagination: IPagination) => {
    const actions = (
      <GetActions
        icons={[
          {
            name: "View",
            method: () => {
              setFeedback(feedback);
              setShowFeedback(true);
            },
            disabled: !resourceAllocate("pms-feedback-rating.read"),
          },
        ]}
      />
    );

    return {
      id: createIndex(pagination, index),
      feedback: (
        <CustomTypography
          limit={100}
          label={feedback.feedback || ""}
        />
      ),
      parameter: capitalize(feedback && feedback._parameter && feedback._parameter.name || ""),
      user: capitalize(feedback && feedback._user && feedback._user.name || ""),
      createdAt: formatDate(feedback &&feedback.createdAt || ""),
      actions,
    };
  };

  if (feedbacks && feedbacks.data && feedbacks.data.data) {
    rows = feedbacks.data.data.map((feedback, i) => createRow(i, feedback, state.pagination));
  }

  return (
    <>
      <Header
        className="my-2"
        searchPlaceholder="Search by feedback"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        filterStartDate={formatDate(state.filters.createdAt?.startDate)}
        filterEndDate={formatDate(state.filters.createdAt?.endDate)}
      >
        <FeedbackParameters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="Add feedbacks to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <BootstrapDialog
        open={showFeedback}
        onClose={() => setShowFeedback(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog"
      >
        <BootstrapDialogTitle
          id="alert-dialog-title"
          onClose={() => setShowFeedback(false)}
        >
          {"Feedback Details"}
        </BootstrapDialogTitle>
        <DialogContent>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6}>
                <TextField label={"Date"} value={formatDate(feedback?.createdAt)} disabled />
              </Grid>
              <Grid item xs={6}>
                <TextField label={"Parameter"} value={capitalize(feedback && feedback._parameter && feedback._parameter.name || "")} disabled />
              </Grid>
            </Grid>
            <Grid container marginTop={2}>
              <Grid item xs={12}>
                <TextField label={"Feedback"} value={feedback?.feedback} disabled multiline />
              </Grid>
            </Grid>
        </DialogContent>
      </BootstrapDialog>
      <Outlet />
    </>
  );
};

export default TeamEvaluationFeedback;
