import { ChangeEvent, useEffect, useState } from "react";
import { UsersService } from "../../services";
import useSnackbar from "../../hooks/useSnackbar";
import useUser from "../../hooks/useUser";
import { IErrorResponse, IPersonalInformationFields, IProfileProfessionalFields, IProfle } from "../../interfaces";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { myProfileValidation } from "../../validations/configuration/my-profile";
import { Box, Button, Divider, Grid, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import { capitalize, formatDate } from "../../utilities/helper";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CustomLabel from "../../components/mui/custom-label";
import MobileNumber from "../../components/mui/mobile-number";
import SearchSelect from "../../components/mui/search-select";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ChangePassword from "./change-password";
import HttpService from "../../services/http";

const SettingsLayout = () => {
  const { httpFormRequest } = HttpService();
  const { myProfileUpdate } = UsersService();
  const [isDisabled, setIsDisabled] = useState(true);
  const [ userImage, setUserImage ] = useState("");
  const [changePasswordDailog, setChangePasswordDialog] = useState(false);
  const [emailtoggle, setEmailtoggle] = useState<boolean>(false);

  const handleToggleSwitch = () => {
    setEmailtoggle(!emailtoggle);
  };

  const handleCancelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDisabled(true);
    clearErrors();
  };

  const { snackbar } = useSnackbar();
  const { user, userRefetch } = useUser();
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    trigger,
    clearErrors,
  } = useForm<IProfle>({
    resolver: joiResolver(myProfileValidation),
    defaultValues: {
      firstName: "",
      lastName: "",
      role: "",
      number: "",
      department: "",
      dob: "",
      image: "",
      address: "",
      linkedinUrl: "",
      extensionPhoneNumber: "",
    },
  });

  const [state, setState] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  const [extensionNumber, setExtensionNumber] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  useEffect(() => {
    if (user) {
      setValue("firstName", `${capitalize(user?.firstName || "")}`);
      setValue("lastName", `${capitalize(user?.lastName || "")}`);
      setExtensionNumber(user?.extensionNumber);
      setValue("extensionPhoneNumber", user?.extensionNumber?.number);
      setEmailtoggle(user.wantToBeNotified);
      setValue("gender", user.gender);
      setValue("address", user?.location?.formattedAddress || "");
      setState({
        country: user?.phone?.country,
        dialCode: user?.phone?.dialCode,
        iso2: user?.phone?.iso2,
      });
      setUserImage(user.image);
      setValue("number", user?.phone?.number);
      setValue("extensionPhoneNumber", user?.extensionNumber?.number);
      setExtensionNumber({
        country: user?.extensionNumber?.country,
        dialCode: user?.extensionNumber?.dialCode,
        iso2: user?.extensionNumber?.iso2,
      });
      setValue("image", user?.image || "");
      setValue("linkedinUrl", user?.linkedinUrl || "");
      setValue("dob", user?.dob || "");
    }
  }, [user, isDisabled]);

  const onNumberChange = (e: SelectChangeEvent<string>, name: string) => {
    const { value } = e.target;
    const data = value.split(":");
    if(name === "number") { 
      setState({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    }else{
      setExtensionNumber({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    }
  };

  const personalInformationFields: IPersonalInformationFields[] = [
    {
      label: "First Name",
      name: "firstName",
      type: "input",
      placeholder: "Enter first name",
      wordLimit: 30,
      required: true
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "input",
      placeholder: "Enter last name",
      wordLimit: 30,
      required: true
    },
    {
      label: "Mobile Number",
      name: "number",
      type: "mobile-number",
      placeholder: "Enter contact number",
      required: true
    },
    {
      label: "Extension Mobile Number",
      name: "extensionPhoneNumber",
      type: "mobile-number",
      placeholder: "Enter contact number",
    },
    {
      label: "LinkedIn Profile URL",
      name: "linkedinUrl",
      type: "input",
      placeholder: "Enter LinkedIn URL",
    },
    {
      label: "Date of Birth",
      name: "dob",
      type: "date",
    },
    {
      label: "Gender",
      name: "gender",
      type: "select",
      options: [
        {
          key: "MALE",
          value: "Male",
        },
        {
          key: "FEMALE",
          value: "Female",
        },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      required: true,
    },
    {
      label: "Employee Address",
      name: "address",
      type: "input",
    },
  ];

  const professionalFields: IProfileProfessionalFields[] = [
    {
      label: "Email",
      name: "email",
      type: "input",
      value: user?.email,
    },
    {
      label: "Department",
      name: "department",
      type: "input",
      value: capitalize(user?.department?.name),
    },
    {
      label: "Role",
      name: "role",
      type: "input",
      value: capitalize(user?._role?.name),
    },
    {
      label: "Designation",
      name: "designation",
      type: "input",
      value: capitalize(user?.designation?.name),
    },
    {
      label: "Reporting Manager",
      name: "reportingTo",
      type: "input",
      value: capitalize(user?.reportingTo?.name),
    },
    {
      label: "Joined Date",
      name: "joinedDate",
      type: "input",
      value: formatDate(user?.joinedDate),
    },
    {
      label: "Business Unit",
      name: "businessUnit",
      type: "input",
      value: capitalize(user?.businessUnit?.name),
    },
    {
      label: "Legal Entry",
      name: "legalEntry",
      type: "input",
      value: capitalize(user?.legalEntry),
    },
    {
      label: "Date of Leaving",
      name: "dateOfLeaving",
      type: "input",
      value: formatDate(user?.dateOfLeaving),
    },
  ];

  const onSubmit = async (data: IProfle) => {
    try {
      const payload = { ...data };
      payload.wantToBeNotified = emailtoggle;
      payload.name = "";
      payload.phone = {
        dialCode: state.dialCode,
        iso2: state.iso2,
        country: state.country,
        number: payload.number || "",
      };
      payload.extensionNumber =  {
        ...extensionNumber,
        number: payload.extensionPhoneNumber || "",
      },
      payload.name = `${getValues("firstName") && getValues("firstName")} ${
        getValues("lastName") && getValues("lastName")
      }`;
      payload.location = {
        formattedAddress: payload.address || "",
      };
      payload.image = userImage;
      delete payload.number;
      delete payload.extensionPhoneNumber;
      delete payload.role;
      delete payload.department;
      delete payload.address;
      const update = await myProfileUpdate({
        _id: user && user._id,
        ...payload,
      });
      setIsDisabled(true);
      snackbar(update.message, "info");
      userRefetch();
    } catch (error) {
      const err = error as { message: string };
      snackbar(err.message, "warning");
    }
  };
  const selectDate = (
    value: string | number | Date | dayjs.Dayjs | null | undefined,
    name: string
  ) => {
    const date =
      value && dayjs(value).toString() !== "Invalid Date"
        ? dayjs(value)?.toISOString()
        : undefined;
    const keyExist = name === "dob";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["png", "jpeg", "jpg", "webp"],
          1
        );
        const keyExist = type === "image";
        if (keyExist) {
          setValue(type, uploaded?.data);
          setUserImage(uploaded?.data);
          trigger(type);
        }
      }
    }
    catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  return (
    <Box>
    <Divider className="divider pl-3 pr-3"/>
      <Box paddingLeft={"10px"} paddingRight={"10px"}>
        <Box>
          <Typography fontWeight={700} variant="h6" marginTop={"20px"}>
            Profile Information
          </Typography>
          <Typography fontWeight={400} variant="body1" color={"#999"}>
            Manage your personal information and settings.
          </Typography>
        </Box>
        <Box className="profile-box mt-4" display={"flex"} gap={"70px"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"25%"}
            height={"100%"}
            marginTop={"10px"}
          >
            <Grid key={"image"} item xs={12}>
                <Box className="center">
                  <Box height="100px" width="100px" className={`upload-img ${ isDisabled ? "" : "editable"}`} aria-label="upload picture" component="label">
                    <img src={userImage || ""} alt="review" />
                    {!isDisabled && <div className="edit-img ">{userImage?.length ? "Edit" : "Add"}</div> }
                    <input hidden accept="image/*" type="file" disabled={isDisabled} onChange={e => uploadFile(e, "image")} />
                  </Box> 
                </Box>
                {errors["image"] && <span style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.85rem" }}>{errors["image"]?.message}</span>}
              </Grid>
            <Typography variant="body1" fontWeight={700} marginTop={"10px"} textAlign={"center"}>
              {user?.firstName || ""} {user?.lastName || ""}
            </Typography>
            <Typography variant="body2" fontSize={"12px"} textAlign={"center"}>
              {user?.designation?.name || ""}
            </Typography>
            <Typography variant="body2" color={"#999"} fontSize={"10px"} textAlign={"center"}>
              {user?.userId || ""}
            </Typography>
          </Box>
          <Box display={"flex"} gap={"20px"} flexDirection={"column"}>
            <Box>
              <Typography variant="h6" fontWeight={700} fontSize={"18px"}>
                Personal Details
                {isDisabled && (
                  <IconButton
                    className="ml-2 p-0"
                    color="primary"
                    onClick={() => setIsDisabled(false)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Typography>
              {
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2} marginTop={"1px"}>
                    {personalInformationFields.map((field) => {
                      if (field.type === "input") {
                        return (
                          <Grid key={field.label} item lg={4} md={6} xs={12}>
                            <Controller
                              control={control}
                              name={field.name}
                              render={(prop) => (
                                <TextField
                                  label={
                                    <CustomLabel
                                      label={field.label}
                                      required={field?.required}
                                    />
                                  }
                                  className="disable-text"
                                  disabled={isDisabled}
                                  placeholder={field.placeholder}
                                  error={!!errors[field.name]}
                                  helperText={errors[field.name]?.message}
                                  {...prop.field}
                                />
                              )}
                            />
                          </Grid>
                        );
                      } else if (field.type === "mobile-number") {
                        return (
                          <Grid key={field.label} item lg={4} md={6} xs={12}>
                            <Controller
                              control={control}
                              name={field.name}
                              render={(prop) => (
                                <MobileNumber
                                  disabled={isDisabled}
                                  vendorOrUserEdit
                                  key={field.label}
                                  NumberFieldLabel={
                                    <CustomLabel
                                      label={field.label}
                                      required={field?.required}
                                    />
                                  }
                                  dialCodeValue={field.name === "number" ? `${state?.country}:${state?.iso2}:${state?.dialCode}` : `${extensionNumber?.country}:${extensionNumber?.iso2}:${extensionNumber?.dialCode}`}
                                  onChange={(event) => onNumberChange(event, field.name)}
                                  error={!!errors[field.name]}
                                  helperText={errors[field.name]?.message}
                                  other={prop.field}
                                />
                              )}
                            />
                          </Grid>
                        );
                      } else if (field.type === "date") {
                        return (
                          <Grid key={field.label} item lg={4} md={6} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                label={
                                  <CustomLabel
                                    label={field.label}
                                    required={field?.required}
                                  />
                                }
                                onChange={(e) => selectDate(e, field.name)}
                                disabled={isDisabled}
                                value={dayjs(getValues(field.name))}
                                slotProps={{
                                  textField: {
                                    error: !!errors[field.name],
                                    helperText: errors[field.name]?.message,
                                  },
                                }}
                                format="LL"
                              />
                            </LocalizationProvider>
                          </Grid>
                        );
                      }
                      else {
                        return (
                          <Grid key={field.label} item lg={4} md={6} xs={12}>
                            <SearchSelect
                              disabled={isDisabled}
                              name={field.name}
                              label={
                                <CustomLabel
                                  label={field.label}
                                  required={field?.required}
                                />
                              }
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                              options={field.options}
                              displayFieldKey={
                                field.displayFieldKey ? field.displayFieldKey : ""
                              }
                              storeFieldKey={
                                field.storeFieldKey ? field.storeFieldKey : ""
                              }
                              displayUserName={field.displayUserName}
                              capitalize={field.capitalize}
                              trigger={trigger}
                              setValue={setValue}
                              getValues={getValues}
                            />
                          </Grid>
                        );
                      }
                    })}
                    {
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <Typography
                            variant="body2"
                            color={isDisabled ? "textSecondary" : ""}
                          >
                            Email Notifications
                          </Typography>
                          <Switch
                            disabled={isDisabled}
                            checked={emailtoggle || false}
                            onChange={handleToggleSwitch}
                          />
                        </Box>

                       
                      </Grid>
                    }
                  </Grid>
                  {!isDisabled && (
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              variant="outlined"
                              type="button"
                              onClick={handleCancelClick}
                            >
                              Discard
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              className="ml-2"
                            >
                              Save
                            </Button>
                          </Box>
                        )}
                </form>
              }
            </Box>
            <Box>
              <Typography variant="h6" fontWeight={700} fontSize={"18px"}>
                Professional Details
              </Typography>
              {
                <Grid container spacing={2} marginTop={"1px"}>
                  {professionalFields.map((field) => (
                    <Grid key={field.label} item xs={12} lg={4} md={6}>
                      <TextField
                        variant="outlined"
                        label={field.label}
                        fullWidth
                        value={field.value}
                        disabled={true}
                        InputLabelProps={{
                          shrink: !!field.value,
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              }
            </Box>
          </Box>
        </Box>
        <Box className="profile-box mt-4" display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
          <Typography fontWeight={700} variant="h6" >
            Change Password
          </Typography>
          <Typography fontWeight={400} variant="body1" color={"#999"}>
            A secure password helps protect your account
          </Typography>
          </Box>
          <Box>
            <Button onClick={() => setChangePasswordDialog(true)}>
              Change Password
            </Button>
            <ChangePassword isOpen={changePasswordDailog} onClose={() => setChangePasswordDialog(false)} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsLayout;