import "../style.scss";
import { Box, IconButton } from "@mui/material";
import { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IColumn, IPagination } from "../../../interfaces";
import { CollegeImageService } from "../../../services";
import { Outlet, useSearchParams } from "react-router-dom";
import { createIndex, formatDateTime } from "../../../utilities/helper";
import { ICollegeImageRow, ICollegeImageState, ICollegeImage } from "../../../interfaces";
import ImageViewer from "react-simple-image-viewer";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import WarningDialog from "../../../components/mui/warning-dialog";
import GetActions from "../../../components/get-actions";

interface IImageViewer {
  open: boolean,
  index: number,
  images: string[],
}
const CollegeImage = () => {
  let rows: ICollegeImageRow[] = [];
  const { snackbar } = useSnackbar();
  const { getCollegeImages, deleteCollegeImage } = CollegeImageService();
  const [search] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<ICollegeImageState>({
    deleteWarning: false,
    _collegeImage: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });
  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });

  const getCollegeImage = useQuery({
    queryKey: ["allCollegeImages", state.pagination.page, state.filters],
    queryFn: () => getCollegeImages({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getCollegeImage.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getCollegeImage.data.meta.page,
          totalPages: getCollegeImage.data.meta.totalPages,
          totalRecords: getCollegeImage.data.meta.totalRecords
        }
      }));


      const images: string[] = [];
      getCollegeImage.data?.data.map(ele => {
        images.push(ele.image);
      });

      setImageViewer(prev => ({
        ...prev,
        images
      }));
    }
  }, [getCollegeImage.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleDelete = (_collegeImage = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _collegeImage
    }
    ));
  };

  useEffect(() => {
    if (getCollegeImage.data && getCollegeImage.data.data && getCollegeImage.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [getCollegeImage.data && getCollegeImage.data.data]);

  const onDelete = async () => {
    try {
      const deleted = await deleteCollegeImage({ _id: state._collegeImage });
      snackbar(deleted.message, "info");
      handleDelete();
      getCollegeImage.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "collegeImage_image",
      label: "Image"
    },
    {
      id: "collegeImage_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Action"
    },
  ];

  const createRow = (index: number, collegeImage: ICollegeImage, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Delete", method: () => handleDelete(collegeImage._id), disabled: true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      collegeImage_image: <IconButton onClick={() => handleImageViewer(index)}><img className="thumbnail" src={collegeImage?.image} alt="" /></IconButton>,
      collegeImage_createdAt: formatDateTime(collegeImage?.createdAt),
      action
    };
  };
  if (getCollegeImage.data?.data.length) {
    rows = getCollegeImage.data?.data.map((collegeImage, i) => createRow(i, collegeImage, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className="my-2"
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete College Image"
        description="Are you sure you want to delete this college image?"
      />

      {/* Preview Image */}
      {imageViewer.open && (
        <>
          <ImageViewer
            src={imageViewer.images}
            currentIndex={imageViewer.index}
            disableScroll={true}
            closeOnClickOutside={false}
            onClose={handleImageViewer}
            backgroundStyle={
              {
                zIndex: 5
              }
            }
          />
        </>
      )}

      <Outlet context={{ reFetch: getCollegeImage.refetch }} />
    </>
  );
};
export default CollegeImage;