import { Accordion, AccordionDetails, AccordionSummary, Box, Button, DialogActions, DialogContent, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BootstrapDialog, BootstrapDialogTitle } from "../shared/mui-tabs";
import { FC, useState } from "react";
interface props {
    onClose: () => void;
    metaTitle: string;
    metaDescription: string;
    words: number;
}

const SeoSummary: FC<props> = ({ onClose, metaTitle, metaDescription, words }) => {
    const getStatusAndDescription = (key: string, value: string, words = 0) => {
        let status = "success";
        let description = "";

        switch (key) {
            case "Meta title":
                if (!value) {
                    status = "error";
                    description = `Meta Title is missing!\n${metaTitle}`;
                } else if (value.length > 60) {
                    status = "warning";
                    description = `Meta Title is too long!\n${metaTitle} (${value.length}/60)`;
                }
                else {
                    status = "success";
                    description = `A Meta Title has been found!\n${metaTitle} (${value.length}/60)`;
                }
                break;

            case "Meta description":
                if (!value) {
                    status = "error";
                    description = `Meta Description is missing!\n${metaDescription}`;
                } else if (value.length > 160) {
                    status = "warning";
                    description = `Meta Description is too long!\n${metaDescription} (${value.length}/160)`;
                }
                else {
                    status = "success";
                    description = `A Meta Description is present and valid.\n${metaDescription} (${value.length}/160)`;
                }
                break;

            case "Word counter":
                if (words < 300) {
                    status = "error";
                    description = `The word count is below the recommended minimum.\nWord count: ${words}`;
                } else {
                    status = "success";
                    description = `The word count is sufficient.\nWord count: ${words}`;
                }
                break;

            default:
                status = "warning";
                description = `Unknown SEO metric.\n${value}`;
        }

        return { status, description };
    };

    const data = [
        {
            title: "Meta title",
            info: "A meta title describes the page content.",
            ...getStatusAndDescription("Meta title", metaTitle),
        },
        {
            title: "Meta description",
            info: "Meta descriptions help improve SEO and CTR.",
            ...getStatusAndDescription("Meta description", metaDescription),
        },
        {
            title: "Word counter",
            info: "The recommended word count for SEO is 300+ words.",
            ...getStatusAndDescription("Word counter", "", words),
        },
      ];

    const getStatusColor = (status: string) => {
        switch (status) {
            case "success":
                return "green";
            case "error":
                return "red";
            case "warning":
                return "orange";
            default:
                return "grey";
        }
    };

    const [expanded, setExpanded] = useState<number | null>(null);

    const handleAccordionChange = (panelIndex: number) => {
      setExpanded((prev) => (prev === panelIndex ? null : panelIndex));
    };

    return (
        <BootstrapDialog
            maxWidth="md"
            onClose={onClose}
            open={true}
            className="preview-container"
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    SEO Plugin
                </Box>
            </BootstrapDialogTitle>

            <DialogContent dividers sx= {{ maxHeight: "45vh", overflow: "auto"}}>
                <Box mt={1}>
                    <Typography gutterBottom variant="h5" component="div">
                        SEO Analyze
                    </Typography>
                </Box>

                <Divider orientation="horizontal" />

                <Box>
                    {data.map((item, index) => (
                        <Accordion key={index} sx={{ marginTop: "10px" }} expanded={expanded === index} onChange={() => handleAccordionChange(index)}>
                            <AccordionSummary
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                            >
                                <Box marginTop={1}>
                                    <Typography variant="subtitle1">
                                        {<ExpandMoreIcon />}
                                    </Typography>
                                </Box>

                                <Box display="flex" alignItems="center" flexGrow={1} marginLeft={3}>
                                    <Box
                                        width={8}
                                        height={8}
                                        borderRadius="50%"
                                        bgcolor={getStatusColor(item.status)}
                                        mr={1}
                                    />
                                    <Typography variant="subtitle1" flexGrow={1}>
                                        {item.title}
                                    </Typography>
                                </Box>
                                <Tooltip title={item.info}>
                                    <IconButton size="small">
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2" whiteSpace="pre-line">
                                    {item.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions className="dialog-footer">
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>

        </BootstrapDialog>
    );

};

export default SeoSummary;