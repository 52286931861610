import "./style.scss";
import { Badge, Box, Button, Tooltip } from "@mui/material";
import { FC, MouseEvent, ChangeEvent, ReactNode, LegacyRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ReactComponent as ImportIcon } from "../../assets/images/import.svg";
import { ReactComponent as ExportIcon } from "../../assets/images/export.svg";
import { FileDownload } from "@mui/icons-material";

interface props {
    onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
    searchPlaceholder?: string;
    btnText?: string;
    anotherBtnText?: string;
    anotherBtnText2?: string;
    inputRef?: LegacyRef<HTMLInputElement>;
    onBtnClick?: (e: MouseEvent<HTMLElement>) => void;
    anotherOnBtnClick?: (e: MouseEvent<HTMLElement>) => void;
    anotherOnBtnClick2?: (e: MouseEvent<HTMLElement>) => void;
    onDelete?: (e: MouseEvent<HTMLElement>) => void;
    onFilter?: (e: MouseEvent<HTMLButtonElement>) => void;
    filterCount?: number;
    onDownload?: (e: MouseEvent<HTMLButtonElement>) => void;
    onImport?: (e: MouseEvent<HTMLButtonElement>) => void;
    onExport?: (e:MouseEvent<HTMLButtonElement>) => void;
    isDeleteDisable?: boolean;
    className?: string;
    children?: ReactNode;
    preventEnterPress?: boolean;
    filterStartDate?: string;
    filterEndDate?: string;
}

const Header: FC<props> = ({
    onSearch,
    searchPlaceholder,
    btnText,
    anotherBtnText,
    anotherBtnText2,
    inputRef,
    onBtnClick,
    anotherOnBtnClick,
    anotherOnBtnClick2,
    onDelete,
    onFilter,
    filterCount,
    onImport,
    onExport,
    isDeleteDisable,
    className,
    children,
    preventEnterPress,
    onDownload,
    filterStartDate,
    filterEndDate
}) => {  

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (

    <Box id='header' justifyContent='space-between' className={`center ${className}`}>
        <Box className='h-100'>
            {
                anotherOnBtnClick2 &&
                <Button
                    color='primary'
                    variant='contained'
                    className='add-btn'
                    startIcon={<AddIcon />}
                    onClick={anotherOnBtnClick2}
                >
                    {anotherBtnText2}
                </Button>
            }
            {
                onBtnClick &&
                <Button
                    color='primary'
                    variant='contained'
                    className='add-btn ml-2'
                    startIcon={<AddIcon />}
                    onClick={onBtnClick}
                >
                    {btnText}
                </Button>
            }
            {
                anotherOnBtnClick &&
                <Button
                    color='primary'
                    variant='contained'
                    className='add-btn ml-2'
                    startIcon={<AddIcon />}
                    onClick={anotherOnBtnClick}
                >
                    {anotherBtnText}
                </Button>
            }
            {
                onImport &&
                <Tooltip title='Import'>
                    <Button
                        variant='outlined'
                        className='ml-2 h-100'
                        onClick={onImport}
                        color="primary"
                    >
                    <ImportIcon color="primary" className="import-icon"/>
                    </Button>
                </Tooltip>
            }
            {
                onExport && 
                <Tooltip title="Export">
                    <Button
                    variant='outlined'
                    className='ml-2 h-100'
                    onClick={onExport}
                    
                    >
                        <FileDownload />
                    </Button>
                </Tooltip>
            }
            {
                onDownload && 
                <Tooltip title="Export">
                    <Button
                    variant='outlined'
                    className='ml-2 h-100'
                    onClick={onDownload}
                    color="primary"
                    >
                        <ExportIcon className="export-icon" color="primary"/>
                    </Button>
                </Tooltip>
            }
            {
                onDelete &&

                <Tooltip title='Delete'>
                    <span>
                        <Button
                            color='error'
                            variant='outlined'
                            className='ml-2 h-100'
                            disabled={isDeleteDisable}
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                        </Button>
                    </span>
                </Tooltip>
            }
            {
                onFilter &&
                  <Tooltip title='Filter'>
                      <Badge
                          className="h-100 ml-2"
                          color="primary"
                          badgeContent={filterCount}
                          invisible={!filterCount}
                      >
                          <Button
                              variant='outlined'
                              color={"primary"}
                              onClick={onFilter}
                          >
                              <FilterListIcon color="primary"/>
                          </Button>
                      </Badge>
                  </Tooltip>
            }
            {
                filterStartDate && filterEndDate &&
                <span className="date-range">
                    From {filterStartDate} to {filterEndDate}
                </span>
            }
            {children}
        </Box>
        {onSearch && (
            <Box className="search-box">
                <SearchIcon className="search-icon" />
                <input
                    className="search-input"
                    name="search"
                    ref={inputRef}
                    placeholder={searchPlaceholder}
                    onChange={onSearch}
                    onKeyDown={(event) => {
                        if (preventEnterPress) {
                            handleKeyDown(event);
                        }
                    }}
                />
            </Box>
        )}
    </Box>
);
};

export default Header;