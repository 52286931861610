import Joi from "joi";
import { required } from "../../shared";

export const assessmentMediaValidation = Joi.object({
  name: Joi.string()
    .min(3)
    .max(60)
    .trim()
    .required()
    .label("Name")
    .messages({
      "string.empty": required,
    }),
  imageUrl: Joi
    .string()
    .min(3)
    .trim()
    .required()
    .label("Image")
    .messages({
      "string.empty": required,
    }),
  description: Joi.string()
    .required()
    .min(3)
    .trim()
    .label("Description")
    .messages({
      "string.empty": required,
    }),
});
