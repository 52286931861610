import { FC, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import CallIcon from "@mui/icons-material/Call";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import ShareIcon from "@mui/icons-material/Share";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RateReviewIcon from "@mui/icons-material/RateReview";
import VideocamIcon from "@mui/icons-material/Videocam";
import LaunchIcon from "@mui/icons-material/Launch";
import AddIcon from "@mui/icons-material/Add";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { FileDownload } from "@mui/icons-material";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DragIndicatorIcon from "@mui/icons-material/DragHandle";
import NotesIcon from "@mui/icons-material/Notes";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";
import {ReactComponent as PreOnboardIcon} from "../../assets/images/pre-onboard.svg"; 
import RestartAltIcon from "@mui/icons-material/RestartAlt";

interface props {
    icons: {
        name: string;
        method: () => void,
        disabled?: boolean;
    }[]
}

const GetActions: FC<props> = ({ icons }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActionClick = (method: () => void) => {
        method();
        handleClose();
    };

    return (
        <>
            <IconButton size="small" onClick={handleClick} id="action-ellipsis-button">
                <MoreVertIcon />
            </IconButton>
            <Menu 
                anchorEl={anchorEl} 
                open={open} 
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {icons.map((ele) => (
                    <Tooltip key={ele.name} title={ele.name} placement="left">
                        <span>
                            <MenuItem
                                onClick={() => handleActionClick(ele.method)}
                                disabled={ele.disabled}
                            >
                                <IconButton size="small" color={getActionColor(ele.name)} disabled={ele.disabled}>
                                    {getActionIcon(ele.name)}
                                </IconButton>
                                <span>{ele.name}</span>
                            </MenuItem>
                        </span>
                    </Tooltip>
                ))}
            </Menu>
        </>
    );
};

const getActionIcon = (action: string) => {
    switch (action) {
        case "Export":
            return <FileDownload />;
        case "Edit":
            return <EditIcon />;
        case "List":    
            return <PlaylistAddRoundedIcon sx={{fontSize:28}}/>;
        case "Mail":
            return <EmailIcon />;
        case "SMS":
            return <SmsIcon />;
        case "Call Status":
            return <CallIcon />;
        case "Interview":
            return <InsertInvitationIcon />;
        case "Calendar Invite":
            return <InsertInvitationIcon />;
        case "Send Detail":
            return <PersonIcon />;
        case "Delete":
            return <DeleteIcon />;
        case "Reschedule Interview":
            return <SyncIcon />;
        case "Reopen Job":
            return <SyncIcon />;
        case "Share":
            return <ShareIcon />;
        case "Onboard":
            return <AssignmentIndIcon />;
        case "Add Review":
            return <RateReviewIcon />;
        case "Google Meet":
            return <VideocamIcon />;
        case "Preview":
            return <LaunchIcon />;
        case "Add":
            return <AddIcon />;
        case "Open":
            return <OpenInFullIcon />;
        case "WhatsApp":
            return <WhatsAppIcon />;
        case "View":
            return <VisibilityIcon />;
        case "Clone":
            return <ContentCopyIcon />;
        case "Change Position":
            return <DragIndicatorIcon />;
        case "Notes":
            return <NotesIcon />;
        case "Pre Onboard":
            return <PreOnboardIcon />;
        case "Edit Score":
            return <EditNoteIcon />;
        case "View Analytics":
            return <QueryStatsIcon />;
        case "View Details":
            return <SettingsSuggestIcon />;
        case "Generate": 
            return <RestartAltIcon/>;
        case "Approve":
            return <DoneAllIcon/>;
        case "Reject":
            return <ClearIcon/>;
        default:
            return <ErrorIcon />;
    }
};

const getActionColor = (action: string) => {
    switch (action) {
        case "Export":
            return "primary";
        case "Edit":
            return "primary";
        case "List":
            return "primary";
        case "Mail":
            return "primary";
        case "SMS":
            return "primary";
        case "WhatsApp":
            return "primary";
        case "Call Status":
            return "primary";
        case "Interview":
            return "primary";
        case "Calendar Invite":
            return "primary";
        case "Send Detail":
            return "primary";
        case "Delete":
            return "error";
        case "Reschedule Interview":
            return "primary";
        case "Reopen Job":
            return "primary";
        case "Share":
            return "primary";
        case "Onboard":
            return "primary";
        case "Add Review":
            return "primary";
        case "Google Meet":
            return "primary";
        case "Preview":
            return "primary";
        case "Add":
            return "primary";
        case "Open":
            return "primary";
        case "View":
            return "primary";
        case "Clone":
            return "primary";
        case "Change Position":
            return "primary";
        case "Notes": 
            return "primary";
        case "Pre Onboard":
            return "primary";
        case "Edit Score":
            return "primary";
        case "View Analytics":
            return "primary";
        case "View Details":
            return "primary";
        case "Generate":
            return "primary";
        case "Approve":
            return "primary";
        case "Reject":
            return "error";
        default:
            return "error";
    }
};

export default GetActions;
