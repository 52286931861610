import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { ParameterService } from "../../../services";
import { IPagination } from "../../../interfaces";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { capitalize, createIndex } from "../../../utilities/helper";
import { IParameterRow, IParameter, IParameterState } from "../../../interfaces";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";

const Parameter = () => {
  let rows: IParameterRow[] = [];
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getParameters, deleteParameter } = ParameterService();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IParameterState>({
    deleteWarning: false,
    _parameter: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const parameters = useQuery({
    queryKey: ["allParameters", state.pagination.page, state.filters],
    queryFn: () => getParameters({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (parameters.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: parameters.data.meta.page,
          totalPages: parameters.data.meta.totalPages,
          totalRecords: parameters.data.meta.totalRecords
        }
      }));
    }
  }, [parameters.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_parameter = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _parameter
    }
    ));
  };

  useEffect(() => {
    if (parameters.data && parameters.data.data && parameters.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [parameters.data && parameters.data.data]);
  const onDelete = async () => {
    try {
      const deleted = await deleteParameter({ _id: state._parameter });
      snackbar(deleted.message, "info");
      handleDelete();
      parameters.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "parameter_name",
      label: "Name"
    },
    {
      id: "parameter_parameterType",
      label: "Parameter Type"
    },
    {
      id: "parameter_fieldType",
      label: "Field Type"
    },
    {
      id: "parameter_createdBy",
      label: "Created By"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, parameter: IParameter, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + parameter._id, search: searchParams.toString() }), disabled: resourceAllocate("interview-parameter.write") ? false : true },
        { name: "Delete", method: () => handleDelete(parameter._id), disabled: resourceAllocate("interview-parameter.remove") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      parameter_name: capitalize(parameter.name),
      parameter_parameterType: capitalize(parameter.type),
      parameter_fieldType: capitalize(parameter.fieldType),
      parameter_createdBy: parameter?.createdBy?.name && capitalize(parameter?.createdBy?.name),
      action,
    };
  };

  if (parameters.data?.data.length) {
    rows = parameters.data?.data.map((parameter, i) => createRow(i, parameter, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by name of parameter"
        onSearch={onSearch}
        btnText="ADD PARAMETER"
        onBtnClick={resourceAllocate("interview-parameter.write") ? () => navigate("new") : undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Parameter"
        description="Are you sure you want to delete this parameter?"
      />

      <Outlet context={{ reFetch: parameters.refetch }} />
    </>
  );
};
export default Parameter;

