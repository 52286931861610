import React, { SyntheticEvent, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Autocomplete, AutocompleteChangeReason, Box, Chip, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IErrorResponse, IOutletProps } from "../../../../../interfaces";
import CustomDialog from "../../../../../components/mui/dialog";
import CustomLabel from "../../../../../components/mui/custom-label";
import { capitalize } from "../../../../../utilities/helper";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { SkillItemCategoryService } from "../../../../../services/skill-matrix/skill-items-categories";
import { courseSectionService } from "../../../../../services/configuration/course-catgory";
import { courseConfigurationService } from "../../../../../services/configuration/course";
import { courseConfigurationSectionValidation } from "../../../../../validations/configuration/course-category";
import { ICourseConfigurationFormFelds, ICourseConfigurationSectionField } from "../../../../../interfaces/configuration/course-category";

const ManageCourseSectionConfiguration: React.FC = () => {
  const { courseId, courseSectionId } = useParams<{ courseId: string, courseSectionId: string }>();
  const [searchParams] = useSearchParams();
  const { addCourseSection, updateCourseSection, getCourseSection } = courseSectionService();
  const { getcourseConfiguration } = courseConfigurationService();
  const { getPartialSkillItemCategory } = SkillItemCategoryService();
  const outlet = useOutletContext<IOutletProps>();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();

  const getPartialCourseConfiguration = useQuery({
    queryKey: ["get-partial-course-configuration", courseId],
    queryFn: () => getcourseConfiguration({ _id: courseId }),
    enabled: !!courseId
  });

  const partialCourseConfigurationRaw = getPartialCourseConfiguration.data?.data;

  const skillParameters = partialCourseConfigurationRaw?._skillParameter;

  const getSkillItemCategoryRaw = useQuery({
    queryKey: ["get-skill-category-partial", skillParameters],
    queryFn: () => getPartialSkillItemCategory({ _skillParameter: skillParameters }),
    enabled: !!skillParameters
  });

  const skillCategories = getSkillItemCategoryRaw.data?.data;

  const handleKeyDownNonNumeric = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = (event.target as HTMLInputElement).value;
    if (event.key === " " && currentValue.trim().length === 0) {
      event.preventDefault();
    }
  };

  const onChangeMultipleSelect = (
    event: SyntheticEvent<Element, Event>,
    value: { _id: string; name: string }[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedStatus: string[] = [...getValues("_skillCategory")];
    let newSelectedStatus: string[];
    let removedStatus: string[];

    switch (reason) {
      case "selectOption":
        newSelectedStatus = value.map((status) => status._id);
        newSelectedStatus = newSelectedStatus.filter(
          (status) => !updatedSelectedStatus.includes(status)
        );
        updatedSelectedStatus = [
          ...updatedSelectedStatus,
          ...newSelectedStatus,
        ];
        break;
      case "removeOption":
        removedStatus = value.map((status) => status._id);
        updatedSelectedStatus = updatedSelectedStatus.filter(
          (status) => !removedStatus.includes(status)
        );
        break;
    }
    setValue("_skillCategory", updatedSelectedStatus);
    trigger("_skillCategory");
  };

  const handleMultipleDeleteChip = (deleteElement: string) => {
    const updatedStatus = getValues("_skillCategory").filter((status) => status !== deleteElement);
    setValue("_skillCategory", updatedStatus);
  };
  const hitQuery = !(courseSectionId === "new" || courseSectionId === "view");

  const courseConfiguration = useQuery({
    queryKey: [hitQuery],
    queryFn: () => getCourseSection({ _id: courseSectionId }),
    enabled: hitQuery && !!courseSectionId
  });

  const { handleSubmit, control, setValue,getValues, formState: { errors }, trigger } = useForm<ICourseConfigurationSectionField>({
    resolver: joiResolver(courseConfigurationSectionValidation),
    defaultValues: {
      name: "",
      description: "",
      _skillCategory: [],
    }
  });

  useEffect(() => {
      if (courseConfiguration.data?.data && courseSectionId !== "new") {
        setValue("name", courseConfiguration.data.data.name || "");
        setValue("description", courseConfiguration.data.data.description || "");
        setValue("_skillCategory", courseConfiguration.data.data._skillCategory || [].map((item) => item));
        trigger("_skillCategory");
      }
  }, [skillCategories, courseConfiguration.data]);

  const onSubmit = async (data: ICourseConfigurationSectionField) => {
    try {
      if (courseSectionId === "new") {
        const payload = { ...data, _course: courseId };
        const add = await addCourseSection(payload);
        snackbar(add.message, "info");
      } else {
        const update = await updateCourseSection({ _id: courseSectionId, ...data });
        snackbar(update.message, "info");
      }
      navigate({
        pathname: "/configurations/course/course-section/" + courseId,
        search: searchParams.toString()
      });
      outlet?.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate(-1);
  };

  const fields: ICourseConfigurationFormFelds[] = [
    {
      label: "Name",
      name: "name",
      type: "input",
      placeholder: "Type your course section name here",
      required: true
    },
    {
      label: "Skill Category",
      name: "_skillCategory",
      type: "multipleSearchSelect",
      placeholder: "Search...",
      required: true
    },
    {
      label: "Description",
      name: "description",
      type: "input",
      placeholder: "Type your description here",
      required: true
    },
  ];

  return (
    <Box>
      <CustomDialog
        size="md"
        title={courseSectionId !== "new" ? "Edit Course Section" : "Add Course Section"}
        isOpen={!!courseSectionId}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {fields.map((field) => {
            if (field.name === "description") {
              return (
                <Grid key={field.label} item xs={12} md={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        inputProps={{ maxLength: 100 }}
                        onKeyDown={handleKeyDownNonNumeric}
                        onChange={onChange}
                        value={value}
                        multiline
                        minRows={5}
                      />
                    )}
                  />
                </Grid>
              );
            }
            else if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={6} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        inputProps={{ maxLength: 30 }}
                        onKeyDown={handleKeyDownNonNumeric}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "multipleSearchSelect") {
              return (
                <Grid key={field.label} item xs={6} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <>
                        <Autocomplete
                          className="disable-text"
                          options={
                            (skillCategories) ||
                            [].map((element) => element)
                          }
                          getOptionLabel={(option) => capitalize(option.name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label={
                                <CustomLabel
                                  label={field.label}
                                  required={field?.required}
                                />
                              }
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                            />
                          )}
                          {...prop.field}
                          value={[]}
                          onChange={onChangeMultipleSelect}
                          multiple
                        />
                        {getValues("_skillCategory").map((element) => (
                          <Chip
                            key={element}
                            label={
                              (skillCategories || []).find(
                                (category) => category._id === element
                              )?.name
                            }
                            color="primary"
                            variant="outlined"
                            onDelete={() => handleMultipleDeleteChip(element)}
                            sx={{ margin: "5px" }}
                          />
                        ))}
                      </>
                    )}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageCourseSectionConfiguration;