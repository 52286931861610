import { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
	ClassicEditor,
    Base64UploadAdapter,
    List,
	ListProperties,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	Bold,
	CKBox,
	CloudServices,
	Code,
	CodeBlock,
	Essentials,
	GeneralHtmlSupport,
	Heading,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Italic,
	Link,
	LinkImage,
	Paragraph,
	PictureEditing,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	Underline,
	Undo
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { IErrorResponse } from "../../../../../interfaces";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import CustomDialog from "../../../../../components/mui/dialog";
import { Box, Grid } from "@mui/material";
import HtmlDiff from "htmldiff-js";
import "./style.scss";
import { GlossaryContentService } from "../../../../../services/glossary";

interface outletProps {
    reFetchContent: () => void;
    reFetchVersions: () => void;
    getPreviousVersionData: string;
    getCurrentVersionData: string;
}

export interface IContent {
    content: string | undefined;
}

const ViewVersionContent = () => {
    const { id,contentId } = useParams();

    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();

    const { updateContentVersion } = GlossaryContentService();
    const { handleSubmit,watch, setValue } = useForm<IContent>({
        defaultValues: {
            content: "",
        }
    });
    const computeDiff = (oldContent: string, newContent: string) => {
        const normalizedOldContent = oldContent.replace(/&nbsp;/g, "");
        const normalizedNewContent = newContent.replace(/&nbsp;/g, "");
        let diffs = HtmlDiff.execute(normalizedOldContent, normalizedNewContent);
        diffs = diffs.replace(/<ins(.*?)>/g, "<ins class='diff-added'$1>");
        diffs = diffs.replace(/<del(.*?)>/g, "<del class='diff-removed'$1>");
        diffs = diffs.replace(/<ins[^>]*><img(.*?)><\/ins>/g, "<ins class='diff-added'><img$1 class='diff-added-image' /></ins>");
        diffs = diffs.replace(/<del[^>]*><img(.*?)><\/del>/g, "<del class='diff-removed'><img$1 class='diff-removed-image' /></del>");
        return diffs; 
    };

     useEffect(() => {
        const previousContent = outlet?.getPreviousVersionData || "";
        const currentContent = outlet?.getCurrentVersionData || "";
        const diffContent = computeDiff(previousContent, currentContent);
        setValue("content", diffContent);
    }, [outlet?.getPreviousVersionData, outlet?.getCurrentVersionData]);

    const onSubmit = async () => {
        try {
            const content = await updateContentVersion({ _id: contentId });
            snackbar(content.message, "info");
            outlet?.reFetchContent && outlet.reFetchContent();
            outlet?.reFetchVersions && outlet.reFetchVersions();
            onClose();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            outlet?.reFetchContent && outlet.reFetchContent();
            outlet?.reFetchVersions && outlet.reFetchVersions();
        }
    };

    const onClose = () => {
        navigate({
            pathname: `/glossary/manage/${id}`,
            search: searchParam.toString()
        });
    };


    return (
        <>
            <Box>
                <CustomDialog
                    title={"Glossary Content"}
                    isOpen={!!contentId}
                    onClose={onClose}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={watch("content")}
                                config={{
                                    plugins: [
                                        Alignment,
                                        Autoformat,
                                        Base64UploadAdapter,
                                        List,
                                        ListProperties,
                                        AutoImage,
                                        AutoLink,
                                        Autosave,
                                        Bold,
                                        CKBox,
                                        CloudServices,
                                        Code,
                                        CodeBlock,
                                        Essentials,
                                        GeneralHtmlSupport,
                                        Heading,
                                        HtmlEmbed,
                                        ImageBlock,
                                        ImageCaption,
                                        ImageInline,
                                        ImageInsert,
                                        ImageInsertViaUrl,
                                        ImageResize,
                                        ImageStyle,
                                        ImageTextAlternative,
                                        ImageToolbar,
                                        ImageUpload,
                                        Italic,
                                        Link,
                                        LinkImage,
                                        Paragraph,
                                        PictureEditing,
                                        SelectAll,
                                        ShowBlocks,
                                        SourceEditing,
                                        Table,
                                        TableCaption,
                                        TableCellProperties,
                                        TableColumnResize,
                                        TableProperties,
                                        TableToolbar,
                                        TextTransformation,
                                        Underline,
                                        Undo
                                    ],
                                    htmlSupport: {
                                        allow: [
                                            {
                                                name: /^.*$/,
                                                styles: true,
                                                attributes: true,
                                                classes: true
                                            }
                                        ]
                                    },
                                }}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>

                </CustomDialog>
            </Box>
        </>
    );

};

export default ViewVersionContent;