import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { IPartsOfFunnel, IPartsOfFunnelRows, IPartsOfFunnelStatus } from "../../../interfaces/content/parts-of-funnel";
import useDebounce from "../../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import PartsOfFunnelService from "../../../services/content/parts-of-funnel";
import { useQuery } from "@tanstack/react-query";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { IColumn, IErrorResponse, IPagination } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import { createIndex } from "../../../utilities/helper";
import { Box, Checkbox, Typography } from "@mui/material";
import { handleSelect, handleSelectAll } from "../common/helper";
import useResource from "../../../hooks/useResource";
const PartsOfFunnel = () => {

    const [search, setSearch] = useState("");
    const { getContentPartsOfFunnels, deleteContentPartsOfFunnel } = PartsOfFunnelService();
    const [openState, setOpenState] = useState(false);
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { resourceAllocate } = useResource();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState<IPartsOfFunnelStatus>({
        multiDeleteWarning: false,
        selectAll: [],
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1,
            totalRecords: 0
        },
        searching: {
            search: ""
        },
        deleteWarning: false,
        _deleteId: ""
    });

    const partsOfFunnel = useQuery({
        queryKey: ["partsOfFunnel", state.pagination.page, state.searching],
        queryFn: () => getContentPartsOfFunnels({
            ...state.searching,
            pagination: true,
            page: state.pagination.page,
            limit: state.pagination.limit
        })
    });

    useEffect(() => {
        if (partsOfFunnel?.data?.data?.length) {
            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: partsOfFunnel?.data?.meta?.page,
                    totalPages: partsOfFunnel?.data?.meta?.totalPages,
                    totalRecotds: partsOfFunnel?.data?.meta?.totalRecords
                }
            }));
        }

    }, [partsOfFunnel?.data?.meta]);

    useEffect(() => {
        if (partsOfFunnel.data && partsOfFunnel.data.data && partsOfFunnel.data.data.length === 0) {
          setState((prevState) => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
              totalPages: 1,
              totalRecords: 0,
            },
          }));
        }
      }, [partsOfFunnel.data && partsOfFunnel.data.data]);


    // searching functionality starts here
    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
    const searchRecord = useDebounce(search, 1000);
    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }

    }, [searchRecord]);
    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                page: page
            },
            searching: {
                ...prev.searching,
                search: search
            }
        }));

    }, [searchParams]);

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };


    const isChecked = (partsOfFunnel.data?.data?.length && state.selectAll?.length === partsOfFunnel.data?.data?.length) ? true : false;
    const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(partsOfFunnel.data?.data.length)) ? true : false;
    const columns: IColumn[] = [
        {
            id: "all",
            label: <Checkbox onChange={e => handleSelectAll(e, partsOfFunnel?.data?.data ? partsOfFunnel?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />,
            maxWidth: 0
        },
        {
            id: "name",
            label: "Parts of funnel",
            minWidth: 200
        },
        {
            id: "action",
            label: "Actions",
            align: "right"
        }
    ];

    const handleDelete = (_deleteId = "") => {
        setState((prevState) => ({
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
            _deleteId: _deleteId
        }));
    };

    const onDelete = async () => {
        try {
            const response = await deleteContentPartsOfFunnel({ ids: [state._deleteId] });
            setState(prevState => ({
                ...prevState,
                selectAll : prevState.selectAll.filter((del) => del !== state._deleteId)
            }));
            handleDelete();
            snackbar(response?.message, "info");
            partsOfFunnel.refetch();
            navigate(
                {pathname : "/content/parts-of-funnel",
                search: searchParams.toString()}
            );

        } catch (error) {
            const err = error as IErrorResponse;
            console.log("Error in delete sub-category", err);
            snackbar(err?.data?.message, "error");
        }
    };
    const handleMultiDelete = () => setState(prevState => ({
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning
    }));
    const onMultiDelete = async () => {
        try {
            const goal = await deleteContentPartsOfFunnel({ ids: state.selectAll });
            snackbar(goal.message, "info");
            partsOfFunnel.refetch();
            setState(prevState => ({
                ...prevState,
                multiDeleteWarning: false,
                selectAll: []
            }));
            navigate(
                {pathname : "/content/parts-of-funnel",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            handleMultiDelete();
            console.log({ "Error in delete project": error });
        }
    };
    const createRows = (items: IPartsOfFunnel, selectedAll: string[], index: number, pagination: IPagination) => {

        const action = <GetActions
            icons={[
                {
                    name: "Edit", method: () => {
                        setOpenState(true);
                        navigate({
                            pathname: "" + items._id,
                            search: searchParams.toString()
                        });
                    },
                    disabled: resourceAllocate("cms-funnel-parts.write") ? false : true

                },
                { name: "Delete", method: () => handleDelete(items._id), disabled: resourceAllocate("cms-funnel-parts.remove") ? false : true },
            ]}
        />;
        return {
            all: <Checkbox onChange={e => handleSelect(e, items._id, state, setState)} checked={selectedAll.includes(items._id)} />,
            id: createIndex(pagination, index),
            name: items?.name,
            action
        };
    };


    let rows: IPartsOfFunnelRows[] = [];

    if (partsOfFunnel?.data?.data?.length) {
        rows = partsOfFunnel?.data?.data?.map((item: IPartsOfFunnel, index: number) => createRows(item, state.selectAll, index, state.pagination));

    }


    const openManageComponent = () => {
        setOpenState(true);
        navigate("/content/parts-of-funnel/new");
    };

    const openManageImport = () => {
        setOpenState(true);
        navigate("/content/parts-of-funnel/import");
    };

    return (
        <div>
            {openState && <Outlet context={{ ...Outlet, reFetch: partsOfFunnel.refetch }} />}
            <Header
                className="my-2"
                searchPlaceholder="Search by name"
                onSearch={onSearch}
                onDelete={resourceAllocate("cms-funnel-parts.remove") ? handleMultiDelete : undefined}
                isDeleteDisable={state.selectAll.length ? false : true}
                btnText="Add Parts Of Funnel"
                onBtnClick={resourceAllocate("cms-funnel-parts.write") ? () => openManageComponent() : undefined}
                onImport={resourceAllocate("cms-funnel-parts.write") ? () => openManageImport() : undefined}
            />
            <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1">Total Parts Of Funnel:</Typography>
                    <Typography className="ml-3" variant="body1" >{partsOfFunnel?.data?.meta?.totalRecords}</Typography>
                </Box>
            </Box>

            <CustomTable
                rows={rows}
                columns={columns}
                height="calc(100vh - 285px)"
                pagination={state.pagination}
                onPageChange={onPageChange}
            />

            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete Part of funnel"
                description="Are you sure you want to delete this part of funnel?"
            />
            {/* Multiple Delete  */}
            <WarningDialog
                isOpen={state.multiDeleteWarning}
                onClose={() => handleMultiDelete()}
                onConfirm={onMultiDelete}
                title="Delete All part of funnel"
                description={`Are you sure you want to delete ${state.selectAll.length} selected part of funnel ?`}
            />

        </div>
    );
};

export default PartsOfFunnel;