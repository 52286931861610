import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import {
  IKrRequest,
  IUnderReviewRequestsRow,
  IUnderReviewRequestsState,
} from "../../../../interfaces/okr";
import { KrRequestsService } from "../../../../services/okr/self-assessment";
import useDebounce from "../../../../hooks/useDebounce";
import useSnackbar from "../../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../../interfaces";
import GetActions from "../../../../components/get-actions";
import useResource from "../../../../hooks/useResource";
import WarningDialog from "../../../../components/mui/warning-dialog";
import { capitalize, formatDate } from "../../../../utilities/helper";
import OkrSelfAssessmentFilters from "../filters";

interface outletProps {
  refetch: () => void;
}

const UnderReviewRequests = () => {
  let rows: IUnderReviewRequestsRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const outlet = useOutletContext<outletProps>();
  const { getUnderReviewRequests, deleteRequest } = KrRequestsService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IUnderReviewRequestsState>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    _request: "",
    deleteWarning: false,
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
    },
    filterCount: 0
  });

  const pendingKRs = useQuery({
    queryKey: ["all-under-review-krs", state.pagination.page, state.filters],
    queryFn: () =>
      getUnderReviewRequests({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        startDate: state.filters.date?.startDate,
        endDate: state.filters.date?.endDate
      }),
  });
  const data = pendingKRs.data?.data;

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (pendingKRs.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: pendingKRs.data.meta.page,
          totalPages: pendingKRs.data.meta.totalPages,
          totalRecords: pendingKRs.data.meta.totalRecords,
        },
      }));
    }
  }, [pendingKRs.data?.meta]);

  useEffect(() => {
    if (data?.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page:
            prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [data]);

  const onDelete = async () => {
    try {
      const deleted = await deleteRequest({ _id: state._request });
      snackbar(deleted.message, "info");
      handleDelete();
      pendingKRs.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };
  const handleDelete = (_request = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _request,
    }));
  };

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const dateFilter: {
        key: string;
        value: string;
        startDate: string;
        endDate: string;
      }[] = searchParams.get("date")
        ? JSON.parse(String(searchParams.get("date")))
        : [];

    filterCount += dateFilter.length ? 1 : 0;

    let date:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (dateFilter?.length) {
      date = {
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        search,
        date
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const columns = [
    {
      id: "keyResult",
      label: "Key Result",
    },
    {
      id: "objective",
      label: "Objective",
    },
    {
      id: "targetFigure",
      label: "Target Figure",
    },
    {
      id: "achievedValue",
      label: "Achieved Value",
    },
    {
      id: "endDate",
      label: "End Date",
    },
    {
      id: "submittedOn",
      label: "Submitted On",
    },
    {
      id: "actions",
      label: "Actions",
    }
  ];

  const createRow = (index: number, data: IKrRequest) => {
    const actions = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + `${data._keyResult._id}/${data._id}`,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("okr-self-evaluation.write"), 
          },
          {
            name: "Delete",
            method: () => handleDelete(data._id),
            disabled: !resourceAllocate("okr-self-evaluation.remove"),
          },
        ]}
      />
    );

    return {
      keyResult: capitalize(data?._keyResult?.name),
      objective: capitalize(data?._objective?.name),
      createdBy: capitalize(data?.createdBy?.name),
      targetFigure: data?.targetFigure,
      achievedValue: data?.achievedValue,
      endDate: formatDate(data?._keyResult?.endDate),
      submittedOn: formatDate(data?.submittedOn),
      actions,
    };
  };

  if (data && data.length) {
    rows = data.map((data, i) => createRow(i, data));
  }

  return (
    <div>
      <Header
        className="my-2"
        searchPlaceholder="Search by KR or Objective name"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <OkrSelfAssessmentFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="There are no pending requests."
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Request"
        description="Are you sure you want to delete this request?"
      />

      <Outlet context={{ ...outlet, reFetch: pendingKRs.refetch }} />
    </div>
  );
};

export default UnderReviewRequests;
