import Joi from "joi";
import { required, valid_string } from "../../../shared";

export const candidateInterviewValidation = Joi.object({
    interview_round: Joi.string()
        .valid("HR SCHEDULED", "TA SCHEDULED" ,"ROHIT SCHEDULED")
        .required()
        .label("Interview Round")
        .messages({
            "any.only": valid_string
        }),
    _interviewer: Joi.string()
        .label("Interviewer")
        .required()
        .messages({
            "string.empty": valid_string,
        }),

    emailText: Joi.string()
        .label("Interviewer")
        .allow("")
        .messages({
            "string.empty": required,
        }),
    attendees: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("Attendees"),
    template: Joi.string()
        .label("Calendar Template")
        .required()
        .messages({
            "string.empty": valid_string,
        }),
    interviewDateTime: Joi.string()
        .label("Schedule Date and Time")
        .required()
        .messages({
            "string.empty": required,
        }),
    googleTemplate: Joi.object({
        subject: Joi.string(),
        content: Joi.string()

    }),
     duration: Joi.number()
            .required()
            .label("Interview Duration")
            .messages({
                "number.empty": required
    }),
});
