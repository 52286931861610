import "./style.scss";
import { useEffect, useState, MouseEvent } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import CustomTable from "../../../../components/mui/table";
import { BarGraph } from "../../../../components/graphs";
import Tag from "./tag";
import { TeamEvaluationOverviewService } from "../../../../services/pms/team-evaluation";
import { format, addMonths, addQuarters } from "date-fns";
import { IAnalyticsState, IReporteeRating, ITeamEvaluationFilterState, ITeamRating, ITeamRatingRows } from "../../../../interfaces/pms/team-evaluation";
import { IReportFilterDialog } from "../../../../interfaces";
import { PmsParametersService } from "../../../../services/pms/pms-parameters";
import { capitalize, formatDate } from "../../../../utilities/helper";
import { Badge, Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import TeamEvaluationFilters from "./filters";

const barGraphData = (
  data: IReporteeRating[] | undefined,
  startDate: string | number | Date,
  endDate: string | number | Date,
  period: string
) => {
  const periods: string[] = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (period === "Monthly") {
    let current = start;
    while (current <= end) {
      periods.push(format(current, "yyyy-MM"));
      current = addMonths(current, 1);
    }
  } else if (period === "Quarterly") {
    let current = start;
    while (current <= end) {
      const quarter = Math.ceil((current.getMonth() + 1) / 3);
      periods.push(`${current.getFullYear()}-Q${quarter}`); 
      current = addQuarters(current, 1);
    }
  }

  const mappedData = {
    labels: periods,
    datasets: [
      {
        label: "Direct Reportees",
        data: periods.map((period) => {
          const matchingData = data?.find(
            (item) => item.groupingField === period
          );
          return Number(matchingData?.DirectReportees?.averageRating.toFixed(2)) || 0;
        }),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Indirect Reportees",
        data: periods.map((period) => {
          const matchingData = data?.find(
            (item) => item.groupingField === period
          );
          return Number(matchingData?.IndirectReportees?.averageRating.toFixed(2)) || 0;
        }),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };
  return mappedData; 
};

const TeamEvaluationOverview = () => {
  let rows: ITeamRatingRows[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersCount, setFiltersCount] = useState(0);
  const { getPmsParameters } = PmsParametersService();
  const {getTeamEvaluationOverview, getReporteesRatingAnalytics, getParameterWiseTeamReport} = TeamEvaluationOverviewService();
  const [selectedParameter, setSelectedParameter] = useState({
    id: "all",
    name: "All Parameters",
  });

  const pmsParameters = useQuery({
    queryKey: ["all-pms-parameters-data"],
    queryFn: () => getPmsParameters({ pagination: false }),
  });

  const pmsParametersData = (pmsParameters && pmsParameters.data && pmsParameters.data.data) || [];

  const [filters, setFilters] = useState<ITeamEvaluationFilterState>({createdAt: undefined, _parameter: [], _jobPosition: [], _role: []});


  const teamEvaluationOverview = useQuery({
    queryKey: ["teamEvaluationOverview", filters],
    queryFn: () => getTeamEvaluationOverview({
        startDate: filters && filters.createdAt && filters.createdAt.startDate,
        endDate: filters && filters.createdAt && filters.createdAt.endDate,
        _parameter: filters && filters._parameter,
        _role: filters && filters._role,
        _jobPosition: filters && filters._jobPosition
      }),
  });
  const ratings =
    teamEvaluationOverview &&
    teamEvaluationOverview.data &&
    teamEvaluationOverview.data.data;

  const paramterWiseTeamRatingData = useQuery({
    queryKey: ["paramterWiseTeamRatingData", filters, selectedParameter.id],
    queryFn: () =>
      getParameterWiseTeamReport({
        _parameter:
          selectedParameter.id === "all" ? undefined : selectedParameter.id,
        startDate: filters && filters.createdAt && filters.createdAt.startDate,
        endDate: filters && filters.createdAt && filters.createdAt.endDate,
        _role: filters && filters._role,
        _jobPosition: filters && filters._jobPosition
      }),
  });
  const teamData =
    paramterWiseTeamRatingData &&
    paramterWiseTeamRatingData.data &&
    paramterWiseTeamRatingData.data.data;

  const columns = [
    {
      id: "user",
      label: "User",
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "rating",
      label: "Rating",
    },
  ];

  const createRow = (index: number, rating: ITeamRating) => ({
    user: capitalize(rating.user),
    role: capitalize(rating.role),
    rating: Number(rating.averageScore.toFixed(2)),
  });

  if (teamData && teamData.length) {
    rows = teamData.map((test, index) => createRow(index, test));
  }

  const [state, setState] = useState<IAnalyticsState>({
    period: "Monthly",
    analytics: {
      labels: [],
      data: [],
    },
  });

  const [filterState, setFilterState] = useState<IReportFilterDialog>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
  });

  useEffect(() => {
    let filterCount = 0;
    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];
    const _jobPosition: { key: string; value: string }[] = searchParams.get("_jobPosition")
      ? JSON.parse(String(searchParams.get("_jobPosition")))
      : [];
    const _role: { key: string; value: string }[] = searchParams.get("_role")
      ? JSON.parse(String(searchParams.get("_role")))
      : [];
    const _parameter: { key: string; value: string }[] = searchParams.get("_parameter")
      ? JSON.parse(String(searchParams.get("_parameter")))
      : [];
    filterCount += _role.length ? 1 : 0;
    filterCount += _jobPosition.length ? 1 : 0;
    filterCount += _parameter.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    let createdAt:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }
    setFiltersCount(filterCount);
    setFilters((prevState) => ({
      ...prevState,
      createdAt,
      _parameter: _parameter.map((_parameter) => _parameter.key),
      _jobPosition: _jobPosition.map((_jobPosition) => _jobPosition.key),
      _role: _role.map((_role) => _role.key),
    }));
  }, [searchParams]);


  const getRatingAnalyticsData = useQuery({
    queryKey: [state.period, "bar-graph-data", filters],
    queryFn: () =>
      getReporteesRatingAnalytics({
        period: state.period,
        startDate: filters && filters.createdAt && filters.createdAt.startDate,
        endDate: filters && filters.createdAt && filters.createdAt.endDate,
        _parameter: filters && filters._parameter,
        _role: filters && filters._role,
        _jobPosition: filters && filters._jobPosition
      }),
    enabled: !!(filters && filters.createdAt && filters.createdAt.startDate && filters.createdAt.endDate)
  });

  const reporteesRating =
    getRatingAnalyticsData &&
    getRatingAnalyticsData.data &&
    getRatingAnalyticsData.data.data;

  const graphData = barGraphData(
    reporteesRating,
    (filters && filters.createdAt && filters.createdAt.startDate) || "",
    (filters && filters.createdAt && filters.createdAt.endDate) || "",
    state.period
  );

  const onChange = async (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const anchorElement = e.currentTarget;

    setFilterState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: anchorElement,
        isOpen: !filterState.filterDialog.isOpen,
      },
    }));
  };

  const closeFilter = () => {
    setFilterState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  useEffect(() => {
    if (!searchParams.get("date")) {
      const today = new Date();
      const lastYear = new Date();
      lastYear.setFullYear(today.getFullYear() - 1);

      const defaultDateFilter = [
        {
          key: "custom",
          value: "Custom",
          startDate: lastYear.toISOString(),
          endDate: today.toISOString(),
        },
      ];

      searchParams.set("date", JSON.stringify(defaultDateFilter));
      setSearchParams(searchParams);
    }
  }, []);

  const handleParameterChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setSelectedParameter({ id: value, name: name });
  };

  return (
    <div className="team-evaluation">
      <Box className="team-evaluation-overview">
        <Typography
          className="head"
          variant="h6"
          marginBottom="10px"
          style={{ fontWeight: 700 }}
        >
          Overview Ratings
        </Typography>
        <Box className="tab-header">
          <Box className="tab-header-box">
            <Box display="flex" alignItems="center" className="filter-box">
              <Box className="date-range">
                From {formatDate(filters && filters.createdAt && filters.createdAt.startDate)} to {formatDate(filters && filters.createdAt && filters.createdAt.endDate)}
              </Box>
              <Tooltip title="Filter">
                <Badge
                  className="h-100 ml-2"
                  color="primary"
                  badgeContent={filtersCount}
                  invisible={!filtersCount}
                >
                  <Button
                    className={
                      filtersCount ? "filter active" : "filter default"
                    }
                    variant="outlined"
                    onClick={openFilter}
                  >
                    <FilterListIcon />
                  </Button>
                </Badge>
              </Tooltip>
            </Box>
            <TeamEvaluationFilters
              anchorEl={filterState.filterDialog.anchorEl}
              isOpen={filterState.filterDialog.isOpen}
              OnClose={closeFilter}
            />
          </Box>
        </Box>
      </Box>
      <Box className="team-overview-tabs">
        <Tag name="Team`s Average Rating" count={ratings?.teamAverage || 0} message={"average performance rating for the entire team."}/>
        <Tag
          name="Company`s Average Rating"
          count={ratings?.overallComapnyAverage || 0}
          message={"average performance rating across the company."}
        />
        <Tag name="Median Rating" count={ratings?.median || 0} message={"middle rating value for the team, representing overall balance."}/>
        <Tag
          name="Highest Performer"
          count={
            Number(ratings?.result?.highest?.averageRatingOverall.toFixed(2)) ||
            0
          }
          user={ratings?.result?.highest?.user && ratings?.result?.highest?.user.name}
          message={"team member with the highest performance rating."}
        />
        <Tag
          name="Lowest Performer"
          count={
            Number(ratings?.result?.lowest?.averageRatingOverall.toFixed(2)) ||
            0
          }
          user={ratings?.result?.lowest?.user && ratings?.result?.lowest?.user.name}
          message={"team member with the lowest performance rating."}
        />
      </Box>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <Box
            className="center"
            marginBottom="15px"
            justifyContent="space-between"
          >
            <>
              <Typography
                className="head"
                variant="h6"
                marginBottom="10px"
                style={{ fontWeight: 700 }}
              >
                Overall Performance
              </Typography>
            </>
            <div className="date-picker position-relative">
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  size="small"
                  name="period"
                  value={state.period}
                  onChange={onChange}
                >
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Quarterly">Quarterly</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>
          <BarGraph data={graphData} isStacked={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className="center"
            marginBottom="18px"
            justifyContent="space-between"
          >
            <div>
              <Typography
                className="head"
                variant="h6"
                marginBottom="10px"
                style={{ fontWeight: 700 }}
              >
                Parameter Wise Rating
              </Typography>
            </div>
            <Box
              display="flex"
              alignItems="center"
              className="mr-3"
              marginLeft={"auto"}
            >
              <Select
                value={selectedParameter.id}
                onChange={handleParameterChange}
                size="small"
              >
                <MenuItem value="all">All Paramters</MenuItem>
                {pmsParametersData.map((parameter) => (
                  <MenuItem key={parameter._id} value={parameter._id}>
                    {parameter.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <CustomTable columns={columns} rows={rows} height={340 + 75} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TeamEvaluationOverview;
