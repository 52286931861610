import { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useQuery } from "@tanstack/react-query";
import { SkillItemCategoryService } from "../../../../../services/skill-matrix/skill-items-categories";
import { courseConfigurationService } from "../../../../../services/configuration/course";
import { MenuItem } from "../../../../../components/shared/filter";
import { capitalize } from "../../../../../utilities/helper";
import { props } from "../../../../../interfaces";
import { ICourseConfigurationSectionFilters } from "../../../../../interfaces/configuration/course-category";
import useResource from "../../../../../hooks/useResource";

const CourseConfigurationSectionFilters: FC<props> = ({ anchorEl, isOpen, OnCloseFilter }) => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const { resourceAllocate } = useResource();
   const { courseId } = useParams<{ courseId: string }>();
   const { getcourseConfiguration } = courseConfigurationService();
   const partialCourseConfiguration  = useQuery({
    queryKey: ["course-configuration-partial-filters", courseId],
    queryFn: () => getcourseConfiguration({ _id: courseId }),
    enabled: !!isOpen
  });
  const skillParamId = partialCourseConfiguration.data?.data?._skillParameter;

   const {getPartialSkillItemCategory} = SkillItemCategoryService();
    const skillParams  = useQuery({
      queryKey: ["skill-category-partial-filters", skillParamId],
      queryFn: () => getPartialSkillItemCategory({ _skillParameter:skillParamId }),
      enabled: !!skillParamId
    });

   const [state, setState] = useState<ICourseConfigurationSectionFilters>({
       selectedMenu: 0,
       _skillCategory: [],
       activeStatus: []
   });

   const activeStatuses = [
     { _id: true, name: "Active" },
     { _id: false, name: "Inactive" },
   ];

   useEffect(() => {
       const _skillCategory: { key: string, value: string }[] = searchParams.get("_skillCategory") ? JSON.parse(String(searchParams.get("_skillCategory"))) : [];
       const activeStatus: { key: string, value: string }[] = searchParams.get("activeStatus") ? JSON.parse(String(searchParams.get("activeStatus"))) : [];
       
       setState(prevState => ({
           ...prevState,
           _skillCategory,
           activeStatus
       }));

   }, [searchParams]);

   const handleLeftListItem = (
       event: React.MouseEvent<HTMLDivElement, MouseEvent>,
       index: number,
   ) => {
       setState(prevState => ({
           ...prevState,
           selectedMenu: index
       }));
   };

   const handleRightListItem = (name: "_skillCategory"|"activeStatus" , key: string|boolean, value: string) => {
       let payload: Array<{
           key: string|boolean;
           value: string;
       }> = [];
       const isExist = !!state[name].find(ele => ele.key === key);
       if (isExist) {
           payload = state[name].filter(ele => ele.key !== key);
       } else {
           payload = state[name];
           payload.push({
               key,
               value
           });
       }

       setState(prevState => ({
           ...prevState,
           [name]: payload
       }));
   };

   const deleteChip = (name: "_skillCategory"|"activeStatus", key: string) => {
       let payload: Array<{
           key: string;
           value: string;
       }> = [];
       payload = state[name].filter(ele => ele.key !== key);
       setState(prevState => ({
           ...prevState,
           [name]: payload
       }));
   };

   const resetFilter = () => {
       setState({
           selectedMenu: 0,
           _skillCategory: [],
           activeStatus: []
       });
   };

   const onApply = () => {
     searchParams.set("_skillCategory", JSON.stringify(state._skillCategory));
     searchParams.set("activeStatus", JSON.stringify(state.activeStatus));

     searchParams.set("page", "1");
     navigate({
       pathname: "/configurations/course/course-section/" + courseId,
       search: searchParams.toString(),
     });
     OnCloseFilter();
   };

   const onClose = () => {
       
       const _skillCategory: { key: string, value: string}[] = searchParams.get("_skillCategory") ? JSON.parse(String(searchParams.get("_skillCategory"))) : [];
       const activeStatus: { key: string, value: string}[] = searchParams.get("activeStatus") ? JSON.parse(String(searchParams.get("activeStatus"))) : [];


       setState(prevState => ({
           ...prevState,
           _skillCategory,
           activeStatus,
       }));
       OnCloseFilter();
   };

   return (
     <>
       <Menu
         id="basic-menu"
         anchorEl={anchorEl}
         open={isOpen}
         onClose={onClose}
         MenuListProps={{
           "aria-labelledby": "basic-button",
         }}
       >
         <Box id="filters-container">
           <Box
             className="center mb-3"
             justifyContent="space-between"
             alignItems="start"
           >
             <div className="active-filter mb-1">
               {state._skillCategory.length || state.activeStatus.length ? (
                 <>
                   {state._skillCategory.map((ele) => (
                     <Chip
                       key={ele.key}
                       className="m-1"
                       color="primary"
                       onDelete={() => deleteChip("_skillCategory", ele.key)}
                       label={ele.value}
                       variant="outlined"
                     />
                   ))}

                   {state.activeStatus.map((ele) => (
                     <Chip
                       key={ele.key}
                       className="m-1"
                       color="primary"
                       onDelete={() => deleteChip("activeStatus", ele.key)}
                       label={ele.value}
                       variant="outlined"
                     />
                   ))}
                 </>
               ) : (
                 <Box className="mt-2" display="flex" alignItems="center">
                   <FilterAltOffIcon />
                   <Typography className="ml-2">
                     No filters are applied
                   </Typography>
                 </Box>
               )}
             </div>
             <IconButton
               onClick={OnCloseFilter}
               style={{ marginRight: "-10px" }}
             >
               <CloseIcon />
             </IconButton>
           </Box>

           <Grid className="filter-box" container>
             <Grid id="left" item xs={5}>
               <List component="nav">
                {
                  resourceAllocate("skill-matrix-category.read") && 
                  <MenuItem
                    index={0}
                    label="Skill Category"
                    selectedMenu={state.selectedMenu === 0}
                    onChange={handleLeftListItem}
                    count={state._skillCategory}
                  />
                }
                 <MenuItem
                   index={1}
                   label="Status"
                   selectedMenu={state.selectedMenu === 1}
                   onChange={handleLeftListItem}
                   count={state.activeStatus}
                 />
               </List>
             </Grid>
             <Divider orientation="vertical" />

             <Grid id="right" item xs={6}>
               <List component="nav">
                 {state.selectedMenu === 0 &&
                   skillParams?.data?.data.map((_skillCategory) => (
                     <ListItemButton
                       key={_skillCategory._id}
                       selected={
                         !!state._skillCategory.find(
                           (ele) => ele.key === _skillCategory._id
                         )
                       }
                       onClick={() =>
                         handleRightListItem(
                           "_skillCategory",
                           _skillCategory._id,
                           capitalize(_skillCategory.name)
                         )
                       }
                     >
                       <ListItemText primary={_skillCategory.name} />
                       <Checkbox
                         edge="end"
                         checked={
                           !!state._skillCategory.find(
                             (ele) => ele.key === _skillCategory._id
                           )
                         }
                       />
                     </ListItemButton>
                   ))}

                 {state.selectedMenu === 1 &&
                   activeStatuses.map((activeStatus) => (
                     <ListItemButton
                       key={activeStatus.name}
                       selected={
                         !!state.activeStatus.find(
                           (ele) => Boolean(ele.key) === activeStatus._id
                         )
                       }
                       onClick={() =>
                         handleRightListItem(
                           "activeStatus",
                           activeStatus._id,
                           activeStatus.name
                         )
                       }
                     >
                       <ListItemText primary={activeStatus.name} />
                       <Checkbox
                         edge="end"
                         checked={
                           !!state.activeStatus.find(
                             (ele) => Boolean(ele.key) === activeStatus._id
                           )
                         }
                       />
                     </ListItemButton>
                   ))}
               </List>
             </Grid>
           </Grid>
           <Box className="actions-btn" marginTop="8px" textAlign="end">
             <Button
               variant="outlined"
               color="error"
               onClick={() => resetFilter()}
             >
               Clear All
             </Button>
             <Button className="ml-2" onClick={onApply}>
               Apply
             </Button>
           </Box>
         </Box>
       </Menu>
     </>
   );
};


export default CourseConfigurationSectionFilters;
