import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { capitalize } from "../../../../../../../utilities/helper";
import { TextField, Grid, Typography, Box, InputAdornment } from "@mui/material";
import { useNavigate, useParams, useOutletContext,useSearchParams } from "react-router-dom";
import { TestsService } from "../../../../../../../services/configuration/tests";
import { useQuery } from "@tanstack/react-query";
import useSnackbar from "../../../../../../../hooks/useSnackbar";
import TestInputDialog from "../../../../../../../components/mui/dialog/testInputDialog";
import React, { useEffect } from "react";
import {
  ICreateTestCategoriesPayload,
  ITestCreatCategoryField,
} from "../../../../../../../interfaces/configuration/tests";
import { categoryValidationSchema } from "../../../../../../../validations/configuration/test";
import { IErrorResponse } from "../../../../../../../interfaces";

const fields: ITestCreatCategoryField[] = [
  {
    label: "Category Name",
    name: "name",
    type: "input",
    placeholder: "Write your test category name here...",
  },
  {
    label: "Low Grade (Min)",
    name: "low.min",
    type: "input",
    placeholder: "Enter low grade min",
  },
  {
    label: "Low Grade (Max)",
    name: "low.max",
    type: "input",
    placeholder: "Enter low grade max",
  },
  {
    label: "Moderate Grade (Min)",
    name: "moderate.min",
    type: "input",
    placeholder: "Enter moderate grade min",
  },
  {
    label: "Moderate Grade (Max)",
    name: "moderate.max",
    type: "input",
    placeholder: "Enter moderate grade max",
  },
  {
    label: "High Grade (Min)",
    name: "high.min",
    type: "input",
    placeholder: "Enter high grade min",
  },
  {
    label: "High Grade (Max)",
    name: "high.max",
    type: "input",
    placeholder: "Enter high grade max",
  },
];

const NewCategory: React.FC = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { testId, categoryId } = useParams<{ testId: string; categoryId: string; }>();
  const [searchParam] = useSearchParams();
  interface OutletProps {
    reFetch: () => void;
    refetchTest: () => void;
  }

  const outlet = useOutletContext<OutletProps>();
  const hitQuery = categoryId === "new" || categoryId === "view" ? false : true; 
  const { createTestCategory, updateTestCategories, getgetCategoryById } =
    TestsService();

  const getCategoryById = useQuery({
    queryKey: [hitQuery],
    queryFn: () => getgetCategoryById({ _id: categoryId }),
    enabled: hitQuery,
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICreateTestCategoriesPayload>({
    resolver: joiResolver(categoryValidationSchema),
  });

  useEffect(() => {
    if (categoryId !== "new") {
      if (getCategoryById.data) {
        const { name, low, moderate, high } = getCategoryById.data.data;
        setValue("name", capitalize(name) || "");
        setValue("low.min", low?.min || 0);
        setValue("low.max", low?.max || 0);
        setValue("moderate.min", moderate?.min || 0);
        setValue("moderate.max", moderate?.max || 0);
        setValue("high.min", high?.min || 0);
        setValue("high.max", high?.max || 0);
      }
    }
  }, [testId, getCategoryById.data]);

  const onSubmit = async (data: ICreateTestCategoriesPayload) => {
    try {
      const payload = {
        ...data,
        _test: testId,
      };

      if (categoryId === "new") {
        const add = await createTestCategory(payload);
        snackbar(add.message, "info");
        navigate(`/assessment/tests/test-info/manage/${testId}`);
        outlet.reFetch && outlet.reFetch();
        outlet.refetchTest && outlet.refetchTest();
      } else {
        const update = await updateTestCategories({
          ...payload,
          _id: categoryId,
        });
        snackbar(update.message, "info");
        navigate(`/assessment/tests/test-info/manage/${testId}`);
        outlet.reFetch && outlet.reFetch();
        outlet.refetchTest && outlet.refetchTest();
      }
    } catch (err) {
      const error = err as IErrorResponse;
      snackbar(error.data.message, "warning");
    }
  };

  const onClose = () => {
    navigate({
      pathname: `/assessment/tests/test-info/manage/${testId}`,
      search: searchParam.toString()
    });
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.currentTarget.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
    const truncatedValue = sanitizedValue.slice(0, 4);
    e.currentTarget.value = truncatedValue;
  };

  const getInputProps = () => ({
    pattern: "[0-9]*",
    inputMode: "numeric" as const,
    maxLength: 4,
    onInput: handleInput,
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
  
      // Disallow specific keys
      const invalidKeys = ["e", "E", "-", "+", "ArrowUp", "ArrowDown"];
      if (invalidKeys.includes(e.key)) {
        e.preventDefault();
        return;
      }
  
      // Allow backspace and delete
      if (["Backspace", "Delete"].includes(e.key)) {
        return;
      }
  
      // Validate the current value after the new key is pressed
      const currentValue = target.value + e.key;
      if (!isNaN(Number(currentValue)) && parseInt(currentValue) > 100) {
        snackbar("Percentage cannot exceed 100%", "warning");
        e.preventDefault();
      }
    },
    onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      snackbar("Pasting is not allowed", "warning");
    },
    onWheel: (e: React.WheelEvent<HTMLInputElement>) => {
      e.preventDefault();
    }
  });
  

  return (
    <TestInputDialog
      title={categoryId !== "new" ? "Edit Category" : "Add Category"}
      isOpen={categoryId ? true : false}
      onClose={onClose}
      primaryButton={{
        name: "Cancel",
        color: "primary",
        onClick: onClose,
      }}
      secondaryButton={{
        name: categoryId === "new" ? "Create" : "Update",
        color: "primary",
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Box sx={{ padding: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name={fields[0].name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={fields[0].label}
                  size="medium"
                  placeholder={fields[0].placeholder}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: 30
                  }}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={{ margin: "1rem 0rem 1rem 0rem" }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ margin: "1rem 0rem 1rem 0rem" }}
            >
              Grading:
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="low"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Grade Selection"
                  placeholder="Low"
                  value="Low"
                  disabled
                  fullWidth
                  inputProps={{
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    maxLength: 4,
                    onInput: (e) => {
                      e.preventDefault();
                      const inputValue = e.currentTarget.value;
                      const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                      const truncatedValue = sanitizedValue.slice(0, 4);
                      e.currentTarget.value = truncatedValue;
                      field.onChange(truncatedValue);
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={fields[1].name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Start Number"
                  placeholder="00"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.low?.min}
                  helperText={errors.low?.min?.message}
                  inputProps={getInputProps()}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={fields[2].name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="End Number"
                  placeholder="00"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.low?.max}
                  helperText={errors.low?.max?.message}
                  fullWidth
                  inputProps={getInputProps()}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="moderate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Grade Selection"
                  placeholder="Moderate"
                  value="Moderate"
                  disabled
                  fullWidth
                  inputProps={getInputProps()}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={fields[3].name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Start Number"
                  placeholder="00"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.moderate?.min}
                  helperText={errors.moderate?.min?.message}
                  fullWidth
                  inputProps={getInputProps()}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={fields[4].name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="End Number"
                  placeholder="00"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.moderate?.max}
                  helperText={errors.moderate?.max?.message}
                  fullWidth
                  inputProps={getInputProps()}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="high"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Grade Selection"
                  placeholder="High"
                  value="High"
                  disabled
                  fullWidth
                  inputProps={getInputProps()}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={fields[5].name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Start Number"
                  placeholder="00"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.high?.min}
                  helperText={errors.high?.min?.message}
                  inputProps={getInputProps()}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={fields[6].name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text" 
                  label="End Number"
                  placeholder="00"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.high?.max}
                  helperText={errors.high?.max?.message}
                  fullWidth
                  inputProps={getInputProps()}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  sx={{ marginBottom: "2rem" }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </TestInputDialog>
  );
};
export default NewCategory;
