import { Avatar, Box, Typography } from "@mui/material";
import { FC } from "react";
import { capitalize } from "../../../../../utilities/helper";
import CustomTypography from "../../../../../components/mui/max-length-limit";

interface props {
    name: string;
    count: number;
    user?: string;
    message: string;
}

const Tag: FC<props> = ({ name, count, user, message }) => (

    <div
        className={"team-evaluation-tag"}
    >
        <Typography className="cards-title" variant="body1">{name}</Typography>
        <Box display="flex" alignItems="center">
            <Typography className="analytics" variant="h4">
                {`${count}` || 0}
            </Typography>
            <Typography className="analytics-marks" variant="body2">
                {"/ 7"}
            </Typography>
            {
                user && (
                    <Box marginLeft={2}>
                        <Avatar sx={{ width: 20, height: 20 }} />
                    </Box>
                )
            }
            <Typography className="analytics" variant="body1" marginLeft={0.5}>
                <CustomTypography
                    limit={10}
                    label={capitalize(user || "")}
                />
            </Typography>
        </Box>
        <Typography className="analytics-message" variant="body1">
            {message || ""}
        </Typography>
    </div>
);

export default Tag;