import { IApprovedOrRejectedRequestsResponse, IKrRequestResponse, IKrRequestsResponse } from "../../../../interfaces/okr";
import { krRequests } from "../../../endpoints";
import HttpService from "../../../http";

const KrRequestsService = () => {
    const { httpRequest } = HttpService();

    const getPendingSelfAssessment = async (search: object): Promise<IKrRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestResponse>(
            "GET",
            `${krRequests}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addRequest = async (payload: object): Promise<IKrRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestResponse>(
            "POST",
            `${krRequests}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateRequest = async (payload: object): Promise<IKrRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestResponse>(
            "PUT",
            `${krRequests}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getUnderReviewRequests = async (search: object): Promise<IKrRequestsResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestsResponse>(
            "GET",
            `${krRequests}/under-review/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteRequest = async (query: object): Promise<IKrRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestResponse>(
            "DELETE",
            `${krRequests}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getApprovedOrRejectedRequests = async (search: object): Promise<IApprovedOrRejectedRequestsResponse> => new Promise((resolve, reject) => {
        httpRequest<IApprovedOrRejectedRequestsResponse>(
            "GET",
            `${krRequests}/approved-or-rejected/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getApprovedOrRejectedRequestsTimeline = async (search: object): Promise<IKrRequestsResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestsResponse>(
            "GET",
            `${krRequests}/approved-or-rejected`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

        return { updateRequest, getPendingSelfAssessment, addRequest, getUnderReviewRequests, deleteRequest, getApprovedOrRejectedRequests, getApprovedOrRejectedRequestsTimeline };
};

export { KrRequestsService };