import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, Outlet, useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../../hooks/useResource";
import { a11yProps } from "../../../components/shared/mui-tabs";
import useUser from "../../../hooks/useUser";
import { useQuery } from "@tanstack/react-query";
import { PmsParametersService } from "../../../services/pms/pms-parameters";

interface IState {
    redirectionLinks: string[];
}

const SelfEvaluationLayout = () => {
  const [value, setValue] = useState(0);
  const { user } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const { getPmsParameters } = PmsParametersService();
  const [state, setState] = useState<IState>({
    redirectionLinks: []
  });
  const pmsParameters = useQuery({
    queryKey: ["all-pms-parameters-data"],
    queryFn: () =>
      getPmsParameters({
        pagination: false,
      }),
  });
  const pmsParametersData =
    (pmsParameters && pmsParameters.data && pmsParameters.data.data) || [];

  useEffect(() => {
    const redirectionLinks: string[] = [];
    if (resourceAllocate("pms-feedback-rating.read")) {   
      redirectionLinks.push("overview");
    }
    if (resourceAllocate("pms-feedback-rating.read")) {  
      redirectionLinks.push("feedbacks");
    }
    if(resourceAllocate("pms-parameter.read")) {   
        for (let i = 0; i < pmsParametersData.length; i++) {
            redirectionLinks.push(`${pmsParametersData[i]._id}`);
        }
    }

    setState(prev => ({
      ...prev,
      redirectionLinks
    }));
  }, [user, pmsParametersData]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParams.delete("_parameter");
    setSearchParams(searchParams);
    navigate({
      pathname: state.redirectionLinks[newValue],
      search: searchParams.toString()
    });
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
            {resourceAllocate("pms-feedback-rating.read") && <Tab className="tabs-space start-tab-space" label="Overview" {...a11yProps(0)} />}
            {resourceAllocate("pms-feedback-rating.read") && <Tab className="tabs-space" label="Feedback" {...a11yProps(1)} />}
            {resourceAllocate("pms-parameter.read") && pmsParametersData?.map((tab, index) => <Tab key={tab._id} className={`tabs-space ${index === 0 ? "start-tab-space" : ""}`} {...a11yProps(index)} label={tab.name} />)}
          </Tabs>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default SelfEvaluationLayout;