import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { useSelector } from "react-redux";
import { IUser } from "../../../../interfaces";
import dayjs from "dayjs";
import { capitalize, checkTimePeriod } from "../../../../utilities/helper";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IOkrEvaluationsFilters } from "../../../../interfaces/okr/evaluation";

interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onClose: () => void;
}

const OkrEvaluationsFilters: FC<props> = ({ anchorEl, isOpen, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<IOkrEvaluationsFilters>({
    selectedMenu: 0,
    _assignee: [],
    date: [],
  });
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);

  useEffect(() => {
    const _assignee: { key: string; value: string }[] = searchParams.get(
      "_assignee"
    )
      ? JSON.parse(String(searchParams.get("_assignee")))
      : [];

    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];

    setState((prevState) => ({
      ...prevState,
      _assignee,
      date,
    }));
  }, [searchParams]);

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const handleRightListItem = (
    name:  "_assignee",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];
    const isExist = !!state[name].find((ele) => ele.key === key);
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const handleDateListItem = (name: "date", key: string, value: string) => {
    const date = checkTimePeriod(key);

    const payload: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [
      {
        key,
        value,
        startDate: date.startDate,
        endDate: date.endDate,
      },
    ];

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let date: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [];

    if (state.date.length && period === "start") {
      date = state.date.map((e) => ({
        ...e,
        startDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (state.date.length && period === "end") {
      date = state.date.map((e) => ({
        ...e,
        endDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (!state.date.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59);
      date = [
        {
          key: "custom",
          value: "custom",
          startDate: dayjs(e).startOf("day").toISOString(),
          endDate: currentDate.toISOString(),
        },
      ];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      date = [
        {
          key: "custom",
          value: "custom",
          startDate: currentDate.toISOString(),
          endDate: dayjs(e).endOf("day").toISOString(),
        },
      ];
    }

    setState((prevState) => ({
      ...prevState,
      date,
    }));
  };

  const deleteChip = (
    name: "_assignee" | "date",
    key: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      _assignee: [],
      date: [],
    });
  };

  const onApply = () => {
    searchParams.set("_assignee", JSON.stringify(state._assignee));
    searchParams.set("date", JSON.stringify(state.date));

    searchParams.set("page", "1");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    onClose();
  };

  const dateOptions = [
    { key: "yesterday", value: "Yesterday" },
    { key: "today", value: "Today" },
    { key: "thisWeek", value: "Weekly" },
    { key: "thisMonth", value: "Monthly" },
    { key: "thisQuarter", value: "Quarterly" },
  ];

  const handleClose = () => {
    
    const _assignee: { key: string; value: string }[] = searchParams.get(
      "_assignee"
    )
      ? JSON.parse(String(searchParams.get("_assignee")))
      : [];

    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];

    setState((prevState) => ({
      ...prevState,
      _assignee,
      date,
    }));
    onClose();
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {
              state._assignee.length ||
              state.date.length ? (
                <>
                  {state._assignee.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("_assignee", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.date.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      icon={<CalendarMonthIcon />}
                      color="primary"
                      onDelete={() => deleteChip("date", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
              ) : (
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filters are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton onClick={handleClose} style={{ marginRight: "-10px" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid className="filter-box" container>
            <Grid id="left" item xs={5}>
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Responsibility"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state._assignee}
                />
                <MenuItem
                  index={1}
                  label="Date"
                  selectedMenu={state.selectedMenu === 1}
                  onChange={handleLeftListItem}
                  count={state.date}
                />
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid id="right" item xs={6}>
              <List component="nav">
                {state.selectedMenu === 0 &&
                  users.map((status) => (
                    <ListItemButton
                      key={status._id}
                      selected={
                        !!state._assignee.find(
                          (ele) => ele.key === status._id
                        )
                      }
                      onClick={() =>
                        handleRightListItem(
                          "_assignee",
                          status._id,
                          status.name
                        )
                      }
                    >
                      <ListItemText primary={capitalize(status.name)} />
                      <Checkbox
                        edge="end"
                        checked={
                          !!state._assignee.find(
                            (ele) => ele.key === status._id
                          )
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 1 && (
                  <>
                    {dateOptions.map((date) => (
                      <ListItemButton
                        key={date.key}
                        selected={
                          !!state.date.find((ele) => ele.key === date.key)
                        }
                        onClick={() =>
                          handleDateListItem(
                            "date",
                            date.key,
                            capitalize(date.value)
                          )
                        }
                      >
                        <ListItemText primary={date.value} />
                        <Checkbox
                          edge="end"
                          checked={
                            !!state.date.find((ele) => ele.key === date.key)
                          }
                        />
                      </ListItemButton>
                    ))}
                    <Box marginTop={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.date[0]?.startDate
                              ? dayjs(state.date[0]?.startDate)
                              : null
                          }
                          onChange={(e) => handleDate(e, "start")}
                          label="Start Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                      <div className="mt-3" />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.date[0]?.endDate
                              ? dayjs(state.date[0]?.endDate)
                              : null
                          }
                          onChange={(e) => handleDate(e, "end")}
                          label="End Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}
              </List>
            </Grid>
          </Grid>

          <Box className="actions-btn" marginTop="8px" textAlign="end">
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button className="ml-2" onClick={onApply}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default OkrEvaluationsFilters;
