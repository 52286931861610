import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import {  IUser } from "../../../../interfaces";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { useQuery } from "@tanstack/react-query";
import { VendorCategoryService } from "../../../../services/vendor/vendor-category";
import { IVendorServiceFilter } from "../../../../interfaces/vendor/vendor-service";
import { useSelector } from "react-redux";
import { capitalize, checkTimePeriod, displayName } from "../../../../utilities/helper";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs"; 
import useResource from "../../../../hooks/useResource";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const VendorServiceFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { resourceAllocate } = useResource();
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);

    const { getVendorPartialCategory } = VendorCategoryService();

    const categories = useQuery({
      queryKey: ["allCategories"],
      queryFn: () =>
        getVendorPartialCategory({
          type: "SERVICE",
        }),
    });

    const categoriesData = categories?.data?.data; 

    const [state, setState] = useState<IVendorServiceFilter>({ 
        selectedMenu: 0,
        category: [],
        createdBy: [],
        date: []
    });

    useEffect(() => {
        resetFilter(); 
        if(!resourceAllocate("vendor-category.read")){
            setState(prevState => ({
                ...prevState,
                selectedMenu: 1
            }));
        }
    }, []);

    useEffect(() => {
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const createdBy: { key: string, value: string }[] = searchParams.get("created-by") ? JSON.parse(String(searchParams.get("created-by"))) : [];
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];

        setState(prevState => ({
            ...prevState,
            category,
            createdBy,
            date
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "category" | "createdBy" | "date", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const handleDateListItem = (name: "date", key: string, value: string) => {
        const date = checkTimePeriod(key);
    
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: date.startDate,
          endDate: date.endDate,
        }];
    
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };

      const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let date: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
    
        if (state.date.length && period === "start") {
          date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.date.length && period === "end") {
          date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.date.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          date = [{
            key: "custom",
            value: "custom", 
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          date = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
    
        setState(prevState => ({
          ...prevState,
          date
        }));
      };



    const deleteChip = (name:  "category" | "createdBy" | "date", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            category: [],
            createdBy: [],
            date: []
        });
    };

    const onApply = () => {
        searchParams.set("category", JSON.stringify(state.category));
        searchParams.set("created-by", JSON.stringify(state.createdBy));
        searchParams.set("date", JSON.stringify(state.date));


        searchParams.set("page", "1");
        navigate({
            pathname: "/directory/services",
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const createdBy: { key: string, value: string }[] = searchParams.get("created-by") ? JSON.parse(String(searchParams.get("created-by"))) : [];
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        setState(prevState => ({
            ...prevState,
            category,
            createdBy,
            date
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.category.length || state.createdBy.length || state.date.length) ?
                                <>

                                    {state.category.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("category", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.createdBy.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("createdBy", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.date.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("date", ele.key)} label={ele.value} variant="outlined" />)}
                  
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton 
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            {
                                resourceAllocate("vendor-category.read") && 
                                <MenuItem
                                    index={0}
                                    label="Category"
                                    selectedMenu={state.selectedMenu === 0}
                                    onChange={handleLeftListItem}
                                    count={state.category}
                                />
                            }

                            <MenuItem
                                index={1}
                                label="Created By"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.createdBy}
                            />

                            <MenuItem
                                index={2}
                                label="Date"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                count={state.date}
                            />

                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                categoriesData && categoriesData.map(category => <ListItemButton
                                    key={category?._id}
                                    selected={state.category.find(ele => ele.key === category._id) ? true : false}
                                    onClick={() => handleRightListItem("category", category._id, category.name)}
                                >
                                    <ListItemText primary={capitalize(category.name)} />
                                    <Checkbox edge="end" checked={state.category.find(ele => ele.key === category._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                users.map((user) =>
                                <ListItemButton
                                    key={user._id}
                                    selected={state.createdBy.find(ele => ele.key === user._id) ? true : false}
                                    onClick={() => handleRightListItem("createdBy", user._id, `${capitalize(displayName(user))}`)}
                                >
                                    <ListItemText primary={`${capitalize(displayName(user))}`} />
                                    <Checkbox edge="end" checked={state.createdBy.find(ele => ele.key === user._id) ? true : false} />
                                </ListItemButton>
                                )
                            }
                            {
                                state.selectedMenu === 2 &&
                                <>
                                {[
                                    { key: "yesterday", value: "Yesterday" },
                                    { key: "today", value: "Today" },
                                    { key: "thisWeek", value: "Weekly" },
                                    { key: "thisMonth", value: "Monthly" },
                                    { key: "thisQuarter", value: "Quarterly" },
                                ]?.map((date) =>
                                    <ListItemButton
                                    key={date.key}
                                    selected={state.date.find(ele => ele.key === date.key) ? true : false}
                                    onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                                    >
                                    <ListItemText primary={date.value} />
                                    <Checkbox edge="end" checked={state.date.find(ele => ele.key === date.key) ? true : false} />
                                    </ListItemButton>
                                )}
                                <Box marginTop={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.date[0]?.startDate ? dayjs(state.date[0]?.startDate) : null}
                                        onChange={e => handleDate(e, "start")}
                                        label="Start Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                    <div className="mt-3" />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.date[0]?.endDate ? dayjs(state.date[0]?.endDate) : null}
                                        onChange={e => handleDate(e, "end")}
                                        label="End Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                </Box>
                                </>

                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default VendorServiceFilters;