import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import CustomDialog from "../../../../components/mui/dialog";
import { IErrorResponse, ITemplate } from "../../../../interfaces";
import { Box, Button, FormHelperText, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../../components/mui/select";
import { ChangeEvent, useEffect, useState } from "react";
import { templateEmailValidation, templateLinkedinValidation } from "../../../../validations";
import { useQuery } from "@tanstack/react-query";
import { TemplateService } from "../../../../services";
import Joi from "joi";
import useSnackbar from "../../../../hooks/useSnackbar";
import HttpService from "../../../../services/http";
import MESSAGE from "../../../../assets/json/message.json";
import GetActions from "../../../../components/get-actions";
import CustomTable from "../../../../components/mui/table";
import TinyEditor from "../../../../components/tiny-editor";

interface IState {
    errors: {
        content?: string
    }
}

interface outletProps {
    reFetch: () => void;
    refetchTemplates: () => void;
}

interface IAttachmentRow {
    id: number,
    file_name: string,
    file_size: string,
    action: JSX.Element
}

const ManageTemplate = () => {
    let attachmentRow: IAttachmentRow[] = [];
    
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { httpFormRequest } = HttpService();
    const outlet = useOutletContext<outletProps>();
    const { getTemplate, addTemplate, updateTemplate } = TemplateService();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const template = useQuery({
        queryKey: [hitQuery],
        queryFn: () => getTemplate({ _id: id }),
        enabled: hitQuery
    });

    const { handleSubmit, control, getValues, setValue, setError, clearErrors, watch, trigger, resetField, formState: { errors } } = useForm<ITemplate>({
        defaultValues: {
            title: "",
            type: "email",
            tag: "general",
            subject: "",
            content: "",
            linkedinMessage: "",
            attachments: []
        }
    });
    const [state, setState] = useState<IState>({
        errors: {}
    });

    useEffect(() => {
        if (template.data?.data) {
            if (template.data.data.type === "email") {
                setValue("tag", template.data?.data.tag);
                setValue("subject", template.data?.data?.email?.subject);
                setValue("content", template.data?.data?.email?.content);
                setValue("attachments", template.data?.data?.email?.attachments);
                trigger("content");
            }else {
                setValue("linkedinMessage", template.data?.data?.linkedinMessage);
            }
            setValue("title", template.data?.data?.title);
            setValue("type", template.data.data.type);
        }
    }, [id, template.data]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "type") {
                if(value.type === "linkedinMessage"){
                    resetField("email.subject");
                    resetField("email.content");
                }else{
                    resetField("linkedinMessage");
                }
                trigger("type");
                clearErrors();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {

        setState(prev => ({
            ...prev,
            errors: {
                content: ""
            }
        }));
    }, [getValues("content")?.length]);


    const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const allowedFileTypes = ["doc", "docx", "pdf", "png", "jpeg", "jpg"];

            if (e.target.files) {
                const uploaded = await httpFormRequest<{ data: string }>(
                    e.target.files,
                    e.target.files[0].name,
                    allowedFileTypes,
                    10
                );
                const attachments = getValues("attachments") || [];
                attachments.push({
                    filename: e.target.files[0].name,
                    contentType: e.target.files[0].type,
                    sizeInBytes: e.target.files[0].size,
                    path: uploaded.data.split("uploads")[1]
                });
                setValue("attachments", attachments);
                snackbar(MESSAGE.EN.upload_success, "info");
            }
        } catch (error) {
            console.log("error in candidate detail upload", error);
            snackbar(MESSAGE.EN.upload_failed, "error");
        }
    };

    const removeAttachment = (i: number) => {
        const attachments = getValues("attachments") || [];
        attachments.splice(i, 1);

        setValue("attachments", attachments);
        trigger("attachments");
        snackbar(MESSAGE.EN.upload_remove, "info");
    };

    const onSubmit = async (data: ITemplate) => {
        let payload: {
            email?: {
                content: string | undefined;
                subject: string | undefined;
                attachments: { filename: string, path: string, sizeInBytes: number, contentType: string }[] | [];
            };
            linkedinMessage?: string;
            type?: "email" | "linkedinMessage";
            tag?: string;
            title?: string;
            _id?: string;
        } = {
            title: data.title,
            type: data.type,
            tag: data.type === "linkedinMessage" ? "general" : data.tag
        };

        let err: Joi.ValidationResult<ITemplate>;

        if (data.type === "email") {
            payload = {
                ...payload,
                email: {
                    subject: data.subject,
                    content: data.content,
                    attachments: (getValues("attachments") || [])
                }
            };
            err = templateEmailValidation.validate(payload);
        } else {
            payload = {
                ...payload,
                linkedinMessage: data.linkedinMessage
            };
            err = templateLinkedinValidation.validate({
                ...payload,
                linkedinMessage: data.linkedinMessage
            });
        }

        if (err.error?.details.length) {
            err.error?.details.map(error => {
                const label = error.context?.key;
                const message = error.message;
                const isLabelExist = label === "title" || label === "subject" || label === "linkedinMessage";
                isLabelExist && error.message && setError(label, { message });

                if (label === "content") {
                    setState(prev => ({
                        ...prev,
                        errors: {
                            content: message
                        }
                    }));
                }
            });
        } else {
            try {
                if (id === "new") {
                    const add = await addTemplate(payload);
                    snackbar(add.message, "info");
                    navigate("/configurations/template");
                    outlet?.reFetch && outlet.reFetch();
                    outlet?.refetchTemplates && outlet.refetchTemplates();
                }
                else {
                    payload = { ...payload, _id: id };
                    const update = await updateTemplate(payload);
                    snackbar(update.message, "info");
                    navigate({
                        pathname: "/configurations/template",
                        search: searchParams.toString()
                    });
                    outlet?.reFetch && outlet.reFetch();
                    outlet?.refetchTemplates && outlet.refetchTemplates();
                }
            } catch (error) {
                const err = error as IErrorResponse;
                snackbar(err.data.message, "warning");
                console.log(error);
            }

        }

    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/template",
            search: searchParams.toString()
        });
    };

    const attachmentColumns = [
        {
            id: "id",
            label: "S No."
        },
        {
            id: "file_name",
            label: "File Name"
        },
        {
            id: "file_size",
            label: "File Type"
        },
        {
            id: "action",
            label: "Actions"
        }
    ];

    const attachments = getValues("attachments") || [];
    if (attachments.length) {
        attachmentRow = attachments.map((attachment, i) => {
            const action = <GetActions
                icons={
                    [
                        { name: "Preview", method: () => window.open(`${process.env.REACT_APP_S3_BASE_URL}${attachment.path}`, "_blank") },
                        { name: "Delete", method: () => removeAttachment(i) }
                    ]
                }
            />;

            return {
                id: i + 1,
                file_name: attachment.filename.length > 50 ?
                    attachment.filename.substring(0, 50) + "..."
                    : attachment.filename,
                file_size: `${(attachment.sizeInBytes/ 1024/1024).toFixed(2)} MB`,
                action
            };
        });
    }


    return (
        <CustomDialog
            size='md'
            title={id !== "new" ? "Edit Template" : "Add Template"}
            isOpen={id ? true : false}
            onClose={onClose}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name="title"
                        render={(prop) => <TextField
                            label="Title*"
                            className="disable-text"
                            variant="outlined"
                            size="small"
                            placeholder="Type template name here"
                            error={errors.title?.message ? true : false}
                            helperText={errors.title?.message}
                            {...prop.field}
                        />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Select
                        control={control}
                        name="type"
                        label="Type*"
                        size="small"
                        variant="outlined"
                        error={errors.type?.message ? true : false}
                        helperText={errors.type?.message}
                    >
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="linkedinMessage">Linkedin</MenuItem>
                    </Select>
                </Grid>

                {
                    getValues("type") === "email" &&
                    <>
                        <Grid item xs={12} md={6}>
                            <Select
                                control={control}
                                name="tag"
                                label="Tag"
                                size="small"
                                variant="outlined"
                                error={errors.tag?.message ? true : false}
                                helperText={errors.tag?.message}
                            >
                                <MenuItem value="general">General</MenuItem>
                                <MenuItem value="interviewer">Interviewer Email Template</MenuItem>
                                <MenuItem value="review">Review Email Template</MenuItem>
                                <MenuItem value="google_calendar">Google Calender</MenuItem>
                                <MenuItem value="resource_request">Resource Request</MenuItem>
                                <MenuItem value="offered">Offered</MenuItem>
                                <MenuItem value="coderbyte">CoderByte</MenuItem>
                                <MenuItem value="new_joinee" disabled>New Joinee Template</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="subject"
                                render={(prop) => <TextField
                                    label="Subject*"
                                    className="disable-text"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Type subject here"
                                    error={errors.subject?.message ? true : false}
                                    helperText={errors.subject?.message}
                                    {...prop.field}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={state.errors?.content ? "tiny-error" : ""}>
                                <TinyEditor value={getValues("content")} onChange={(e: string) => setValue("content", e)} height="375px"   />
                                {state.errors?.content && (
                                    <FormHelperText sx={{ margin: "4px 14px 0px 14px" }} error={true}>
                                        {state.errors.content}
                                    </FormHelperText>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputLabel id="upload">
                                <Button component="label" fullWidth>
                                    Upload Attachment
                                    <input
                                        hidden
                                        type="file"
                                        id="upload"
                                        onChange={uploadFile}
                                        accept="application/pdf, image/png, image/jpeg, .doc, .docx"
                                    />
                                </Button>
                            </InputLabel>
                        </Grid>

                        {
                            attachments.length ? <Grid item xs={12}>
                                <CustomTable
                                    columns={attachmentColumns}
                                    rows={attachmentRow}
                                />
                            </Grid>
                                :
                                null
                        }
                    </>

                }

                {
                    getValues("type") === "linkedinMessage" &&
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="linkedinMessage"
                            render={(prop) => <TextField
                                label="Linkedin Message*"
                                className="disable-text"
                                variant="outlined"
                                size="small"
                                error={errors.linkedinMessage?.message ? true : false}
                                helperText={errors.linkedinMessage?.message}
                                multiline
                                minRows={4}
                                {...prop.field}
                            />}
                        />
                    </Grid>
                }
            </Grid>
        </CustomDialog>
    );
};

export default ManageTemplate;