import "./style.scss";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FeedbackTableProps } from "../../../../../interfaces/pms/team-evaluation";
import { capitalize } from "../../../../../utilities/helper";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PmsParametersService } from "../../../../../services/pms/pms-parameters";
import { generateEvaluationPeriods } from "../../../team-evaluation/parameter-wise/feedback-table";
import { pmsSubParametersService } from "../../../../../services/pms/pms-parameters/sub-parameters";

const FeedbackTable: React.FC<FeedbackTableProps> = ({ data, startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const { id } = useParams();
  const { getPmsSubParameters } = pmsSubParametersService();
  const { getPmsParameter } = PmsParametersService();

  const parameter = useQuery({
    queryKey: ["parameter-data", id],
    queryFn: () => getPmsParameter({ _id: id }),
  });

  const parameterFrequency =
    parameter && parameter.data && parameter.data.data && parameter.data.data.frequency || "";

    let evaluationPeriods: { label: string; cycle: number, year: number, periodStart: Date, periodEnd: Date }[] = [];
    if (parameterFrequency) {
      evaluationPeriods = generateEvaluationPeriods(start, end, parameterFrequency).filter(
        (period) => period.periodStart >= start && period.periodStart <= end
      );
    }

  const hasSubParameters = !!data[0]?.subParameters.length;

  const pmsSubParameters = useQuery({
    queryKey: ["all-sub-parameters", id],
    queryFn: () =>
        getPmsSubParameters({
        pagination: false,
        _parameter: id
      }),
  });
  const pmsSubParametersData = (pmsSubParameters && pmsSubParameters.data && pmsSubParameters.data.data) || [];

  return (
    <TableContainer component={Paper} className="table-self-feedback-container">
      <Table stickyHeader aria-label="sticky table" className="table">
        <TableHead style={{ position: "sticky", zIndex:3 }}>
          <TableRow>
            <TableCell className="fix-width header border sticky-cell">Sub Parameter</TableCell>
            {hasSubParameters && evaluationPeriods.map((period) => (
              <TableCell
                key={`period-${period.cycle}-${period.label}`}
                align="center"
                className="fix-width header border"
              >
                {period.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {hasSubParameters ? (
            data[0]?.subParameters.map((subParam, index) => (
              <TableRow key={`${subParam.subParameter}-${index}`}>
                <TableCell className="border sticky-cell">{capitalize(subParam.subParameterName)}</TableCell>
                {evaluationPeriods.map((period) => {
                  const rating = subParam.ratings.find(
                    (r) =>
                      r.evaluationCycle === period.cycle && r.year === period.year
                  );
                  return (
                    <TableCell
                      key={`period-${period.cycle}-${period.label}`}
                      align="center"
                      className="border"
                    >
                      {rating ? rating.rating : "-"}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            pmsSubParametersData.map((subParam) => (
              <TableRow key={`${subParam._id}`}>
                <TableCell className=" border">{capitalize(subParam.name || "")}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeedbackTable;
