import "./style.scss";
import React, { useState, KeyboardEvent, useEffect, useRef } from "react";
import { Avatar, Box, MenuItem, Paper, Popper, TextField, Typography } from "@mui/material";
import { IUser } from "../../../interfaces";
import { useSelector } from "react-redux";
import { capitalize } from "../../../utilities/helper";
import { Controller } from "react-hook-form";

interface ToMailProps {
    name: string;
    label: string | JSX.Element;
    // eslint-disable-next-line
    control: any;
    value?: string;
    placeholder?: string;
    error?: boolean;
    helperText?: string;
    onEmailSelect: (email: string, name: string) => void;
    onKeyUp?: (e: KeyboardEvent<HTMLDivElement>, key: string) => void;
    size? : "small" | "medium";
    disabled?:boolean;
}

const EmailSuggestion: React.FC<ToMailProps> = ({ label, control, placeholder, name, value, error, helperText, onEmailSelect, onKeyUp, size , disabled}) => {
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
    const [state, setState] = useState({
        isFocused: false,
        focusedIndex: -1
    });

    const suggestionBoxRef = useRef<HTMLDivElement>(null);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleResultClick = (email: string) => {
        setState((prev) => ({
            ...prev,
            focusedIndex: -1
        }));
        onEmailSelect(email, name);
    };

    const filteredUsers = users.filter((user) => {
        if (value) {
            const regex = new RegExp(value, "i");
            return (regex.test(user.email) || regex.test(user.name));
        } else {
            return false;
        }
    });

   useEffect(() => {
        if (state.focusedIndex !== -1 && suggestionBoxRef.current) {
            const menuItem = suggestionBoxRef.current.childNodes[state.focusedIndex] as HTMLElement;
            menuItem?.scrollIntoView({ block: "nearest" });
        }
    }, [state.focusedIndex]);

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setState((prev) => ({
                ...prev,
                focusedIndex: Math.min(prev.focusedIndex + 1, filteredUsers.length - 1)
            }));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setState((prev) => ({
                ...prev,
                focusedIndex: Math.max(prev.focusedIndex - 1, -1)
            }));
        } else if (e.key === "Enter" && state.focusedIndex !== -1) {
            handleResultClick(filteredUsers[state.focusedIndex].email);
        }
    };

    const showSuggestions = value?.length && filteredUsers.length && state.isFocused;

    return (
      <>
        <Box position="relative" ref={anchorRef}>
          <Controller
            control={control}
            name={name}
            render={(prop) => (
              <TextField
                {...prop.field}
                label={label}
                className="disable-text"
                disabled={disabled ? disabled : false}
                variant={"outlined"}
                placeholder={placeholder}
                error={error ? true : false}
                helperText={helperText}
                onKeyUp={(e) => onKeyUp && onKeyUp(e, name)}
                onFocus={() =>
                  setState((prev) => ({ ...prev, isFocused: true }))
                }
                onBlur={() => {
                  setState((prev) => ({ ...prev, isFocused: false }));
                }}
                autoComplete="off"
                onKeyDown={handleKeyDown}
                size={size ? size : "small"}
                fullWidth
              />
            )}
          />
          <Popper
            open={Boolean(showSuggestions)}
            anchorEl={anchorRef.current}
            style={{ width: anchorRef.current?.offsetWidth, zIndex: 9999 }}
            placement="bottom-start"
            disablePortal={false}
          >
            {showSuggestions && (
              <Paper
                elevation={3}
                ref={suggestionBoxRef}
                sx={{maxHeight: "200px", overflow: "auto", mt: 0.5, width: "100%"}}
              >
                {filteredUsers.map((user, index) => (
                  <MenuItem
                    key={index}
                    onMouseDown={() => handleResultClick(user.email)}
                    selected={index === state.focusedIndex}
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar className="profile-avatar" src={user?.image}>
                        {user?.image
                          ? user.image
                          : user.name
                          ? user.name[0]?.toUpperCase()
                          : "A"}
                      </Avatar>
                      <div>
                        <Typography
                          className="ml-2"
                          variant="body1"
                          sx={{ lineHeight: "1" }}
                        >
                          {capitalize(user.name)}
                        </Typography>
                        <Typography
                          className="ml-2"
                          variant="caption"
                          sx={{ marginTop: "1px" }}
                        >
                          {user.email}
                        </Typography>
                      </div>
                    </Box>
                  </MenuItem>
                ))}
              </Paper>
            )}
          </Popper>
        </Box>
      </>
    );
};

export default EmailSuggestion;
