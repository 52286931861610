import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import { IKrRequestRow, IKrRequestState } from "../../../../interfaces/okr";
import useDebounce from "../../../../hooks/useDebounce";
import CustomTypography from "../../../../components/mui/max-length-limit";
import { okrKeyResultsService } from "../../../../services/okr/objectives/key-results";
import OkrSelfAssessmentFilters from "../filters";
import { capitalize, formatDate } from "../../../../utilities/helper";
import { IKeyResultData } from "../../../../interfaces/okr/objectives/key-results";

interface outletProps {
  refetchPendingKRs: () => void;
}

const PendingKRs = () => {
  let rows: IKrRequestRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { getPendingKRS } = okrKeyResultsService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IKrRequestState>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    pagination: { 
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
    },
    filterCount: 0
  });

  const pendingKRs = useQuery({
    queryKey: ["all-pending-krs", state.pagination.page, state.filters],
    queryFn: () =>
      getPendingKRS({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        startDate: state.filters.date?.startDate,
        endDate: state.filters.date?.endDate
      }),
  });
  const data = pendingKRs.data?.data;
  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (pendingKRs.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: pendingKRs.data.meta.page,
          totalPages: pendingKRs.data.meta.totalPages,
          totalRecords: pendingKRs.data.meta.totalRecords,
        },
      }));
    }
  }, [pendingKRs.data?.meta]);

  useEffect(() => {
    if (data?.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const dateFilter: {
        key: string;
        value: string;
        startDate: string;
        endDate: string;
      }[] = searchParams.get("date")
        ? JSON.parse(String(searchParams.get("date")))
        : [];

    filterCount += dateFilter.length ? 1 : 0;

    let date:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (dateFilter?.length) {
      date = {
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        search,
        date
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const columns = [
    {
      id: "keyResult",
      label: "Key Result",
    },
    {
      id: "objective",
      label: "Objective",
    },
    {
      id: "createdBy",
      label: "Created By",
    },
    {
      id: "targetFigure",
      label: "Target Figure",
    },
    {
      id: "startDate",
      label: "Start Date",
    },
    {
      id: "endDate",
      label: "End Date",
    }
  ];

  const createRow = (index: number, data: IKeyResultData) => {

    const keyResult = <CustomTypography
          limit={80}
          label={capitalize(data?.name)}
          onClick={() => navigate({ pathname: "" + data?._id, search: searchParams.toString() })}
          color={"primary"}
      />;

    return {
      keyResult,
      objective: capitalize(data?._objective?.name),
      createdBy: capitalize(data?.createdBy?.name),
      targetFigure: data?.targetFigure,
      startDate: formatDate(data?.startDate),
      endDate: formatDate(data?.endDate),
    };

  };

  if (data && data.length) {
    rows = data.map((data, i) =>
      createRow(i, data)
    );
  }

  return (
    <div>

      <Header
        className="my-2"
        searchPlaceholder="Search by KR or Objective name"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <OkrSelfAssessmentFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="There are no pending tasks."
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      
      <Outlet context={{ ...outlet, reFetch: pendingKRs.refetch }} />
    </div>
  );
};

export default PendingKRs;
