import Joi from "joi";
import { required } from "../../shared";

export const offeredPreOnBoardValidation = Joi.object({
    to: Joi.string()
        .email({ tlds: { allow: false } })
        .label("To")
        .required()
        .messages({
            "string.empty": required,
        }),
    ccText: Joi.string()
        .label("CC")
        .allow(""),
    cc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("CC"),
    name: Joi.string()
        .label("Name")
        .messages({
            "string.empty": required,
        })
        .min(3)
        .max(50)
        .trim(),
    company: Joi.string()
        .label("Company")
        .required()
        .min(3)
        .max(60)
        .trim()
        .messages({
            "string.empty": required,
        }),
    reportingManager: Joi.string()
        .label("Reporting Manager")
        .messages({
            "string.empty": required,
        })
        .min(3)
        .max(50)
        .trim(),
    jobPosition: Joi.string()
        .label("Job Title")
        .messages({
            "string.empty": required,
        }),

    _department: Joi.string()
        .label("Department"),


    jobType: Joi.string().allow(""),
    date: Joi.string()
        .label("Date of joining")
});