import { IUser } from "../../../../interfaces";
import { LOAD_USER } from "../../../actions";

interface IAction {
    type: "LOAD_USER",
    payload: IUser
}

interface IState {
    list: IUser[]
}

const initialState: IState = {
    list: [
        {
            name: "",
            firstName: "",
            image: "",
            linkedinUrl: "",
            assignedToMe: false,
            lastName: "",
            dob: "",
            joinedDate: "",
            dateOfLeaving: "",
            location: {
                formattedAddress: "",
                country: "",
            },
            reportingTo: {
                name: "",
                _id: "",
            },
            legalEntry: "",
            businessUnit: {
                name: "",
                _id: "",
            },
            designation: {
                name: "",
                _id: "",
            },
            employeeNumber: "",
            userId: "",
            email: "",
            gender: "MALE",
            phone: {
                country: "",
                dialCode: "",
                iso2: "",
                number: "",
            },
            extensionNumber: {
                country: "",
                dialCode: "",
                iso2: "",
                number: "",
            },
            address: "",
            password: "",
            department: {
                name: "",
                createdBy: {
                    name: ""
                },
                createdAt: "",
                _id: "",
            },
            role: ["HR"],
            status: "ACTIVE",
            _id: "",
            createdBy: {
                name: "",
                _id: "",
            },
            _role: {
                isDefault: false,
                name: "",
                role: "",
                _id: "",
            },
            integrationMeta:{
                picture:""
            },
            isReportingManager: false
        }
    ]
};

const userReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_USER: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { userReducer };
