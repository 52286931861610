import { Route, Routes } from "react-router-dom";
import ImportBlog from "./blog/import";
import BlogList from "./blog/list";
import ManageBlog from "./blog/manage";
import ScheduleBlog from "./blog/manage/schedule";
import ViewVersionContent from "./blog/manage/content-modal";
import ChecklistPublish from "./blog/manage/checklistPublish";
import ChecklistWriter from "./blog/manage/content/checkListWriter";
import ChecklistReviewer from "./blog/manage/score/checkListReviewer";
import ImportBlogKeyword from "./blog/import keywords";

const BlogRouting = () => (
  <Routes>
    <Route path="/" element={<BlogList />}>
      <Route path="/import" element={<ImportBlog />} />
      <Route path="/import-keywords" element={<ImportBlogKeyword />} />
    </Route>
    <Route path="/manage/:id" element={<ManageBlog />}>
      <Route path="/manage/:id/schedule" element={<ScheduleBlog />} />
      <Route
        path="/manage/:id/checklistPublish"
        element={<ChecklistPublish />}
      />
      <Route path="/manage/:id/checklistWriter" element={<ChecklistWriter />} />
      <Route
        path="/manage/:id/checklistReviewer"
        element={<ChecklistReviewer />}
      />
      <Route
        path="/manage/:id/view-content/:contentId"
        element={<ViewVersionContent />}
      />
    </Route>
  </Routes>
);
export default BlogRouting;