import { Box, Grid, TextField, Typography } from "@mui/material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { IAssessmentPenalityRule, IErrorResponse, IStatus } from "../../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import CustomDialog from "../../../../components/mui/dialog";
import CustomLabel from "../../../../components/mui/custom-label";
import "./style.scss";
import { AssessmentPenalityService } from "../../../../services";
import { assessmentPenalityValidation } from "../../../../validations";
import SearchSelect from "../../../../components/mui/search-select";

interface outletProps {
  reFetch: () => void;
}

const ManageAssessmentPenality = () => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { getAssessmentPenality, addAssessmentPenality, updateAssessmentPenality } = AssessmentPenalityService();

  const { handleSubmit, control, setValue, trigger, getValues, formState: { errors } } = useForm<IAssessmentPenalityRule>({
    resolver: joiResolver(assessmentPenalityValidation),
    defaultValues: {
      numberOfAttemptsAllowed: "",
      updateStatus: ""
    }
  });
 const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);


  const onSubmit = async (data: IAssessmentPenalityRule) => {
    try {


      if (id === "new") {
        const addRule = await addAssessmentPenality(data);
        snackbar(addRule.message, "info");
      } else {
        const updateRule = await updateAssessmentPenality({ _id: id, ...data });
        snackbar(updateRule.message, "info");
      }
      navigate("/assessment/assessments-penality");
      outlet.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };


  const assessmentPenality = useQuery({
    queryKey: ["assessmentRule"],
    queryFn: () => getAssessmentPenality({ _id: id }),
    enabled: id !== "new"
  });
  const assessmentPenalityData = assessmentPenality && assessmentPenality.data && assessmentPenality.data.data;

  useEffect(() => {
    if (id !== "new" && assessmentPenalityData) {
      setValue("numberOfAttemptsAllowed", String(assessmentPenalityData?.numberOfAttemptsAllowed) ?? "");
      setValue("updateStatus", assessmentPenalityData?.updateStatus ?? "");
      trigger("updateStatus");
    }
  }, [assessmentPenalityData]);
 
  return (
    <Box>
    <CustomDialog
      title={id === "new" ? "Add Penalty Rule" : "Update Penalty Rule"}
      isOpen={true}
      size="md"
      onClose={() => navigate("/assessment/assessments-penality")}
      onSubmit={handleSubmit(onSubmit)}
     >
      <Grid container spacing={1} className="form-grid mt-3 mb-3">
      <Grid item xs={2.5} className="assessment-penality-text">
              <Typography variant="body1">
                {"If the Candidate cheats "}
              </Typography>
            </Grid>
      <Grid item marginTop={errors["numberOfAttemptsAllowed"] ? "20px" : 0} xs={errors["numberOfAttemptsAllowed"] ? 2.3 : 1.3}>
              <Controller
                control={control}
                name="numberOfAttemptsAllowed"
                render={(prop) => (
                  <TextField
                    variant="outlined"
                    type="number"
                    {...prop.field}
                    sx={{ width: errors["numberOfAttemptsAllowed"] ? "150px" : "80px" }}
                    error={!!errors["numberOfAttemptsAllowed"]}
                    helperText={errors["numberOfAttemptsAllowed"]?.message}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      maxLength: 3,
                      onInput: (e) => {
                        e.preventDefault();
                        const inputValue = e.currentTarget.value;
                        const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                        const truncatedValue = sanitizedValue.slice(0, 4);
                        e.currentTarget.value = truncatedValue;
                        prop.field.onChange(truncatedValue);
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3.4} className="assessment-penality-text">
              <Typography variant="body1">
                {"times, then mark the status as"}
              </Typography>
            </Grid>
            <Grid item marginTop={errors["updateStatus"] ? "20px" : 0} xs={3.7}>
                  <SearchSelect
                      name={"updateStatus"}
                      label={<CustomLabel label={"Updated Status"} required={true} />}
                      error={errors["updateStatus"] ? true : false}
                      helperText={errors["updateStatus"]?.message}
                      options={statusList}
                      displayFieldKey={"name"}
                      storeFieldKey={"_id"}
                      capitalize={true}
                      keyUpperCase={true}
                      // disabled={isEditMode}
                      trigger={trigger}
                      setValue={setValue}
                      getValues={getValues}
                    />
            </Grid>
       </Grid>
     </CustomDialog>
     </Box>
  );
};

export default ManageAssessmentPenality;
