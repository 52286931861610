
import { glossary } from "../../endpoints";
import HttpService from "../../http";
import { IGlossariesResponse, IGlossaryResponse, IGlossarySummaryResponse } from "../../../interfaces/content-marketing/glossary";
const GlossaryService = () => {

    const { httpRequest } = HttpService();
    const getGlossariesList = async (search: object): Promise<IGlossariesResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossariesResponse>(
                "GET",
                `${glossary}/list`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    const getGlossary = async (query: object): Promise<IGlossaryResponse> => 
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryResponse>(
                "GET",
                `${glossary}`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });
    
    
    const addGlossary = async (payload: object): Promise<IGlossaryResponse> => 
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryResponse>(
                "POST",
                `${glossary}`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });
    
    
    const deleteGlossary = async (payload: object): Promise<IGlossaryResponse> =>
            new Promise((resolve, reject) => {
                httpRequest<IGlossaryResponse>(
                    "DELETE", 
                    `${glossary}`, 
                    payload
                )
                    .then(resolve)
                    .catch(reject);
            });
    
        const updateGlossary = async (payload: object): Promise<IGlossaryResponse> =>
            new Promise((resolve, reject) => {
                httpRequest<IGlossaryResponse>(
                    "PUT", 
                    `${glossary}`, 
                    payload
                )
                    .then(resolve)
                    .catch(reject);
            });
    
        const updateGlossaryStatus = async (payload: object): Promise<IGlossaryResponse> =>
            new Promise((resolve, reject) => {
                httpRequest<IGlossaryResponse>(
                    "PUT", 
                    `${glossary}/status`, 
                    payload
                )
                    .then(resolve)
                    .catch(reject);
            });
    
    
        const publishGlossary = async (payload: object): Promise<IGlossaryResponse> => new Promise((resolve, reject) => {
            httpRequest<IGlossaryResponse>(
                "POST",
                `${glossary}/publish`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });
    
        const scheduleGlossary = async (payload: object): Promise<IGlossaryResponse> => new Promise((resolve, reject) => {
            httpRequest<IGlossaryResponse>(
                "POST",
                `${glossary}/schedule`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });
    
        const getGlossarySummary = async (search: object): Promise<IGlossarySummaryResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossarySummaryResponse>(
                "GET",
                `${glossary}/summary`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });
    
        const saveChecks = async (payload: object): Promise<IGlossaryResponse> => new Promise((resolve, reject) => {
            httpRequest<IGlossaryResponse>(
                "POST",
                "config-cms/check-list-glossary-mapping",
                payload
            )
                .then(resolve)
                .catch(reject);
        });
    return { 
        getGlossariesList,
        addGlossary, 
        getGlossary,
        deleteGlossary,
        updateGlossary,
        updateGlossaryStatus,
        publishGlossary,
        scheduleGlossary,
        getGlossarySummary,
        saveChecks
    };

};

export { GlossaryService };