import HttpService from "../../http";
import { settings } from "../../endpoints";
import { ISitemapSettingResponse, ISitemapSettingsResponse } from "../../../interfaces/site-map/settings";

const SiteMapSettingService = () => {
    const { httpRequest } = HttpService();

    const getSiteMapSettings = async (search: object): Promise<ISitemapSettingsResponse> => new Promise((resolve, reject) => {
        httpRequest<ISitemapSettingsResponse>(
            "GET",
            `${settings}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getSiteMapSetting = async (search: object): Promise<ISitemapSettingResponse> => new Promise((resolve, reject) => {
        httpRequest<ISitemapSettingResponse>(
            "GET",
            `${settings}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteSiteMapSetting = async (query: object): Promise<ISitemapSettingsResponse> => new Promise((resolve, reject) => {
        httpRequest<ISitemapSettingsResponse>(
            "DELETE",
            `${settings}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const addSiteMapSetting = async (payload: object): Promise<ISitemapSettingsResponse> => new Promise((resolve, reject) => {
        httpRequest<ISitemapSettingsResponse>(
            "POST",
            `${settings}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateSiteMapSetting = async (payload: object): Promise<ISitemapSettingsResponse> => new Promise((resolve, reject) => {
        httpRequest<ISitemapSettingsResponse>(
            "PUT",
            `${settings}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getSiteMapSetting, deleteSiteMapSetting, addSiteMapSetting, updateSiteMapSetting, getSiteMapSettings };
};

export { SiteMapSettingService };