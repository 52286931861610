import { useQuery } from "@tanstack/react-query";
import { MouseEvent } from "react";
import { ChangeEvent, useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import { capitalize } from "../../../utilities/helper";
import {
  IPMSParameterData,
  IPmsParameterRow,
  IPmsParameterState,
} from "../../../interfaces/pms/pms-parameter";
import { PmsParametersService } from "../../../services/pms/pms-parameters";
import { Box, Tooltip } from "@mui/material";
import PMSParametersFilters from "./filters";
import CustomTypography from "../../../components/mui/max-length-limit";

interface outletProps {
  refetchParameters: () => void;
}

const PMSParameter = () => {
  let rows: IPmsParameterRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getPmsParameters } = PmsParametersService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IPmsParameterState>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filters: {
      search: "",
      type: [],
      frequency: [],
    },
    filterCount: 0,
  });

  const pmsParameters = useQuery({
    queryKey: ["all-pms-parameters", state.filters],
    queryFn: () =>
      getPmsParameters({
        pagination: false,
        ...state.filters,
      }),
  });
  const pmsParametersData =
    (pmsParameters && pmsParameters.data && pmsParameters.data.data) || [];

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    let filterCount = 0;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const type: { key: string; value: string }[] = searchParams.get("type")
      ? JSON.parse(String(searchParams.get("type")))
      : [];
    const frequency: { key: string; value: string }[] = searchParams.get(
      "frequency"
    )
      ? JSON.parse(String(searchParams.get("frequency")))
      : [];
    filterCount += type.length ? 1 : 0;
    filterCount += frequency.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      filters: {
        search,
        type: type.map((item) => item.key),
        frequency: frequency.map((item) => item.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "frequency",
      label: "Frequency",
    },
    {
      id: "type",
      label: "Type",
    },
    {
      id: "weightage",
      label: "Weightage(%)",
    },
    {
      id: "evaluator",
      label: "Evaluator",
    },
    {
      id: "_roles",
      label: "Roles",
    },
  ];

  const createRow = (index: number, parameter: IPMSParameterData) => {
    const _roles = (
      <Tooltip title={parameter._roles.map((ser) => ser.name).join(", ")}>
        <span>
          {parameter._roles.length > 0 ? (
            <>
              {capitalize(parameter._roles[0].name)}{" "}
              {parameter._roles.length > 1 && (
                <span style={{ color: "var(--primary-color)" }}>
                  +{parameter._roles.length - 1}
                </span>
              )}
            </>
          ) : (
            "-"
          )}
        </span>
      </Tooltip>
    );

    const name = (
      <CustomTypography
        limit={50}
        label={capitalize(parameter.name)}
        color="primary"
        onClick={
          resourceAllocate("pms-sub-parameter.read")
            ? () => {
                navigate({
                  pathname: `sub-parameters/${parameter._id}`,
                  search: searchParams.toString(),
                });
              }
            : undefined
        }
      />
    );

    return {
      id: (index + 1).toString(),
      name,
      frequency: capitalize(parameter.frequency || ""),
      type: capitalize(parameter.type || ""),
      weightage: parameter.weightage,
      evaluator: capitalize(parameter.evaluator || "-"),
      _roles,
    };
  };

  if (pmsParametersData.length) {
    rows = pmsParametersData.map((vendor, i) => createRow(i, vendor));
  }

  return (
    <div>
      {/* Add Data  */}
      <Header
        className="my-2"
        searchPlaceholder="Search by parameter name"
        onSearch={onSearch}
        btnText="Add/Edit Parameter"
        onBtnClick={
          resourceAllocate("pms-parameter.write")
            ? pmsParametersData.length > 0
              ? () => navigate("update")
              : () => navigate("new")
            : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <PMSParametersFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
      </Header>
      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 170px)"
          errorMessage="Add parameters to see the data here"
        />
      </Box>

      <Outlet
        context={{
          ...outlet,
          refetchParameters: pmsParameters.refetch,
          parametersData: pmsParametersData,
        }}
      />
    </div>
  );
};

export default PMSParameter;
