import "./style.scss";
import { useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import dayjs from "dayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { IErrorResponse, IUser } from "../../../../../interfaces";
import CustomDialog from "../../../../../components/mui/dialog";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CustomLabel from "../../../../../components/mui/custom-label";
import { capitalize } from "../../../../../utilities/helper";
import { okrKeyResultsService } from "../../../../../services/okr/objectives/key-results";
import SearchSelect from "../../../../../components/mui/search-select";
import {
  IKeyResultData,
  IKeyResultStateFieldsForm,
  KRFields,
} from "../../../../../interfaces/okr/objectives/key-results";
import { keyResultStateValidation } from "../../../../../validations/okr/objectives/key-results";
import { COMMON_ERROR_MESSAGES } from "../../../../../utilities/messages/common";

interface OutletProps {
  refetchKeyResults: () => void;
  keyResults: IKeyResultData[];
  objectiveName: string;
  objectiveDeparment: string;
}

const ManageOKRKeyResults = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<OutletProps>();
  const { keyResults, objectiveDeparment } = outlet;
  const { snackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { id, objectiveId } = useParams();
  const { addKeyResult, updateKeyResults } = okrKeyResultsService();
  const [deletedKeyResults, setDeletedKeyResults] = useState<string[]>([]);
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(
    (state) => state.user.list
  );

  const keyResultFields: KRFields[] = [
    {
      label: "Key Result",
      name: "name",
      type: "input",
      placeholder: "Enter key result name",
      required: true,
    },
    {
      label: "Description",
      name: "description",
      type: "input",
      placeholder: "Enter key result description",
      required: true,
    },
    {
      label: "Responsibility",
      name: "_assignee",
      type: "select",
      placeholder: "Enter _assignee name",
      required: true,
      options: users.filter((user) => user?.department?._id === objectiveDeparment).map((user) => ({
        key: user._id,
        value: capitalize(user.name),
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Start Date",
      name: "startDate",
      type: "date",
      required: true,
    },
    {
      label: "ETC",
      name: "endDate",
      type: "date",
      required: true,
    },
    {
      label: "Figure Type",
      name: "figureType",
      type: "select",
      placeholder: "Select figure type",
      required: true,
      options: [
        { key: "numeric", value: "Numeric" },
        { key: "percentage", value: "Percentage" },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Current Figure",
      name: "currentFigure",
      type: "input",
      placeholder: "Enter current figure",
      required: true,
    },
    {
      label: "Target Figure",
      name: "targetFigure",
      type: "input",
      placeholder: "Enter target figure",
      required: true,
    },
    {
      label: "Weightage (%)",
      name: "weightage",
      type: "input",
      placeholder: "Enter weightage",
      required: true,
    },
    {
      label: "Approver",
      name: "_approver",
      type: "select",
      placeholder: "Enter _approver name",
      required: true,
      options: users.filter((user) => user?.department?._id === objectiveDeparment).map((user) => ({
        key: user._id,
        value: capitalize(user.name),
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Priority",
      name: "priority",
      type: "select",
      placeholder: "Enter priority",
      required: true,
      options: [
        { key: "high", value: "High" },
        { key: "medium", value: "Medium" },
        { key: "low", value: "Low" },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
  ];

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    watch,
  } = useForm<IKeyResultStateFieldsForm>({
    resolver: joiResolver(keyResultStateValidation),
    defaultValues: {
      keyResults: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "keyResults",
  });

  useEffect(() => {
    if (id !== "new" && keyResults && keyResults.length > 0) {
      keyResults.forEach((keyResult) => {
        append({
          _id: keyResult._id,
          name: keyResult.name,
          description: keyResult.description,
          _assignee: keyResult?._assignee?._id,
          startDate: keyResult.startDate,
          endDate: keyResult.endDate,
          figureType: keyResult.figureType,
          priority: keyResult.priority,
          _approver: keyResult?._approver?._id,
          currentFigure: keyResult.currentFigure,
          targetFigure: keyResult.targetFigure,
          weightage: keyResult.weightage  
        });
      });
    } else if (id === "new") {
      append({
        _id: "",
        name: "",
        description: "",
        _assignee: "",
        startDate: "",
        endDate: "",
        figureType: "",
        priority: "",
        _approver: "",
        currentFigure: 0,
        targetFigure: 0,
        weightage: 0
      });
    }
  }, [id, keyResults]);

  const totalWeightage = watch("keyResults").reduce(
    (sum, keyResult) => sum + (Number(keyResult.weightage) || 0),
    0
  );

  const onSubmit = async (data: IKeyResultStateFieldsForm) => {
    if (totalWeightage !== 100) {
      snackbar(COMMON_ERROR_MESSAGES.en.invalid_weightage, "warning");
      return;
    }

    try {
      const payload = {
        keyResults: data.keyResults.map((keyResult) => ({
          _id: id === "new" ? undefined : keyResult._id || undefined,
          name: keyResult.name,
          description: keyResult.description,
          _assignee: keyResult._assignee,
          startDate: keyResult.startDate,
          endDate: keyResult.endDate,
          figureType: keyResult.figureType,
          priority: keyResult.priority,
          _approver: keyResult._approver,
          currentFigure: keyResult.currentFigure,
          targetFigure: keyResult.targetFigure,
          weightage: keyResult.weightage,
          _objective: objectiveId,
        })),
        _objective: objectiveId,  
        deletedKeyResults: id !== "new" ? deletedKeyResults : undefined,
      };

      if (id === "new") {
        const res = await addKeyResult(payload);
        snackbar(res.message, "info");
      } else {
        const res = await updateKeyResults(payload);
        snackbar(res.message, "info");
      }

      navigate({
        pathname: `/okr-objectives/key-results/${objectiveId}`,
        search: searchParams.toString(),
      });
      outlet?.refetchKeyResults && outlet.refetchKeyResults();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const selectDate = (
    value: string | number | Date | dayjs.Dayjs | null | undefined,
    name: string,
    rowIndex: number
  ) => {
    const date =
      value && dayjs(value).toString() !== "Invalid Date"
      ? (name === "startDate" ? dayjs(value)?.startOf("day")?.toISOString() : dayjs(value)?.endOf("day")?.toISOString())
        : undefined;
    const keyExist = name === "startDate" || name === "endDate";
    if (keyExist && date) {
      setValue(`keyResults.${rowIndex}.${name}`, date);
      trigger(`keyResults.${rowIndex}.${name}`);
    }
  };

  return (
    <CustomDialog
      title="Add/Edit Key Results"
      isOpen={true}
      onClose={() =>
        navigate({
          pathname: `/okr-objectives/key-results/${objectiveId}`,
          search: searchParams.toString(),
        })
      }
      onSubmit={handleSubmit(onSubmit)}
      size="xl"
    >
      <Box>
        <div className="note-box">
          <p className="note-text">
            <strong>Note: </strong>Please ensure that the total weightage of all
            key results combined equals 100%. You cannot save new key result if
            the total weightage exceeds or is less than 100%.
          </p>
        </div>

        {fields.map((field, rowIndex) => (
          <Box
            key={field.id}
            display="flex"
            alignItems="flex-start"
            gap={2}
            mb={2}
            mt={3}
          >
            <Grid container spacing={2}>
              {keyResultFields.map((field) => {
                if (field.type === "input") {
                  return (
                    <Grid item xs={12} md={3} key={`${rowIndex}-${field.name}`}>
                      <Controller
                        control={control}
                        name={`keyResults.${rowIndex}.${field.name}`}
                        render={({ field: formField }) => (
                          <TextField
                            {...formField}
                            label={
                              <CustomLabel
                                label={field.label}
                                required={field.required}
                              />
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                            type={field.name === "name" || field.name === "description" ? "text" : "number"}
                            placeholder={field.placeholder}
                            error={
                              !!errors.keyResults?.[rowIndex]?.[field.name]
                            }
                            helperText={
                              errors.keyResults?.[rowIndex]?.[field.name]
                                ?.message
                            }
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              let value = e.target.value;
                              if (field.name === "weightage") {
                                value = value.replace(/[^0-9]/g, "");
                                if (value !== "" && Number(value) > 100) {
                                  e.target.value = value.slice(0, -1); 
                                  return;
                                }
                              } else if (["currentFigure", "targetFigure"].includes(field.name)) {
                                value = value.replace(/[^0-9]/g, ""); 
                              }
                    
                              formField.onChange(value); 
                            }}
                            inputProps={{
                              onWheel: (e) => {
                                (e.target as HTMLElement).blur();
                                e.preventDefault();
                              },
                            }}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if(field.name !== "name" && field.name !== "description") {
                                if (["e", "E", "-"].includes(e.key)) {
                                  e.preventDefault(); 
                                }
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                  );
                } else if (field.type === "date") {
                  return (
                    <Grid item xs={12} md={3} key={`${rowIndex}-${field.name}`}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label={
                            <CustomLabel
                              label={field.label}
                              required={field?.required}
                            />
                          }
                          onChange={(e) => selectDate(e, field.name, rowIndex)}
                          value={dayjs(
                            getValues(`keyResults.${rowIndex}.${field.name}`)
                          )}
                          slotProps={{
                            textField: {
                              error:
                                !!errors.keyResults?.[rowIndex]?.[field.name],
                              helperText:
                                errors.keyResults?.[rowIndex]?.[field.name]
                                  ?.message,
                            },
                          }}
                          format="LL"
                        />
                      </LocalizationProvider>
                    </Grid>
                  );
                } else if (field.type === "select") {
                  return (
                    <Grid item xs={12} md={3} key={`${rowIndex}-${field.name}`}>
                      <SearchSelect
                        name={`keyResults.${rowIndex}.${field.name}`}
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field.required}
                          />
                        }
                        error={!!errors.keyResults?.[rowIndex]?.[field.name]}
                        helperText={
                          errors.keyResults?.[rowIndex]?.[field.name]?.message
                        }
                        options={field.options}
                        displayFieldKey={
                          field.displayFieldKey ? field.displayFieldKey : ""
                        }
                        storeFieldKey={
                          field.storeFieldKey ? field.storeFieldKey : ""
                        }
                        displayUserName={field.displayUserName}
                        trigger={trigger}
                        setValue={setValue}
                        getValues={getValues}
                      />
                    </Grid>
                  );
                }
              })}
              <Grid item xs={12} md={3}>
                <IconButton
                  onClick={() => {
                    if(field._id.length){
                      setDeletedKeyResults([...deletedKeyResults, field._id]);
                    }
                    remove(rowIndex);
                  }}
                  disabled={fields.length === 1}
                  color="error"
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Button
          variant="outlined"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() =>
            append({
              _id: "",
              name: "",
              description: "",
              _assignee: "",
              startDate: "",
              endDate: "",
              figureType: "",
              priority: "",
              _approver: "",
              currentFigure: 0,
              targetFigure: 0,
              weightage: 0,
            })
          }
        >
          Add Key Result
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default ManageOKRKeyResults;
