
import HttpService from "../../http";
import { glossaryComment } from "../../endpoints";
import { IGlossaryCommentReplyResponse, IGlossaryCommentResponses } from "../../../interfaces/content-marketing/glossary/comment";
const GlossaryCommentsService = () => {

    const { httpRequest } = HttpService();
    const getGlossaryCommentList = async (search: object): Promise<IGlossaryCommentResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryCommentResponses>(
                "GET",
                `${glossaryComment}/list`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addGlossaryComment = async (payload: object): Promise<IGlossaryCommentResponses> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryCommentResponses>(
            "POST",
            `${glossaryComment}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const addGlossaryCommentReply = async (payload: object): Promise<IGlossaryCommentReplyResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryCommentReplyResponse>(
            "POST",
            `${glossaryComment}/reply`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    return { addGlossaryComment, getGlossaryCommentList, addGlossaryCommentReply };

};

export { GlossaryCommentsService };