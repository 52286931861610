import { Box, DialogContent, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../components/shared/mui-tabs";
import { JobService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { IJobActivity } from "../../../../interfaces";
import { formatDate } from "../../../../utilities/helper";
import TimelineIcon from "@mui/icons-material/Timeline";
import dayjs from "dayjs";

const JobTimeline = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const { getJobTimeline } = JobService();
  const [searchParams] = useSearchParams();
  const jobTimeline = useQuery({
    queryKey: ["job-timeline"],
    queryFn: () => getJobTimeline({ _job: id })
  });
  const groupByDate = (array: IJobActivity[] | undefined) =>
    array && array.reduce((acc: { [key: string]: IJobActivity[] }, obj) => {
      const istDate = new Date(obj.createdAt);
      const dateKey = formatDate(istDate);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(obj);
      return acc;
    }, {});

  const activities = groupByDate(jobTimeline?.data?.data);
  const dateIsSame = (date1: Date | string, date2: Date | string) => dayjs(date1).startOf("day").isSame(dayjs(date2).startOf("day"));
  const onClose = () => {
    navigate({
      pathname: "/careers/jobs",
      search: searchParams.toString()
    });
  };

  return <div>
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      open={true}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Job Details</Typography>
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: "65vh" }}>
        <Box width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Box>
              <Tabs value={0} aria-label="basic tabs example">
                <Tab className="tabs-space start-tab-space" label="Timeline" />
              </Tabs>
            </Box>
          </Box>

          <TabPanel value={0} index={0}>
            <Box paddingTop="10px">
              <Box height="56vh" overflow="auto" paddingTop="10px">

                <div id="timeline" style={{ height: "calc(100% - 50px)", overflow: "scroll" }}>
                  {activities && Object.keys(activities).length ? (
                    Object.entries(activities).reverse().map(([date, activityArray], index) => (
                      <Box key={index} display="flex">
                        <Box
                          sx={{ backgroundColor: dateIsSame(date, new Date()) ? theme.palette.primary.main : theme.palette.grey[200] }}
                          className= {dateIsSame(date, new Date()) ? "text-white" : undefined}
                        >
                          <Typography
                            className="center h-100"
                            sx={{ width: "200px" }}
                            variant="h5"
                          >
                            {formatDate(date)}
                          </Typography>
                        </Box>
                        <div className="timelineBox w-100">
                          {[...activityArray].reverse().map((activity, idx) => (
                            <div
                              key={idx}
                              className="timelineDescBox py-3 pl-5"
                            >
                              <Typography className="time" sx={{ width: "150px" }} variant="body1">
                                {dayjs(activity.createdAt).format("LT")}
                              </Typography>
                              <Typography className="desc" variant="body2">
                                {activity.message}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Box>
                    ))
                  ) : (
                    <Box className="no-data center" height={"calc(100% - 30px)"}>
                      <TimelineIcon fontSize="large" className="error-icon" />
                      <Typography className="noData-title">No data to show!</Typography>
                      <Typography className="noData-sub-title">
                        Make an action to see the timeline here
                      </Typography>
                    </Box>
                  )}
                </div>
                <Box paddingTop={3} className="center" justifyContent="start">
                  <Box className="center" justifyContent="start">
                    <div className="identify-box primary-bg-color" />
                    <Typography className="ml-2" variant="body1">
                      Today
                    </Typography>
                  </Box>
                  <Box className="center" justifyContent="start" marginLeft="30px">
                    <div className="identify-box grey-color" />
                    <Typography className="ml-2" variant="body1">
                      Earlier Dates
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </TabPanel>

        </Box>
      </DialogContent>
    </BootstrapDialog>

  </div>;
};

export default JobTimeline;
