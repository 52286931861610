import HttpService from "../../http";
import { objectives } from "../../endpoints";
import { IOKRObjectivesResponse, IOKRObjectiveResponse } from "../../../interfaces/okr";

const OkrObjectivesService = () => {
    const { httpRequest } = HttpService();

    const getObjectives = async (search: object): Promise<IOKRObjectivesResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRObjectivesResponse>(
            "GET",
            `${objectives}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getObjective = async (search: object): Promise<IOKRObjectiveResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRObjectiveResponse>(
            "GET",
            `${objectives}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteObjective = async (query: object): Promise<IOKRObjectiveResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRObjectiveResponse>(
            "DELETE",
            `${objectives}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const addObjective = async (payload: object): Promise<IOKRObjectiveResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRObjectiveResponse>(
            "POST",
            `${objectives}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateObjective = async (payload: object): Promise<IOKRObjectiveResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRObjectiveResponse>(
            "PUT",
            `${objectives}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getObjectives, updateObjective, getObjective, addObjective, deleteObjective };
};

export { OkrObjectivesService };