
import { IAlternativeKeywordDensityResponse, IAlternativeScoreResponse } from "../../../interfaces/alternative-comparison/score";
import { alternativeComparisonScore, alternativeKeywordDensity } from "../../endpoints";
import HttpService from "../../http";
const AlternativeScoreService = () => {

    const { httpRequest } = HttpService();

    const addAlternativeScore = async (payload: object): Promise<IAlternativeScoreResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeScoreResponse>(
            "PUT",
            `${alternativeComparisonScore}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getAlternativeScore = async (query: object): Promise<IAlternativeScoreResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeScoreResponse>(
            "GET",
            `${alternativeComparisonScore}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getAlternativeKeywordDensity = async (search: object): Promise<IAlternativeKeywordDensityResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IAlternativeKeywordDensityResponse>(
                "GET",
                `${alternativeKeywordDensity}`,
                {},
                search,
                false
            )
                .then(resolve)
                .catch(reject);
        });

    return { addAlternativeScore , getAlternativeScore, getAlternativeKeywordDensity };
};

export { AlternativeScoreService };