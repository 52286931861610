import { FC, useEffect, useState } from "react";
import { Box, Button, capitalize, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { IPMSParametersFilters } from "../../../../interfaces/pms/pms-parameter";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    onClose: () => void;
}

const PMSParametersFilters: FC<props> = ({ anchorEl, isOpen, onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [state, setState] = useState<IPMSParametersFilters>({
        selectedMenu: 0,
        type: [],
        frequency: [],
    });

    useEffect(() => {
        const type: { key: string, value: string }[] = searchParams.get("type") ? JSON.parse(String(searchParams.get("type"))) : [];
        const frequency: { key: string, value: string }[] = searchParams.get("frequency") ? JSON.parse(String(searchParams.get("frequency"))) : [];

        setState(prevState => ({
            ...prevState,
            type,
            frequency,
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "type" | "frequency", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = !!state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "type" | "frequency", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            type: [],
            frequency: [],
        });
    };

    const onApply = () => {
        searchParams.set("type", JSON.stringify(state.type));
        searchParams.set("frequency", JSON.stringify(state.frequency));

        searchParams.set("page", "1");
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
        onClose();
    };

    const types = [
        { key: "ROLE-BASED", value: "ROLE BASED" },
        { key: "INDIVIDUAL", value: "INDIVIDUAL" },
    ];

    const frequencies = [
        { key: "WEEKLY", value: "WEEKLY" },
        { key: "BI-WEEKLY", value: "BI WEEKLY" },
        { key: "MONTHLY", value: "MONTHLY" },
        { key: "QUARTERLY", value: "QUARTERLY" },
    ];

    const handleClose = () => {
        const type: { key: string, value: string }[] = searchParams.get("type") ? JSON.parse(String(searchParams.get("type"))) : [];
        const frequency: { key: string, value: string }[] = searchParams.get("frequency") ? JSON.parse(String(searchParams.get("frequency"))) : [];
        setState(prevState => ({
            ...prevState,
            type,
            frequency,
        }));
        onClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.type.length || state.frequency.length) ?
                                <>

                                    {state.type.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("type", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.frequency.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("frequency", ele.key)} label={ele.value} variant="outlined" />)}

                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={handleClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            <MenuItem
                                index={0}
                                label="Type"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.type}
                            />
                            <MenuItem
                                index={1}
                                label="Frequency"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.frequency}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                types.map(status => <ListItemButton
                                    key={status.key}
                                    selected={!!state.type.find(ele => ele.key === status.key)}
                                    onClick={() => handleRightListItem("type", status.key, status.value)}
                                >
                                    <ListItemText primary={status.value} />
                                    <Checkbox edge="end" checked={!!state.type.find(ele => ele.key === status.key)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                frequencies.map(i => <ListItemButton
                                    key={i.key}
                                    selected={!!state.frequency.find(ele => ele.key === i.key)}
                                    onClick={() => handleRightListItem("frequency", i.key, capitalize(i.key))}
                                >
                                    <ListItemText primary={capitalize(i.key)} />
                                    <Checkbox edge="end" checked={!!state.frequency.find(ele => ele.key === i.key)} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default PMSParametersFilters;