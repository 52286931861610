import { Routes, Route } from "react-router-dom";
import AltetnativeComparison from "./list";
import AlternativeComparisonManage from "./manage";
import ChecklistReviewer from "./manage/score/checklist-reviewer";
import ChecklistPublish from "./manage/checklist-publish";
import ScheduleAlternative from "./manage/schedule";
import ImportAlternative from "./import";
import ChecklistWriter from "./manage/content/checklist-writer";

const AlternativeComparisonRouting = () => (
  <Routes>
    <Route path="/" element={<AltetnativeComparison />}>
      <Route path="/import" element={<ImportAlternative />} />
    </Route>
    <Route path="/manage/:id" element={<AlternativeComparisonManage />}>
      <Route path="/manage/:id/schedule" element={<ScheduleAlternative />} />
      <Route
        path="/manage/:id/checklistPublish"
        element={<ChecklistPublish />}
      />
      <Route path="/manage/:id/checklistWriter" element={<ChecklistWriter />} />
      <Route
        path="/manage/:id/checklistReviewer"
        element={<ChecklistReviewer />}
      />
    </Route>
  </Routes>
);

export default AlternativeComparisonRouting;
