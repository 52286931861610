import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, Outlet, useSearchParams } from "react-router-dom";
import useResource from "../../../hooks/useResource";
import { Box, Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../../components/shared/mui-tabs";
import useUser from "../../../hooks/useUser";
import { useQuery } from "@tanstack/react-query";
import { PmsParametersService } from "../../../services/pms/pms-parameters";

interface IState {
  redirectionLinks: string[];
}

const TeamEvaluationLayout = () => {
  const [value, setValue] = useState(0);
  const { user } = useUser();
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { resourceAllocate, isSuperAdmin } = useResource();
  const { getPmsParameters } = PmsParametersService();
  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  const pmsParameters = useQuery({
    queryKey: ["all-pms-parameters-data"],
    queryFn: () =>
      getPmsParameters({
        pagination: false,
      }),
  });

  const pmsParametersData =
    (pmsParameters && pmsParameters.data && pmsParameters.data.data) || [];

  useEffect(() => {
    const redirectionLinks: string[] = [];

    if (isSuperAdmin()) {
      redirectionLinks.push("overview", "feedback", ...pmsParametersData.map((p) => p._id));
    }
    else {
      if (resourceAllocate("pms-feedback-rating.read")){
        redirectionLinks.push("overview");
        redirectionLinks.push("feedback");
      }
  
      for (const parameter of pmsParametersData) {
        if (parameter.type === "ROLE-BASED" && parameter._roles.includes(user && user._role)) {
          redirectionLinks.push(parameter._id);
        } else if (parameter.type === "INDIVIDUAL") {
          if (
            (parameter.evaluator === "REPORTING-MANAGER" && user && user.isReportingManager) ||
            parameter.evaluator === "ALL"
          ) {
            redirectionLinks.push(parameter._id);
          }
        }
      }
    }

    setState((prev) => ({
      ...prev,
      redirectionLinks,
    }));
  }, [user, pmsParametersData]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (_: SyntheticEvent, newValue: number) => { 
    searchParams.delete("_parameter");
    setSearchParams(searchParams);
    navigate({
      pathname: state.redirectionLinks[newValue],
      search: searchParams.toString()
    });
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
          {state.redirectionLinks.map((link, index) => {
            const parameter = pmsParametersData.find((p) => p._id === link);
            const label = parameter ? parameter.name : link.charAt(0).toUpperCase() + link.slice(1);
            return (
              <Tab
                key={link}
                className={`tabs-space ${index === 0 ? "start-tab-space" : ""}`}
                {...a11yProps(index)}
                label={label}
              />
            );
          })}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default TeamEvaluationLayout;