import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { useNavigate ,useOutletContext, useSearchParams} from "react-router-dom";
import {Controller,useForm} from "react-hook-form";
import { IErrorResponse} from "../../../../../interfaces";
import { IEditSkillName,ISKillEditField } from "../../../../../interfaces/skill-matrix/edit-skill-matrix/edit-skill";
import { useQuery } from "@tanstack/react-query";
import { SkillMatrixService } from "../../../../../services";
import { FC, useEffect } from "react";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import { EditSkillValidation } from "../../../../../validations/skill-matrix/edit-skill-matrix/skill";
interface IOutletProps{
    reFetch: () => void;
}
interface ISkillMatrixProps{
    skillParameterId:string;
    isDisabled:boolean;
}

const EditSkill: FC<ISkillMatrixProps> = ({skillParameterId, isDisabled}) => {
    const {snackbar} = useSnackbar();
    const {getSkillItem,updateSkill } = SkillMatrixService();
    const outlet = useOutletContext<IOutletProps>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const onClose = () => {
        navigate({pathname:"/skill-matrix", search: searchParams.toString()});
    };

    const {control,
        handleSubmit,
        setValue,
        formState:{errors}
    } = useForm<IEditSkillName>({
        resolver: joiResolver(EditSkillValidation),
        defaultValues:{
            name:"",
            employees:"",
            skillCategory:"",
            skillItems:""
        }

    });
    const skill = useQuery({
        queryKey:["Skill"],
        queryFn:() => getSkillItem({_id:skillParameterId})
    });

    useEffect(() => {
        if(skill?.data){
            setValue("name",skill?.data?.data?.name ?? "");
            setValue("skillCategory",String(skill?.data?.data?.skillCategoriesCount) ?? "");
            setValue("skillItems",String(skill?.data?.data?.skillItemsCount) ?? "");
            setValue("employees",String(skill?.data?.data?.employeesCount) ?? "");
        }
    },[skill?.data]);

    const fields:ISKillEditField[] = [
        {
            name:"name",
            label:"Skill Name",
            type:"input",
            placeholder:"Enter Skill Name"
        },        
        {
            name:"skillCategory",
            label:"Category",
            type:"input",
        },        
        {
            name:"skillItems",
            label:"Skill Items",
            type:"input",
        },        
        {
            name:"employees",
            label:"Employees",
            type:"input",
        }
    ];

    const onSubmit = async (data:IEditSkillName) => {
        try{
            const payload = {
                name: data.name, _id:skillParameterId
            };
            const response = await updateSkill(payload);
            snackbar(response.message,"info");
            navigate("/skill-matrix");
            outlet?.reFetch && outlet.reFetch();
        }catch(error){
         const err = error as IErrorResponse;
         if(err?.data?.message === "Name already exists"){
            return snackbar("Skill Matrix name already exists","warning");
         }
         console.log("Error in edit skill",err);
        }
    };
    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
            { fields.map((field) =>{
            if(field.type==="input"){
                return(
                    <Grid key={field.label} item xs={6} mt={2}>
                        <Controller
                            name={field.name}
                            control={control}
                            render = {(prop) => ( 
                                <TextField
                                label={field.label}
                                className="disable-text"
                                variant="outlined"
                                disabled = {isDisabled || !!(field.name === "skillItems" || field.name === "skillCategory" || field.name === "employees")}
                                size="small"
                                placeholder={field.placeholder}
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                                {...prop.field}    
                                />                               
                            )  }
                            />
                    </Grid>
                );
            }
            })}
            <>
            { !isDisabled &&  <Box className="action-box">
                    <Divider sx={{ marginBottom: "20px" }} />
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </Box> }
            </>
        </Grid>
        </form>
        </>

    );
};

export {EditSkill};