
import { IOrganisationConfigurationTheme } from "../../../interfaces/organisationConfiguration/basic-details";
import { THEME_CONFIGURATION } from "../../actions";

interface IAction {
    type: "THEME_CONFIGURATION",
    payload: IOrganisationConfigurationTheme
}

interface IState {
    list: IOrganisationConfigurationTheme | null
}

const initialState: IState = {
    list: null
};

const themeConfigurationReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case THEME_CONFIGURATION: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { themeConfigurationReducer };
