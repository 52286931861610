import { Avatar, Box, Drawer, IconButton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import { NotificationService } from "../../services";
import CloseIcon from "@mui/icons-material/Close";
import useUser from "../../hooks/useUser";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { ICandidate } from "../../interfaces";
import useSnackbar from "../../hooks/useSnackbar";
import { capitalize } from "../../utilities/helper";
import { useNavigate } from "react-router-dom";
import "./style.scss";
dayjs.extend(calendar);

interface props {
    isOpen: boolean;
    count: number;
    reFetchCount: () => void;
    onClose: () => void;
}

const Notification: FC<props> = ({ isOpen, count, reFetchCount, onClose }) => {
    const { snackbar } = useSnackbar();
    const { user } = useUser();
    const navigate = useNavigate();
    const { getNotifications, updateNotification } = NotificationService();
    const [expanded, setExpanded] = useState("");

    const notifications = useQuery({
        queryKey: ["notifications"],
        queryFn: () => getNotifications({ pagination: true, page: 1, limit: 50 })
    });

    const onReadNotification = async (_id: string) => {
        try {
            const update = await updateNotification({ notificationId: _id, updateAll: false });
            snackbar(update.message, "info");
            notifications.refetch();
            reFetchCount();
        } catch (error) {
            console.log("Error in notification ", error);
        }
    };

    const onReadAllNotifications = async () => {
        try {
            const update = await updateNotification({ updateAll: true });
            snackbar(update.message, "info");
            notifications.refetch();
            reFetchCount();
            onClose();
        } catch (error) {
            console.log("Error in notification ", error);
        }
    };

    const checkIsRead = (notify: { _id: string, isRead: boolean }[], _id?: string) => {
        let isRead = false;
        if(_id) {
            setExpanded(_id);
        }
        if(expanded === _id) {
            setExpanded("");
        }
        notify?.map((ele) => {
            if (ele._id === user?._id) {
                isRead = ele.isRead;
            }
        });
        return isRead;
    };

    const navigateToCandidate = (candidate: ICandidate) => {
        const nav = `/candidates/${candidate.typeOfLead?.toLowerCase()}/manage/${candidate._id}`;
        navigate(nav);
        onClose();
    };

    return (
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <Box className="drawer-box">
          <Box className="center sticky-header">
            <Typography variant="subtitle1">All Notifications</Typography>
            <Box className="mark-all-box">
              {count ? (
                <div onClick={onReadAllNotifications}>
                  <Typography className="mark-all-read" variant="subtitle2">
                    Mark all as read
                  </Typography>
                </div>
              ) : null}
              <IconButton
                className="icon-button"
                onClick={() => {
                    setExpanded("");
                    onClose();
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box className="notifications">
          {notifications.data?.data?.map((notification, index) => (
            <Box
              key={index}
              className={
                checkIsRead(notification?.notifyTo)
                  ? "accordion accordion-read"
                  : "accordion accordion-unread"
              }
              onClick={() =>
                checkIsRead(notification?.notifyTo, notification?._id)
                  ? undefined
                  : onReadNotification(notification._id)
              }
            >
              {expanded === notification._id ? (
                <Box className="expanded-box">
                  <Avatar className="avatar" src={user?.image}>
                    {user?.image
                      ? user.image
                      : user?.firstName && user?.firstName[0]?.toUpperCase()}
                  </Avatar>
                  {notification.category !== "ASSESSMENT" &&
                    notification.category !== "USER" && (
                      <div>
                        <Typography fontSize={14}>
                          {notification.message}
                        </Typography>
                        {notification.category !== "USER" &&
                        notification.category !== "ASSESSMENT" &&
                        notification.category !== "GLOSSARY" &&
                        notification.category !== "BLOG" &&
                        notification.category !== "RESOURCE_REQUEST" &&
                        notification.category !== "ALTERNATIVE_COMPARISON" &&
                        notification.category !== "COURSE_ASSIGNMENT" && (
                          <div className="mt-2">
                            <Typography variant="body1" fontSize={12}>
                              Name:{" "}
                              <span
                                onClick={() =>
                                  navigateToCandidate(notification._lead)
                                }
                                className="link-none-underline primary-color fw-bold"
                              >
                                {capitalize(notification._lead?.name)}
                              </span>
                            </Typography>
                            <Typography variant="body1" fontSize={12}>
                              Email: {notification._lead?.email}
                            </Typography>
                          </div>
                        )}
                        <Typography
                          className="timestamp"
                          variant="caption"
                          fontSize={12}
                        >
                          {dayjs(dayjs(notification.createdAt)).calendar()}
                        </Typography>
                      </div>
                    )}
                  {notification.category === "ASSESSMENT" && (
                    <div>
                      <Typography fontSize={14}>
                        {notification.message.split("View report:")[0]}
                      </Typography>
                      <Typography fontSize={14}>
                        <strong>View report: </strong>{" "}
                        <a
                          href={notification.message
                            .split("View report:")[1]
                            .trim()}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "var(--primary-color)",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Click here
                        </a>
                      </Typography>
                      <Typography
                        className="timestamp"
                        variant="caption"
                        fontSize={12}
                      >
                        {dayjs(dayjs(notification.createdAt)).calendar()}
                      </Typography>
                    </div>
                  )}
                  {notification.category === "USER" && (
                    <div>
                      <Typography fontSize={14}>
                        {
                          notification.message.split(
                            "See user requests for more details"
                          )[0]
                        }
                      </Typography>
                      <Typography fontSize={14}>
                        <strong>View details: </strong>
                        <a
                          href={`${process.env.REACT_APP_URL}user-requests`}
                          rel="noopener noreferrer"
                          style={{
                            color: "var(--primary-color)",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Click here
                        </a>
                      </Typography>
                      <Typography
                        className="timestamp"
                        variant="caption"
                        fontSize={12}
                      >
                        {dayjs(dayjs(notification.createdAt)).calendar()}
                      </Typography>
                    </div>
                  )}
                </Box>
              ) : (
                <Box className="expanded-box">
                  <Avatar className="avatar" src={user?.image}>
                    {user?.image
                      ? user.image
                      : user?.firstName && user?.firstName[0]?.toUpperCase()}
                  </Avatar>
                  <Box className="content">
                    <div className="message-preview">
                      <Typography variant="body1" fontSize={14}>
                        {notification.message.length > 35
                          ? notification.message.slice(0, 35) + "..."
                          : notification.message}
                      </Typography>
                    </div>
                    <Typography
                      className="timestamp"
                      variant="caption"
                      fontSize={12}
                    >
                      {dayjs(dayjs(notification.createdAt)).calendar()}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
          </Box>
        </Box>
      </Drawer>
    );
    
};

export default Notification;