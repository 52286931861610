import { useState, useEffect, ChangeEvent } from "react";
import {
  useNavigate,
  Outlet,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";
import {
  IContentCategoryData,
  IContentCategoryRow,
  IContentCategoryState,
} from "../../interfaces/category-content";
import useSnackbar from "../../hooks/useSnackbar";
import { ContentCategoryService } from "../../services/category-content";
import { useQuery } from "@tanstack/react-query";
import { IErrorResponse, IPagination } from "../../interfaces";
import GetActions from "../../components/get-actions";
import { capitalize, createIndex } from "../../utilities/helper";
import Header from "../../components/header";
import CustomTable from "../../components/mui/table";
import WarningDialog from "../../components/mui/warning-dialog";
import CustomTypography from "../../components/mui/max-length-limit";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
interface outletProps {
  refetchContentCategories: () => void;
}

const ContentCategory = () => {
  let rows: IContentCategoryRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const {
    getContentCategories,
    deleteContentCategory,
    updateContentCategoryStatus,
  } = ContentCategoryService();
  const [state, setState] = useState<IContentCategoryState>({
    deleteWarning: false,
    _categoryContent: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
  });

  const categoriesContent = useQuery({
    queryKey: ["allContent", state.pagination.page],
    queryFn: () =>
      getContentCategories({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
      }),
  });

  useEffect(() => {
    if (categoriesContent.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: categoriesContent.data.meta.page,
          totalPages: categoriesContent.data.meta.totalPages,
          totalRecords: categoriesContent.data.meta.totalRecords,
        },
      }));
    }
  }, [categoriesContent.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "category",
      label: "Category",
    },
    {
      id: "product",
      label: "Product",
    },
    {
      id: "priority",
      label: "Priority",
    },
    {
      id: "slug",
      label: "Slug",
    },
    {
      id: "category_para",
      label: "Category Para",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const handleDelete = (_categoryContent = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _categoryContent,
    }));
  };

  const onDelete = async () => {
    try {
      const category = await deleteContentCategory({
        ids: [state._categoryContent],
      });
      snackbar(category.message, "info");
      handleDelete();
      categoriesContent.refetch();
      outlet?.refetchContentCategories && outlet.refetchContentCategories();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  useEffect(() => {
    if (categoriesContent.data && categoriesContent.data.data && categoriesContent.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [categoriesContent.data && categoriesContent.data.data]);

  const handleStatus = async (
    event: SelectChangeEvent<string>,
    _id: string
  ) => {
    const { name, value } = event.target;

    try {
      const payload = {
        _id,
        [name]: value,
      };
      const response = await updateContentCategoryStatus(payload);
      snackbar(response.message, "info");
      categoriesContent.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const createRow = (
    index: number,
    bundle: IContentCategoryData,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: `${bundle._id}`,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("cms-category-content.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(bundle._id),
            disabled: !resourceAllocate("cms-category-content.remove") || bundle.status === "PUBLISHED",
          },
        ]}
      />
    );

    const status = (
      <FormControl sx={{ width: 150 }}>
        <Select
          size="small"
          name="status"
          onChange={(e) => handleStatus(e, bundle?._id)}
          value={bundle?.status}
          disabled={!resourceAllocate("cms-category-content.write")}
        >
          <MenuItem disabled value="none">
            Select
          </MenuItem>
          <MenuItem value="DRAFT">Draft</MenuItem>
          <MenuItem value="PUBLISHED">Published</MenuItem>
        </Select>
      </FormControl>
    );

    return {
      id: createIndex(pagination, index),
      product: capitalize(bundle._product.name),
      category: capitalize(bundle._category.name),
      priority: bundle.priority,
      slug: bundle.slug,
      category_para: (
        <CustomTypography limit={60} label={bundle.category_para} />
      ),
      status,
      action,
    };
  };

  if (
    categoriesContent &&
    categoriesContent.data &&
    categoriesContent.data.data &&
    categoriesContent.data.data.length
  ) {
    rows = categoriesContent.data.data.map((bundle, i) =>
      createRow(i, bundle, state.pagination)
    );
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className="my-2"
        btnText="Add Category Content"
        onBtnClick={
          resourceAllocate("cms-category-content.write")
            ? () => navigate({
              pathname: "new",
              search: searchParams.toString(),
            })
            : undefined
        }
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 208px)"
          errorMessage="Add category content to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Category Content"
        description="Are you sure you want to delete this category content?"
      />

      <Outlet context={{ ...outlet, reFetch: categoriesContent.refetch }} />
    </>
  );
};

export default ContentCategory;
