import useSnackbar from "../../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../../interfaces";
import KeywordService from "../../../../services/content/keyword";
import ProjectsService from "../../../../services/content/projects-products";
import { CMS_ERROR_MESSAGES } from "../../../../utilities/messages";
import { COMMON_ERROR_MESSAGES } from "../../../../utilities/messages/common";


const usevalidateGlossaryKeywords = () => {
    const { getContentProjectPartial } = ProjectsService();
    const {getContentKeywordPartial} = KeywordService();
    const { snackbar } = useSnackbar();
    // eslint-disable-next-line
    const validateGlossaryKeyword = async (leads: any[]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            try{
            const mandatoryFields = ["slug", "primaryKeyword", "secondaryKeywords", "_product"];
            const payload = [];
            const error = [];
            let row = 2;
            if(!leads[0].length){
                error.push([COMMON_ERROR_MESSAGES.en.empty_excel_file]);
            }

            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                // eslint-disable-next-line
                const data: any = {};
                const secKeywords = [];
                // eslint-disable-next-line
                const errMessage: any = {};


                if (typeof lead[0] === "string") {
                    data.slug = lead[0];
                } else {
                    if (lead[0] === undefined) {
                        errMessage["Slug"] = "Slug is required";
                    }
                    else {
                        errMessage["Slug"] = "Slug must be string";
                    }
                }

 
                if (typeof lead[1] === "string") {
                    try {
                        const project = await getContentProjectPartial({
                            search: lead[1].trim(),
                        });
                        if (project.data?._id) {

                            data._product = project.data?._id;

                        } else {
                            errMessage["Product"] = CMS_ERROR_MESSAGES.en.invalid_product_name;
                        }
                    } catch (error) {
                        errMessage["Product"] = CMS_ERROR_MESSAGES.en.invalid_product_name;
                    }
                } else {
                    if (lead[1] !== undefined) {
                        errMessage["Product"] = CMS_ERROR_MESSAGES.en.invalid_product_name;
                    }
                }


                if (typeof lead[2] === "string") {
                    try {
                        if(!data._product){
                            errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_mapping;
                        }else{
                            const keyword = await getContentKeywordPartial({
                                search: lead[2].trim(),
                                _product: data._product,
                            });
                            if (keyword.data?._id) {
    
                                data.primaryKeyword = keyword.data?._id;
    
                            } else {
                                errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_mapping;
                            }
                        }
                    } catch (error) {
                        errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_primary_keyword_name;
                    }
                } else {
                    if (lead[2] !== undefined) {
                        errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_primary_keyword_name;
                    }
                }

                if(typeof lead[3] !== "string" && lead[3] !== undefined){
                    errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_secondary_keyword_name;
                }else{
                    let ind = 3;
                    try {
                        while(ind < lead.length){
                            if(lead[ind]){
                                if(!data._product){
                                    errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_mapping;
                                } else{
                                    const keyword = await getContentKeywordPartial({
                                        search: lead[ind].trim(),
                                        _product: data._product,
                                    });
                                    if (keyword.data?._id) {
                                        secKeywords.push( keyword.data?._id);
                                    } else {
                                        errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_mapping;
                                    }
                                }
                            }
                            ind++;
                        }
                        data.secondaryKeywords = secKeywords;
                    } catch (error) {
                         errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_secondary_keyword_name;
                    }
                }

                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            // return all the data
            resolve({
                data: payload,
                errorMessage: error,
            });
        }
        catch(error){
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }
        });

    return { validateGlossaryKeyword };
};
export default usevalidateGlossaryKeywords;
