import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams, useSearchParams, } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import EditLable from "./manage";
import { SkillMatrixService } from "../../../../services";
import useResource from "../../../../hooks/useResource";
import { capitalize } from "../../../../utilities/helper";
import { a11yProps, BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../components/shared/mui-tabs";
import UserTimeline from "../../../organisation/organisation-users/manage-user/timeline";
import { IState } from "../../../../interfaces/shared/tab-panel";

const ManageSkillItemLayout = () => {
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const { getSkillLable } = SkillMatrixService();
  const { labelId, id } = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const isTimeline = searchParam.get("type") === "timeline";
  const [isDisable, setIsDisable] = useState(true);
  const getLable = useQuery({ queryKey: ["get-skill-lable"], queryFn: () => getSkillLable({ _id: labelId }),  });


  const [value, setValue] = useState(0);

  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
    if(resourceAllocate("skill-matrix-item.read")){
      redirectionLinks.push("basic-detail");
      redirectionLinks.push("timeline");
    }

      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }

    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);
  

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
  };

  const onClose = () => {
    searchParam.delete("type");
    navigate({ pathname: "/skill-matrix/manage/"+id, search: searchParam.toString() });
  };

  const name = getLable?.data?.data?.name ? capitalize(getLable?.data?.data?.name) : "";

return <div>
    <BootstrapDialog
      maxWidth="md"
      onClose={onClose}
      open={!!id}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          {name} { isDisable && !isTimeline && <IconButton 
          className="ml-2" color="primary" onClick={() => setIsDisable(prev => !prev)} ><EditIcon /></IconButton>}
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: isTimeline ? "65vh" : "30vh" }}>
        <Box width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {(resourceAllocate("skill-matrix-item.read"))&& <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />}
                {resourceAllocate("skill-matrix-item.read") && <Tab className="tabs-space" label="Timeline" {...a11yProps(2)} />}
              </Tabs>    
          </Box>

          {/* Basic detail  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={state.redirectionLinks.indexOf("basic-detail")}>
              <EditLable labelId= {labelId || ""} isDisabled={isDisable} />
          </TabPanel>

          {/* Timeline details  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={state.redirectionLinks.indexOf("timeline")}>
            <UserTimeline _skillItem={labelId || ""} />
          </TabPanel>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  </div>;
};

export default ManageSkillItemLayout;