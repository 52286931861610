import HttpService from "../../http";
import { assessmentMedia } from "../../endpoints";
import { IAssessmentMediaListResponse, IAssessmentMediaResponse } from "../../../interfaces/configuration/assessment-media";

const AssessmentMediaService = () => {
	const { httpRequest } = HttpService();

	const addAssessmentMedia = async (payload: object): Promise<IAssessmentMediaResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentMediaResponse>(
			"POST",
			`${assessmentMedia}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const updateAssessmentMedia = async (payload: object): Promise<IAssessmentMediaResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentMediaResponse>(
			"PUT",
			`${assessmentMedia}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentMedia = async (query: object): Promise<IAssessmentMediaResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentMediaResponse>(
			"GET",
			`${assessmentMedia}`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentMediaList = async (search: object): Promise<IAssessmentMediaListResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentMediaListResponse>(
			"GET",
			`${assessmentMedia}/list`,
			{},
			search
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteAssessmentMedia = async (payload: object): Promise<IAssessmentMediaResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentMediaResponse>(
			"DELETE",
			`${assessmentMedia}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	return { addAssessmentMedia, getAssessmentMedia, updateAssessmentMedia, getAssessmentMediaList, deleteAssessmentMedia };
};

export { AssessmentMediaService };
