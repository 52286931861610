
import HttpService from "../../http";
import { alternativeComparison } from "../../endpoints";
import { IAlternativeComparisonResponse, IAlternativeSampleResponse } from "../../../interfaces/alternative-comparison";


const AlternativeImportService = () => {

    const { httpRequest } = HttpService();
    const getAlternativeSample = async (search: object): Promise<IAlternativeSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IAlternativeSampleResponse>(
                "GET",
                `${alternativeComparison}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });
    const importAlternative = async (payload: object): Promise<IAlternativeComparisonResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IAlternativeComparisonResponse>(
                "POST",
                `${alternativeComparison}/import`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    return { getAlternativeSample, importAlternative };

};

export { AlternativeImportService };