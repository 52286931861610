import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import "./style.scss";

import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  InputAdornment,
  Radio,
} from "@mui/material";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TestInputDialog from "../../../../../../../../components/mui/dialog/testInputDialog";
import NumberInputIntroduction from "../../../../../../../../components/mui/custom-number-input";
import { useQuery } from "@tanstack/react-query";
import { TestsService } from "../../../../../../../../services/configuration/tests";
import { testQuestionValidation } from "../../../../../../../../validations/configuration/test/test-question";
import { joiResolver } from "@hookform/resolvers/joi";
import { ITestCategories } from "../../../../../../../../interfaces/configuration/tests";

interface Field {
  label: string;
  name: "question" | "_category";
  type: "input" | "select";
  placeholder: string;
  options?: ITestCategories[];
}


interface Answer {
  option: string;
  weightage: number;
  id: number;
}

interface ITestQuesData {
  options: Answer[];
  question: string;
  _category: string;
}
const ViewQuestion: React.FC = () => {
  const { control, setValue, formState: { errors } } = useForm<ITestQuesData>(
    {
      resolver: joiResolver(testQuestionValidation),
      defaultValues: {
        question: "",
        _category: "",
        options: [{ option: "", weightage: 0, id: 1 }],
      }
    }
  );
  const navigate = useNavigate();
  const { getTestCategoryList, getSingleTestQuestion } = TestsService();
  const [searchParam] = useSearchParams();

  const [answers, setAnswers] = useState<Answer[]>([
    { option: "", weightage: 0, id: 1 },
  ]);

  useEffect(() => () => setAnswers([]), []);

  const { categoryQuesId, testId } = useParams();
  const categories = useQuery({
    queryKey: ["test-category"],

    queryFn: () => getTestCategoryList({
      _test: testId,
    })
  });

  const question = useQuery({
    queryKey: ["question-data"],

    queryFn: () => getSingleTestQuestion({
      _id: categoryQuesId,
    }),
    enabled: !(categoryQuesId === "new")
  });
  const fields: Field[] = [
    {
      label: "Question",
      name: "question",
      type: "input",
      placeholder: "Question?",
    },
    {
      label: "Question Category",
      name: "_category",
      type: "select",
      placeholder: "options",
      options: categories?.data?.data || [],
    },
  ];


  useEffect(() => {
    if (categoryQuesId !== "new" && question?.data?.data) {
      setValue("question", question?.data?.data?.question);
      setValue("_category", question?.data?.data?._category?._id);
      setValue("options", question?.data?.data?.options.map((option, index) => ({
        option: option.option,
        weightage: option.weightage,
        id: index + 1,
      })));
      setAnswers(question?.data?.data?.options.map((option, index) => ({
        option: option.option,
        weightage: option.weightage,
        id: index + 1,
      })));
    }
  }, [categoryQuesId, question?.data]);



  const onClose = () => {
    navigate({
      pathname: `/assessment/tests/test-info/manage/${testId}`,
      search: searchParam.toString()
    });
  };


  // Custom toolbar options
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"]
    ],
  };

  return (
    <TestInputDialog
      title={categoryQuesId !== "new" ? "View Question" : "Add Question"}
      isOpen={!!categoryQuesId}
      onClose={onClose}
    >

      <Grid container spacing={2} className="view-question" sx={{ padding: "0.5rem" }}>
        <Grid item xs={12}>
          <Typography variant="body1">Question?</Typography>
        </Grid>
        {fields.map((field, index) => (
          <Grid item xs={field.type === "select" ? 4 : 8} key={index}>
            <Controller
              name={field.name}
              control={control}
              render={({ field: controllerField }) => (
                <>
                  {field.type === "select" ? (
                    <TextField
                      {...controllerField}
                      select
                      label={field.label}
                      fullWidth
                      variant="outlined"
                      disabled={true}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      InputProps={{
                        style: {
                          height: "46px",
                        },
                      }}
                    >
                      {(field.options || []).map((option: ITestCategories) => (
                        <MenuItem key={option._id} value={option._id} disabled={true}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <div className={errors[field.name] ? "ql-editor-error" : ""}>
                      <ReactQuill
                      modules={modules}
                        value={controllerField.value}
                        readOnly={true}
                        onChange={controllerField.onChange}
                        placeholder={field.placeholder}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                      />
                      {errors[field.name] && (
                        <Typography color="error" variant="caption">
                          {errors[field.name]?.message as React.ReactNode}
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              )}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="body1">Options:</Typography>
        </Grid>

        {answers.map((answer, index) => (
          <React.Fragment key={answer.id}>
            <Grid item xs={9} >
              <TextField
                type="input"
                placeholder="options"
                disabled={true}
                fullWidth
                value={answer.option}
                error={!!(errors?.options && errors.options[index]?.option)} // Check for option errors
                helperText={errors?.options && errors.options[index]?.option?.message}
                style={{ height: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Radio
                        checked={false}
                        disabled={true}
                        inputProps={{ "aria-label": "Select Correct Answer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3} className="weightage">
              <Grid item xs={4} >
                {<NumberInputIntroduction initialValue={answer.weightage} index={index} />}
              </Grid>
              <Grid item xs={6} >
                <Typography
                  variant="body1"
                  style={{ fontSize: "16px", height: "60%", paddingLeft: "0.5rem", color: "#666666" }}
                >
                  points
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </TestInputDialog>


  );
};

export default ViewQuestion;
