import { FC, useEffect } from "react";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { SkillItemCategoryService } from "../../../services/skill-matrix/skill-items-categories";
import useSnackbar from "../../../hooks/useSnackbar";
import { ISkillItemCategory, ISkillItemCategoryFields } from "../../../interfaces/skill-matrix/add-skill-matrix";
import { skillItemCategoryValidation } from "../../../validations/skill-matrix/skill-item-category";
import { IErrorResponse } from "../../../interfaces";
import CustomLabel from "../../../components/mui/custom-label";
import { ISkillCategory } from "../../../interfaces/skill-matrix/categories";
interface outletProps {
    reFetch: () => void;
    refetchSkillItemCategories: () => void;
    reFetchSkillItemData: () => void;
    reFetchUserSkills: () => void;
}

interface ISkillCategoryProps{
    data: ISkillCategory | undefined;
    isDisabled: boolean;
}
const ManageSkillItemCategory: FC<ISkillCategoryProps> = ({data, isDisabled}) => {
    const { id, categoryId } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const navigate = useNavigate();
    const { updateSkillItemCategory } = SkillItemCategoryService();
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<ISkillItemCategory>({
        resolver: joiResolver(skillItemCategoryValidation),
        defaultValues: {
            name: "",
            skillItems: 0,
            employees: 0,
        }
    });

    useEffect(() => {
            if (data) {
                setValue("name", data.name || "");
                setValue("skillItems", data.skillItemsCount || 0);
                setValue("employees", data.employeesCount || 0);
            }
    }, [data]);

    const onSubmit = async (data: ISkillItemCategory) => {
        try {
            const payload = { name: data.name, _id: categoryId, _skillParameter: id };
            const update = await updateSkillItemCategory(payload);
            snackbar(update.message, "info");
            navigate({
                pathname: `/skill-matrix/manage/${id}`,
                search: searchParam.toString()
            });
            outlet?.reFetch && outlet.reFetch();
            outlet?.reFetchSkillItemData && outlet.reFetchSkillItemData();
            outlet?.reFetchUserSkills && outlet.reFetchUserSkills();
            
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }

    };

    const onClose = () => {
        navigate({
            pathname: `/skill-matrix/manage/${id}`,
            search: searchParam.toString()
        });
    };

    const fields: ISkillItemCategoryFields[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type your category name here",
            required: true
        }, {
            label: "Skill Items",
            name: "skillItems",
            type: "input",
        }, {
            label: "Employees",
            name: "employees",
            type: "input",
        },
    ];

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4} mt={1}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            disabled={isDisabled || !!(field.name === "skillItems" || field.name === "employees")}
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            inputProps={{maxLength: 60}}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
                <>
              { !isDisabled &&  <Box className="action-box">
                    <Divider sx={{ marginBottom: "20px" }} />
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </Box> }
            </>
            </form>
        </Box>
    );
};

export default ManageSkillItemCategory;