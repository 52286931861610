import { useQuery } from "@tanstack/react-query";
import { UsersService } from "../../../services";
import { Box, Switch, Typography, IconButton } from "@mui/material";
import { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { IErrorResponse, IPagination, IUser, IUserRow, IUserState } from "../../../interfaces";
import {
  capitalize,
  createIndex,
  formatMobileNumber,
  displayName,
} from "../../../utilities/helper";
import UserFilter from "./filter";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WarningDialog from "../../../components/mui/warning-dialog";
import CustomTypography from "../../../components/mui/max-length-limit";

interface outletProps {
  refetchUsers: () => void;
}

const User = () => {
  let rows: IUserRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getUsers, updateUser, deleteUser } = UsersService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IUserState>({
    deleteWarning: false,
    _user: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
      _role: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filterCount: 0,
  });

  const users = useQuery({
    queryKey: ["allUsers", state.pagination.page, state.filters],
    queryFn: () =>
      getUsers({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        ...state.filters,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (users.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: users.data.meta.page,
          totalPages: users.data.meta.totalPages,
          totalRecords: users.data.meta.totalRecords,
        },
      }));
    }
  }, [users.data?.meta]);

  useEffect(() => {
    if (users.data && users.data.data && users.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [users.data && users.data.data]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const _role: { key: string; value: string }[] = searchParams.get("_role")
      ? JSON.parse(String(searchParams.get("_role")))
      : [];
    filterCount += _role.length ? 1 : 0;
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        _role: _role.map((_role) => _role.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_user = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _user,
    }));
  };

  const onDelete = async () => {
    try {
      const user = await deleteUser({ _id: state._user });
      snackbar(user.message, "info");
      handleDelete();
      users.refetch();
      outlet?.refetchUsers && outlet.refetchUsers();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const onStatusUpdate = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const status = checked ? "ACTIVE" : "INACTIVE";
    try {
      const user = await updateUser({ _id: id, status });
      snackbar(user.message, "info");
      users.refetch();
      outlet?.refetchUsers && outlet.refetchUsers();
    } catch (error) {
      console.log("Error in user status update", error);
    }
  };

  const onCopy = (label: string, text: string) => {
    navigator.clipboard.writeText(text);
    snackbar(`${capitalize(label)} ID copied`, "info");
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "user_ID",
      label: "User ID",
    },
    {
      id: "user_name",
      label: "Name",
    },
    {
      id: "user_email",
      label: "Email",
    },
    {
      id: "user_phone",
      label: "Phone",
    },
    {
      id: "team",
      label: "Department",
    },
    {
      id: "designation",
      label: "Designation",
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "reporting_manager",
      label: "Reporting Manager",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "createdBy",
      label: "Created By",
    },
    {
      id: "action",
      label: "Action",
    },
  ];


  const createRow = (index: number, user: IUser, pagination: IPagination) => {
    const name = (
      <Box className="center">
        <Typography variant="body2" className="link-none-underline">
          {user.userId}
        </Typography>
        <IconButton
          className="ml-2"
          onClick={() =>
            onCopy(`${capitalize(displayName(user))}`, user.userId)
          }
          size="small"
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Box>
    );
    const action = (user && user._role && user._role.role && user?._role?.role.includes("SUPERADMIN") )? null : (
      <GetActions
        icons={[
          {
            name: "Delete",
            method: () => handleDelete(user._id),
            disabled: !(resourceAllocate("user.remove") || (resourceAllocate("user.removeAssigned") && user.assignedToMe)),
          },
        ]}
      />
    );

    const status = (user && user._role && user._role.role && user._role?.role.includes("SUPERADMIN")) ? null : (
      <Switch
        disabled={!(resourceAllocate("user.edit") || (resourceAllocate("user.editAssigned") && user.assignedToMe))}
        onChange={(event, checked) => onStatusUpdate(event, checked, user._id)}
        checked={user.status === "ACTIVE" ? true : false}
      />
    );
    const email = (
      <Box display="flex" alignItems="center">
      <IconButton className="ml-2" onClick={() => onCopy("email", user.email)} size="small">
        <ContentCopyIcon fontSize="small" />
      </IconButton >
      <CustomTypography
        limit={20}
        label={user.email}
      />
      </Box>
    );

    return {
      id: createIndex(pagination, index),
      user_ID: name,
      user_name: <CustomTypography
                    limit={30}
                    label={capitalize(displayName(user))}
                    onClick={
                      !(user && user._role?.role === "SUPERADMIN") ? 
                        resourceAllocate("user") || resourceAllocate("skill-user-mapping") ? 
                          () => navigate({ pathname: "manage/" + user._id, search: "type=basic-detail&" + searchParams.toString() }) : undefined 
                      : undefined
                    }
                    color={!(user && user._role && user._role.role === "SUPERADMIN") ? "primary" : undefined}
                />,
      user_email: email,
      user_phone: formatMobileNumber(user.phone) || "",
      team: capitalize(user?.department?.name || ""),
      designation: user.designation && user.designation?.name || "",
      role: capitalize(user._role?.name || ""),
      reporting_manager: user.reportingTo && capitalize(user.reportingTo?.name) || "",
      status,
      createdBy: capitalize((user &&  user.createdBy &&user.createdBy.name) || ""),
      action,
    };
  };

  if (users.data?.data.length) {
    rows = users.data?.data.map((user, i) =>
      createRow(i, user, state.pagination)
    );
  }

  return (
    <div>
      {/* Add Data  */}
      <Header
        className="my-2"
        searchPlaceholder="Search by name, email and phone"
        onSearch={onSearch}
        onImport={
          resourceAllocate("user.write") ? () => navigate({ pathname: "import", search: searchParams.toString() }) : undefined
        }
        btnText="Add User"
        onBtnClick={
          resourceAllocate("user.write") ? () => navigate({ pathname: "new", search: searchParams.toString() }) : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
        isDeleteDisable={false}
      >
        <UserFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 200px)"
          errorMessage="Add user to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure you want to delete this user?"
      />
      
      <Outlet context={{ ...outlet, reFetch: users.refetch }} />
    </div>
  );
};

export default User;
