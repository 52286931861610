import "./style.scss";
import { useEffect, useState, MouseEvent } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import Tag from "./tags";
import { BarGraph } from "../../components/graphs";
import LineChart from "../../components/graphs/line-graph";
import SkillAnalyticsFilter from "./filters";
import { SkillMatrixService } from "../../services";
import { IPeriodFilterNames, ISkillAnalyticsFilters, ISkillEvaluation, ISkillEvaluationData } from "../../interfaces/skill-matrix/action";
import { IBarGraphState, IFilterState, ILineGraphState } from "../../interfaces/skill-matrix/skill-matrix-analytics";
import { generateWeeklyandQuarterlyData } from "../../utilities/helper";

const SkillMatrixAnalytics = () => {
  const [filters, setFilters] = useState<ISkillAnalyticsFilters>({ time: undefined, parameter: [], employee: [], designation: [] });

  const [periodFilter, setPeriodFilter] = useState<IPeriodFilterNames>({
    period: "WEEKLY"
  });

  const {getSkillAnalytics} = SkillMatrixService();
  const [filtersCount, setFiltersCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<IBarGraphState>({
    analytics: {
      labels: [],
      datasets: [],
    },
  });

  const [lineGraphState, setLineGraphState] = useState<ILineGraphState>({
    analytics: {
      labels: [],
      datasets: [{
        label: "",
        data: [],
        tension: 0
      }],
    },
  });

  const skillAnalyticsScoreDifference = useQuery({
    queryKey: [periodFilter.period, "skill-matrix-analytics-score", filters],
    queryFn: () =>
      getSkillAnalytics({
        type: "score",
        period: periodFilter.period,
        ...filters
      }),
  });

  const skillAnalyticsTimeDifference = useQuery({
    queryKey: [periodFilter.period, "skill-matrix-analytics-time", filters],
    queryFn: () =>
      getSkillAnalytics({
        type: "date",
        period: periodFilter.period,
        ...filters
      }),
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
  });

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const anchorElement = e.currentTarget;

    setFilterState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: anchorElement,
        isOpen: !filterState.filterDialog.isOpen,
      },
    }));
  };

  const closeFilter = () => {
    setFilterState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  useEffect(() => {
    if (!searchParams.get("time")) {
      const today = new Date();
      const lastYear = new Date();
      lastYear.setFullYear(today.getFullYear() - 1);

      const defaultDateFilter = [
        {
          key: "custom",
          value: "Custom",
          startDate: lastYear.toISOString(),
          endDate: today.toISOString(),
        },
      ];

      searchParams.set("time", JSON.stringify(defaultDateFilter));
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
  const dataFromApi = skillAnalyticsScoreDifference?.data?.data.map((item) => item.data);
  const evaluationCycles = dataFromApi && dataFromApi.map(
    (item) => parseInt(item.evaluationCycle)
  );

  const formattedLabels = generateWeeklyandQuarterlyData(evaluationCycles ||[], periodFilter.period);
  
  const processedData = dataFromApi && dataFromApi.map((item, index) => ({
    ...item,
    evaluationCycle: formattedLabels[index]
  })) as ISkillEvaluationData[];

    const transformedData = {
      labels: processedData?.map((item) => item.evaluationCycle) || [],
      datasets: Array.from(
        new Set(processedData?.flatMap(item => Object.keys(item)) as string[])
      )
        .filter((key) => key !== "evaluationCycle")
        .map((key) => ({
          label: key,
          data: processedData?.map((item) => item[key as keyof ISkillEvaluation]),
          tension: 0.4,
        })),
    };

    setState((prevState) => ({
      ...prevState,
      analytics: transformedData ? transformedData : prevState.analytics,
    }));

  }, [skillAnalyticsScoreDifference?.data?.data]);

  useEffect(() => {
    const dataFromApi = skillAnalyticsTimeDifference?.data?.data.map((item) => item.data);
    const evaluationCycles = dataFromApi && dataFromApi.map(
      (item) => parseInt(item.evaluationCycle)
    );
    const formattedLabels = generateWeeklyandQuarterlyData(evaluationCycles ||[], periodFilter.period);

    const processedData = dataFromApi && dataFromApi.map((item, index) => ({
      ...item,
      evaluationCycle: formattedLabels[index]
    })) as ISkillEvaluationData[];

    const transformedData = {
      labels: processedData?.map((item) => item.evaluationCycle) || [],
      datasets: Array.from(
        new Set(processedData?.flatMap(item => Object.keys(item)))
      )
        .filter((key) => key !== "evaluationCycle")
        .map((key) => ({
          label: key,
          data: processedData?.map((item) => item[key]),
          tension: 0.4,
        })),
    };

    setLineGraphState((prevState) => ({
      ...prevState,
      analytics: transformedData ? {
        ...transformedData,
        datasets: transformedData.datasets.map(dataset => ({
          ...dataset,
          data: dataset.data ?? [],
        }))
      } : prevState.analytics,
    }));

  }, [skillAnalyticsTimeDifference?.data?.data]);

  const onChange = async (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setPeriodFilter({
      period: value as IPeriodFilterNames["period"]
    });
  };

  useEffect(() => {
    let filterCount = 0;
    const time: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("time")
        ? JSON.parse(String(searchParams.get("time")))
        : [];

    const parameter: { key: string, value: string }[] = searchParams.get("parameter") ? JSON.parse(String(searchParams.get("parameter"))) : [];
    const designation: { key: string, value: string }[] = searchParams.get("designation") ? JSON.parse(String(searchParams.get("designation"))) : [];
    const employee: { key: string, value: string }[] = searchParams.get("employee") ? JSON.parse(String(searchParams.get("employee"))) : [];

    filterCount += time.length ? 1 : 0;
    filterCount += parameter.length ? 1 : 0;
    filterCount += designation.length ? 1 : 0;
    filterCount += employee.length ? 1 : 0;

    let timeRequest:
      | {
        startDate: string;
        endDate: string;
      }
      | undefined = undefined;
    if (time?.length) {
      timeRequest = {
        startDate: time[0]?.startDate,
        endDate: time[0]?.endDate,
      };
    }

    setFiltersCount(filterCount);

    setFilters((prevState) => ({
      ...prevState,
      parameter: parameter.map(item => item.key),
      designation: designation.map(item => item.key),
      employee: employee.map(item => item.key),
      time: timeRequest,
    }));
  }, [searchParams]);

  return (
    <div className="self-evaluation">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          Overview
        </Typography>

        <Box display="flex" alignItems="center">
          <Box mx={3}>
            <FormControl size="small">
              <Select
                variant="outlined"
                name="period"
                value={periodFilter.period}
                onChange={onChange}
              >
                <MenuItem value="MONTHLY">Monthly</MenuItem>
                <MenuItem value="WEEKLY">Weekly</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className="filter-box">
            <Tooltip title="Filter">
              <Badge
                color="primary"
                badgeContent={filtersCount}
                invisible={!filtersCount}
              >
                <Button
                  className={filtersCount ? "filter active" : "filter default"}
                  variant="outlined"
                  onClick={openFilter}
                >
                  <FilterListIcon color="primary" />
                </Button>
              </Badge>
            </Tooltip>
            <SkillAnalyticsFilter
              anchorEl={filterState.filterDialog.anchorEl}
              isOpen={filterState.filterDialog.isOpen}
              OnCloseFilter={closeFilter}
            />
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="metric-card" mb={3}>
            <Box className="self-evaluation-tag">
              <Tag
                name="Average levels advancement"
                count={skillAnalyticsScoreDifference?.data?.meta.averageScoreTime ?? 0}
              />
            </Box>
          </Box>

          <Box className="chart-container">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                Average time to advance one level
              </Typography>
            </Box>
            <BarGraph data={state.analytics} height={340} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" gap={2} mb={3}>
            <Box className="metric-card" flex={1}>
              <Box className="self-evaluation-tag">
                <Tag
                  name="Average time to advance one level"
                  count={skillAnalyticsTimeDifference?.data?.meta.averageScoreTime ?? 0}
                />
              </Box>
            </Box>
            <Box className="metric-card" flex={1}>
              <Box className="self-evaluation-tag">
                <Tag
                  name="No of levels advanced"
                  count={skillAnalyticsScoreDifference?.data?.meta.totalSkillAdvanced ?? 0}
                />
              </Box>
            </Box>
          </Box>

          <Box className="chart-container">
            <Box mb={2}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                Skill Advancement
              </Typography>
            </Box>
            <LineChart data={lineGraphState.analytics} height={340} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SkillMatrixAnalytics;