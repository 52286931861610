import Joi from "joi";
import { required } from "../../shared";

export const automationValidation = Joi.object({
    // configuration 
    title: Joi.string()
        .required()
        .min(3)
        .max(60)
        .trim()
        .label("Title")
        .messages({
            "string.empty": required,
        }),
    module: Joi.string()
        .valid("LEAD", "CPD", "JOB")
        .required()
        .label("Module")
        .messages({
            "string.empty": required,
        }),
    communicationType: Joi.string()
        .valid("EMAIL", "ASSESSMENT", "WHATSAPP")
        .required()
        .label("Communication Type")
        .messages({
            "string.empty": required,
        }),
    _template: Joi.string()
        .when("communicationType", {
            is: Joi.string().valid("EMAIL", "WHATSAPP"),
            then: Joi.required(),
            otherwise: Joi.optional()
            .allow("")
        })
        .label("Template")
        .messages({
            "string.empty": required,
        }),
    _assessment: Joi.array()
        .when("communicationType", {
            is: "ASSESSMENT",
            then: Joi.required(),
            otherwise: Joi.optional()
            .allow("")
        })
        .label("Assessment")
        .messages({
            "string.empty": required,
        }),
    whatsappTemplateName: Joi.string()
        .optional()
        .allow("")
        .label("Template")
        .messages({
            "string.empty": required,
        }),
        cc: Joi.array().items(
            Joi.string()
            .optional()
            .allow("")
        ),
        ccText: Joi.string()
            .optional()
            .allow(""),
    scheduledType: Joi.string()
        .required()
        .valid("IMMEDIATELY", "CUSTOM")
        .label("Scheduled Type")
        .messages({
            "string.empty": required,
        }),
    scheduledTime: Joi.string()
        .when("scheduledType", {
            is: Joi.not("IMMEDIATELY"),
            then: Joi.required(),
        })
        .label("Time")
        .messages({
            "string.empty": required,
        }),
    unitOfTime: Joi.string()
        .valid("DAYS", "HOURS")
        .label("Days/Hours"),

    updatedStatus: Joi.string()
        .optional()
        .allow("")
        .label("Updated Status"),

    // filters 
    filterStatus: Joi.alternatives().conditional("module", {
        is: ["LEAD", "CPD", "JOB"],
        then: Joi.array()
            .items(Joi.string()
            )
            .min(1)
            .required()
            .label("Status")
            .messages({
                "array.empty": required,
            })
    }),
    filterSource: Joi.alternatives().conditional("module", {
        is: "LEAD",
        then: Joi.array()
            .items(Joi.string()
            )
            .min(1)
            .required()
            .label("Status")
            .messages({
                "array.empty": required,
            }),
        otherwise: Joi.optional()
    }),
    filterJobType: Joi.alternatives().conditional("module", {
        is: "LEAD",
        then: Joi.array()
            .items(Joi.string()
            )
            .min(1)
            .required()
            .label("Status")
            .messages({
                "array.empty": required,
            }),
        otherwise: Joi.optional()
    }),
    filterEngagementType: Joi.alternatives().conditional("module", {
        is: "LEAD",
        then: Joi.array()
            .items(Joi.string()
            )
            .min(1)
            .required()
            .label("Status")
            .messages({
                "array.empty": required,
            }),
        otherwise: Joi.optional()
    }),
    cpdId: Joi.array()
        .optional(),
    filterCategory: Joi.alternatives().conditional("module", {
        is: "CPD",
        then: Joi.array()
            .items(Joi.string()
            )
            .min(1)
            .required()
            .label("Status")
            .messages({
                "array.empty": required,
            }),
        otherwise: Joi.optional()
    }),

    jobTitle: Joi.alternatives().conditional("module", {
        is: "LEAD",
        then: Joi.array()
            .items(Joi.string()
            )
            .min(0)
            .label("job Title")
            .messages({
                "array.empty": required,
            }),
        otherwise: Joi.optional()
    }),

    // Receiver 
    receiverType: Joi.string()
        .valid("END_USER", "ROLE_BASED", "CUSTOM")
        .required()
        .label("Receiver Type")
        .messages({
            "string.empty": required,
        }),
    receiverRole: Joi.alternatives().conditional("receiverType", {
        is: "ROLE_BASED",
        then: Joi.string()
            .required()
            .label("Role")
            .messages({
                "string.empty": required,
            }),
        otherwise: Joi.optional()
    }),
});