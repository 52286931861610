import "../blog-comments/style.scss";
import { Box, Button, Grid, IconButton, InputBase, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { capitalize, formatDate } from "../../utilities/helper";
import useSnackbar from "../../hooks/useSnackbar";
import { IErrorResponse } from "../../interfaces";
import { IBlogCommentReply } from "../../interfaces/content-marketing/blog/comment";
import useResource from "../../hooks/useResource";
import { GlossaryCommentsService } from "../../services/glossary/comments";
import { CMS_ERROR_MESSAGES } from "../../utilities/messages";

interface props {
  commentType: string;
  activeAction: boolean;
  setActiveAction: (value: boolean) => void;
}

interface initialReplyStates {
  _id: string,
  addReply: boolean,
  expandedReplies: boolean,
}

const GlossaryComment: FC<props> = ({ commentType, activeAction, setActiveAction }) => {
  const [state, setState] = useState({
    addComment: false
  });
  const { id } = useParams();

  const { addGlossaryComment, getGlossaryCommentList, addGlossaryCommentReply } = GlossaryCommentsService();
  const glossaryCommentList = useQuery({
    queryKey: ["glossary-comment-list", id, commentType],
    queryFn: () => getGlossaryCommentList({ _glossary: id, type: commentType }),
  });
  const { resourceAllocate } = useResource();
  const [commentStates, setCommentStates] = useState<initialReplyStates[] | undefined>([]);
  const { snackbar } = useSnackbar();
  const [commentText, setCommentText] = useState("");
  const [replyText, setReplyText] = useState("");

  useEffect(() => {
    if (glossaryCommentList?.data?.data && (!commentStates || commentStates.length !== glossaryCommentList.data.data.length)) {
        setCommentStates(glossaryCommentList.data.data && glossaryCommentList.data.data.map((comment) => ({
          _id: comment && comment._id,
          addReply: false,
          expandedReplies: false,
        })));
    }
  }, [glossaryCommentList, commentStates]);
  const toggleAddComment = async (type: "new" | "reply", commentId?: string) => {

    if (type === "new") {
      setState(prev => ({ ...prev, addComment: !state.addComment }));
    } else if (type === "reply") {
      setCommentStates((prev) => {
        if (!prev) return prev;

        const newState = prev.map((comment: initialReplyStates) => {
          if (comment._id === commentId) {
            return { ...comment, addReply: !comment.addReply };
          }
          return { ...comment, addReply: false };
        });

        return newState;
      });
    }
  };

  const toggleExpandReplies = (id: string) => {
    setCommentStates((prev) => {
      if (!prev) return prev;

      const newState = prev.map((comment: initialReplyStates) => {
        if (comment._id === id) {
          return { ...comment, expandedReplies: !comment.expandedReplies };
        }
        return { ...comment, expandedReplies: false };
      });

      return newState;
    });
  };


  const addComment = async (type: "new" | "reply", commentId?: string) => {
    toggleAddComment(type, commentId);
    try {
      if (type === "new") {
          if(!commentText.trim().length){
            snackbar(CMS_ERROR_MESSAGES.en.empty_comment_text, "error");
            return;
          }
        const response = await addGlossaryComment({
          _glossary: id,
          type: commentType,
          text: commentText,
        });
        snackbar(response.message, "info");
        glossaryCommentList.refetch();
      } else {
        if(!replyText.trim().length){
          snackbar(CMS_ERROR_MESSAGES.en.empty_reply_text, "error");
          return;
        }
        const response = await addGlossaryCommentReply({
          commentId: commentId,
          type: commentType,
          text: replyText,
        });
        snackbar(response.message, "info");
        glossaryCommentList.refetch();

      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };


  return (
    <div id="comments">
      <header>
        <Typography variant="body1">Comments</Typography>
        <IconButton color="primary" onClick={() => setActiveAction(!activeAction)}>
          <CloseIcon color="action" />
        </IconButton>
      </header>

      <div className="note-container">
        {!state.addComment && resourceAllocate("cms-glossary.write") && (
          <div className="add-note mb-3" onClick={() => toggleAddComment("new")}>
            <AddIcon className="plus-icon" />
            <Typography variant="caption">Add Comment</Typography>
          </div>
        )}

        {state.addComment && (
          <div className="note-input form-border mb-3">
            <InputBase
              className="description mb-2"
              multiline
              placeholder="Write a comment..."
              onChange={(e) => setCommentText(e.target.value)}
            />

            <div className="add-btn">
              <Button size="small" variant="outlined" className="mr-2" onClick={() => toggleAddComment("new")}>
                Cancel
              </Button>
              <Button size="small" onClick={() => addComment("new")}>
                Comment
              </Button>
            </div>
          </div>
        )}

        {glossaryCommentList?.data?.data.map((comment, index) => (
          <div key={index}>
            <div className="form-border mb-3">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Avatar alt="Logo">
                    {comment?.createdBy?.name.charAt(0).toUpperCase()}
                  </Avatar>
                </Grid>
                <Grid item xs={10}>
                  <Box className="mb-2 form-box">
                    <Typography variant="body2" className="form-heading">
                      {comment && comment.createdBy && capitalize(comment.createdBy.name) || ""}
                    </Typography>
                    <span className="date">{formatDate(comment && comment.createdAt)}</span>
                  </Box>

                  <Typography variant="caption" className="form-text">{comment && comment.text || ""}</Typography>

                  {commentStates && commentStates[index]?.expandedReplies && comment.replies && comment.replies.length > 0 && (
                    <React.Fragment>
                      {comment.replies.map((reply: IBlogCommentReply, replyIndex: number) => (
                        <Box key={replyIndex} className="mt-3 data-box">
                          <Grid container spacing={1}>
                            <Grid item xs={2}>
                              <Avatar alt="Logo" className="avatar">
                                {reply?.createdBy?.name.charAt(0).toUpperCase()}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Box className="mb-2 created-by">
                                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                                  {capitalize(reply?.createdBy?.name || "")}
                                </Typography>
                                <span className="date">{formatDate(reply.createdAt)}</span>
                              </Box>
                              <Typography variant="caption">{reply.text}</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </React.Fragment>
                  )}

                  <Box className="reply-box">
                    <Typography variant="subtitle2" className="mt-2" onClick={() => toggleAddComment("reply", comment._id)}>
                      Reply
                    </Typography>
                    {
                      (commentStates && comment.replies.length !== 0) &&
                      <Typography variant="subtitle2" className="mt-2 ml-3" onClick={() => toggleExpandReplies(comment._id)}>
                        {commentStates[index]?.expandedReplies ? "Hide" : "View"} {comment.replies.length} Replies
                      </Typography>
                    }
                  </Box>

                  {commentStates && commentStates[index]?.addReply && (
                    <TextField
                      className="mt-2"
                      placeholder="Reply to this comment..."
                      onChange={(e) => setReplyText(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Add Reply">
                            <IconButton color="primary" onClick={() => addComment("reply", comment._id)}>
                              <ArrowUpwardIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlossaryComment;