import { FC, useEffect, useState, MouseEvent, ChangeEvent} from "react";
import { Box, Button, Divider, Grid, InputAdornment, SelectChangeEvent, TextField, IconButton } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { IUser, IUserField, IUserValues } from "../../../../../interfaces/configuration/user";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { BusinessUnitsService } from "../../../../../services/configuration/business-unit";
import { userEditValidation } from "../../../../../validations/configuration/user";
import { useSelector } from "react-redux";
import { IJobPosition } from "../../../../../interfaces/configuration/job-position";
import dayjs from "dayjs";
import { IErrorResponse } from "../../../../../interfaces/shared";
import { UsersService } from "../../../../../services/configuration/user";
import { ITeam } from "../../../../../interfaces/configuration/team";
import CustomLabel from "../../../../../components/mui/custom-label";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MobileNumber from "../../../../../components/mui/mobile-number";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchSelect from "../../../../../components/mui/search-select";
import { capitalize } from "../../../../../utilities/helper";
import { IRole } from "../../../../../interfaces";
import useResource from "../../../../../hooks/useResource";
import HttpService from "../../../../../services/http";
import UploadImg from "../../../../../assets/images/upload.png";

interface props {
  user: IUser | undefined;
  isDisable: boolean;
  setIsDisable: (e: boolean) => void;
}

interface outletProps {
    reFetch: () => void;
    refetchUsers: () => void;
  }
const BasicDetail: FC<props> = ({ user, isDisable, setIsDisable}) => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const { updateUser } = UsersService();
    const { resourceAllocate } = useResource();
    const { httpFormRequest } = HttpService();
    const navigate = useNavigate();
    const [imageSelected, setImageSelected] = useState<string | null>(null);
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const [showPassword, setShowPassword] = useState(false);
    const { getBusinessUnits } = BusinessUnitsService();
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(
      (state) => state.user.list
    );
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
      (state) => state.team.list
    );
    const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>(
      (state) => state.role.list
    );
    const businessUnits = useQuery({
      queryKey: ["all-user-business-units"],
      queryFn: () => getBusinessUnits({
        pagination: false
      })
    });
    const {
      handleSubmit,
      control,
      getValues,
      trigger,
      setValue,
      watch,
      formState: { errors },
    } = useForm<IUserValues>({
      resolver: joiResolver(userEditValidation),
      defaultValues: {
        email: "",
        linkedinUrl: "",
        gender: "MALE",
        departmentId: "",
        designation: "",
        number: "",
        roleId: "",
        status: "ACTIVE",
        firstName: "",
        lastName: "",
        dob: "",
        joinedDate: "",
        address: "",
        legalEntry: "",
        employeeNumber: "",
        dateOfLeaving: "",
        extensionPhoneNumber: "",
        image: ""
      },
    });
  
    const designations = useSelector<{ jobPosition: { list: IJobPosition[] } }, IJobPosition[]>(state => state.jobPosition.list);
    const getDesignationOptions = () => 
      designations.filter(title => title && title._department && title._department._id === getValues("departmentId"));
  
    const getRolesOptions = () => 
      roles.filter(title => title && title._department && title._department._id === getValues("departmentId"));

    const [phone, setPhone] = useState({
      country: "INDIA",
      dialCode: "+91",
      iso2: "IN",
    });
  
    const [extensionNumber, setExtensionNumber] = useState({
      country: "INDIA",
      dialCode: "+91",
      iso2: "IN",
    });

    useEffect(() => {
        if (user) {
          setValue("firstName", capitalize(user.firstName) ?? "");
          setValue("lastName", capitalize(user.lastName) ?? "");
          setValue("email", user.email ?? "");
          setValue("gender", user.gender);
          setValue("departmentId", (user.department && user.department._id) ?? "");
          trigger("departmentId");
          const validDesignations = getDesignationOptions();
          if (user.designation && validDesignations.some(option => option._id === user.designation._id)) {
              setValue("designation", user.designation._id);
          } else {
              setValue("designation", "");
          }
          const validRoles = getRolesOptions();
          if (user._role && validRoles.some(option => option._id === user._role._id)) {
              setValue("roleId", user._role._id);
          } else {
              setValue("roleId", "");
          }
          setPhone(user.phone);
          setValue("number", user.phone.number ?? "");
          setExtensionNumber(user.extensionNumber);
          setValue("extensionPhoneNumber", (user.extensionNumber && user.extensionNumber.number) ?? "");
          setValue("dob", user.dob ?? "");
          setValue("joinedDate", user.joinedDate ?? "");
          setValue("dateOfLeaving", user.dateOfLeaving ?? "");
          setValue("reportingTo", (user.reportingTo && user.reportingTo._id) ?? "");
          setValue("employeeNumber", user.employeeNumber ?? "");
          setValue("legalEntry", user.legalEntry ?? "");
          setValue("businessUnit", (user.businessUnit && user.businessUnit._id));
          setValue("address", (user.location && user.location.formattedAddress) ?? "");
          setValue("status", user.status);
          setValue("image", user.image);
          setValue("linkedinUrl", user.linkedinUrl);
          setImageSelected((user.image));
        }
    }, [isDisable, user]);

    useEffect(() => {
      const subscription = watch(({ name }) => {
        if (name === "departmentId") {
          setValue("designation","");
          setValue("roleId","");
        }
      });
      return () => subscription.unsubscribe();
    }, [watch]);

  const selectDate = (value: string | number | Date | dayjs.Dayjs | null | undefined, name: string) => {
    const date =
      value && dayjs(value).toString() !== "Invalid Date"
        ? dayjs(value)?.toISOString()
        : undefined;
    const keyExist = name === "dob" || name === "joinedDate" || name === "dateOfLeaving";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  const onSubmit = async (data: IUserValues) => {
    try {
      const payload = {
        ...data,
        _id: id,
        phone: {
          ...phone,
          number: data.number,
        },
        extensionNumber: {
          ...extensionNumber,
          number: data.extensionPhoneNumber,
        },
        location: {
          formattedAddress: data.address || undefined,
        },
        _role: data.roleId,
        department: data.departmentId,
        name: `${data?.firstName} ${data?.lastName}`,
      };
      delete payload.extensionPhoneNumber;
      delete payload.number;
      delete payload.roleId;
      delete payload.departmentId;
      delete payload.address;
      const user = await updateUser({ ...payload, _id: id });
      snackbar(user.message, "info");
      navigate({
        pathname: "/users",
        search: searchParam.toString(),
      });
      outlet?.reFetch && outlet.reFetch();
      outlet?.refetchUsers && outlet.refetchUsers();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onNumberChange = (e: SelectChangeEvent<string>, name: string) => {
    const { value } = e.target;
    const data = value.split(":");
    if(name === "number") { 
      setPhone({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    }else{
      setExtensionNumber({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    }
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["png", "jpeg", "jpg", "webp"],
          1
        );
        const keyExist = type === "image";
        if (keyExist) {
          setValue(type, uploaded?.data);
          setImageSelected(URL.createObjectURL(e.target.files[0]));
          trigger(type);
        }
      }
    }
    catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const fields: IUserField[] = [
    {
      label: "Image*",
      name: "image",
      type: "upload",
    },
    {
      label: "First Name",
      name: "firstName",
      type: "input",
      placeholder: "Type first name here",
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "input",
      placeholder: "Type last name here",
      required: true,
    },
    {
      label: "Employee Number",
      name: "employeeNumber",
      type: "input",
      placeholder: "Type employee number here",
    },
    {
      label: "Number",
      name: "number",
      type: "mobile-number",
      required: true,
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      placeholder: "Type your email here",
      required: true,
    },
    {
      label: id === "new" ? "Password" : "Change Password",
      name: "password",
      type: "input",
      placeholder: "Type your password here",
      required: id === "new",
    },
    {
      label: "Gender",
      name: "gender",
      type: "select",
      options: [
        {
          key: "MALE",
          value: "Male"
        },
        {
          key: "FEMALE",
          value: "Female"
        },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      required: true,
    },
    {
      type: "select",
      name: "reportingTo",
      label: "Reporting Manager",
      options: users.map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
      required: true,
    },
    {
      label: "DOB",
      name: "dob",
      type: "date",
    },
    {
      label: "Joined Date",
      name: "joinedDate",
      type: "date",
      required: true,
    },
    {
      label: "Date of leaving",
      name: "dateOfLeaving",
      type: "date",
    },
    {
      label: "Department",
      name: "departmentId",
      type: "select",
      required: true,
      options: teams.map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
      disabled: !resourceAllocate("user.edit"),
    },
    {
      label: "Role",
      name: "roleId",
      type: "select",
      required: true,
      options: getRolesOptions().map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Legal Entry",
      name: "legalEntry",
      type: "input",
      placeholder: "Type legal entry here",
    },
    {
      label: "Business Unit",
      name: "businessUnit",
      type: "select",
      options: businessUnits && businessUnits.data && businessUnits.data.data && businessUnits.data.data.map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })) || [],
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Designation",
      name: "designation",
      type: "select",
      options: getDesignationOptions().map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
      required: true,
    },
    {
      label: "Employee Address",
      name: "address",
      type: "input",
      placeholder: "Type employee address here",
    },
    {
      label: "Extension Number",
      name: "extensionPhoneNumber",
      type: "mobile-number"
    },
    {
      label: "LinkedIn Profile Url",
      name: "linkedinUrl",
      type: "input",
      placeholder: "Type linkedin profile url here",
    },
  ];

  const onChangeDepartment = () => {
    setValue("roleId", "");
    setValue("designation", "");
  };

    return(
        <Box paddingTop="10px"> 
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                {fields.map((field) => {
            if (
              field.type === "input" &&
              field.name === "password" 
            ) {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        className="disable-text"
                        disabled={isDisable}
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="new-password"
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "upload") {
              return (<Grid key={field.label} item xs={12}>
                <Box className="center">
                  <Box height="100px" width="100px" className={`upload-img ${ isDisable ? "" : "editable"}`} aria-label="upload picture" component="label">
                    <img src={imageSelected || UploadImg} alt="review" />
                    {!isDisable && <div className="edit-img ">Edit</div> }
                    <input hidden accept="image/*" type="file" disabled={isDisable} onChange={e => uploadFile(e, field.name)} />
                  </Box>
                </Box>
                {errors[field.name] && <span style={{ color: "#d32f2f", marginLeft: "528px", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
              </Grid>
              );
            }else if (field.type === "input") {
              return (
                field.name !== "password" && (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={
                            <CustomLabel
                              label={field.label}
                              required={field?.required}
                            />
                          }
                          className="disable-text"
                          disabled={isDisable}
                          size="small"
                          placeholder={field.placeholder}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                          autoComplete={
                            field.name === "email" ? "username" : ""
                          }
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                )
              );
            } else if (field.type === "mobile-number") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <MobileNumber
                        key={field.label}
                        className="disable-text"
                        NumberFieldLabel={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        dialCodeValue={field.name === "number" ? `${phone?.country}:${phone?.iso2}:${phone?.dialCode}` : `${extensionNumber?.country}:${extensionNumber?.iso2}:${extensionNumber?.dialCode}`}
                        onChange={(event) => onNumberChange(event, field.name)}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        other={prop.field}
                        disabled={isDisable}
                        vendorOrUserEdit={true}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "date") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field?.required}
                        />
                      }
                      onChange={(e) => selectDate(e, field.name)}
                      value={dayjs(getValues(field.name))}
                      slotProps={{
                        textField: {
                          error: !!errors[field.name],
                          helperText: errors[field.name]?.message,
                        },
                      }}
                      disabled={isDisable}
                      format="LL"
                    />
                  </LocalizationProvider>
                </Grid>
              );
            } else{
              return (<Grid key={field.label} item xs={12} md={6}>
                <SearchSelect
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    options={field.options}
                    displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                    storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                    displayUserName={field.displayUserName}
                    capitalize={field.capitalize}
                    trigger={trigger}
                    disabled={field.disabled || isDisable}
                    setValue={setValue}
                    getValues={getValues}
                    onChange={()=>{
                      if(field.name === "departmentId"){
                        onChangeDepartment();
                      }
                    }}
                    size="small"
                />
            </Grid>
            );
            }
          })}
                </Grid>

                {
                    !isDisable &&
                    <>
                        <Box className="action-box">
                        <Divider sx={{ marginBottom: "20px" }} />
                        <Button variant="outlined" onClick={() => setIsDisable(true)}>Cancel</Button>
                        <Button type="submit">Save</Button>
                        </Box>
                    </>
                }
            </form>
        </Box>
    );
};

export default BasicDetail;
