import HttpService from "../../http";
import { customUrl } from "../../endpoints";
import { ICustomUrlResponse, ICustomUrlSampleResponse, ICustomUrlsResponse } from "../../../interfaces/site-map/custom-urls";

const CustomUrlService = () => {
    const { httpRequest } = HttpService();

    const getCustomUrls = async (search: object): Promise<ICustomUrlsResponse> => new Promise((resolve, reject) => {
        httpRequest<ICustomUrlsResponse>(
            "GET",
            `${customUrl}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCustomUrl = async (search: object): Promise<ICustomUrlResponse> => new Promise((resolve, reject) => {
        httpRequest<ICustomUrlResponse>(
            "GET",
            `${customUrl}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCustomUrl = async (query: object): Promise<ICustomUrlsResponse> => new Promise((resolve, reject) => {
        httpRequest<ICustomUrlsResponse>(
            "DELETE",
            `${customUrl}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const addCustomUrl = async (payload: object): Promise<ICustomUrlsResponse> => new Promise((resolve, reject) => {
        httpRequest<ICustomUrlsResponse>(
            "POST",
            `${customUrl}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCustomUrl = async (payload: object): Promise<ICustomUrlsResponse> => new Promise((resolve, reject) => {
        httpRequest<ICustomUrlsResponse>(
            "PUT",
            `${customUrl}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getCustomUrlSample = async (search: object): Promise<ICustomUrlSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ICustomUrlSampleResponse>(
                "GET",
                `${customUrl}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });
    const importCustomUrl = async (payload: object): Promise<ICustomUrlResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ICustomUrlResponse>(
                "POST",
                `${customUrl}/import`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });


    return { getCustomUrl, deleteCustomUrl, addCustomUrl, updateCustomUrl, getCustomUrls, getCustomUrlSample, importCustomUrl };
};

export { CustomUrlService };