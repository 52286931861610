import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {  IErrorResponse } from "../../../../../interfaces";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import useSnackbar from "../../../../../hooks/useSnackbar";
import CustomDialog from "../../../../../components/mui/dialog";
import { Box, Grid } from "@mui/material";
import { GlossaryService } from "../../../../../services/glossary";
import { glossaryScheduleValidation } from "../../../../../validations/content-marketing/glossary";
import CustomLabel from "../../../../../components/mui/custom-label";
import { IScheduleGlossary, IScheduleGlossaryField } from "../../../../../interfaces/content-marketing/glossary/content";

interface outletProps {
    reFetch: () => void
}

const ScheduleGlossary = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { scheduleGlossary, getGlossary } = GlossaryService();
    const { handleSubmit, getValues, setValue, trigger, formState: { errors } } = useForm<IScheduleGlossary>({
        resolver: joiResolver(glossaryScheduleValidation),
        defaultValues: {
            scheduledDate: "",
        }
    });

    const getGlossaryDetail = useQuery({
        queryKey: ["glossary-details"],
        queryFn: () => getGlossary({
            _id: id
        })
    });

    useEffect(() => {
        if (getGlossaryDetail && getGlossaryDetail.data && getGlossaryDetail.data.data) {
            setValue("scheduledDate", String(getGlossaryDetail.data.data.scheduledDate) || "");
            trigger("scheduledDate");
        }
    }, [getGlossaryDetail.data]);

    const onSubmit = async (data: IScheduleGlossary) => {
        try {
            if (id) {
                const selectedTime = dayjs(data.scheduledDate);
                const currentTime = dayjs();
                if (selectedTime.isBefore(currentTime, "minute")) {
                    snackbar("Glossary cannot scheduled be in the past. Please select a current or future time", "warning");
                    return;
                }

                const payload = { ...data, _glossary: id };
                const reschedule = await scheduleGlossary(payload);
                snackbar(reschedule.message, "info");
                onClose();
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }
    };

    const selectDate = (value: string | number | Date | dayjs.Dayjs | null | undefined) => {
        const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
        setValue("scheduledDate", date);
        trigger("scheduledDate");
    };
    const onClose = () => {
        navigate({
            pathname: `/glossary/manage/${id}`,
            search: searchParam.toString()
        });
    };

    const fields: IScheduleGlossaryField[] = [
        {
            label: "Date and Time",
            name: "scheduledDate",
            type: "date",
            required: true
        },
    ];

    return (
        <>
            <Box>
                <CustomDialog
                    title={"Schedule Glossary"}
                    size="xs"
                    confirmText="Schedule"
                    isOpen={!!id}
                    onClose={onClose}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "date") {
                                    return (
                                        <Grid key={field.label} item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDateTimePicker
                                                    label={<CustomLabel label={field.label} required={field.required} />}
                                                    onChange={selectDate}
                                                    slotProps={{
                                                        textField: {
                                                            error: !!errors[field.name],
                                                            helperText: errors[field.name]?.message
                                                        }
                                                    }}
                                                    value={dayjs(getValues(field.name) ? getValues(field.name) : null)}
                                                    format="LLL"
                                                    shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), "day")}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                </CustomDialog>
            </Box>
        </>
    );

};

export default ScheduleGlossary;