import { IAlternativeImportData } from "../../../interfaces/alternative-comparison";
import KeywordService from "../../../services/content/keyword";
import ProjectsService from "../../../services/content/projects-products";
import plannerService from "../../../services/planner";
import { CMS_ERROR_MESSAGES } from "../../../utilities/messages";
import { COMMON_ERROR_MESSAGES } from "../../../utilities/messages/common";


const useValidateAlternative = () => {
    const { getContentProjectPartial } = ProjectsService();
    const { getQuarterPartial } = plannerService();
    const {getContentKeywordPartial} = KeywordService();

    const validateAlternative = (alternatives: (string | number)[][]) => (
        new Promise((resolve) => {
            const mandatoryFields = ["type", "title", "description", "slug", "primaryKeyword", "secondaryKeywords", "_product", "_quarter"];
            const payload = [];
            const error = [];
            let row = 2;
            if(!alternatives[0].length){
                error.push([COMMON_ERROR_MESSAGES.en.empty_excel_file]);
            }
    
            (async () => {
                for (const alternative of alternatives) {
                    if (!alternative?.length) {
                        ++row;
                        continue;
                    }
    
                    const data: IAlternativeImportData = {
                        type: "",
                        title: "",
                        description: "",
                        slug: "",
                        primaryKeyword: "",
                        secondaryKeywords: [],
                        _product: "",
                        _quarter: ""
                    };
                    const secKeywords = [];
                    const errMessage: Record<string, string> = {};
    
                    if (typeof alternative[0] === "string") {
                        data.type = alternative[0];
                    } else {
                        if (alternative[0] === undefined) {
                            errMessage["Alternative/Comaprison Type"] = "Alternative/Comaprison Type is required";
                        } else {
                            errMessage["Alternative/Comaprison Type"] = "Alternative/Comaprison type must be string";
                        }
                    }
    
                    if (typeof alternative[1] === "string") {
                        data.title = alternative[1];
                    } else {
                        if (alternative[1] === undefined) {
                            errMessage["Alternative/Comaprison Title"] = "Alternative/Comaprison Title is required";
                        } else {
                            errMessage["Alternative/Comaprison Title"] = "Alternative/Comaprison Title must be string";
                        }
                    }
    
                    if (typeof alternative[2] === "string") {
                        data.description = alternative[2];
                    } else {
                        if (alternative[2] === undefined) {
                            errMessage["Description"] = "Description is required";
                        }
                        else {
                            errMessage["Description"] = "Description must be string";
                        }
                    }
    
                    if (typeof alternative[3] === "string") {
                        data.slug = alternative[3];
                    } else {
                        if (alternative[3] === undefined) {
                            errMessage["Slug"] = "Slug is required";
                        }
                        else {
                            errMessage["Slug"] = "Slug must be string";
                        }
                    }
    
     
                    if (typeof alternative[4] === "string") {
                        try {
                            const project = await getContentProjectPartial({
                                search: alternative[4].trim(),
                            });
                            if (project.data?._id) {
                                data._product = project.data?._id;
                            } else {
                                errMessage["Product"] = CMS_ERROR_MESSAGES.en.invalid_product_name;
                            }
                        } catch (error) {
                            errMessage["Product"] = CMS_ERROR_MESSAGES.en.invalid_product_name;
                        }
                    } else {
                        if (alternative[4] !== undefined) {
                            errMessage["Product"] = CMS_ERROR_MESSAGES.en.invalid_product_name;
                        }
                    }
    
                    if (typeof alternative[5] === "string") {
                        try {
                            const project = await getQuarterPartial({
                                search: alternative[5].trim(),
                                _product: data._product
                            });
                            if (project.data?._id) {
                                data._quarter = project.data?._id;
                            } else {
                                errMessage["Quarter"] = CMS_ERROR_MESSAGES.en.invalid_quarter_name;
                            }
                        } catch (error) {
                            errMessage["Quarter"] = CMS_ERROR_MESSAGES.en.invalid_quarter_name;
                        }
                    } else {
                        if (alternative[5] !== undefined) {
                            errMessage["Quarter"] = CMS_ERROR_MESSAGES.en.invalid_quarter_name;
                        }
                    }
    
                    if (typeof alternative[6] === "string") {
                        try {
                            if(!data._product){
                                errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_product_mapping;
                            }else{
                                const keyword = await getContentKeywordPartial({
                                    search: alternative[6],
                                    _product: data._product,
                                });
                                if (keyword.data?._id) {
                                    data.primaryKeyword = keyword.data?._id;
                                } else {
                                    errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_product_mapping;
                                }
                            }
                        } catch (error) {
                            errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_primary_keyword_name;
                        }
                    } else {
                        if (alternative[6] !== undefined) {
                            errMessage["Primary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_primary_keyword_name;
                        }
                    }
    
                    if(typeof alternative[7] !== "string" && alternative[7] !== undefined){
                        errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_secondary_keyword_name;
                    }else{
                        let ind = 7;
                        try {
                            while(ind < alternative.length){
                                if(alternative[ind]){
                                    if(!data._product){
                                        errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_product_mapping;
                                    }else{
                                        const keyword = await getContentKeywordPartial({
                                            search: alternative[ind],
                                            _product: data._product,
                                        });
                                        if (keyword.data?._id) {
                                            secKeywords.push( keyword.data?._id);
                                        } else {
                                            errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.keyword_product_mapping;
                                        }
                                    }
                                }
                                ind++;
                            }
                            data.secondaryKeywords = secKeywords;
                        } catch (error) {
                            errMessage["Secondary Keyword"] = CMS_ERROR_MESSAGES.en.invalid_secondary_keyword_name;
                        }
                    }
    
                    if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                        if (Object.keys(errMessage).length) {
                            error.push({ ...errMessage, row });
                        } else {
                            payload.push(data);
                        }
                    } else {
                        if (Object.keys(errMessage).length) {
                            error.push({ ...errMessage, row });
                        }
                    }
                    ++row;
                }
    
                resolve({
                    data: payload,
                    errorMessage: error,
                });
            })();
        }));

    return { validateAlternative };
};
export default useValidateAlternative;
