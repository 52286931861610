import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomTable from "../../mui/table";
import { ICallStatusRow, ICandidateCallStatus, ICandidateDetails, IColumn, IErrorResponse, IStatus } from "../../../interfaces";
import { capitalize, formatCallStatus, formatDateTime } from "../../../utilities/helper";
import { Controller, useForm } from "react-hook-form";
import Select from "../../mui/select";
import { callStatusValidation } from "../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
import { CandidateService } from "../../../services";
import useSnackbar from "../../../hooks/useSnackbar";
import { useSelector } from "react-redux";
import CustomLabel from "../../mui/custom-label";

interface props {
  candidate: ICandidateDetails | undefined;
  currentTab: number;
  activeAction: boolean;
  setActiveAction?: (e: boolean) => void;
  candidateDetailRefetch: () => void;
  candidateListRefetch: () => void;
}

interface IState {
  list: ICallStatusRow[]
}

const CallStatus: FC<props> = ({ currentTab, activeAction,setActiveAction, candidate, candidateDetailRefetch, candidateListRefetch }) => {
  const callStatuses = candidate && candidate.callStatuses && [...candidate.callStatuses].reverse();
  const { addCandidateCallStatus } = CandidateService();
  const { snackbar } = useSnackbar();
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const [state, setState] = useState<IState>({
    list: []
  });
  const { control, handleSubmit, reset, formState: { errors } } = useForm<ICandidateCallStatus>({
    resolver: joiResolver(callStatusValidation),
    defaultValues: {
      _callStatus: "",
      comment: ""
    }

  });

  useEffect(() => {
    if (callStatuses) {
      const list = callStatuses ? callStatuses.map((callStatus, i) => createRow(i, callStatus)) : [];
      setState(prevState => (
        {
          ...prevState,
          list
        }
      ));
    }
  }, [candidate?.callStatuses]);

  const onSubmit = async (data: ICandidateCallStatus) => {
    try {
      const payload = {
        _id: candidate ? candidate?._id : "",
        callData: {
          ...data,
          timestamp: new Date().toISOString(),
        }
      };

      const updatedCandidate = await addCandidateCallStatus(payload);
      snackbar(updatedCandidate.message, "info");
      reset();
      setActiveAction && setActiveAction(false);
      candidateDetailRefetch();
      candidateListRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      setActiveAction && setActiveAction(false);
      console.log("error in candidate call status add", error);
    }
  };

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "status",
      label: "Call Status"
    },
    {
      id: "called_on",
      label: "Called On"
    },
    {
      id: "called_by",
      label: "Called By"
    },
    {
      id: "notes",
      label: "Notes",
      maxWidth: 370,
      whiteSpace: "normal"
    },
  ];

  const createRow = (index: number, callStatus: ICandidateCallStatus,) => (
    {
      id: index + 1,
      status: formatCallStatus(callStatus && callStatus.status || ""),
      called_on: formatDateTime(callStatus.createdAt),
      called_by: capitalize(callStatus.addedBy.name),
      notes: capitalize(callStatus.comment)
    }
  );

  const isActionActive = currentTab === 2 && activeAction;

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">
        {
          isActionActive &&
          <Box marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Select
                    control={control}
                    name="_callStatus"
                    label={<CustomLabel label="Call Status" required />}
                    size={"small"}
                    variant={"outlined"}
                    error={!!errors._callStatus}
                    helperText={errors["_callStatus"]?.message}
                  >
                    <MenuItem disabled value="none">Select</MenuItem>
                    {
                        statusList
                          .filter(status => status.type === "CALL" && status.status === "ACTIVE")
                          .map((status, i) => <MenuItem key={i} value={status._id}>{capitalize(status?.name)}</MenuItem>)
                    }
                  </Select>
                </Grid>
                <Grid item md={6} lg={7}>
                  <Controller
                    control={control}
                    name="comment"
                    render={(prop) => <TextField
                      label="Note"
                      variant="outlined"
                      size="small"
                      placeholder="Add call status summary"
                      error={errors.comment ? true : false}
                      helperText={errors["comment"]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <Button
                    fullWidth
                    type="submit"
                  >
                    Add Call Status
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        }

        <CustomTable
          columns={columns}
          rows={state.list}
          height={isActionActive ? "calc(100% - 77px)" : "calc(100% - 13px)"}
          width="calc(100% - 2px)"
        />
      </Box>
    </Box>
  );
};

export default CallStatus;