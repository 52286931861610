import { IResponse } from "../../../interfaces";
import { ISitemapsResponse } from "../../../interfaces/site-map/sitemap";
import { publicUrl, sitemap } from "../../endpoints";
import HttpService from "../../http";

const SitemapService = () => {
    const { httpRequest } = HttpService();

    const getSitemap = async (search : object) => new Promise<string>((resolve, reject) => {
        httpRequest<string>(
            "GET",
            `config/${publicUrl}/sitemap`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
      });

      const getSitemaps = async (search: object): Promise<ISitemapsResponse> => new Promise((resolve, reject) => {
        httpRequest<ISitemapsResponse>(
            "GET",
            `${sitemap}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const generateSitemap = async (search: object): Promise<IResponse> => new Promise((resolve, reject) => {
        httpRequest<IResponse>(
            "GET",
            `${sitemap}/generate`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });
      
    return { getSitemap, getSitemaps, generateSitemap };
};

export { SitemapService };