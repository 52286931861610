import HttpService from "../../http";
import { author, authorList } from "../../endpoints";
import {
    IContentAuthorResponse,
    IContentAuthorResponses,
} from "../../../interfaces/content/author";

const AuthorService = () => {
    const { httpRequest } = HttpService();
    const getContentAuthors = async (query: object): Promise<IContentAuthorResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IContentAuthorResponses>(
                "GET",
                `${authorList}`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });

    const addContentAuthor = async (
        payload: object
    ): Promise<IContentAuthorResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IContentAuthorResponse>("POST", `${author}`, payload)
                .then(resolve)
                .catch(reject);
        });

    const getContentAuthor = async (
        query: object
    ): Promise<IContentAuthorResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IContentAuthorResponse>("GET", `${author}`, {}, query)
                .then(resolve)
                .catch(reject);
        });

    const updateContentAuthor = async (
        payload: object
    ): Promise<IContentAuthorResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IContentAuthorResponse>("PUT", `${author}`, payload)
                .then(resolve)
                .catch(reject);
        });

    const deleteContentAuthor = async (
        payload: object
    ): Promise<IContentAuthorResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IContentAuthorResponse>("DELETE", `${author}`, payload)
                .then(resolve)
                .catch(reject);
        });

    return {
        getContentAuthors,
        addContentAuthor,
        getContentAuthor,
        updateContentAuthor,
        deleteContentAuthor,
    };
};

export default AuthorService;
