import { IErrorResponse, IProjectData } from "../../../../interfaces";
import { Autocomplete, capitalize, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useEffect } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { joiResolver } from "@hookform/resolvers/joi";
import CustomLabel from "../../../../components/mui/custom-label";
import { UrlBundleService } from "../../../../services/sitemap";
import { IUrlBundler, IUrlBundlerField } from "../../../../interfaces/site-map/url-bundles";
import { urlHandleValidation } from "../../../../validations/sitemap";
import { useSelector } from "react-redux";
interface outletProps {
    reFetch: () => void;
    refetchUrlBundles: () => void;
}

const ManageUrlBundle = () => {
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = !(id === "new");
    const navigate = useNavigate();
    const { addUrlBundles, updateUrlBundles, getUrlBundle} = UrlBundleService();
    const urlBundle = useQuery({ queryKey: [hitQuery], queryFn: () => getUrlBundle({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, getValues, watch, formState: { errors } } = useForm<IUrlBundler>({
        resolver: joiResolver(urlHandleValidation),
        defaultValues: {
            _product: "",
            type: "",
            changeFrequency: "",
            lastMod: false,
            pattern: ""
        }
    });

    const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
        (state) => state.cmsProduct.list
    ) || [];
    useEffect(() => {
        if (id !== "new" && urlBundle.data) {
          const data = urlBundle.data.data;
          setValue("type", data.type);
          setValue("_product", data._product._id);
          setValue("priority", data.priority.toString());
          setValue("changeFrequency", data.changeFrequency);
          setValue("lastMod", data.lastMod);
          setValue("pattern", data.pattern);
        }
    }, [id, urlBundle.data]);

    const onSubmit = async (data: IUrlBundler) => {
        try {
            if (id === "new") {
                const payload = { ...data };

                const add = await addUrlBundles(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/sitemap/url-bundles",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchUrlBundles && outlet.refetchUrlBundles();
            } else {
                const payload = { ...data, _id: id};
                const update = await updateUrlBundles(payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/sitemap/url-bundles",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchUrlBundles && outlet.refetchUrlBundles();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/sitemap/url-bundles",
            search: searchParam.toString()
        });
    };

    const patternMapping: { [key: string]: string } = {
      BLOG: "/blogs/slug",
      GLOSSARY: "/glossary/slug",
      ALTERNATIVE: "/alternatives/slug",
      COMPARISON: "/comparisons/slug",
    };

    useEffect(() => {
      setValue("pattern", patternMapping[getValues("type")] || "");
    }, [watch("type")]);

    const changeFrequency = [
      { _id: "always", name: "Always" },
      { _id: "hourly", name: "Hourly" },
      { _id: "daily", name: "Daily" },
      { _id: "weekly", name: "Weekly" },
      { _id: "monthly", name: "Monthly" },
      { _id: "yearly", name: "Yearly" },
      { _id: "never", name: "Never" },
    ];

    const priorities = [
      { _id: "0.1", name: "0.1" },
      { _id: "0.2", name: "0.2" },
      { _id: "0.3", name: "0.3" },
      { _id: "0.4", name: "0.4" },
      { _id: "0.5", name: "0.5" },
      { _id: "0.6", name: "0.6" },
      { _id: "0.7", name: "0.7" },
      { _id: "0.8", name: "0.8" },
      { _id: "0.9", name: "0.9" },
      { _id: "1", name: "1" },
    ];

    const types = [
      { _id: "BLOG", name: "Blog" },
      { _id: "GLOSSARY", name: "Glossary" },
      { _id: "ALTERNATIVE", name: "Alternative" },
      { _id: "COMPARISON", name: "Comparison" },
    ];
    
    const fields: IUrlBundlerField[] = [
        {
            label: "Product Name",
            name: "_product",
            type: "auto-complete",
            placeholder: "Select product name",
            required: true,
        },
        {
            type: "select",
            name: "type",
            label: "Type",
            placeholder: "Select type",
            required: true,
            items: types
        },
        {
            label: "Priority",
            name: "priority",
            type: "select",
            placeholder: "Select priority",
            required: true,
            items: priorities
        },
        {
            type: "select",
            name: "changeFrequency",
            label: "Select change frequency",
            placeholder: "Select change frequency",
            required: true,
            items: changeFrequency
        },
        {
            label: "Pattern",
            name: "pattern",
            type: "input",
            placeholder: "Type pattern",
            required: true,
            disabled: true
        },
        {
            type: "boolean",
            name: "lastMod",
            label: "Last Modified",
            required: true,
        },
    ];

    return (
            <CustomDialog
            
                title={id !== "new" ? "Edit Url Bundle" : "Add Url Bundle"}
                isOpen={!!id}
                onClose={onClose}
                confirmText={id !== "new" ? "Edit Url Bundle" : "Add Url Bundle"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => 
                                        <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            disabled={field.disabled}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }else if(field.type === "auto-complete"){
                                return (
                                  <Grid xs={12} md={6} key={field.label} item>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={(prop) => <Autocomplete
                                        className="disable-text"
                                        options={productList.map(
                                          (data) => data
                                        ) || []}
                                        clearIcon={null}
                                        getOptionLabel={(option) => capitalize(option.name)}
                                        renderInput={(params) => <TextField
                                          {...params}
                                          error={!!errors[field.name]}
                                          helperText={errors[field.name]?.message}
                                          size={"small"}
                                          variant={"outlined"}
                                          label={<CustomLabel label="Product" required={field?.required} />}
                                          placeholder={field.placeholder}
                                        />
                                      }
                                        {...prop.field}
                                        value={productList.find(data => data._id === getValues(field.name)) || null}
                                        onChange={(e, value) => {
                                          setValue(field.name, value?._id || "");
                                        }}
                                        renderOption={(props, option) => (
                                          <li {...props} key={option._id}>
                                            {capitalize(option.name)}
                                          </li>
                                        )}
                                      />}
                                    />
                                  </Grid>
                                );
                            }
                            else if(field.type === "boolean"){
                                return (
                                    <Grid key={field.label} item xs={12} md={6}>
                                      <Controller
                                        control={control}
                                        name={field.name}
                                        render={({ field: checkboxField }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={!!checkboxField.value || false}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => checkboxField.onChange(event.target.checked)}
                                              />
                                            }
                                            label={field.label}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  );
                            }
                            else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={(prop) => <Autocomplete
                                        className="disable-text"
                                        options={field.items || []}
                                        clearIcon={null}
                                        getOptionLabel={(item) => capitalize(item.name)}
                                        renderInput={(params) => <TextField
                                          {...params}
                                          error={!!errors[field.name]}
                                          helperText={errors[field.name]?.message}
                                          size={"small"}
                                          variant={"outlined"}
                                          label={<CustomLabel label={field.label} required={field?.required} />}
                                          placeholder={field.placeholder}
                                        />
                                      }
                                        {...prop.field}
                                        value={field.items && field.items.find(data => data._id === getValues(field.name)) || null}
                                        onChange={(e, value) => {
                                          setValue(field.name, value?._id || "");
                                        }}
                                        renderOption={(props, item) => (
                                          <li {...props} key={item._id}>
                                            {capitalize(item.name)}
                                          </li>
                                        )}
                                      />}
                                    />
                                </Grid>
                                );
                              }
                        })
                    }
                </Grid>
            </CustomDialog>
    );

};

export default ManageUrlBundle;