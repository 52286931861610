import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  createIndex
} from "../../../utilities/helper";
import { IAssessmentEvaluationRulesRow, IPagination } from "../../../interfaces";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import useDebounce from "../../../hooks/useDebounce";
import CustomTypography from "../../../components/mui/max-length-limit";
import useResource from "../../../hooks/useResource";
import { IAssessment, IAssessmentState } from "../../../interfaces/configuration/assessments";
import { AssessmentService } from "../../../services/configuration/assessments";

interface outletProps {
  refetchTests: () => void;
}
const AssessmentEvaluation = () => {
  let rows: IAssessmentEvaluationRulesRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAssessments } = AssessmentService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IAssessmentState>({
    deleteWarning: false,
    _question: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      _tests: [],
      _codingChallenges: [],
      status: [],
      _instruction: [],
      date: []
    },
    filterCount: 0,
  });
  const assessments = useQuery({
    queryKey: ["all-assessments-rules", state.pagination.page, state.filters],
    queryFn: () =>
      getAssessments({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    searchRecord.length
      ? setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }))
      : (searchParams.delete("search"), setSearchParams(searchParams));
  }, [searchRecord]);

  useEffect(() => {
    assessments.data &&
      assessments.data.data &&
      assessments.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: assessments.data.meta.page,
          totalPages: assessments.data.meta.totalPages,
          totalRecords: assessments.data.meta.totalRecords,
        },
      }));
  }, [assessments.data && assessments.data.data && assessments.data.meta]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);


  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "assessment_title",
      label: "Title",
    },
    {
      id: "ruleCount",
      label: "Rules",
    },
  ];
  const createRow = (index: number, assessment: IAssessment, pagination: IPagination) => {
    const title =
      <CustomTypography
        limit={50}
        label={assessment.title ?? ""}
        color="primary"
        onClick={
          (resourceAllocate("assessment-evaluation-rule.read"))
            ? () => {
              navigate({
                pathname: `rules/${assessment._id}`,
                search: searchParams.toString(),
              });
            }
            : undefined
        }
      />;

    return {
      id: createIndex(pagination, index),
      assessment_title: title,
      ruleCount: assessment.ruleCount
    };
  };

  if (assessments.data && assessments.data.data && assessments.data.data.length) {
    rows = ((assessments.data && assessments.data.data && assessments.data.data) || []).map(
      (test, i) => createRow(i, test, state.pagination)
    );
  }

  useEffect(() => {
    if (assessments.data && assessments.data.data && assessments.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [assessments.data && assessments.data.data]);

  return (
    <>
      <Header
        className="my-2"
        searchPlaceholder="Search by title"
        onSearch={onSearch}
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <Outlet context={{ ...outlet, reFetch: assessments.refetch }} />
    </>
  );
};
export default AssessmentEvaluation;