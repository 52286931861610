import Joi from "joi";
import { required } from "../shared";

export const ScoreAndStatusValidation = Joi.object({
    score: Joi.number()
    .max(100)
    .min(0)
    .allow("")
    .messages({
      "string.empty": required,
    }),
    status: Joi.string()
    .max(25)
    .messages({
      "string.empty": required,
    }),
});