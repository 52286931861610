import "./style.scss";
import { useEffect, useState } from "react";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  DialogContent,
  Dialog,
  Box,
  Button,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  LOAD_JOB,
  LOAD_USER,
  LOAD_ROLE,
  LOAD_TEAM,
  LOAD_ENGAGEMENT_TYPE,
  LOAD_JOB_POSITION,
  LOAD_STATUS,
  LOAD_TEMPLATE,
  LOAD_COLLEGE,
  LOAD_CPD_DRIVE,
  LOAD_JOB_TITLE,
  LOAD_CMS_PRODUCT,
  THEME_CONFIGURATION,
} from "../redux";
import {
  UsersService,
  RoleService,
  StatusService,
  EngagementService,
  JobPositionService,
  TeamsService,
  JobTitleService,
  JobService,
  CollegeService,
  TemplateService,
  cpdDriveService,
  NotificationService,
} from "../services";
import Logout from "../assets/images/logout.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import homeIcon from "@mui/icons-material/Home";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import Media from "@mui/icons-material/Image";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ListItem from "./components/layout-list-item";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SchoolIcon from "@mui/icons-material/School";
import EventIcon from "@mui/icons-material/Event";
import AssessmentIcon from "@mui/icons-material/Assessment";
import WebIcon from "@mui/icons-material/Web";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SourceIcon from "@mui/icons-material/Source";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DeleteIcon from "@mui/icons-material/Delete";
import useUser from "../hooks/useUser";
import useResource from "../hooks/useResource";
import { capitalize } from "../utilities/helper";
import Notification from "../screens/notification";
import SearchCandidate from "../components/search-candidate";
import ListIcon from "@mui/icons-material/List";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CompareIcon from "@mui/icons-material/Compare";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import InsightsIcon from "@mui/icons-material/Insights";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ProjectsService from "../services/content/projects-products";
import { IProject } from "../interfaces/content/projects-products";
import { TestsService } from "../services/configuration/tests";
import AccordionMenu from "./components/layout-accordion";
import PeopleIcon from "@mui/icons-material/People";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { ReactComponent as CandidateCareerIcon } from "../assets/images/candidate-career.svg";
import { ReactComponent as AnalyticsTabIcon } from "../assets/images/analytics-tab.svg";
import { ReactComponent as CmsTabIcon } from "../assets/images/cms-tab.svg";
import { ReactComponent as ConfigurationTabIcon } from "../assets/images/configuration-tab.svg";
import { ReactComponent as DashboardTabIcon } from "../assets/images/dashboard-tab.svg";
import { ReactComponent as DirectoryTabIcon } from "../assets/images/directory-tab.svg";
import { ReactComponent as ResourceTabIcon } from "../assets/images/resource-planning-tab.svg";
import { ReactComponent as SkillMatrixTabIcon } from "../assets/images/skill-matrix-tab.svg";
import { ReactComponent as TrashTabIcon } from "../assets/images/trash-tab.svg";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import StarIcon from "@mui/icons-material/Star";
import CategoryIcon from "@mui/icons-material/Category";
import AppsIcon from "@mui/icons-material/Apps";
import Groups2Icon from "@mui/icons-material/Groups2";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BarChartIcon from "@mui/icons-material/BarChart";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PollIcon from "@mui/icons-material/Poll";
import { IErrorResponse } from "../interfaces";
import useSnackbar from "../hooks/useSnackbar";
import { organisationConfigurationDataService } from "../services/organisation-themes";
const Layout = () => {
  const dispatch = useDispatch();
  const { resourceAllocate } = useResource();
  const navigate = useNavigate();
  const { setUser } = useUser();
  const { getPartialJobs } = JobService();
  const { getPartialUsers, logout } = UsersService();
  const { getPartialRoles } = RoleService();
  const { getPartialTeams } = TeamsService();
  const { getPartialEngagements } = EngagementService();
  const { getJobPositions } = JobPositionService();
  const { getPartialJobTitles } = JobTitleService();
  const { getStatuss } = StatusService();
  const { getTemplates } = TemplateService();
  const { getPartialColleges } = CollegeService();
  const { getCpdDrives } = cpdDriveService();
  const { countNotifications } = NotificationService();
  const { getContentProjects } = ProjectsService();
  const { getTests } = TestsService();
  const { snackbar } = useSnackbar();


  const { user } = useUser();
  const getJobs = useQuery({
    queryKey: ["partialJob"],
    queryFn: () => getPartialJobs(),
    enabled: resourceAllocate("job.read"),
  });
  const getUsers = useQuery({
    queryKey: ["partialUSers"],
    queryFn: () => getPartialUsers(),
    enabled: resourceAllocate("user.read") || resourceAllocate("common.read"),
  });
  const getTest = useQuery({
    queryKey: ["getTest"],
    queryFn: () => getTests(),
    enabled: resourceAllocate("test.read"),
  });

  const roles = useQuery({
    queryKey: ["partialRoles"],
    queryFn: () => getPartialRoles(),
    enabled: resourceAllocate("user-role.read"),
  });
  const teams = useQuery({
    queryKey: ["partialTeams"],
    queryFn: () => getPartialTeams(),
    enabled: resourceAllocate("department.read") || resourceAllocate("common.read"),
  });
  const getEngagementTypes = useQuery({
    queryKey: ["partialEngagementType"],
    queryFn: () => getPartialEngagements(),
    enabled: resourceAllocate("job-type.read") || resourceAllocate("common.read"),
  });
  const jobPositions = useQuery({
    queryKey: ["partialJobPositions"],
    queryFn: () => getJobPositions({}),
    enabled: resourceAllocate("job-position.read"),
  });
  const jobTitles = useQuery({
    queryKey: ["partialJobTitles"],
    queryFn: () => getPartialJobTitles({}),
    enabled: resourceAllocate("job-title.read") || resourceAllocate("common.read"),
  });
  const getStatusData = useQuery({
    queryKey: ["allStatus"],
    queryFn: () => getStatuss(),
    enabled: resourceAllocate("status.read") || resourceAllocate("common.read"),
  });
  const templates = useQuery({
    queryKey: ["allTemplates"],
    queryFn: () => getTemplates(),
    enabled: resourceAllocate("template.read") || resourceAllocate("common.read"),
  });
  const getColleges = useQuery({
    queryKey: ["partialColleges"],
    queryFn: () => getPartialColleges(),
    enabled: resourceAllocate("college.read"),
  });
  const getDrives = useQuery({
    queryKey: ["allCpdDrives"],
    queryFn: () => getCpdDrives({}),
    enabled: resourceAllocate("college-recruitment.read"),
  });
  const notificationCount = useQuery({
    queryKey: ["notification-count"],
    queryFn: () => countNotifications(),
    enabled: resourceAllocate("notification.read"),
  });
  const projectsList = useQuery({
    queryKey: ["projects"],
    queryFn: () => getContentProjects({ isActive: true }),
    enabled: resourceAllocate("cms-product-title.read"),
  });

  const { getOrganisationThemeConfiguration } = organisationConfigurationDataService();

  const organisationConfiguration = useQuery({
    queryKey: ["organisation-theme-configuration"],
    queryFn: () => getOrganisationThemeConfiguration(),
  });
  const orgThemeData = organisationConfiguration && organisationConfiguration.data && organisationConfiguration.data.data;

  if (organisationConfiguration.data?.data) {
    dispatch({ type: THEME_CONFIGURATION, payload: { list: organisationConfiguration?.data?.data } });
  }

  if (getJobs.data?.data.length) {
    dispatch({ type: LOAD_JOB, payload: { list: getJobs?.data?.data } });
  }

  if (getUsers.data?.data.length) {
    dispatch({ type: LOAD_USER, payload: { list: getUsers?.data?.data } });
  }

  if (roles.data?.data.length) {
    dispatch({ type: LOAD_ROLE, payload: { list: roles?.data?.data } });
  }

  if (teams.data?.data.length) {
    dispatch({ type: LOAD_TEAM, payload: { list: teams?.data?.data } });
  }

  if (getEngagementTypes.data?.data.length) {
    dispatch({
      type: LOAD_ENGAGEMENT_TYPE,
      payload: { list: getEngagementTypes?.data?.data },
    });
  }

  if (jobPositions.data?.data.length) {
    dispatch({
      type: LOAD_JOB_POSITION,
      payload: { list: jobPositions?.data?.data },
    });
  }

  if (jobTitles.data?.data.length) {
    dispatch({
      type: LOAD_JOB_TITLE,
      payload: { list: jobTitles?.data?.data },
    });
  }

  if (getStatusData.data?.data.length) {
    dispatch({
      type: LOAD_STATUS,
      payload: { list: getStatusData?.data?.data },
    });
  }

  if (templates.data?.data.length) {
    dispatch({ type: LOAD_TEMPLATE, payload: { list: templates?.data?.data } });
  }

  if (getColleges.data?.data.length) {
    dispatch({
      type: LOAD_COLLEGE,
      payload: { list: getColleges?.data?.data },
    });
  }

  if (getDrives.data?.data.length) {
    dispatch({
      type: LOAD_CPD_DRIVE,
      payload: { list: getDrives?.data?.data },
    });
  }

  if (projectsList.data?.data.length) {
    dispatch({
      type: LOAD_CMS_PRODUCT,
      payload: { list: projectsList?.data?.data },
    });
  }

  const projects =
    useSelector<{ cmsProduct: { list: IProject[] } }, IProject[]>(
      (state) => state?.cmsProduct?.list
    ) || [];

const logoFromAdminPanel = orgThemeData?.logo;

  const selectedLink = projects && projects[0];
  const id = selectedLink ? selectedLink._id : "";

  const isCollapse = localStorage.getItem("collapse-side-bar");
  const location = useLocation();
  const [state, setState] = useState({
    collapse: isCollapse === "true" ? false : true,
    menuOption: false,
    logoutWarning: false,
    isNotificationOpen: false,
  });

  useEffect(() => {
    changeSidebarSize();
  }, []);

  const changeSidebarSize = () => {
    const leftSide = document.getElementById("left") as HTMLElement;
    const rightSide = document.getElementById("right") as HTMLElement;
    const collapseText = document.getElementsByClassName(
      "collapse-text"
    ) as HTMLCollectionOf<HTMLDivElement>;
    const collapseListItem = document.getElementsByClassName(
      "collapse-ListItem"
    ) as HTMLCollectionOf<HTMLDivElement>;

    if (state.collapse) {
      leftSide.style.width = "200px";
      rightSide.style.marginLeft = "calc(200px + 16px)";

      for (let i = 0; i < collapseText.length; i++) {
        collapseText[i].style.display = "initial";
      }
      for (let i = 0; i < collapseListItem.length; i++) {
        collapseListItem[i].style.paddingLeft = "16px";
      }
    } else {
      leftSide.style.width = "54px";
      rightSide.style.marginLeft = "calc(60px + 16px)";
      for (let i = 0; i < collapseText.length; i++) {
        collapseText[i].style.display = "none";
      }
      for (let i = 0; i < collapseListItem.length; i++) {
        collapseListItem[i].style.paddingLeft = "16px";
      }
    }

    setState((prevState) => ({
      ...prevState,
      collapse: !prevState.collapse,
    }));

    localStorage.setItem("collapse-side-bar", String(!state.collapse));
  };

  const handleProfileMenu = () => {
    setState((prevState) => ({
      ...prevState,
      menuOption: !prevState.menuOption,
    }));
  };

  const handleLogout = () => {
    setState((prevState) => ({
      ...prevState,
      logoutWarning: !prevState.logoutWarning,
    }));
  };

  const onLogout = () => {
    logout()
    .then((response) => {
      localStorage.removeItem("currentUserToken");
      localStorage.removeItem("selectedFilterProductId");
      setUser(() => ({}));
      navigate("/login");
      snackbar(response.message, "info");
    })
    .catch((err) => {
      const error = err as IErrorResponse;
      snackbar(error?.data?.message, "error");
      if(err.status === 401) {
       navigate("/login");
      }
    });
  };

  const displayName = (name: string) => {
    if (name?.length > 9) {
      return `${capitalize(name.slice(0, 10))}...`;
    } else {
      return name;
    }
  };

  const onOpenNotificationDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isNotificationOpen: true,
    }));
  };

  const onCloseNotificationDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isNotificationOpen: false,
    }));
  };

  const showNavTitle = (title: string) => {
    let text = title;
    switch (title) {
      case "cpd":
        text = "CPD";
        break;
      case "broadcast-events":
        text = "Broadcast Analytics";
        break;
      case "broadcast-group":
        text = "Broadcast Group";
        break;
      case "kpi":
        text = "KPI";
        break;
      case "skill-matrix":
        text = "Skill Matrix";
        break;
      case "requested-skill":
        text = "Requested Skill";
        break;
      case "resource-requested":
        text = "Resource Planning";
        break;
      case "blogs-analytics":
        text = "Blogs Analytics";
        break;
      case "content":
        text = "Planner Configuration";
        break;
      case "organisation-tree":
        text = "Organisation Tree";
        break;
      case "organisation-configuration":
        text = "Organisation Configuration";
        break;      
      case "skill-development":
        text = "Skill Development";
        break;
      case "pms-parameters": 
        text = location.pathname.split("/")[2] === "sub-parameters" ? "Sub Parameters" : "Parameters";
        break;
      case "self-evaluation":
        text = "Self Evaluation";
        break;
      case "team-evaluation":
        text = "Team Evaluation";
        break;
      case "okr-objectives":
        text = location.pathname.split("/")[2] === "key-results" ? "Key Results" : "Objectives";
        break;
      case "okr-self-evaluation":
        text = "Self Assessment";
        break;
      case "okr-ratings":
        text = "Ratings";
        break;
      case "okr-evaluations":
        text = "Evaluations"; 
        break;
      case "category-content":
        text = "Category Content";
        break;
      case "skill-analytics":
        text = "Skill Analytics";
        break;
      case "alternative-comparison":
        text = "Comparison";
        break;
      case "user-requests":
        text = "User Requests";
        break;
      case "assessments":
        text= "Interviews and Reviews";
        break;
      case "assessment":
        text = "Assessment Configuration";
        break;
    }

    return text;
  };

    const navigateLink = (
    module:
      | "cpd"
      | "report"
      | "configuration"
      | "assessment"
      | "careers"
      | "cms-configuration"
      | "assessments"
      | "assessments-evaluation"
      | "assessments-penality"
      | "directory"
      | "skill-development"
      | "trash"
      | "organisation-configuration"
      | "self-evaluation"
      | "team-evaluation"
      | "sitemap"
  ) => {
    let link = "";
    switch (module) {
      case "cpd": {
        if (resourceAllocate("cpd-dashboard")) {
          link = "/cpd/dashboard";
        } else if (resourceAllocate("cpd-drives")) {
          link = "/cpd/drives";
        } else if (resourceAllocate("college")) {
          link = "/cpd/college";
        }
        break;
      }
      case "careers": {
        if (resourceAllocate("job")) {
          link = "/careers/jobs";
        } else if (resourceAllocate("employee-review")) {
          link = "/careers/employee-review";
        } else if (resourceAllocate("college-image")) {
          link = "/careers/college-images";
        } else if (resourceAllocate("employee-image")) {
          link = "/careers/office-employee-images";
        } else if (resourceAllocate("faq")) {
          link = "/careers/faqs";
        } else if (resourceAllocate("careers-highlight")) {
          link = "/careers/highlight";
        }
        break;
      }
      case "sitemap": {
        if(resourceAllocate("cms-sitemap")) {  
          link = "/sitemap/sitemap-data";
        }else if (resourceAllocate("cms-url-bundle")) {
          link = "/sitemap/url-bundles";
        } else if (resourceAllocate("cms-custom-url")) {
          link = "/sitemap/custom-url";
        } else if (resourceAllocate("cms-sitemap-setting")) {
          link = "/sitemap/settings";
        }
        break;
      }
      case "report": {
        if (resourceAllocate("report-analytics")) {
          link = "/reports/overview";
        } else if (resourceAllocate("kpi")) {
          link = "/reports/kpi";
        } else if (resourceAllocate("cms-blog-analytics")) {
          link = "/reports/cms";
        }
        break;
      }
      case "assessments": {
        if (resourceAllocate("interview")) {
          link = "/assessments/interview/upcoming";
        } else if (resourceAllocate("review")) {
          link = "/assessments/review/pending";
        }
        break;
      }
      case "configuration": {
        if (resourceAllocate("user-role")) {
          link = "/configurations/roles";
        } else if (resourceAllocate("resource-request-role")) {
          link = "/configurations/resource-request-roles";
        } else if (resourceAllocate("department")) {
          link = "/configurations/departments";
        } else if (resourceAllocate("business-unit")) {
          link = "/configurations/business-unit";
        } else if(resourceAllocate("job-type")) {
          link = "/configurations/engagement-type";
        } else if(resourceAllocate("job-position")) {
          link = "/configurations/designation"; 
        } else if (resourceAllocate("job-title")) {
          link = "/configurations/job-title";
        } else if (resourceAllocate("template")) {
          link = "/configurations/template";
        } else if (resourceAllocate("status")) {
          link = "/configurations/status";
        } else if (resourceAllocate("google-integration")) {
          link = "/configurations/integration";
        } else if (resourceAllocate("interview-parameter")) {
          link = "/configurations/parameters";
        } else if (resourceAllocate("review-parameter")) {
          link = "/configurations/review-parameters";
        } else if (resourceAllocate("onboard-users")) {
          link = "/configurations/onboard-user";
        } else if(resourceAllocate("coderbyte-links")) {
          link = "/configurations/coder-byte";
        } else if(resourceAllocate("automation")) {
          link = "/configurations/automation";
        } else if (resourceAllocate("daily-report-roles")) {
          link = "/configurations/daily-report-roles";
        } else if (resourceAllocate("configuration-course")) {
          link = "/configurations/course";        
        } 
        
        break;
      }
      case "assessment": {
        if(resourceAllocate("assessment")) {  
          link = "/assessment/assessments";
        } else if (resourceAllocate("technical-test-tag")) {
          link = "/assessment/question-tags";
        } else if (resourceAllocate("technical-question-languages")) {
          link = "/assessment/question-languages";
        } else if (resourceAllocate("technical-question")) {
          link = "/assessment/technical-questions";
        } else if (resourceAllocate("assessment-instruction")) {
          link = "/assessment/assessments-instruction";
        } else if(resourceAllocate("assessment-media")) {
          link = "/assessment/assessments-media";
        } else if (resourceAllocate("assessment-evaluation-rule")) {
          link = "/assessment/assessments-evaluation";
        } else if (resourceAllocate("assessment-penality")) {
          link = "/assessment/assessments-penality";
        } else if (resourceAllocate("test")) {
          link = "/assessment/tests";
        }
        break;
      }
			case "directory": {
				if (resourceAllocate("vendor")) {
					link = "/directory/vendors";
				}
				else if (resourceAllocate("vendor-category")) {
					link = "/directory/categories";
				}
				else if (resourceAllocate("vendor-service")) {
					link = "/directory/services";
				}
				break;
			}
      case "trash": {
        if (resourceAllocate("leads-trash")) {
          link = "/trash/candidate-trash";
        }
        else if (resourceAllocate("blogs-trash")) {
          link = "/trash/blog-trash";
        }
        else if(resourceAllocate("glossary-trash")) { 
          link = "/trash/glossary-trash";
        }
        else if(resourceAllocate("cms-alternative-comparison-trash")) {
          link = "/trash/comparison-trash";
        }
        break;
      }
      case "cms-configuration": {
        if (resourceAllocate("cms-product-title")) {
          link = "/content/products";
        } else if (resourceAllocate("cms-category")) {
          link = "/content/category";
        } else if (resourceAllocate("cms-sub-category")) {
          link = "/content/sub-category";
        } else if (resourceAllocate("cms-keyword")) {
          link = "/content/keywords";
        } else if (resourceAllocate("cms-funnel-parts")) {
          link = "/content/parts-of-funnel";
        } else if (resourceAllocate("cms-goal")) {
          link = "/content/goals";
        } else if (resourceAllocate("cms-content-types")) {
          link = "/content/type-of-content";
        } else if (resourceAllocate("cms-score-data")) {
          link = "/content/score-data";
        } else if (resourceAllocate("cms-product-mapping")) {
          link = "/content/product-mapping";
        }
        break;
      }
      case "organisation-configuration": {
        if (resourceAllocate("organisation-configuration.write")) {
          link = "/organisation-configuration/basic-details";
        }
        break;
      }
      case "self-evaluation": {
        if (resourceAllocate("pms-feedback-rating")) {  
          link = "/self-evaluation/overview";
        } else if (resourceAllocate("pms-feedback-rating.read")) {   
          link = "/self-evaluation/feedbacks";
        }
        break;
      }
      case "team-evaluation": {
        if (resourceAllocate("pms-feedback-rating")) {  
          link = "/team-evaluation/overview";
        } else if (resourceAllocate("pms-feedback-rating.read")) {  
          link = "/team-evaluation/feedbacks";
        }
        break;
      }
      case "skill-development":{
        if (resourceAllocate("course-assignment.readAssigned")) {
          link = "/skill-development/assigned-course-list";
        }
        break;
      }
    }
    return link;
  };

  const showHeader = !new RegExp("/blog/manage").test(location.pathname) && !new RegExp("/comparison/manage").test(location.pathname) && !new RegExp("/glossary/manage").test(location.pathname);

  const menuItemsDashboard = {
    name: "Dashboard",
    data: [
      {
        label: "Dashboard",
        Icon: DashboardIcon,
        navigateLink: "/dashboard",
        accessKey: "dashboard",
        resource: ["dashboard"],
      },
      {
        label: "Home",
        Icon: homeIcon,
        navigateLink: "/home",
        accessKey: "cms-home",
        resource: ["cms-home"],
      },
    ],
    Permissions: ["dashboard", "cms-home"],
  };

  const menuItemsContentManagement = {
    name: "CMS",
    data: [
      {
        label: "Planner-config",
        Icon: ManageAccountsIcon,
        navigateLink: navigateLink("cms-configuration"),
        accessKey: "Blog-Planner",
        resource: [
          "cms-product-title",
          "cms-category",
          "cms-sub-category",
          "cms-keyword",
          "cms-goal",
          "cms-funnel-parts",
          "cms-content-types",
          "cms-score-data",
          "cms-product-mapping",
        ],
      },
      {
        label: "Planner",
        Icon: EventAvailableIcon,
        navigateLink: `/planner/${id}`,
        accessKey: "cms-quarter",
        resource: ["cms-quarter"],
      },
      {
        label: "Blogs",
        Icon: ListIcon,
        navigateLink: "/blog",
        accessKey: "cms-blog",
        resource: ["cms-blog"],
      },
      {
        label: "Comparison",
        Icon: CompareIcon,
        navigateLink: "/comparison",
        accessKey: "cms-alternative-comparison",
        resource: ["cms-alternative-comparison"],
      },
      {
        label: "Glossary",
        Icon: SourceIcon, 
        navigateLink: "/glossary",
        accessKey: "cms-glossary",
        resource: ["cms-glossary"],
      },
      {
        label: "Sitemap",
        Icon: AppsIcon,
        navigateLink: navigateLink("sitemap"),
        accessKey: "cms-url-bundle",
        resource: ["cms-url-bundle", "cms-custom-url", "cms-sitemap-setting", "cms-sitemap"],
      },
      {
        label: "Category Content",
        Icon: CategoryIcon,
        navigateLink: "/category-content",
        accessKey: "cms-category-content",
        resource: ["cms-category-content"],
      },
      {
        label: "Media",
        Icon: Media,
        navigateLink: "/media-images",
        accessKey: "cms-media-image",
        resource: ["cms-media-image"],
      }
    ],
    Permissions: [
      "cms-product-title",
      "cms-category",
      "cms-sub-category",
      "cms-keyword",
      "cms-goal",
      "cms-funnel-parts",
      "cms-content-types",
      "cms-score-data",
      "cms-product-mapping",
      "cms-quarter",
      "cms-blog",
      "cms-url-bundle",
      "cms-custom-url",
      "cms-sitemap-setting",
      "alternative-comparison"
    ],
  };

  const menuItemsCandidateManagement = {
    name: "Candidates & Careers",
    data: [
      {
        label: "Candidates",
        Icon: SupervisedUserCircleIcon,
        navigateLink: "/candidates/intern",
        accessKey: "lead",
        resource: ["lead"],
      },
      {
        label: "CPD",
        Icon: SchoolIcon,
        navigateLink: navigateLink("cpd"),
        accessKey: "cpd",
        resource: ["cpd-dashboard", "cpd-drives", "college"],
      },
      {
        label: "Careers Page",
        Icon: WebIcon,
        navigateLink: navigateLink("careers"),
        accessKey: "careers-page",
        resource: [
          "job",
          "employee-review",
          "college-image",
          "employee-image",
          "faq",
          "careers-highlight",
        ],
      },
      {
        label: "Offers",
        Icon: RecentActorsIcon,
        navigateLink: "/offered",
        accessKey: "offer",
        resource: ["offer"],
      },
      {
        label: "Interviews and Reviews",
        Icon: EventIcon,
        navigateLink: navigateLink("assessments"),
        accessKey: "assessment",
        resource: ["interview", "review"],
      },
      {
        label: "Broadcast Group",
        Icon: ForwardToInboxIcon,
        navigateLink: "/broadcast-group",
        accessKey: "broadcast-group",
        resource: ["broadcast-group"],
      },
      {
        label: "Assessments",
        Icon: AssessmentIcon,
        navigateLink: navigateLink("assessment"),
        accessKey: "assessment",
        resource: [
          "assessment",
          "technical-test-tag",
          "technical-question-languages",
          "technical-question",
          "assessment-instruction",
          "assessment-evaluation-rule",
          "assessment-penality",
          "test"
        ],
      },
    ],
    Permissions: [
      "lead",
      "cpd-dashboard",
      "cpd-drives",
      "college",
      "job",
      "employee-review",
      "college-image",
      "employee-image",
      "faq",
      "careers-highlight",
      "offer",
      "interview",
      "review",
      "broadcast-group",
      "assessment",
      "technical-test-tag",
      "technical-question-languages",
      "technical-question",
      "assessment-instruction",
      "assessment-evaluation-rule",
      "assessment-penality",
      "test"
    ],
  };

  const menuItemsTrash = {
    name: "Trash",
    data: [
      {
        label: "Trash",
        Icon: DeleteIcon,
        navigateLink: navigateLink("trash"),
        accessKey: "trash",
        resource: ["leads-trash", "blogs-trash", "glossary-trash", "cms-alternative-comparison-trash"],
      },
    ],
    Permissions: ["leads-trash", "blogs-trash", "glossary-trash", "cms-alternative-comparison-trash"],
  };

  const menuItemsConfiguration = {
    name: "Configurations",
    data: [
      {
        label: "Configurations",
        Icon: ManageAccountsIcon,
        navigateLink: navigateLink("configuration"),
        accessKey: "user-role",
        resource: [
          "user-role",
          "department",
          "job-type",
          "job-position",
          "job-title",
          "template",
          "status",
          "google-integration",
          "interview-parameter",
          "review-parameter",
          "onboard-user",
          "resource-request-role",
          "daily-report",
          "configuration-course",
        ],
      },
    ],
    Permissions: [
      "user-role",
      "department",
      "job-type",
      "job-position",
      "job-title",
      "template",
      "status",
      "google-integration",
      "interview-parameter",
      "review-parameter",
      "onboard-user",
      "resource-request-role",
      "daily-report",
      "configuration-course",
    ],
  };

  const menuItemsDirectory = {
    name: "Directory",
    data: [
      {
        label: "Directory",
        Icon: StoreMallDirectoryIcon,
        navigateLink: "/directory/vendors",
        accessKey: "vendor",
        resource: ["vendor","vendor-category.read","vendor-service.read"],
      },
    ],
    Permissions: ["vendor","vendor-category.read","vendor-service.read"],
  };

  const menuItemsResourcePlanning = {
    name: "Resource Planning",
    data: [
      {
        label: "Resource Planning",
        Icon: BorderColorIcon,
        navigateLink: "/resource-requested/requests",
        accessKey: "resource-request",
        resource: ["resource-request"],
      },
    ],
    Permissions: ["resource-request"],
  };

  const menuItemsReports = {
    name: "Analytics",
    data: [
      {
        label: "Reports",
        Icon: AnalyticsIcon,
        navigateLink: navigateLink("report"),
        accessKey: "report-analytics",
        resource: ["report-analytics", "kpi", "cms-blog-analytics"],
      },
      {
        label: "Blogs Analytics",
        Icon: InsightsIcon,
        navigateLink: "/blogs-analytics/pages",
        accessKey: "cms-blog-insights",
        resource: ["cms-blog-insights"],
      },
    ],
    Permissions: ["report-analytics", "kpi", "cms-blog-analytics", "cms-blog-insights"],
  };

  const menuItemSkillMatrix = {
    name: "Skill Management",
    data: [
      {
        label: "Skill Matrix",
        Icon: ListIcon,
        navigateLink: "/skill-matrix",
        accessKey: "skill-matrix",
        resource: ["skill-matrix"],
      },
      {
        label: "Requested Skill",
        Icon: AnnouncementIcon,
        navigateLink: "/requested-skill",
        accessKey: "skill-matrix-request",
        resource: ["skill-matrix-request"],
      },
      {
        label: "Skill Development",
        Icon: LibraryBooksIcon,
        navigateLink: "/skill-development/assigned-course-list",
        accessKey: "course-assignment",
        resource: ["course-assignment"],
      },
      {
        label: "Skill Analytics",
        Icon: AnalyticsIcon,
        navigateLink: "/skill-analytics",
        accessKey: "skill-matrix-analytics",
        resource: ["skill-matrix-analytics"],        
      },
    ],
    Permissions: ["skill-matrix", "skill-matrix-request", "skill-matrix-analytics", "course-assignment"],
  };

  const menuItemOrganisation = {
    name: "Organisation",
    data: [
      {
        label: "Users",
        Icon: PeopleIcon,
        navigateLink: "/users",
        accessKey: "user",
        resource: ["user"],
      },
      {
        label: "User Requests",
        Icon: GroupAddIcon,
        navigateLink: "/user-requests",
        accessKey: "user",
        resource: ["user.write"],
      },
      {
        label: "Organisation Tree",
        Icon: BadgeIcon,
        navigateLink: "/organisation-tree",
        accessKey: "organisation-tree",
        resource: ["organisation-tree"],
      },
      {
        label: "Organisation Configuration",
        Icon: PeopleIcon,
        navigateLink: navigateLink("organisation-configuration"),
        accessKey: "organisation-themes",
        resource: ["organisation-configuration.write"],
      },
    ],
    Permissions: ["user", "user.write", "organisation-tree", "organisation-configuration.write"],
  };

  const menuItemsPMS = {
    name: "Performance Management",
    data: [
      {
        label: "Parameters",
        Icon: FormatListBulletedIcon,
        navigateLink: "/pms-parameters",
        accessKey: "pms-parameter",
        resource: ["pms-parameter"],
      },
      {
        label: "Self Evaluation",
        Icon: AccountBoxIcon,
        navigateLink: navigateLink("self-evaluation"),
        accessKey: "pms-feedback-rating",
        resource: ["pms-feedback-rating"],
      },
      {
        label: "Team Evaluation",
        Icon: Groups2Icon,
        navigateLink: navigateLink("team-evaluation"),
        accessKey: "pms-feedback-rating",
        resource: ["pms-feedback-rating"],
      },
    ],
    Permissions: ["pms-parameter", "pms-feedback-rating"],
  };

  const menuItemsOKR = {
    name: "OKR",
    data: [
      {
        label: "Objectives",
        Icon: FormatListBulletedIcon,
        navigateLink: "/okr-objectives",
        accessKey: "okr-objectives",
        resource: ["okr-objectives"],
      },
      {
        label: "Self Assessment",
        Icon: AccountBoxIcon,
        navigateLink: "okr-self-evaluation/pending",
        accessKey: "okr-self-evaluation", 
        resource: ["okr-self-evaluation"],
      },
      {
        label: "Evaluations",
        Icon: ChecklistIcon,
        navigateLink: "/okr-evaluations/pending",
        accessKey: "okr-team-evaluation", 
        resource: ["okr-team-evaluation"],
      },
      {
        label: "Ratings",
        Icon: StarIcon,
        navigateLink: "/okr-ratings",
        accessKey: "okr-ratings",
        resource: ["okr-ratings"],
      }
    ],
    Permissions: ["okr-objectives", "okr-ratings", "okr-team-evaluation", "okr-self-evaluation"],
  };

  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(null);
  
	const handleAccordionToggle = (accordionName: string|null) => {
    setExpandedAccordion(expandedAccordion === accordionName ? null : accordionName);
	};

  const organisationLogo = String(process.env.REACT_APP_S3_BASE_URL) + logoFromAdminPanel;

  return (
    <>
      {/* left sidebar  */}
      <div id="left" className="left-side-nav">
        <div className="center">
          {!state.collapse ? (
            <div 
              id="logo" 
              className="logo-box" 
              onClick={changeSidebarSize}
            >
              <img src={organisationLogo} alt="logo" />
            </div>
          ) : (
            <div 
              id="collapse"
              className="collapse-arrow"
              onClick={changeSidebarSize}
            >
              <ArrowForwardIosIcon className="collapse-arrow-icon h-100" />
            </div>
          )}
        </div>

        <div className={state.collapse ? "nav-items-collapse-box" : "nav-items-box"}>
          <div style={{ overflowY: "auto", overflowX: "hidden" }}>
            {/* // For Dashboard */}
            {menuItemsDashboard.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <AccordionMenu
                menuItems={menuItemsDashboard}
                isCollapse={state.collapse}
                Icon={DashboardTabIcon}
                navigateLink={["/dashboard", "/home/dashboard"]}
                expanded={expandedAccordion === "dashboard"}
                onToggle={() => handleAccordionToggle("dashboard")}
              />
            )}

            {/* Project and Content Management */}
            {menuItemsContentManagement.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <AccordionMenu
                menuItems={menuItemsContentManagement}
                isCollapse={state.collapse}
                Icon={CmsTabIcon}
                navigateLink={[
                  "/content",
                  "/planner",
                  "/blog",
                  "/media-images",
                  "/comparison",
                  "/glossary",
                  "/sitemap",
                  "/category-content"
                ]}
                expanded={expandedAccordion === "contentManagement"}
                onToggle={() => handleAccordionToggle("contentManagement")}
                
              />
            )}

            {/* Candidate and Employee Management */}
            {menuItemsCandidateManagement.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <AccordionMenu
                menuItems={menuItemsCandidateManagement}
                isCollapse={state.collapse}
                Icon={CandidateCareerIcon}
                navigateLink={[
                  "/candidates",
                  "/cpd",
                  "/offered",
                  "/assessments",
                  "/broadcast-group",
                  "/careers",
                  "/assessment"
                ]}
                expanded={expandedAccordion === "candidateManagement"}
                onToggle={() => handleAccordionToggle("candidateManagement")}
              />
            )}

            {/* Reports */}
            {menuItemsReports.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <AccordionMenu
                menuItems={menuItemsReports}
                isCollapse={state.collapse}
                Icon={AnalyticsTabIcon}
                navigateLink={[
                  "/reports",
                  "/blogs-analytics"
                ]}
                expanded={expandedAccordion === "reports"}
                onToggle={() => handleAccordionToggle("reports")}
              />
            )}

            {/* Trash */}
            {menuItemsTrash.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <ListItem
                isCollapse={state.collapse}
                Icon={TrashTabIcon}
                navigateLink={navigateLink("trash")}
                label="Trash"
                mainMenuItem={true}
              />
            )}

            {/* Configuration */}
            {menuItemsConfiguration.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
							<ListItem
								isCollapse={state.collapse}
								Icon={ConfigurationTabIcon}
								navigateLink={navigateLink("configuration")}
								label="Configurations"
								mainMenuItem={true}
							/>
            )}

            {/* Directory Management */}
						{menuItemsDirectory.Permissions.some((resource) =>
							resourceAllocate(resource)
						) && (
								<ListItem
									isCollapse={state.collapse}
									Icon={DirectoryTabIcon}
									navigateLink={navigateLink("directory")}
									label="Directory Management"
									mainMenuItem={true}
								/>
							)}
            {/* Resource Planning */}
            {menuItemsResourcePlanning.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <ListItem
                label="Resource Planning"
                Icon={ResourceTabIcon}
                navigateLink={"/resource-requested/requests"}
                isCollapse={state.collapse}
                mainMenuItem={true}
              />
            )}

            {/* Skill Matrix */}
            {menuItemSkillMatrix.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <AccordionMenu
                menuItems={menuItemSkillMatrix}
                isCollapse={state.collapse}
                Icon={SkillMatrixTabIcon}
                navigateLink={["/skill-matrix", "/requested-skill", "/skill-analytics"]}
                expanded={expandedAccordion === "skillMatrix"}
                onToggle={() => handleAccordionToggle("skillMatrix")}
              />
            )}

            {/* Performance Management System */}
            {menuItemsPMS.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <AccordionMenu
                menuItems={menuItemsPMS}
                isCollapse={state.collapse}
                Icon={BarChartIcon}
                navigateLink={["/pms-parameters", navigateLink("self-evaluation"), navigateLink("team-evaluation")]}
                expanded={expandedAccordion === "pmsParameter"}
                onToggle={() => handleAccordionToggle("pmsParameter")}
              />
            )}

            {/* OKR */}
            {menuItemsOKR.Permissions.some((resource) =>
              resourceAllocate(resource)
            ) && (
              <AccordionMenu
                menuItems={menuItemsOKR}
                isCollapse={state.collapse}
                Icon={PollIcon}
                navigateLink={["/okr-objectives", "/okr-ratings", "/okr-self-evaluation", "/okr-evaluations"]}
                expanded={expandedAccordion === "okrObjectives"}
                onToggle={() => handleAccordionToggle("okrObjectives")}
              />
            )}

            {/* Organisation */}
            {
              menuItemOrganisation.Permissions.some((resource) =>
                resourceAllocate(resource)
              ) && (
                
                <AccordionMenu
                  menuItems={menuItemOrganisation}
                  isCollapse={state.collapse}
                  Icon={AccountTreeIcon}
                  navigateLink={["/users", "/organisation-tree", "/organisation-configuration", "/user-requests"]}
                  expanded={expandedAccordion === "organisation"}
                  onToggle={() => handleAccordionToggle("organisation")}
                />
              )
            } 
          </div>

          {/* profile buttons */}
          <div>
            <Divider className="divider-color" />
            <div>
              <div
                className={`profile-transition ${
                  state.menuOption ? "margin-top-30" : "profile-buttons-hide"
                }`}
              >
                <ListItem
                  label="Settings"
                  navigateLink="/settings"
                  Icon={SettingsIcon}
                  isCollapse={state.collapse}
                />

                <div
                  className="profileButtonWrap"
                  id="logout-icon"
                  onClick={handleLogout}
                >
                  <Tooltip
                    title={"Logout"}
                    disableHoverListener={!isCollapse}
                    disableFocusListener={!isCollapse}
                    placement="right"
                  >
                    <ListItemIcon className="navIcons">
                      <ExitToAppIcon style={{  color: "var(--secondary-color)"}}/>
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    className="collapse-text"
                    primary={"Logout"}
                    classes={{ primary: "listItem-text" }}
                  />
                </div>
              </div>

              <div
                id="profile"
                className={
                  state.menuOption
                    ? "rotate-icon-down active-profile profile"
                    : "rotate-icon-up profile"
                }
                onClick={handleProfileMenu}
              >
                <div className="profile-name-box">
                  <Avatar
                    className="profile-avatar"
                    src={user?.image}
                  >
                    {user ? user?.image ? user.image : user.name && user.name[0]?.toUpperCase() : "A"}
                  </Avatar>
                  <div className="name collapse-text">
                    {user ? displayName(capitalize(user.name)) : ""}
                  </div>
                </div>
                <ExpandLessIcon className="collapse-text" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right side content  */}
      <div id="right" className="right-side-content">
        <Box className="center top-nav" justifyContent="space-between">
          {showHeader && (
            <Typography variant="h5">
              {showNavTitle(location.pathname.split("/")[1])}
            </Typography>
          )}

          {resourceAllocate("notification") && (
            <Box className="center">
              {location.pathname.split("/")[1] === "candidates" && (
                <SearchCandidate />
              )}

              {showHeader && (
                <IconButton onClick={onOpenNotificationDrawer} className="ml-3">
                  <Badge
                    badgeContent={notificationCount.data?.data || 0}
                    color="error"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              )}
            </Box>
          )}

          <Notification
            count={notificationCount.data?.data || 0}
            isOpen={state.isNotificationOpen}
            onClose={onCloseNotificationDrawer}
            reFetchCount={notificationCount.refetch}
          />
        </Box>
        <Outlet
          context={{
            refetchUsers: getUsers.refetch,
            refetchTeams: teams.refetch,
            refetchEngagementTypes: getEngagementTypes.refetch,
            refetchJobTitles: jobTitles.refetch,
            refetchJobPositions: jobPositions.refetch,
            refetchStatuss: getStatusData.refetch,
            refetchTemplates: templates.refetch,
            refetchColleges: getColleges.refetch,
            refetchJobss: getJobs.refetch,
            refetchDrivess: getDrives.refetch,
            refetchTest: getTest.refetch,
            refetchProjects: projectsList.refetch,
            refetchOrganisationConfiguration: organisationConfiguration.refetch,
          }}
        />
      </div>

      {/* logout modal  */}
      <Dialog
        open={state.logoutWarning}
        onClose={handleLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        PaperProps={{ sx: { width: 500, borderRadius: 2 } }}
      >
        <DialogContent>
          <Box height={350} className="center">
            <Box className="center" flexDirection="column">
              <img src={Logout} alt="Logo" srcSet="" width={110} />
              <Typography
                variant="h5"
                sx={{ fontWeight: 700 }}
                className="mt-2 mb-1"
              >
                Log out
              </Typography>
              <Typography>Are you sure you want to log out?</Typography>
              <div className="button mt-4">
                <Button
                  sx={{ color: "black" }}
                  color="inherit"
                  className="mr-3"
                  onClick={handleLogout}
                >
                  Cancel
                </Button>
                <Button onClick={onLogout}>{"Yes, I'II be back"}</Button>
              </div>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Layout;
