import Joi from "joi";
import { required } from "../../shared";

export const organisationSoftwaresValidation = Joi.object({
  softwareFields: Joi.array().items(
    Joi.object({
      label: Joi.string()
        .trim()
        .required()
        .label("Software Type")
        .messages({ "string.empty": required }),
      
      description: Joi.string()
        .trim()
        .required()
        .label("Software Description")
        .messages({ "string.empty": required }),
      
      name: Joi.string()
        .trim()
        .required()
        .label("Software Name")
        .messages({ "string.empty": required }),

      url: Joi.string()
        .uri()
        .required()
        .label("Software URL")
        .messages({ "string.empty": required }),
    })
  ).min(1).required()
});
