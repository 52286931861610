import { useQuery } from "@tanstack/react-query";
import { FC, useEffect } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AssessmentInstructionService } from "../../../../services/configuration/assessment-instructions";
import useSnackbar from "../../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../../interfaces";
import "./style.scss";
import { Box, FormHelperText, Grid, TextField } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import CustomDialog from "../../../../components/mui/dialog";
import { instructionValidation } from "../../../../validations/configuration/assessmentInstructions";
import { IAssesmentInstructionField, IassessmentInstructions } from "../../../../interfaces/configuration/assessments-instructions";
import TinyEditor from "../../../../components/tiny-editor";

interface outletProps {
  reFetch: () => void;
}

const InstructionEditor: FC = () => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { getAssessmentInstruction, updateAssessmentInstructions, addAssessmentInstructions } = AssessmentInstructionService();
  const assessmentInstructions = useQuery({
    queryKey: [id],
    queryFn: () =>
      getAssessmentInstruction({
        _id: id,
      }),
    enabled: id !== "new",
  });

  const data = assessmentInstructions && assessmentInstructions.data && assessmentInstructions.data.data;

  const { handleSubmit, control, setValue,trigger, getValues, formState: { errors } } = useForm<IassessmentInstructions>({
    resolver: joiResolver(instructionValidation),
    defaultValues: {
      name: "",
      instructions: ""
    }
  });

  useEffect(() => {
    if (data) {
      setValue("name", data.name || "");
      setValue("instructions", data.instructions || "");
      trigger("instructions");
    }
  }, [data, id, setValue]);

  const onSubmit = async () => {
    try {
      const payload = {
        instructions: getValues("instructions"),
        name: getValues("name"),
        _id: id === "new" ? undefined : id,
      };

      const action = id === "new" ? addAssessmentInstructions : updateAssessmentInstructions;
      const response = await action(payload);

      if (response.success) {
        snackbar(response.message, "info");
        navigate({
          pathname: "/assessment/assessments-instruction",
          search: searchParam.toString(),
        });
        outlet.reFetch && outlet.reFetch();
      } else {
        snackbar(response.message, "warning");
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/assessment/assessments-instruction",
      search: searchParam.toString(),
    });
  };
  const fields: IAssesmentInstructionField[] = [
    {
      label: "Name*",
      name: "name",
      type: "input",
      placeholder: "Type your Instruction name here",
    },
    {
      label: "Instructions*",
      name: "instructions",
      type: "editor",
    },
  ];

  return (
    <Box>
      <CustomDialog
        size="md"
        title={id !== "new" ? "Edit Instructions" : "Add Instructions"}
        isOpen={!!id}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4} className="form-grid">
          {fields.map((field) => (
            <Grid key={field.label} item xs={12} className="form-item">
              {field.type === "input" ? (
                <Controller
                  control={control}
                  name={field.name}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label={field.label}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      inputProps={{ maxLength: 20 }}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Box className={errors[field.name] ? "tiny-error" : ""}>
                  <TinyEditor value={getValues("instructions")} onChange={(e: string) => setValue("instructions", e)} height="375px"   type = {["ASSESSMENTS"]} />
                  <FormHelperText className="form-helper-text" error={!!errors[field.name]}>
                    {errors[field.name]?.message}
                  </FormHelperText>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default InstructionEditor;