export const CMS_ERROR_MESSAGES = {
    en: {
        select_all_checkboxes: "Please select all checkboxes",
        fill_basic_details: "Please add basic details first.",
        invalid_json: "Invalid JSON format. Please check your input for table data.",
        schedule_in_past: "Alternative cannot scheduled be in the past. Please select a current or future time.",
        duplicate_slug: "Please enter a unique slug. This slug exists in any other glossary.",
        empty_comment_text: "Comment text cannot be empty.",
        empty_reply_text: "Reply text cannot be empty.",
        invalid_product_name: "Please select a valid product name.",
        invalid_quarter_name: "Please select a valid quarter name.",
        invalid_category_name: "Please select a valid category name.",
        invalid_subcategory_name: "Please select a valid subcategory name.",
        invalid_primary_keyword_name: "Please select a valid primary keyword name.",
        invalid_secondary_keyword_name: "Please select a valid secondary keyword name.",
        product_category_mapping: "Please select a valid category that corresponds to the selected product.",
        category_subCategory_mapping: "Please select a valid sub category that corresponds to the selected category.",
        keyword_mapping: "Please select a valid keyword that corresponds to the selected category and subcategory.",
        keyword_product_mapping: "Please select a valid keyword that corresponds to the selected product.",
    }
};