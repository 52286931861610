import React, { SyntheticEvent, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Autocomplete, AutocompleteChangeReason, Box, Chip, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IErrorResponse, IOutletProps } from "../../../../../interfaces";
import CustomDialog from "../../../../../components/mui/dialog";
import SearchSelect from "../../../../../components/mui/search-select";
import CustomLabel from "../../../../../components/mui/custom-label";
import { capitalize } from "../../../../../utilities/helper";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { courseSectionService } from "../../../../../services/configuration/course-catgory";
import { courseSubSectionService } from "../../../../../services/configuration/course-sub-category";
import { SkillMatrixService } from "../../../../../services";
import { ICourseConfigurationSectionField, ICourseSubSectionFields } from "../../../../../interfaces/configuration/course-sub-category";
import { courseConfigurationSubSectionValidation } from "../../../../../validations/configuration/course-sub-category";

const ManageCourseSubSectionConfiguration: React.FC = () => {
    const { courseSubSectionId, courseSectionId, courseId } = useParams<{ courseSubSectionId: string, courseSectionId: string, courseId: string }>();
    const [searchParams] = useSearchParams();  
    const { addcourseSubSection, getcourseSubSection, updatecourseSubSection } = courseSubSectionService();
    const {getSkillMatrixCategoryItems} = SkillMatrixService();
    const outlet = useOutletContext<IOutletProps>();
    const { snackbar } = useSnackbar();
    const navigate = useNavigate();
    const {getCourseSection} = courseSectionService();

    const getCourseSectionData = useQuery({
        queryKey: ["get-course-section-data", courseSectionId],
        queryFn: () => getCourseSection({ _id: courseSectionId }),
        enabled: !!courseSectionId
      });

    const skillCategoryId = getCourseSectionData.data?.data._skillCategory;

    const getSkillItems = useQuery({
        queryKey: ["course-skill-items-list", skillCategoryId],
        queryFn: () => getSkillMatrixCategoryItems({ _skillCategory: skillCategoryId }),
        enabled: !!skillCategoryId
      });

    const skillItemsData = getSkillItems.data?.data;

    const hitQuery = !(courseSubSectionId === "new" || courseSubSectionId === "view");

    const courseConfiguration = useQuery({
          queryKey: [hitQuery],
          queryFn: () => getcourseSubSection({_id: courseSubSectionId}),
          enabled: hitQuery
      });

    const handleKeyDownNonNumeric = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const currentValue = (event.target as HTMLInputElement).value;
        if (event.key === " " && currentValue.trim().length === 0) {
            event.preventDefault();
        }
    };

    const handleKeyDownNumeric = (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (
            event.key === "Backspace" ||
            event.key === "Delete" ||
            event.key === "Tab" ||
            event.key === "Escape" ||
            event.key === "Enter" ||
            event.key.startsWith("Arrow")
        ) {
            return;
        }
   
        if (!/^[0-9]$/.test(event.key)) {
            event.preventDefault();
        }
    };

    const onChangeMultipleSelect = (
        event: SyntheticEvent<Element, Event>,
        value: {name: string, _id: string}[],
        reason: AutocompleteChangeReason
      ) => {
        let updatedSelectedStatus: string[] = [...getValues("_skillItem")];
        let newSelectedStatus: string[];
        let removedStatus: string[];
   
        switch (reason) {
          case "selectOption":
            newSelectedStatus = value.map((item) => item._id);
            newSelectedStatus = newSelectedStatus.filter(
              (status) => !updatedSelectedStatus.includes(status)
            );
            updatedSelectedStatus = [
              ...updatedSelectedStatus,
              ...newSelectedStatus,
            ];
            break;
          case "removeOption":
            removedStatus = value.map((item) => item._id);
            updatedSelectedStatus = updatedSelectedStatus.filter(
              (status) => !removedStatus.includes(status)
            );
            break;
        }
        setValue("_skillItem", updatedSelectedStatus);
        trigger("_skillItem");
      };

      const handleMultipleDeleteChip = (deleteElement: string) => {
        const updatedStatus = getValues("_skillItem").filter((status) => status !== deleteElement);
        setValue("_skillItem", updatedStatus);
      };

    const { handleSubmit, control, setValue, formState: { errors }, getValues, trigger } = useForm<ICourseConfigurationSectionField>({
        resolver: joiResolver(courseConfigurationSubSectionValidation),
        defaultValues: {
            name: "",
            description: "",
            _skillItem: [],
            estimatedDuration: 0
        }
    });
    
    useEffect(() => {
            if (courseConfiguration.data?.data && courseSubSectionId != "new") {
                setValue("name", courseConfiguration.data.data.name || "");
                setValue("description", courseConfiguration.data.data.description || "");
                setValue("estimatedDuration", courseConfiguration.data.data.estimatedDuration || "");
                setValue("_skillItem", courseConfiguration.data.data._skillItem || [].map(data => data) as string[]);
                trigger("_skillItem");
            }
    }, [courseSubSectionId, courseConfiguration.data, setValue]);

    const onSubmit = async (data: ICourseConfigurationSectionField) => {
        try {
            if (courseSubSectionId === "new") {
                const payload = {...data, _courseSection: courseSectionId};
                const add = await addcourseSubSection(payload);
                snackbar(add.message, "info");
            } else {
                const update = await updatecourseSubSection({_id: courseSubSectionId, ...data});
                snackbar(update.message, "info");
            }
            navigate({
                pathname: "/configurations/course/course-section/"+courseId+"/course-sub-section/"+courseSectionId,
                search: searchParams.toString()
            });
            outlet?.reFetch && outlet.reFetch();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const onClose = () => {
        navigate(-1);
    };

    const fields: ICourseSubSectionFields[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type your course sub section name here",
            required: true
        },

        {
            label: "Skill Item",
            name: "_skillItem",
            type: "multipleSearchSelect",
            placeholder: "Search...",
            displayFieldKey: "name",
            storeFieldKey: "_id",
            capitalize: true,
            required: true
        },

        {
            label: "Estimated Duration (in hours)",
            name: "estimatedDuration",
            type: "input",
            placeholder: "Type your estimated duration here",
            required: true
        },

        {
            label: "Description",
            name: "description",
            type: "input",
            placeholder: "Type your description here",
            required: true
        } 
    ];

    return (
        <Box>
            <CustomDialog
                size="sm"
                title={courseSubSectionId !== "new" ? "Edit Course Sub Section" : "Add Course Sub Section"}
                isOpen={!!courseSubSectionId}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {fields.map(field => {
                        if (field.type === "input") {
                            return (
                                <Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={<CustomLabel label={field.label} required={field?.required} />}
                                                className="disable-text"
                                                variant="outlined"
                                                size="small"
                                                placeholder={field.placeholder}
                                                error={!!errors[field.name]}
                                                helperText={errors[field.name]?.message}
                                                inputProps={{ maxLength: field.name === "description" ? 100: field.name === "estimatedDuration" ? 4: 30 }}
                                                onKeyDown={field.name === "estimatedDuration"? handleKeyDownNumeric :handleKeyDownNonNumeric}
                                                onChange={onChange}
                                                value={value}
                                                multiline = {field.name === "description"}
                                                minRows={field.name === "description" ? 5: 1}
                                            />
                                        )}
                                    />
                                </Grid>);
                        }
                        else if(field.type === "searchSelect"){
                                return(
                                <Grid key={field.label} item xs={12}>
                                    <SearchSelect
                                    name={field.name}
                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                    options={field.options}
                                    displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                                    storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                                    displayUserName={field.displayUserName}
                                    capitalize={field.capitalize}
                                    keyUpperCase={field.keyUpperCase}
                                    trigger={trigger}
                                    setValue={setValue}
                                    getValues={getValues}
                                  />
                                  </Grid>
                                );
                            }
                        else if (field.type === "multipleSearchSelect"){
                            return(
                                <Grid key={field.label} item xs={12} md={12}>
                                <Controller
                                  control={control}
                                  name={field.name}
                                  render={(prop) => (
                                    <>
                                      <Autocomplete
                                        className="disable-text"
                                        options={skillItemsData || []
                                          .map((element) => element)}
                                        getOptionLabel={(option) =>
                                          capitalize(option.name)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            size="small"
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                          />
                                        )}
                                        {...prop.field}
                                        value={[]}
                                        onChange={onChangeMultipleSelect}
                                        multiple
                                      />
                                      {getValues("_skillItem").map((element) => (
                                        <Chip
                                          key={element}
                                          label={
                                            (skillItemsData || []).find(
                                              (item) => item._id === element
                                            )?.name
                                          }
                                          color="primary"
                                          variant="outlined"
                                          onDelete={() => handleMultipleDeleteChip(element)}
                                          sx={{ margin: "5px" }}
                                        />
                                      ))}
                                    </>
                                  )}
                                />
                              </Grid>
                            );
                        }
                    })}
                </Grid>
            </CustomDialog>
        </Box>
    );
};


export default ManageCourseSubSectionConfiguration;
