import "./style.scss";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import useSnackbar from "../../../../../hooks/useSnackbar";
import {
  IEvaluation,
  IEvaluationsFields,
} from "../../../../../interfaces/okr";
import { IErrorResponse } from "../../../../../interfaces";
import CustomLabel from "../../../../../components/mui/custom-label";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../../components/shared/mui-tabs";
import { EvaluationsService } from "../../../../../services/okr/evaluations";
import { evaluationValidation } from "../../../../../validations/okr/evaluations";
import { capitalize, formatDate } from "../../../../../utilities/helper";
import { ProgressBar } from "../../../../../utilities/helper1";
import useResource from "../../../../../hooks/useResource";
interface outletProps {
  reFetch: () => void;
}

const ManagePendingEvaluations = () => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const [searchParam] = useSearchParams();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { getPendingEvaluation, approveOrRejectRequest } =
    EvaluationsService();
  const req = useQuery({
    queryKey: [id],
    queryFn: () => getPendingEvaluation({ _id: id }),
    enabled: !!id,
  });
  const reqData = req.data?.data;
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IEvaluation>({
    resolver: joiResolver(evaluationValidation),
    defaultValues: {
      _id: "",
      _keyResult: "",
      _objective: "",
      note: "",
      targetFigure: 0,
      achievedValue: 0,
      feedback: "",
      endDate: "",
      qualityOfCompletion: "excellent",
      isCompleted: false,
      _assignee: "",
      createdBy: "",
    },
  });

  useEffect(() => {
    if (reqData) {
      setValue("_keyResult", reqData._keyResult.name);
      setValue("_objective", reqData._objective.name);
      setValue("_assignee", reqData._assignee?.name || "");  
      setValue("createdBy", reqData.createdBy?.name || "");
      setValue("note", reqData.note);
      setValue("targetFigure", reqData.targetFigure);
      setValue("achievedValue", reqData.achievedValue);
      setValue("endDate", formatDate(reqData?._keyResult?.endDate));
    }
  }, [reqData]);

  const onClose = () => {
    navigate({
      pathname: "/okr-evaluations/pending",
      search: searchParam.toString(),
    });
  };

  const onSubmit = async (data: IEvaluation) => {
    try {
      const payload = {
        _id: id,
        status: data?.status,
        feedback: data?.feedback || "",
        qualityOfCompletion: data?.qualityOfCompletion || "",
        isCompleted: data?.isCompleted || false,
      };
      const res = await approveOrRejectRequest(payload);
      snackbar(res.message, "info");
      outlet?.reFetch && outlet.reFetch();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const fields: IEvaluationsFields[] = [
    {
      label: "Key Result",
      name: "_keyResult",
      type: "input",
      disabled: true,
      required: true,
    },
    {
      label: "Responsibility",
      name: "_assignee",
      type: "input",
      disabled: true,
      required: true,
    },
    {
      label: "Created By",
      name: "createdBy",
      type: "input",
      disabled: true,
      required: true,
    },
    {
      label: "Timeline",
      name: "endDate",
      type: "input",
      disabled: true,
      required: true,
    },
    {
      label: "Target Value",
      name: "targetFigure",
      type: "input",
      disabled: true,
      required: true,
    },
    {
      label: "Achieved Value",
      name: "achievedValue",
      type: "input",
      placeholder: "Type achieved figure here",
      required: true,
      disabled: true,
    },
    {
      label: "Note",
      name: "note",
      type: "input",
      placeholder: "Type note here",
      required: true,
      disabled: true,
    },
    {
      label: "Quality of Completion",
      name: "qualityOfCompletion",
      type: "radio-buttons",
      required: true,
    },
    {
      label: "Feedback",
      name: "feedback",
      type: "input",
      placeholder: "Type feedback here",
      required: true,
    },
    {
      label: "Mark As Completed",
      name: "isCompleted",
      type: "checkbox",
    },
  ];

  const qualityOfCompletionOptions = [
    {
      label: "Excellent",
      value: "excellent",
    },
    {
      label: "Very Good",
      value: "very-good"
    },
    {
      label: "Good",
      value: "good",
    },
    {
      label: "Not Good",
      value: "not-good",
    },
    {
      label: "Bad",
      value: "bad",
    },
  ];

  return (
    <BootstrapDialog maxWidth="md" onClose={onClose} open={!!id} scroll="paper" className="dialog-div">
      <BootstrapDialogTitle onClose={onClose}>
      <Box display="flex" flexDirection={"column"}>
        <span className="title">
          {capitalize(reqData?._keyResult?.name || "Evaluation")} 
        </span>
        <span className="submission-date">
          <span className="requested-on">Sumbitted On: <span className="submitted-by">{formatDate(reqData?.submittedOn)}</span></span>
        </span>
        <span className="progress">
          <ProgressBar progress={Number(reqData?._keyResult?.approvedCompletion.toFixed(3))} />;
        </span>
      </Box>
    </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {fields.map((field) => {
            if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={field.name === "note" || field.name === "feedback" ? 12 : 6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field.required}
                          />
                        }
                        disabled={field.disabled}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        multiline={field.name === "note" || field.name === "feedback"}
                        minRows={
                          field.name === "note" || field.name === "feedback"
                            ? 3
                            : 1
                        }
                        maxRows={
                          field.name === "note" || field.name === "feedback"
                            ? 3
                            : 1
                        }
                        {...prop.field}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (field.name === "achievedValue") {
                            if (["e", "E", "-"].includes(e.key)) {
                              e.preventDefault();
                            }
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "radio-buttons") {
              return (
                <Grid key={field.label} item xs={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={() => (
                      <>
                        <FormLabel id="demo-radio-buttons-group-label">
                          <CustomLabel
                            label={field.label}
                            required={field.required}
                          />
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue="excellent"
                          onChange={(e) => {
                            setValue("qualityOfCompletion", e.target.value);
                          }}
                        >
                          {
                            qualityOfCompletionOptions.map((option) => (
                              <FormControlLabel
                                key={option.label}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              />
                            ))
                          }
                        </RadioGroup>
                      </>
                    )}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid key={field.label} item xs={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={() => (
                      <FormControlLabel
                        control={<Checkbox onChange={(e) => setValue(field.name, e.target.checked)}/>}
                        label={<CustomLabel
                        label={field.label}
                        required={field.required}
                      />}
                      />
                    )}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </DialogContent>
      <Box display={"flex"} justifyContent="flex-end" m={2} gap={1}>
        <Button
          variant="contained"
          color="error"
          type="submit"
          onClick={() => {
            onSubmit({...getValues(), status: "rejected"});
          }}
          disabled={!resourceAllocate("okr-team-evaluation.write")}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={!resourceAllocate("okr-team-evaluation.write")}
          onClick={() => {
            onSubmit({...getValues(), status: "approved"});
          }}
        >
          Approve
        </Button>
      </Box>
    </BootstrapDialog>
  );
};

export default ManagePendingEvaluations;
