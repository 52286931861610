import { FC, useEffect, useState } from "react";
import { Box, DialogContent } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Timeline from "./timeline";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../components/shared/mui-tabs";
import { AlternativeComparisonService } from "../../../services/alternative-comparison";

interface props {
  setShowTimeline: (value: boolean) => void;
}
const AlternativeTimeline: FC<props> = ({ setShowTimeline }) => {
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const { getAlternativeActivities } = AlternativeComparisonService();
  const activityData = useQuery({
    queryKey: ["alternative-timeline-activity"],
    queryFn: () => getAlternativeActivities({ _alternativeComparison: id }),
    enabled: id !== "new"
  });

  useEffect(() => {
    setValue(0);
  }, []);

  return (
    <BootstrapDialog
      maxWidth="lg"
      open={true}
    >
      <BootstrapDialogTitle onClose={() => setShowTimeline(false)}>
        <Box display="flex" alignItems="center">
          Timeline
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: "58vh" }}>
        <Box width="100%" height="100%">
          <TabPanel value={value} index={0}>
            <Timeline data={activityData.data && activityData.data.data} />
          </TabPanel>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default AlternativeTimeline;
