
import { Box, DialogContent, Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useResource from "../../../../../hooks/useResource";
import { a11yProps, BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../../components/shared/mui-tabs";
import EvaluationRule from "./evauation";
import RejectionRule from "./rejection";

interface IState {
  redirectionLinks: string[];
}

const AssessmentEvaluationRulesManage: FC = () => {
  const { assessmentId, id } = useParams();
  const { resourceAllocate } = useResource();
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
      if (resourceAllocate("assessment-evaluation-rule.read")) {
        redirectionLinks.push("evaluation");
        redirectionLinks.push("selection");
      }
      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }

    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "evaluation";
    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);

  const handleClose = () => {
    navigate(`/assessment/assessments-evaluation/rules/${assessmentId}`);
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
  };

  return (
    <BootstrapDialog
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <BootstrapDialogTitle onClose={handleClose}>
        <Box display="flex" alignItems="center">
          {id === "new" ? "Add Rule" : "Edit Rule"}
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: "65vh", display: "flex", flexDirection: "column" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {resourceAllocate("assessment-evaluation-rule.read") && <Tab className="tabs-space start-tab-space" disabled={id !== "new"} label="Evaluation Rule" {...a11yProps(0)} />}
            {resourceAllocate("assessment-evaluation-rule.read") && <Tab className="tabs-space" disabled={id !== "new"} label="Selection Rule" {...a11yProps(1)} />}
          </Tabs>
        </Box>
        <Box sx={{ flex: 1, overflow: "auto", marginTop: 2 }}>
          {resourceAllocate("assessment-evaluation-rule.read") && <TabPanel
            style={{
              marginTop: 10,
            }}
            value={value}
            index={state.redirectionLinks.indexOf("evaluation")}
          >
            <EvaluationRule />
          </TabPanel>}
          {resourceAllocate("assessment-evaluation-rule.read") && <TabPanel
            style={{
              marginTop: 10,
            }}
            value={value}
            index={state.redirectionLinks.indexOf("selection")}
          >
            <RejectionRule />
          </TabPanel>}
        </Box>
      </DialogContent>
    </BootstrapDialog>

  );
};
export default AssessmentEvaluationRulesManage;