import { IPMSSubParameterResponse, IPMSSubParametersResponse } from "../../../../interfaces/pms/pms-parameter/sub-parameter";
import { pmsSubParameter } from "../../../endpoints";
import HttpService from "../../../http";


const pmsSubParametersService = () => {
    const { httpRequest } = HttpService();

    const getPmsSubParameters = async (search: object): Promise<IPMSSubParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IPMSSubParametersResponse>(
            "GET",
            `${pmsSubParameter}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getPmsSubParameter = async (search: object): Promise<IPMSSubParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IPMSSubParameterResponse>(
            "GET",
            `${pmsSubParameter}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addPmsSubParameter = async (payload: object): Promise<IPMSSubParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IPMSSubParameterResponse>(
            "POST",
            `${pmsSubParameter}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updatePmsSubParameter = async (payload: object): Promise<IPMSSubParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IPMSSubParameterResponse>(
            "PUT",
            `${pmsSubParameter}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getPmsSubParameters, updatePmsSubParameter, getPmsSubParameter, addPmsSubParameter };
};

export { pmsSubParametersService };