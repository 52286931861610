import { ICourseSubSectionGetResponse, ICourseSubSectionResponse, ICourseSubSectionResponses } from "../../../interfaces/configuration/course-sub-category";
import { courseSubSection } from "../../endpoints";
import HttpService from "../../http";

const courseSubSectionService = () => {
  const { httpRequest } = HttpService();
  const addcourseSubSection = async (payload: object): Promise<ICourseSubSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSubSectionResponse>(
      "POST",
      `${courseSubSection}`,
      payload
    )
      .then(resolve)
      .catch(reject);
  });

  const getcourseSubSection = async (query: object): Promise<ICourseSubSectionGetResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSubSectionGetResponse>(
      "GET",
      `${courseSubSection}`,
      {},
      query
    )
      .then(resolve)
      .catch(reject);
  });

  const getPartialcourseSubSection = async (): Promise<ICourseSubSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSubSectionResponse>(
      "GET",
      `${courseSubSection}/list/partial`,
    )
      .then(resolve)
      .catch(reject);
  });

  const getcourseSubSectionList = async (search: object): Promise<ICourseSubSectionResponses> => new Promise((resolve, reject) => {
    httpRequest<ICourseSubSectionResponses>(
      "GET",
      `${courseSubSection}/list`,
      {},
      search
    )
      .then(resolve)
      .catch(reject);
  });


  const updatecourseSubSection = async (payload: object): Promise<ICourseSubSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSubSectionResponse>(
      "PUT",
      `${courseSubSection}`,
      payload,
    )
      .then(resolve)
      .catch(reject);
  });

  const deletecourseSubSection = async (payload: object): Promise<ICourseSubSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSubSectionResponse>(
      "DELETE",
      `${courseSubSection}`,
      {},
      payload
    )
      .then(resolve)
      .catch(reject);
  });
  return {
    addcourseSubSection,
    getcourseSubSection,
    getPartialcourseSubSection,
    getcourseSubSectionList,
    updatecourseSubSection,
    deletecourseSubSection
  };
};

export { courseSubSectionService };
