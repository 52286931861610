import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IErrorResponse } from "../../../interfaces";
import { joiResolver } from "@hookform/resolvers/joi";
import { forgetPasswordValidation } from "../../../validations";
import { IAuth, IAuthResponse } from "../../../interfaces/auth";
import { Button, TextField, Typography, InputAdornment, Box, DialogActions } from "@mui/material";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";
import EmailIcon from "@mui/icons-material/Email";
import Logo from "../../../assets/images/logo.png";
import useSnackbar from "../../../hooks/useSnackbar";
import { useQuery } from "@tanstack/react-query";
import { organisationConfigurationDataService } from "../../../services/organisation-themes";
import { generateColorPalette } from "../../../utilities/helper";

const ForgetPassword = () => {
  const authService = AuthService();
  const navigate = useNavigate();
  const { user } = useUser();
  const { snackbar } = useSnackbar();
  const { register, handleSubmit, formState: { errors } } = useForm<IAuth>({
    resolver: joiResolver(forgetPasswordValidation),
    defaultValues: {
      email: "",
    }
  });

  const { getOrganisationThemeConfiguration } = organisationConfigurationDataService();

  const organisationConfiguration = useQuery({
    queryKey: ["organisation-theme-configuration"],
    queryFn: () => getOrganisationThemeConfiguration(),
  });

  const orgThemeData = organisationConfiguration && organisationConfiguration.data && organisationConfiguration.data.data;
  const logo = orgThemeData && orgThemeData.logo && String(process.env.REACT_APP_S3_BASE_URL) + orgThemeData.logo || Logo;
  const fontFamily = orgThemeData && orgThemeData.fontFamily || "sans-serif";
  const dynamicTheme = {
    "--primary-color": orgThemeData && orgThemeData.primaryColor || "#118CFC",
    "--primary-color-dark": generateColorPalette(orgThemeData && orgThemeData.primaryColor || "#1C2C25")["600"],
    "--primary-color-light": generateColorPalette(orgThemeData && orgThemeData.primaryColor || "#1C2C25")["50"],
    "--font-family": orgThemeData && orgThemeData.fontFamily || "sans-serif",
  } as React.CSSProperties;

  useEffect(() => {
    if (user?.name?.length) {
      navigate("/");
    }
  }, [user]);

  const onSubmit = async (data: IAuth) => {
    const payload = {
      ...data
    };
    try {
      const auth = await authService.forgetPassword(payload) as IAuthResponse;
      snackbar(auth.message, "info");
      navigate("/login");

    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("Error in forgot password:  ", error);
    }
  };

  return (
    <div style={dynamicTheme}>
      <Box className="login-container center" flexDirection="column">
        <img alt="logo" src={logo} width="63" height="66" />
        <Typography className="margin-top-40 fw-bold welcome" variant="h5">
          Forget Password
        </Typography>
        <p className="sub-text grey-500">
          Helping you to manage and hire candidates
        </p>
        <Box className="credential-box" marginTop="40px" marginX="10px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              placeholder="Email*"
              className="mb-3"
              {...register("email")}
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
                sx: {
                  fontFamily: fontFamily,
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--primary-color)",
                  },
                },
              }}
              InputLabelProps={{
                sx: { fontFamily },
              }}
              sx={{
                fontFamily: fontFamily,
              }}
            />
            <DialogActions sx={{ padding: 0 }}>
              <Button
                className="margin-top-40 height-50 secondary-button"
                sx={{ marginRight: "40px" }}
                variant="outlined"
                fullWidth
                onClick={() => navigate("/login")}
              >
                Back To Login
              </Button>
              <Button
                type="submit"
                className="margin-top-40 height-50 login-button"
                fullWidth
              >
                Send
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default ForgetPassword;