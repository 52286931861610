import { Navigate, createBrowserRouter } from "react-router-dom";
import Assessment from "../screens/configuration-layout/assessment";
import QuestionLanguages from "../screens/configuration-layout/question-languages";
import Tags from "../screens/configuration-layout/tags";
import ManageTag from "../screens/configuration-layout/tags/manage";
import TechnicalQuestion from "../screens/configuration-layout/technical-question";
import ManageTechnicalQuestion from "../screens/configuration-layout/technical-question/manage";
import ManageQuestionTemplates from "../screens/configuration-layout/technical-question/manage/templates/manage";
import ManageQuestionTestCases from "../screens/configuration-layout/technical-question/manage/test-cases/manage";
import PageNotFound from "../components/page-not-found";
import Layout from "../layout";
import Dashboard from "../screens/dashboard";
import { lazy, Suspense } from "react";
// Authentication
import Login from "../screens/auth/login";
import ForgotPassword from "../screens/auth/forgot-password";
import ResetPassword from "../screens/auth/reset-password";

// Candidate
import CandidateLayout from "../screens/candidate-layout";
import CandidateList from "../screens/candidate-layout/list";
import AddCandidate from "../screens/candidate-layout/components/add-candidate";
import ManageCandidateLayout from "../components/manage-candidate";

//CPD
import CpdLayout from "../screens/cpd-layout";
import Dashboards from "../screens/cpd-layout/dashboard";
import ManageDashboard from "../screens/cpd-layout/dashboard/manage";
import Drives from "../screens/cpd-layout/drives";
import ImportCpd from "../screens/cpd-layout/drives/import";
import ManageDrive from "../screens/cpd-layout/drives/manage";
import CpdDriveLayout from "../screens/cpd-layout/drives/drive-detail";
import College from "../screens/cpd-layout/college";
import AddCollegeLayout from "../screens/cpd-layout/college/add-college";
import CollegeLayout from "../screens/cpd-layout/college/college-detail";
import ImportCollege from "../screens/cpd-layout/college/import";
import CollegeActionLayout from "../screens/cpd-layout/college/action";
import CpdDriveActionLayout from "../screens/cpd-layout/drives/actions-layout";

//Interview
import InterviewsList from "../screens/assessments-layout/list-interview";
import RescheduleInterview from "../screens/assessments-layout/reschedule-interview";
import ManageInterview from "../screens/assessments-layout/manage-interview";

//Review

//Career
import CareersLayout from "../screens/career-layout";
import Job from "../screens/career-layout/jobs";
import ManageJob from "../screens/career-layout/jobs/manage";
import EmployeeReview from "../screens/career-layout/employee-review";
import ManageEmployeeReview from "../screens/career-layout/employee-review/manage";
import CollegeImage from "../screens/career-layout/college-image";
import ManageCollegeImage from "../screens/career-layout/college-image/manage";
import OfficeEmployeeImage from "../screens/career-layout/office-employee-image";
import ManageEmployeeImage from "../screens/career-layout/office-employee-image/manage";
import Faq from "../screens/career-layout/faq";
import ManageFaq from "../screens/career-layout/faq/manage";

//Broadcast
import Broadcast from "../screens/broadcast-group/Broadcast";
import ManageBroadcast from "../screens/broadcast-group/Broadcast/manage";

//Offered
import Offers from "../screens/offered";
import OfferedActionLayout from "../screens/offered/actions";
import NewJoinee from "../screens/offered/manage";

//KPI
import KPI from "../screens/kpi";
import ManageKpi from "../screens/kpi/manage";

//Report
import ReportLayout from "../screens/report-layout";
import Overview from "../screens/report-layout/overview";
import Jobs from "../screens/report-layout/jobs";
import Candidates from "../screens/report-layout/candidates";
import TeamPerformance from "../screens/report-layout/team-performance";
import CpdAnalysis from "../screens/report-layout/cpd";

//Trash
import Trash from "../screens/trash-layout/trash";
import TrashLayout from "../screens/trash-layout";

// Configuration
import ConfigurationLayout from "../screens/configuration-layout";
import Team from "../screens/configuration-layout/team";
import ManageTeam from "../screens/configuration-layout/team/manage";
import EngagementType from "../screens/configuration-layout/engagement-type";
import ManageEngagementType from "../screens/configuration-layout/engagement-type/manage";
import JobPosition from "../screens/configuration-layout/job-position";
import ManageJobPosition from "../screens/configuration-layout/job-position/manage";
import JobTitle from "../screens/configuration-layout/job-title";
import ManageJobTitle from "../screens/configuration-layout/job-title/manage";
import Template from "../screens/configuration-layout/template";
import ManageTemplate from "../screens/configuration-layout/template/manage";
import Status from "../screens/configuration-layout/status";
import ManageStatus from "../screens/configuration-layout/status/manage";
import Integration from "../screens/configuration-layout/integration/integration";
import Parameter from "../screens/configuration-layout/parameter";
import ManageParameter from "../screens/configuration-layout/parameter/manage";
import ReviewParameter from "../screens/configuration-layout/review-parameter";
import ManageReviewParameter from "../screens/configuration-layout/review-parameter/manage";
import CandidateActionLayout from "../screens/candidate-layout/components/actions-layout";
import OnBoardUser from "../screens/configuration-layout/onboard-user";
import ManageOnboardUser from "../screens/configuration-layout/onboard-user/manage";
import CoderByteLinks from "../screens/configuration-layout/coder-byte-links/Index";
import ManageCoderByteLink from "../screens/configuration-layout/coder-byte-links/manage";
import AutomationLayout from "../screens/configuration-layout/automation";
import Automation from "../screens/configuration-layout/automation/list";
import ResourceRole from "../screens/configuration-layout/resource-role";
import DailyReportRoles from "../screens/configuration-layout/daily-report";
import ManageDailyReport from "../screens/configuration-layout/daily-report/manage";
//Skill Matrix

const SkillMatrix = lazy(() => import("../screens/skill-matrix"));

import AddSkillMatrixLayout from "../screens/skill-matrix/add-skill-matrix";
import SkillMatrixActionLayout from "../screens/skill-matrix/add-employee-skill-item";
import EditSkillMatrix from "../screens/skill-matrix/edit-skill-matrix";
import ImportSkillMatrix from "../screens/skill-matrix/edit-skill-matrix/import-skill-item";
import RequestedSkill from "../screens/requested-skill";
import UpdateRequest from "../screens/requested-skill/manage";
import UpdateSkillMatrix from "../screens/skill-matrix/edit-skill-matrix/manage-skill-item-score";

// Setting
import SettingsLayout from "../screens/settings-layout";
import AuthGuard from "../guard";
import Role from "../screens/configuration-layout/role";
import ManageRole from "../screens/configuration-layout/role/manage";
import ImportCandidate from "../screens/candidate-layout/components/import-candidate";
import GoogleLogin from "../screens/auth/google-login";
import GoogleCalender from "../screens/configuration-layout/google-calendar";
import Redirect from "../components/redirect";
import ContentLayout from "../screens/content-layout";
import ContentListSubCategory from "../screens/content-layout/sub-category";
import AddCategory from "../screens/content-layout/category/manage";
import AddSubCategory from "../screens/content-layout/sub-category/manage";
import AddKeyword from "../screens/content-layout/keyword/manage";
import ManageAutomation from "../screens/configuration-layout/automation/manage";
import AssessmentsLayout from "../screens/assessments-layout";
import Reviews from "../screens/assessments-layout/list-review/list-review";
import ManageReview from "../screens/assessments-layout/manage-review";
import BlogRouting from "../screens/content-marketing-layout/blog.routing";
import BroadcastAnalytics from "../screens/broadcast-group/Broadcast/analytics";
import Highlights from "../screens/career-layout/high-lights";
import ManageHighlight from "../screens/career-layout/high-lights/manage";
import CategoryList from "../screens/content-layout/category";
import KeywordList from "../screens/content-layout/keyword";
import ProjectsList from "../screens/content-layout/project-products";
import AddProjects from "../screens/content-layout/project-products/manage";
import PartsOfFunnel from "../screens/content-layout/parts-of-funnel";
import AddPartsOfFunnel from "../screens/content-layout/parts-of-funnel/manage";
import Goals from "../screens/content-layout/goals";
import AddGoal from "../screens/content-layout/goals/manage";
import AddTypesOfContent from "../screens/content-layout/types-of-content/manage";
import TypesOfContent from "../screens/content-layout/types-of-content";
import AddScore from "../screens/content-layout/score-data/manage";
import ImportPartsOfFunnel from "../screens/content-layout/parts-of-funnel/import-parts-of-funnel";
import ScoreData from "../screens/content-layout/score-data";
import ImportCategory from "../screens/content-layout/category/import";
import PlannerLayout from "../screens/planner-layout";
import ManageProjects from "../screens/planner-layout/quarter";
import ManageForm from "../screens/planner-layout/quarter/manage";
import Planner from "../screens/planner-layout/planner";
import ManageQuarterTargetForm from "../screens/planner-layout/planner/manage";
import CmsAnalytics from "../screens/report-layout/cms";
import CmsHomeModule from "../screens/cms-home";
import ProductMappingList from "../screens/content-layout/product-mapping";
import AddProductMapping from "../screens/content-layout/product-mapping/manage";
import AuthorList from "../screens/content-layout/author";
import AddAuthor from "../screens/content-layout/author/manage";
import ImportQuarterTarget from "../screens/planner-layout/planner/import";
import WriterReport from "../screens/content-layout/writer-report-roles";

//Broadcast-Group
import BroadcastGroup from "../screens/broadcast-group";
import ManageBroadcastGroup from "../screens/broadcast-group/manage";
import ImportKeyword from "../screens/content-layout/keyword/import";
import ImportSubCategory from "../screens/content-layout/sub-category/import";
import TrashBlog from "../screens/trash-layout/trash-blog";

import ManageTestInfo from "../screens/configuration-layout/tests/Tests/manageTestCategories";

import TestRouting from "../screens/configuration-layout/tests/TestRouting";
import TestNestedRouting from "../screens/configuration-layout/tests/TestInsideRouts";

//Directory Manangement
import VendorLayout from "../screens/vendors";
import Vendor from "../screens/vendors/vendor";
import VendorCategories from "../screens/vendors/categories";
import VendorServices from "../screens/vendors/services";
import ManageVendorCategory from "../screens/vendors/categories/manage";
import ManageVendorService from "../screens/vendors/services/manage";
import ManageVendor from "../screens/vendors/manage-vendor";
import AddVendor from "../screens/vendors/add-vendor";


import { ResourceRequestLayout } from "../screens/resource-request";
import RequestList from "../screens/resource-request/request-list";
import ManageResourceRequest from "../screens/resource-request/manage-resource-requirement";
import ViewAssessmentResponses from "../components/manage-candidate/view-assessment-response";
import ManageResourceLayout from "../screens/resource-request/individual-request";
import RequestMail from "../screens/resource-request/individual-request/email-action";
import ManageResourceRole from "../screens/configuration-layout/resource-role/manage";
import BlogsPagesInsights from "../screens/blogs-insights/pages";
import BlogsInsightsLayout from "../screens/blogs-insights/indeex";
import BlogsKeywordsInsights from "../screens/blogs-insights/keywords";
import JobTimeline from "../screens/career-layout/jobs/timeline";
import AssessmentInstruction from "../screens/configuration-layout/assessments-instructions";
import InstructionEditor from "../screens/configuration-layout/assessments-instructions/manage";
import BusinessUnit from "../screens/configuration-layout/business-unit";
import ManageBusinessUnit from "../screens/configuration-layout/business-unit/manage";
import OrganisationTree from "../screens/organisation/organisation-tree";
import User from "../screens/organisation/organisation-users";
import ImportUser from "../screens/organisation/organisation-users/import";
import ManageUserLayout from "../screens/organisation/organisation-users/manage-user";
import ManageUser from "../screens/organisation/organisation-users/manage";
import ManageWriterReportRoles from "../screens/content-layout/writer-report-roles/manage";
import SkillDevelopmentLayout from "../screens/skill-development-training";
import SkillDevelopmentList from "../screens/skill-development-training/list";
import ManageSkillDevelopment from "../screens/skill-development-training/manage";
import ManageConfigurationBasicDetails from "../screens/organisation/organisation-configuration/basic-details";
import { OrganisationConfigurationLayout } from "../screens/organisation/organisation-configuration";
import AssessmentInfo from "../screens/configuration-layout/assessment/info";
import AssessmentEvaluation from "../screens/configuration-layout/assessment-evaluation";
import AssessmentEvaluationRules from "../screens/configuration-layout/assessment-evaluation/rules";
import AssessmentEvaluationRulesManage from "../screens/configuration-layout/assessment-evaluation/rules/manage";
import ExportCandidate from "../screens/candidate-layout/components/export-candidate";
import ManageAssessmentLayout from "../screens/configuration-layout/assessment/manage";
import AssessmentPenality from "../screens/configuration-layout/assessment-penality";
import ManageAssessmentPenality from "../screens/configuration-layout/assessment-penality/manage";
import ManageSkillMatrixLayout from "../screens/skill-matrix/edit-skill-matrix/edit-skill-name";
import ManageSkillItemLayout from "../screens/skill-matrix/edit-skill-matrix/edit-skill-item-name";
import ManageSkillCategoryLayout from "../screens/skill-matrix/skill-item-category/skill-category-layout";
import SkillMatrixAnalytics from "../screens/skill-matrix-anlytics";
import PMSParameter from "../screens/pms/pms-parameters";
import ManagePMSParameter from "../screens/pms/pms-parameters/manage";
import PMSSubParameter from "../screens/pms/pms-parameters/sub-parameter";
import ManagePMSSubParameter from "../screens/pms/pms-parameters/sub-parameter/manage";
import SelfEvaluationLayout from "../screens/pms/self-evaluation";
import TeamEvaluationLayout from "../screens/pms/team-evaluation";
import SelfEvaluationOverview from "../screens/pms/self-evaluation/overview";
import SelfEvaluationFeedback from "../screens/pms/self-evaluation/feedback";
import TeamEvaluationOverview from "../screens/pms/team-evaluation/overview";
import TeamEvaluationFeedback from "../screens/pms/team-evaluation/feedback";
import TeamEvaluationParameterWise from "../screens/pms/team-evaluation/parameter-wise";
import AddFeedback from "../screens/pms/team-evaluation/parameter-wise/add-feedback";
import CourseConfigurationList from "../screens/configuration-layout/course";
import CourseConfigurationSectionList from "../screens/configuration-layout/course/course-section";
import CourseConfigurationSubSectionList from "../screens/configuration-layout/course/course-sub-section";
import ManageCourseConfiguration from "../screens/configuration-layout/course/manage";
import ManageCourseSectionConfiguration from "../screens/configuration-layout/course/course-section/manage";
import ManageCourseSubSectionConfiguration from "../screens/configuration-layout/course/course-sub-section/manage";
import SelfEvaluationParameterWise from "../screens/pms/self-evaluation/parameter-wise";
import SiteMapLayout from "../screens/site-map";
import SiteMapSetting from "../screens/site-map/settings";
import SiteMapUrlBundle from "../screens/site-map/url-bundle";
import ManageUrlBundle from "../screens/site-map/url-bundle/manage";
import SiteMapCustomUrl from "../screens/site-map/custom-url";
import ManageCustomUrl from "../screens/site-map/custom-url/manage";
import ManageSitemapSetting from "../screens/site-map/settings/manage";
import ContentCategory from "../screens/category-content";
import ManageContentCategory from "../screens/category-content/manage";
import GlossaryRouting from "../screens/content-marketing-layout/glossary-routing";
import TrashGlossary from "../screens/trash-layout/trash-glossary";
import MediaImage from "../screens/cms-media";
import AddMediaImage from "../screens/cms-media/manage";
import ManageSkillDevelopmentScoreAndStatus from "../screens/skill-development-training/edit-score";
import AlternativeComparisonRouting from "../screens/alternative-comparison";
import ManageSkillMatrixCategory from "../screens/skill-matrix/skill-item-category/manage-category";
import TrashAlternative from "../screens/trash-layout/trash-alternative";
import Sitemap from "../screens/site-map/sitemap";
import UserRequests from "../screens/organisation/user-requests";
import ManageUserRequest from "../screens/organisation/user-requests/manage";
import ImportCustomUrl from "../screens/site-map/custom-url/import";
import AssessmentLayout from "../screens/assessments";
import RequestSent from "../screens/auth/google-login/request-sent";
import AssessmentMedia from "../screens/configuration-layout/assessment-media";
import ManageAssessmentMedia from "../screens/configuration-layout/assessment-media/manage";
import OrganisationSoftwares from "../screens/organisation/organisation-configuration/softwares";
import OKRObjectives from "../screens/okr/objectives";
import ManageObjective from "../screens/okr/objectives/manage";
import OKRKeyresutls from "../screens/okr/objectives/key-results";
import ManageOKRKeyresutls from "../screens/okr/objectives/key-results/manage";
import OKRRatings from "../screens/okr/ratings";
import ManageOKRRatings from "../screens/okr/ratings/manage";
import SelfAssessmentLayout from "../screens/okr/self-assessment";
import PendingKRs from "../screens/okr/self-assessment/pending";
import ManagePendingKRs from "../screens/okr/self-assessment/pending/manage";
import ApprovedRejectedRequests from "../screens/okr/self-assessment/approved-rejected";
import UnderReviewRequests from "../screens/okr/self-assessment/under-review";
import KRRequestTimeline from "../screens/okr/self-assessment/approved-rejected/timeline";
import EvaluationLayout from "../screens/okr/evaluations";
import CompletedEvaluations from "../screens/okr/evaluations/completed";
import PendingEvaluations from "../screens/okr/evaluations/pending";
import ManagePendingEvaluations from "../screens/okr/evaluations/pending/manage";
import KeyResultDatails from "../screens/okr/objectives/key-results/details";

export default createBrowserRouter([
  {
    path: "login",
    element: <Login />, 
    errorElement: <PageNotFound />,
  },
  {
    path: "request-sent",
    element: <RequestSent />,
    errorElement: <PageNotFound />,
  },
  {
    path: "google-login",
    element: <GoogleLogin />,
    errorElement: <PageNotFound />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/reset-password/:id",
    element: <ResetPassword />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/redirect",
    element: <Redirect />,
    errorElement: <PageNotFound />,
  },
  {
    element: <AuthGuard />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <Layout />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <Redirect />,
            errorElement: <PageNotFound />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
            errorElement: <PageNotFound />,
          },
          {
            path: "content",
            element: <ContentLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "products",
                element: <ProjectsList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddProjects />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "category",
                element: <CategoryList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddCategory />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportCategory />,
                  },
                ],
              },
              {
                path: "sub-category",
                element: <ContentListSubCategory />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddSubCategory />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportSubCategory />,
                  },
                ],
              },
              {
                path: "keywords",
                element: <KeywordList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddKeyword />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportKeyword />,
                  },
                ],
              },
              {
                path: "parts-of-funnel",
                element: <PartsOfFunnel />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddPartsOfFunnel />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportPartsOfFunnel />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "goals",
                element: <Goals />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddGoal />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "type-of-content",
                element: <TypesOfContent />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddTypesOfContent />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "score-data",
                element: <ScoreData />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddScore />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "product-mapping",
                element: <ProductMappingList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddProductMapping />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "author",
                element: <AuthorList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <AddAuthor />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "writer-report-roles",
                element: <WriterReport />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageWriterReportRoles />,
                    errorElement: <PageNotFound />,
                  }
                ]
              },
            ],
          },
          {
            path: "planner",
            element: <PlannerLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageProjects />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":idd",
                    element: <ManageForm />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
            ],
          },

          {
            path: "planner/:moduleId/manage/:quarterId",
            element: <Planner />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageQuarterTargetForm />,
                errorElement: <PageNotFound />,
              },
              {
                path: "import",
                element: <ImportQuarterTarget />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "sitemap",
            element: <SiteMapLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "sitemap-data",
                element: <Sitemap />,
                errorElement: <PageNotFound />,
              },
              {
                path: "url-bundles",
                element: <SiteMapUrlBundle />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageUrlBundle />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "custom-url",
                element: <SiteMapCustomUrl />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageCustomUrl />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportCustomUrl />,
                    errorElement: <PageNotFound />,
                  }
                ],
              },
              {
                path: "settings",
                element: <SiteMapSetting />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageSitemapSetting />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
            ],
          },
          {
            path: "/category-content",
            element: <ContentCategory />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageContentCategory />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "candidates",
            element: <CandidateLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":typeOfLead",
                element: <CandidateList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: "new",
                    element: <AddCandidate />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportCandidate />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "export",
                    element: <ExportCandidate />,
                    errorElement: <PageNotFound />
                  },
                  {
                    path: "manage/:leadId",
                    element: <ManageCandidateLayout />,
                    errorElement: <PageNotFound />,
                    children: [
                      {
                        path: "manage-interview/:id",
                        element: <ManageInterview />,
                        errorElement: <PageNotFound />,
                      },
                    {
                      path:"assessment-response-details/:testId/:sessionId",
                      element:<ViewAssessmentResponses />,
                      errorElement:<PageNotFound/>,
                    },
                    ],
                  },
                  {
                    path: "action/:leadId",
                    element: <CandidateActionLayout />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
            ],
          },
          {
            path: "blog/*",
            element: <BlogRouting />,
            errorElement: <PageNotFound />,
          },
          {
            path: "glossary/*",
            element: <GlossaryRouting />,
            errorElement: <PageNotFound />,
          },
          {
            path: "media-images",
            element:  <MediaImage />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <AddMediaImage />,
                errorElement: <PageNotFound />,
              }
            ]
          },
          {
            path: "comparison/*",
            element: <AlternativeComparisonRouting />,
            errorElement: <PageNotFound />,
          },
          {
            path: "home",
            element: <CmsHomeModule />,
            errorElement: <PageNotFound />,
          },
          {
            path: "cpd",
            element: <CpdLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "dashboard",
                element: <Dashboards />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageDashboard />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "drives",
                element: <Drives />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageDrive />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportCpd />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "manage/:id",
                    element: <CpdDriveLayout />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "action/:id",
                    element: <CpdDriveActionLayout />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "college",
                element: <College />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: "new",
                    element: <AddCollegeLayout />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "manage/:id",
                    element: <CollegeLayout />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "import",
                    element: <ImportCollege />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "action/:id",
                    element: <CollegeActionLayout />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
            ],
          },
          {
            path: "assessments",
            element: <AssessmentsLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "interview/:type",
                element: <InterviewsList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: "reschedule/:id",
                    element: <RescheduleInterview />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "manage/:id",
                    element: <ManageInterview />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: ":typeOfLead/manage/:leadId",
                    element: <ManageCandidateLayout />,
                    errorElement: <PageNotFound />,
                    children: [
                      {
                        path: "manage-interview/:id",
                        element: <ManageInterview />,
                        errorElement: <PageNotFound />,
                      },
                      {
                        path:"assessment-response-details/:testId/:sessionId",
                        element:<ViewAssessmentResponses />,
                        errorElement:<PageNotFound/>,
                      },
                    ],
                  },
                ],
              },

              {
                path: "review/:types",
                element: <Reviews />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageReview />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: ":typeOfLead/manage/:leadId",
                    element: <ManageCandidateLayout />,
                    errorElement: <PageNotFound />,
                    children: [
                      {
                        path: "manage-interview/:id",
                        element: <ManageInterview />,
                        errorElement: <PageNotFound />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "careers",
            element: <CareersLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "jobs",
                element: <Job />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageJob />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "timeline/:id",
                    element: <JobTimeline />,
                    errorElement: <PageNotFound />,
                  }
                ],
              },
              {
                path: "employee-review",
                element: <EmployeeReview />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageEmployeeReview />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "college-images",
                element: <CollegeImage />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageCollegeImage />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "office-employee-images",
                element: <OfficeEmployeeImage />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageEmployeeImage />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "faqs",
                element: <Faq />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageFaq />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "highlight",
                element: <Highlights />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageHighlight />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
            ],
          },
          {
            path: "broadcast",
            element: <Broadcast />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageBroadcast />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "broadcast-group",
            element: <BroadcastGroup />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageBroadcastGroup />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "broadcast-group/:id/broadcast",
            element: <Broadcast />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":broadCastId",
                element: <ManageBroadcast />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "broadcast-group/:id/broadcast/events/:broadcastId",
            element: <BroadcastAnalytics />,
            errorElement: <PageNotFound />,
          },
          {
            path: "offered",
            element: <Offers />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":typeOfLead/manage/:leadId",
                element: <ManageCandidateLayout />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: "manage-interview/:id",
                    element: <ManageInterview />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "action/:leadId",
                element: <OfferedActionLayout />,
                errorElement: <PageNotFound />,
              },
              {
                path: "new-joinee",
                element: <NewJoinee />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "reports",
            element: <ReportLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "overview",
                element: <Overview />,
                errorElement: <PageNotFound />,
              },
              {
                path: "jobs",
                element: <Jobs />,
                errorElement: <PageNotFound />,
              },
              {
                path: "candidate",
                element: <Candidates />,
                errorElement: <PageNotFound />,
              },
              {
                path: "team-performance",
                element: <TeamPerformance />,
                errorElement: <PageNotFound />,
              },
              {
                path: "cpd",
                element: <CpdAnalysis />,
                errorElement: <PageNotFound />,
              },
              {
                path: "kpi",
                element: <KPI />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageKpi />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "cms",
                element: <CmsAnalytics />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "Trash",
            element: <TrashLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "candidate-trash",
                element: <Trash />,
                errorElement: <PageNotFound />,
              },
              {
                path: "blog-trash",
                element: <TrashBlog />,
                errorElement: <PageNotFound />,
              },
              {
                path: "glossary-trash",
                element: <TrashGlossary />,
                errorElement: <PageNotFound />,
              },
              {
                path: "comparison-trash",
                element: <TrashAlternative />,
                errorElement: <PageNotFound />,
              }
            ],
          },
          {
            path: "configurations",
            element: <ConfigurationLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "roles",
                element: <Role />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageRole />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "resource-request-roles",
                element: <ResourceRole />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageResourceRole />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "departments",
                element: <Team />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageTeam />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "business-unit",
                element: <BusinessUnit />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageBusinessUnit />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "engagement-type",
                element: <EngagementType />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageEngagementType />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "designation",
                element: <JobPosition />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageJobPosition />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "job-title",
                element: <JobTitle />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageJobTitle />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "template",
                element: <Template />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageTemplate />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },   
              {
                path: "status",
                element: <Status />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageStatus />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "integration",
                element: <Integration />,
                errorElement: <PageNotFound />,
              },
              {
                path: "parameters",
                element: <Parameter />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageParameter />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "review-parameters",
                element: <ReviewParameter />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageReviewParameter />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "onboard-user",
                element: <OnBoardUser />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageOnboardUser />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "coder-byte",
                element: <CoderByteLinks />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageCoderByteLink />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "automation",
                element: <AutomationLayout />,
                errorElement: <PageNotFound />,
              },
              {
                path: "automation/list",
                element: <Automation />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageAutomation />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "daily-report-roles",
                element: <DailyReportRoles />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageDailyReport />,
                    errorElement: <PageNotFound />,
                  }
                ]
              },
              
              {
                path: "course",
                element: <CourseConfigurationList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: "manage/:courseConfigurationId",
                    element: <ManageCourseConfiguration />,
                    errorElement: <PageNotFound />,
                  }
                ]
              }        
            ],
          },
          {
            path: "assessment",
            element: <AssessmentLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "question-tags",
                element: <Tags />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageTag />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "assessments-media",
                element: <AssessmentMedia />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageAssessmentMedia />,
                    errorElement: <PageNotFound />,
                  },
                ],
              },
              {
                path: "question-languages",
                element: <QuestionLanguages />,
                errorElement: <PageNotFound />,
              },
              {
                path: "technical-questions",
                element: <TechnicalQuestion />,
                errorElement: <PageNotFound />,
              },

              {
                path: "assessments-instruction",
                element: <AssessmentInstruction />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <InstructionEditor />,
                    errorElement: <PageNotFound />,
                  }
                ]
              },
              {
                path: "assessments-penality",
                element: <AssessmentPenality />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageAssessmentPenality />,
                    errorElement: <PageNotFound />,
                  }
                ]
              },
              {
                path: "assessments-evaluation",
                element: <AssessmentEvaluation />,
                errorElement: <PageNotFound />,
              },

              {
                path: "assessments",
                element: <Assessment />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageAssessmentLayout />,
                    errorElement: <PageNotFound />,
                  }
                ]
              },
              {
                path: "tests/*",
                element: <TestRouting />,
                errorElement: <PageNotFound />,
              }       
            ],
          },
          {
            path: "assessment/technical-questions/:id",
            element: <ManageTechnicalQuestion />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "manage-template/:templateId",
                element: <ManageQuestionTemplates />,
                errorElement: <PageNotFound />,
              },
              {
                path: "manage-test-case/:testCaseId",
                element: <ManageQuestionTestCases />,
                errorElement: <PageNotFound />,
              },
              
            ]
          },

          // Creating the paths for the Category , response and questions
          {
            path: "assessment/tests/test-info/manage/:testId",
            element: <ManageTestInfo />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "*",
                element: <TestNestedRouting />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "assessment/assessments/assessment-info/:assessmentId",
            element: <AssessmentInfo />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "response-details/:testId/:leadId",
                element: <ViewAssessmentResponses />,
                errorElement: <PageNotFound />,
              }
            ]
          },

          {
            path: "assessment/assessments-evaluation/rules/:assessmentId",
            element: <AssessmentEvaluationRules />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <AssessmentEvaluationRulesManage />,
                errorElement: <PageNotFound />,
              }
            ]
          },

          {
            path: "blogs-analytics",
            element: <BlogsInsightsLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "pages",
                element: <BlogsPagesInsights />,
                errorElement: <PageNotFound />,
              },
              {
                path: "keywords",
                element: <BlogsKeywordsInsights />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "directory",
            element: <VendorLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "vendors",
                element: <Vendor />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: "new",
                    element: <AddVendor />,  
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "manage/:id",
                    element: <ManageVendor />,   
                    errorElement: <PageNotFound />,
                  },
                ]
              },
              {
                path: "categories",
                element: <VendorCategories />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageVendorCategory />,
                    errorElement: <PageNotFound />,
                  }
                ]
              },
              {
                path: "services",
                element: <VendorServices />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageVendorService />,
                    errorElement: <PageNotFound />,
                  }
                ]
              },
            ],
          },

          {
            path: "users",
            element: <User />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "import",
                element: <ImportUser />,
                errorElement: <PageNotFound />,
              },
              {
                path: ":id",
                element: <ManageUser />,
                errorElement: <PageNotFound />,
              },
              {
                path: "manage/:id",
                element: <ManageUserLayout />,
                errorElement: <PageNotFound />,
              },
              {
                path: "import",
                element: <ImportUser />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "user-requests",
            element: <UserRequests />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageUserRequest />,
                errorElement: <PageNotFound />,
              }
            ]
          },

          {
            path: "pms-parameters",
            element: <PMSParameter />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManagePMSParameter />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "pms-parameters/sub-parameters/:parameterId",
            element: <PMSSubParameter />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManagePMSSubParameter />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "self-evaluation",
            element: <SelfEvaluationLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "overview",
                element: <SelfEvaluationOverview />,
                errorElement: <PageNotFound />,
              },
              {
                path: "feedbacks",
                element: <SelfEvaluationFeedback />,
                errorElement: <PageNotFound />,
              },
              {
                path: ":id",
                element: <SelfEvaluationParameterWise />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "team-evaluation",
            element: <TeamEvaluationLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "overview",
                element: <TeamEvaluationOverview />,
              },
              {
                path: "feedback",
                element: <TeamEvaluationFeedback />,
                errorElement: <PageNotFound />,
              },
              {
                path: ":id",
                element: <TeamEvaluationParameterWise />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "team-evaluation/:id/feedback",
            element: <AddFeedback />,
            errorElement: <PageNotFound />,
          },

          {
            path: "okr-objectives",
            element: <OKRObjectives />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageObjective />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "okr-objectives/key-results/:objectiveId",
            element: <OKRKeyresutls />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageOKRKeyresutls />,
                errorElement: <PageNotFound />,
              },
              {
                path: "details/:krId",
                element: <KeyResultDatails />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "okr-self-evaluation",
            element: <SelfAssessmentLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "pending",
                element: <PendingKRs />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":krId",
                    element: <ManagePendingKRs />,  
                    errorElement: <PageNotFound />,
                  },
                ]
              },
              {
                path: "approved-rejected",
                element: <ApprovedRejectedRequests />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: "view-timeline/:id",
                    element: <KRRequestTimeline />,  
                    errorElement: <PageNotFound />,
                  }
                ]
              },
              {
                path: "under-review",
                element: <UnderReviewRequests />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":krId/:reqId",
                    element: <ManagePendingKRs />,  
                    errorElement: <PageNotFound />,
                  },
                ]
              },
            ],
          },

          {
            path: "okr-evaluations",
            element: <EvaluationLayout />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "pending",
                element: <PendingEvaluations />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManagePendingEvaluations />,  
                    errorElement: <PageNotFound />,
                  },
                ]
              },
              {
                path: "completed",
                element: <CompletedEvaluations />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "okr-ratings",
            element: <OKRRatings />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <ManageOKRRatings />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "organisation-tree",
            element: <OrganisationTree />,
            errorElement: <PageNotFound />,
          },

          {
            path: "skill-matrix",
            element: (
              <Suspense>
                <SkillMatrix />
              </Suspense>
            ),
            errorElement: <PageNotFound />,
            children: [
              {
                path: "new",
                element: <AddSkillMatrixLayout />,
                errorElement: <PageNotFound />,
              },
              {
                path: "add-action/:id",
                element: <SkillMatrixActionLayout />,
                errorElement: <PageNotFound />,
              },
              {
                path: "edit/:id",
                element: <ManageSkillMatrixLayout />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "skill-matrix/manage/:id",
            element: <EditSkillMatrix />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "add-action",
                element: <SkillMatrixActionLayout />,
                errorElement: <PageNotFound />,
              },
              {
                path: "category/:categoryId",
                element: <ManageSkillCategoryLayout />,
                errorElement: <PageNotFound />,
              },
              {
                path: "category/add",
                element: <ManageSkillMatrixCategory />,
                errorElement: <PageNotFound />,
              },
              {
                path: "import",
                element: <ImportSkillMatrix />,
                errorElement: <PageNotFound />,
              },
              {
                path: "update-skill/:itemId",
                element: <UpdateSkillMatrix />,
                errorElement: <PageNotFound />,
              },
              {
                path: ":labelId",
                element: <ManageSkillItemLayout />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: "requested-skill",
            element: <RequestedSkill />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: ":id",
                element: <UpdateRequest />,
                errorElement: <PageNotFound />,
              },
            ],
          },

          {
            path: "skill-analytics",
            element: <SkillMatrixAnalytics />,
            errorElement: <PageNotFound />,
          },

          {
            path: "organisation-configuration",
            element: <OrganisationConfigurationLayout />,
            children: [
              {
                path: "basic-details",
                element: <ManageConfigurationBasicDetails />,
                errorElement: <PageNotFound />,
              },
              {
                path: "softwares-used",
                element: <OrganisationSoftwares />,
                errorElement: <PageNotFound />,
              }
            ],
            errorElement: <PageNotFound />,
          },

          {
            path: "resource-requested",
            element: (
                <ResourceRequestLayout />
            ),
            errorElement: <PageNotFound />,
            children: [
              {
                path: "requests",
                element: (
                    <RequestList />
                ),
                children: [
                  {
                    path: "manage/:id",
                    element: (
                        <ManageResourceLayout />
                    ),
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "new",
                    element: <ManageResourceRequest />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "mail/:id",
                    element: (
                        <RequestMail />
                    ),
                    errorElement: <PageNotFound />,
                  },
                ]
              },

            ],
            
          },

          {
            path: "skill-development",            
            element: <SkillDevelopmentLayout />,
            children: [
              {
                path: "assigned-course-list",
                element: <SkillDevelopmentList />,
                errorElement: <PageNotFound />,
                children: [
                  {
                    path: ":id",
                    element: <ManageSkillDevelopment />,
                    errorElement: <PageNotFound />,
                  },
                  {
                    path: "edit-score/:id",
                    element: <ManageSkillDevelopmentScoreAndStatus />,
                    errorElement: <PageNotFound />,
                  }
                ],
              },
            ],
            errorElement: <PageNotFound />,
          },  
          {
            path: "configurations/course/course-section/:courseId",
            element: <CourseConfigurationSectionList />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "manage/:courseSectionId",
                element: <ManageCourseSectionConfiguration />,
                errorElement: <PageNotFound />,
              }             
            ]
          },

          {
            path: "configurations/course/course-section/:courseId/course-sub-section/:courseSectionId",
            element: <CourseConfigurationSubSectionList />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: "manage/:courseSubSectionId",
                element: <ManageCourseSubSectionConfiguration />,
                errorElement: <PageNotFound />,
              }             
            ]
          },   

          {
            path: "settings",
            element: <SettingsLayout />,
            errorElement: <PageNotFound />,
          },
          {
            path: "google-calendar",
            element: <GoogleCalender />,
            errorElement: <PageNotFound />,
          },
        ],
      },
    ],
  },
  {
    path: "/not-found",
    element: <PageNotFound />,
    errorElement: <PageNotFound />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
    errorElement: <PageNotFound />,
  },
]);
