import { mediaImage } from "../endpoints";
import HttpService from "../http";
import { IMediaImageResponse, IMediaImagesResponse } from "../../interfaces";
const MediaImageService = () => {

	const { httpRequest } = HttpService();
	const getMediaImageList = async (search: object): Promise<IMediaImagesResponse> =>
		new Promise((resolve, reject) => {
			httpRequest<IMediaImagesResponse>(
				"GET",
				`${mediaImage}/list`,
				{},
				search
			)
				.then(resolve)
				.catch(reject);
		});


	const addMediaImage = async (payload: object): Promise<IMediaImageResponse> => new Promise((resolve, reject) => {
		httpRequest<IMediaImageResponse>(
			"POST",
			`${mediaImage}`,
			payload)
			.then(resolve)
			.catch(reject);
	});

	const getMediaImage = async (query: object): Promise<IMediaImageResponse> => new Promise((resolve, reject) => {
		httpRequest<IMediaImageResponse>(
			"GET",
			`${mediaImage}`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteMediaImage = async (payload: object): Promise<IMediaImageResponse> =>
		new Promise((resolve, reject) => {
			httpRequest<IMediaImageResponse>(
				"DELETE",
				`${mediaImage}`,
				payload
			)
				.then(resolve)
				.catch(reject);
		});

	const updateMediaImage = async (payload: object): Promise<IMediaImageResponse> =>
		new Promise((resolve, reject) => {
			httpRequest<IMediaImageResponse>(
				"PUT",
				`${mediaImage}`,
				payload
			)
				.then(resolve)
				.catch(reject);
		});

	return { addMediaImage, getMediaImage, getMediaImageList, deleteMediaImage, updateMediaImage };

};

export { MediaImageService };
