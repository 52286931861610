import "./style.scss";
import { useQuery } from "@tanstack/react-query";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";
import { ChangeEvent, useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
  useParams,
} from "react-router-dom";
import useResource from "../../../../hooks/useResource";
import useDebounce from "../../../../hooks/useDebounce";
import { Box, Tooltip, Typography } from "@mui/material";
import { capitalize, formatDate } from "../../../../utilities/helper";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import { okrKeyResultsService } from "../../../../services/okr/objectives/key-results";
import { OkrObjectivesService } from "../../../../services/okr";
import {
  IKeyResultData,
  IKeyResultRows,
  IKeyResultState,
} from "../../../../interfaces/okr/objectives/key-results";
import { ProgressBar } from "../../../../utilities/helper1";

interface outletProps {
  refetchKeyResults: () => void;
}

const OKRKeyresutls = () => {
  const { objectiveId } = useParams();
  const { getObjective } = OkrObjectivesService();

  const objective = useQuery({
    queryKey: ["objective-details"],
    queryFn: () => getObjective({ _id: objectiveId }),
    enabled: !!objectiveId,
  });

  const objectiveName =
    (objective &&
      objective.data &&
      objective.data.data &&
      objective.data.data.name) ||
    "";
  const objectiveDeparment =
    (objective &&
      objective.data &&
      objective.data.data &&
      objective.data.data._department) ||
    "";

  let rows: IKeyResultRows[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getKeyResults } = okrKeyResultsService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IKeyResultState>({
    filters: {
      search: "",
    },
  });

  const keyResults = useQuery({
    queryKey: ["all-key-results", state.filters],
    queryFn: () =>
      getKeyResults({
        pagination: false,
        _objective: objectiveId,
        ...state.filters,
      }),
  });
  const keyResultsData =
    (keyResults && keyResults.data && keyResults.data.data) || [];

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      filters: {
        search,
      },
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleClick = () => {
    navigate({
      pathname: "/okr-objectives",
      search: searchParams.toString(),
    });
  };

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "assignee",
      label: "Responsibility",
    },
    {
      id: "priority",
      label: "Priority",
    },
    {
      id: "currentFigure",
      label: "Current Figure",
    },
    {
      id: "targetFigure",
      label: "Target Figure",
    },
    {
      id: "startDate",
      label: "Start Date",
    },
    {
      id: "endDate",
      label: "End Date",
    },
    {
      id: "weightage",
      label: "Weightage(%)",
    },
    {
      id: "achievedValue",
      label: "Achieved Value",
    },
    {
      id: "progress",
      label: "Progress",
    },
    {
      id: "completionDate",
      label: "Completion Date",
    },
    {
      id: "approver",
      label: "Approver",
    },
  ];

  const createRow = (index: number, kr: IKeyResultData) => ({
    id: (index + 1).toString(),
    name: (
      <Tooltip title={kr?.name ?? ""} placement="bottom-start">
        <Typography fontSize={14} color="primary" onClick={() => navigate({ pathname: "details/" + kr?._id, search: searchParams.toString() })} className="cursor-pointer">
          {capitalize(kr?.name)}
        </Typography>
      </Tooltip>
    ),
    description: 
      <Tooltip title={kr?.description ?? ""}>
        <Typography fontSize={14}>
          {kr?.description && kr?.description?.length > 60
            ? `${capitalize(kr?.description).slice(0, 60)}...`
            : kr?.description ?? "--"}
        </Typography>
      </Tooltip>,
    assignee: capitalize(kr?._assignee?.name),
    priority: capitalize(kr?.priority),
    currentFigure: kr?.currentFigure?.toString(),
    targetFigure: kr?.targetFigure?.toString(),
    startDate: formatDate(kr?.startDate),
    endDate: formatDate(kr?.endDate),
    weightage: kr?.weightage?.toString(),
    actualFigure: kr?.actualFigure?.toString(),
    progress: <ProgressBar progress={Number(kr?.approvedCompletion.toFixed(2)) || 0} />,
    completionDate: formatDate(kr?.completionDate) || "",
    achievedValue: kr?.achievedValue || 0,
    approver: capitalize(kr?._approver?.name),
  });

  if (keyResultsData.length) {
    rows = keyResultsData.map((param, i) => createRow(i, param));
  }

  return (
    <div>
      <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography className="title" variant="h5" fontWeight={600}>
            {capitalize(objectiveName)}
          </Typography>
        </div>
      </header>
      <Header
        className="my-2"
        searchPlaceholder="Search by key result name"
        onSearch={onSearch}
        btnText="Add/Edit Key Result"
        onBtnClick={
          resourceAllocate("okr-key-results.write")
            ? keyResultsData.length > 0
              ? () => navigate("update")
              : () => navigate("new")
            : undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 230px)"
          errorMessage="Add key results to see the data here"
        />
      </Box>

      <Outlet
        context={{
          ...outlet,
          refetchKeyResults: keyResults.refetch,
          keyResults: keyResultsData,
          objectiveName,
          objectiveDeparment
        }}
      />
    </div>
  );
};

export default OKRKeyresutls;
