import { IOrganisationConfigurationResponse, IOrganisationThemeConfigurationResponse } from "../../interfaces/organisationConfiguration/basic-details";
import HttpService from "../../services/http";
import { orgConfig, publicUrl } from "../endpoints";

const organisationConfigurationDataService = () => {
  const { httpRequest } = HttpService();

  const getOrganisationConfiguration =
    async (): Promise<IOrganisationConfigurationResponse> =>
      new Promise((resolve, reject) => {
        httpRequest<IOrganisationConfigurationResponse>("GET", `${orgConfig}`)
          .then(resolve)
          .catch(reject);
      });

  const updateOrganisationConfiguration = async (
    payload: object
  ): Promise<IOrganisationConfigurationResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IOrganisationConfigurationResponse>("PUT", `${orgConfig}`, payload)
        .then(resolve)
        .catch(reject);
    });
  
  const updateOrganisationThemeConfiguration = async (
    payload: object
  ): Promise<IOrganisationConfigurationResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IOrganisationConfigurationResponse>("PUT", `${orgConfig}/theme-configuration`, payload)
        .then(resolve)
        .catch(reject);
    });

  const getOrganisationThemeConfiguration = async () :Promise<IOrganisationThemeConfigurationResponse>=> new Promise((resolve,reject) => {
      httpRequest<IOrganisationThemeConfigurationResponse>(
        "GET",
        `config/${publicUrl}/organisation-theme-configuration`,
    )
      .then(resolve)
      .catch(reject);
  });

  return { getOrganisationConfiguration, updateOrganisationConfiguration, updateOrganisationThemeConfiguration, getOrganisationThemeConfiguration };
};

export { organisationConfigurationDataService };
