import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Switch, TextField, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { useQuery } from "@tanstack/react-query";
import { capitalize, checkTimePeriod } from "../../../../utilities/helper";
import { IAssessmentFilterState } from "../../../../interfaces/configuration/assessments";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AssessmentInstructionService } from "../../../../services/configuration/assessment-instructions";
import { TechnicalQuestionService } from "../../../../services";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import useSnackbar from "../../../../hooks/useSnackbar";
import { TestsService } from "../../../../services/configuration/tests";
import useResource from "../../../../hooks/useResource";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const AssessmentFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { snackbar } = useSnackbar();
    const { resourceAllocate } = useResource();
    const { getTechnicalQuestions } = TechnicalQuestionService();
    const { getAssessmentInstructionPartial } = AssessmentInstructionService();
    const { getTests } = TestsService();
    const codingChallenges = useQuery({
        queryKey: ["allQuestions"],
        queryFn: () =>
            getTechnicalQuestions({}),
    });
    const codingChallengesData = codingChallenges?.data?.data;

    const tests = useQuery({
        queryKey: ["allTests"],
        queryFn: () =>
            getTests(),
    });
    const testsData = tests?.data?.data.map(test => ({
        _id: test._id,
        title: test.name
      }));

    const instructions = useQuery({
        queryKey: ["allInstructions"],
        queryFn: () =>
            getAssessmentInstructionPartial(),
    });

    const instructionsData = instructions?.data?.data;
    const [searchState, setSearchState] = useState({
        selectedMenu: 0,
        searchQuery: "",
    });

    const handleSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setSearchState({ ...state, searchQuery: event.target.value });
      };
    
      const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.stopPropagation();
      };
    
    const handleNumberKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.stopPropagation();
        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(event.key) && event.preventDefault();
      };

    const filterData = (data : {title: string, _id: string}[]) => {
        const query = searchState.searchQuery.trim().toLowerCase();
        return data.filter((item: { title: string; }) => item.title.toLowerCase().includes(query));
    };

    const statusList = [
        { value: "CREATED", key: "CREATED" },
        { value: "PUBLISHED", key: "PUBLISHED" },
        { value: "ARCHIVED", key: "ARCHIVED" },
    ];

    const [state, setState] = useState<IAssessmentFilterState>({
        selectedMenu: 0,
        _tests: [],
        _codingChallenges: [],
        status: [],
        _instruction: [],
        date: [{
            key: "",
            value: "",
            startDate: "",
            endDate: ""
        }],
        duration: {
            startDuration: undefined,
            endDuration: undefined
        }

    });

    const [applyDurationFilter, setApplyDurationFilter] = useState(
        !!state.duration && !!state.duration.endDuration
    );

    useEffect(() => {
        resetFilter();
        if(!resourceAllocate("technical-question.read") && !resourceAllocate("test.read")) {
            setState((prevState) => ({
                ...prevState,
                selectedMenu: 2
            }));
        }else if(!resourceAllocate("technical-question.read")){
            setState((prevState) => ({
                ...prevState,
                selectedMenu: 1
            }));
        }
    }, []);

    useEffect(() => {
        const _tests: { key: string, value: string }[] = searchParams.get("_tests") ? JSON.parse(String(searchParams.get("_tests"))) : [];
        const _codingChallenges: { key: string, value: string }[] = searchParams.get("_codingChallenges") ? JSON.parse(String(searchParams.get("_codingChallenges"))) : [];
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const _instruction: { key: string, value: string }[] = searchParams.get("_instruction") ? JSON.parse(String(searchParams.get("_instruction"))) : [];
        const duration: { startDuration: number, endDuration: number } = searchParams.get("duration") ? JSON.parse(String(searchParams.get("duration"))) : { startDuration: undefined, endDuration: undefined };
        const dateFilter: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        let date: {
            startDate: string;
            endDate: string;
            key: string;
            value: string;
        }[] = [];
        if (dateFilter?.length) {
            date = [{
                key: dateFilter[0]?.key,
                value: dateFilter[0]?.value,
                startDate: dateFilter[0]?.startDate,
                endDate: dateFilter[0]?.endDate,
            }];
        }
        setState((prevState) => ({
            ...prevState,
            _tests,
            _codingChallenges,
            status,
            _instruction,
            duration,
            date
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
        setSearchState({ selectedMenu: index, searchQuery: "" });
    };


    const handleRightListItem = (name: "_tests" | "_codingChallenges" | "status" | "_instruction", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "_tests" | "_codingChallenges" | "status" | "_instruction" | "date", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const handleDateListItem = (name: "date", key: string, value: string) => {
        const date = checkTimePeriod(key);

        const payload: Array<{
            key: string;
            value: string;
            startDate: string,
            endDate: string,
        }> = [{
            key,
            value,
            startDate: date.startDate,
            endDate: date.endDate,
        }];

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let date: Array<{
            key: string;
            value: string;
            startDate: string,
            endDate: string,
        }> = [];

        if (state.date.length && period === "start") {
            date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.date.length && period === "end") {
            date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.date.length && period === "start") {
            const currentDate = new Date();
            currentDate.setHours(23, 59, 59);
            date = [{
                key: "custom",
                value: "custom",
                startDate: dayjs(e).startOf("day").toISOString(),
                endDate: currentDate.toISOString()
            }];
        } else {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            date = [{
                key: "custom",
                value: "custom",
                startDate: currentDate.toISOString(),
                endDate: dayjs(e).endOf("day").toISOString(),
            }];
        }

        setState(prevState => ({
            ...prevState,
            date
        }));
    };

    const resetFilter = () => {
        setApplyDurationFilter(false);
        setState({
            selectedMenu: 0,
            _tests: [],
            _codingChallenges: [],
            status: [],
            _instruction: [],
            date: [],
            duration: {
                startDuration: undefined,
                endDuration: undefined
            }
        });
    };

    const onApply = () => {
        if (applyDurationFilter && (!state.duration || !state.duration.startDuration || !state.duration.endDuration)) {
            snackbar("Both minimum and maximum duration must be provided", "warning");
        }
        else if (state.duration && ((state.duration.startDuration && !state.duration.endDuration) || (!state.duration.startDuration && state.duration.endDuration))) {
            snackbar("Both minimum and maximum duration must be provided", "warning");
        } else if (state.duration && state.duration.startDuration && state.duration.endDuration && (state.duration.startDuration > state.duration.endDuration)) {
            snackbar("Minimum duration should be less than maximum duration", "warning");
        } else {
            searchParams.set("_tests", JSON.stringify(state._tests));
            searchParams.set("_codingChallenges", JSON.stringify(state._codingChallenges));
            searchParams.set("status", JSON.stringify(state.status));
            searchParams.set("_instruction", JSON.stringify(state._instruction));
            searchParams.set("duration", JSON.stringify(state.duration));
            searchParams.set("date", JSON.stringify(state.date));
            setSearchState({ ...state, searchQuery: "" });

            searchParams.set("page", "1");
            navigate({
                pathname: "/assessment/assessments",
                search: searchParams.toString()
            });
            OnClose();
        }
    };

    const onClose = () => {
        const _tests: { key: string, value: string }[] = searchParams.get("_tests") ? JSON.parse(String(searchParams.get("_tests"))) : [];
        const _codingChallenges: { key: string, value: string }[] = searchParams.get("_codingChallenges") ? JSON.parse(String(searchParams.get("_codingChallenges"))) : [];
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const _instruction: { key: string, value: string }[] = searchParams.get("_instruction") ? JSON.parse(String(searchParams.get("_instruction"))) : [];
        const duration: { startDuration: number, endDuration: number } = searchParams.get("duration") ? JSON.parse(String(searchParams.get("duration"))) : { startDuration: undefined, endDuration: undefined };
        const dateFilter: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        let date: {
            startDate: string;
            endDate: string;
            key: string;
            value: string;
        }[] = [];
        if (dateFilter?.length) {
            date = [{
                key: dateFilter[0]?.key,
                value: dateFilter[0]?.value,
                startDate: dateFilter[0]?.startDate,
                endDate: dateFilter[0]?.endDate,
            }];
        }
        setState((prevState) => ({
            ...prevState,
            _tests,
            _codingChallenges,
            status,
            _instruction,
            duration,
            date
        }));
        OnClose();
    };

    const deleteDurationChip = () => {
        setState((prevState) => ({
            ...prevState,
            duration: {
                startDuration: undefined,
                endDuration: undefined
            },
        }));
        setApplyDurationFilter(false);
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state._codingChallenges.length || state.status.length || state._instruction.length || state._tests.length || (!!state.duration && !!state.duration.startDuration) || state.date.length) ?
                                <>

                                    {state._codingChallenges.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_codingChallenges", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state._instruction.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_instruction", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state._tests.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_tests", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.date.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("date", ele.key)} label={ele.value} variant="outlined" />)}
                                    {applyDurationFilter && <Chip className="m-1" color="primary" onDelete={() => deleteDurationChip()} label="Duration Filter Applied" variant="outlined" />}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            {
                                resourceAllocate("technical-question.read") && 
                                    <MenuItem
                                        index={0}
                                        label="Coding Challenges"
                                        selectedMenu={state.selectedMenu === 0}
                                        onChange={handleLeftListItem}
                                        count={state._codingChallenges}
                                    />
                            }

                            {
                                resourceAllocate("test.read") && 
                                    <MenuItem
                                        index={1}
                                        label="MCQs Tests"
                                        selectedMenu={state.selectedMenu === 1}
                                        onChange={handleLeftListItem}
                                        count={state._tests}
                                    />
                            }


                            <MenuItem
                                index={2}
                                label="Duration"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                count={applyDurationFilter ? [{ value: "Duration" }] : []}
                            />

                            <MenuItem
                                index={3}
                                label="Status"
                                selectedMenu={state.selectedMenu === 3}
                                onChange={handleLeftListItem}
                                count={state.status}
                            />

                            <MenuItem
                                index={4}
                                label="Date"
                                selectedMenu={state.selectedMenu === 4}
                                onChange={handleLeftListItem}
                                count={state.date}
                            />

                            {
                                resourceAllocate("assessment-instruction.read") && 
                                    <MenuItem
                                        index={5}
                                        label="Assessment Instructions"
                                        selectedMenu={state.selectedMenu === 5}
                                        onChange={handleLeftListItem}
                                        count={state._instruction}
                                    />
                            }

                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                <>

                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        fullWidth
                                        value={searchState.searchQuery}
                                        onChange={handleSearch}
                                        onKeyDown={handleKeyDown}
                                        style={{ marginBottom: "16px" }}
                                    />

                                    {codingChallengesData && filterData(codingChallengesData).map((category: { _id: string; title: string; }) => <ListItemButton
                                        key={category?._id}
                                        selected={!!state._codingChallenges.find(ele => ele.key === category._id)}
                                        onClick={() => handleRightListItem("_codingChallenges", category?._id, category.title)}
                                    >
                                        <ListItemText primary={capitalize(category?.title || "")} />
                                        <Checkbox edge="end" checked={!!state._codingChallenges.find(ele => ele.key === category._id)} />
                                    </ListItemButton>)}
                                </>
                            }
                            {
                                state.selectedMenu === 1 &&
                                <>
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        fullWidth
                                        value={searchState.searchQuery}
                                        onChange={handleSearch}
                                        onKeyDown={handleKeyDown}
                                        style={{ marginBottom: "16px" }}
                                    />
                                    {testsData && filterData(testsData).map((service: { _id: string; title: string; }) => <ListItemButton
                                        key={service?._id}
                                        selected={!!state._tests.find(ele => ele.key === service._id)}
                                        onClick={() => handleRightListItem("_tests", service._id, service.title)}
                                    >
                                        <ListItemText primary={capitalize(service.title)} />
                                        <Checkbox edge="end" checked={!!state._tests.find(ele => ele.key === service._id)} />
                                    </ListItemButton>)}

                                </>
                            }
                            {
                                state.selectedMenu === 2 &&
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", margin: "16px 0" }}>

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={applyDurationFilter}
                                                    onChange={(e) => {

                                                        if (!e.target.checked) {

                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                duration: {
                                                                    startDuration: undefined,
                                                                    endDuration: undefined,
                                                                },
                                                            }));
                                                        }
                                                        setApplyDurationFilter(e.target.checked);
                                                    }
                                                    }
                                                />
                                            }
                                            label="Apply Duration Filter"
                                        />

                                        <TextField
                                            type="number"
                                            label="Minimum Duration (in min)"
                                            placeholder="Enter minimum duration"
                                            variant="outlined"
                                            disabled={!applyDurationFilter}
                                            value={state.duration && state.duration.startDuration || ""}
                                            onKeyDown={handleNumberKeyDown}
                                            inputProps={{
                                                pattern: "[0-9]*",
                                                inputMode: "numeric",
                                                maxLength: 3,
                                                onInput: (e) => {
                                                  e.preventDefault();
                                                  const inputValue = e.currentTarget.value;
                                                  const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                                                  const truncatedValue = sanitizedValue.slice(0, 3);
                                                  e.currentTarget.value = truncatedValue;
                                                  setState((prevState) => ({
                                                    ...prevState,
                                                    duration: {
                                                        ...prevState.duration,
                                                        startDuration: Number(truncatedValue),
                                                    },
                                                }));
                                                },
                                                required: true,
                                              }}
                                        />
                                        <TextField
                                            type="number"
                                            label="Maximum Duration (in min)"
                                            placeholder="Enter maximum duration"
                                            variant="outlined"
                                            value={state.duration && state.duration.endDuration || ""}
                                            disabled={!applyDurationFilter}
                                            onKeyDown={handleNumberKeyDown}
                                            inputProps={{
                                                pattern: "[0-9]*",
                                                inputMode: "numeric",
                                                maxLength: 3,
                                                onInput: (e) => {
                                                  e.preventDefault();
                                                  const inputValue = e.currentTarget.value;
                                                  const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                                                  const truncatedValue = sanitizedValue.slice(0, 3);
                                                  e.currentTarget.value = truncatedValue;
                                                  setState((prevState) => ({
                                                    ...prevState,
                                                    duration: {
                                                        ...prevState.duration,
                                                        endDuration: Number(truncatedValue),
                                                    },
                                                }));
                                                },
                                                required: true,
                                              }}
                                        />
                                    </Box>
                                </>
                            }
                            {
                                state.selectedMenu === 3 &&
                                statusList.map(status => <ListItemButton
                                    key={status.key}
                                    selected={!!state.status.find(ele => ele.key === status.key)}
                                    onClick={() => handleRightListItem("status", status.key, status.value)}
                                >
                                    <ListItemText primary={status.value} />
                                    <Checkbox edge="end" checked={!!state.status.find(ele => ele.key === status.key)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 4 &&
                                <>
                                    {[
                                        { key: "yesterday", value: "Yesterday" },
                                        { key: "today", value: "Today" },
                                        { key: "thisWeek", value: "Weekly" },
                                        { key: "thisMonth", value: "Monthly" },
                                        { key: "thisQuarter", value: "Quarterly" },
                                    ]?.map((date) =>
                                        <ListItemButton
                                            key={date.key}
                                            selected={!!state.date.find(ele => ele.key === date.key)}
                                            onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                                        >
                                            <ListItemText primary={date.value} />
                                            <Checkbox edge="end" checked={!!state.date.find(ele => ele.key === date.key)} />
                                        </ListItemButton>
                                    )}
                                    <Box marginTop={2}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                value={state.date[0]?.startDate ? dayjs(state.date[0]?.startDate) : null}
                                                onChange={e => handleDate(e, "start")}
                                                label="Start Date"
                                                format="LL"
                                            />
                                        </LocalizationProvider>
                                        <div className="mt-3" />
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                value={state.date[0]?.endDate ? dayjs(state.date[0]?.endDate) : null}
                                                onChange={e => handleDate(e, "end")}
                                                label="End Date"
                                                format="LL"
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </>

                            }
                            {
                                state.selectedMenu === 5 &&
                                instructionsData && instructionsData.map(service => <ListItemButton
                                    key={service?._id}
                                    selected={state._instruction.find(ele => ele.key === service._id) ? true : false}
                                    onClick={() => handleRightListItem("_instruction", service._id, service.name)}
                                >
                                    <ListItemText primary={capitalize(service.name)} />
                                    <Checkbox edge="end" checked={!!state._instruction.find(ele => ele.key === service._id)} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default AssessmentFilters;