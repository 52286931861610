import Joi from "joi";
import { required } from "../../shared";
export const authorValidation = Joi.object({
    _user: Joi.string()
        .required()
        .label("User")
        .messages({
            "string.empty": required
        }),
    _productId: Joi.string()
        .required()
        .label("Prodcut")
        .messages({
            "string.empty": required
        }),
    designation: Joi.string()
        .required()
        .label("Designation")
        .messages({
            "string.empty": required
        }),
    about: Joi.string()
        .trim()
        .min(3)
        .required()
        .label("About")
        .messages({
            "string.empty": required
        }),
    slug: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(30)
        .label("Slug")
        .messages({
            "string.empty": required
        }),
    linkedin: Joi.string()
        .optional()
        .allow("")
        .label("Linkedin"),
    image: Joi.string()
        .required()
        .label("Image")
        .messages({
            "string.empty": required
        }),
    metaTitle: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(60)
        .label("Meta Title")
        .messages({
            "string.empty": required
        }),
    metaDescription: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(200)
        .label("Meta Description")
        .messages({
            "string.empty": required
        }),
    _keywords: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Keywords")
        .messages({
            "array.empty": required,
        }),
});
