
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useResource from "../../../../hooks/useResource";
import { TabPanel } from "../../../../components/shared/mui-tabs";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";
import { AssessmentService } from "../../../../services/configuration/assessments";
import { useQuery } from "@tanstack/react-query";
import AssessmentResponse from "./assessment-response";
import AssessmentPsyTests from "./psy-tests";
import AssessmentCodingQuestions from "./coding-questions";
import Cheating from "./cheating";

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

interface IState {
  redirectionLinks: string[];
}

const AssessmentInfo: FC = () => {
  const { assessmentId } = useParams();
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const { resourceAllocate } = useResource();
  const { getAssessment } = AssessmentService();
  const assessmentDetails = useQuery({ queryKey: ["assessment-information"], queryFn: () => getAssessment({ _id: assessmentId }), enabled: resourceAllocate("assessment.read") });
  const assessmentName = assessmentDetails && assessmentDetails.data && assessmentDetails.data.data && assessmentDetails.data.data.title || "";
  const assessmentPsyTests = assessmentDetails && assessmentDetails.data && assessmentDetails.data.data && assessmentDetails.data.data._tests || [];
  const assessmentCodingQuestions = assessmentDetails && assessmentDetails.data && assessmentDetails.data.data && assessmentDetails.data.data._codingChallenges || [];
  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });
  
  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];

    if (!redirectionLinks.length) {
      if (resourceAllocate("assessment.read")) {
        redirectionLinks.push("psy tests");
        redirectionLinks.push("technical questions");
      }
      if (resourceAllocate("technical-session.read")) {
        redirectionLinks.push("responses");
      }
      if(resourceAllocate("assessment.read")) {
        redirectionLinks.push("cheating");
      }
      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }

    const tab = searchParam.get("type") || "psy tests"; 
    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", state.redirectionLinks[newValue]);
    searchParam.set("page", "1");
    setSearchParam(searchParam);
    setValue(newValue);
  };

  const handleClick = () => {
    navigate("/assessment/assessments");
  };

  return (
    <div id="test-manage">
      <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography className="title" variant="h5">
            {assessmentName}
          </Typography>
        </div>
      </header>
      <Box width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {state.redirectionLinks.map((link, index) => (
              <Tab
                key={index}
                className={index === 0 ? "tabs-space start-tab-space" : "tabs-space"}
                label={link.charAt(0).toUpperCase() + link.slice(1)}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {state.redirectionLinks.map((link, index) => (
          <TabPanel key={index} value={value} index={index}>
            {link === "psy tests" && <AssessmentPsyTests tests={assessmentPsyTests} />}
            {link === "technical questions" && <AssessmentCodingQuestions codingQuestions={assessmentCodingQuestions} />}
            {link === "responses" && <AssessmentResponse />}
            {link === "cheating" && <Cheating />}
          </TabPanel>
        ))}
      </Box>
    </div>
  );
};
export default AssessmentInfo;