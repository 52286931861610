import {Autocomplete, Button, capitalize, Chip, Grid, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomDialog from "../../../components/mui/dialog";
import { ContentCategoryService } from "../../../services/category-content";
import { IContentCategory, IContentCategoryField } from "../../../interfaces/category-content";
import { contentCategoryValidation } from "../../../validations/category-content";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse, IKeyword, IProjectData } from "../../../interfaces";
import CustomLabel from "../../../components/mui/custom-label";
import CategoryService from "../../../services/content/category";
import KeywordService from "../../../services/content/keyword";
import BrowserPreview from "../../../components/boowser-preview";
import SeoSummary from "../../../components/seo-summary";

interface outletProps {
  reFetch: () => void;
  refetchContentCategories: () => void;
}

const ManageContentCategory = () => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const { getContentCategorys } = CategoryService();
  const { getContentKeywords } = KeywordService();
  const [searchParam] = useSearchParams();
  const outlet = useOutletContext<outletProps>();
  const hitQuery = !(id === "new");
  const navigate = useNavigate();
  const [showBrowserPreview, setShowBrowserPreview] = useState(false);
  const [showSeoSummary, setShowSeoSummary] = useState(false);
  const { getContentCategory, addContentCategory, updateContentCategory } = ContentCategoryService();
  const categoryContent = useQuery({ queryKey: [hitQuery], queryFn: () => getContentCategory({ _id: id }), enabled: hitQuery });
  const categoryContentData = categoryContent && categoryContent.data && categoryContent.data.data;
  const { handleSubmit, control, setValue, getValues, trigger, watch, formState: { errors } } = useForm<IContentCategory>({
    resolver: joiResolver(contentCategoryValidation),
    defaultValues: {
      _product: "",
      _category: "",
      category_para: "",
      slug: "",
      metaTitle: "",
      metaDescription: "",
      _keywords: [],
      priority: ""
    }
  });

  const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
    (state) => state.cmsProduct.list
  ) || [];
  const categoryData = useQuery({
    queryKey: ["categoryData"],
    queryFn: () =>
      getContentCategorys({ isActive: true }),
  });
  const categories = categoryData && categoryData.data && categoryData.data.data || [];

  const keywordsData = useQuery({
    queryKey: ["keywordData"],
    queryFn: () =>
      getContentKeywords({ pagination: false }),
  });
  const keywords = keywordsData && keywordsData.data && keywordsData.data.data || [];

  useEffect(() => {
    if (id !== "new" && categoryContent.data) {
        const data = categoryContent && categoryContent.data && categoryContent.data.data || [];
        setValue("_product", data._product);
        setValue("priority", data.priority);
        setValue("_category", data?._category?._id);
        setValue("category_para", data.category_para);
        setValue("slug", data.slug);
        setValue("metaTitle", data.metaTitle);
        setValue("metaDescription", data.metaDescription);
        setValue("_keywords", data._keywords.map((item) => item._id));
    }
  }, [id, categoryContent.data]);

  const onSubmit = async (data: IContentCategory) => {
    try {
      if (id === "new") {

        const add = await addContentCategory(data);
        snackbar(add.message, "info");
        navigate({
          pathname: "/category-content",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchContentCategories && outlet.refetchContentCategories();
      } else {
        const payload = { ...data, _id: id };
        const update = await updateContentCategory(payload);
        snackbar(update.message, "info");
        navigate({
          pathname: "/category-content",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchContentCategories && outlet.refetchContentCategories();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/category-content",
      search: searchParam.toString()
    });
  };

  const fields: IContentCategoryField[] = [
    {
      label: "Product Name",
      name: "_product",
      type: "auto-complete",
      placeholder: "Select product name",
      required: true,
    },
    {
      label: "Category Name",
      name: "_category",
      type: "auto-complete",
      placeholder: "Select category name",
      required: true,
    },
    {
      label: "Category Para",
      name: "category_para",
      type: "input",
      placeholder: "Type category para",
      required: true,
    },
    {
      label: "Slug",
      name: "slug",
      type: "input",
      placeholder: "Type slug",
      required: true,
    },
    {
      label: "Priority",
      name: "priority",
      type: "input",
      placeholder: "Type priority",
      required: true,
    },
    {
      label: "Meta Title",
      name: "metaTitle",
      type: "input",
      placeholder: "Type meta title",
      required: true,
    },
    {
      label: "Meta Description",
      name: "metaDescription",
      type: "input",
      placeholder: "Type meta description",
      required: true,
    },
    {
      label: "Keywords",
      name: "_keywords",
      type: "select",
      required: true,
    },
  ];

  const onChangeKeywordsAutocomplete = (
    event: SyntheticEvent<Element, Event>,
    value: IKeyword[],
    field: IContentCategoryField
  ) => {
    const data = getValues(field.name) as string[];
    const isExist = data && data.includes(value[0]._id);
    if (!isExist) {
      data && data.push(value[0]._id);
      setValue(field.name, data);
    }
    trigger(field.name);
  };

  const handleInput = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, 
    onChange: (value: string) => void
) => {
    const inputValue = e.currentTarget.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
    const valueAsNumber = parseInt(sanitizedValue, 10);

    if (!isNaN(valueAsNumber) && valueAsNumber >= 1 && valueAsNumber <= 999) {
        e.currentTarget.value = sanitizedValue;
        onChange(sanitizedValue);
    } else if (sanitizedValue === "") {
        e.currentTarget.value = sanitizedValue;
        onChange(sanitizedValue);
    } else {
        e.currentTarget.value = sanitizedValue.slice(0, -1);
    }
};


  return (
    <>
      <CustomDialog
        title={id !== "new" ? "Edit Category Content" : "Add Category Content"}
        isOpen={!!id}
        onClose={onClose}
        confirmText={id !== "new" ? "Edit Category Content" : "Add Category Content"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) =>
                      <TextField
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        className="disable-text"
                        variant={"outlined"}
                        size={"small"}
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        {...prop.field}
                        multiline={field.name === "category_para"}
                        minRows={field.name === "category_para" ? 3 : 1}
                        maxRows={field.name === "category_para" ? 3 : 1}
                        type={field.name === "priority" ? "number" : "text"}
                        onKeyDown={field.name === "priority" ? (evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault() : undefined}
                        inputProps={
                          field.name === "priority" ?
                          {
                          pattern: "[0-9.]*",
                          inputMode: "decimal",
                          onInput: (e) => {
                              handleInput(e, prop.field.onChange);
                          },
                      } : undefined
                    }
                      />}
                  />
                </Grid>
                );
              }else if (field.name === "_product") {
                return (
                  <Grid xs={12} md={6} key={field.label} item>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <Autocomplete
                        className="disable-text"
                        options={productList.map( 
                          (data) => data
                        ) || []}
                        clearIcon={null}
                        getOptionLabel={(option) => capitalize(option.name)}
                        renderInput={(params) => <TextField
                          {...params}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                          size={"small"}
                          variant={"outlined"}
                          label={<CustomLabel label="Product" required={field?.required} />}
                          placeholder={field.placeholder}
                        />
                        }
                        {...prop.field}
                        value={productList.find(data => data._id === getValues(field.name)) || null}
                        onChange={(e, value) => {
                          setValue("_category", "");
                          setValue("_keywords", []);
                          setValue(field.name, value?._id || "");
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {capitalize(option.name)}
                          </li>
                        )}
                      />}
                    />
                  </Grid>
                );
              }else if (field.name === "_category") {
                return (
                  <Grid xs={12} md={6} key={field.label} item>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <Autocomplete
                        className="disable-text"
                        options={categories.filter(category => category._productId?._id === watch("_product")).map(
                          (data) => data
                        ) || []}
                        clearIcon={null}
                        getOptionLabel={(option) => capitalize(option.name)}
                        renderInput={(params) => <TextField
                          {...params}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                          size={"small"}
                          variant={"outlined"}
                          label={<CustomLabel label="Category" required={field?.required} />}
                          placeholder={field.placeholder}
                        />
                        }
                        {...prop.field}
                        value={categories.find(data => data._id === getValues(field.name)) || null}
                        onChange={(e, value) => {
                          setValue("_keywords", []);
                          setValue(field.name, value?._id || "");
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {capitalize(option.name)}
                          </li>
                        )}
                      />}
                    />
                  </Grid>
                );
              }else {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <>
                          <Autocomplete
                            fullWidth
                            options={keywords.filter(keyword => keyword._category?._id === watch("_category")).map(
                              (data) => data
                            ) || []}
                            getOptionLabel={(option) => capitalize(option.name)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label={<CustomLabel label={field.label} required={field?.required} />}
                                placeholder={field.placeholder}
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                              />
                            )}
                            {...prop.field}
                            value={[]}
                            onChange={(e, value) =>
                              onChangeKeywordsAutocomplete(e, value, field)
                            }
                            disabled={field.disabled}
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {capitalize(option.name)}
                              </li>
                            )}
                            multiple
                          />
                          {((getValues(field.name) as string[]) || []).map(
                            (item) => (
                              <Chip
                                key={item}
                                style={{ margin: "5px" }}
                                label={
                                  keywords.find(
                                    (data) => data._id === item
                                  )?.name
                                }
                                color="primary"
                                onDelete={() => {
                                  setValue(
                                    field.name,
                                    (getValues(field.name) as string[])?.filter(
                                      (value) => value !== item
                                    )
                                  );
                                }}
                                variant="outlined"
                              />
                            ))}
                        </>
                      )}
                    />
                  </Grid>
                );
              }
            })
          }
        </Grid>
        {
          id !== "new" && 
          <Grid container spacing={4} marginTop={0.5}>
            <Grid key={"browser-preview"} item xs={12} md={6}>
              <Button variant="outlined" fullWidth onClick={() => { setShowBrowserPreview(true); }}> Browser Preview</Button>
            </Grid>
            <Grid key={"browser-preview"} item xs={12} md={6}>
              <Button variant="outlined" fullWidth onClick={() => { setShowSeoSummary(true); }}> SEO Summary </Button>
            </Grid>
          </Grid>
        }
      </CustomDialog>
      {showBrowserPreview && <BrowserPreview onClose={() => { setShowBrowserPreview(false); }} metaTitle={categoryContentData && categoryContentData.metaTitle || ""} metaDescription={categoryContentData && categoryContentData.metaDescription || ""} keywords={categoryContentData && categoryContentData._keywords && categoryContentData._keywords.map(keyword => keyword.name) || []} />}
      {showSeoSummary && <SeoSummary onClose={() => { setShowSeoSummary(false); }} metaTitle={categoryContentData && categoryContentData.metaTitle || ""} metaDescription={categoryContentData && categoryContentData.metaDescription || ""} words={categoryContentData && categoryContentData.category_para.trim().split(/\s+/).length || 0} />}
    </>
  );

};

export default ManageContentCategory;