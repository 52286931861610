import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import {
  capitalize,
} from "../../../utilities/helper";
import { Box, Tooltip, Typography } from "@mui/material";
import { OkrRatingsService } from "../../../services/okr";
import useSnackbar from "../../../hooks/useSnackbar";
import WarningDialog from "../../../components/mui/warning-dialog";
import GetActions from "../../../components/get-actions";
import { IErrorResponse } from "../../../interfaces";
import { IOKRRating, IOKRRatingRow, IOKRRatingState } from "../../../interfaces/okr/ratings";

interface outletProps {
  refetchRatings: () => void;
}

const OKRRatings = () => {
  let rows: IOKRRatingRow[] = [];
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const { getRatings, deleteRating } = OkrRatingsService();
  const [state, setState] = useState<IOKRRatingState>({
    deleteWarning: false,
    _rating: "",
  });

  const ratings = useQuery({
    queryKey: ["all-okr-ratings"],
    queryFn: () =>
      getRatings({
        pagination: false,
      }),
  });
  const ratingsData =
    (ratings && ratings.data && ratings.data.data) || [];

  const columns = [
    {
      id: "scale",
      label: "Rating",
    },
    {
      id: "remark",
      label: "Remark",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "progressRange",
      label: "Progress Range",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const createRow = (index: number, rating: IOKRRating) => {

    const description = (
      <Tooltip title={rating?.description ?? ""}>
        <Typography fontSize={14}>
          {rating?.description && rating?.description?.length > 60
            ? `${capitalize(rating?.description).slice(0, 60)}...`
            : rating?.description ?? "--"}
        </Typography>
      </Tooltip>
    );

    const actions = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + rating._id,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("okr-ratings.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(rating._id),
            disabled: !resourceAllocate("okr-ratings.remove"),
          },
        ]}
      />
    );

    return {
      scale: rating?.scale,
      remark: capitalize(rating?.remark || "-"),
      progressRange: `${rating?.minRange}% - ${rating?.maxRange}%`,
      description,
      actions,
    };
  };

  if (ratingsData.length) {
    rows = ratingsData.map((obj, i) => createRow(i, obj));
  }
  const onDelete = async () => {
    try {
      const deleted = await deleteRating({ _id: state._rating });
      snackbar(deleted.message, "info");
      handleDelete();
      ratings.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };
  const handleDelete = (_rating = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _rating,
    }));
  };

  return (
    <div>
      <Header
        className="my-2"
        btnText="Add Rating"
        onBtnClick={
          resourceAllocate("okr-ratings.write")
            ? () => navigate({
              pathname: "new",
              search: searchParams.toString(),
            })
            : undefined
        }
      />

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 200px)"
          errorMessage="Add ratings to see the data here"
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Objective"
        description="Are you sure you want to delete this rating?"
      />

      <Outlet
        context={{
          ...outlet,
          refetchRatings: ratings.refetch,
        }}
      />
    </div>
  );
};

export default OKRRatings;
