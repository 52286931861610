import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { skillDevelopmentServices } from "../../../services/skill-development";
import { IErrorResponse } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomDialog from "../../../components/mui/dialog";
import CustomLabel from "../../../components/mui/custom-label";
import Select from "../../../components/mui/select";
import { IScoreAndSkillFields, ISkillDevelopment } from "../../../interfaces/skill-development";
import { ScoreAndStatusValidation } from "../../../validations/skill-status-scoring";

interface OutletProps {
    reFetch: () => void;
    refetchTeams: () => void;
}

const ManageSkillDevelopmentScoreAndStatus: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<OutletProps>();
    const { snackbar } = useSnackbar();
    const navigate = useNavigate();

    const {
      getSkillDevelopment,
      updateSkillDevelopmentStatusScoringCompletion
    } = skillDevelopmentServices();

    const handleKeyDownNumeric = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            event.key === "Backspace" ||
            event.key === "Delete" ||
            event.key === "Tab" ||
            event.key === "Escape" ||
            event.key === "Enter" ||
            event.key.startsWith("Arrow")
        ) {
            return;
        }
   
        if (!/^[0-9]$/.test(event.key)) {
            event.preventDefault();
        }
    };


    const handleKeyDownNonNumeric = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const currentValue = (event.target as HTMLInputElement).value;
        if (event.key === " " && currentValue.trim().length === 0) {
            event.preventDefault();
        }
    };


    const getSkillDevelopmentData = useQuery({
        queryKey: ["get-skill-development-score-status", id],
        queryFn: () => getSkillDevelopment({_id: id}),
        enabled: !!id,
    });


    const { handleSubmit, control, setValue, formState: { errors }, getValues } = useForm<ISkillDevelopment>({
        resolver: joiResolver(ScoreAndStatusValidation),
        defaultValues: {
            score: "",
            status: ""
        }
    });


    useEffect(() => {
        if (id !== "new") {
            if (getSkillDevelopmentData.data?.data) {
                setValue("status", getSkillDevelopmentData.data.data?.status || "");
                setValue("score", getSkillDevelopmentData.data.data?.score || "");
            }
        }
    }, [id, getSkillDevelopmentData.data, setValue]);


    const onSubmit = async (data: ISkillDevelopment) => {
        try {
          if(!getValues("score")){
            delete data.score;
          }
          const updatedPayload = {...data , _id: id};
          const status = await updateSkillDevelopmentStatusScoringCompletion(updatedPayload);
          snackbar(status.message, "info");
          outlet?.reFetch && outlet.reFetch();
          navigate({
            pathname: "/skill-development/assigned-course-list", 
            search: searchParam.toString()
        });
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/skill-development/assigned-course-list", 
            search: searchParam.toString()
        });
    };


    const fields: IScoreAndSkillFields[] = [
        {
            label: "Score",
            name: "score",
            type: "input",
            placeholder: "Enter score here",
            required: true
        },
        {
          label: "Status",
          name: "status",
          type: "select",
          children: [
            <MenuItem key={"ASSIGNED"} value="ASSIGNED">Assigned</MenuItem>,
            <MenuItem key={"RE-ASSIGNED"} value="RE-ASSIGNED">Re Assigned</MenuItem>,
            <MenuItem key={"CANCELLED"} value="CANCELLED">Cancelled</MenuItem>,
            <MenuItem key={"COMPLETED"} value="COMPLETED">COMPLETED</MenuItem>,
          ]
        },
    ];

    return (
        <Box>
            <CustomDialog
                size="sm"
                title={"Updating Skill Course Score and Status"}
                isOpen={!!id}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {fields.map(field => {
                        if (field.type === "input") {
                            return (
                                <Grid key={field.label} item xs={6} md={6}>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={({ field: { onChange, value } }) => (
                                      <TextField
                                        label={<CustomLabel label={field.label} required={field?.required} />}
                                        className="disable-text"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        placeholder={field.placeholder}
                                        error={!!errors[field.name]}
                                        helperText={errors[field.name]?.message}
                                        inputProps={{ maxLength: 3 , min: 0, max:100 }}
                                        onKeyDown={field.name === "score" ? handleKeyDownNumeric : handleKeyDownNonNumeric}
                                        onChange={(e) => {
                                          const newValue = Math.min(Number(e.target.value), 100);
                                          onChange(newValue);
                                      }}
                                        value={value}
                                      />
                                      )}
                                    />
                                </Grid>);
                        }else if (field.type === "select") {
                        return (
                          <Grid key={field.label} item xs={6} md={6}>
                            <Select
                              control={control}
                              name={field.name}
                              label={
                                <CustomLabel
                                  label={field.label}
                                  required={field?.required}
                                />
                              }
                              size="small"
                              variant="outlined"
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                            >
                              {field.children}
                            </Select>
                          </Grid>
                        );
                      } 
                    })}
                </Grid>
            </CustomDialog>
        </Box>
    );
};


export default ManageSkillDevelopmentScoreAndStatus;