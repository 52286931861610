import { IReporteeRatingResponse, ITeamEvaluationResonse, ITeamRatingResponse } from "../../../interfaces/pms/team-evaluation";
import { pmsRatingFeedback } from "../../endpoints";
import HttpService from "../../http";


const TeamEvaluationOverviewService = () => {
    const { httpRequest } = HttpService();

    const getTeamEvaluationOverview = async (search: object): Promise<ITeamEvaluationResonse> => new Promise((resolve, reject) => {
        httpRequest<ITeamEvaluationResonse>(
            "GET",
            `${pmsRatingFeedback}/team-evaluation-overview`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getReporteesRatingAnalytics = async (search: object): Promise<IReporteeRatingResponse> => new Promise((resolve, reject) => {
        httpRequest<IReporteeRatingResponse> (
            "GET",
            `${pmsRatingFeedback}/reportees-ratings`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getParameterWiseTeamReport = async (search: object): Promise<ITeamRatingResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamRatingResponse> (
            "GET",
            `${pmsRatingFeedback}/team-members-parameter-wise`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { getTeamEvaluationOverview, getReporteesRatingAnalytics, getParameterWiseTeamReport };
};

export { TeamEvaluationOverviewService };