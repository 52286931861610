export const columns = [
  {
    id: "keyResult",
    label: "Key Result",
  },
  {
    id: "objective",
    label: "Objective",
  },
  {
    id: "responsibility",
    label: "Responsibility",
  },
  {
    id: "createdBy",
    label: "Created By",
  },
  {
    id: "targetFigure",
    label: "Target Figure",
  },
  {
    id: "achievedValue",
    label: "Achieved Value",
  },
  {
    id: "endDate",
    label: "Timeline",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "submittedOn",
    label: "Submitted On",
  },
];
