import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { useSelector } from "react-redux";
import { ITeam } from "../../../../interfaces";
import { IOkrObjectivesFilters } from "../../../../interfaces/okr";
import dayjs from "dayjs";
import { capitalize, checkTimePeriod } from "../../../../utilities/helper";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onClose: () => void;
}

const OkrObjectiveFilters: FC<props> = ({ anchorEl, isOpen, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<IOkrObjectivesFilters>({
    selectedMenu: 0,
    type: [],
    frequency: [],
    status: [],
    _department: [],
    date: [],
  });
  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
    (state) => state.team.list
  );

  useEffect(() => {
    const _department: { key: string; value: string }[] = searchParams.get(
      "_department"
    )
      ? JSON.parse(String(searchParams.get("_department")))
      : [];
    const type: { key: string; value: string }[] = searchParams.get("type")
      ? JSON.parse(String(searchParams.get("type")))
      : [];
    const frequency: { key: string; value: string }[] = searchParams.get(
      "frequency"
    )
      ? JSON.parse(String(searchParams.get("frequency")))
      : [];
    const status: { key: string; value: string }[] = searchParams.get("status")
      ? JSON.parse(String(searchParams.get("status")))
      : [];

    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];

    setState((prevState) => ({
      ...prevState,
      type,
      _department,
      frequency,
      status,
      date,
    }));
  }, [searchParams]);

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const handleRightListItem = (
    name: "type" | "frequency" | "_department" | "status",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];
    const isExist = !!state[name].find((ele) => ele.key === key);
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const handleDateListItem = (name: "date", key: string, value: string) => {
    const date = checkTimePeriod(key);

    const payload: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [
      {
        key,
        value,
        startDate: date.startDate,
        endDate: date.endDate,
      },
    ];

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let date: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [];

    if (state.date.length && period === "start") {
      date = state.date.map((e) => ({
        ...e,
        startDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (state.date.length && period === "end") {
      date = state.date.map((e) => ({
        ...e,
        endDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (!state.date.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59);
      date = [
        {
          key: "custom",
          value: "custom",
          startDate: dayjs(e).startOf("day").toISOString(),
          endDate: currentDate.toISOString(),
        },
      ];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      date = [
        {
          key: "custom",
          value: "custom",
          startDate: currentDate.toISOString(),
          endDate: dayjs(e).endOf("day").toISOString(),
        },
      ];
    }

    setState((prevState) => ({
      ...prevState,
      date,
    }));
  };

  const deleteChip = (
    name: "type" | "frequency" | "_department" | "status" | "date",
    key: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      type: [],
      frequency: [],
      _department: [],
      status: [],
      date: [],
    });
  };

  const onApply = () => {
    searchParams.set("type", JSON.stringify(state.type));
    searchParams.set("frequency", JSON.stringify(state.frequency));
    searchParams.set("_department", JSON.stringify(state._department));
    searchParams.set("status", JSON.stringify(state.status));
    searchParams.set("date", JSON.stringify(state.date));

    searchParams.set("page", "1");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    onClose();
  };

  const types = [
    { key: "one-time", value: "One Time" },
    { key: "recurring", value: "Recurring" },
  ];

  const frequencies = [
    { key: "daily", value: "Daily" },
    { key: "weekly", value: "Weekly" },
    { key: "monthly", value: "Monthly" },
    { key: "quarterly", value: "Quarterly" },
    { key: "yearly", value: "Yearly" },
  ];

  const status = [
    { key: "pending", value: "Pending" },
    { key: "in-progress", value: "In Progress" },
    { key: "completed", value: "Completed" },
  ];

  const dateOptions = [
    { key: "yesterday", value: "Yesterday" },
    { key: "today", value: "Today" },
    { key: "thisWeek", value: "Weekly" },
    { key: "thisMonth", value: "Monthly" },
    { key: "thisQuarter", value: "Quarterly" },
  ];

  const handleClose = () => {
    const type: { key: string; value: string }[] = searchParams.get("type")
      ? JSON.parse(String(searchParams.get("type")))
      : [];
    const frequency: { key: string; value: string }[] = searchParams.get(
      "frequency"
    )
      ? JSON.parse(String(searchParams.get("frequency")))
      : [];
    const _department: { key: string; value: string }[] = searchParams.get(
      "_department"
    )
      ? JSON.parse(String(searchParams.get("_department")))
      : [];
    const status: { key: string; value: string }[] = searchParams.get("status")
      ? JSON.parse(String(searchParams.get("status")))
      : [];

    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];

    setState((prevState) => ({
      ...prevState,
      type,
      frequency,
      _department,
      status,
      date,
    }));
    onClose();
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {state.type.length ||
              state.frequency.length ||
              state._department.length ||
              state.date.length ||
              state.status.length ? (
                <>
                  {state.type.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("type", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.frequency.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("frequency", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state._department.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("_department", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.status.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("status", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.date.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      icon={<CalendarMonthIcon />}
                      color="primary"
                      onDelete={() => deleteChip("date", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
              ) : (
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filters are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton onClick={handleClose} style={{ marginRight: "-10px" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid className="filter-box" container>
            <Grid id="left" item xs={5}>
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Type"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state.type}
                />
                <MenuItem
                  index={1}
                  label="Frequency"
                  selectedMenu={state.selectedMenu === 1}
                  onChange={handleLeftListItem}
                  count={state.frequency}
                />
                <MenuItem
                  index={2}
                  label="Department"
                  selectedMenu={state.selectedMenu === 2}
                  onChange={handleLeftListItem}
                  count={state._department}
                />
                <MenuItem
                  index={3}
                  label="Status"
                  selectedMenu={state.selectedMenu === 3}
                  onChange={handleLeftListItem}
                  count={state.status}
                />
                <MenuItem
                  index={4}
                  label="Date"
                  selectedMenu={state.selectedMenu === 4}
                  onChange={handleLeftListItem}
                  count={state.date}
                />
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid id="right" item xs={6}>
              <List component="nav">
                {state.selectedMenu === 0 &&
                  types.map((status) => (
                    <ListItemButton
                      key={status.key}
                      selected={
                        !!state.type.find((ele) => ele.key === status.key)
                      }
                      onClick={() =>
                        handleRightListItem("type", status.key, status.value)
                      }
                    >
                      <ListItemText primary={status.value} />
                      <Checkbox
                        edge="end"
                        checked={
                          !!state.type.find((ele) => ele.key === status.key)
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 1 &&
                  frequencies.map((i) => (
                    <ListItemButton
                      key={i.key}
                      selected={
                        !!state.frequency.find((ele) => ele.key === i.key)
                      }
                      onClick={() =>
                        handleRightListItem(
                          "frequency",
                          i.key,
                          capitalize(i.key)
                        )
                      }
                    >
                      <ListItemText primary={capitalize(i.key)} />
                      <Checkbox
                        edge="end"
                        checked={
                          !!state.frequency.find((ele) => ele.key === i.key)
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 2 &&
                  teams.map((status) => (
                    <ListItemButton
                      key={status._id}
                      selected={
                        !!state._department.find(
                          (ele) => ele.key === status._id
                        )
                      }
                      onClick={() =>
                        handleRightListItem(
                          "_department",
                          status._id,
                          status.name
                        )
                      }
                    >
                      <ListItemText primary={capitalize(status.name)} />
                      <Checkbox
                        edge="end"
                        checked={
                          !!state._department.find(
                            (ele) => ele.key === status._id
                          )
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 3 &&
                  status.map((status) => (
                    <ListItemButton
                      key={status.key}
                      selected={
                        !!state.status.find((ele) => ele.key === status.key)
                      }
                      onClick={() =>
                        handleRightListItem("status", status.key, status.value)
                      }
                    >
                      <ListItemText primary={status.value} />
                      <Checkbox
                        edge="end"
                        checked={
                          !!state.status.find((ele) => ele.key === status.key)
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 4 && (
                  <>
                    {dateOptions.map((date) => (
                      <ListItemButton
                        key={date.key}
                        selected={
                          !!state.date.find((ele) => ele.key === date.key)
                        }
                        onClick={() =>
                          handleDateListItem(
                            "date",
                            date.key,
                            capitalize(date.value)
                          )
                        }
                      >
                        <ListItemText primary={date.value} />
                        <Checkbox
                          edge="end"
                          checked={
                            !!state.date.find((ele) => ele.key === date.key)
                          }
                        />
                      </ListItemButton>
                    ))}
                    <Box marginTop={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.date[0]?.startDate
                              ? dayjs(state.date[0]?.startDate)
                              : null
                          }
                          onChange={(e) => handleDate(e, "start")}
                          label="Start Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                      <div className="mt-3" />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.date[0]?.endDate
                              ? dayjs(state.date[0]?.endDate)
                              : null
                          }
                          onChange={(e) => handleDate(e, "end")}
                          label="End Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}
              </List>
            </Grid>
          </Grid>

          <Box className="actions-btn" marginTop="8px" textAlign="end">
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button className="ml-2" onClick={onApply}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default OkrObjectiveFilters;
