import { FC, SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, Button, capitalize, Chip, Grid, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse, IKeyword, IUser } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import {IAuthor, IContentAuthorField } from "../../../../interfaces/content/author";
import AuthorService from "../../../../services/content/author";
import { authorValidation } from "../../../../validations/content/author";
import CustomLabel from "../../../../components/mui/custom-label";
import { IProjectData } from "../../../../interfaces/content/projects-products";
import CustomDialog from "../../../../components/mui/dialog";
import KeywordService from "../../../../services/content/keyword";
import { useSelector } from "react-redux";
import BrowserPreview from "../../../../components/boowser-preview";
import SeoSummary from "../../../../components/seo-summary";

interface IoutletProps {
  reFetch: () => void;
}

const AddCategory: FC = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<IoutletProps>();
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const hitQuery = id === "new" ? false : true;
  const [showBrowserPreview, setShowBrowserPreview] = useState(false);
  const [showSeoSummary, setShowSeoSummary] = useState(false);
  const { addContentAuthor, getContentAuthor, updateContentAuthor } = AuthorService();
  const { getContentKeywords } = KeywordService();
  const author = useQuery({
    queryKey: ["author-details", id],
    queryFn: () => getContentAuthor({ _id: id }),
    enabled: hitQuery,
  });
  const authorData = author && author.data && author.data.data;
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<IAuthor>({
    resolver: joiResolver(authorValidation),
    defaultValues: {
      _productId: "",
      _user: "",
      designation: "",
      about: "",
      slug: "",
      linkedin: "",
      image: "",
      metaTitle: "",
      metaDescription: "",
      _keywords: []
    },
  });

  const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
    (state) => state.cmsProduct.list
  ) || [];

  const keywordsData = useQuery({
    queryKey: ["keywordData"],
    queryFn: () =>
      getContentKeywords({ pagination: false }),
  });
  const keywords = keywordsData && keywordsData.data && keywordsData.data.data || [];
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(
    (state) => state.user.list
  );
  useEffect(() => {
    if (id !== "new") {
      if (author?.data?.data) {
        const data = author.data && author.data.data;
        setValue("_productId", data._productId && data._productId._id);
        setValue("_user", data._user && data._user && data._user._id);
        setValue("designation", data._user && data._user.designation && data._user.designation.name);
        setValue("about", data.about);
        setValue("slug", data.slug);
        setValue("linkedin", data._user && data._user.linkedinUrl);
        setValue("image", data._user && data._user.image);
        setValue("metaTitle", data.metaTitle);
        setValue("metaDescription", data.metaDescription);
        setValue("_keywords", data._keywords && data._keywords.map((item) => item._id));
      }
    }
  }, [author.data]);

  const onSubmit = async (data: IAuthor) => {
    try {
      if (id === "new") {
        const payload = { ...data };
        delete payload.image;
        delete payload.linkedin;
        delete payload.designation;
        const add = await addContentAuthor(payload);  
        snackbar(add.message, "info");
        navigate("/content/author");
        outlet?.reFetch && outlet?.reFetch();
      } else {
        const payload = { ...data, _id: id };
        delete payload.image;
        delete payload.linkedin;
        delete payload.designation;
        const update = await updateContentAuthor(payload);
        snackbar(update.message, "info");
        navigate({
          pathname: "/content/author",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet?.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };
  const fields: IContentAuthorField[] = [
    {
      label: "Product Name",
      name: "_productId",
      type: "auto-complete",
      placeholder: "Select product name",
      required: true,
    },
    {
      label: "Author Name",
      name: "_user",
      type: "auto-complete",
      placeholder: "Select author name",
      required: true,
    },
    {
      label: "Designation",
      name: "designation",
      type: "input",
      placeholder: "Type designation",
      required: true,
      disabled: true
    },
    {
      label: "Slug",
      name: "slug",
      type: "input",
      placeholder: "Type slug",
      required: true,
    },
    {
      label: "About",
      name: "about",
      type: "input",
      placeholder: "Type about",
      required: true,
    },
    {
      label: "Image",
      name: "image",
      type: "input",
      placeholder: "Type image",
      disabled: true,
      required: true,
    },
    {
      label: "Linkedin Profile",
      name: "linkedin",
      type: "input",
      placeholder: "Type linkedin",
      disabled: true
    },
    {
      label: "Keywords",
      name: "_keywords",
      type: "select",
      required: true,
    },
    {
      label: "Meta Title",
      name: "metaTitle",
      type: "input",
      placeholder: "Type meta title",
      required: true,
    },
    {
      label: "Meta Description",
      name: "metaDescription",
      type: "input",
      placeholder: "Type meta description",
      required: true,
    },
  ];

  const onClose = () => {
    navigate({
      pathname: "/content/author",
      search: searchParam.toString()
    });
  };

  const onChangeKeywordsAutocomplete = (
    event: SyntheticEvent<Element, Event>,
    value: IKeyword[],
    field: IContentAuthorField
  ) => {
    const data = getValues(field.name) as string[];
    const isExist = data && data.includes(value[0]._id);
    if (!isExist) {
      data && data.push(value[0]._id);
      setValue(field.name, data);
    }
    trigger(field.name);
  };

  return (
    <>
    <CustomDialog
    title={id !== "new" ? "Edit Author" : "Add Author"}
    isOpen={!!id}
    onClose={onClose}
    confirmText={id !== "new" ? "Edit Author" : "Add Author"}
    onSubmit={handleSubmit(onSubmit)}
  >
    <Grid container spacing={4}>
      {
        fields.map(field => {
          if (field.type === "input") {
            return (<Grid key={field.label} item xs={12} md={6}>
              <Controller
                control={control}
                name={field.name}
                render={(prop) =>
                  <TextField
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    className="disable-text"
                    variant={"outlined"}
                    size={"small"}
                    placeholder={field.placeholder}
                    error={!!errors[field.name]}
                    disabled={field.disabled}
                    helperText={errors[field.name]?.message}
                    {...prop.field}
                    multiline={field.name === "about"}
                    minRows={field.name === "about" ? 3 : 1}
                    maxRows={field.name === "about" ? 3 : 1}
                  />}
              />
            </Grid>
            );
          }else if (field.name === "_productId") {
            return (
              <Grid xs={12} md={6} key={field.label} item>
                <Controller
                  control={control}
                  name={field.name}
                  render={(prop) => <Autocomplete
                    className="disable-text"
                    options={productList.map( 
                      (data) => data
                    ) || []}
                    clearIcon={null}
                    getOptionLabel={(option) => capitalize(option.name)}
                    renderInput={(params) => <TextField
                      {...params}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label="Product" required={field?.required} />}
                      placeholder={field.placeholder}
                    />
                    }
                    {...prop.field}
                    value={productList.find(data => data._id === getValues(field.name)) || null}
                    onChange={(e, value) => {
                      setValue(field.name, value?._id || "");
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {capitalize(option.name)}
                      </li>
                    )}
                  />}
                />
              </Grid>
            );
          }else if (field.name === "_user") {
            return (
              <Grid xs={12} md={6} key={field.label} item>
                <Controller
                  control={control}
                  name={field.name}
                  render={(prop) => <Autocomplete
                    className="disable-text"
                    options={users.map(
                      (data) => data
                    ) || []}
                    clearIcon={null}
                    getOptionLabel={(option) => capitalize(option.name)}
                    renderInput={(params) => <TextField
                      {...params}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      placeholder={field.placeholder}
                    />
                    }
                    {...prop.field}
                    value={users.find(data => data._id === getValues(field.name)) || null}
                    onChange={(e, value) => {
                      setValue(field.name, value?._id || "");
                      setValue("designation", value?.designation.name || "");
                      setValue("linkedin", value && value.linkedinUrl || "");
                      setValue("image", value && value.image || "");
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {capitalize(option.name)}
                      </li>
                    )}
                  />}
                />
              </Grid>
            );
          }else {
            return (
              <Grid key={field.label} item xs={12} md={6}>
                <Controller
                  control={control}
                  name={field.name}
                  render={(prop) => (
                    <>
                      <Autocomplete
                        fullWidth
                        options={keywords.map(
                          (data) => data
                        ) || []}
                        getOptionLabel={(option) => capitalize(option.name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            placeholder={field.placeholder}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message}
                          />
                        )}
                        {...prop.field}
                        value={[]}
                        onChange={(e, value) =>
                          onChangeKeywordsAutocomplete(e, value, field)
                        }
                        disabled={field.disabled}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {capitalize(option.name)}
                          </li>
                        )}
                        multiple
                      />
                      {((getValues(field.name) as string[]) || []).map(
                        (item) => (
                          <Chip
                            key={item}
                            style={{ margin: "5px" }}
                            label={
                              keywords.find(
                                (data) => data._id === item
                              )?.name
                            }
                            color="primary"
                            onDelete={() => {
                              setValue(
                                field.name,
                                (getValues(field.name) as string[])?.filter(
                                  (value) => value !== item
                                )
                              );
                            }}
                            variant="outlined"
                          />
                        ))}
                    </>
                  )}
                />
              </Grid>
            );
          }
        })
      }
        </Grid>
      {
        id !== "new" && 
        <Grid container spacing={4} marginTop={0.5}>
          <Grid key={"browser-preview"} item xs={12} md={6}>
            <Button variant="outlined" fullWidth onClick={() => { setShowBrowserPreview(true); }}> Browser Preview</Button>
          </Grid>
          <Grid key={"browser-preview"} item xs={12} md={6}>
            <Button variant="outlined" fullWidth onClick={() => { setShowSeoSummary(true); }}> SEO Summary </Button>
          </Grid>
        </Grid>
      }

  </CustomDialog>
        {showBrowserPreview && <BrowserPreview onClose={() => { setShowBrowserPreview(false); }} metaTitle={authorData && authorData.metaTitle || ""} metaDescription={authorData && authorData.metaDescription || ""} keywords={authorData && authorData._keywords && authorData._keywords.map(data => data.name) || []} />}
        {showSeoSummary && <SeoSummary onClose={() => { setShowSeoSummary(false); }} metaTitle={authorData && authorData.metaTitle || ""} metaDescription={authorData && authorData.metaDescription || ""} words={authorData && authorData.about.length || 0}/>}
    </>
  );
};

export default AddCategory;
