import "./style.scss";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams, Outlet } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BootstrapDialog, BootstrapDialogTitle,  TabPanel, a11yProps } from "../../../components/shared/mui-tabs";
import DialogContent from "@mui/material/DialogContent";
import TimelineResourceRequest from "../timeline";
import { ResourceRequestedServices } from "../../../services/resource-request";
import { formatDate } from "@fullcalendar/core";
import { capitalize } from "../../../utilities/helper";
import ResourceRequestDetails from "../resource-request-details";
const redirectionLinks = ["basic-detail", "timeline"];
interface outletProps {
  reFetch: () => void
}

const ManageResourceLayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const outlet = useOutletContext<outletProps>();
  const [open, setOpen] = useState(!!id);
  const [value, setValue] = useState(0);
  const [searchParam, setSearchParam] = useSearchParams();
  const { getRequestedResource } = ResourceRequestedServices();
  const hitQuery = !["new", "view"].includes(id ?? "");
  const requestedResource = useQuery({ queryKey: [hitQuery], queryFn: () => getRequestedResource({ _id: id }), enabled: hitQuery });

  useEffect(() => {
    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
    setValue(redirectionLinks.indexOf(tab));
  }, [searchParam]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", redirectionLinks[newValue]);
    setSearchParam(searchParam);
  };

  const onClose = () => {
    setOpen((prev)=>!prev);
    navigate({ pathname: "/resource-requested/requests", search: searchParam.toString() });
};

const data = requestedResource &&
             requestedResource.data &&
             requestedResource.data.data; 

const name = data && capitalize(data.jobTitle?.name|| data.hardwareDetails?.name || data.softwareDetails?.name || "");

const hardwareName = data && data.hardwareDetails ? data.hardwareDetails.name : "";

const softwareName = data && data.softwareDetails ? data.softwareDetails.name : "";         

const submissionDate = data && data.submissionDate || "";

const requestedBy = data && data.requestedBy ? capitalize(data.requestedBy.name) : "";
                

return <div className="main-div">
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      open={open}
      className="dialog-div"
    >

    <BootstrapDialogTitle onClose={onClose}>
      <Box display="flex" flexDirection={"column"}>
        <span className="title">{name? name: hardwareName? hardwareName: softwareName? softwareName: ""} </span>
        <span className="submission-date">
          <span className="requested-by">Requested By: <span className="submitted-by">{capitalize(requestedBy)} on {formatDate(submissionDate)}</span></span>
        </span>
      </Box>
    </BootstrapDialogTitle>
    <DialogContent >
      <Box width="100%" height="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />
              <Tab className="tabs-space" label="Timeline" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <Divider light={true} />
  
        <TabPanel
          value={value}
          style={{height:"60vh", overflow: "auto"}}
          index={0}>
            <ResourceRequestDetails />
            </TabPanel>
        <TabPanel value={value} index={1} style={{height: "60vh"}} >
          <TimelineResourceRequest
            resource={requestedResource && requestedResource.data && requestedResource.data.data}
            />
        </TabPanel>

      </Box>
    </DialogContent>
  </BootstrapDialog> 
  <Outlet context={{...outlet}}/>
</div>;
};

export default ManageResourceLayout;
