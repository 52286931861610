import InlineDialog from "../../../../components/mui/inlineDialogue";
import { FC, useEffect } from "react";
import { Autocomplete, Box, capitalize, Grid, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import { IAddCategory } from "../../../../interfaces/content/category";
import { CategoryValidation } from "../../../../validations/content/category";
import { joiResolver } from "@hookform/resolvers/joi";
import CategoryService from "../../../../services/content/category";
import ProjectsService from "../../../../services/content/projects-products";
import CustomLabel from "../../../../components/mui/custom-label";
import { IProjectData } from "../../../../interfaces/content/projects-products";



interface IoutletProps {
  reFetch: () => void;
}

const AddCategory: FC = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<IoutletProps>();
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const { addContentCategory, getContentCategory, updateContentCategory } = CategoryService();
  const { getContentProjects } = ProjectsService();
  const hitQuery = id === "new" ? false : true;
  const category = useQuery({
    queryKey: ["category-details", id],
    queryFn: () => getContentCategory({ _id: id }),
    enabled: hitQuery,
  });

  const projectList = useQuery({
    queryKey: ["projectList"],
    queryFn: async () => {
      try {
        const data = await getContentProjects({isActive: true});
        return data;
      } catch (error) {
        const err = error as IErrorResponse;
        console.error("Error fetching project list:", error);
        snackbar(err.data.message, "error");
      }
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<IAddCategory>({
    resolver: joiResolver(CategoryValidation),
    defaultValues: {
      name: "",
      _productId: ""
    },
  });

  useEffect(() => {
    if (id !== "new") {
      if (category?.data?.data) {
        const categoryData = category?.data?.data;
        setValue("_productId",categoryData && categoryData._productId && categoryData._productId || "");
        trigger("_productId");
        setValue("name", categoryData && categoryData.name || "");
        trigger("name");
      }
    }
  }, [category.data]);


  const onSubmit = async (data: IAddCategory) => {
    try {
      if (id === "new") {
        const add = await addContentCategory(data);
        snackbar(add.message, "info");
        navigate("/content/category");
        outlet?.reFetch && outlet?.reFetch();
      } else {
        const update = await updateContentCategory({ ...data, _id: id });
        snackbar(update.message, "info");
        navigate({
          pathname: "/content/category",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet?.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const handleProductChange = async (value: IProjectData | null) => {
    if (value) {
      setValue("_productId", value._id);
      trigger("_productId");
    }
  };

  return (
    <Box>
      <InlineDialog
        onClose={() => navigate("/content/category")}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"_productId"}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={projectList?.data?.data?.map(
                  (data) => data
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option.name)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["_productId"] ? true : false}
                  helperText={errors["_productId"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Product*" />}
                  placeholder={"Please Select Product"}
                />}
                {...prop.field}
                value={projectList?.data?.data?.find(data => data._id === getValues("_productId")) || null}
                onChange={(e, value) => {
                  handleProductChange(value);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {capitalize(option.name)}
                  </li>
                )}
              />}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={"name"}
              control={control}
              render={(prop) => <TextField
                placeholder={"Type Category Here"}
                label={<CustomLabel label={"Category"} required={true} />}
                error={!!errors["name"]}
                helperText={errors["name"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
        </Grid>
      </InlineDialog>
    </Box>
  );
};

export default AddCategory;
