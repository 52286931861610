import { Box, Button, DialogActions, DialogContent, Divider, FormControlLabel, Switch, Typography } from "@mui/material";
import { FC, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { BootstrapDialog, BootstrapDialogTitle } from "../shared/mui-tabs";
interface props {
    onClose: () => void;
    metaTitle: string;
    metaDescription: string;
    keywords: string[];
}

const BrowserPreview: FC<props> = ({ onClose, metaTitle, metaDescription, keywords }) => {
    const [isMobileView, setIsMobileView] = useState(false);

    const handleToggleChange = () => {
        setIsMobileView((prev) => !prev);
    };

    const countKeywords = (text: string, keywordsArray: string[]) => {
        const matches: string[] = [];
        keywordsArray.forEach((keyword) => {
            const regex = new RegExp(`\\b${keyword}\\b`, "g");
            const match = text.match(regex);
            if (match) {
                matches.push(keyword);
            }
        });
        return matches;
    };

    const titleKeywords = countKeywords(metaTitle, keywords);
    const descriptionKeywords = countKeywords(metaDescription, keywords);

    const data = [
        {
            status: metaTitle.length <= 60 ? "success" : "error",
            text: `Your title is ${metaTitle.length} characters long (max 60)`
        },
        {
            status: metaDescription.length <= 160 ? "success" : "error",
            text: `Your description is ${metaDescription.length} characters long (max 160)`
        },
        {
            status: titleKeywords.length > 0 ? "success" : "error",
            text: `You ${
                titleKeywords.length > 0 ? `use ${titleKeywords.length} keyword(s)` : "don't use any keywords"
            } in your title (${titleKeywords.join(", ")})`
        },
        {
            status: descriptionKeywords.length > 0 ? "success" : "error",
            text: `You ${
                descriptionKeywords.length > 0
                    ? `use ${descriptionKeywords.length} keyword(s)`
                    : "don't use any keywords"
            } in your description (${descriptionKeywords.join(", ")})`
        }
    ];

    return (
        <BootstrapDialog
            maxWidth="md"
            onClose={onClose}
            open={true}
            className="preview-container"
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    SEO Plugin
                </Box>
            </BootstrapDialogTitle>

            <DialogContent dividers>
                <Box mt={1}>
                    <Typography gutterBottom variant="h5" component="div">
                        Browser Preview
                    </Typography>
                </Box>

                <Divider orientation="horizontal" />

                <Box className="toggle-box mt-2 p-2">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isMobileView}
                                onChange={handleToggleChange}
                                color="primary"
                            />
                        }
                        label={isMobileView ? "Mobile" : "Web"}
                    />
                </Box>

                <Box p={2}>
                    {isMobileView ? (
                        <Box>
                            <Box pl={2}>
                                <Typography variant="body1" gutterBottom>
                                    {metaTitle}
                                </Typography>
                                <Typography variant="body2" color="primary" gutterBottom>
                                    https://url-of-your-website.io
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {metaDescription}
                                </Typography>
                            </Box>

                            <Box mt={2}>
                                {data.map((item, index) => (
                                    <Box display="flex" alignItems="center" mb={1} key={index}>
                                        {item.status === "success" ? (
                                            <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        ) : (
                                            <ErrorIcon color="error" sx={{ mr: 1 }} />
                                        )}
                                        <Typography variant="body1">{item.text}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                {metaTitle}
                            </Typography>
                            <Typography variant="body2" color="primary" gutterBottom>
                                https://url-of-your-website.io
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {metaDescription}
                            </Typography>
                            <Box mt={2}>
                                {data.map((item, index) => (
                                    <Box display="flex" alignItems="center" mb={1} key={index}>
                                        {item.status === "success" ? (
                                            <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        ) : (
                                            <ErrorIcon color="error" sx={{ mr: 1 }} />
                                        )}
                                        <Typography variant="body1">{item.text}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>

            </DialogContent>
            <DialogActions className="dialog-footer">
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>

        </BootstrapDialog>
    );

};

export default BrowserPreview;