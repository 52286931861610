import Joi from "joi";
import { required } from "../../shared";

export const myProfileValidation = Joi.object({
  firstName: Joi.string()
    .max(30)
    .trim()
    .label("First Name")
    .required()
    .messages({
      "string.empty": required,
    }),  
    lastName: Joi.string()
    .max(30)
    .trim()
    .required()
    .label("Last Name"),
  role: Joi.string()
    .max(30)
    .trim()
    .allow("")
    .label("Authority"),
  department: Joi.string()
    .max(30)
    .trim()
    .allow("")
    .label("Teams"),
  number: Joi.string()
    .required()
    .min(10)
    .max(10)
    .label("Primary Contact Number")
    .messages({
      "string.empty": required
    }),
  gender: Joi.string()
    .required()
    .valid("MALE", "FEMALE")
    .label("Gender")
    .messages({
      "string.empty": required,
    }),
  dob: Joi.string()
    .allow("")
    .optional()
    .label("Date of Birth"),
  linkedinUrl: Joi.string()
    .allow("")
    .optional()
    .uri()
    .label("LinkedIn URL"),
  address: Joi
    .string()
    .allow("")
    .optional()
    .label("Address"),
  image: Joi.string()
  .required()
  .label("Image")
  .messages({
    "string.empty": required,
  }),
  extensionPhoneNumber: Joi.string()
    .optional()
    .allow("")
    .label("Extension Phone Number")
    .when(Joi.string().min(1), {
        then: Joi.string().min(8),
    }),
});
