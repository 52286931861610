import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { RoleService } from "../../../../services";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControl, FormHelperText, Grid, MenuItem, TextField, Typography, Select, capitalize } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IDataSet, IErrorResponse, IRoleField, IRoleForm, ITeam } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { roleValidation } from "../../../../validations";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import CustomTable from "../../../../components/mui/table";
import {  getDisplayLabel, mergeMultipleJson } from "../../../../utilities/helper";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Header from "../../../../components/header";
import CustomLabel from "../../../../components/mui/custom-label";
import analyticsRole from "../../../../assets/json/resources/analytics/resource.json";
import organisationRole from "../../../../assets/json/resources/organisation/resource.json";
import skillDevelopmentTrainingRole from "../../../../assets/json/resources/skill-development-training/resource.json";
import candidateAndCareerRole from "../../../../assets/json/resources/candidate-career-management/resource.json";
import cmsRole from "../../../../assets/json/resources/cms/resource.json";
import configurationRole from "../../../../assets/json/resources/configuration/resource.json";
import assessmentPermissions from "../../../../assets/json/resources/assessment/resource.json";
import dashboardRole from "../../../../assets/json/resources/dashboard/resource.json";
import directoryRole from "../../../../assets/json/resources/directory/resource.json";
import pmsRole from "../../../../assets/json/resources/pms/resource.json";
import okrRole from "../../../../assets/json/resources/okr/resource.json";
import resourcePlanningRole from "../../../../assets/json/resources/resource-planning/resource.json";
import skillMatrixRole from "../../../../assets/json/resources/skill-matrix/resource.json";
import TrashRole from "../../../../assets/json/resources/trash/resource.json";
import { useSelector } from "react-redux";
import SearchSelect from "../../../../components/mui/search-select";
import "./style.scss";


interface outletProps {
    reFetch: () => void
}

interface IResource {
    name: string;
    read?: string | string[];
    module: string;
    write?: string | string[];
    remove?: string | string[];
    edit?: string | string[];
    title?: string;
}

interface IState {
    resources: string[];
}

const ManageRole = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const [search, setSearch] = useState("");
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const navigate = useNavigate();
    const { getRole, addRole, updateRole } = RoleService();
    const combineResource = mergeMultipleJson(
      analyticsRole,
      candidateAndCareerRole,
      cmsRole,
      configurationRole,
      dashboardRole,
      directoryRole,
      pmsRole,
      resourcePlanningRole,
      skillMatrixRole,
      TrashRole,
      organisationRole,
      skillDevelopmentTrainingRole,
      assessmentPermissions,
      okrRole
    );
    const roleResources = combineResource.map( resource => resource.data).flat();
    const searchedResourceRaw = search.length ? combineResource
    .map(combineResource => ({
        name: combineResource.name,
        data: combineResource.data.filter(resource => resource.name.toLowerCase().includes(search.toLowerCase()))
    }))
    .filter(dataset => dataset.data.length > 0): combineResource;


    const searchedResource = searchedResourceRaw.map(dataset => ({
        name: dataset.name,
        data: dataset.data.length > 0 ? dataset.data : dataset.data
    }));

    const team = useQuery({ queryKey: [hitQuery], queryFn: () => getRole({ _id: id }), enabled: hitQuery });
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
        (state) => state.team.list
    );
    const { handleSubmit, control, reset, setValue, trigger, getValues, formState: { errors } } = useForm<IRoleForm>({
        resolver: joiResolver(roleValidation),
        defaultValues: {
            name: "",
            description: "",
            _department: ""
        }
    });
    const [state, setState] = useState<IState>({
        resources: []
    });
    const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (id !== "new") {
            if (team.data?.data) {
                setValue("name", capitalize(team.data.data?.name ?? ""));
                setValue("description", capitalize(team.data.data?.description ?? ""));
                setValue("_department", team.data.data?._department?._id ?? "");
                trigger("_department");
                setState(prevState => ({
                    ...prevState,
                    resources: team.data.data?.resources
                }));

                setValue("resources", team.data.data?.resources);
            }
        }
    }, [id, team.data]);  

    const handleResource = (checked: boolean, resources: Array<string | undefined>) => {
        const filterResources: string[] = [];
        let stateResources: string[] = [];
        if (checked) {
            resources.map(v => v && filterResources.push(v));
            stateResources = [...state.resources, ...filterResources];

            if (resources.includes("lead.write")) {
                if (!stateResources.includes("job.read")) {
                    stateResources.push("job.read");
                }
                if (!stateResources.includes("college-recruitment.read")) {
                    stateResources.push("college-recruitment.read");
                }
                if (!stateResources.includes("college.read")) {
                    stateResources.push("college.read");
                }
                if (!stateResources.includes("user.read")) {
                    stateResources.push("user.read");
                }
            }
            if(resources.includes("cms-blog-analytics.read")){
                if (!stateResources.includes("cms-product-title.read")) {
                    stateResources.push("cms-product-title.read");
                }
            }

            stateResources = [...new Set(stateResources)];
            setState(prevState => ({
                ...prevState,
                resources: stateResources
            }));
        } else {
            resources.map(v => v && filterResources.push(v));
            stateResources = [...state.resources];
            stateResources = stateResources.filter(resource => !filterResources.includes(resource));

            setState(prevState => ({
                ...prevState,
                resources: stateResources
            }));
        }

        setValue("resources", stateResources);
    };

    const onSubmit = async (data: IRoleForm) => {
    // Create a copy of the current resources
    let resources: string[] = [...state.resources];
        const checkPermission = (permission: string | string[] | undefined) => {
            if (typeof permission === "string") {
                return resources.includes(permission);
            }
            return Array.isArray(permission) && permission.some(p => resources.includes(p));
        };
        
        roleResources.forEach(roleResource => {
            const isRead = checkPermission(roleResource.read);
            const isWrite = checkPermission(roleResource.write);
            const isEdit = checkPermission(roleResource.edit);
            const isRemove = checkPermission(roleResource.remove);
        
            if (isRead || isWrite || isEdit || isRemove) {
                resources.push(roleResource.module);
            }
        });

    // Filter out role modules that should not be included
    const roleModules = roleResources.filter(roleResource => {
        const isRead = typeof roleResource.read === "string" ? resources.includes(roleResource.read) : roleResource.read?.some(r => resources.includes(r));
        const isWrite = typeof roleResource.write === "string" ? resources.includes(roleResource.write) : roleResource.write?.some(w => resources.includes(w));
        const isEdit = typeof roleResource.edit === "string" ? resources.includes(roleResource.edit) : roleResource.edit?.some(e => resources.includes(e));
        const isRemove = typeof roleResource.remove === "string" ? resources.includes(roleResource.remove) : roleResource.remove?.some(r => resources.includes(r));

        return !isRead && !isWrite && !isEdit && !isRemove && resources.includes(roleResource.module);
    });

    roleModules.forEach(roleResource => {
        const index = resources.indexOf(roleResource.module);
        if (index > -1) {
            resources.splice(index, 1);
        }
    });

    // Ensure unique resources
    resources = [...new Set(resources)];
    const payload = { ...data, resources };

    try {
        if (id === "new") {
            const add = await addRole(payload);
            snackbar(add.message, "info");
        } else {
            const update = await updateRole({ ...payload, _id: id });
            snackbar(update.message, "info");
        }

        // Navigate and refresh
        navigate({
            pathname: "/configurations/roles",
            search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
        
        // Reset form and clear resources
        reset();
        setState(prev => ({ ...prev, resources: [] }));
    } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err.data?.message || "An error occurred", "warning");
        console.error(error);
    }
};

    const onClose = () => {
        navigate({
            pathname: "/configurations/roles",
            search: searchParam.toString()
        });
    };
    
    const fields: IRoleField[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type role name here",
            required: true
        },
        {
            label: "Department",
            name: "_department",
            type: "select",
            required: true,
            options: teams.map((i) => ({
                key: i._id,
                value: capitalize(i.name)
            })),
            displayFieldKey: "value",
            storeFieldKey: "key",
        },
        {
            label: "Description",
            name: "description",
            type: "multiline",
            placeholder: "Type role description here",
            required: true
        },
    ];

    const columns = [
        {
            id: "title",
            label: "Title"
        },
        {
            id: "all",
            label: "All"
        },
        {
            id: "read",
            label: "Read"
        },
        {
            id: "write",
            label: "Write"
        },
        {
            id: "edit",
            label: "Edit"
        },
        {
            id: "remove",
            label: "Delete"
        },
    ];

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>, field: IRoleField) => {
      const input = event.target as HTMLInputElement;
      const inputValue = input.value;
      const isNameField = field.name === "name";
      const isDescriptionField = field.name === "description";
      let maxLength;

      if (event.key === "Enter" || (event.key === " " && inputValue.length === 0)) {
        event.preventDefault();
      }
    
      if (isNameField) {
        maxLength = 30;
      } else if (isDescriptionField) {
        maxLength = 100;
      } else {
        maxLength = 30;
      }
    
      if (inputValue.length >= maxLength && event.key !== "Backspace" && event.key !== "Delete") {
        event.preventDefault();
      }
    };

    const updateResources = (selectedValue: string | null, permission: string[]) => {
        const updatedResources: string[] = state.resources.filter(r => !permission.includes(r));
    
        if (selectedValue) {
            updatedResources.push(selectedValue);
    
            // Handle additional logic based on the selected value
            if (selectedValue === "lead.write") {
                const dependencies = [
                    "job.read",
                    "college-recruitment.read",
                    "college.read",
                    "user.read"
                ];
                dependencies.forEach(dep => {
                    if (!updatedResources.includes(dep)) {
                        updatedResources.push(dep);
                    }
                });
            }
    
            if (selectedValue === "cms-blog-analytics.read") {
                if (!updatedResources.includes("cms-product-title.read")) {
                    updatedResources.push("cms-product-title.read");
                }
            }
        }
        setState(prevState => ({
            ...prevState,
            resources: [...new Set(updatedResources)] // Ensure no duplicates
        }));
        setValue("resources", updatedResources);
    };
  const createRow = (resource: IResource) => {
        const renderPermission = (permission: string | string[] | undefined ) => {
            if (permission && Array.isArray(permission) && permission.length > 1) {
                return (
                    <FormControl variant="outlined" sx={{ minWidth: 120, maxWidth: 120 }}>
                        <Select
                           value={state.resources.find(r => 
                            permission.some(perm => r === perm)
                        ) || ""}
                            onChange={(e) => {
                                updateResources(e.target.value, permission as string[]);
                            }}
                            displayEmpty
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {permission.map((perm) => {
                                const displayLabel = perm.split(".")[1]; // Get the second part of the permission
                                return (
                                    <MenuItem key={perm} value={perm}>
                                        {getDisplayLabel(displayLabel)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                );
            } else {
                return (
                    <Checkbox
                        onChange={(e) => handleResource(e.target.checked, [permission as string || undefined])}
                        disabled={!permission}
                        checked={state.resources.includes(permission as string || "")}
                    />
                );
            }
        };
    
        return {
            title: resource.name,
            all: (
                <Checkbox
                    onChange={(e, checked) => {
                        const permissions = [
                            typeof resource.read === "string" ? resource.read : (resource.read?.[0] || undefined),
                            typeof resource.write === "string" ? resource.write : (resource.write?.[0] || undefined),
                            typeof resource.edit === "string" ? resource.edit : (resource.edit?.[0] || undefined),
                            typeof resource.remove === "string" ? resource.remove : (resource.remove?.[0] || undefined)
                        ];
                        handleResource(checked, permissions);
                    }}
                    checked={
                        (typeof resource.read === "string" ? state.resources.includes(resource.read) : (resource.read?.some(r => state.resources.includes(r)))) &&
                        (typeof resource.write === "string" ? state.resources.includes(resource.write) : (resource.write?.some(w => state.resources.includes(w)))) &&
                        (typeof resource.edit === "string" ? state.resources.includes(resource.edit) : (resource.edit?.some(e => state.resources.includes(e)))) &&
                        (typeof resource.remove === "string" ? state.resources.includes(resource.remove) : (resource.remove?.some(r => state.resources.includes(r))))
                    }
                />
            ),
            read: renderPermission(resource.read),
            write: renderPermission(resource.write),
            edit: renderPermission(resource.edit),
            remove: renderPermission(resource.remove),
        };
    };
    


    return (
      <Box >
        <CustomDialog
          size="xl"
          title={id !== "new" ? "Edit Role" : "Add Role"}
          isOpen={!!id}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
         >
          <Grid container spacing={4} className="form-fields">
            {fields.map((field) => {
              if (field.type === "input") {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={<CustomLabel label={field.label} required={field.required}/>}
                          placeholder={field.placeholder}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                          onKeyDown={(event) => handleKeyDown(event, field)}
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                );
              } else if (field.type === "multiline") {
                return (<Grid key={field.label} item xs={12}>
                    <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => <TextField
                            {...prop.field}
                            label=
                            {
                                <CustomLabel
                                    label={field.label}
                                    required={field.required}
                                />
                            }
                            placeholder={field.placeholder}
                            className="disable-text"
                            variant="outlined"
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message}
                            multiline
                            minRows={3}
                        />}
                  />
                </Grid>
                );
              } else if (field.type === "select") {
                return (<Grid key={field.label} item xs={6}>
                    <SearchSelect
                        name={field.name}
                        label={<CustomLabel label={field.label} required={field.required} />}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        options={field.options}
                        displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                        storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                        displayUserName={field.displayUserName}
                        capitalize={field.capitalize}
                        trigger={trigger}
                        setValue={setValue}
                        getValues={getValues}
                    />
                </Grid>
                );
              }
            })}
          </Grid>

          <Box mt={2} mb={1}>     
          <Header
            searchPlaceholder="Search by role title"
            onSearch={onSearch}
            preventEnterPress={true}
          />
          </Box> 
            <div className="accordian-table-container">
            {errors?.resources && (
                        <FormHelperText
                          error={!!errors?.resources}
                        >
                          {errors?.resources?.message}
                        </FormHelperText>
                      )}

          {searchedResource.map((item: IDataSet, index: number) => (
            <Accordion
              className="accordion"
              key={index}
              expanded={expandedPanel === item.name}
              onChange={handleAccordionChange(item.name)}
            >
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <Typography>{item.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box overflow="auto">
                    <Box>
                      <CustomTable
                        columns={columns}
                        rows={item.data.map((item: IResource) =>
                          createRow(item)
                        )}
                        maxHeight="calc(100vh - 400px)"
                        width="calc(100% - 2px)"
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}

          </div>
        </CustomDialog>
      </Box>
    );
};

export default ManageRole;