import { FC } from "react";
import { Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import { skillItemCategoryValidation } from "../../../../validations/skill-matrix/skill-item-category";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";
import { ISkillItemCategory, ISkillItemCategoryFields } from "../../../../interfaces/skill-matrix/add-skill-matrix";
import { IErrorResponse } from "../../../../interfaces";
import CustomDialog from "../../../../components/mui/dialog";
import CustomLabel from "../../../../components/mui/custom-label";

interface outletProps {
    reFetch: () => void;
    refetchSkillItemCategories: () => void;
    reFetchSkillItemData: () => void;
    reFetchUserSkills: () => void;
}

const ManageSkillMatrixCategory: FC = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const navigate = useNavigate();
    const { addSkillItemCategory } = SkillItemCategoryService();
    const { handleSubmit, control, formState: { errors } } = useForm<ISkillItemCategory>({
        resolver: joiResolver(skillItemCategoryValidation),
        defaultValues: {
            name: "",
        }
    });

    const onSubmit = async (data: ISkillItemCategory) => {
        try {
            const add = await addSkillItemCategory({ name: data.name, _skillParameter: id});
            snackbar(add.message, "info");
            navigate({
                pathname: `/skill-matrix/manage/${id}`,
                search: searchParam.toString()
            });
            outlet?.reFetch && outlet.reFetch();
            outlet?.reFetchSkillItemData && outlet.reFetchSkillItemData();
            outlet?.reFetchUserSkills && outlet.reFetchUserSkills();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }
    };

    const onClose = () => {
        navigate({
            pathname: `/skill-matrix/manage/${id}`,
            search: searchParam.toString()
        });
    };

    const fields: ISkillItemCategoryFields[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type your category name here",
            required: true
        }
    ];

    return (
        <CustomDialog
        size="sm"
        title={"Add Skill Category"}
        isOpen={!!id}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            inputProps={{maxLength: 60}}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
                </CustomDialog>                    
    );
};

export default ManageSkillMatrixCategory;