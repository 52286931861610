import HttpService from "../../http";
import { glossaryAssignment } from "../../endpoints";
import { IGlossaryAssignmentResponse } from "../../../interfaces/content-marketing/glossary";
const GlossaryAssignmentService = () => {
    const { httpRequest } = HttpService();

    const updateGlossaryAssignment = async (payload: object): Promise<IGlossaryAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryAssignmentResponse>(
                "PUT",
                `${glossaryAssignment}`, 
                payload
            )
                .then(resolve)
                .catch(reject);
        });

        const bulkGlossaryAssignment = async (payload: object): Promise<IGlossaryAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryAssignmentResponse>(
                "POST", 
                `${glossaryAssignment}/bulk-assign`, 
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    const getGlossaryAssignment = async (query: object): Promise<IGlossaryAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryAssignmentResponse>(
                "GET", 
                `${glossaryAssignment}`, 
                {}, 
                query
            )
                .then(resolve)
                .catch(reject);
        });
    return { getGlossaryAssignment, updateGlossaryAssignment, bulkGlossaryAssignment };

};

export { GlossaryAssignmentService };