import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Grid, TextField } from "@mui/material";
import { IErrorResponse } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import CustomLabel from "../../../../components/mui/custom-label";
import { OkrRatingsService } from "../../../../services/okr";
import { okrRatingValidation } from "../../../../validations/okr";
import { IOKRRating, IOKRRatingsFields } from "../../../../interfaces/okr/ratings";
import { OKR_ERROR_MESSAGES } from "../../../../utilities/messages";
interface outletProps {
  reFetch: () => void;
  refetchRatings: () => void;
}

const ManageOKRRatings = () => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const [searchParam] = useSearchParams();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { addRating, getRating, updateRating } =
    OkrRatingsService();
  const rating = useQuery({
    queryKey: [id],
    queryFn: () => getRating({ _id: id }),
    enabled: id !== "new",
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IOKRRating>({
    resolver: joiResolver(okrRatingValidation),
    defaultValues: {
      scale: 0,
      description: "",
      remark: "",
      minRange: 0,
      maxRange: 0
    },
  });

  useEffect(() => {
    if (id !== "new" && rating.data?.data) {
        const data = rating.data.data;
        setValue("scale", data.scale);
        setValue("remark", data.remark);
        setValue("minRange", data.minRange);
        setValue("maxRange", data.maxRange);
        setValue("description", data.description);
    }
  }, [id, rating.data]);

  const onClose = () => {
    navigate({
      pathname: "/okr-ratings",
      search: searchParam.toString(),
    });
  };

  const onSubmit = async (data: IOKRRating) => {
    try {
      if(data.minRange > data.maxRange){
        snackbar(OKR_ERROR_MESSAGES.en.invalid_progress, "warning");
        return;
      }
      if (id === "new") {
        const add = await addRating(data);
        snackbar(add.message, "info");
      } else {
        const payload = { ...data, _id: id };
        const update = await updateRating(payload);
        snackbar(update.message, "info");
      }
      outlet?.reFetch && outlet.reFetch();
      outlet?.refetchRatings && outlet.refetchRatings();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const fields: IOKRRatingsFields[] = [
    {
      label: "Rating",
      name: "scale",
      type: "input",
      placeholder: "Type rating here",
      required: true,
    },
    {
      label: "Remark",
      name: "remark",
      type: "input",
      placeholder: "Type rating remark here",
      required: true,
    },
    {
      label: "Minimum Progress",
      name: "minRange",
      type: "input",
      placeholder: "Type minimum progress here",
      required: true,
    },
    {
      label: "Maximum Progress",
      name: "maxRange",
      type: "input",
      placeholder: "Type maximum progress here",
      required: true,
    },
    {
      label: "Description",
      name: "description",
      type: "input",
      placeholder: "Type rating description here",
      required: true,
    },
  ];

  return (
    <CustomDialog
      size="md"
      title={id !== "new" ? "Edit Rating" : "Add Rating"}
      confirmText={id !== "new" ? "Edit Rating" : "Add Rating"}
      isOpen={!!id}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        {fields.map((field) => {
          if (field.type === "input") {
            return (
              <Grid key={field.label} item xs={field.name === "description" ? 12 : 3}>
                <Controller
                  control={control}
                  name={field.name}
                  render={(prop) => (
                    <TextField
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field.required}
                        />
                      }
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      multiline={field.name === "description"}
                      minRows={field.name === "description" ? 3 : 1}
                      maxRows={field.name === "description" ? 3 : 1}
                      type={field.name === "description" || field.name === "remark" ? "text" : "number"}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let value = e.target.value;
                        if (field.name === "scale") {
                          value = value.replace(/[^0-9]/g, "");
                          if (value !== "" && Number(value) > 10) {
                            e.target.value = value.slice(0, -1); 
                            return;
                          }
                        } else if (["minRange", "maxRange"].includes(field.name)) {
                          value = value.replace(/[^0-9]/g, "");
                          if (value !== "" && Number(value) > 100) {
                            e.target.value = value.slice(0, -1); 
                            return;
                          }
                        }
                        prop.field.onChange(value); 
                      }}
                      inputProps={{
                        onWheel: (e) => {
                          (e.target as HTMLElement).blur();
                          e.preventDefault();
                        },
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if(field.name !== "remark" && field.name !== "description") {
                          if (["e", "E", "-"].includes(e.key)) {
                            e.preventDefault(); 
                          }
                        }
                      }}
                      {...prop.field}
                    />
                  )}
                />
              </Grid>
            );
          } 
        })}
      </Grid>
    </CustomDialog>
  );
};

export default ManageOKRRatings;
