import Joi from "joi";
import { required } from "../../shared";

export const okrRatingValidation = Joi.object({
  scale: Joi.number()
    .min(0)
    .max(10)
    .required()
    .label("Rating Scale")
    .messages({
        "string.empty": required,
    }),
  description: Joi.string()
    .min(3)
    .trim()
    .required()
    .label("Description")
    .messages({
        "string.empty": required,
    }),
  remark: Joi.string()
    .required()
    .min(3)
    .max(30)
    .trim()
    .label("Remark")
    .messages({
      "string.empty": required,
    }),
  minRange: Joi.number()
    .required()
    .label("Minimum Range")
    .messages({
        "string.empty": required,
    }),
  maxRange: Joi.number()
    .required()
    .label("Maximum Range")
    .messages({
        "string.empty": required,
    }),
});
