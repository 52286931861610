import { useState, useEffect, ChangeEvent } from "react";
import { capitalize, createIndex } from "../../../utilities/helper";
import { Box } from "@mui/material";
import useSnackbar from "../../../hooks/useSnackbar";
import { Outlet, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import WarningDialog from "../../../components/mui/warning-dialog";
import { useQuery } from "@tanstack/react-query";
import GetActions from "../../../components/get-actions";
import { IErrorResponse, IPagination } from "../../../interfaces";
import { ICustomUrlData, ICustomUrlRow, ICustomUrlState } from "../../../interfaces/site-map/custom-urls";
import { CustomUrlService } from "../../../services/sitemap/custom-url";

interface outletProps {
  refetchCustomUrl: () => void;
}

const SiteMapCustomUrl = () => {
  let rows: ICustomUrlRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getCustomUrls, deleteCustomUrl } = CustomUrlService();
  const [state, setState] = useState<ICustomUrlState>({
    deleteWarning: false,
    _customUrl: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    }
  });

  const customUrl = useQuery({
    queryKey: ["allCustomUrls", state.pagination.page],
    queryFn: () =>
        getCustomUrls({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page
      }),
  });

  useEffect(() => {
    if (customUrl.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: customUrl.data.meta.page,
          totalPages: customUrl.data.meta.totalPages,
          totalRecords: customUrl.data.meta.totalRecords,
        },
      }));
    }
  }, [customUrl.data?.meta]);

  useEffect(() => {
    if (customUrl.data && customUrl.data.data && customUrl.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [customUrl.data && customUrl.data.data]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleDelete = (_customUrl = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _customUrl,
    }));
  };

  const onDelete = async () => {
    try {
      const category = await deleteCustomUrl({ _id: state._customUrl });
      snackbar(category.message, "info");
      handleDelete();
      customUrl.refetch();
      outlet?.refetchCustomUrl && outlet.refetchCustomUrl();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };


  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "product",
      label: "Product"
    },
    {
      id: "slug",
      label: "Slug",
    },
    {
      id: "priority",
      label: "Priority"
    },
    {
      id: "changeFrequency",
      label: "Change Frequency"
    },
    {
      id: "action",
      label: "Actions",
    }
  ];


  const createRow = (index: number, customUrl: ICustomUrlData, pagination: IPagination) => {
    const action = 
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({ pathname:`${customUrl._id}`, search: searchParams.toString() }),
            disabled: !resourceAllocate("cms-custom-url.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(customUrl._id),
            disabled: !resourceAllocate("cms-custom-url.remove"),
          },
        ]}
      />;

    return {
      id: createIndex(pagination, index),
      product: capitalize(customUrl._product.name),
      slug: customUrl.slug,
      priority: (customUrl.priority).toString(),
      changeFrequency: capitalize(customUrl.changeFrequency),
      action,
    };
  };

  if (customUrl && customUrl.data && customUrl.data.data && customUrl.data.data.length) {
    rows = customUrl.data.data.map((customUrl, i) =>
      createRow(i, customUrl, state.pagination)
    );
  }

  return (
    <>
      <Header
        className="my-2"
        btnText="Add Custom Url"
        onBtnClick={
          resourceAllocate("cms-custom-url.write") ? () => navigate("new") : undefined
        }
        onImport={resourceAllocate("cms-custom-url.write") ? () => navigate({ pathname: "import", search: searchParams.toString() }) : undefined}
      />

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="Add  custom url to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Url Bundle"
        description="Are you sure you want to delete this custom url?"
      />
      
      <Outlet context={{ ...outlet, reFetch: customUrl.refetch }} />
    </>
  );
};

export default SiteMapCustomUrl;