import Joi from "joi";
import { required } from "../../../shared";

export const selfAssessmentValidation = Joi.object({
  _id: Joi.string().allow(""),
  _keyResult: Joi.string()
    .required()
    .label("Key Result")
    .messages({
        "string.empty": required,
    }),
  note: Joi.string()
    .min(3)
    .trim()
    .required()
    .label("Note")
    .messages({
        "string.empty": required,
    }),
  _objective: Joi.string()
    .required()
    .label("Objective")
    .messages({
      "string.empty": required,
    }),
  targetFigure: Joi.number()
    .required()
    .label("Target Value")
    .messages({
        "string.empty": required,
    }),
  achievedValue: Joi.number()
    .required()
    .label("Achieved Value")
    .messages({
        "string.empty": required,
    }),
});
