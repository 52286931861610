import { Box, Typography } from "@mui/material";
import { FC } from "react";

interface props {
    name: string;
    count: number;
}

const Tag: FC<props> = ({ name, count }) => (
    <div
        className={"self-evaluation-tag"}
    >
        <Typography className="cards-title" variant="body1">{name}</Typography>
        <Box display="flex" alignItems="center">
            <Typography className="analytics" variant="h4">
                {`${count}` || 0}
            </Typography>
            <Typography className="analytics-marks" variant="body2">
                {"/ 7"}
            </Typography>
        </Box>
    </div>
);

export default Tag;