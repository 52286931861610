import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../../interfaces";
import { EngagementService } from "../../../services";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { capitalize, createIndex, formatDateTime } from "../../../utilities/helper";
import { IEngagementRow, IEngagementState, IEngagement } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";

interface outletProps {
  refetchEngagementTypes: () => void;
}

const EngagementType = () => {
  let rows: IEngagementRow[] = [];
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const outlet = useOutletContext<outletProps>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getEngagements, deleteEngagement } = EngagementService();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IEngagementState>({
    deleteWarning: false,
    _engagement: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const engagements = useQuery({
    queryKey: ["allEngagements", state.pagination.page, state.filters],
    queryFn: () => getEngagements({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (engagements.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: engagements.data.meta.page,
          totalPages: engagements.data.meta.totalPages,
          totalRecords: engagements.data.meta.totalRecords
        }
      }));
    }
  }, [engagements.data?.meta]);


  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_engagement = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _engagement
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteEngagement({ _id: state._engagement });
      snackbar(deleted.message, "info");
      handleDelete();
      engagements.refetch();
      outlet?.refetchEngagementTypes && outlet.refetchEngagementTypes();
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "engagement_title",
      label: "Title"
    },
    {
      id: "engagement_createdBy",
      label: "Created By"
    },
    {
      id: "engagement_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, engagement: IEngagement, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + engagement._id, search: searchParams.toString() }), disabled: resourceAllocate("job-type.write") ? false : true },
        { name: "Delete", method: () => handleDelete(engagement._id), disabled: resourceAllocate("job-type.remove") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      engagement_title: capitalize(engagement.name),
      engagement_createdBy: engagement?.createdBy?.name && capitalize(engagement?.createdBy?.name),
      engagement_createdAt: formatDateTime(engagement?.createdAt),
      action,
    };
  };

  useEffect(() => {
    if (engagements.data && engagements.data.data && engagements.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [engagements.data && engagements.data.data]);
  
  if (engagements.data?.data.length) {
    rows = engagements.data?.data.map((engagement, i) => createRow(i, engagement, state.pagination));
  }
  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD ENGAGEMENT TYPE"
        onBtnClick={resourceAllocate("job-type.write") ? () => navigate("new") : undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Engagement-Type"
        description="Are you sure you want to delete this engagement-type?"
      />
      <Outlet context={{ ...outlet, reFetch: engagements.refetch }} />
    </>
  );
};
export default EngagementType;

