import { Box, Button, Chip, FormHelperText, Grid, MenuItem, TextField } from "@mui/material";
import { FC, useEffect, useState, KeyboardEvent } from "react";
import { ICandidateDetails, ICandidateInterview, ICandidateInterviewField, ICandidateInterviewRow, IErrorResponse, IInterview, ITemplate, IUser } from "../../../interfaces";
import { formatDateTime, capitalize, checkStatusColor, displayName } from "../../../utilities/helper";
import { InterviewService } from "../../../services";
import { candidateInterviewValidation } from "../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { validateEmail } from "../../../validations/shared";
import useSnackbar from "../../../hooks/useSnackbar";
import GetActions from "../../get-actions";
import CustomTable from "../../mui/table";
import Select from "../../mui/select";
import dayjs from "dayjs";
import WarningDialog from "../../mui/warning-dialog";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { timeOptions } from "../../../utilities/helper2";
interface props {
  candidate: ICandidateDetails | undefined;
  activeAction: boolean;
  setActiveAction?: (e: boolean) => void;
  currentTab: number;
  candidateDetailRefetch: () => void;
  candidateListRefetch: () => void;
}

interface IState {
  list: ICandidateInterviewRow[];
  deleteWarning: boolean;
  _interview: string;
}

const Interview: FC<props> = ({ candidate, activeAction,setActiveAction, currentTab, candidateDetailRefetch, candidateListRefetch }) => {
  const interviews = candidate && candidate.interviewRounds && [...candidate.interviewRounds];
  const { addInterview, deleteInterview } = InterviewService();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useUser();
  const [searchParam] = useSearchParams();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  let [...templates] = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(state => state.template.list) || [];
  templates = templates.filter(template => template.type === "email" && template.tag === "google_calendar");
  const { control, getValues, setValue, resetField, trigger, reset, watch, handleSubmit, formState: { errors } } = useForm<ICandidateInterview>({
    resolver: joiResolver(candidateInterviewValidation),
    defaultValues: {
      interview_round: "",
      _interviewer: "",
      emailText: "",
      template: "",
      interviewDateTime: "",
    }
  });
  const [state, setState] = useState<IState>({
    list: [],
    deleteWarning: false,
    _interview: ""
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "template") {
        const template = templates.find((template: ITemplate) => template._id === value.template);
        setValue("googleTemplate.subject", String(template?.email.subject));
        setValue("googleTemplate.content", String(template?.email.content));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, templates]);

  useEffect(() => {
    if (user) {
      const attendees: string[] = getValues("attendees") || [];
      attendees.push(user?.email);
      setValue("attendees", [...new Set(attendees)]);
      trigger("attendees");
    }
  }, [user]);

  useEffect(() => {
    if (interviews) {
      const list = interviews ? interviews.map((callStatus, i) => createRow(i, callStatus)) : [];
      setState(prevState => (
        {
          ...prevState,
          list
        }
      ));
    }
  }, [candidate?.interviewRounds]);

  const handleDelete = (_interview = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _interview
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteInterview({ _id: state._interview });
      handleDelete();
      snackbar(deleted.message, "info");
      candidateDetailRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("Error in deleting candidate interview", error);
    }
  };

  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];

    if (key === "emailText") {
      const err = validateEmail(getValues(key));
      if (err.error) {
        return;
      }

      if (e.key === "Enter" && key === "emailText") {
        const prev = getValues("attendees") ? getValues("attendees") : [];
        const newEmail = getValues("emailText") ? String(getValues("emailText")) : "";
        payload = [...prev, newEmail];

        setValue("attendees", [...new Set(payload)]);
        resetField(key);
      }
    }
  };

  const removeEmail = (value: string) => {
    let payload = getValues("attendees");
    payload = payload.filter(email => email !== value);
    setValue("attendees", payload);
    trigger("attendees");
  };

  // eslint-disable-next-line
  const selectDate = (value: Date | null) => {
    const date = value && dayjs(value) ? dayjs(value)?.toISOString() : undefined;
    setValue("interviewDateTime", date);
    trigger("interviewDateTime");
  };

  const handleEmailCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
      snackbar("Email ID copied to clipboard", "info");
  };

  const onSubmit = async (data: ICandidateInterview) => {
    const selectedTime = dayjs(data.interviewDateTime);
    const currentTime = dayjs();
    if (selectedTime.isBefore(currentTime, "minute")) {
      snackbar("Interview time cannot be in the past. Please select a current or future time", "warning");
      return;
    }
    const payload = { ...data };
    delete payload.emailText;
    delete payload.template;

    try {
      const interviewScheduled = await addInterview({
        ...payload,
        _lead: candidate?._id,
      });
      reset();
      setActiveAction && setActiveAction(false);
      snackbar(interviewScheduled.message, "info");
      candidateDetailRefetch();
      candidateListRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      setActiveAction && setActiveAction(false);
      console.log("Error in deleting candidate interview", error);
    }
  };

  const fields: ICandidateInterviewField[] = [
    {
      type: "select",
      name: "interview_round",
      label: "Interview Round*",
      children: [
        <MenuItem key="HR SCHEDULED" value="HR SCHEDULED">HR Scheduled</MenuItem>,
        <MenuItem key="TA SCHEDULED" value="TA SCHEDULED">TA Scheduled</MenuItem>
      ]
    },
    {
      type: "select",
      name: "_interviewer",
      label: "Interviewer*",
      children: users.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)
    },
    {
      type: "select",
      name: "template",
      label: "Template*",
      children: templates.map(template => <MenuItem key={template._id} value={template._id}>{template.title}</MenuItem>)
    },
    {
      type: "date",
      name: "interviewDateTime",
      label: "Interview Schedule Date and Time*"
    },
    {
      type: "time",
      name: "duration",
      label: "Interview Duration*"
    },
    {
      type: "input",
      name: "emailText",
      label: "Attendees",
      placeholder: "Type email address and press enter"
    }
  ];

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "round",
      label: "Round"
    },
    {
      id: "interviewer",
      label: "Interviewer"
    },
    {
      id: "interview_at",
      label: "Interview On"
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "score",
      label: "Score"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, interview: IInterview,) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: `manage-interview/${interview?._id}`, search: searchParam.toString() }) },
        { name: "Delete", method: () => handleDelete(interview._id) },
      ]}
    />;

    return {
      id: index + 1,
      round: capitalize(interview.title),
      interviewer: capitalize((interview._user as { name: string })?.name),
      interview_at: formatDateTime(interview.interviewDateTime),
      status: <Chip variant={"outlined"} color={checkStatusColor(interview.status)} label={capitalize(interview.status)} />,
      score: interview.score,
      action
    };
  };

  const isActionActive = currentTab === 4 && activeAction;

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        {/* Add Data  */}
        {
          isActionActive &&
          <Box marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
              <Grid container spacing={4}>
                {
                  fields.map(field => {
                    if (field.type === "input") {
                      return (<Grid key={field.label} item xs={4}>
                        <Controller
                          control={control}
                          name={field.name}
                          render={(prop) => <TextField
                            label={field.label}
                            className="disable-text"
                            variant={"outlined"}
                            size={"small"}
                            placeholder={field.placeholder ? "Type email address and press enter" : ""}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            {...prop.field}
                            onKeyUp={e => addEmail(e, field.name)}
                          />}
                        />
                        {
                          <Box>
                            {
                              field.name === "emailText" && getValues("attendees") &&
                              getValues("attendees").map(email => <Chip
                                key={email}
                                label={email}
                                onClick={() => handleEmailCopyToClipboard(email)}
                                onDelete={() => removeEmail(email)}
                                color="primary"
                                variant="outlined"
                                sx={{ margin: "5px" }}
                              />)
                            }
                          </Box>
                        }
                      </Grid>
                      );
                    } else if (field.type === "date") {
                      return (<Grid key={field.label} item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDateTimePicker
                            label={field.label}
                            onChange={(e: Date | null) => selectDate(e)}
                            slotProps={{
                              textField: {
                                error: errors[field.name] ? true : false,
                                helperText: errors[field.name]?.message
                              }
                            }}
                            shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), "day")}
                            format="LLL"
                          />
                        </LocalizationProvider>

                      </Grid>
                      );
                    } else if (field.type === "time") {
                      return (<Grid key={field.label} item xs={4}>
                        <Select
                          control={control}
                          name={field.name}
                          label={field.label}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                        >
                          {
                            timeOptions
                              .map((option, i) => <MenuItem key={i} value={option.value}>{capitalize(option?.label)}</MenuItem>)
                          }
                        </Select>
                      </Grid>);
                    }

                    else {
                      return (<Grid key={field.label} item xs={4}>
                        <Select
                          control={control}
                          className="disable-text"
                          name={field.name}
                          label={field.label}
                          size={"small"}
                          variant={"outlined"}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]?.message}
                        >
                          {field.children}
                        </Select>
                      </Grid>
                      );
                    }
                  })
                }
                <Grid item xs={4} marginLeft={"auto"} className={validateEmail(candidate?.email).error && "center"}>
                  {
                    validateEmail(candidate?.email).error
                      ?
                      <FormHelperText error={true}>{"Note: 'Candidate Email' must be a valid email"}</FormHelperText>
                      :
                      <Button type="submit" fullWidth>Schedule Interview</Button>
                  }
                </Grid>
              </Grid>
            </form>
          </Box>
        }

        {/* show Data  */}
        <CustomTable
          columns={columns}
          rows={state.list}
          height={isActionActive ? "calc(100% - 144px)" : "calc(100% - 12px)"}
          width="calc(100% - 2px)"
        />

        {/* Delete Data  */}
        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => handleDelete()}
          onConfirm={onDelete}
          title="Cancel Interview"
          description="Are you sure you want to delete this interview?"
        />
      </Box>
    </Box>
  );
};

export default Interview;