import HttpService from "../../http";
import { alternativeComparisonAssignment } from "../../endpoints";
import { IComparisonAssignmentResponse } from "../../../interfaces/alternative-comparison/assignment";
const ComparisionAssignmentService = () => {
    const { httpRequest } = HttpService();

    const updateComparisionAssignment = async (payload: object): Promise<IComparisonAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IComparisonAssignmentResponse>(
                "PUT",
                `${alternativeComparisonAssignment}`, 
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    const getComparisionAssignment = async (query: object): Promise<IComparisonAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IComparisonAssignmentResponse>(
                "GET", 
                `${alternativeComparisonAssignment}`, 
                {}, 
                query
            )
                .then(resolve)
                .catch(reject);
        });
    return { getComparisionAssignment, updateComparisionAssignment };

};

export { ComparisionAssignmentService };