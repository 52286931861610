import { IEmailTemplateKeywordsResponse } from "../../../interfaces/configuration/email-template-keywords";
import { emailTemplateKeywords } from "../../endpoints";
import HttpService from "../../http";

const emailTemplateKeywordsService = () => {
    const { httpRequest } = HttpService();

    const getPartialEmailTemplateKeywords = async (search: object): Promise<IEmailTemplateKeywordsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEmailTemplateKeywordsResponse>(
            "GET",
            `${emailTemplateKeywords}/list/partial`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { getPartialEmailTemplateKeywords };
};

export { emailTemplateKeywordsService };
