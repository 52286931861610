import Joi from "joi";
import { required } from "../../shared";
export const sitemapSettingsValidation = Joi.object({
    _product: Joi.string()
        .required()
        .label("Product")
        .messages({
            "string.empty": required,
        }),
    hostname: Joi.string()
        .trim()
        .min(3)
        .max(100)
        .required()
        .pattern(/^https:\/\/.*/)
        .label("Host Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": "Host Name must start with https://",
        })
});