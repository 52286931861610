import { FC, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Chart as ChartJS, defaults, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import ErrorMessage from "../../shared/error-message";
import ShowChartIcon from "@mui/icons-material/ShowChart";


ChartJS.register(...registerables);


interface LineChartProps {
  label?: string;
  data: {
    labels: string[];
    datasets: Array<{
      label: string;
      data: number[] | string[];
      borderColor?: string;
      backgroundColor?: string;
      tension?: number;
    }>;
  };
  height?: number | string;
  errorMessage?: string;
}


const LineChart: FC<LineChartProps> = ({
  label,
  data,
  height = 300,
  errorMessage = "No data available",
}) => {
  const theme = useTheme();


  useEffect(() => {
    defaults.font.family = theme.typography.fontFamily;
  }, [theme]);


  return (
    <Box className="box-container" sx={{ height: height }}>
      {data && data.labels && data.labels.length ? (
        <>
          <Typography variant="h6">{label}</Typography>
          <div>
            <Line
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    min:0,
                    ticks: {
                      stepSize: 0.5
                    },
                    grid: {
                      color: theme.palette.grey[200]
                    }
                  },
                  x: {
                    grid: {
                      display: true,
                      color: theme.palette.grey[200]
                    }
                  }
                },
                plugins: {
                  legend: {
                    position: "top",
                    align: "start",
                    labels: {
                      boxWidth: 15,
                      usePointStyle: true,
                      pointStyle: "line"
                    }
                  }
                },
                elements: {
                  point: {
                    radius: 4,
                    hoverRadius: 6
                  },
                  line: {
                    borderWidth: 2
                  }
                }
              }}
              width={100}
              height={Number(height) - 30}
            />
          </div>
        </>
      ) : (
        <ErrorMessage
          Icon={<ShowChartIcon fontSize="large" className="error-icon" />}
          fullHeight
          errorMessage={errorMessage}
        />
      )}
    </Box>
  );
};


LineChart.defaultProps = {
  height: 400,
};


export default LineChart;