import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces";
import {
	Box, Button, Divider, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from "@mui/material";
import {
	useParams,
	useNavigate,
	useOutletContext,
	useSearchParams,
} from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect, useState } from "react";

import { IAssesmentCompositionField, IAssessmentComposition } from "../../../../../interfaces/configuration/assessments";

import "./style.scss";
import { Typography } from "@mui/material";
import { AssessmentCompositionService } from "../../../../../services";
import { useQuery } from "@tanstack/react-query";
import { assessmentCompositionValidation } from "../../../../../validations/configuration/assessment-composition";

interface outletProps {
	reFetch: () => void;
	refetchTest: () => void;
}


const ManageAssessmentComposition = () => {
	const { id } = useParams();
	const [searchParam] = useSearchParams();
	const { snackbar } = useSnackbar();
	const outlet = useOutletContext<outletProps>();
	const { getAssessmentComposition, updateAssessmentComposition } = AssessmentCompositionService();
	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const inputValue = e.currentTarget.value;
		const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
		const truncatedValue = sanitizedValue.slice(0, 4);
		e.currentTarget.value = truncatedValue;
	};

	const getInputProps = () => ({
		pattern: "[0-9]*",
		inputMode: "numeric" as const,
		maxLength: 3,
		onInput: handleInput,
	});

	const hitQuery = (id === "new" || id === "view") ? false : true;
	const navigate = useNavigate();

	const { handleSubmit, control, trigger, watch, setValue, formState: { errors } } = useForm<IAssessmentComposition>(
		{
			resolver: joiResolver(assessmentCompositionValidation),
			defaultValues: {
				easyPercentage: "",
				mediumPercentage: "",
				hardPercentage: "",
				totalPercentage: "",
				_tests: [],
			}
		}
	);

	const composition = useQuery({
		queryKey: ["assessment-composition"],
		queryFn: () => getAssessmentComposition({ _assessment: id }),
		enabled: hitQuery
	});

	useEffect(() => {
		if (id !== "new" && composition && composition.data && composition.data.data) {
			setValue("_tests", composition.data.data._tests);
			setValue("easyPercentage", composition.data.data.easyPercentage && composition.data.data.easyPercentage.toString() || "0");

			setValue("mediumPercentage", composition.data.data.mediumPercentage && composition.data.data.mediumPercentage.toString() || "0");

			setValue("hardPercentage", composition.data.data.hardPercentage && composition.data.data.hardPercentage.toString() || "0");

			const total =  (Number(composition.data.data.easyPercentage) || 0) + (Number(composition.data.data.mediumPercentage) || 0) + (Number(composition.data.data.hardPercentage) || 0);
			if(total === 100){
				setError(null);
			}
			setValue("totalPercentage", total.toString());
			trigger("totalPercentage");
		}
	}, [composition.data]);

	const [error, setError] = useState<string | null>(null);

	const onSubmit = async (data: IAssessmentComposition) => {
		try {
			if(data.totalPercentage?.toString() !== "100"){
				snackbar("Total percentage should be 100%", "warning");
				return;
			}
			for(let i = 0; i < data._tests.length; i++){
				for(let j = 0; j < data._tests[i]._categories.length; j++){
					if(!data._tests[i]._categories[j].assignedQuestions || data._tests[i]._categories[j].assignedQuestions === 0){
						snackbar("Assigned questions cannot be 0 or Empty", "warning");
						return;
					}
				}
			}
			const transformedData = {
				...data,
				_assessment: id,
				_tests: data._tests.map((test) => ({
					_test: test._test._id, // test ID
					_categories: test._categories.map((category) => ({
						_category: category._category._id, // category ID
						easyQuestions: category.easyQuestions,
						mediumQuestions: category.mediumQuestions,
						hardQuestions: category.hardQuestions,
						assignedQuestions: category.assignedQuestions,
					}))
				}))
			};
			delete transformedData.totalPercentage;
			const update = await updateAssessmentComposition({ ...transformedData });
			snackbar(update.message, "info");
			navigate({
				pathname: "/assessment/assessments",
				search: searchParam.toString(),
			});
			outlet.reFetch && outlet.reFetch();
			outlet.refetchTest && outlet.refetchTest();
		} catch (error) {
			const err = error as IErrorResponse;
			snackbar(err.data.message, "warning");
		}
	};
	const onClose = () => {
		navigate({
			pathname: "/assessment/assessments",
			search: searchParam.toString(),
		});
	};

	const fields: IAssesmentCompositionField[] = [
		{
			label: "Easy*",
			name: "easyPercentage",
			type: "input",
			placeholder: "Type percentage here",
		},
		{
			label: "Moderate*",
			name: "mediumPercentage",
			type: "input",
			placeholder: "Type percentage here",
		},
		{
			label: "Hard*",
			name: "hardPercentage",
			type: "input",
			placeholder: "Type percentage here",
		},
		{
			label: "Total",
			name: "totalPercentage",
			type: "input",
			placeholder: "Type percentage here",
			disabled: true
		},
	];
	const easyPercentage = watch("easyPercentage");
	const mediumPercentage = watch("mediumPercentage");
	const hardPercentage = watch("hardPercentage");
	const totalPercentage = watch("totalPercentage");

	useEffect(() => {
		// Check if all three fields are filled
		const totalPercentage = (Number(easyPercentage) || 0) + (Number(mediumPercentage) || 0) + (Number(hardPercentage) || 0);
		setValue("totalPercentage", totalPercentage.toString() ?? "");
		if (totalPercentage !== 100) {
			setError("The sum of easy, medium, and hard percentages must be 100%");
		}
		if (easyPercentage !== "" && mediumPercentage !== "" && hardPercentage !== "" && totalPercentage === 100) {

			setError(null);

			const updateTests = async () => {
				const tests = watch("_tests"); // Get the current tests from the form

				// Loop through each test
				tests.forEach((test, testIndex) => {
					test._categories.forEach((categoryData, categoryIndex) => {
						// Get the assignedQuestions value from the category data or the watch
						const assignedQuestions = categoryData?.assignedQuestions ?? watch(`_tests.${testIndex}._categories.${categoryIndex}.assignedQuestions`) ?? 0;

						// Calculate counts based on percentages
						const hardCount = (assignedQuestions * Number(hardPercentage)) / 100;
						const mediumCount = (assignedQuestions * Number(mediumPercentage)) / 100;
						const easyCount = assignedQuestions - Math.round(mediumCount) - Math.round(hardCount);

						// Update form values using setValue for easy, medium, and hard questions
						setValue(`_tests.${testIndex}._categories.${categoryIndex}.easyQuestions`, Math.round(easyCount));
						setValue(`_tests.${testIndex}._categories.${categoryIndex}.mediumQuestions`, Math.round(mediumCount));
						setValue(`_tests.${testIndex}._categories.${categoryIndex}.hardQuestions`, Math.round(hardCount));

						// Directly mutate the category data (no need to return anything)
						categoryData.easyQuestions = Math.round(easyCount);
						categoryData.mediumQuestions = Math.round(mediumCount);
						categoryData.hardQuestions = Math.round(hardCount);
					});
				});
			};

			// Call the async function to update the tests
			updateTests();

		}
	}, [easyPercentage, mediumPercentage, hardPercentage, totalPercentage]);

	const handleAssignedQuestionsChange = (testIndex: number, categoryIndex: number, assignedQuestions: number) => {
    setValue(`_tests.${testIndex}._categories.${categoryIndex}.assignedQuestions`, assignedQuestions !== 0 ? assignedQuestions : undefined);
    trigger(`_tests.${testIndex}._categories.${categoryIndex}.assignedQuestions`);

    // Recalculate easy, medium, and hard questions based on the percentage
    const hardCount = ((assignedQuestions ?? 0) * Number(hardPercentage)) / 100;
    const mediumCount = ((assignedQuestions ?? 0) * Number(mediumPercentage)) / 100;
    const easyCount = (assignedQuestions ?? 0) - Math.round(mediumCount) - Math.round(hardCount);

    // Update the easy, medium, and hard question counts
    setValue(`_tests.${testIndex}._categories.${categoryIndex}.easyQuestions`, Math.round(easyCount));
    setValue(`_tests.${testIndex}._categories.${categoryIndex}.mediumQuestions`, Math.round(mediumCount));
    setValue(`_tests.${testIndex}._categories.${categoryIndex}.hardQuestions`, Math.round(hardCount));
};


	return (

		<Box className="composition-table">
			<form
				onSubmit={handleSubmit(onSubmit)}
			>
				<Typography className="mb-3 mt-3 bold-text" variant="body1" >
					Difficulty-wise Composition:
				</Typography>
				<Grid container spacing={4}>
					{fields.map((field) =>
					(
						<Grid key={field.label} item xs={2}>
							<Controller
								control={control}
								name={field.name}
								render={(prop) => <TextField
									label={field.label}
									className="disable-text"
									disabled={field.disabled}
									variant="outlined"
									size="small"
									placeholder={field.placeholder}
									error={!!(errors && errors[field.name])}
									helperText={errors[field.name]?.message}
									inputProps={{
										...getInputProps(),
										onKeyDown: (e) => {
											const target = e.target as HTMLInputElement;
											if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "ArrowUp" || e.key === "ArrowDown") {
												e.preventDefault();
											}

											if (e.key === "Backspace" || e.key === "Delete") {
												return;
											}

											const currentValue = target.value + e.key;

											if (parseInt(currentValue) > 100) {
												snackbar("Percentage cannot exceed 100%", "warning");
												e.preventDefault();
											}
										}
									}}
									InputProps={{
										endAdornment: <InputAdornment position="end">%</InputAdornment>,
									}}
									{...prop.field}
								/>}
							/>
						</Grid>

					))}
				</Grid>
				{error && <div style={{ color: "red" }} className="mt-2">{error}</div>}

				<Box marginTop={2} className="composition-table-container" height="60vh" overflow="auto">
					<Typography className="mb-3 mt-3 bold-text" variant="body1" >
						Category-wise Composition:
					</Typography>
					<TableContainer
						className="table-container"
						sx={{
							width: "calc(100% - 2px)",
							height: "calc(100% - 70px)"
						}}>
						<Table stickyHeader aria-label="sticky table" className="table">
							<TableHead className="table-head">
								<TableRow>
									<TableCell className="styled-table-cell">Test Name</TableCell>
									<TableCell className="styled-table-cell">Category Name</TableCell>
									<TableCell className="styled-table-cell">Total Questions</TableCell>
									<TableCell className="styled-table-cell">Assigned Questions</TableCell>
									<TableCell className="styled-table-cell">Easy</TableCell>
									<TableCell className="styled-table-cell">Moderate</TableCell>
									<TableCell className="styled-table-cell">Hard</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="table-body">
								{(watch("_tests") ?? []).map((test, testIndex) =>
								(test._categories.length > 0 ? (
									test._categories.map((category, categoryIndex) => (
										<TableRow key={`${test._test.name}-${categoryIndex}`}>
											{/* Test Name */}
											<TableCell className={categoryIndex === test._categories.length - 1 ? "styled-table-cell-body last-category-cell" : "styled-table-cell-body"}>
												{categoryIndex === 0 ? test._test.name : ""}
											</TableCell>
											{/* Category Details */}
											<TableCell>{category._category.name}</TableCell>
											<TableCell>{category._category.numberOfQuestions}</TableCell>
											<TableCell>
												<Controller
													control={control}
													name={`_tests.${testIndex}._categories.${categoryIndex}.assignedQuestions`}
													render={(prop) => (
														<TextField
															className="custom-textfield disable-text"
															type="number"
															{...prop.field}
															value={category.assignedQuestions}
															disabled={easyPercentage === "" || mediumPercentage === "" || hardPercentage === "" || watch("totalPercentage") !== "100"}
															onChange={(e) => {
																handleAssignedQuestionsChange(testIndex, categoryIndex, Number(e.target.value || 0));
															}}
															inputProps={{
																sx: { textAlign: "center" },
																onKeyDown: (e) => {
																	const target = e.target as HTMLInputElement;
																	const totalQuestions = category._category.numberOfQuestions;

																	if (
																		e.key === "e" ||
																		e.key === "E" ||
																		e.key === "-" ||
																		e.key === "+" ||
																		e.key === "ArrowUp" ||
																		e.key === "ArrowDown"
																	) {
																		e.preventDefault();
																	}

																	if (e.key === "Backspace" || e.key === "Delete") {
																		return;
																	}

																	const currentValue = target.value + e.key;
																	if (parseInt(currentValue) > totalQuestions) {
																		snackbar("Assigned questions cannot exceed total questions.", "warning");
																		e.preventDefault();
																	}
																},
																onPaste: (e) => {
																	e.preventDefault();
																},
																onWheel: (e) => {
																	(e.target as HTMLElement).blur(); // Ensure wheel actions don't change the input
																	e.preventDefault();
																},
															}}
															error={!!(
																errors &&
																errors["_tests"] &&
																errors["_tests"][testIndex] &&
																errors["_tests"][testIndex]?.["_categories"] &&
																errors["_tests"][testIndex]?.["_categories"]?.[categoryIndex] &&
																errors["_tests"][testIndex]?.["_categories"]?.[categoryIndex]?.["assignedQuestions"]
															)}
															helperText={
																errors &&
																errors["_tests"] &&
																errors["_tests"][testIndex] &&
																errors["_tests"][testIndex]?.["_categories"] &&
																errors["_tests"][testIndex]?.["_categories"]?.[categoryIndex] &&
																errors["_tests"][testIndex]?.["_categories"]?.[categoryIndex]?.["assignedQuestions"]?.message
															}
														/>
													)}
												/>
											</TableCell>
											{/* Easy, Medium, and Hard Questions (calculated) - Disabled */}
											<TableCell>
												<TextField
													className="custom-textfield"
													value={category.easyQuestions}
													disabled
													inputProps={{
														sx: { textAlign: "center" },
													}}
												/>
											</TableCell>
											<TableCell>
												<TextField
													className="custom-textfield"
													value={category.mediumQuestions}
													disabled
													inputProps={{
														sx: { textAlign: "center" },
													}}
												/>
											</TableCell>
											<TableCell>
												<TextField
													className="custom-textfield"
													value={category.hardQuestions}
													disabled
													inputProps={{
														sx: { textAlign: "center" },
													}}
												/>
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow key={`${test._test.name}-empty`}>
										{/* Empty row for tests with no categories */}
										<TableCell className="styled-table-cell-body last-category-cell">{test._test.name}</TableCell>
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell />
									</TableRow>
								))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>

				<>
					<Box className="action-box">
						<Divider sx={{ marginBottom: "20px" }} />
						<Button variant="outlined" onClick={() => onClose}>Cancel</Button>
						<Button type="submit">Save</Button>
					</Box>
				</>

			</form>
		</Box>

	);
};
export default ManageAssessmentComposition;