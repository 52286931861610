import { ICourseGetSectionResponse, ICourseSectionResponse, ICourseSectionResponses } from "../../../interfaces/configuration/course-category";
import { courseSection } from "../../endpoints";
import HttpService from "../../http";

const courseSectionService = () => {
  const { httpRequest } = HttpService();
  const addCourseSection = async (payload: object): Promise<ICourseSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSectionResponse>(
      "POST",
      `${courseSection}` ,
      payload
    )
      .then(resolve)
      .catch(reject);
  });

  const getCourseSection = async (query: object): Promise<ICourseGetSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseGetSectionResponse>(
      "GET",
      `${courseSection}` ,
      {},
      query
    )
      .then(resolve)
      .catch(reject);
  });

  const getPartialCourseSection = async (): Promise<ICourseSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSectionResponse>(
      "GET",
      `${courseSection}/list/partial`,
    )
      .then(resolve)
      .catch(reject);
  });

  const getCourseSectionList = async (search: object): Promise<ICourseSectionResponses> => new Promise((resolve, reject) => {
    httpRequest<ICourseSectionResponses>(
      "GET",
      `${courseSection}/list`,
      {},
      search
    )
      .then(resolve)
      .catch(reject);
  });


  const updateCourseSection = async (payload: object): Promise<ICourseSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSectionResponse>(
      "PUT",
      `${courseSection}` ,
      payload,
    )
      .then(resolve)
      .catch(reject);
  });

  const deleteCourseSection = async (payload: object): Promise<ICourseSectionResponse> => new Promise((resolve, reject) => {
    httpRequest<ICourseSectionResponse>(
      "DELETE",
      `${courseSection}`,
      {},
      payload,
    )
      .then(resolve)
      .catch(reject);
  });
  return { addCourseSection, getCourseSection, getPartialCourseSection, getCourseSectionList, updateCourseSection, deleteCourseSection };
};

export { courseSectionService };
