import { IAlternativeContentResponse } from "../../../interfaces/alternative-comparison/content";
import { alternativeContent } from "../../endpoints";
import HttpService from "../../http";
const AlternativeContentService = () => {

    const { httpRequest } = HttpService();
    const saveAlternativeContent = async (payload: object): Promise<IAlternativeContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeContentResponse>(
            "PUT",
            `${alternativeContent}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const submitAlternativeContent = async (payload: object): Promise<IAlternativeContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeContentResponse>(
            "PUT",
            `${alternativeContent}/submit-content`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getAlternativeContent = async (query: object): Promise<IAlternativeContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeContentResponse>(
            "GET",
            `${alternativeContent}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { saveAlternativeContent, submitAlternativeContent, getAlternativeContent };

};

export { AlternativeContentService };