import { useQuery } from "@tanstack/react-query";
import backArrowRoundIcon from "../../../../../assets/images/back-arrow-round.svg";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, capitalize, TextField, Typography } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { PmsParametersService } from "../../../../../services/pms/pms-parameters";
import { pmsSubParametersService } from "../../../../../services/pms/pms-parameters/sub-parameters";
import { UsersService } from "../../../../../services";
import { IErrorResponse } from "../../../../../interfaces";
import CustomTable from "../../../../../components/mui/table";
import { FeedbackRatingService } from "../../../../../services/pms/feedback";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { IFeedbackForm } from "../../../../../interfaces/pms/team-evaluation";
import { PMS_ERROR_MESSAGES } from "../../../../../utilities/messages";

const AddFeedback = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();

  const { getPmsParameter } = PmsParametersService();
  const { getPmsSubParameters } = pmsSubParametersService();
  const { getPartialUsers } = UsersService();
  const { addFeedbackRating } = FeedbackRatingService();

  const { control, handleSubmit, setValue } = useForm<IFeedbackForm>({
    defaultValues: {
      feedbackData: [],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "feedbackData",
  });

  const parameter = useQuery({
    queryKey: ["parameters-details"],
    queryFn: () => getPmsParameter({ _id: id }),
    enabled: !!id,
  });

  const parameterData = parameter && parameter.data && parameter.data.data;
  const parameterName = parameterData && parameterData.name || "";
  const reportingTo = parameterData &&parameterData.evaluator === "REPORTING-MANAGER";

  const pmsSubParameters = useQuery({
    queryKey: ["all-pms-sub-parameters", id],
    queryFn: () =>
      getPmsSubParameters({
        pagination: false,
        _parameter: id,
      }),
  });

  const pmsSubParametersData = pmsSubParameters?.data?.data || [];

  const users = useQuery({
    queryKey: ["users-for-feedback", reportingTo],
    queryFn: () =>
      getPartialUsers({
        reportingTo,
      }),
    enabled: reportingTo !== undefined,
  });

  const usersData = users?.data?.data || [];

  useEffect(() => {
    if (usersData.length > 0 && pmsSubParametersData.length > 0) {
      const feedbackFields = usersData.map((user) => ({
        _user: user._id,
        _parameter: id || "",
        _subParameters: pmsSubParametersData.map((subParam) => ({
          _id: subParam._id,
          rating: 0,
        })),
        feedback: "",
      }));

      setValue("feedbackData", feedbackFields);
    }
  }, [usersData, pmsSubParametersData, id, setValue]);

  const onSubmit = async (data: IFeedbackForm) => {
    const missingRatings = data.feedbackData.some((userData) =>
      userData._subParameters.some(
        (subParam) => subParam.rating === null || subParam.rating === undefined || subParam.rating === ""
      )
    );

    if (missingRatings) {
      snackbar(PMS_ERROR_MESSAGES.en.rating_for_all_subparams, "warning");
      return;
    }

    try {
      await addFeedbackRating(data.feedbackData);
      navigate(`/team-evaluation/${id}`);
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const handleClick = () => {
    navigate(`/team-evaluation/${id}`);
  };

  const columns = [
    {
      id: "employee",
      label: "Employee",
      minWidth: 200,
      maxWidth: 200,
    },
    ...pmsSubParametersData.map((subParam) => ({
      id: subParam._id,
      label: capitalize(subParam.name),
    })),
    {
      id: "feedback",
      label: "Feedback",
      minWidth: 700,
      maxWidth: 700,
    },
  ];

  const rows = fields.map((field, index) => ({
    id: field._user,
    employee: capitalize(usersData.find((user) => user._id === field._user)?.name || ""),
    ...pmsSubParametersData.reduce(
      (acc, subParam, subIndex) => ({
        ...acc,
        [subParam._id]: (
          <Controller
            name={`feedbackData.${index}._subParameters.${subIndex}.rating`}
            control={control}
            render={({ field }) => (
              <TextField
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              name={`subParam-${subParam._id}`}
              value={field.value}
              onKeyDown={(evt) => {
                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                  evt.key
                ) && evt.preventDefault();
              }}
              inputProps={{
                maxLength: 4,
                pattern: "[0-9.]*", 
                inputMode: "decimal",
                onInput: (e) => {
                  const inputValue = e.currentTarget.value;
                  const sanitizedValue = inputValue
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");

                  const valueAsNumber = parseFloat(sanitizedValue);

                  if (!isNaN(valueAsNumber) && valueAsNumber <= 7) {
                    e.currentTarget.value = sanitizedValue;
                    field.onChange(sanitizedValue);
                  } else if (sanitizedValue === "") {
                    e.currentTarget.value = sanitizedValue;
                    field.onChange(sanitizedValue);
                  } else {
                    e.currentTarget.value = sanitizedValue.slice(0, -1);
                  }
                },
              }}
            />
            )}
          />
        ),
      }),
      {}
    ),
    feedback: (
      <Controller
        name={`feedbackData.${index}.feedback`}
        control={control}
        render={({ field }) => (
          <TextField {...field} variant="outlined" size="small" fullWidth />
        )}
      />
    ),
  }));

  return (
    <>
      <header className="header">
        <Box className="title-box">
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography className="title" variant="h5" fontWeight={600}>
            {"Add Feedback"}
          </Typography>
        </Box>
        <Box className="sub-title-box" marginTop={3}>
          <Typography className="sub-text" variant="h5" fontWeight={600}>
            {capitalize(parameterName)}
          </Typography>
        </Box>
      </header>
      <Box marginTop={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomTable columns={columns} rows={rows} height={"calc(100vh - 235px)"} />
          <Box display="flex" justifyContent="flex-end" marginTop={2} gap={2}>
            <Button variant="outlined" color="error" onClick={() => navigate(`/team-evaluation/${id}`)}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddFeedback;