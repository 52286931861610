import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChangeEvent, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { TemplateService } from "../../../services";
import { IPagination } from "../../../interfaces";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { capitalize, createIndex } from "../../../utilities/helper";
import { ITemplateRow, ITemplate, ITemplateState } from "../../../interfaces";
import TemplateFilter from "./filter";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import useSnackbar from "../../../hooks/useSnackbar";
import useResource from "../../../hooks/useResource";

interface outletProps {
  refetchTemplates: () => void;
}

const Template = () => {
  let rows: ITemplateRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const { resourceAllocate } = useResource();
  const { getTemplates, deleteTeam } = TemplateService();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<ITemplateState>({
    deleteWarning: false,
    _templates: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });

  const templates = useQuery({
    queryKey: ["allTemplates", state.pagination.page, state.filters],
    queryFn: () => getTemplates({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (templates.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: templates.data.meta.page,
          totalPages: templates.data.meta.totalPages,
          totalRecords: templates.data.meta.totalRecords
        }
      }));
    }
  }, [templates.data?.meta]);


  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const type: { key: string, value: string }[] = searchParams.get("type") ? JSON.parse(String(searchParams.get("type"))) : [];
    filterCount += type.length ? 1 : 0;
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        type: type.map(type => type.key),
      },
      filterCount
    }));
  }, [searchParams]);
  
  useEffect(() => {
    if (templates.data && templates.data.data && templates.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [templates.data && templates.data.data]);
  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_templates = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _templates
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteTeam({ _id: state._templates });
      snackbar(deleted.message, "info");
      handleDelete();
      templates.refetch();
      outlet?.refetchTemplates && outlet.refetchTemplates();
    } catch (error) {
      console.log(error);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "templates_title",
      label: "Title"
    },
    {
      id: "templates_type",
      label: "Type"
    },
    {
      id: "templates_tag",
      label: "Tag"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];
  

  const createRow = (index: number, Template: ITemplate, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + Template._id, search: searchParams.toString() }), disabled: !resourceAllocate("template.write")},
        { name: "Delete", method: () => handleDelete(Template._id), disabled: !resourceAllocate("template.remove") || Template?.tag === "new_joinee"},
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      templates_title: capitalize(Template.title),
      templates_tag: ["google_calendar","resource_request", "new_joinee"].includes(Template.tag) ? capitalize(Template.tag.replace("_"," ")) : capitalize(Template.tag),
      templates_type: capitalize(Template.type),
      action,
    };
  };
  if (templates.data?.data.length) {
    rows = templates.data?.data.map((Template, i) => createRow(i, Template, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD TEMPLATE"
        onBtnClick={resourceAllocate("template.write") ? () => navigate("new"): undefined}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <TemplateFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Template"
        description="Are you sure you want to delete this template?"
      />
      <Outlet context={{ ...outlet, reFetch: templates.refetch }} />
    </>
  );
};
export default Template;