import "./style.scss";
import { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CustomDialog from "../../../../components/mui/dialog";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { IPMSParameterData, PMSParameterField, PMSParameterFieldForm } from "../../../../interfaces/pms/pms-parameter";
import { IErrorResponse, IRole } from "../../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import CustomLabel from "../../../../components/mui/custom-label";
import { useSelector } from "react-redux";
import { PmsParametersService } from "../../../../services/pms/pms-parameters";
import useSnackbar from "../../../../hooks/useSnackbar";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  TextField
} from "@mui/material";
import { capitalize } from "../../../../utilities/helper";
import { pmsParametersValidations } from "../../../../validations/pms";
import { joiResolver } from "@hookform/resolvers/joi";
import Select from "../../../../components/mui/select";
import { PMS_ERROR_MESSAGES } from "../../../../utilities/messages";

interface outletProps {
  refetchParameters: () => void;
  parametersData: IPMSParameterData[];
}

const ManagePMSParameter = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const [ searchParams ] = useSearchParams();
  const { parametersData } = outlet;
  const { snackbar } = useSnackbar();
  const { id } = useParams();
  const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>(
    (state) => state.role.list
  );
  const { addPmsParameter, updatePmsParameter } = PmsParametersService();
  const [deletedParameters, setDeletedParameters] = useState<string[]>([]);
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    watch,
    getValues,
    formState: { errors },
  } = useForm<PMSParameterFieldForm>({
    resolver: joiResolver(pmsParametersValidations),
    defaultValues: {
      form: [
        {
          _id: "",
          name: "",
          fieldType: "",
          frequency: "",
          evaluator: "",
          _roles: [],
        },
      ],
    },
  });

  const [parameters, setParameters] = useState<PMSParameterField[]>(
    id === "new"
      ? [
          {
            _id: "",
            name: "",
            fieldType: "",
            frequency: "",
            evaluator: "",
            _roles: [],
          },
        ]
      : parametersData.map((param) => ({
          _id: param._id || "",
          name: param.name || "",
          fieldType: param.type || "",
          weightage: param.weightage,
          frequency: param.frequency || "",
          evaluator: param.evaluator || "",
          _roles: param._roles.map((role) => role._id) || [],
        }))
  );

  useEffect(() => {
    setValue("form", parameters);
    trigger("form");
  }, [parameters]);
  const handleAddRow = () => {
    setParameters((prev) => [
      ...prev,
      {
        _id: "",
        name: "",
        fieldType: "",
        frequency: "",
        evaluator: "",
        _roles: [],
      },
    ]);
  };

  const handleRemoveRow = (index: number) => {
    if (parameters[index]._id.length) {
      setDeletedParameters((prev) => [...prev, parameters[index]._id]);
    }
    setParameters((prev) => {
      const updated = prev.filter((_, i) => i !== index);
      setValue("form", updated);
      trigger("form");
      return updated;
    });
  };

  const onSubmit = async (data: PMSParameterFieldForm) => {
    let weightageSum = 0;
    data.form.forEach((param) => {
      weightageSum += Number(param.weightage);
    });
    if (weightageSum !== 100) {
      snackbar(PMS_ERROR_MESSAGES.en.invalid_weightage, "warning");
      return;
    }

    try {
      const payload = {
        parameters: data.form.map((param) => ({
          _id:
            id === "new" ? undefined : param._id.length ? param._id : undefined,
          name: param.name,
          type: param.fieldType,
          weightage: param.weightage,
          frequency: param.frequency,
          evaluator: param.fieldType === "INDIVIDUAL" ? param.evaluator : undefined,
          _roles: param.fieldType === "ROLE-BASED" ? param._roles : undefined,
        })),
        deletedParameters: id !== "new" ? deletedParameters : undefined,
      };
      if (id === "new") {
        const res = await addPmsParameter(payload);
        snackbar(res.message, "info");
        navigate({
          pathname: "/pms-parameters",
          search: searchParams.toString(),
        });
        outlet?.refetchParameters && outlet.refetchParameters();
      } else {
        const res = await updatePmsParameter(payload);
        snackbar(res.message, "info");
        navigate({
          pathname: "/pms-parameters",
          search: searchParams.toString(),
        });
        outlet?.refetchParameters && outlet.refetchParameters();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  return (
    <CustomDialog
      title="Add/Edit Parameters"
      isOpen={true}
      onClose={() => {
        navigate({
            pathname: "/pms-parameters",
            search: searchParams.toString(),
          });
        }
      }
      onSubmit={handleSubmit(onSubmit)}
      size="xl"
    >
      <Box>
        <div className="heading-box">
          <p className="sub-text">
              <strong>Note: </strong>Please ensure that the total weightage of all parameters combined equals 100%. You cannot save new parameters if the total weightage exceeds or is less than 100%.
          </p>
        </div>
        {parameters.map((param, index) => (
          <Box key={index} display="flex" alignItems="flex-start" gap={2} mb={2} mt={3}>
            <Grid container spacing={2}>
              <Grid key={`name-${index}`} item xs={2}>
                <Controller
                  defaultValue={param.name}
                  control={control}
                  name={`form.${index}.name`}
                  render={() => (
                    <TextField
                      label={<CustomLabel label={"Parameter name"} required />}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={"Enter name"}
                      error={!!errors.form?.[index]?.name}
                      helperText={errors.form?.[index]?.name?.message}
                      onChange={(e) => {
                        setParameters((prev) => {
                          const updated = [...prev];
                          updated[index].name = e.target.value;
                          return updated;
                        });
                        trigger(`form.${index}.name`);
                      }}
                      value={param.name || ""}
                    />
                  )}
                />
              </Grid>

              <Grid key={`weightage-${index}`} item xs={2}>
                <Controller
                  defaultValue={param.weightage}
                  control={control}
                  name={`form.${index}.weightage`}
                  render={() => (
                    <TextField
                      label={<CustomLabel label={"Weightage (in %)"} required />}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={"Enter weightage in percentage"}
                      type="number"
                      error={!!errors.form?.[index]?.weightage}
                      helperText={errors.form?.[index]?.weightage?.message}

                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "" || (Number(value) > 0 && Number(value) <= 100)) {
                          setParameters((prev) => {
                            const updated = [...prev];
                            updated[index].weightage = value === undefined ? undefined : Number(value);
                            return updated;
                          });
                          trigger(`form.${index}.weightage`);
                        }
                      }}
                      value={param.weightage || ""}
                    />
                  )}
                />
              </Grid>

              <Grid key={`frequency-${index}`} item xs={2}>
                  <Select
                    control={control}
                    className="disable-text"
                    name={`form.${index}.frequency`}
                    label={<CustomLabel label={"Frequency"} required />}
                    size={"small"}
                    variant={"outlined"}
                    error={!!errors.form?.[index]?.frequency}
                    helperText={errors.form?.[index]?.frequency?.message}
                    onChange={(e) => {
                      setParameters((prev) => {
                        const updated = [...prev];
                        updated[index].frequency = e && String(e.target.value) || "";
                        return updated;
                      });
                      trigger(`form.${index}.frequency`);
                    }}
                  >
                    <MenuItem key="WEEKLY" value="WEEKLY">
                      Weekly
                    </MenuItem>
                    <MenuItem key="BI-WEEKLY" value="BI-WEEKLY">
                      Bi-weekly
                    </MenuItem>
                    <MenuItem key="MONTHLY" value="MONTHLY">
                      Monthly
                    </MenuItem>
                    <MenuItem key="QUARTERLY" value="QUARTERLY">
                      Quarterly
                    </MenuItem>
                  </Select>
              </Grid>

              <Grid key={`fieldType-${index}`} item xs={2}>
                <Select
                    control={control}
                    onChange={(e) => {
                      setParameters((prev) => {
                        const updated = [...prev];
                        updated[index].fieldType = e && String(e.target.value) || "";
                        return updated;
                      });
                      trigger(`form.${index}.fieldType`);
                    }}
                    className="disable-text"
                    name={`form.${index}.fieldType`}
                    label={<CustomLabel label={"Type"} required />}
                    defaultValue={param.fieldType}
                    size={"small"}
                    variant={"outlined"}
                    error={!!errors.form?.[index]?.fieldType}
                    helperText={errors.form?.[index]?.fieldType?.message}
                  >
                    <MenuItem key="INDIVIDUAL" value="INDIVIDUAL">
                      Individual
                    </MenuItem>

                    <MenuItem key="ROLE-BASED" value="ROLE-BASED">
                      Role-based
                    </MenuItem>
                </Select>
              </Grid>

              {watch(`form.${index}.fieldType`) === "INDIVIDUAL" && (
                <Grid key={`evaluator-${index}`} item xs={4}>
                  <Select
                    control={control}
                    onChange={(e) => {
                      setParameters((prev) => {
                        const updated = [...prev];
                        updated[index].evaluator = e && String(e.target.value) || "";
                        return updated;
                      });
                      trigger(`form.${index}.evaluator`);
                    }}
                    className="disable-text"
                    name={`form.${index}.evaluator`}
                    label={<CustomLabel label={"Evaluator"} required />}
                    defaultValue={param.evaluator}
                    size={"small"}
                    variant={"outlined"}
                    error={!!errors.form?.[index]?.evaluator}
                    helperText={errors.form?.[index]?.evaluator?.message}
                  >
                      <MenuItem key="ALL" value="ALL">
                        All
                      </MenuItem>
                      <MenuItem key="REPORTING-MANAGER" value="REPORTING-MANAGER">
                        Reporting Manager
                      </MenuItem>
                </Select>
                </Grid>
              )}

              {watch(`form.${index}.fieldType`) === "ROLE-BASED" && (
                <Grid key={`role-${index}`} item xs={4}>
                  <Controller
                    control={control}
                    name={`form.${index}._roles`}
                    render={(prop) => (
                      <>
                        <Autocomplete
                          fullWidth
                          options={roles}
                          getOptionLabel={(option) => capitalize(option.name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label={<CustomLabel label={"Roles"} required />}
                              placeholder={"Select roles"}
                              error={!!errors.form?.[index]?._roles}
                              helperText={errors.form?.[index]?._roles?.message}
                            />
                          )}
                          {...prop.field}
                          value={[]}
                          onChange={(e, value) => {
                            const data = getValues(
                              `form.${index}._roles`
                            ) as string[];
                            if (!data) {
                              setValue(`form.${index}._roles`, [value[0]._id]);
                            }
                            const isExist = data && data.includes(value[0]._id);
                            if (!isExist) {
                              data && data.push(value[0]._id);
                              setValue(`form.${index}._roles`, data);
                            }
                            setParameters((prev) => {
                              const updated = [...prev];
                              updated[index]._roles = data;
                              return updated;
                            });
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              {capitalize(option.name)}
                            </li>
                          )}
                          multiple
                        />
                        {(
                          (getValues(`form.${index}._roles`) as string[]) || []
                        ).map((item) => (
                          <Chip
                            key={item}
                            style={{ margin: "5px" }}
                            label={
                              roles.find((title) => title._id === item)?.name
                            }
                            color="primary"
                            onDelete={() => {
                              setValue(
                                `form.${index}._roles`,
                                (
                                  getValues(`form.${index}._roles`) as string[]
                                )?.filter((value) => value !== item)
                              );
                              setParameters((prev) => {
                                const updated = [...prev];
                                updated[index]._roles = (
                                  getValues(`form.${index}._roles`) as string[]
                                )?.filter((value) => value !== item);
                                return updated;
                              });
                            }}
                            variant="outlined"
                          />
                        ))}
                      </>
                    )}
                  />
                </Grid>
              )}
            </Grid>

           
            <IconButton
              onClick={() => handleRemoveRow(index)}
              disabled={parameters.length === 1}
              color="error"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        ))}

       
        <Button
          variant="outlined"
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddRow}
        >
          Add Parameter
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default ManagePMSParameter;
