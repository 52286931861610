import Joi from "joi";
import { required } from "../shared";

export const InterviewRescheduleValidation = Joi.object({
    title: Joi.string()
        .required()
        .label("Round")
        .messages({
            "string.empty": required
        }),
    interviewDateTime: Joi.string()
        .required()
        .label("Date")
        .messages({
            "string.empty": required
        }),
    duration: Joi.number()
        .required()
        .label("Interview Duration")
        .messages({
            "number.empty": required
        }),
});

export const InteriewValidation = Joi.object({
    status: Joi.string()
        .valid("HR SELECTED", "HR REJECTED", "TA SELECTED", "TA REJECTED", "ROHIT SELECTED", "ROHIT REJECTED")
        .required()
        .label("Status")
        .messages({
            "string.empty": required
        }),
    feedback: Joi.string()
        .allow("")
        .label("Note"),
});