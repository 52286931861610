import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  TextField,
  capitalize,
  Box,
  AutocompleteChangeReason,
  Chip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces";
import Select from "../../../../../components/mui/select";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect, useState } from "react";
import { ICategory } from "../../../../../interfaces/content/category";
import CustomLabel from "../../../../../components/mui/custom-label";
import ProjectsService from "../../../../../services/content/projects-products";
import { IProject } from "../../../../../interfaces/content/projects-products";
import plannerService from "../../../../../services/planner";
import KeywordService from "../../../../../services/content/keyword";
import goalsService from "../../../../../services/content/goals";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import useResource from "../../../../../hooks/useResource";
import { IKeyword } from "../../../../../interfaces/content/keyword";
import { IGlossaryForm } from "../../../../../interfaces/content-marketing/glossary";
import { GlossaryService } from "../../../../../services/glossary";
import { joiResolver } from "@hookform/resolvers/joi";
import { glossaryBasicDetailValidation } from "../../../../../validations/content-marketing/glossary";
import { CMS_ERROR_MESSAGES } from "../../../../../utilities/messages";

const ManageBasicDetailsGlossary = () => {
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<IGlossaryForm>({
    resolver: joiResolver(glossaryBasicDetailValidation),
    defaultValues: {
      title: "",
      description: "",
      slug: "",
      _product: "",
      _quarter: "",
      primaryKeyword: "",
      secondaryKeywords: [],
      goals: "",
    },
  });
  const { resourceAllocate } = useResource();
  const { getContentProjects } = ProjectsService();
  const { getPlannerList } = plannerService();
  const { getContentKeywords } = KeywordService();
  const { snackbar } = useSnackbar();
  const { getContentsGoals } = goalsService();
  const { getGlossary, updateGlossary, addGlossary } = GlossaryService();
  const navigate = useNavigate();
  const { id } = useParams();
  const hitQuery = !(id === "new");
    useState<ICategory | null>(null);
  const [selectedProductId, setSelectedProductId] = useState<IProject | null>(
    null
  );

  const projectsList = useQuery({
    queryKey: ["projects"],
    queryFn: () => getContentProjects({ isActive: true }),
  });

  const quartersList = useQuery({
    queryKey: ["quarters", selectedProductId?._id],
    queryFn: () => getPlannerList({ productId: selectedProductId?._id }),
  });

  const keywordsList = useQuery({
    queryKey: ["keywords-for-glossary", selectedProductId?._id],
    queryFn: () => getContentKeywords({
      productId: selectedProductId?._id
    }),
    enabled: selectedProductId !== null
  });
  const glossary = useQuery({
    queryKey: ["glossary", id],
    queryFn: () => getGlossary({ 
      populate: ["_quarter", "_product", "primaryKeyword", "secondaryKeywords", "goals"],
      _id: id 
    }),
    enabled: hitQuery,
  });

  const goalList = useQuery({
    queryKey: ["goals"],
    queryFn: () => getContentsGoals({}),
  });

  useEffect(() => {
    if (glossary?.data?.data && id !== "new") {
      const data = glossary && glossary.data && glossary.data.data;
      setValue("title", data.title || "");
      setValue("description", data.description || "");
      setValue("slug", data.slug || "");
      setValue("_product", data._product?._id || "");
      setSelectedProductId(data._product);
      setValue("_quarter", data._quarter?._id || "");
      setValue(
        "primaryKeyword",
        data.primaryKeyword?._id || ""
      );
      setValue(
        "secondaryKeywords",
        data.secondaryKeywords && data.secondaryKeywords.map((data) => data._id) || []
      );
      setValue("goals", data.goals && data.goals._id || "");
      setValue("audience", data.audience);
    }
  }, [glossary?.data?.data]);
  const onSubmit = async (data: IGlossaryForm) => {
    try {
      if (id === "new") {
        const response = await addGlossary(data);
        snackbar(response.message, "info");
        navigate({
          pathname: "/glossary",
          search: searchParams.toString()
        });
      } else {
        const payload = {
          ...data,
          _id: id,
        };
        const response = await updateGlossary(payload);
        snackbar(response.message, "info");
        navigate({
          pathname: "/glossary",
          search: searchParams.toString(),
        });
      }
    } catch (error) {
      const err = error as IErrorResponse;
      if(err && err.data && err.data.message && err.data.message.includes("slug_1 dup key:")){
        snackbar(CMS_ERROR_MESSAGES.en.duplicate_slug, "error");
      }else{
        snackbar(err?.data?.message, "error");
      }
    }
  };
  const handleProductChange = async (value: ICategory | null) => {
    if (value) {
      setSelectedProductId(value);
      setValue("_product", value._id);
      trigger("_product");
      setValue("primaryKeyword", "");
      setValue("secondaryKeywords", []);
    }
  };

  const handlePrimaryKeywordChange = async (value: ICategory | null) => {
    if (value) {
      setValue("primaryKeyword", value._id);
      trigger("primaryKeyword");
    }
  };

  const onChangeSecondaryKeywordsAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IKeyword[],
    reason: AutocompleteChangeReason
  ) => {
    const keywordData = getValues("secondaryKeywords");
    const isExist = keywordData.includes(value[0]?._id);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          keywordData.push(value[0]?._id);
          setValue("secondaryKeywords", keywordData);
        }
        break;
      default:
        break;
    }

    trigger("secondaryKeywords");
  };

  const handleKewordDeleteChip = (kewordId: string) => {
    const data = getValues("secondaryKeywords");
    const newData = data.filter((id) => id !== kewordId);
    setValue("secondaryKeywords", newData);
  };

  return (
    <div className="my-3 form-border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="title"
              render={(prop) => (
                <TextField
                  label={<CustomLabel label="Glossary Title" required />}
                  className="disable-text"
                  variant="outlined"
                  size="small"
                  placeholder="Type glossary title"
                  error={!!errors["title"]}
                  helperText={errors["title"]?.message}
                  {...prop.field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="description"
              render={(prop) => (
                <TextField
                  label={<CustomLabel label="Description" required />}
                  className="disable-text"
                  variant="outlined"
                  size="small"
                  placeholder="Type glossary description"
                  multiline
                  minRows={3}
                  maxRows={3}
                  error={!!errors["description"]}
                  helperText={errors["description"]?.message}
                  {...prop.field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="slug"
              render={(prop) => (
                <TextField
                  label={<CustomLabel label="Slug" required />}
                  className="disable-text"
                  variant="outlined"
                  size="small"
                  placeholder="Type glossary slug"
                  error={!!errors["slug"]}
                  helperText={errors["slug"]?.message}
                  {...prop.field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"_product"}
              render={(prop) => (
                <Autocomplete
                  className="disable-text"
                  options={projectsList && projectsList.data && projectsList.data.data.map((data) => data) || []}
                  clearIcon={null}
                  getOptionLabel={(option) => capitalize(option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors["_product"]}
                      helperText={errors["_product"]?.message}
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label="Product" required />}
                      placeholder={"Please Select Product"}
                    />
                  )}
                  {...prop.field}
                  value={
                    projectsList && projectsList.data && projectsList.data.data.find(
                      (data) => data._id === getValues("_product")
                    ) || null
                  }
                  onChange={(e, value) => {
                    handleProductChange(value);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {capitalize(option.name)}
                    </li>
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="_quarter"
              label={<CustomLabel label="Quarter" required />}
              size={"small"}
              variant={"outlined"}
              error={!!errors["_quarter"]}
              helperText={errors["_quarter"]?.message}
              disabled={selectedProductId === null}
            >
              {quartersList && quartersList.data && quartersList.data.data.map((data) => (
                <MenuItem key={data._id} value={data._id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"primaryKeyword"}
              disabled={selectedProductId === null}
              render={(prop) => (
                <Autocomplete
                  className="disable-text"
                  options={keywordsList && keywordsList.data && keywordsList.data.data && keywordsList.data.data.map((data) => data) || []}
                  clearIcon={null}
                  getOptionLabel={(option) => capitalize(option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors["primaryKeyword"]}
                      helperText={errors["primaryKeyword"]?.message}
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label="Primary Keyword" required />}
                      placeholder={"Please Select Product"}
                    />
                  )}
                  {...prop.field}
                  value={
                    keywordsList && keywordsList.data && keywordsList.data.data?.find(
                      (data) => data._id === getValues("primaryKeyword")
                    ) || null
                  }
                  onChange={(e, value) => {
                    handlePrimaryKeywordChange(value);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {capitalize(option.name)}
                    </li>
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" alignItems="start" flexDirection="column">
              <Controller
                control={control}
                disabled={selectedProductId === null}
                name={"secondaryKeywords"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={
                          keywordsList && keywordsList.data && keywordsList.data.data?.map(
                            (keywordData) => keywordData
                          ) || []
                        }
                        getOptionLabel={(option) => capitalize(option.name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={
                              <CustomLabel label="Secondary Keyword" required />
                            }
                            placeholder={"Secondary Keyword"}
                            error={!!errors["secondaryKeywords"]}
                            helperText={errors["secondaryKeywords"]?.message}
                          />
                        )}
                        {...prop.field}
                        value={[]}
                        onChange={(e, value, reason) =>
                          onChangeSecondaryKeywordsAutoComplete(
                            e,
                            value,
                            reason
                          )
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {capitalize(option.name)}
                          </li>
                        )}
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >
                      {getValues("secondaryKeywords")?.map((keyowrdId) => (
                        <Chip
                          key={keyowrdId}
                          label={
                            keywordsList && keywordsList.data && keywordsList.data.data?.find(
                              (user) => user._id === keyowrdId
                            )?.name
                          }
                          color="primary"
                          variant="outlined"
                          onDelete={() => handleKewordDeleteChip(keyowrdId)}
                          sx={{ margin: "5px" }}
                        />
                      ))}
                    </Box>
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="goals"
              label={<CustomLabel label="Goal" required />}
              size={"small"}
              variant={"outlined"}
              error={!!errors["goals"]}
              helperText={errors["goals"]?.message}
            >
              {goalList && goalList.data && goalList.data.data?.map((data) => (
                <MenuItem key={data?._id} value={data._id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="audience"
              render={(prop) => (
                <TextField
                  label={<CustomLabel label="Audience" />}
                  className="disable-text"
                  variant="outlined"
                  size="small"
                  placeholder="Write audience of the glossary here..."
                  multiline
                  minRows={3}
                  maxRows={3}
                  error={!!errors["audience"]}
                  helperText={errors["audience"]?.message}
                  {...prop.field}
                  inputProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <div className="form-action-btn">
          <Button
            variant="outlined"
            onClick={() => {
              navigate({
                pathname: "/glossary",
                search: searchParams.toString(),
              });
            }}
            disabled={!resourceAllocate("cms-glossary.write")}
          >
            Discard
          </Button>
          <Button
            type="submit"
            className="ml-2"
            disabled={!resourceAllocate("cms-glossary.write")}
          >
            Save
          </Button>
        </div>
      </form>
      <Outlet />
    </div>
  );
};

export default ManageBasicDetailsGlossary;
