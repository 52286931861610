import { FC, useEffect, useState } from "react";
import { Box, DialogContent } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../components/shared/mui-tabs";
import Timeline from "./timeline";
import GlossaryActivityService from "../../../../services/glossary/timeline";

interface props {
  setShowTimeline: (value: boolean) => void;
}
const GlossaryTimeline: FC<props> = ({ setShowTimeline }) => {
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const { getGlossaryActivities } = GlossaryActivityService();
  const activityData = useQuery({
    queryKey: ["glossary-timeline-activity"],
    queryFn: () => getGlossaryActivities({ _glossary: id }),
    enabled: id !== "new"
  });

  useEffect(() => {
    setValue(0);
  }, []);

  return (
    <BootstrapDialog
      maxWidth="lg"
      open={true}
    >
      <BootstrapDialogTitle onClose={() => setShowTimeline(false)}>
        <Box display="flex" alignItems="center">
          Timeline
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: "58vh" }}>
        <Box width="100%" height="100%">
          <TabPanel value={value} index={0}>
            <Timeline data={activityData.data && activityData.data.data} />
          </TabPanel>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default GlossaryTimeline;
