import "../../../../components/shared/filter/style.scss";
import { FC } from "react";
import { Box, Button, capitalize, Grid, IconButton, MenuItem, Menu } from "@mui/material";
import Select from "../../../../components/mui/select";
import { IBlogAssignment, IBlogState, IErrorResponse } from "../../../../interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { blogAssignmentService } from "../../../../services/blog";
import useSnackbar from "../../../../hooks/useSnackbar";
import useResource from "../../../../hooks/useResource";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { blogBulkAssignmentValidation } from "../../../../validations";
import { displayName } from "../../../../utilities/helper";
import DateTime from "../../../../components/mui/date-time";
import "./style.scss";
import { UsersService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { CMS_ERROR_MESSAGES } from "../../../../utilities/messages";
import CustomLabel from "../../../../components/mui/custom-label";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    ids: string[];
    OnClose: () => void;
    setState: React.Dispatch<React.SetStateAction<IBlogState>>
}

const BulkAssignment: FC<props> = ({ anchorEl, isOpen, OnClose, ids, setState }) => {
    const navigate = useNavigate();

    const { getRoleBasedUser } = UsersService();
    const writerList = useQuery({
        queryKey: ["writer-list"],
        queryFn: () => getRoleBasedUser({ role: ["writer","reviewer","content admin"] }),
    });

    const reviewerList = useQuery({
        queryKey: ["reviewer-list"],
        queryFn: () => getRoleBasedUser({ role: ["reviewer","content admin"] }),
    });

    const designerList = useQuery({
        queryKey: ["illustrator-list"],
        queryFn: () => getRoleBasedUser({ role: "illustrator" }),
    });

    const publisherList = useQuery({
        queryKey: ["publisher-list"],
        queryFn: () => getRoleBasedUser({ role: "publisher" }),
    });

    const { snackbar } = useSnackbar();
    const { bulkBlogAssignment } = blogAssignmentService();
    const { id } = useParams();

    const { resourceAllocate } = useResource();

    const { handleSubmit, control, getValues, setValue, reset, formState: { errors } } = useForm<IBlogAssignment>({
        resolver: joiResolver(blogBulkAssignmentValidation),
        defaultValues: {
            writer: {
                userId: "",
                submissionDate: dayjs().startOf("day").toISOString()
            },
            reviewer: {
                userId: "",
                submissionDate: dayjs().add(2, "days").startOf("day").toISOString()
            },
            illustrator: {
                userId: "",
                submissionDate: dayjs().add(4, "days").startOf("day").toISOString()
            },
            publisher: {
                userId: "",
                submissionDate: dayjs().add(6, "days").startOf("day").toISOString()
            }
        }
    });

    const changeDate = (e: dayjs.Dayjs | null, name: string) => {
        const newDate = e ? dayjs(e).startOf("day").toISOString() : "";

        if (name === "writer.submissionDate") {
            setValue(name, newDate);
        }
        else if (name === "reviewer.submissionDate") {
            setValue(name, newDate);
        }
        else if (name === "illustrator.submissionDate") {
            setValue(name, newDate);
        }
        else if (name === "publisher.submissionDate") {
            setValue(name, newDate);
        }

    };

    const handleClose = () => {
        reset();
        OnClose();
    };

    const onSubmit = async (data: IBlogAssignment) => {
        try {
            if (id === "new") {
                snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
            } else {
                const response = await bulkBlogAssignment({ ...data, blogIds: ids });
                snackbar(response.message, "info");
                setState((prevState) => ({
                    ...prevState,
                    selectAll: []
                }));
                handleClose();
                navigate("/blog");
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            console.log("Error in Adding assignment", error);
        }
    };



    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >

            <Box id="filters-container">
                <Box className="center mb-1" display={"flex"} justifyContent="flex-end" alignItems="center">
                    <div className="active-filter mb-1">
                        <IconButton
                            onClick={handleClose}
                            style={{ marginRight: "-10px" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid className="filter-box" container spacing={4}>

                        {/* Writer details  */}
                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="writer.userId"
                                label={<CustomLabel label="Writer" required />}    
                                size={"small"}
                                variant={"outlined"}
                                error={errors["writer"]?.userId ? true : false}
                                helperText={errors["writer"]?.userId?.message}
                            >
                                {(writerList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Submission Date" required />} 
                                name="writer.submissionDate"
                                changeDate={changeDate}
                                value={getValues("writer.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                        {/* Reviewer detail  */}
                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="reviewer.userId"
                                label={<CustomLabel label="Reviewer" required />} 
                                size={"small"}
                                variant={"outlined"}
                                error={errors["reviewer"]?.userId ? true : false}
                                helperText={errors["reviewer"]?.userId?.message}
                            >
                                {(reviewerList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}

                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Reviewer Date" required />}
                                name="reviewer.submissionDate"
                                changeDate={changeDate}
                                value={getValues("reviewer.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                        {/* Designer detail  */}
                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="illustrator.userId"
                                label={<CustomLabel label="Designer" required />}
                                size={"small"}
                                variant={"outlined"}
                                error={errors["illustrator"]?.userId ? true : false}
                                helperText={errors["illustrator"]?.userId?.message}
                            >
                                {(designerList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}

                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Designer Date" required />}
                                name="designer.submissionDate"
                                changeDate={changeDate}
                                value={getValues("illustrator.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                        {/* Publisher detail  */}
                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="publisher.userId"
                                label={<CustomLabel label="Publisher" required />}
                                size={"small"}
                                variant={"outlined"}
                                error={errors["publisher"]?.userId ? true : false}
                                helperText={errors["publisher"]?.userId?.message}
                            >
                                {(publisherList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}

                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Publisher Date" required />}
                                name="publisher.submissionDate"
                                changeDate={changeDate}
                                value={getValues("publisher.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                    </Grid>

                    <div className="mt-4 save-box">

                        <Button
                            type="submit"
                            disabled={!resourceAllocate("cms-assignment.write")}
                        >Save</Button>
                    </div>
                </form>
            </Box>

        </Menu>
    </>;
};

export default BulkAssignment;