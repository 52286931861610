import Joi from "joi";
import { required } from "../../shared";

export const blogContentValidation = Joi.object({
    author: Joi.string()
        .required()
        .label("Author")
        .messages({
            "string.empty": required,
        }),
    readTime: Joi.number()
        .optional()
        .allow("")
        .label("Read Time"),
    content: Joi.string()
        .required()
        .trim()
        .label("Content")
        .messages({
            "string.empty": required,
        }),
    metaTitle: Joi.string()
        .required()
        .label("Meta Title")
        .min(3)
        .trim()
        .max(65)
        .messages({
            "string.empty": required,
        }),
    metaDescription: Joi.string()
        .required()
        .min(3)
        .trim()
        .max(165)
        .label("Meta Description")
        .messages({
            "string.empty": required,
        }),
});
