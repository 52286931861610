import { IAlternativeComparisonResponse, IAlternativeComparisonsResponse, IAlternativeTimelineResponse } from "../../../interfaces/alternative-comparison";
import { IComparisonAssignmentsResponse } from "../../../interfaces/alternative-comparison/assignment";
import { IComparisonAlternativeResponseSummary } from "../../../interfaces/alternative-comparison/summary";
import { alternativeComparison, alternativeComparisonAssignment, alternativeComparisonTimeline } from "../../endpoints";
import HttpService from "../../http";

const AlternativeComparisonService = () => {
  const { httpRequest } = HttpService();

  const getAlternativeComparisons = async (
    search: object
  ): Promise<IAlternativeComparisonsResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonsResponse>(
        "GET",
        `${alternativeComparison}/list`,
        {},
        search
      )
        .then(resolve)
        .catch(reject);
    });
  const addComparison = async (
    payload: object
  ): Promise<IAlternativeComparisonResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
        "POST",
        `${alternativeComparison}`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const getComparison = async (
    query: object
  ): Promise<IAlternativeComparisonResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
        "GET",
        `${alternativeComparison}`,
        {},
        query
      )
        .then(resolve)
        .catch(reject);
    });

  const deleteComparison = async (
    payload: object
  ): Promise<IAlternativeComparisonResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
        "DELETE",
        `${alternativeComparison}`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const updateComparison = async (
    payload: object
  ): Promise<IAlternativeComparisonResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
        "PUT",
        `${alternativeComparison}`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const updateAlternativeComparisonStatus = async (
    payload: object
  ): Promise<IAlternativeComparisonResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
        "PUT",
        `${alternativeComparison}/status`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const getComparisonAlternativeSummary = async (
    search: object
  ): Promise<IComparisonAlternativeResponseSummary> =>
    new Promise((resolve, reject) => {
      httpRequest<IComparisonAlternativeResponseSummary>(
        "GET",
        `${alternativeComparison}/summary`,
        {},
        search
      )
        .then(resolve)
        .catch(reject);
    });

    const bulkAlternativeAssignment = async (payload: object): Promise<IComparisonAssignmentsResponse> =>
      new Promise((resolve, reject) => {
          httpRequest<IComparisonAssignmentsResponse>("POST", `${alternativeComparisonAssignment}/bulk-assign`, payload)
              .then(resolve)
              .catch(reject);
      });

    const saveChecks = async (payload: object): Promise<IAlternativeComparisonResponse> => new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
          "POST",
          "config-cms/check-list-alternative-comparison-mapping",
          payload
      )
          .then(resolve)
          .catch(reject);
    });

    const publishAlternative = async (payload: object): Promise<IAlternativeComparisonResponse> => new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
          "POST",
          `${alternativeComparison}/publish`,
          payload
      )
          .then(resolve)
          .catch(reject);
  });

  const scheduleAlternative = async (payload: object): Promise<IAlternativeComparisonResponse> => new Promise((resolve, reject) => {
      httpRequest<IAlternativeComparisonResponse>(
          "POST",
          `${alternativeComparison}/schedule`,
          payload
      )
          .then(resolve)
          .catch(reject);
  });

  const getAlternativeActivities = async (search: object): Promise<IAlternativeTimelineResponse> => new Promise((resolve, reject) => {
    httpRequest<IAlternativeTimelineResponse>(
        "GET",
        `${alternativeComparisonTimeline}`,
        {},
        search,
        false
    )
        .then(resolve)
        .catch(reject);
});
  return {
    getAlternativeComparisons,
    addComparison,
    getComparison,
    deleteComparison,
    updateComparison,
    updateAlternativeComparisonStatus,
    getComparisonAlternativeSummary,
    bulkAlternativeAssignment,
    saveChecks,
    publishAlternative,
    scheduleAlternative,
    getAlternativeActivities
  };
};

export { AlternativeComparisonService };
