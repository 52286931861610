import Joi from "joi";
import { required,valid_string} from "../../shared";

export const statusValidation = Joi.object({
    name: Joi.string()
        .required()
        .trim()
        .label("Status")
        .messages({
            "string.empty": required
        }),
    category: Joi.string()
        .required()
        .label("Category")
        .messages({
            "string.empty": required
        }),
    type: Joi.string()
        .valid("CANDIDATE" ,"CALL", "CPD", "JOB")
        .required()
        .label("Type")
        .messages({
            "any.only": valid_string
        }),
    status: Joi.string()
        .valid("ACTIVE")
        .required()
        .label("Type")
        .messages({
            "any.only": valid_string
        }),
});