import "./style.scss";
import { useEffect, useState, MouseEvent } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  GroupedByParameter,
  IParameterGroupedData,
  ISelfEvaluationRow,
} from "../../../../interfaces/pms/self-evaluation";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CustomTable from "../../../../components/mui/table";
import { BarGraph } from "../../../../components/graphs";
import Tag from "./tag";
import { SelfEvaluationService } from "../../../../services/pms/self-evaluation";
import { useSearchParams } from "react-router-dom";
import { IReportFilterDialog } from "../../../../interfaces";
import FeedbackParameters from "../feedback/filter";
import { formatDate } from "../../../../utilities/helper";
import { PMS_ERROR_MESSAGES } from "../../../../utilities/messages";

const columns = [
  {
    id: "parameters",
    label: "Parameters",
  },
  {
    id: "yourRating",
    label: "Your Rating",
  },
  {
    id: "teamRating",
    label: "Team Avg",
  },
  {
    id: "companyRating",
    label: "Company Avg",
  },
];

interface IState {
  period: string;
  analytics: {
    labels: string[];
    data: number[];
  };
}

const SelfEvaluationOverview = () => {
  let rows: ISelfEvaluationRow[] = [];
  const theme = useTheme();

  const [filters, setFilters] = useState<{
    createdAt?: {
      startDate?: string;
      endDate?: string;
    };
  }>({ createdAt: undefined });

  const {
    getSelfEvaluationOverview,
    getRatingAnalytics,
    getSelfEvaluationOverviewParameterWise,
  } = SelfEvaluationService();

  const selfEvaluationOverview = useQuery({
    queryKey: ["selfEvaluationOverview", filters],
    queryFn: () =>
      getSelfEvaluationOverview({
        startDate: filters && filters.createdAt && filters.createdAt.startDate,
        endDate: filters && filters.createdAt && filters.createdAt.endDate,
      }),
  });
  const ratings =
    selfEvaluationOverview &&
    selfEvaluationOverview.data &&
    selfEvaluationOverview.data.data;

  const [filtersCount, setFiltersCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IState>({
    period: "monthly",
    analytics: {
      labels: [],
      data: [],
    },
  });

  const parameterWise = useQuery({
    queryKey: ["getSelfEvaluationOverviewParameterWise", filters],
    queryFn: () =>
      getSelfEvaluationOverviewParameterWise({
        startDate: filters && filters.createdAt && filters.createdAt.startDate,
        endDate: filters && filters.createdAt && filters.createdAt.endDate,
      }),
  });
  const paramterWiseData =
    parameterWise && parameterWise.data && parameterWise.data.data; 

  const getRatingAnalyticsData = useQuery({
    queryKey: [state.period, "bar-graph-data", filters],
    queryFn: () =>
      getRatingAnalytics({
        period: state.period,
        startDate: filters && filters.createdAt && filters.createdAt.startDate,
        endDate: filters && filters.createdAt && filters.createdAt.endDate,
      }),
  });

  const [filterState, setFilterState] = useState<IReportFilterDialog>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
  });

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const anchorElement = e.currentTarget;

    setFilterState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: anchorElement,
        isOpen: !filterState.filterDialog.isOpen,
      },
    }));
  };

  const closeFilter = () => {
    setFilterState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  useEffect(() => {
    if (!searchParams.get("date")) {
      const today = new Date();
      const lastYear = new Date();
      lastYear.setFullYear(today.getFullYear() - 1);

      const defaultDateFilter = [
        {
          key: "custom",
          value: "Custom",
          startDate: lastYear.toISOString(),
          endDate: today.toISOString(),
        },
      ];

      searchParams.set("date", JSON.stringify(defaultDateFilter));
      setSearchParams(searchParams);
    }
  }, []);

  const determineIntervalType = (interval: string): string => {
    const [startDate, endDate] = interval.split("-");
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error(PMS_ERROR_MESSAGES.en.invalid_date_format);
    }
    const diffTime = end.getTime() - start.getTime();
    const diffDays = diffTime / (1000 * 3600 * 24);
    if (diffDays >= 28 && diffDays <= 31) {
      return "Month"; 
    } else if (diffDays >= 90 && diffDays <= 92) {
      return "Quarter";
    } else {
      return state.period === "monthly" ? "Month" : "Quarter";
    }
  };

  const formatMonth = (date: string) => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return date;
    return `${parsedDate.getFullYear()}-${String(parsedDate.getMonth() + 1).padStart(2, "0")}`;
  };
  const formatQuarter = (date: string) => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return date;  
    
    const year = parsedDate.getFullYear();
    const month = parsedDate.getMonth(); 
    const quarter = Math.floor(month / 3) + 1;
    return `${year} Q${quarter}`;
  };
  useEffect(() => {
    const labels: string[] = [];
    const data: number[] = [];
  
    if (getRatingAnalyticsData.data?.data.intervalAverages.length) {
  
      getRatingAnalyticsData.data?.data.intervalAverages.forEach((ele) => {
        const intervalParts = ele.interval.split("-");
        if (intervalParts.length === 2) {
          const [startDate] = intervalParts;
          const isMonthInterval = determineIntervalType(ele.interval) === "Month";
          const formattedInterval = isMonthInterval
            ? formatMonth(startDate.trim())  
            : formatQuarter(startDate.trim());
  
          labels.push(formattedInterval);
        } else {
          labels.push(ele.interval);
        }
  
        data.push(ele.average);
      });
    }
  
    setState((prevState) => ({
      ...prevState,
      analytics: {
        labels,
        data,
      },
    }));
  }, [getRatingAnalyticsData.data?.data]); 

  const onChange = async (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    let filterCount = 0;
    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];
    filterCount += date.length ? 1 : 0;
    let createdAt:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }
    setFiltersCount(filterCount);
    setFilters((prevState) => ({
      ...prevState,
      createdAt,
    }));
  }, [searchParams]);

  const showAnalytics = () => {
    const data = {
      labels: [],
      datasets: [{ data: [], backgroundColor: theme.palette.primary.light }],
    };

    return {
      ...data,
      labels: state.analytics.labels,
      datasets: [
        {
          label: "Ratings",
          data: state.analytics.data,
          backgroundColor: theme.palette.primary.light,
        },
      ],
    };
  };

  const formatTableRows = (data: IParameterGroupedData | undefined) => {
    if (!data) return []; 
  
    const {
      myTeamGroupedByParamter,
      myGroupedByParamter,
      companyGroupedByParamter,
    } = data;
  
    const mapRatingsByParameterId = (groupedData: GroupedByParameter[]) => {
      const ratingsMap: Record<string, number> = {};
      groupedData.forEach((group) => {
        group.parameters.forEach((param) => {
          ratingsMap[param.parameterId] = param.averageRating || 0;
        });
      });
      return ratingsMap;
    };
  
    const teamRatingsMap = mapRatingsByParameterId(myTeamGroupedByParamter);
    const yourRatingsMap = mapRatingsByParameterId(myGroupedByParamter);
    const companyRatingsMap = mapRatingsByParameterId(companyGroupedByParamter);
  
    const uniqueParameters = myGroupedByParamter.flatMap((group) =>
      group.details.map((detail) => ({
        parameterId: detail._id,
        name: detail.name,
      }))
    );

    const rows = uniqueParameters.map((param) => ({
      parameters: param.name, 
      yourRating: yourRatingsMap[param.parameterId]?.toFixed(2) || "0.00", 
      teamRating: teamRatingsMap[param.parameterId]?.toFixed(2) || "0.00", 
      companyRating: companyRatingsMap[param.parameterId]?.toFixed(2) || "0.00", 
    }));
  
    return rows;
  };
  
 rows = formatTableRows(paramterWiseData);
  
  return (
    <div className="self-evaluation">
      <Box className="self-evaluation-overview">
        <Typography
          className="head"
          variant="h6"
          marginBottom="10px"
          style={{ fontWeight: 700 }}
        >
          Overview Ratings
        </Typography>
        <Box className="tab-header">
          <Box className="tab-header-box">
            <Box display="flex" alignItems="center" className="filter-box">
              <Box className="date-range">
                From {formatDate(filters && filters.createdAt && filters.createdAt.startDate)} to {formatDate(filters && filters.createdAt && filters.createdAt.endDate)}
              </Box>
              <Tooltip title="Filter">
                <Badge
                  className="h-100 ml-2"
                  color="primary"
                  badgeContent={filtersCount}
                  invisible={!filtersCount}
                >
                  <Button
                    className={
                      filtersCount ? "filter active" : "filter default"
                    }
                    variant="outlined"
                    onClick={openFilter}
                  >
                    <FilterListIcon />
                  </Button>
                </Badge>
              </Tooltip>
            </Box>
            <FeedbackParameters
              anchorEl={filterState.filterDialog.anchorEl}
              isOpen={filterState.filterDialog.isOpen}
              OnClose={closeFilter}
            />
          </Box>
        </Box>
      </Box>
      <Box className="self-overview-tabs">
        <Tag
          name="Overall Average Rating"
          count={ratings?.overallMyAverage || 0}
        />
        <Tag name="Team`s Average Rating" count={ratings?.teamAverage || 0} />
        <Tag
          name="Company`s Average Rating"
          count={ratings?.overallComapnyAverage || 0}
        />
      </Box>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <Box
            className="center"
            marginBottom="15px"
            justifyContent="space-between"
          >
            <>
              <Typography
                className="head"
                variant="h6"
                marginBottom="10px"
                style={{ fontWeight: 700 }}
              >
                Overall Performance
              </Typography>
            </>
            <div className="date-picker position-relative">
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  size="small"
                  name="period"
                  value={state.period}
                  onChange={onChange}
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="quarterly">Quarterly</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>
          <BarGraph data={showAnalytics()} height={320} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className="center"
            marginBottom="15px"
            justifyContent="space-between"
          >
            <div>
              <Typography
                className="head"
                variant="h6"
                marginBottom="10px"
                style={{ fontWeight: 700 }}
              >
                Parameter Wise Rating
              </Typography>
            </div>
          </Box>
          <CustomTable columns={columns} rows={rows} height={340} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SelfEvaluationOverview;
