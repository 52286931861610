import { IEvaluationDataResponse, IKrRequestResponse, IKrRequestsResponse } from "../../../interfaces/okr";
import { krEvaluations } from "../../endpoints";
import HttpService from "../../http";

const EvaluationsService = () => {
    const { httpRequest } = HttpService();

    const getEvaluations = async (search: object): Promise<IKrRequestsResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestsResponse>(
            "GET",
            `${krEvaluations}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getPendingEvaluation = async (search: object): Promise<IEvaluationDataResponse> => new Promise((resolve, reject) => {
        httpRequest<IEvaluationDataResponse>(
            "GET",
            `${krEvaluations}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const approveOrRejectRequest = async (payload: object): Promise<IKrRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrRequestResponse>(
            "POST",
            `${krEvaluations}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getEvaluations, getPendingEvaluation, approveOrRejectRequest };
};

export { EvaluationsService };