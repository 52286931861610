import Joi from "joi";
import { required } from "../shared";

export const contentCategoryValidation = Joi.object({
    slug: Joi.string()
        .trim()
        .min(3)
        .max(40)
        .required()
        .label("Slug")
        .messages({
            "string.empty": required,
        }),
    _product: Joi.string()
        .required()
        .label("Product")
        .messages({
            "string.empty": required,
        }),
    priority: Joi.number()
        .required()
        .label("Priority")
        .messages({
            "string.empty": required,
        }),
    _category: Joi.string()
        .required()
        .label("Category")
        .messages({
            "string.empty": required,
        }),
    category_para: Joi.string()
        .trim()
        .min(3)
        .required()
        .label("Category Para")
        .messages({
            "string.empty": required,
        }),
    metaTitle: Joi.string()
        .trim()
        .min(3)
        .max(65)
        .required()
        .label("Meta Title")
        .messages({
            "string.empty": required,
        }),
    metaDescription: Joi.string()
        .trim()
        .min(3)
        .max(165)
        .required()
        .label("Meta Description")
        .messages({
            "string.empty": required,
        }),
    _keywords: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Keywords")
        .messages({
            "array.empty": required,
        }),
});