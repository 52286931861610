import { ICustomUrlImportData } from "../../../../interfaces/site-map/custom-urls";

import ProjectsService from "../../../../services/content/projects-products";


const usevalidateCustomUrl = () => {
    const { getContentProjectPartial } = ProjectsService();

    const validateCustomUrl = async (customUrls: (string | number)[][]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["_product","slug","priority","changeFrequency"];
            const payload = [];
            const error = [];
            let row = 2;

            for await (const customUrl of customUrls) {
                if (!customUrl?.length) {
                    ++row;
                    continue;
                }

                const data: ICustomUrlImportData = {
                    _product: "",
                    slug: "",
                    changeFrequency: "",
                    priority: 0
                };

                const errMessage: Record<string, string> = {};

                   
                if (typeof customUrl[0] === "string") {
                    try {
                        const project = await getContentProjectPartial({
                            search: customUrl[0].trim(),
                        });
                        if (project.data?._id) {
                            data._product = project.data?._id;
                        } else {
                            errMessage["_product"] = "Please enter a valid product name";
                        }
                    } catch (error) {
                        errMessage["_product"] = "Please enter a valid product name";
                    }
                } else {
                    if (customUrl[0] !== undefined) {
                        errMessage["_product"] = "Please enter a valid product name";
                    }
                }

                if (typeof customUrl[1] === "string") {
                    data.slug = customUrl[1];
                } else {
                    if (customUrl[1] === undefined) {
                        errMessage["slug"] = "Slug is required";
                    } else {
                        errMessage["slug"] = "Slug must be string";
                    }
                }
                if (typeof customUrl[2] === "number") {
                    data.priority = customUrl[2];
                } else {
                    if (customUrl[2] === undefined) {
                        errMessage["priority"] = "Priority is required";
                    } else {
                        errMessage["priority"] = "Priority must be a number";
                    }
                }
                if (typeof customUrl[3] === "string") {
                    data.changeFrequency = customUrl[3].toLowerCase();
                } else {
                    if (customUrl[2] === undefined) {
                        errMessage["changeFrequency"] = "Change Frequency is required";
                    } else {
                        errMessage["changeFrequency"] = "Change Frequency must be string";
                    }
                }


                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateCustomUrl };
};
export default usevalidateCustomUrl;
