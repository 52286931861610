
import HttpService from "../../http";
import { glossaryKeywordDensity, glossaryScore } from "../../endpoints";
import { IGlossaryKeywordDensityResponses, IGlossaryScoreResponse, IGlossaryScoresResponse } from "../../../interfaces/content-marketing/glossary/score";
const GlossaryScoreService = () => {

    const { httpRequest } = HttpService();
    const getGlossaryScoreList = async (search: object): Promise<IGlossaryScoresResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryScoresResponse>(
                "GET",
                `${glossaryScore}/list`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

        const getGlossaryKeywordDensity = async (search: object): Promise<IGlossaryKeywordDensityResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryKeywordDensityResponses>(
                "GET",
                `${glossaryKeywordDensity}`,
                {},
                search,
                false
            )
                .then(resolve)
                .catch(reject);
        });


    const addGlossaryScore = async (payload: object): Promise<IGlossaryScoreResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryScoreResponse>(
            "PUT",
            `${glossaryScore}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getGlossaryScore = async (query: object): Promise<IGlossaryScoreResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryScoreResponse>(
            "GET",
            `${glossaryScore}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { addGlossaryScore, getGlossaryScoreList, getGlossaryScore, getGlossaryKeywordDensity };

};

export { GlossaryScoreService };