import { Box, capitalize, Tab, Tabs } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams, Outlet } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DialogContent from "@mui/material/DialogContent";
import { okrKeyResultsService } from "../../../../../services/okr/objectives/key-results";
import { a11yProps, BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../../components/shared/mui-tabs";
import KeyResultTimeline from "./timeline";

const redirectionLinks = ["timeline"];
interface outletProps {
  reFetch: () => void
}

const KeyResultDatails = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { objectiveId, krId } = useParams();
  const [value, setValue] = useState(0);
  const { getKeyResult } = okrKeyResultsService();
  const [searchParam, setSearchParam] = useSearchParams();
  const kr = useQuery({
    queryKey: ["kr-details", krId],
    queryFn: () => getKeyResult({ _id: krId })
  });
  
  useEffect(() => {
    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "timeline";
    setValue(redirectionLinks.indexOf(tab));
  }, [searchParam]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    searchParam.set("type", redirectionLinks[newValue]);
    setSearchParam(searchParam);
  };

  const onClose = () => {
    searchParam.delete("type");
    navigate({ pathname: `/okr-objectives/key-results/${objectiveId}`, search: searchParam.toString() });
  };

  const name = kr.data?.data.name ? capitalize(kr.data?.data.name) : "Key Result Details";

  return <div>
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      open={!!krId}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          {name}
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Box>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab className="tabs-space start-tab-space" label="Timeline" {...a11yProps(0)} />
              </Tabs>
            </Box>
          </Box>
          <TabPanel
            style={{
              height: "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={0}>
            <KeyResultTimeline />
          </TabPanel>

        </Box>
      </DialogContent>
    </BootstrapDialog>

    <Outlet context={{...outlet}}/>
  </div>;
};

export default KeyResultDatails;
