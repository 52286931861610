import { FC } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../../components/shared/mui-tabs";
import { Box, DialogContent } from "@mui/material";
import CustomTable from "../../../../../components/mui/table";
import { IColumn } from "../../../../../interfaces";
interface props {
    onClose: () => void;
}

const ShowCriteria: FC<props> = ({ onClose }) => {
    const columns : IColumn[] = [
        {
            id: "score",
            label: "Score",
        },
        {
            id: "category",
            label: "Category",
        },
        {
            id: "description",
            label: "Description",
            maxWidth: 500,
            whiteSpace: "normal",
            wordBreak: "keep-all"
        },
        {
            id: "expectation",
            label: "Task Completion Expectation",
            maxWidth: 500,
            whiteSpace: "normal",
            wordBreak: "keep-all"
        }
    ];
    const rows = [
        {
            score: "0",
            category: "Beginner",
            description: "The employee has no knowledge or experience in the skill.",
            expectation: "0% - Unable to perform tasks related to this skill."
        },
        {
            score: "1",
            category: "Learner",
            description: "The employee knows the skill in theory but lacks practical experience.",
            expectation: "20% - Can understand basic concepts but cannot apply them effectively."
        },
        {
            score: "2",
            category: "Helper",
            description: "The employee can assist existing resources but cannot take full responsibility.",
            expectation: "40% - Can help with tasks but relies on others for direction and completion."
        },
        {
            score: "3",
            category: "Contributor",
            description: "The employee can take responsibility for tasks with some supervision and guidance.",
            expectation: "60% - Can handle tasks independently with occasional support."
        },
        {
            score: "4",
            category: "Expert",
            description: "The employee can take full responsibility for tasks without the need for supervision.",
            expectation: "100% - Completes tasks independently and meets all expectations."
        },
        {
            score: "5",
            category: "Leader",
            description: "The employee can manage tasks independently and oversee the work of others, providing guidance.",
            expectation: "120% - Not only completes tasks but also mentors and leads others to success."
        }
    ];
    return (
        <BootstrapDialog
            maxWidth="md"
            onClose={onClose}
            open={true}
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    Scoring Criteria Details
                </Box>
            </BootstrapDialogTitle>

            <DialogContent dividers>
                <CustomTable columns={columns} rows={rows} />
            </DialogContent>

        </BootstrapDialog>
    );
};

export default ShowCriteria;