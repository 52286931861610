import { useState } from "react";
import { IErrorResponse, IOfficeEmployeeField } from "../../../../interfaces";
import { Box, Grid, TextField } from "@mui/material";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IOfficeEmployee } from "../../../../interfaces";
import { OfficeEmployeeImageService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useEffect } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import { OfficeEmployeeInageValidation } from "../../../../validations";
import UploadImg from "../../../../assets/images/upload.png";
import HttpService from "../../../../services/http";
import { capitalize } from "../../../../utilities/helper";
interface outletProps {
    reFetch: () => void
}


const ManageEmployeeImage = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { httpFormRequest } = HttpService();
    const characterLimit = 200;
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const [imageSelected, setImageSelected] = useState<string | null>(null);
    const { addOfficeEmployeeImage, getOfficeEmployeeImage, updateOfficeEmployeeImage } = OfficeEmployeeImageService();
    const employeeImage = useQuery({ queryKey: [hitQuery], queryFn: () => getOfficeEmployeeImage({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, trigger, formState: { errors } } = useForm<IOfficeEmployee>({
        resolver: joiResolver(OfficeEmployeeInageValidation),
        defaultValues: {
            image: "",
            altText: "",
        }
    });

    useEffect(() => {
        if (id === "new") {
            console.log("new");
        } else {
            if (employeeImage.data?.data) {
                setValue("image", employeeImage.data.data.image);
                setValue("altText", capitalize(employeeImage.data.data.altText));
                setImageSelected(employeeImage.data.data.image);
                trigger("image");
            }
        }
    }, [id, employeeImage.data]);

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
        try {
            if (e.target.files && e.target.files.length > 0) {
                const uploaded = await httpFormRequest<{ data: string }>(
                    e.target.files,
                    e.target?.files ? e.target?.files[0].name : "",
                    ["webp"],
                    0.098
                );
                const keyExist = type === "image";
                if (keyExist) {
                    setValue(type, uploaded.data);
                    setImageSelected(URL.createObjectURL(e.target.files[0]));
                    trigger(type);
                }
            }
        } catch (error) {
            console.log("error in candidate detail upload", error);
        }
    };

    const onSubmit = async (data: IOfficeEmployee) => {
        try {
            if (id === "new") {
                const payload = { ...data };
                const add = await addOfficeEmployeeImage(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/careers/office-employee-images",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            } else {
                const payload = { ...data, _id: id };
                const update = await updateOfficeEmployeeImage(id ? id : "", payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/careers/office-employee-images",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/careers/office-employee-images",
            search: searchParam.toString()
        });
    };

    const fields: IOfficeEmployeeField[] = [
        {
            label: "Name*",
            name: "image",
            type: "upload",
        },
        {
            label: "Image Alt*",
            name: "altText",
            type: "multiline",
            placeholder: "Type alt text here  (Characters should not more than 200)",
            width: 12
        },
    ];

    return (
        <Box>
            <CustomDialog
                title={id !== "new" ? "Edit Employee Image" : "Add Employee Image"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "upload") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Box className="center">
                                        <Box height="100px" width="100px" className="upload-img editable" aria-label="upload picture" component="label">
                                            <img src={imageSelected || UploadImg} alt="review" />
                                            <div className="edit-img ">{imageSelected ? "Edit" : "Add"}</div>
                                            <input hidden accept="image/*" type="file" onChange={e => uploadFile(e, field.name)} />
                                        </Box>
                                    </Box>
                                    {errors[field.name] && <span style={{ color: "#d32f2f", marginLeft: "376px", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                                </Grid>
                                );
                            }
                            else if (field.type === "multiline") {
                                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={
                                                errors[field.name]?.message ||
                                                `${prop.field.value.length}/${characterLimit} characters`
                                            }
                                            multiline
                                            minRows={4}
                                            inputProps={{
                                                maxLength: characterLimit,
                                            }}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageEmployeeImage;