import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import "./style.scss";

import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  Grid,
  TextField,
   MenuItem,
  IconButton,
  Typography,
  InputAdornment,
  Radio,
  Button,
  Tooltip,
} from "@mui/material";
 import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
 } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import useSnackbar from "../../../../../../../../hooks/useSnackbar";
import TestInputDialog from "../../../../../../../../components/mui/dialog/testInputDialog";
import NumberInputIntroduction from "../../../../../../../../components/mui/custom-number-input";
import { useQuery } from "@tanstack/react-query";
import { TestsService } from "../../../../../../../../services/configuration/tests";
import { testQuestionValidation } from "../../../../../../../../validations/configuration/test/test-question";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse } from "../../../../../../../../interfaces";
import { ITestCategories } from "../../../../../../../../interfaces/configuration/tests";
import { cleanHtmlString } from "../../../../../../../../utilities/helper";
import CustomDialog from "../../../../../../../../components/mui/dialog";
import useDebounce from "../../../../../../../../hooks/useDebounce";
import { AssessmentMediaService } from "../../../../../../../../services/configuration/assessment-media";
import { IAssessmentMedia } from "../../../../../../../../interfaces/configuration/assessment-media";

interface Field {
  label: string;
  name: "question" | "_category" | "difficulty";
  type: "input" | "select";
  placeholder: string;
  options?: ITestCategories[] | { _id: string; name: string }[];
}


interface Answer {
  option: string;
  weightage: number;
  id: number;
}

interface ITestQuesData {
  options: Answer[];
  question: string;
  _category: string;
  difficulty: string;
}
const AddQuestion: React.FC = () => {
  const { control, setValue, handleSubmit, trigger, formState: { errors } } = useForm<ITestQuesData>(
    {
      resolver: joiResolver(testQuestionValidation),
      defaultValues: {
        question: "",
        _category: "",
        difficulty: "",
        options: [{ option: "", weightage: 0, id: 1 }],
      }
    }
  );
  const navigate = useNavigate();
  const { getTestCategoryList, createTestQuestion, getSingleTestQuestion, updateTestQuestion } = TestsService();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();

  interface OutletProps {
    reFetch: () => void;
    refetchTest: () => void;
  }

  const outlet = useOutletContext<OutletProps>();
  const [answers, setAnswers] = useState<Answer[]>([
    { option: "", weightage: 0, id: 1 },
  ]);
  
  useEffect(() => () => setAnswers([]), []);
  const [answerIdCounter, setAnswerIdCounter] = useState<number>(2);
  
  const { categoryQuesId, testId } = useParams();
  const categories = useQuery({
    queryKey: ["test-category"],
    
    queryFn: () => getTestCategoryList({
      _test: testId,
    })
  });

  const question = useQuery({
    queryKey: ["question-data"],
    
    queryFn: () => getSingleTestQuestion({
      _id: categoryQuesId,
    }),
    enabled: categoryQuesId === "new" ? false : true
  });
  const fields: Field[] = [
    {
      label: "Question",
      name: "question",
      type: "input",
      placeholder: "Question?",
    },
    {
      label: "Question Category",
      name: "_category",
      type: "select",
      placeholder: "options",
      options: categories?.data?.data || [],
    },
    {
      label: "Difficulty",
      name: "difficulty",
      type: "select",
      placeholder: "Option",
      options: [
        { _id: "EASY", name: "Easy" },
        { _id: "MEDIUM", name: "Moderate" },
        { _id: "HARD", name: "Hard" },
      ]
    }
  ];
  const onSubmit = async (data: ITestQuesData) => {

    const formData = {
      ...data,
      question: cleanHtmlString(data.question),
      options: data.options.map((optionValue: Answer) => ({
        option: optionValue.option,
        weightage: optionValue.weightage
      }))
    };

    try {
      if (categoryQuesId === "new") {
        const response = await createTestQuestion({ ...formData, _test: testId });
        snackbar(response?.message, "info");
        navigate({
          pathname: `/assessment/tests/test-info/manage/${testId}`,
          search: searchParam.toString()
        });
      
        outlet.reFetch && outlet.reFetch();
        outlet.refetchTest && outlet.refetchTest();


      } else {
        const response = await updateTestQuestion({ ...formData, _test: testId, _id: categoryQuesId });
        snackbar(response?.message, "info");
        navigate({
          pathname: `/assessment/tests/test-info/manage/${testId}`,
          search: searchParam.toString()
        });
        outlet.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "warning");
      console.error("Validation error:", err);
    }
  };

  useEffect(() => {
    if (categoryQuesId !== "new" && question?.data?.data) {
      setValue("question", question?.data?.data?.question);
      setValue("_category", question?.data?.data?._category?._id);
      setValue("difficulty", question?.data?.data?.difficulty);
      setValue("options",question?.data?.data?.options.map((option, index) => ({
        option: option.option,
        weightage: option.weightage,
        id: index + 1,
      })));
      setAnswers(question?.data?.data?.options.map((option, index) => ({
        option: option.option,
        weightage: option.weightage,
        id: index + 1,
      })));
    }
  }, [categoryQuesId, question?.data]);

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = {
      ...updatedAnswers[index],
      option: e.target.value,
    };
    setValue("options", updatedAnswers);
    trigger("options");
    setAnswers(updatedAnswers);
  };

  const handlePointsChange = (value: number | null, index: number) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = {
      ...updatedAnswers[index],
      weightage: value ? value : 0,
    };
    setValue("options", updatedAnswers);
    setAnswers(updatedAnswers);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery);
  const [loading, setLoading] = useState(false);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [images, setImages] = useState<IAssessmentMedia[]>([]);
  const [page, setPage] = useState(1);
  const [showImagesDialog, setShowImagesDialog] = useState(false);
  const quillRef = useRef<ReactQuill>(null);
  const { getAssessmentMediaList } = AssessmentMediaService();

  const handleImageClick = (image: IAssessmentMedia) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);
      const imageUrl = `${process.env.REACT_APP_S3_BASE_URL}${image.imageUrl}`;
      
      if (range) {
        editor.insertEmbed(range.index, "image", imageUrl);
        editor.setSelection(range.index + 1, 0);
      }
    }
    
    
    setShowImagesDialog(false);
    setSearchQuery("");
  };

  const fetchImages = async (page: number, searchQuery = "") => {
    if (loading)return;
    setLoading(true);
    try {
      const response = await getAssessmentMediaList({ page, pagination: true, limit:20, search: searchQuery });
      const newImages = response.data;
      if (newImages.length < 20) {
        setHasMoreImages(false); 
      }
      setImages((prevImages) => [...prevImages, ...newImages]);
      setPage(page + 1);
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if(showImagesDialog){
      setImages([]);
      setPage(1);
      setHasMoreImages(true);
      fetchImages(1, debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, showImagesDialog]); 

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const scrollHeight = target.scrollHeight;
    const scrollTop = target.scrollTop;
    const clientHeight = target.clientHeight;
    const nearBottom = scrollHeight - scrollTop - clientHeight <= 50;
    if (hasMoreImages && nearBottom) {
      fetchImages(page);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchQuery(searchValue);
  };
  const handleAddAnswer = () => {
    const newAnswer = {
      option: "",
      weightage: 0,
      id: answerIdCounter,
    };

    setValue("options", [...answers, newAnswer]);
    setAnswers([...answers, newAnswer]);
    setAnswerIdCounter(answerIdCounter + 1);
  };

  const handleRemoveAnswer = (id: number) => {
    const updatedAnswers = answers.filter((answer) => answer.id !== id);
    setValue("options", updatedAnswers);
    setAnswers(updatedAnswers);
  };

  const onClose = () => {
    navigate({
      pathname: `/assessment/tests/test-info/manage/${testId}`,
      search: searchParam.toString()
    });
  };
 

 // Custom toolbar options
 const modules = {
  toolbar: [
    ["bold", "italic", "underline"]
  ],
};

const isQuillEmpty = (value: string) => {
  if (!value.replace(/<(.|\n)*?>/g, "").trim().length && !value.includes("<img")) {
    return true;
  }
  return false;
};

  return (
    <TestInputDialog
      title={categoryQuesId !== "new" ? "Edit Question" : "Create Question"}
      isOpen={!!categoryQuesId}
      onClose={onClose}
      primaryButton={{
        name: "Cancel",
        color: "primary",
        onClick: onClose,
      }}
      secondaryButton={{
        name: "Save Changes",
        color: "primary",
        onClick: handleSubmit(onSubmit),
      }}
    >                  
    
      <Grid container spacing={2} sx={{ padding: "0.5rem" }}>
        <Grid item xs={12}>
          <Typography variant="body1">Question?</Typography>
        </Grid>
        {fields.map((field, index) => (
          <Grid item xs={index === 0 ? 12 : 4} key={index}>
            <Controller
              name={field.name}
              control={control}
              render={({ field: controllerField }) => (
                <>
                  {field.type === "select" ? (
                    <Grid item xs={12}>
                      <TextField
                        {...controllerField}
                        select
                        label={field.label}
                        
                        variant="outlined"
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        InputProps={{
                          style: {
                            height: "46px",
                          },
                        }}
                      >
                        {(field.options || []).map(
                          (
                            option:
                              | ITestCategories
                              | { _id: string; name: string }
                          ) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>
                  ) : (
                    <Grid container xs={12} spacing={3}>
                      <Grid item xs={10}>
                        <div
                          className={
                            errors[field.name] ? "ql-editor-error" : ""
                          }
                        >
                          <ReactQuill
                            ref={quillRef}
                            modules={modules}
                            theme="snow"
                            value={controllerField.value}
                            onChange={(content) => {
                              if (!isQuillEmpty(content)) {
                                controllerField.onChange(content);
                              } else {
                                controllerField.onChange("");
                              }
                            }}
                            placeholder={field.placeholder}
                            style={{
                              display: "flex",
                              flexDirection: "column-reverse",
                            }}
                          />
                          {errors[field.name] && (
                            <Typography color="error" variant="caption">
                              {errors[field.name]?.message as React.ReactNode}
                            </Typography>
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={2}>
                        <Button
                          size="medium"
                          onClick={() => setShowImagesDialog(true)}
                        >
                          Add Image
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="body1">Options:</Typography>
        </Grid>

        {answers.map((answer, index) => (
          <React.Fragment key={answer.id}>
            <Grid item xs={9} style={{ height: "calc(100% / 7)" }}>
              <TextField
                type="input"
                placeholder="options"
                fullWidth
                value={answer.option}
                error={
                  errors?.options && errors.options[index]?.option
                    ? true
                    : false
                } // Check for option errors
                helperText={
                  errors?.options && errors.options[index]?.option?.message
                }
                onChange={(e) => handleAnswerChange(e, index)}
                style={{ height: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Radio
                        checked={false}
                        disabled={true}
                        inputProps={{ "aria-label": "Select Correct Answer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                {
                  <NumberInputIntroduction
                    initialValue={answer.weightage}
                    onChange={handlePointsChange}
                    index={index}
                  />
                }
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "16px",
                    height: "60%",
                    paddingLeft: "0.5rem",
                    color: "#666666",
                  }}
                >
                  points
                </Typography>
              </Grid>
              {index > 0 && (
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    onClick={() => handleRemoveAnswer(answer.id)}
                    style={{ padding: "0.1rem", backgroundColor: "#AA263F" }}
                  >
                    <RemoveIcon style={{ fontSize: "1rem", color: "white" }} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12} sx={{ marginBottom: "2rem", marginTop: "1rem" }}>
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              textDecorationColor: "#346CF5",
              color: "#346CF5",
            }}
            onClick={handleAddAnswer}
          >
            Add Option
          </span>
        </Grid>
      </Grid>
      <Outlet />
      {/* </CustomDialog> */}

      <CustomDialog
        title="Select Image"
        isOpen={showImagesDialog}
        onClose={() => {
          setShowImagesDialog(false);
          setSearchQuery("");
        }}
        disabled={true}
      >
        <TextField
          fullWidth
          label="Search Images"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-bar"
        />

        {images?.length ? (
          <div className="images-container" onScroll={handleScroll}>
            <Grid container spacing={2}>
              {images.map((image, i) => (
                <Grid key={i} item xs={6} sm={4} md={3} lg={3}>
                  <div
                    className="image-wrapper"
                    onClick={() => handleImageClick(image)}
                  >
                    <img
                      src={`${process.env.REACT_APP_S3_BASE_URL}${image.imageUrl}`}
                      alt={`Image ${i}`}
                    />
                    <div className="hover-overlay">Select</div>
                  </div>
                  <Tooltip title={image.name} arrow>
                    <Typography className="image-name" variant="body1" noWrap>
                      {image.name.length > 50
                        ? `${image.name.slice(0, 50)}…`
                        : image.name}
                    </Typography>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <div className="no-images">
            <p>Unfortunately, no images are currently available!</p>
          </div>
        )}
      </CustomDialog>
    </TestInputDialog>
  );
};

export default AddQuestion;
