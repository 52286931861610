import "../../../manageTestCategories/style.scss";
import { Box, Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ResponseDialogTest from "../../../../../../../components/mui/dialog/responseDialogTest";
import { TabPanel } from "../../../../../../cpd-layout/drives/drive-detail";
import ResponseResult from "./results";
import ResponseBasicDetail from "./basicDetails";
import ResponseAllQuestions from "./allQuestions";
import { useQuery } from "@tanstack/react-query";
import { CandidateService } from "../../../../../../../services";
import { capitalize } from "../../../../../../../utilities/helper";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[];
}

const ManageResponses: FC = () => {
  const [value, setValue] = useState<number>(0);
  const { testId,leadId } = useParams();

  const navigate = useNavigate();
  const [searchParam,setSearchParam] = useSearchParams();

  const { getCandidatePartial } = CandidateService();

  const candidateDetails = useQuery({
    queryKey: ["candidate"],
    queryFn: () => getCandidatePartial({ _id: leadId })
  });
  const [state] = useState<IState>({
    redirectionLinks: ["Basic Details", "results", "all Questions"],
  });

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("subType", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
    setValue(newValue);
  };

  const onClose = () => {
    searchParam.delete("subType");
    navigate({
      pathname: `/assessment/tests/test-info/manage/${testId}`,
      search: searchParam.toString(),
    });
  };

  useEffect(() => {
    const tab = searchParam.get("subType") ? String(searchParam.get("subType")) : "results";
    setValue(state.redirectionLinks.indexOf(tab));
  }, [searchParam]);

  return (
    <>
      <ResponseDialogTest
        title={capitalize(candidateDetails.data?.data.name ?? "")}
        secondaryText={candidateDetails?.data?.data?.email ?? ""}
        isOpen={true}
        onClose={onClose}
        size={"lg"}
        disabled
      >
        <div className="response-tab">
          <Box width="100%">
            <Box sx={{ borderBottom: 1, borderColor: "divider" , margin:"0px 8px"}}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {state.redirectionLinks.map((link, index) => (
                  <Tab
                    key={index}
                    className={index === 0 ? "tabs-space start-tab-space" : "tabs-space"}
                    label={link.charAt(0).toUpperCase() + link.slice(1)}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Box>
            {state.redirectionLinks.map((link, index) => (
              <TabPanel key={index} value={value} index={index}>
                {link === "Basic Details" && <ResponseBasicDetail />}
                {link === "results" && <ResponseResult />}
                {link === "all Questions" && <ResponseAllQuestions />}
              </TabPanel>
            ))}
          </Box>
        </div>
      </ResponseDialogTest>
    </>
  );
};

export default ManageResponses;
