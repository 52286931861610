import React, { ChangeEvent, useEffect } from "react";
import { Box, Button, Grid, InputLabel, Link, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomLabel from "../../../../components/mui/custom-label";
import { IOrganisationConfigurationTheme, IOrganisationConfigurationThemeField } from "../../../../interfaces/organisationConfiguration/basic-details";
import { organisationConfigurationDataService } from "../../../../services/organisation-themes";
import { IErrorResponse } from "../../../../interfaces";
import { fonts } from "../../../../utilities/helper";
import HttpService from "../../../../services/http";
import { joiResolver } from "@hookform/resolvers/joi";
import { organisationThemeValidation } from "../../../../validations/organisation";
import { useSelector } from "react-redux";

import timeZones  from "../../../../assets/json/timezones.json";
import SearchSelect from "../../../../components/mui/search-select";

interface outletProps {
  refetchOrganisationConfiguration: () => void;
  reFetch: () => void,
}
const ManageConfigurationDetails = () => {  
  const { httpFormRequest } = HttpService();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { updateOrganisationThemeConfiguration } = organisationConfigurationDataService();

  const organisationConfigurationRedux = useSelector(
    (state: { themeConfiguration: { list: IOrganisationConfigurationTheme } }) =>
      state.themeConfiguration.list 
  );  
  const orgConfigurationData = organisationConfigurationRedux && organisationConfigurationRedux;

  const { handleSubmit, control, setValue , trigger, formState: { errors }, getValues } = useForm<IOrganisationConfigurationTheme>({
  resolver: joiResolver(organisationThemeValidation),
  defaultValues: {
    primaryColor: "", 
    secondaryColor: "",
    primaryBackgroundColor: "",
    secondaryBackgroundColor: "",
    fontFamily: "",
    favicon: "",
    logo: "",
    metaTitle: "",
    loginPageLabel: "",
    bannerImage: "",
    contactEmail: "",
    cmsEmail: "",
    operationsEmail: "",
    timezone: ""
  }
});

  useEffect(() => {
    if (orgConfigurationData) {
      setValue("primaryColor",orgConfigurationData.primaryColor || "");
      setValue("secondaryColor",orgConfigurationData.secondaryColor || "");
      setValue("primaryBackgroundColor",orgConfigurationData.primaryBackgroundColor || "");
      setValue("secondaryBackgroundColor",orgConfigurationData.secondaryBackgroundColor || "");
      setValue("fontFamily",orgConfigurationData.fontFamily || "");
      setValue("favicon",orgConfigurationData.favicon || "");
      setValue("logo",orgConfigurationData.logo || "");
      setValue("metaTitle",orgConfigurationData.metaTitle || "");
      setValue("loginPageLabel",orgConfigurationData.loginPageLabel || "Welcome to ATS");
      setValue("bannerImage",orgConfigurationData.bannerImage || "");
      setValue("contactEmail",orgConfigurationData.contactEmail || "");
      setValue("cmsEmail",orgConfigurationData.cmsEmail || "");
      setValue("operationsEmail",orgConfigurationData.operationsEmail || "");
      setValue("timezone",orgConfigurationData?.timezone || "");
    }
  }, [orgConfigurationData]);

  const onSubmit = async (data: IOrganisationConfigurationTheme) => {
    try {
      const update = await updateOrganisationThemeConfiguration(data);
      snackbar(update.message, "info");
      navigate("/organisation-configuration/basic-details");
      outlet?.refetchOrganisationConfiguration && outlet.refetchOrganisationConfiguration();

    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const colorFields: IOrganisationConfigurationThemeField[] = [
    { label: "Primary Color", name: "primaryColor", type: "colorPicker", required: true },
    { label: "Secondary Color", name: "secondaryColor", type: "colorPicker", required: true },
    { label: "Primary Background Color", name: "primaryBackgroundColor", type: "colorPicker", required: true },
    { label: "Secondary Background Color", name: "secondaryBackgroundColor", type: "colorPicker", required: true },
  ];

  const titleLoginFields: IOrganisationConfigurationThemeField[] = [
    { label: "Title", name: "metaTitle", type: "input", required: true },
    { label: "Login Page Label", name: "loginPageLabel", type: "input", required: true },
  ];

  const fontField: IOrganisationConfigurationThemeField[] = [
    {
      label: "Fonts",
      name: "fontFamily",
      type: "auto-complete",
      options: fonts.map(font => ({ key: font, value: font })),
      displayFieldKey: "key",
      storeFieldKey: "value",
      required: true
    }
  ];

  const timezoneFields: IOrganisationConfigurationThemeField[] = [
    {
      label: "Timezone",
      name: "timezone",
      type: "auto-complete",
      options: timeZones.map(timeZone => ({ key: timeZone.display, value: timeZone.region })),
      displayFieldKey: "key",
      storeFieldKey: "value",
      required: true
    }
  ];

  const emailFields: IOrganisationConfigurationThemeField[] = [
    { label: "Contact Email", name: "contactEmail", type: "input", required: true },
    { label: "CMS Contact Email", name: "cmsEmail", type: "input", required: true },
    { label: "Operations Contact Email", name: "operationsEmail", type: "input", required: true },
  ];

  const assetFields: IOrganisationConfigurationThemeField[] = [
    { label: "Logo", name: "logo", type: "file" },
    { label: "Fav Icon", name: "favicon", type: "file" },
    { label: "Banner Image", name: "bannerImage", type: "file" }
  ];

  const assetInputFields: IOrganisationConfigurationThemeField[] = [
    { label: "Logo", name: "logo", type: "input", required: true },
    { label: "Fav Icon", name: "favicon", type: "input", required: true },
    { label: "Banner Image", name: "bannerImage", type: "input", required: true },
  ];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files ? e.target.files[0].name : "",
          type === "favicon" ? ["ico"] : ["svg","ico","webp", "pdf", "png", "jpeg", "jpg"],
          10
      );
      const name = type === "logo" ? "logo" : type === "favicon" ? "favicon" : "bannerImage";
      setValue(name, uploaded.data.split("uploads")[1]);

    } catch (error) {
      snackbar((error as IErrorResponse).data.message, "error");
    }

  };

  const handleTextInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = event.target as HTMLInputElement;
    const maxLength = 30;
    const isLengthValid = inputValue.value.length < maxLength;
    const isEnterKey = event.key === "Enter";
    const isSpaceKey = event.key === " ";

    const isBackspaceKey = event.key === "Backspace";

    if (
      isEnterKey || 
      (isSpaceKey && inputValue.value.length === 0) ||
      (!isLengthValid && !isBackspaceKey)
    ) {
      event.preventDefault();
    }
  };

  const renderField = (field: IOrganisationConfigurationThemeField) => {
    if (field.type === "input") {
      return (
        <Controller
          control={control}
          name={field.name}
          render={({ field: { onChange, value } }) => (
            <TextField
              label={
                <CustomLabel
                  label={field.label}
                  required={field.required}
                />
              }
              variant="outlined"
              size="small"
              fullWidth
              disabled={!!(field.name === "favicon" || field.name === "logo" || field.name === "bannerImage")}
              placeholder={field.placeholder}
              error={!!errors[field.name]}
              helperText={errors[field.name]?.message}
              onChange={onChange}
              value={value}
              onKeyDown={handleTextInput}                                
            />
          )}
        />
      );
    } else if (field.type === "colorPicker") {
      return (
        <Controller
          control={control}
          name={field.name}
          render={({ field: { onChange, value } }) => (
            <Box className="color-picker-wrapper">
              <TextField
                fullWidth
                label={
                  <CustomLabel
                    label={field.label}
                    required={field.required}
                  /> 
                }
                value={value}
                variant="outlined"
                size="small"
                disabled
              />
              <Box className="color-input">
                <TextField
                  type="color"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  variant="standard"
                  size="small"
                />
              </Box>
            </Box>
          )}
        />
      );
    } else if (field.type === "auto-complete") {
      return (
            (<Grid key={field.label} item xs={12}>
              <SearchSelect
                  name={field.name}
                  label={<CustomLabel label={field.label} required={field?.required} />}
                  error={errors[field.name] ? true : false}
                  helperText={errors[field.name]?.message}
                  options={field.options}
                  displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                  storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                  displayUserName={field.displayUserName}
                  capitalize={field.capitalize}
                  trigger={trigger}
                  setValue={setValue}
                  getValues={getValues}
              />
          </Grid>
          ));
    }
    return null;
  };

  const renderAssetField = (field: IOrganisationConfigurationThemeField) => {
    const hasValue = getValues(field.name);

    return (
      <div>
        <Grid container spacing={2}>
          {hasValue && (
            <Grid item xs>
              <Link
                href={
                  getValues(field.name)?.toString()?.startsWith(String(process.env.REACT_APP_S3_BASE_URL))
                    ? getValues(field.name)?.toString()
                    : String(process.env.REACT_APP_S3_BASE_URL) + getValues(field.name)?.toString()
                }                       
                target="_blank"
                underline="none"
                color="inherit"
              >
                <Button variant="outlined" fullWidth>
                  Preview
                </Button>
              </Link>
            </Grid>
          )}
          {!hasValue && (
            <Grid item xs>
              <InputLabel id={`upload-${field.name}`}>
                <Button component="label" fullWidth>
                  Upload {field.label}*
                  <input
                    hidden
                    type="file"
                    id={`upload-${field.name}`}
                    onChange={(e) => uploadFile(e, field.name)}
                    accept="application/pdf, image/png, image/jpeg, .doc, .docx, .ico .webp"
                  />
                </Button>
              </InputLabel>
            </Grid>
          )}
          {hasValue && (
            <Grid item xs>
              <InputLabel id={`upload-${field.name}`}>
                <Button component="label" fullWidth variant="outlined">
                  Change {field.label}
                  <input
                    hidden
                    type="file"
                    id={`upload-${field.name}`}
                    onChange={(e) => uploadFile(e, field.name)}
                    accept="application/pdf, image/png, image/jpeg, .doc, .docx, .ico"
                  />
                </Button>
              </InputLabel>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };
                   
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={handleKeyDown}
      mt={4}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box p={2} border={1} borderRadius={2} borderColor="grey.300">
            <Typography variant="h6" gutterBottom>
              Title and Login Information
            </Typography>
            <Grid container spacing={2}>
              {titleLoginFields.map((field) => (
                <Grid key={field.name} item xs={12} md={6}>
                  {renderField(field)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box p={2} border={1} borderRadius={2} borderColor="grey.300">
            <Typography variant="h6" gutterBottom>
              Colors
            </Typography>
            <Grid container spacing={2}>
              {colorFields.map((field) => (
                <Grid key={field.name} item xs={12} md={6}>
                  {renderField(field)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box p={2} border={1} borderRadius={2} borderColor="grey.300">
            <Typography variant="h6" gutterBottom>
              Font Style
            </Typography>
            <Grid container spacing={2}>
              {fontField.map((field) => (
                <Grid item xs={12} md={6} key={field.name}>
                  {renderField(field)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        
        <Grid item xs={12}>
          <Box p={2} border={1} borderRadius={2} borderColor="grey.300">
            <Typography variant="h6" gutterBottom>
              Timezone
            </Typography>
            <Grid container spacing={2}>
              {timezoneFields.map((field) => (
                <Grid item xs={12} md={6} key={field.name}>
                  {renderField(field)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} border={1} borderRadius={2} borderColor="grey.300">
            <Typography variant="h6" gutterBottom>
              Contact Emails
            </Typography>
            <Grid container spacing={2}>
              {emailFields.map((field) => (
                <Grid key={field.name} item xs={12} md={6}>
                  {renderField(field)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box p={2} border={1} borderRadius={2} borderColor="grey.300">
            <Typography variant="h6" gutterBottom>
              Visual Assets
            </Typography>
            <Grid container spacing={2}>
              {assetFields.map((assetField) => {
                const correspondingInputField = assetInputFields.find(
                  (inputField) => inputField.name === assetField.name
                );
                return (
                  <Grid key={assetField.name} item xs={12} md={6}>
                    <Box
                      p={2}
                      border={1}
                      borderRadius={2}
                      borderColor="grey.300"
                    >
                      {correspondingInputField &&
                        renderField(correspondingInputField)}
                      <Box mt={2} />
                      {renderAssetField(assetField)}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end" mt={2} pb={2}>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ManageConfigurationDetails;
