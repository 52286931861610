
import HttpService from "../../http";
import { alternativeComment } from "../../endpoints";
import { IAlternativeCommentReplyResponse, IAlternativeCommentResponses } from "../../../interfaces/alternative-comparison/comment";
const AlternativeCommentsService = () => {

    const { httpRequest } = HttpService();
    const getAlternativeCommentList = async (search: object): Promise<IAlternativeCommentResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IAlternativeCommentResponses>(
                "GET",
                `${alternativeComment}/list`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addAlternativeComment = async (payload: object): Promise<IAlternativeCommentResponses> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeCommentResponses>(
            "POST",
            `${alternativeComment}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const addAlternativeCommentReply = async (payload: object): Promise<IAlternativeCommentReplyResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeCommentReplyResponse>(
            "POST",
            `${alternativeComment}/reply`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    return { addAlternativeComment, getAlternativeCommentList, addAlternativeCommentReply };

};

export { AlternativeCommentsService };