import { capitalize, Typography } from "@mui/material";
import { FC } from "react";

export const ProgressBar : FC<{ progress: number }> = ({ progress = 0 }) => {
    const getProgressClass = () => {
      if (progress >= 70) return "progress-bar__indicator--high";
      if (progress >= 50) return "progress-bar__indicator--medium";
      return "progress-bar__indicator--low";
    };
  
    return (
      <div className="progress-container">
        <div className="progress-bar">
          <div 
            className={`progress-bar__indicator ${getProgressClass()}`} 
            style={{ width: `${progress}%` }}
          />
        </div>
        <Typography variant="body2">{progress}%</Typography>
      </div>
    );
  };

export  const StatusChip : FC<{ status: string }> = ({ status }) => {
    const getStatusClass = () => {
      switch(status) {
        case "completed": return "status-chip--completed";
        case "in-progress": return "status-chip--in-progress";
        default: return "status-chip--not-started";
      }
    };
  
    return (
    <div className={`status-chip ${getStatusClass()}`}>
      {capitalize(status)}
    </div>
    );
  };

const formatReadableDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = date
    .toLocaleString("default", { month: "short" })
    .toLowerCase();
  const year = String(date.getFullYear()).slice(-2);
  return `${day} ${month} ${year}`;
};

export const getRecurringObjectiveNames = (
  name: string,
  frequency: string,
  startDate: string,
  endDate: string
) => {
  const now = new Date(startDate);
  const nowEnd = new Date(endDate);

  let result = "";

  if (frequency === "daily") {
    result = `${name} ( ${formatReadableDate(now)} - ${formatReadableDate(
      nowEnd
    )} )`;
  } else if (frequency === "weekly") {
    result = `${name} ( ${formatReadableDate(now)} - ${formatReadableDate(
      nowEnd
    )} )`;
  } else if (frequency === "monthly") {
    const currentMonth = now
      .toLocaleString("default", { month: "short" })
      .toLowerCase();
    const currentYear = String(now.getFullYear()).slice(-2);
    result = `${name} ( ${currentMonth} ${currentYear} )`;
  } else if (frequency === "quarterly") {
    const currentMonth = now.getMonth();
    let currentYear = now.getFullYear();
    let currentQuarter: number;
    switch (true) {
      case currentMonth >= 3 && currentMonth <= 5:
        currentQuarter = 1;
        break;
      case currentMonth >= 6 && currentMonth <= 8:
        currentQuarter = 2;
        break;
      case currentMonth >= 9 && currentMonth <= 11:
        currentQuarter = 3;
        break;
      default:
        currentQuarter = 4;
        break;
    }
    if (currentQuarter === 4) {
      currentYear -= 1;
    }
    result = `${name} ( q${currentQuarter} ${currentYear} )`;
  } else {
    const currentYear = now.getFullYear();
    result = `${name} ( ${currentYear} )`;
  }
  return result;
};