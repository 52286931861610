import Joi from "joi";
import { required } from "../../shared";

export const alternativesBasicDetailValidation = Joi.object({
    type: Joi.string()
        .required()
        .label("Type")
        .messages({
            "string.empty": required,
        }),
    leftBannerImage: Joi.string()
        .optional()
        .allow("")
        .label("Left Banner Image"),
    rightBannerImage: Joi.string()
        .optional()
        .allow("")
        .label("Right Banner Image"),
    title: Joi.string()
        .required()
        .min(3)
        .trim()
        .label("Glossary Title")
        .messages({
            "string.empty": required,
        }),
    description: Joi.string()
        .min(3)
        .required()
        .trim()
        .label("Description")
        .messages({
            "string.empty": required,
        }),
    slug: Joi.string()
        .max(60)
        .min(3)
        .trim()
        .required()
        .label("Slug")
        .messages({
            "string.empty": required,
        }),
    _quarter: Joi.string()
        .required()
        .label("Quarter")
        .messages({
            "string.empty": required,
        }),
    _product: Joi.string()
        .required()
        .label("Product")
        .messages({
            "string.empty": required,
        }),
    primaryKeyword: Joi.string()
        .required()
        .label("Primary Keyword")
        .messages({
            "string.empty": required,
        }),
    secondaryKeywords: Joi.array()
        .label("Secondary Keywords")
        .items(Joi.string()
            .required()
        )
        .messages({
            "array.empty": required,
        })
});

export const alternativesBulkAssignmentValidation = Joi.object({
    writer: Joi.object({
        _user: Joi.string()
            .required()
            .label("Writer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Writer submission date")
            .messages({
                "string.empty": required,
            })
    }),
    reviewer: Joi.object({
        _user: Joi.string()
            .required()
            .label("Reviewer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Reviewer submission date")
            .messages({
                "string.empty": required,
            }),
    }),
    illustrator: Joi.object({
        _user: Joi.string()
            .required()
            .label("Designer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Designer submission date")
            .messages({
                "string.empty": required,
            })
    }),
    publisher: Joi.object({
        _user: Joi.string()
            .required()
            .label("publisher")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("publisher submission date")
            .messages({
                "string.empty": required,
            })
    }),
});

export const alternativeScheduleValidation = Joi.object({
    scheduledDate: Joi.string()
        .required()
        .label("Date and time")
        .messages({
            "string.empty": required
        }),
});