import Joi from "joi";
import { required } from "../../../shared";

export const blogImagesValidation = Joi.object({
	form: Joi.array()
		.items(
			Joi.object({
				imageType: Joi.string()
					.required()
					.label("Image Type")
					.messages({
						"string.empty": required,
					}),
				alternativeText: Joi.string()
					.required()
					.label("Alternative Text")
					.messages({
						"string.empty": required,
					}),
				optionSelect: Joi.string()
					.when("isEdit", {
						is: true,
						then: Joi.string().optional().allow(""),
						otherwise: Joi.string()
							.required()
							.label("Select Option")
							.messages({
								"string.empty": required,
							}),
					}),
				isEdit: Joi.boolean()
					.required(),
				imageUrl: Joi.string()
					.when("isEdit", {
						is: true,
						then: Joi.string()
							.optional()
							.allow(""),
						otherwise: Joi.string()
							.when("optionSelect", {
								is: "upload",
								then: Joi.string()
									.required()
									.label("Image URL")
									.messages({
										"string.empty": required,
									}),
								otherwise: Joi.string()
									.optional()
									.allow(""),
							}),
					}),
				name: Joi.string()
					.when("isEdit", {
						is: true,
						then: Joi.string().optional().allow(""),
						otherwise: Joi.string()
							.when("optionSelect", {
								is: "upload",
								then: Joi.string()
									.required()
									.label("Name")
									.messages({
										"string.empty": required,
									}),
								otherwise: Joi.string()
									.optional()
									.allow(""),
							}),
					}),
				imageId: Joi.string()
					.when("isEdit", {
						is: true,
						then: Joi.string()
							.optional()
							.allow(""), // Optional when editing
						otherwise: Joi.string()
							.when("optionSelect", {
								is: "media",
								then: Joi.string()
									.required()
									.label("Image")
									.messages({
										"string.empty": required,
									}),
								otherwise: Joi.string()
									.optional()
									.allow(""),
							}),
					}),
				imageName: Joi.string()
					.when("isEdit", {
						is: true,
						then: Joi.string()
							.optional()
							.allow(""),
						otherwise: Joi.string()
							.when("optionSelect", {
								is: "media",
								then: Joi.string()
									.required()
									.label("Image Name")
									.messages({
										"string.empty": required,
									}),
								otherwise: Joi.string()
									.optional()
									.allow(""),
							}),
					}),
			})
		)
		.sparse()
		.optional()
});
