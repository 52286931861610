import "./style.scss";
import { Box, Grid, Typography } from "@mui/material";
import { IColumn } from "../../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import { capitalize } from "../../../../../utilities/helper";
import { IBlogSummary, IBlogSummaryRow } from "../../../../../interfaces/content-marketing/blog/score";
import CustomTable from "../../../../../components/mui/table";
import { blogService } from "../../../../../services/blog";
import { Outlet, useParams } from "react-router-dom";
import CustomTypography from "../../../../../components/mui/max-length-limit";

const Summary = () => {
    const { getBlogSummary } = blogService();
    const { id } = useParams();

    const hitQuery = id !== "new" ? true : false;

    const getSummary = useQuery({
        queryKey: ["blog-summary"],
        queryFn: () => getBlogSummary({ blogId: id }),
        enabled: hitQuery
    });

    const createRows = (data: IBlogSummary) => ({
        name: capitalize(data?.name),
        content:
          ["Meta Description", "Audience", "Idea of the Blog", "Suggested sub-heading", "Data"].includes(data?.name) ? (
            <CustomTypography
              limit={200}
              label={data?.value ?? ""}
            />
          ) : (
            data?.value
          )
      });
    let rows: IBlogSummaryRow[] = [];
    if (getSummary?.data?.data) {
        rows = getSummary?.data?.data?.map(
            (data: IBlogSummary) =>
                createRows(data)
        );
    }


    const columns: IColumn[] = [
        {
            id: "name",
            label: "Name",
            minWidth:310
        },
        {
            id: "content",
            label: "Content",
        }
    ];



    return (
        <div className="blog-summary">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography className="title" variant="body2">Blog Summary</Typography>
                    <Box marginTop="10px">
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            height="calc(100vh - 305px)"
                        />
                    </Box>
                </Grid>
            </Grid>
            <Outlet />
        </div>
    );
};

export default Summary;
