import { IKRActivityResponse, IKrResponse, IKrResponses } from "../../../../interfaces/okr/objectives/key-results";
import { keyResults } from "../../../endpoints";
import HttpService from "../../../http";


const okrKeyResultsService = () => {
    const { httpRequest } = HttpService();

    const getKeyResults = async (search: object): Promise<IKrResponses> => new Promise((resolve, reject) => {
        httpRequest<IKrResponses>(
            "GET",
            `${keyResults}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getKeyResult = async (search: object): Promise<IKrResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrResponse>(
            "GET",
            `${keyResults}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addKeyResult = async (payload: object): Promise<IKrResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrResponse>(
            "POST",
            `${keyResults}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateKeyResults = async (payload: object): Promise<IKrResponse> => new Promise((resolve, reject) => {
        httpRequest<IKrResponse>(
            "PUT",
            `${keyResults}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPendingKRS = async (search: object): Promise<IKrResponses> => new Promise((resolve, reject) => {
        httpRequest<IKrResponses>(
            "GET",
            `${keyResults}/pending/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getKeyReslutTimeline = async (search: object): Promise<IKRActivityResponse> => new Promise((resolve, reject) => {
        httpRequest<IKRActivityResponse>(
            "GET",
            `${keyResults}/timeline`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { getKeyResults, updateKeyResults, getKeyResult, addKeyResult, getPendingKRS, getKeyReslutTimeline };
};

export { okrKeyResultsService };