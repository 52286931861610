import Joi from "joi";
import { required} from "../../../shared";

export const callStatusValidation = Joi.object({
    _callStatus: Joi.string()
        .required()
        .label("Call Status")
        .messages({
            "string.empty": required,
        }),
    comment: Joi.string()
        .label("Note")
        .max(200)
        .allow("")
});
