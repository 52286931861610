import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ICategory, ICategoryStatus } from "../../../interfaces/content/category";
import { IGoal, IGoalStatus } from "../../../interfaces/content/goals";
import { ISubCategoryStatus } from "../../../interfaces/content/sub-category";
import { IKeywordStatus } from "../../../interfaces/content/keyword";
import { IQuarterTarget } from "../../../interfaces/planner/quarter-target";
import { IAuthorData, IBlog, IBlogState, ITechnicalQuestion, ITechnicalQuestionState } from "../../../interfaces";
import { IBlogImage, IBlogImageState } from "../../../interfaces/content-marketing/blog/image";
import { IProductMapping, IProductMappingStatus } from "../../../interfaces/content/product-mapping";
import { IGlossary } from "../../../interfaces/content-marketing/glossary";
import { IAlternativeComparison } from "../../../interfaces/alternative-comparison";
interface ITestStates {
  selectAll: string[];
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
  };
}

export const handleSelectAll = (e: ChangeEvent<HTMLInputElement>,
  data: IAlternativeComparison[] | ICategory[] | IGoal[] | IQuarterTarget[] | IBlog[] | IBlogImage[] |IProductMapping[] | ITechnicalQuestion[] | IAuthorData[] | IGlossary[],
  // eslint-disable-next-line
  setState: Dispatch<SetStateAction<any>>) => {
  let payload: Array<string> = [];
  if (e.target.checked) {
    if (data?.length) {
      payload = data
        .map(ele => ele._id);
    }
  } else {
    payload = [];
  }
  // eslint-disable-next-line
  setState((prevState: any) => ({
    ...prevState,
    selectAll: payload
  }));
};

export const handleSelect = (
  e: ChangeEvent<HTMLInputElement>,
  id: string,
  state: ICategoryStatus | IGoalStatus | ISubCategoryStatus | IKeywordStatus | IBlogState | IBlogImageState | IProductMappingStatus | ITestStates | ITechnicalQuestionState,
  // eslint-disable-next-line
  setState: Dispatch<SetStateAction<any>>
) => {
  let payload: Array<string> = [];

  if (e.target.checked) {
    payload = state.selectAll.slice();
    payload.push(id);
  } else {
    payload = state.selectAll.filter((ele) => ele !== id);
  }
  // eslint-disable-next-line
  setState((prevState: any) => ({
    ...prevState,
    selectAll: payload,
  }));
};

export const keywordFilterInitialState = Object.freeze({
  selectedMenu: 0,
  category: [],
  subCategory:[],
  product: []
});