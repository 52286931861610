import Joi from "joi";
import { required } from  "../../shared";

export const courseValidation = Joi.object({
  name: Joi.string()
    .required()
    .label("Name")
    .max(25)
    .messages({
      "string.empty": required,
      "string.max": "The name must not exceed 25 characters."
    }),
  description: Joi.string()
    .required()
    .label("Description")
    .max(100)
    .messages({
      "string.empty": required,
      "string.max": "The name must not exceed 100 characters."
    }),
  shortName: Joi.string()
    .required()
    .max(100)
    .label("Short Name")
    .messages({
      "string.empty": required,
      "string.max": "The name must not exceed 100 characters."
    }),
  _department: Joi.string()
    .required()
    .label("Department")
    .max(25)
    .messages({
      "string.empty": required,
      "string.max": "The selection must not exceed 25 characters."
    }),
  _skillParameter: Joi.array()
    .required()
    .min(1)
    .label("Skill Parameter")
    .messages({
      "array.empty": required,
      "array.min": "Please select at least one value for skill parameter."
    })
});