import "./style.scss";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel, a11yProps } from "../../../components/shared/mui-tabs";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "@tanstack/react-query";
import { CandidateService, broadcastService } from "../../../services";
import { capitalize, formatMobileNumber, formateEngagementType, displayName } from "../../../utilities/helper";
import WhatsApp from "./whatsApp";

import Mail from "./mail";
import OnBoard from "./onboard";
import { useSelector } from "react-redux";
import { ITemplate } from "../../../interfaces";
import useUser from "../../../hooks/useUser";
import PreOnBoard from "./pre-onboard";

const redirectionLinks = ["mail", "whatsApp", "onboard", "pre-onboard"];

interface outletProps {
    candidatesRefetch: () => void;
}

const OfferedActionLayout = () => {
    const navigate = useNavigate();
    const [...templates] = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(state => state.template.list) || [];
    const { leadId } = useParams();
    const { candidatesRefetch } = useOutletContext<outletProps>();
    const { user } = useUser();
    const [value, setValue] = useState(0);
    const { getCandidatePartial } = CandidateService();
    const [searchParam, setSearchParam] = useSearchParams();
    const candidate = useQuery({
        queryKey: ["candidate"],
        queryFn: () => getCandidatePartial({ _id: leadId })
    });

    const { whatsappTemplates} = broadcastService();

    const whatsAppTemplatesData = useQuery({
        queryKey: ["whatsAppTemplates"],
        queryFn: () => whatsappTemplates({})
    });

    useEffect(() => {
        const tab = searchParam.get("type") ? String(searchParam.get("type")) : "mail";
        setValue(redirectionLinks.indexOf(tab));
    }, [searchParam]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        searchParam.set("type", redirectionLinks[newValue]);
        setSearchParam(searchParam);
    };

    const onClose = () => {
        searchParam.delete("type");
        navigate({
            pathname: "/offered",
            search: searchParam.toString()
        });
    };

    const keywords = {
        name: capitalize(candidate.data?.data?.name || ""),
        email: candidate.data?.data?.email || "",
        collegeName: candidate.data?.data?.collegeName || "",
        jobTitle: candidate.data?.data?.jobId?.title || "",
        department: capitalize(candidate.data?.data?.jobId?.department || ""),
        location: candidate.data?.data?.currentLocation?.formattedAddress || "",
        mobileNumber: formatMobileNumber(candidate.data?.data?.mobileNumber),
        engagementType: formateEngagementType(candidate.data?.data?.typeOfLead || ""),
        score: candidate.data?.data?.testScore || 0,
        portfolioUrl: candidate.data?.data?.portfolio || "",
        campusStatus: candidate.data?.data?._cpd?.length ? "On Campus" : "Off Campus",
        userName: `${capitalize(displayName(user))}`,
        userNumber: formatMobileNumber(user?.phone),
        userDepartment: capitalize(user?.department?.name),
        userDesignation: user?.designation?.name || "",
        approxExperience: candidate?.data?.data?.approxExperience || 0,
        experience: candidate?.data?.data?.experience || 0,
        ctc: candidate?.data?.data?.ctc || 0,
        ectc: candidate?.data?.data?.ectc || 0,
        noticePeriod: candidate?.data?.data?.noticePeriod || 0,
    };

    const name = candidate.data?.data.name ? capitalize(candidate.data?.data.name) : "Candidate Actions";
    const source = candidate.data?.data.source ? candidate.data?.data.source : undefined;

    return <div>
        <BootstrapDialog
            maxWidth="md"
            onClose={onClose}
            open={leadId ? true : false}
            disableEnforceFocus
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    {name}
                </Box>
                <Box className="center" justifyContent="space-between">
                    {
                        source &&
                        <Box display="flex">
                            <Typography variant="subtitle1">Source:</Typography>
                            <Typography className="ml-2" variant="overline">{source}</Typography>
                        </Box>
                    }
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ height: "60vh" }}>
                <Box width="100%" >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab className="tabs-space start-tab-space" label="Mail" {...a11yProps(0)} />
                            <Tab className="tabs-space" label="WhatsApp" {...a11yProps(1)} />
                            <Tab className="tabs-space" label="On Board" {...a11yProps(1)} />
                            <Tab className="tabs-space" label="Pre Onboard" {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    {/* Mail  */}
                    <TabPanel value={value} index={0}>
                        <Mail
                            candidate={candidate.data?.data}
                            templates={templates.filter(template => template.type === "email" && template.tag === "offered")}
                            keywords={keywords}
                            candidatesRefetch={candidatesRefetch}
                            isTabChanged={value}
                        />
                    </TabPanel>

                    {/* whatsApp  */}
                    <TabPanel value={value} index={1}>
                        <WhatsApp
                            candidate={candidate.data?.data}
                            templates={whatsAppTemplatesData?.data?.data}
                        />
                    </TabPanel>

                    {/* OnBoard  */}
                    <TabPanel value={value} index={2}>
                        <OnBoard
                            candidate={candidate.data?.data}
                            candidatesRefetch={candidatesRefetch}
                        />
                    </TabPanel>

                    {/* Pre-OnBoard  */}
                    <TabPanel value={value} index={3}>
                        <PreOnBoard
                            candidate={candidate.data?.data}
                            candidatesRefetch={candidatesRefetch} 
                        />
                    </TabPanel>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    </div>;
};

export default OfferedActionLayout;
