import React, { useEffect, KeyboardEvent, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Chip,
  Autocomplete,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import SearchSelect from "../../../components/mui/search-select";
import {
  IResourceField,
  IResourceRequestFields,
} from "../../../interfaces/resource-request";
import { ResourceRequestedServices } from "../../../services/resource-request";
import { useParams } from "react-router-dom";
import EmailSuggestion from "../../../components/mui/email-suggestion";
import { validateEmail } from "../../../validations/shared";
import { useQuery } from "@tanstack/react-query";
import CustomLabel from "../../../components/mui/custom-label";
import { getresourceFields } from "../manage-resource-requirement/resource-fields";
const StyledChip = styled(Chip)`
  &&& {
    margin: 5px;
  }
`;
const ResourceRequestDetails: React.FC = () => {
  const { id } = useParams();
  const { control, watch, getValues, setValue, trigger, resetField } =
    useForm<IResourceRequestFields>({
      defaultValues: {
        ccList: [],
        jobTitle: "",
        engagementType: "",
        workLocation: "",
        minExp: "",
        maxExp: "",
        openings: "",
        priorityDuration: "",
        durationNeeded: "",
        skillsetRequired: [],
        justification: "",
        hardwareDetails: {
          purchaseType: "",
          durationOfUse: "",
          name: "",
          payment: {
            amount: "",
            currency: "",
          },
        },
        softwareDetails: {
          purchaseType: "",
          durationOfUse: "",
          name: "",
          payment: {
            amount: "",
            currency: "",
          },
        },
        ccText: "",
      },
    });

  const resourceFields = getresourceFields();
  const { getRequestedResource } = ResourceRequestedServices();
  const hitQuery = !!id;
  const [focussedField, setFocussedField] = useState("");
  const requestedResource = useQuery({
    queryKey: [hitQuery],
    queryFn: () => getRequestedResource({ _id: id }),
    enabled: hitQuery,
  });
  const resourceData =
    requestedResource && requestedResource.data && requestedResource.data.data;
  const requestType = watch("requestType");

  useEffect(() => {
    id && setValue("hasrequirement", "true");
    if (resourceData) {
      const candidateInfo = resourceData;
      const keys = [
        "jobTitle",
        "engagementType",
        "workLocation",
        "minExp",
        "maxExp",
        "openings",
        "durationNeeded",
        "skillsetRequired",
        "softwareDetails",
        "hardwareDetails",
        "requestType",
        "justification",
        "ccList",
        "resourceType",
        "priorityDuration",
        "softwareDetails.purchaseType",
        "softwareDetails.name",
        "softwareDetails.paymentAmount",
        "softwareDetails.durationOfUse",
        "hardwareDetails.name",
        "hardwareDetails.purchaseType",
        "hardwareDetails.paymentAmount",
        "hardwareDetails.durationOfUse",
      ];
      keys.forEach((key) => {
        switch (key) {
          case "jobTitle":
            setValue("jobTitle", candidateInfo?.jobTitle?._id ?? "");
            break;
          case "engagementType":
            setValue("engagementType", candidateInfo?.engagementType ?? "");
            break;
          case "workLocation":
            setValue("workLocation", candidateInfo?.workLocation ?? "");
            break;
          case "minExp":
            setValue("minExp", candidateInfo?.minExp ?? "");
            break;
          case "maxExp":
            setValue("maxExp", candidateInfo?.maxExp ?? "");
            break;
          case "openings":
            setValue("openings", candidateInfo?.openings ?? "");
            break;
          case "durationNeeded":
            setValue("durationNeeded", candidateInfo?.durationNeeded ?? "");
            break;
          case "skillsetRequired":
            setValue("skillsetRequired", candidateInfo?.skillsetRequired ?? []);
            break;
          case "hardwareDetails":
            setValue("hardwareDetails", candidateInfo?.hardwareDetails);
            break;
          case "softwareDetails":
            setValue("softwareDetails", candidateInfo?.softwareDetails);
            break;
          case "hasRequirement":
            setValue("hasrequirement", "true");
            break;
          case "resourceType":
            setValue("resourceType", candidateInfo?.resourceType);
            break;
          case "requestType":
            setValue("requestType", candidateInfo?.requestType ?? "");
            break;
          case "justification":
            setValue("justification", candidateInfo?.justification ?? "");
            break;
          case "ccList":
            setValue("ccList", candidateInfo?.ccList ?? []);
            break;
          case "priorityDuration":
            setValue("priorityDuration", candidateInfo?.priorityDuration ?? "");
            break;
        }
      });
    }
  }, [resourceData]);

  const types = [
    { name: "Human Resource", value: "human" },
    { name: "Hardware", value: "hardware" },
    { name: "Software", value: "software" },
  ];

  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];
    if (key === "ccText") {
      e.preventDefault();
      const err = validateEmail(getValues(key));
      if (err.error) {
        return;
      }
      const enteredEmail = getValues(key) ? String(getValues(key)) : "";
      if (enteredEmail.trim() !== "" && e.key === "Enter") {
        if (key === "ccText") {
          const prev = getValues("ccList") ? getValues("ccList") : [];
          payload = [...prev, enteredEmail];
          setValue("ccList", [...new Set(payload)]);
          resetField(key);
        }
      }
    }
  };

  const handleEmailSelect = (email: string, name: string) => {
    let payload: string[] = [];
    if (["ccText"].includes(name)) {
      if (name === "ccText") {
        const prev = getValues("ccList") ? getValues("ccList") : [];
        payload = [...prev, email];
        setValue("ccList", [...new Set(payload)]);
        resetField(name);
      }
    }
  };

  return (
    <Box className="resource-request-form">
      {!id && (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Controller
              name="requestType"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  disabled={!!id}
                  label={
                    <CustomLabel label="Type of Resource" required={true} />
                  }
                  size="small"
                >
                  {types.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      )}
      {
        <>
          <div className="form-fields">
            {["human"].includes(requestType ?? "") && (
              <Grid container spacing={2} mb={2}>
                {requestType === "human" && (
                  <>
                    {(resourceFields.teamMemberFields as IResourceField[]).map(
                      (field) => (
                        <Grid item xs={4} key={field.name}>
                          {(() => {
                            if (field.name === "ccText") {
                              return (
                                <Controller
                                  name="ccText"
                                  control={control}
                                  render={() => (
                                    <>
                                      <EmailSuggestion
                                        name="ccText"
                                        label=<CustomLabel
                                          label="Notify"
                                          required={true}
                                        />
                                        disabled={!!id}
                                        control={control}
                                        value={getValues("ccText")}
                                        placeholder="Enter CC List"
                                        onEmailSelect={handleEmailSelect}
                                        onKeyUp={addEmail}
                                        size="small"
                                      />
                                      <Box mt={1}>
                                        {(getValues("ccList") || []).map(
                                          (ccEmail) => (
                                            <StyledChip
                                              className="styled-chip"
                                              disabled={!!id}
                                              key={ccEmail}
                                              label={ccEmail}
                                              color="primary"
                                              variant="outlined"
                                            />
                                          )
                                        )}
                                      </Box>
                                    </>
                                  )}
                                />
                              );
                            } else if (field.name === "jobTitle") {
                              return (
                                <SearchSelect
                                  name={field.name}
                                  size={"small"}
                                  label={
                                    <CustomLabel
                                      label={field.label}
                                      required={field?.required}
                                    />
                                  }
                                  options={field.children}
                                  displayFieldKey={
                                    field.displayFieldKey
                                      ? field.displayFieldKey
                                      : ""
                                  }
                                  storeFieldKey={
                                    field.storeFieldKey
                                      ? field.storeFieldKey
                                      : ""
                                  }
                                  displayUserName={field.displayUserName}
                                  capitalize={field.capitalize}
                                  setValue={setValue}
                                  getValues={getValues}
                                  disabled={!!id}
                                  trigger={trigger}
                                />
                              );
                            } else if (field.name === "skillsetRequired") {
                              return (
                                <Controller
                                  name="skillsetRequired"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Autocomplete
                                        {...field}
                                        options={[]}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label=<CustomLabel
                                              label="Skills Required"
                                              required={true}
                                            />
                                            disabled={!!id}
                                            variant="outlined"
                                            placeholder="Enter a skill*"
                                            size={"small"}
                                          />
                                        )}
                                        value={field.value || []}
                                        multiple
                                        freeSolo
                                        disabled={!!id}
                                        renderTags={() => null}
                                      />
                                      {getValues("skillsetRequired")?.map(
                                        (skillSet) => (
                                          <Chip
                                            className="styled-chip"
                                            disabled={!!id}
                                            key={skillSet}
                                            label={skillSet}
                                            color="primary"
                                            variant="outlined"
                                          />
                                        )
                                      )}
                                    </>
                                  )}
                                />
                              );
                            } else {
                              return (
                                <Controller
                                  name={field.name}
                                  control={control}
                                  render={({ field: controllerField }) =>
                                    field.type === "select" ? (
                                      <TextField
                                        {...controllerField}
                                        select
                                        label=<CustomLabel
                                          label={field.label}
                                          required={field.required}
                                        />
                                        disabled={!!id}
                                        placeholder={field.placeholder}
                                        size={"small"}
                                        onFocus={() => {
                                          setFocussedField(field.name);
                                        }}
                                        onBlur={() => {
                                          setFocussedField("");
                                        }}
                                        InputLabelProps={{
                                          shrink:
                                            focussedField === field.name ||
                                            !!controllerField.value ||
                                            !!getValues(field.name),
                                        }}
                                      >
                                        {field.children}
                                      </TextField>
                                    ) : (
                                      <TextField
                                        {...controllerField}
                                        className="form-field"
                                        label=<CustomLabel
                                          label={field.label}
                                          required={field.required}
                                        />
                                        placeholder={field.placeholder}
                                        disabled={!!id}
                                        multiline
                                        size="small"
                                      />
                                    )
                                  }
                                />
                              );
                            }
                          })()}
                        </Grid>
                      )
                    )}
                  </>
                )}
              </Grid>
            )}
            {["hardware"].includes(requestType ?? "") && (
              <Grid container spacing={2}>
                
                  <>
                    {(resourceFields.hardwareFields as IResourceField[]).map(
                      (field) => (
                        <Grid item xs={4} key={field.name}>
                          {(() => {
                            if (field.name === "ccText") {
                              return (
                                <Controller
                                  name="ccText"
                                  control={control}
                                  render={() => (
                                    <>
                                      <EmailSuggestion
                                        name="ccText"
                                        label=<CustomLabel
                                          label="Notify"
                                          required={true}
                                        />
                                        disabled={!!id}
                                        control={control}
                                        value={getValues("ccText")}
                                        placeholder="Enter CC List"
                                        onEmailSelect={handleEmailSelect}
                                        onKeyUp={addEmail}
                                        size="small"
                                      />
                                      <Box mt={1}>
                                        {(getValues("ccList") || []).map(
                                          (ccEmail) => (
                                            <StyledChip
                                              className="styled-chip"
                                              disabled={!!id}
                                              key={ccEmail}
                                              label={ccEmail}
                                              color="primary"
                                              variant="outlined"
                                            />
                                          )
                                        )}
                                      </Box>
                                    </>
                                  )}
                                />
                              );
                            } else {
                              return (
                                <Controller
                                  name={field.name}
                                  control={control}
                                  render={({ field: controllerField }) => {
                                    if (field.type === "select") {
                                      return (
                                        <TextField
                                          {...controllerField}
                                          select
                                          label={
                                            <CustomLabel
                                              label={field && field.label}
                                              required={field && field.required}
                                            />
                                          }
                                          disabled={!!id}
                                          placeholder={field.placeholder}
                                          size={"small"}
                                        >
                                          {field.children}
                                        </TextField>
                                      );
                                    } else {
                                      return (
                                        <TextField
                                          {...controllerField}
                                          className="form-field"
                                          disabled={!!id}
                                          label={
                                            <CustomLabel
                                              label={field.label}
                                              required={field.required}
                                            />
                                          }
                                          placeholder={field.placeholder}
                                          size={"small"}
                                        />
                                      );
                                    }
                                  }}
                                />
                              );
                            }
                          })()}
                        </Grid>
                      )
                    )}
                  </>
              
              </Grid>
            )}
            {["software"].includes(requestType ?? "") && (
              <Grid container spacing={2}>
               
                  <>
                    {(resourceFields.softwareFields as IResourceField[]).map(
                      (field) => (
                        <Grid item xs={4} key={field.name}>
                          {(() => {
                            if (field.name === "ccText") {
                              return (
                                <Controller
                                  name="ccText"
                                  control={control}
                                  render={() => (
                                    <>
                                      <EmailSuggestion
                                        name="ccText"
                                        label=<CustomLabel
                                          label="Notify"
                                          required={true}
                                        />
                                        disabled={!!id}
                                        control={control}
                                        value={getValues("ccText")}
                                        placeholder="Enter CC List"
                                        onEmailSelect={handleEmailSelect}
                                        onKeyUp={addEmail}
                                        size="small"
                                      />
                                      <Box mt={1}>
                                        {(getValues("ccList") || []).map(
                                          (ccEmail) => (
                                            <StyledChip
                                              className="styled-chip"
                                              disabled={!!id}
                                              key={ccEmail}
                                              label={ccEmail}
                                              color="primary"
                                              variant="outlined"
                                            />
                                          )
                                        )}
                                      </Box>
                                    </>
                                  )}
                                />
                              );
                            } else {
                              return (
                                <Controller
                                  name={field.name}
                                  control={control}
                                  render={({ field: controllerField }) => {
                                    if (field.type === "select") {
                                      return (
                                        <TextField
                                          {...controllerField}
                                          select
                                          disabled={!!id}
                                          label={
                                            <CustomLabel
                                              label={field.label}
                                              required={field.required}
                                            />
                                          }
                                          placeholder={field.placeholder}
                                          size={"small"}
                                        >
                                          {field.children}
                                        </TextField>
                                      );
                                    } else {
                                      return (
                                        <TextField
                                          {...controllerField}
                                          className="form-field"
                                          disabled={!!id}
                                          label={
                                            <CustomLabel
                                              label={field.label}
                                              required={field.required}
                                            />
                                          }
                                          placeholder={field.placeholder}
                                          size={"small"}
                                        />
                                      );
                                    }
                                  }}
                                />
                              );
                            }
                          })()}
                        </Grid>
                      )
                    )}
                  </>
                
              </Grid>
            )}
          </div>
        </>
      }
    </Box>
  );
};

export default ResourceRequestDetails;
