import { FC, useEffect, useState } from "react";
import { capitalize, checkTimePeriod } from "../../../utilities/helper";
import { MenuItem } from "../../../components/shared/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../components/shared/filter/style.scss";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { IJobPosition, IProps, IUser } from "../../../interfaces";
import { useSelector } from "react-redux";
import { ISkillMatrixAnalyticsFilter } from "../../../interfaces/skill-matrix/skill-matrix-analytics";
import { SkillMatrixService } from "../../../services";
import { useQuery } from "@tanstack/react-query";

const SkillAnalyticsFilter: FC<IProps> = ({ anchorEl, isOpen, OnCloseFilter }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
    const {getSkillParamPartial} = SkillMatrixService();
    const skillParams  = useQuery({
        queryKey: ["get-skill-param-for-fiters"],
        queryFn: () => getSkillParamPartial(),
        enabled: !!isOpen
      });

    const parameterPartial = skillParams?.data?.data || [];
    const designationPartial = useSelector<{ jobPosition: { list: IJobPosition[] } }, IJobPosition[]>(state => state.jobPosition.list);
    const [state, setState] = useState<ISkillMatrixAnalyticsFilter>({
        selectedMenu: 0,
        parameter: [],
        designation: [],
        employee: [],
        time: [],
    });


    useEffect(() => {
        const parameter: { key: string, value: string }[] = searchParams.get("parameter") ? JSON.parse(String(searchParams.get("parameter"))) : [];
        const designation: { key: string, value: string }[] = searchParams.get("designation") ? JSON.parse(String(searchParams.get("designation"))) : [];
        const employee: { key: string, value: string }[] = searchParams.get("employee") ? JSON.parse(String(searchParams.get("employee"))) : [];
        const time: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("time") ? JSON.parse(String(searchParams.get("time"))) : [];
        setState(prevState => ({
            ...prevState,
            parameter,
            designation,
            employee,
            time
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


      const handleDateListItem = (name: "time", key: string, value: string) => {
        const time = checkTimePeriod(key);
   
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: time.startDate,
          endDate: time.endDate,
        }];
   
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };
   
      const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let time: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
   
        if (state.time.length && period === "start") {
          time = state.time.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.time.length && period === "end") {
          time = state.time.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.time.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          time = [{
            key: "custom",
            value: "custom",
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          time = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
   
        setState(prevState => ({
          ...prevState,
          time
        }));
      };


    const handleRightListItem = (name: "parameter" | "designation" | "employee", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];


        const isExist = !!state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const deleteChip = (name: "parameter" | "designation" | "employee" | "time" , key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            parameter: [],
            designation: [],
            employee: [],
            time: []        });
    };


    const onApply = () => {
        searchParams.set("parameter", JSON.stringify(state.parameter));
        searchParams.set("designation", JSON.stringify(state.designation));
        searchParams.set("employee", JSON.stringify(state.employee));
        searchParams.set("time", JSON.stringify(state.time));


        searchParams.set("page", "1");
        navigate({
            pathname: "/skill-analytics",
            search: searchParams.toString()
        });
        OnCloseFilter();
    };


    const onClose = () => {
        const parameter: { key: string, value: string }[] = searchParams.get("parameter") ? JSON.parse(String(searchParams.get("parameter"))) : [];
        const designation: { key: string, value: string }[] = searchParams.get("designation") ? JSON.parse(String(searchParams.get("designation"))) : [];
        const employee: { key: string, value: string }[] = searchParams.get("employee") ? JSON.parse(String(searchParams.get("employee"))) : [];
        const time: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("time") ? JSON.parse(String(searchParams.get("time"))) : [];


        setState(prevState => ({
            ...prevState,
            parameter,
            designation,
            employee,
            time
        }));
        OnCloseFilter();
    };


    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.parameter.length || state.designation.length || state.employee.length || state.time.length) ?
                                <>
                                    {state.parameter.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("parameter", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.designation.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("designation", ele.key)} label={ele.value} variant="outlined" />)}                                  
                                    {state.employee.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("employee", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.time.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("time", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={OnCloseFilter}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>


                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">


                            <MenuItem
                                index={0}
                                label="Parameter"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.parameter}
                            />


                            <MenuItem
                                index={1}
                                label="Designation"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.designation}
                            />


                            <MenuItem
                                index={2}
                                label="Employee"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                count={state.employee}
                            />


                            <MenuItem
                                index={3}
                                label="Time"
                                selectedMenu={state.selectedMenu === 3}
                                onChange={handleLeftListItem}
                                count={state.time}
                             />


                        </List>
                    </Grid>
                    <Divider orientation="vertical" />


                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                parameterPartial.map(i => 
                                <ListItemButton
                                    key={i.name}
                                    selected={!!state.parameter.find(ele => ele.key === i.name)}
                                    onClick={() => handleRightListItem("parameter",i._id, i.name)}
                                >
                                    <ListItemText primary={capitalize(i.name)} />
                                    <Checkbox edge="end" checked={!!state.parameter.find(ele => ele.key === i._id)} />
                                </ListItemButton>
                            )
                            }


                            {
                                state.selectedMenu === 1 &&
                                designationPartial.map(i => <ListItemButton
                                    key={i.name}
                                    selected={!!state.designation.find(ele => ele.key === i.name)}
                                    onClick={() => handleRightListItem("designation", i._id, i.name)}
                                >
                                    <ListItemText primary={capitalize(i.name)} />
                                    <Checkbox edge="end" checked={!!state.designation.find(ele => ele.key === i._id)} />
                                </ListItemButton>)
                            }
                           
                            {
                                state.selectedMenu === 2 &&
                                users.map(i => <ListItemButton
                                    key={i._id}
                                    selected={!!state.employee.find(ele => ele.key === i._id)}
                                    onClick={() => handleRightListItem("employee", i._id, i.name)}
                                >
                                    <ListItemText primary={capitalize(i.name)} />
                                    <Checkbox edge="end" checked={!!state.employee.find(ele => ele.key === i._id)} />
                                </ListItemButton>)
                            }


                            {
                               state.selectedMenu === 3 &&
                                <>
                                {[
                                    { key: "yesterday", value: "Yesterday" },
                                    { key: "today", value: "Today" },
                                    { key: "thisWeek", value: "Weekly" },
                                    { key: "thisMonth", value: "Monthly" },
                                    { key: "thisQuarter", value: "Quarterly" },
                                ]?.map((time) =>
                                    <ListItemButton
                                    key={time.key}
                                    selected={!!state.time.find(ele => ele.key === time.key)}
                                    onClick={() => handleDateListItem("time", time.key, capitalize(time.value))}
                                    >
                                    <ListItemText primary={time.value} />
                                    <Checkbox edge="end" checked={!!state.time.find(ele => ele.key === time.key)} />
                                    </ListItemButton>
                                )}
                                <Box marginTop={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.time[0]?.startDate ? dayjs(state.time[0]?.startDate) : null}
                                        onChange={e => handleDate(e, "start")}
                                        label="Start Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                    <div className="mt-3" />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.time[0]?.endDate ? dayjs(state.time[0]?.endDate) : null}
                                        onChange={e => handleDate(e, "end")}
                                        label="End Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                </Box>
                                </>
                            }
                                        </List>


                                    </Grid>
                                </Grid>
                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};


export default SkillAnalyticsFilter;
