
import HttpService from "../../http";
import { IGlossaryResponse, IGlossarySampleResponse } from "../../../interfaces/content-marketing/glossary";
import { glossary } from "../../endpoints";

const GlossaryImportService = () => {

    const { httpRequest } = HttpService();
    const getGlossarySample = async (search: object): Promise<IGlossarySampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossarySampleResponse>(
                "GET",
                `${glossary}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    const getGlossaryKeywordSample = async (search: object): Promise<IGlossarySampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossarySampleResponse>(
                "GET",
                `${glossary}/update-keyword-sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    const importGlossary = async (payload: object): Promise<IGlossaryResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryResponse>(
                "POST",
                `${glossary}/import`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    const importGlossaryKeyword = async (payload: object): Promise<IGlossaryResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryResponse>(
                "PUT",
                `${glossary}/update-keyword-import`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });
    return { getGlossarySample, importGlossary, getGlossaryKeywordSample, importGlossaryKeyword };

};

export { GlossaryImportService };