import "./index.scss";
import { 
  Box, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Rating,
  Chip,
  Tooltip
} from "@mui/material";
import { capitalize, checkElegibilityChipColor } from "../../../utilities/helper";
import CustomTable from "../../mui/table";
import { ICertification, IEducation, IProject, IWorkExperience, IResumeParseResult, ISkills, IAccordionField } from "../../../interfaces/resume-parser";
import { ArrowDropDownIcon } from "@mui/x-date-pickers/icons";
import { FC } from "react";
import { certificationColumns, educationColumns, projectColumns, workExperienceColumns } from "./fields";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface resumeParserProps{
  parsedData: IResumeParseResult | undefined;
}

const ResumeMatch: FC<resumeParserProps> = ({parsedData}) => {

  const createWorkExperienceRow = (
    index: number,
    resumeData: IWorkExperience
  ) => ({
    company: resumeData.company ?? "--",
    jobTitle: resumeData.jobTitle ?? "--",
    duration:   !resumeData.duration || 
    resumeData.duration === "0" || 
    resumeData.duration.toLowerCase() === "unknown"
      ? "--"
      : resumeData.duration.includes("months") || resumeData.duration.includes("year")
        ? resumeData.duration
        : `${resumeData.duration} months`,
    relevant: String(resumeData.relevant) ?? "--",
    achievements: (
      <Tooltip title={resumeData.achievements ?? ""}>
        <span>
          {resumeData.achievements &&
          resumeData.achievements.toString().length > 100
            ? `${resumeData.achievements.toString().slice(0, 100)}...`
            : resumeData.achievements}
        </span>
      </Tooltip>
    ),
  });

  const createEducationRow = (index: number, data: IEducation) => ({
      qualification: data.qualification ?? "--",
      specialization: data.specialization ?? "--",
      university: data.university ?? "--",
      score: data.score ?? "--"
    });

  const createCertificationRow = (index: number, data: ICertification) => ({
      name: data.name ?? "--",
      platform: data.platform ?? "--",
      year: data.year ?? "--"
    });

  const createProjectRow = (index: number, data: IProject) => ({
      name: data.name ?? "--",
      portal: data.portal ?? "--",
      year: data.year ?? "--"
    });

  const workExperienceRow = parsedData && parsedData.workExperience.map((test, index: number) => createWorkExperienceRow(index, test));
  const educationRow = parsedData && parsedData.education.map((data, index: number) => createEducationRow(index, data));
  const certificationRow = parsedData && parsedData.certifications.map((data, index: number) => createCertificationRow(index, data));
  const projectRow = parsedData && parsedData.projects.map((data, index: number) => createProjectRow(index, data));
  const accoridianFields: IAccordionField[]  = [
    {
      title: "Work Experience",
      columns: workExperienceColumns,
      rows: workExperienceRow as unknown as IWorkExperience[]
    },

    {
      title: "Education",
      columns: educationColumns,
      rows: educationRow as IEducation[]
    },

    {
      title: "Certificate",
      columns: certificationColumns,
      rows: certificationRow as ICertification[]
    },

    {
      title: "Project",
      columns: projectColumns,
      rows: projectRow as IProject[]
    },
  ];

  const isAtsFriendly = (
    (parsedData && parsedData.certifications && parsedData.certifications.length > 0) ||
    (parsedData && parsedData.education && parsedData.education.length > 0) ||
    (parsedData && parsedData.workExperience && parsedData.workExperience.length > 0) ||
    (parsedData && parsedData.projects && parsedData.projects.length > 0) ||
    (parsedData && parsedData.skills && parsedData.skills.length > 0)
  );  

   return (
    isAtsFriendly ?
    <>
    <Box className= "ai-parser-box">
      <Typography variant="h4" gutterBottom>
      <Typography variant="h3" component="div" color="success.main" paddingTop={"15px"}>
     <strong> {parsedData && parsedData.scoring.atsScore}%  </strong>
      </Typography>
      </Typography>
      <div className="match-and-chip">
      Match: <Chip variant={"outlined"} className = "chipclass" color={checkElegibilityChipColor(parsedData && parsedData.scoring.match || "")} label={capitalize(parsedData && parsedData.scoring.match || "")} />
      </div>
      <div className="accordian-container">
        <div className="cardContainer2" >
            <fieldset className="fieldset">
                <legend className="legend"><strong>Summary</strong></legend>
                <div className="summary-body">
                <Typography variant="body2">{parsedData && parsedData.scoring.analysis}</Typography>
                </div>
            </fieldset>
        </div>
      <Accordion className="skill-accordian">
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <span><strong>Skills</strong></span>
        </AccordionSummary>
        <AccordionDetails className = "skill-acordian-detail">
          {parsedData && parsedData.skills.length ? parsedData.skills.map((skill:ISkills, index: number) => (
            <Box key={index} className= "skill-sub-container">
              <Typography variant="body2">{skill.name}</Typography>
              <Box className = "skill-container">
                <Rating value={skill.rating} readOnly precision={0.5} />
                <Typography variant="body2" marginTop={"5px"} >{skill.rating}</Typography>
              </Box>
            </Box>
          )): <Typography variant="body2">No Data Found</Typography>}
        </AccordionDetails> 
      </Accordion>
      {accoridianFields.map((item, index) => (
  <Accordion className="accordian" key={index}>
    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>  
      <Typography><strong>{item.title}</strong></Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box>
        <Box overflow="auto" paddingTop="5px">
          {item.title === "Work Experience" && (
            <CustomTable<IWorkExperience>
              columns={item.columns}
              rows={item.rows as IWorkExperience[]}
              width="calc(100% - 2px)"
              height={300}
            />
          )}
          {item.title === "Education" && (
            <CustomTable<IEducation>
              columns={item.columns}
              rows={item.rows as IEducation[]}
              width="calc(100% - 2px)"
              height={300}
            />
          )}
          {item.title === "Certificate" && (
            <CustomTable<ICertification>
              columns={item.columns}
              rows={item.rows as ICertification[]}
              width="calc(100% - 2px)"
              height={300}
            />
          )}
          {item.title === "Project" && (
            <CustomTable<IProject>
              columns={item.columns}
              rows={item.rows as IProject[]}
              width="calc(100% - 2px)"
              height={300}
            />
          )}
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>
))}

      </div>
    </Box>
    </>
    :
    <Box display="flex" className="center-message">
    <ErrorOutlineIcon fontSize="large" />
    <div>Unable to extract data from the resume </div>
  </Box>
  
);
};

export default ResumeMatch;