import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useUser from "../../../hooks/useUser";
import useResource from "../../../hooks/useResource";
import { a11yProps } from "../../../components/shared/mui-tabs";

interface IState {
  redirectionLinks: string[]
}

const EvaluationLayout = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const outlet = useOutletContext();
  const { user } = useUser();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IState>({
    redirectionLinks: []
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (resourceAllocate("okr-team-evaluation.read")) {
      redirectionLinks.push("pending");
      redirectionLinks.push("completed");
    }
    setState(prev => ({
      ...prev,
      redirectionLinks
    }));
  }, [user]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };
  
  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {
          resourceAllocate("okr-team-evaluation.read") &&
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab className="tabs-space start-tab-space" label="Pending" {...a11yProps(0)} />
            <Tab className="tabs-space" label="Completed" {...a11yProps(1)} />
          </Tabs>
        }
      </Box>
      <Outlet context={outlet} />
    </Box>
  );
};

export default EvaluationLayout;