import React, { useEffect, useState , MouseEvent} from "react";
import { useNavigate } from "react-router-dom";
import { IErrorResponse, IPagination } from "../../../interfaces";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useSearchParams, Outlet } from "react-router-dom";
import { capitalize, createIndex, formatDate } from "../../../utilities/helper";
import CustomTable from "../../../components/mui/table";
import CustomTypography from "../../../components/mui/max-length-limit";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import TestFilter from "../filter";
import { IResourceRequestedRow, IResourceRequested, IRequestResourceState  } from "../../../interfaces/resource-request";
import { ResourceRequestedServices } from "../../../services/resource-request";
import "./style.scss";
import GetActions from "../../../components/get-actions";
import useSnackbar from "../../../hooks/useSnackbar";
import useUser from "../../../hooks/useUser";
import useResource from "../../../hooks/useResource";

const RequestList = () => {
  let rows: IResourceRequestedRow[] = [];  
  const { resourceAllocate } = useResource();
  const { user } = useUser();
  const navigate = useNavigate();
  const { getRequestedResources, updateResourceStatus } = ResourceRequestedServices();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { snackbar } = useSnackbar();
  const requestStatus = ["HR_APPROVED", "HR_REJECTED", "COMPLETED", "SUBMITTED",  "ADMIN_APPROVED", "ADMIN_REJECTED"];
  const [state, setState] = useState<IRequestResourceState>({
    recoverWarning: false,
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      requestType:[],
      categoryId:[],
      category: [],
      status: [],
      requestedBy: []
    },
    filterCount: 0,
    searching: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
  });

  const columns = [
    {
      id: "id",
      label: "Sr. No."
    },
    {
      id: "resource_name",
      label: "Resource Name"
    },
    {
      id: "resource_type",
      label: "Resource Type"
    },
    {
      id: "requested_by",
      label: "Requested By"
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "submission_date",
      label: "Requested On"
    },
    {
      id: "completed_on",
      label: "Closed on"
    },
    {
      id: "action",
      label: "Action"
    }

  ];

  const isAdmin =  user?._role?.name.includes("admin");

  const searchRecord = useDebounce(search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const getResourceRequest = useQuery({
    queryKey: ["requestResourceList", state.pagination.page, state.searching, state.filters],
    queryFn: () => getRequestedResources({
      pagination: true, page: state.pagination.page, limit: state.pagination.limit, ...state.filters
    }
    )
  });

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const requestType: { key: string, value: string }[] = searchParams.get("requestType") ? JSON.parse(String(searchParams.get("requestType"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const requestedBy: { key: string, value: string }[] = searchParams.get("requestedBy") ? JSON.parse(String(searchParams.get("requestedBy"))) : [];
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const submissionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("submissionDate") ? JSON.parse(String(searchParams.get("submissionDate"))) : [];
    const completionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("completionDate") ? JSON.parse(String(searchParams.get("completionDate"))) : [];
    filterCount += category.length ? 1 : 0;
    filterCount += requestType.length ? 1 : 0;
    filterCount += status.length? 1 : 0;
    filterCount += requestedBy.length? 1 : 0;
    filterCount += submissionDate.length? 1: 0;
    filterCount += completionDate.length? 1: 0;

    let requestSubmissionDate: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (submissionDate && submissionDate.length) {
      requestSubmissionDate = {
        startDate: submissionDate && submissionDate[0].startDate,
        endDate: submissionDate && submissionDate[0].endDate,
      };
    }

    let requestCompletionDate: { 
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (completionDate && completionDate.length) {
      requestCompletionDate = {
        startDate: completionDate[0] && completionDate[0].startDate,
        endDate: completionDate[0] && completionDate[0].endDate,
      };
    }
    
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map(item => item.key),
        requestType: requestType.map(item =>  item.key),
        status: status.map( item => item.key),
        requestedBy: requestedBy.map( item => item.key), 
        requestCompletionDate: requestCompletionDate,
        requestSubmissionDate: requestSubmissionDate
      },
      filterCount
    }));
  }, [searchParams]);

  useEffect(() => {
    if ( getResourceRequest.data && 
         getResourceRequest.data.data && 
         getResourceRequest.data.data.length ) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getResourceRequest.data.meta.page,
          totalPages: getResourceRequest.data.meta.totalPages,
          totalRecords: getResourceRequest.data.meta.totalRecords
        }
      }));
    }
  }, [getResourceRequest.data?.meta]);

  useEffect(() => {
    if (getResourceRequest.data && getResourceRequest.data.data && getResourceRequest.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [getResourceRequest.data && getResourceRequest.data.data]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleStatus = async (event: SelectChangeEvent<string>, _id: string) => {
    const { name, value } = event.target;
    try {
      const payload = {
        _id,
        [name]: value,
      };
      const resource = await updateResourceStatus(payload);
      snackbar(resource.message, "info");
      getResourceRequest.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const handleAdminApproval = async(_id: string , status: string) =>{
    try {
      const payload = {
        _id,
        status: status,
      };
      const resource = await updateResourceStatus(payload);
      snackbar(resource.message, "info");
      getResourceRequest.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    } 
  };

  const createRow = (index: number, resource_request: IResourceRequested, pagination: IPagination) => {
    const action = (
      <Grid item md={3}>
      <GetActions
      icons={[
        { name: "Mail", method: () => navigate({ pathname: "mail/" + resource_request._id, search: searchParams.toString() }), disabled: !resourceAllocate("resource-request-email.write") },
        { name: "Approve", method: () => handleAdminApproval(resource_request._id, "ADMIN_APPROVED"), disabled: resource_request.status === "ADMIN_APPROVED" || !isAdmin },
        { name: "Reject", method: () => handleAdminApproval(resource_request._id, "ADMIN_REJECTED"), disabled: resource_request.status === "ADMIN_REJECTED" || !isAdmin },
      ]}
    />
      </Grid>
    );
    return {
      id: createIndex(pagination, index),
      resource_type: (
        <CustomTypography
          limit={30}
          label={ capitalize(resource_request.requestType === "human" ? "Human Resource" : resource_request.requestType )}
        />
      ),      
      resource_name: (
        <div className="primaryColor">
        <CustomTypography
          limit={30}
          label={
            capitalize(resource_request?.requestType === "human"
              ? resource_request?.jobTitle?.name 
              : resource_request?.hardwareDetails?.name || resource_request?.softwareDetails?.name || "")
          }
          onClick={() => navigate({ pathname: "manage/" + resource_request._id, search: searchParams.toString() })}
          color="primary"
        />
        </div>
      ),
      requested_by: capitalize(resource_request.requestedBy && resource_request.requestedBy.name || ""),
      submission_date: formatDate(resource_request.submissionDate || ""),
      status: (
        <FormControl
        className="status"
        >
        <Select
          size="small"
          name="status"
          onChange={e => handleStatus(e, resource_request._id )}
          value={resource_request.status}
          disabled = {!resourceAllocate("resource-request-status.write")}
        >

        {requestStatus.map((status, i) => {
          const isResourceRequestStatus = resourceAllocate("resource-request-status.write");
          const isAdmin = user && user._role && user._role.name === "admin";
          const isDisabled = !isResourceRequestStatus || (!isAdmin && ["ADMIN_APPROVED", "ADMIN_REJECTED"].includes(status));
          const menuItem = ["HR_APPROVED", "HR_REJECTED", "ACTION_TAKEN", "ADMIN_APPROVED", "ADMIN_REJECTED"].includes(status) 
            ? status.replace("_", " ") 
            : status;

          return (
            <MenuItem key={i} value={status} disabled={isDisabled}>
              {capitalize(menuItem)}
            </MenuItem>
          );
        })}
        </Select>
      </FormControl>
      ),
      review_started_on: formatDate(resource_request.actionStartDate || ""),
      approved_on: formatDate(resource_request.hrApprovedDate) || "",
      rejected_on: formatDate(resource_request.hrRejectedDate || ""),
      action_started_on: formatDate(resource_request.actionStartDate || ""),
      completed_on: formatDate(resource_request.completionDate || ""),
      action,
    };
  };
  if (getResourceRequest.data && 
      getResourceRequest.data.data && 
      getResourceRequest.data.data.length) {
      rows = getResourceRequest.data && getResourceRequest.data.data && getResourceRequest.data.data.map((resource_request, i) => createRow(i, resource_request, state.pagination));
      }

  return (
    <>
      <Header
        className="my-2"
        btnText="Add New Resource Request"
        onBtnClick={resourceAllocate("resource-request.write") ? () => navigate({ pathname: "/resource-requested/requests/new", search: searchParams.toString() }) : undefined}
        searchPlaceholder="Search by job title, hardware/software name"
        onSearch={onSearch} 
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <TestFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnCloseFilter={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 250px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <Outlet context={{ reFetch: getResourceRequest.refetch }} />
    </>
  );
};
export default RequestList;
