import "./style.scss";
import { useQuery } from "@tanstack/react-query";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";
import { ChangeEvent, useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { IPMSSubParameter, IPMSSubParameterRow, IPMSSubParameterState } from "../../../../interfaces/pms/pms-parameter/sub-parameter";
import useResource from "../../../../hooks/useResource";
import useDebounce from "../../../../hooks/useDebounce";
import { PmsParametersService } from "../../../../services/pms/pms-parameters";
import { pmsSubParametersService } from "../../../../services/pms/pms-parameters/sub-parameters";
import { Box, Typography } from "@mui/material";
import { capitalize } from "../../../../utilities/helper";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";

interface outletProps {
  refetchSubParameters: () => void;
}

const PMSSubParameter = () => {
    const { parameterId } = useParams();
    const { getPmsParameter } = PmsParametersService();

  const parameter = useQuery({ 
    queryKey: ["parameters-details"], 
    queryFn: () => getPmsParameter({ _id: parameterId }),
    enabled: !!parameterId
  });

  const parameterName = parameter && parameter.data && parameter.data.data && parameter.data.data.name || "";
  const parameterWeightage = parameter && parameter.data && parameter.data.data && parameter.data.data.weightage || 0;


  let rows: IPMSSubParameterRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getPmsSubParameters } = pmsSubParametersService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IPMSSubParameterState>({
    filters: {
      search: "",
    },
  });

  const pmsSubParameters = useQuery({
    queryKey: ["all-pms-sub-parameters", state.filters],
    queryFn: () =>
        getPmsSubParameters({
        pagination: false,
        _parameter: parameterId,
        ...state.filters,
      }),
  });
  const pmsSubParametersData = (pmsSubParameters && pmsSubParameters.data && pmsSubParameters.data.data) || [];

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      filters: {
        search,
      },
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleClick = () => {
    navigate({
      pathname: "/pms-parameters",
      search: searchParams.toString(),
    });
  };

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "weightage",
      label: "Weightage(%)",
    },
    {
      id: "netWeightage",
      label: "Net Weightage(%)",
    },
  ];

  const createRow = (index: number, parameter: IPMSSubParameter) => (
    {
        id: (index + 1).toString(),
        name: parameter.name,
        weightage: parameter.weightage,
        netWeightage: parameterWeightage*(parameter.weightage)/100,
    }
  );

  if (pmsSubParametersData.length) {
    rows = pmsSubParametersData.map((param, i) => createRow(i, param));
  }

  return (
    <div>
        <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography className="title" variant="h5" fontWeight={600}>
            {capitalize(parameterName)}
          </Typography>
          <span className="sub-title-box">Weightage: {parameterWeightage}%</span>
        </div>
      </header>
      <Header
        className="my-2"
        searchPlaceholder="Search by sub parameter name"
        onSearch={onSearch}
        btnText="Add/Edit Sub Parameter"
        onBtnClick={
          resourceAllocate("pms-sub-parameter.write")
            ? pmsSubParametersData.length > 0
              ? () => navigate("update")
              : () => navigate("new")
            : undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 230px)"
          errorMessage="Add sub parameters to see the data here"
        />
      </Box>

      <Outlet
        context={{
          ...outlet,
          refetchSubParameters: pmsSubParameters.refetch,
          pmsSubParameters: pmsSubParametersData,
          parameterWeightage: parameterWeightage,
          parameterName: parameterName,
        }}
      />
    </div>
  );
};

export default PMSSubParameter;
