import Joi from "joi";
import { required, password_pattern } from "../../shared";

export const offeredOnBoardValidation = Joi.object({
    to: Joi.string()
        .email({ tlds: { allow: false } })
        .label("To")
        .required()
        .messages({
            "string.empty": required,
        }),
    ccText: Joi.string()
        .label("CC")
        .allow(""),
    cc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("CC"),
    name: Joi.string()
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    company: Joi.string()
        .required()
        .label("Company")
        .min(3)
        .max(60)
        .trim()
        .messages({
            "string.empty": required,
        }),
    reportingManager: Joi.string()
        .label("Reporting Manager")
        .messages({
            "string.empty": required,
        }),
    onboardEmail: Joi.string()
        .email({ tlds: { allow: false } })
        .label("Onboard Email")
        .messages({
            "string.empty": required,
        }),
    onboardPassword: Joi.string()
        .min(8)
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/)
        .required()
        .label("Password")
        .messages({
            "string.empty": required,
            "string.pattern.base": password_pattern
        }),
    jobPosition: Joi.string()
        .label("Job Title")
        .messages({
            "string.empty": required,
        }),

    _department: Joi.string()
        .label("Department"),


    jobType: Joi.string().allow(""),
});