import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  TextField,
  capitalize,
  Box,
  AutocompleteChangeReason,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect, useState } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import {
  ICategory,
  IErrorResponse,
  IKeyword,
} from "../../../../interfaces";
import { joiResolver } from "@hookform/resolvers/joi";
import { alternativesBasicDetailValidation } from "../../../../validations/content-marketing/alternatives";
import useResource from "../../../../hooks/useResource";
import ProjectsService from "../../../../services/content/projects-products";
import plannerService from "../../../../services/planner";
import KeywordService from "../../../../services/content/keyword";
import useSnackbar from "../../../../hooks/useSnackbar";
import { AlternativeComparisonService } from "../../../../services/alternative-comparison";
import CustomLabel from "../../../../components/mui/custom-label";
import Select from "../../../../components/mui/select";
import { IAlternativeComparisonForm } from "../../../../interfaces/alternative-comparison";
import ImageDialog from "../image-dialog";

const BasicDetail = () => {
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<IAlternativeComparisonForm>({
    resolver: joiResolver(alternativesBasicDetailValidation),
    defaultValues: {
      type: "",
      title: "",
      description: "",
      slug: "",
      primaryKeyword: "",
      secondaryKeywords: [],
      _quarter: "",
      leftBannerImage: "",
      rightBannerImage: "",
      _product: "",
    },
  });
  const { resourceAllocate } = useResource();
  const { getContentProjects } = ProjectsService();
  const { getPlannerList } = plannerService();
  const { getContentKeywords } = KeywordService();
  const { snackbar } = useSnackbar();
  const { addComparison, updateComparison, getComparison } =
    AlternativeComparisonService();
  const navigate = useNavigate();
  const { id } = useParams();
  const hitQuery = !(id === "new");
  const [selectedProductId, setSelectedProductId] = useState<string>("");


  const projectsList = useQuery({
    queryKey: ["projects"],
    queryFn: () => getContentProjects({ isActive: true }),
  });

  const quartersList = useQuery({
    queryKey: ["quarters", selectedProductId],
    queryFn: () => getPlannerList({ productId: selectedProductId }),
  });

  const keywordsList = useQuery({
    queryKey: ["keywords-for-alternatives", selectedProductId],
    queryFn: () => getContentKeywords({
      productId: selectedProductId
    }),
    enabled: selectedProductId !== "",
  });
  const alternatives = useQuery({
    queryKey: ["alternatives", id],
    queryFn: () =>
      getComparison({
        _id: id, populate: ["leftBannerImage", "rightBannerImage", "leftBannerImage.imageId", "rightBannerImage.imageId"]
      }),
    enabled: hitQuery,
  });

  useEffect(() => {
    if (alternatives?.data?.data && id !== "new") {
      const data = alternatives && alternatives.data && alternatives.data.data;
      setValue("title", data.title || "");
      setValue("description", data.description || "");
      setValue("slug", data.slug || "");
      setValue("_product", data._product || "");
      setSelectedProductId(data._product);
      setValue("_quarter", data._quarter || "");
      setValue("primaryKeyword", data.primaryKeyword || "");
      setValue("secondaryKeywords", data.secondaryKeywords || []);
      setValue("leftBannerImage", data.leftBannerImage?._id || "");
      setValue("rightBannerImage", data.rightBannerImage?._id|| "");
      setLeftBannerImage(data.leftBannerImage?.imageId?.imageUrl || "");
      setRightBannerImage(data.rightBannerImage?.imageId?.imageUrl || "");
      setValue("type", data.type || "");
    }
  }, [alternatives?.data?.data]);
  const onSubmit = async (data: IAlternativeComparisonForm) => {
    try {
      if (id === "new") {
        const payload = data;
        if(data.leftBannerImage === "") {
          delete payload.leftBannerImage;
        }
        if(data.rightBannerImage === "") {
          delete payload.rightBannerImage;
        }
        const response = await addComparison(payload);
        snackbar(response.message, "info");
        navigate({
          pathname: "/comparison",
          search: searchParams.toString(),
        });
      } else {
        const payload = {
          ...data,
          _id: id,
        };
        if(data.leftBannerImage === "") {
          delete payload.leftBannerImage;
        }
        if(data.rightBannerImage === "") {
          delete payload.rightBannerImage;
        }
        const response = await updateComparison(payload);
        snackbar(response.message, "info");
        navigate({
          pathname: "/comparison",
          search: searchParams.toString(),
        });
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };
  const handleProductChange = async (value: ICategory | null) => {
    if (value) {
      setSelectedProductId(value._id);
      setValue("_product", value._id);
      trigger("_product");
      setValue("_quarter", "");
      setValue("primaryKeyword", "");
      setValue("secondaryKeywords", []);
    }
  };

  const handlePrimaryKeywordChange = async (value: ICategory | null) => {
    if (value) {
      setValue("primaryKeyword", value._id);
      trigger("primaryKeyword");
    }
  };

  const onChangeSecondaryKeywordsAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IKeyword[],
    reason: AutocompleteChangeReason
  ) => {
    const keywordData = getValues("secondaryKeywords");
    const isExist = keywordData.includes(value[0]?._id);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          keywordData.push(value[0]?._id);
          setValue("secondaryKeywords", keywordData);
        }
        break;
      default:
        break;
    }

    trigger("secondaryKeywords");
  };

  const handleKewordDeleteChip = (kewordId: string) => {
    const data = getValues("secondaryKeywords");
    const newData = data.filter((id) => id !== kewordId);
    setValue("secondaryKeywords", newData);
  };
  const [isLeftBannerImageClicked, setIsLeftBannerImageClicked] = useState(false);
  const [isRightBannerImageClicked, setIsRightBannerImageClicked] = useState(false);
  const onClose = () => {
    setIsLeftBannerImageClicked(false);
    setIsRightBannerImageClicked(false);
  };

  const [leftBannerImage, setLeftBannerImage] = useState("");
  const [rightBannerImage, setRightBannerImage] = useState("");

  const addImage = (image: string, imageId: string, type: string) => {
    const name = type === "leftBannerImage" ? "leftBannerImage" : "rightBannerImage";
    setValue(name, imageId);
    type === "leftBannerImage" ? setLeftBannerImage(image) : setRightBannerImage(image);
    setIsLeftBannerImageClicked(false);
    setIsRightBannerImageClicked(false);
  };

  return (
    <div className="my-3 form-border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
        <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="type"
              label={<CustomLabel label="Type" required />}
              size={"small"}
              variant={"outlined"}
              error={!!errors["type"]}
              helperText={errors["type"]?.message}
            >
              [
                <MenuItem key={"ALTERNATIVE"} value={"ALTERNATIVE"}>
                    {"Alternative"}
                  </MenuItem>,
                  <MenuItem key={"COMPARISON"} value={"COMPARISON"}>
                    {"Comparison"}
                  </MenuItem>
              ]
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="title"
              render={(prop) => (
                <TextField
                  label={<CustomLabel label="Title" required />}
                  className="disable-text"
                  variant="outlined"
                  size="small"
                  placeholder="Type title"
                  error={!!errors["title"]}
                  helperText={errors["title"]?.message}
                  {...prop.field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="description"
              render={(prop) => (
                <TextField
                  label={<CustomLabel label="Description" required />}
                  className="disable-text"
                  variant="outlined"
                  size="small"
                  placeholder="Type description"
                  multiline
                  minRows={3}
                  maxRows={3}
                  error={!!errors["description"]}
                  helperText={errors["description"]?.message}
                  {...prop.field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="slug"
              render={(prop) => (
                <TextField
                  label={<CustomLabel label="Slug" required />}
                  className="disable-text"
                  variant="outlined"
                  size="small"
                  placeholder="Type slug"
                  error={!!errors["slug"]}
                  helperText={errors["slug"]?.message}
                  {...prop.field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"_product"}
              render={(prop) => (
                <Autocomplete
                  className="disable-text"
                  options={
                    (projectsList &&
                      projectsList.data &&
                      projectsList.data.data.map((data) => data)) ||
                    []
                  }
                  clearIcon={null}
                  getOptionLabel={(option) => capitalize(option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors["_product"]}
                      helperText={errors["_product"]?.message}
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label="Product" required />}
                      placeholder={"Please Select Product"}
                    />
                  )}
                  {...prop.field}
                  value={
                    (projectsList &&
                      projectsList.data &&
                      projectsList.data.data.find(
                        (data) => data._id === getValues("_product")
                      )) ||
                    null
                  }
                  onChange={(e, value) => {
                    handleProductChange(value);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {capitalize(option.name)}
                    </li>
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="_quarter"
              label={<CustomLabel label="Quarter" required />}
              size={"small"}
              variant={"outlined"}
              error={!!errors["_quarter"]}
              helperText={errors["_quarter"]?.message}
              disabled={selectedProductId === null}
            >
              {quartersList &&
                quartersList.data &&
                quartersList.data.data.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"primaryKeyword"}
              render={(prop) => (
                <Autocomplete
                  className="disable-text"
                  options={
                    (keywordsList &&
                      keywordsList.data &&
                      keywordsList.data.data &&
                      keywordsList.data.data.map((data) => data)) ||
                    []
                  }
                  clearIcon={null}
                  getOptionLabel={(option) => capitalize(option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors["primaryKeyword"]}
                      helperText={errors["primaryKeyword"]?.message}
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label="Primary Keyword" required />}
                      placeholder={"Please Select Product"}
                    />
                  )}
                  {...prop.field}
                  value={
                    (keywordsList &&
                      keywordsList.data &&
                      keywordsList.data.data?.find(
                        (data) => data._id === getValues("primaryKeyword")
                      )) ||
                    null
                  }
                  onChange={(e, value) => {
                    handlePrimaryKeywordChange(value);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {capitalize(option.name)}
                    </li>
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" alignItems="start" flexDirection="column">
              <Controller
                control={control}
                name={"secondaryKeywords"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={
                          (keywordsList &&
                            keywordsList.data &&
                            keywordsList.data.data?.map(
                              (keywordData) => keywordData
                            )) ||
                          []
                        }
                        getOptionLabel={(option) => capitalize(option.name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={
                              <CustomLabel label="Secondary Keyword" required />
                            }
                            placeholder={"Secondary Keyword"}
                            error={!!errors["secondaryKeywords"]}
                            helperText={errors["secondaryKeywords"]?.message}
                          />
                        )}
                        {...prop.field}
                        value={[]}
                        onChange={(e, value, reason) =>
                          onChangeSecondaryKeywordsAutoComplete(
                            e,
                            value,
                            reason
                          )
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {capitalize(option.name)}
                          </li>
                        )}
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >
                      {getValues("secondaryKeywords")?.map((keyowrdId) => (
                        <Chip
                          key={keyowrdId}
                          label={
                            keywordsList &&
                            keywordsList.data &&
                            keywordsList.data.data?.find(
                              (user) => user._id === keyowrdId
                            )?.name
                          }
                          color="primary"
                          variant="outlined"
                          onDelete={() => handleKewordDeleteChip(keyowrdId)}
                          sx={{ margin: "5px" }}
                        />
                      ))}
                    </Box>
                  </>
                )}
              />
            </Box>
          </Grid>

        <Grid item xs={4} key={"leftBannerImage"}>
          <Box display="flex" alignItems="start">
            <div
              className="w-100"
              onClick={() => setIsLeftBannerImageClicked(true)}
            >
              <Controller
                control={control}
                name={"leftBannerImage"}
                render={() => (
                  <TextField
                    label={<CustomLabel label={"Left Banner Image"} />}
                    className="disable-text"
                    variant="outlined"
                    size="small"
                    value={leftBannerImage}
                    placeholder={"Upload left banner image"}
                    style={{ cursor: "pointer" }}
                    error={!!errors["leftBannerImage"]}
                    helperText={errors["leftBannerImage"]?.message}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Upload">
                          <IconButton color="primary">
                            <UploadIcon color="action" />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Box>
        </Grid>


        <Grid item xs={4} key={"rightBannerImage"}>
          <Box display="flex" alignItems="start">
            <div
              className="w-100"
              onClick={() => setIsRightBannerImageClicked(true)}
            >
              <Controller
                control={control}
                name={"rightBannerImage"}
                render={() => (
                  <TextField
                    label={<CustomLabel label={"Right Banner Image"} />}
                    className="disable-text"
                    variant="outlined"
                    size="small"
                    placeholder={"Upload right banner image"}
                    style={{ cursor: "pointer" }}
                    value={rightBannerImage}
                    error={!!errors["rightBannerImage"]}
                    helperText={errors["rightBannerImage"]?.message}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Upload">
                          <IconButton color="primary">
                            <UploadIcon color="action" />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Box>
        </Grid>
        </Grid>

        <div className="form-action-btn">
          <Button
            variant="outlined"
            onClick={() => {
              navigate({
                pathname: "/comparison",
                search: searchParams.toString(),
              });
            }}
            disabled={!resourceAllocate("cms-alternative-comparison.write")}
          >
            Discard
          </Button>
          <Button
            type="submit"
            className="ml-2"
            disabled={!resourceAllocate("cms-alternative-comparison.write")}
          >
            Save
          </Button>
        </div>
      </form>
            {
              isLeftBannerImageClicked && <ImageDialog _alternativeComparison={id || ""} addImage={addImage} onClose={onClose} type="leftBannerImage"/>
            }
            {
              isRightBannerImageClicked && <ImageDialog _alternativeComparison={id || ""} addImage={addImage} onClose={onClose} type="rightBannerImage"/>
            }
      <Outlet />
    </div>
  );
};

export default BasicDetail;
