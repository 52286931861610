import { ListItem as MuiListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { FC, ElementType } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface props {
    Icon: ElementType
    label: string
    navigateLink: string;
    isCollapse: boolean;
    mainMenuItem?: boolean;
}

const ListItem: FC<props> = ({ label, navigateLink, Icon, isCollapse, mainMenuItem }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isStateActive = location.pathname === navigateLink || location.pathname.split("/")[1] === navigateLink.split("/")[1] || location.pathname.split("/")[1] === navigateLink.split("/")[0];
    const primaryClassName = isStateActive ? "active-listItem-text" : "listItem-text";

    return (
        <MuiListItem
            onClick={() => navigate(navigateLink)}
            id="list-item"
            className={`collapse-ListItem ${isStateActive ? "active-item" : ""} ${mainMenuItem && !isCollapse ? "main-menu-item" : ""}`}
        >
            <Tooltip title={label} disableHoverListener={isCollapse ? false : true} placement="right">
                <ListItemIcon className="navIcons">
                    {
                        <Icon style={{ color: "var(--secondary-color)" }} />
                    }
                </ListItemIcon>
            </Tooltip>
            <ListItemText
                className="collapse-text"
                primary={label}
                classes={{primary: `${primaryClassName} ${mainMenuItem ? "main-menu-primary" : ""}` }}
            />
        </MuiListItem>
    );
};

export default ListItem;