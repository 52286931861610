import { useState } from "react";
import Header from "../../../../../components/header";
import CustomTable from "../../../../../components/mui/table";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IColumn, IErrorResponse, IQuestionTemplateState, IQuestionTestCasesRow, TestCase } from "../../../../../interfaces";
import GetActions from "../../../../../components/get-actions";
import useSnackbar from "../../../../../hooks/useSnackbar";
import WarningDialog from "../../../../../components/mui/warning-dialog";
import { Box, Typography } from "@mui/material";
import useResource from "../../../../../hooks/useResource";
import { TechnicalQuestionService } from "../../../../../services";


const QuestionTestCases = () => {

	const { snackbar } = useSnackbar();
	const { id } = useParams();
	const { resourceAllocate } = useResource();
	const [searchParams] = useSearchParams();
	const { getTechnicalQuestion, deleteTechnicalQuestionTestCase } = TechnicalQuestionService();

	const [state, setState] = useState<IQuestionTemplateState>({
		searching: {
			search: ""
		},
		deleteWarning: false,
		_deleteId: "",
	});

	const navigate = useNavigate();

	const hitQuery = id !== "new";

	const getQuestion = useQuery({
		queryKey: ["getTechnicalQuestion", id],
		queryFn: () => getTechnicalQuestion({ _id: id }),
		enabled: hitQuery
	});


	const columns: IColumn[] = [
		{
			id: "input",
			label: "Input",
			minWidth: 200

		},
		{
			id: "output",
			label: "Output",
			minWidth: 200

		},
		{
			id: "type",
			label: "Type",
			minWidth: 200

		},
		{
			id: "action",
			label: "Actions",
			align: "right"
		}
	];

	const handleDelete = (_deleteId = "") => {
		setState(prevState => ({
			...prevState,
			deleteWarning: !state.deleteWarning,
			_deleteId: _deleteId
		}));
	};

	const onDelete = async () => {
		try {
			const response = await deleteTechnicalQuestionTestCase({ _testCase: state._deleteId, _id: id });

			snackbar(response?.message, "info");
			handleDelete();
			getQuestion.refetch();
			navigate({
				pathname: "/assessment/technical-questions/" + id,
				search: searchParams.toString()
			});
		} catch (error) {
			const err = error as IErrorResponse;
			snackbar(err?.data?.message, "error");
			console.log("Error in delete of goals", err);
		}
	};


	const createRows = (data: TestCase) => {
		const action = <GetActions
			icons={[
				{
					name: "Edit", method: () => {
						navigate({
							pathname: "manage-test-case/" + data._id,
							search: searchParams.toString()
						});
					},
					disabled: !resourceAllocate("technical-question.write")
				},
				{ name: "Delete", method: () => handleDelete(data._id), disabled: !resourceAllocate("technical-question.remove") },
			]}
		/>;
		return {
			input: data.input,
			output: data.output,
			type: data.type,
			action
		};
	};

	let rows: IQuestionTestCasesRow[] = [];
	const totalTestCases =
		(getQuestion &&
			getQuestion.data &&
			getQuestion.data.data &&
			getQuestion.data.data.testCases &&
			getQuestion.data.data.testCases.length)
			? getQuestion.data.data.testCases.length
			: 0;

	if (
		getQuestion &&
		getQuestion.data &&
		getQuestion.data.data &&
		getQuestion.data.data.testCases &&
		(getQuestion.data.data.testCases.length)) {
		rows = (getQuestion.data.data.testCases || []).map((data: TestCase) => createRows(data));
	}


	const openManageComponent = () => {
		navigate({ pathname: "manage-test-case/new", search: searchParams.toString() });
	};


	return (
		<div>

			<Header
				className="my-2"
				btnText="Add Test Case"
				onBtnClick={resourceAllocate("technical-question.write") ? () => openManageComponent() : undefined}

			/>
			<Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
				<Box display="flex" alignItems="center">
					<Typography variant="body1">Total Test Cases:</Typography>
					<Typography className="ml-3" variant="body1" >{totalTestCases ?? 0}</Typography>
				</Box>
			</Box>
			<CustomTable
				columns={columns}
				rows={rows}
				height={"calc(100vh - 355px)"}
			/>

			<WarningDialog
				isOpen={state.deleteWarning}
				onClose={() => handleDelete()}
				onConfirm={onDelete}
				title="Delete Test Case"
				description="Are you sure you want to delete this Test Case?"

			/>
			<Outlet context={{ reFetch: getQuestion.refetch }} />
		</div>
	);
};

export default QuestionTestCases;