import { useEffect, KeyboardEvent, FC, useState, ChangeEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CollegeService } from "../../../../../services";
import { capitalize } from "../../../../../utilities/helper";
import { collegeValidation } from "../../../../../validations";
import { validateEmail } from "../../../../../validations/shared";
import { Box, Button, Chip, Divider, Grid, MenuItem, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { ICollegeField, ICollege, IErrorResponse, TBasicCollege } from "../../../../../interfaces";
import Select from "../../../../../components/mui/select";
import useSnackbar from "../../../../../hooks/useSnackbar";
import UploadImg from "../../../../../assets/images/upload.png";
import HttpService from "../../../../../services/http";



interface props {
  college: ICollege | undefined;
  isDisable: boolean;
  setIsDisable: (e: boolean) => void;
  onClose: () => void;
}

interface outletProps {
  reFetch: () => void;
  refetchColleges: () => void;
}
const ManageCollege: FC<props> = ({ college, isDisable, setIsDisable, onClose }) => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { httpFormRequest } = HttpService();
  const [imageSelected, setImageSelected] = useState<string | null>(null);
  const { updateCollege } = CollegeService();
  const { handleSubmit, getValues, trigger, resetField, control, setValue, formState: { errors } } = useForm<ICollege>({
    resolver: joiResolver(collegeValidation),
    defaultValues: {
      name: "",
      city: "",
      state: "",
      emailText: "",
      status: "",
      remark: "",
      duration: "",
      helpline: "",
      shortName: "",
      offerRule: "",
      mobileNumber: "",
      linkedInPage: "",
      facebookPage: "",
      category: "tier-1",
      secondaryNumber: "",
      internshipStartMonth: "",
      image: "",
    }
  });

  useEffect(() => {
    if (college) {
      const collegeInfo = college;
      const keys: TBasicCollege[] = ["name", "shortName", "city", "state", "category", "helpline", "mobileNumber", "secondaryNumber",
        "email", "status", "internshipStartMonth", "duration", "linkedInPage", "facebookPage", "offerRule", "remark", "image"];
      keys.forEach(key => {
        switch (key) {
          case "name":
            setValue("name", capitalize(collegeInfo.name));
            break;
          case "shortName":
            setValue("shortName", collegeInfo?.shortName?.toUpperCase());
            break;
          case "city":
            setValue("city", capitalize(collegeInfo?.city));
            break;
          case "state":
            setValue("state", capitalize(collegeInfo?.state));
            break;
          case "category":
            setValue("category", collegeInfo?.category);
            break;
          case "helpline":
            setValue("helpline", collegeInfo?.helpline);
            break;
          case "mobileNumber":
            setValue("mobileNumber", collegeInfo?.mobileNumber);
            break;
          case "secondaryNumber":
            setValue("secondaryNumber", collegeInfo?.secondaryNumber);
            break;
          case "email":
            setValue("email", collegeInfo?.email || []);
            break;
          case "status":
            setValue("status", capitalize(collegeInfo?.status));
            break;
          case "internshipStartMonth":
            setValue("internshipStartMonth", collegeInfo?.internshipStartMonth);
            break;
          case "duration":
            setValue("duration", collegeInfo?.duration);
            break;
          case "linkedInPage":
            setValue("linkedInPage", collegeInfo?.linkedInPage);
            break;
          case "facebookPage":
            setValue("facebookPage", collegeInfo?.facebookPage);
            break;
          case "offerRule":
            setValue("offerRule", collegeInfo?.offerRule);
            break;
          case "remark":
            setValue("remark", capitalize(collegeInfo?.remark));
            break;
          case "image":
            setValue("image",collegeInfo?.image);
            setImageSelected(collegeInfo?.image);
            break;
          default:
            break;
        }
        trigger("email");
      });
    }
  }, [college, isDisable]);

  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];

    if (key === "emailText") {
      const err = validateEmail(getValues(key));
      if (err.error) {
        return;
      }
      if (e.key === "Enter" && key === "emailText") {
        const prev = getValues("email") ? getValues("email") : [];
        const newEmail = getValues("emailText") ? String(getValues("emailText")) : "";
        payload = [...prev, newEmail];

        setValue("email", [...new Set(payload)]);
        resetField(key);
      }
    }
  };

  const removeEmail = (key: string, value: string) => {
    if (key === "emailText") {
      let payload = getValues("email");
      payload = payload.filter(email => email !== value);
      setValue("email", payload);
      trigger("email");
    }
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["png", "jpeg", "jpg"],
          1
        );
        const keyExist = type === "image";
        if (keyExist) {
          setValue(type, uploaded.data);
          setImageSelected(URL.createObjectURL(e.target.files[0]));
          trigger(type);
        }
      }
    }
    catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in candidate detail upload", error);
    }
  };

  const handleEmailCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
      snackbar("Email ID copied to clipboard", "info");
  };

  const onSubmit = async (data: ICollege) => {
    try {
      const payload = { ...data, _id: id };
      delete payload.emailText;
      const update = await updateCollege(id ? id : "", payload);
      snackbar(update.message, "info");
      navigate({
        pathname: "/cpd/college",
        search: searchParam.toString()
      });
      outlet?.reFetch && outlet.reFetch();
      outlet?.refetchColleges && outlet.refetchColleges();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const fields: ICollegeField[] = [
    {
      label: "Image*",
      name: "image",
      type: "upload",
    },
    {
      label: "College Name*",
      name: "name",
      type: "input",
    },
    {
      label: "College Short Name*",
      name: "shortName",
      type: "input",
    },
    {
      label: "City*",
      name: "city",
      type: "input",
    },
    {
      label: "State*",
      name: "state",
      type: "input",
    },
    {
      label: "Category*",
      name: "category",
      type: "select",
      children: [
        <MenuItem key={"none"} value="none" disabled>Category</MenuItem>,
        <MenuItem key={"tier-1"} value="tier-1">Tier-1</MenuItem>,
        <MenuItem key={"tier-2"} value="tier-2">Tier-2</MenuItem>,
        <MenuItem key={"tier-3"} value="tier-3">Tier-3</MenuItem>,
      ]
    },
    {
      label: "Helpline Number",
      name: "helpline",
      type: "input",
    },
    {
      label: "Mobile Number (Primary)",
      name: "mobileNumber",
      type: "input",
    },
    {
      label: "Mobile Number (Secondary)",
      name: "secondaryNumber",
      type: "input",
    },
    {
      label: "Email Address",
      name: "emailText",
      type: "input",
      placeholder: "Type email address and press enter"
    },
    {
      label: "Status",
      name: "status",
      type: "input",
    },
    {
      label: "Internship Start Month",
      name: "internshipStartMonth",
      type: "input",
    },
    {
      label: "Duration",
      name: "duration",
      type: "input",
    },
    {
      label: "LinkedIn",
      name: "linkedInPage",
      type: "input",
    },
    {
      label: "Facebook",
      name: "facebookPage",
      type: "input",
    },
    {
      label: "Offer Rule",
      name: "offerRule",
      type: "input",
    },
    {
      label: "Remark",
      name: "remark",
      type: "input",
    },

  ];

  return (
    <Box paddingTop="10px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      disabled={isDisable}
                      variant={isDisable ? "standard" : "outlined"}
                      size={isDisable ? "medium" : "small"}
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                      onKeyUp={e => addEmail(e, field.name)}
                      onKeyDown={e => e.key === "Enter" && e.preventDefault()}
                    />}
                  />
                  {
                    field.name === "emailText" && Array.isArray(getValues("email")) && getValues("email").map(email => (
                      <Chip
                        key={email}
                        label={email}
                        onClick={() => handleEmailCopyToClipboard(email)}
                        onDelete={() => removeEmail(field.name, email)}
                        disabled={isDisable}
                        color={isDisable ? undefined : "primary"}
                        variant="outlined"
                        sx={{ margin: "5px" }}
                      />
                    ))
                  }

                </Grid>
                );
              } else if (field.type === "upload") {
                return (<Grid key={field.label} item xs={12}>
                  <Box className="center">
                    <Box height="100px" width="100px" className={`upload-img ${ isDisable ? "" : "editable"}`} aria-label="upload picture" component="label">
                      <img src={imageSelected || UploadImg} alt="review" />
                      {!isDisable && <div className="edit-img ">{imageSelected ? "Edit" : "Add"}</div> }
                      <input hidden accept="image/*" type="file" disabled={isDisable} onChange={e => uploadFile(e, field.name)} />
                    </Box>
                  </Box>
                  {errors[field.name] && <span style={{ color: "#d32f2f", marginLeft: "528px", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                </Grid>
                );
              } else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    disabled={isDisable}
                    size={isDisable ? "medium" : "small"}
                    variant={isDisable ? "standard" : "outlined"}
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}

                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
        {
          !isDisable &&
          <>
            <Box className="action-box">
              <Divider sx={{ marginBottom: "20px" }} />
              <Button variant="outlined" onClick={() => setIsDisable(true)}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        }
      </form>
    </Box>
  );
};

export default ManageCollege;