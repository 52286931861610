import Joi from "joi";
import { required } from "../../shared";

export const customUrlValidation = Joi.object({
    slug: Joi.string()
        .trim()
        .min(3)
        .max(100)
        .required()
        .label("Slug")
        .messages({
            "string.empty": required,
        }),
    _product: Joi.string()
        .required()
        .label("Product")
        .messages({
            "string.empty": required,
        }),
    priority: Joi.number()
        .required()
        .label("Priority")
        .messages({
            "string.empty": required,
        }),
    changeFrequency: Joi.string()
        .required()
        .label("Change Frequency")
        .messages({
            "string.empty": required,
        }),
});