import { ChangeEvent, FC, KeyboardEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { cpdDriveService } from "../../../../../services";
import { capitalize } from "../../../../../utilities/helper";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { validateEmail } from "../../../../../validations/shared";
import { replaceHtmlKeyword } from "../../../../../utilities/helper";
import { cpdDriveMailValidation } from "../../../../../validations/cpd/drive/action";
import { ICpdDriveMail, ICpdDriveMailField } from "../../../../../interfaces/cpd/drive/action";
import { ITemplate, IErrorResponse, ICpdDrive } from "../../../../../interfaces";
import { Box, Button, Chip, Divider, FormHelperText, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import HttpService from "../../../../../services/http";
import useUser from "../../../../../hooks/useUser";
import Select from "../../../../../components/mui/select";
import useSnackbar from "../../../../../hooks/useSnackbar";
import CustomTable from "../../../../../components/mui/table";
import GetActions from "../../../../../components/get-actions";
import MESSAGE from "../../../../../assets/json/message.json";
import EmailSuggestion from "../../../../../components/mui/email-suggestion";
import CustomLabel from "../../../../../components/mui/custom-label";
import TinyEditor from "../../../../../components/tiny-editor";

interface props {
    templates: ITemplate[],
    cpdDrive: ICpdDrive | undefined;
    keywords: { [key: string]: string | number }

}

interface IAttachmentRow {
    id: number,
    file_name: string,
    file_size: string,
    action: JSX.Element
}

interface outletProps {
    reFetch: () => void
}

const Mail: FC<props> = ({ templates, keywords, cpdDrive }) => {
    let attachmentRow: IAttachmentRow[] = [];
    const { sendEmail } = cpdDriveService();
    const outlet = useOutletContext<outletProps>();
    const navigate = useNavigate();
    const { user } = useUser();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const { httpFormRequest } = HttpService();
    const { control, getValues, setValue, resetField, trigger, watch, handleSubmit, reset, formState: { errors } } = useForm<ICpdDriveMail>({
        resolver: joiResolver(cpdDriveMailValidation),
        defaultValues: {
            to: "",
            template: "",
            ccText: "",
            bccText: "",
            subject: "",
            content: "",
            attachments: [],
        }
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "template") {
                const template = templates.find(template => template._id === value.template);
                setValue("subject", String(template?.email.subject));
                setValue("content", String(template?.email.content));
                const attachments = (template?.email.attachments || []).map(template => ({
                    name: template.filename,
                    mineType: template.contentType,
                    size: `${(template.sizeInBytes / 1024 / 1024).toFixed(2)} MB`,
                    link: template.path
                }));
                setValue("attachments", attachments);
                trigger("content");
            } else if (name && ["to", "bccText", "ccText"].includes(name)) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (cpdDrive) {
            if (cpdDrive?.collegeId?.email.length > 0) {
                setValue("to", cpdDrive?.collegeId?.email[0]);
                const ccEmails = cpdDrive?.collegeId?.email.slice(1);
                setValue("cc", ccEmails);
            } else {
                setValue("to", "");
                setValue("cc", []);
            }
        }
        if (user) {
            const cc: string[] = getValues("cc") || [];
            cc.push(user?.email);
            setValue("cc", [...new Set(cc)]);
            trigger("cc");
        }
    }, [cpdDrive, user]);


    const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        let payload: string[] = [];

        if (key === "ccText" || key === "bccText") {
            const err = validateEmail(getValues(key));
            if (err.error) {
                return;
            }

            const enteredEmail = getValues(key) ? String(getValues(key)) : "";

            if (enteredEmail.trim() !== "" && e.key === "Enter") {
                if (key === "ccText") {
                    const prev = getValues("cc") ? getValues("cc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("cc", [...new Set(payload)]);
                    resetField(key);
                } else if (key === "bccText") {
                    const prev = getValues("bcc") ? getValues("bcc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("bcc", [...new Set(payload)]);
                    resetField(key);
                }
            }
        }
    };


    const handleEmailSelect = (email: string, name: string) => {
        let payload: string[] = [];
        if (name === "to") {
            setValue("to", email);
        } else if (["ccText", "bccText"].includes(name)) {
            if (name === "ccText") {
                const prev = getValues("cc") ? getValues("cc") : [];
                payload = [...prev, email];
                setValue("cc", [...new Set(payload)]);
                resetField(name);

            } else if (name === "bccText") {
                const prev = getValues("bcc") ? getValues("bcc") : [];
                payload = [...prev, email];
                setValue("bcc", [...new Set(payload)]);
                resetField(name);
            }
        }
    };


    const removeEmail = (key: string, value: string) => {
        if (key === "ccText") {
            let payload = getValues("cc");
            payload = payload.filter(email => email !== value);
            setValue("cc", payload);
            trigger("cc");
        } else if (key === "bccText") {
            let payload = getValues("bcc");
            payload = payload.filter(email => email !== value);
            setValue("bcc", payload);
            trigger("bcc");
        }
    };

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const allowedFileTypes = ["doc", "docx", "pdf", "png", "jpeg", "jpg"];

            if (e.target.files) {
                const uploaded = await httpFormRequest<{ data: string }>(
                    e.target.files,
                    e.target.files[0].name,
                    allowedFileTypes,
                    10
                );
                const attachments = getValues("attachments") || [];
                const fileExits = attachments.find(file => (e.target.files && file.name === (e.target.files[0].name)));
                if (!fileExits) {
                    attachments.push({
                        name: e.target.files[0].name,
                        mineType: e.target.files[0].type,
                        size: `${(e.target.files[0].size / 1024 / 1024).toFixed(2)} MB`,
                        link: uploaded.data.split("uploads")[1]
                    });
                    setValue("attachments", attachments);
                    snackbar(MESSAGE.EN.upload_success, "info");
                } else {
                    snackbar(MESSAGE.EN.upload_exists, "warning");
                }
            }
        } catch (error) {
            console.log("error in candidate detail upload", error);
            snackbar(MESSAGE.EN.upload_failed, "error");
        }
    };

    const removeAttachment = (i: number) => {
        const attachments = getValues("attachments") || [];
        attachments.splice(i, 1);

        setValue("attachments", attachments);
        trigger("attachments");
        snackbar(MESSAGE.EN.upload_remove, "info");
    };



    const onSubmit = async (data: ICpdDriveMail) => {
        const payload = {
            ...data,
            subject: replaceHtmlKeyword(data.subject, keywords),
            content: replaceHtmlKeyword(data.content, keywords)
        };

        const attachments = (getValues("attachments") || []).map(attachment => ({
            filename: attachment.name,
            path: process.env.REACT_APP_S3_BASE_URL + attachment.link,
            contentType: attachment.mineType
        }));

        delete payload.ccText;
        delete payload.bccText;
        delete payload.template;
        delete payload.attachments;

        try {
            const emailSent = await sendEmail({
                type: "cpdDrive_email",
                _cpd: cpdDrive?._id,
                ...payload,
                to: [payload.to],
                attachments
            });
            navigate({
                pathname: "/cpd/drives",
                search: searchParam.toString()
            });
            outlet.reFetch();
            snackbar(emailSent.message, "info");
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "warning");
            console.log(error);
        }
    };

    const fields: ICpdDriveMailField[] = [
        {
            type: "input",
            name: "to",
            label: "To",
            placeholder: "Type email address",
            required: true

        },
        {
            type: "select",
            name: "template",
            label: "Template",
            children: templates.map(template => <MenuItem key={template._id} value={template._id}>{capitalize(template.title)}</MenuItem>)
        },
        {
            type: "input",
            name: "ccText",
            label: "CC",
            placeholder: "Type email address and press enter"
        },
        {
            type: "input",
            name: "bccText",
            label: "BCC",
            placeholder: "Type email address and press enter"
        },
        {
            type: "input",
            name: "subject",
            label: "Subject",
            placeholder: "Type subject here",
            width: 12,
            required: true
        },
        {
            type: "text-editor",
            name: "content",
            label: "Content*"
        },
    ];


    const attachmentColumns = [
        {
            id: "id",
            label: "S No."
        },
        {
            id: "file_name",
            label: "File Name"
        },
        {
            id: "file_size",
            label: "File Type"
        },
        {
            id: "action",
            label: "Actions"
        }
    ];

    const attachments = getValues("attachments") || [];
    if (attachments.length) {
        attachmentRow = attachments.map((attachment, i) => {
            const action = <GetActions
                icons={
                    [
                        { name: "Preview", method: () => window.open(`${process.env.REACT_APP_S3_BASE_URL}${attachment.link}`, "_blank") },
                        { name: "Delete", method: () => removeAttachment(i) }
                    ]
                }
            />;

            return {
                id: i + 1,
                file_name: attachment.name.length > 50 ?
                    attachment.name.substring(0, 50) + "..."
                    : attachment.name,
                file_size: attachment.size,
                action
            };
        });
    }
    const onCopy = (url:string,label:string) => {
        navigator.clipboard.writeText(url);
        snackbar(`${capitalize(label)} ID copied to clipboard`, "info");
    };

    return (
        <Box paddingTop="10px">
            <Box sx={{ height: "44vh", overflow: "auto", padding: "10px 4px 0px" }}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => {
                        const isInputField = (e.target as HTMLElement).tagName === "INPUT";
                        if (isInputField && e.key === "Enter") e.preventDefault();
                    }}
                >
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "input" && field.name === "to" || field.name === "ccText" || field.name === "bccText") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <EmailSuggestion
                                            control={control}
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            name={field.name}
                                            value={getValues(field.name)}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            onEmailSelect={handleEmailSelect}
                                            onKeyUp={addEmail}
                                        />
                                        {
                                            <Box>
                                                {
                                                    field.name === "ccText" && getValues("cc") &&
                                                    getValues("cc").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(field.name, email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                        onClick = {() => onCopy(email,"email")}
                                                    />)
                                                    ||
                                                    field.name === "bccText" && getValues("bcc") &&
                                                    getValues("bcc").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(field.name, email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                        onClick = {() => onCopy(email,"email")}
                                                    />)
                                                }
                                            </Box>
                                        }
                                    </Grid>
                                    );
                                }
                                else if (field.type === "input") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={<CustomLabel label={field.label} required={field?.required} />}
                                                className="disable-text"
                                                variant={"outlined"}
                                                size={"small"}
                                                placeholder={field.placeholder}
                                                error={errors[field.name] ? true : false}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}

                                            />}
                                        />
                                    </Grid>
                                    );
                                }
                                else if (field.type === "text-editor") {
                                    return (<Grid key={field.label} item xs={12}>
                                        <Box className={errors[field.name] ? "tiny-error" : ""}>
                                            <TinyEditor value={getValues("content")} onChange={(e: string) => setValue("content", e)} height="375px"   type = {["CPD"]} />
                                            {errors[field.name] && <FormHelperText sx={{ margin: "4px 14px 0px 14px" }} error={errors[field.name] ? true : false}>{errors[field.name]?.message}</FormHelperText>}
                                        </Box>
                                    </Grid>
                                    );
                                }
                                else {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <Select
                                            control={control}
                                            className="disable-text"
                                            name={field.name}
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            size={"small"}
                                            variant={"outlined"}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                        >
                                            {field.children}
                                        </Select>
                                    </Grid>
                                    );
                                }
                            })
                        }

                        <Grid item xs={12} md={6}>
                            <InputLabel id="upload">
                                <Button component="label" fullWidth>
                                    Upload Attachment
                                    <input
                                        hidden
                                        type="file"
                                        id="upload"
                                        onChange={uploadFile}
                                        accept="application/pdf, image/png, image/jpeg, .doc, .docx"
                                    />
                                </Button>
                            </InputLabel>
                        </Grid>

                        {
                            attachments.length ? <Grid item xs={12}>
                                <CustomTable
                                    columns={attachmentColumns}
                                    rows={attachmentRow}
                                />
                            </Grid>
                                :
                                null
                        }
                    </Grid>


                    <Box className="action-box">
                        <Divider sx={{ marginBottom: "16px" }} />
                        <Button variant="outlined" onClick={() => reset()}>clear</Button>
                        <Button type="submit">Send</Button>
                    </Box>
                </form>
            </Box>
        </Box >
    );
};

export default Mail;