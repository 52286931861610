import { Box, Grid, Typography } from "@mui/material";
import CustomTable from "../../../../components/mui/table";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { capitalize } from "../../../../utilities/helper";
import { IColumn } from "../../../../interfaces";
import { AlternativeComparisonService } from "../../../../services/alternative-comparison";
import { IBlogSummary, IBlogSummaryRow } from "../../../../interfaces/content-marketing/blog/score";
import CustomTypography from "../../../../components/mui/max-length-limit";

export const Summary = () => {
  const { id } = useParams();
  const { getComparisonAlternativeSummary } = AlternativeComparisonService();

  const getSumarry = useQuery({
    queryKey: ["comparison-alternative-summary"],
    queryFn: () => getComparisonAlternativeSummary({ _alternativeComparison: id }),
    enabled: id !== "new"
  });

  const getSumarryData = (getSumarry && getSumarry.data && getSumarry.data.data);
  const createRows = (data: IBlogSummary) => ({
    name: capitalize(data?.name),
    content:
      ["Meta Description", "Audience", "Idea of the Blog", "Suggested sub-heading", "Data"].includes(data?.name) ? (
        <CustomTypography
          limit={200}
          label={data?.value ?? ""}
        />
      ) : (
        data?.value
      )
  });
  let rows: IBlogSummaryRow[] = [];
  if (getSumarryData) {
      rows = getSumarryData?.map(
          (data) =>
              createRows(data)
      );
  }

  const columns: IColumn[] = [
    {
        id: "name",
        label: "Name",
        minWidth:310
    },
    {
        id: "content",
        label: "Content",
    }
];

  return (
    <div className="blog-summary">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography className="title" variant="body2">Comparison Summary</Typography>
                    <Box marginTop="10px">
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            height="calc(100vh - 305px)"
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
  );
};