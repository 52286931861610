import { Typography } from "@mui/material";
import { FC } from "react";

interface IProps {
    name: string;
    count: number;
}

const Tag: FC<IProps> = ({ name, count }) => (
    <div
        className={"self-evaluation-tag"}
    >
        <Typography className="cards-title" variant="body1">{name}</Typography>
        <Typography className="analytics" variant="h4">
            {`${count}` || 0}
        </Typography>
    </div>
);

export default Tag;
