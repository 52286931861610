import { IPmsParameterResponse, IPmsParametersResponse } from "../../../interfaces/pms/pms-parameter";
import { pmsParameter } from "../../endpoints";
import HttpService from "../../http";


const PmsParametersService = () => {
    const { httpRequest } = HttpService();

    const getPmsParameters = async (search: object): Promise<IPmsParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IPmsParametersResponse>(
            "GET",
            `${pmsParameter}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getPmsParameter = async (search: object): Promise<IPmsParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IPmsParameterResponse>(
            "GET",
            `${pmsParameter}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addPmsParameter = async (payload: object): Promise<IPmsParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IPmsParameterResponse>(
            "POST",
            `${pmsParameter}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updatePmsParameter = async (payload: object): Promise<IPmsParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IPmsParameterResponse>(
            "PUT",
            `${pmsParameter}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getPmsParameters, updatePmsParameter, getPmsParameter, addPmsParameter };
};

export { PmsParametersService };