import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  debounce,
  Typography,
} from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomLabel from "../../../../components/mui/custom-label";
import { IErrorResponse } from "../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import {
  useCallback,
  useEffect,
  useState,
} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { OrganisationSoftwaresService } from "../../../../services/organisation";
import { joiResolver } from "@hookform/resolvers/joi";
import { organisationSoftwaresValidation } from "../../../../validations/organisation/softwares";
import CKeditor from "../../../../components/ck-editor";
import { ClassicEditor, EventInfo } from "ckeditor5";
import { IOrganisationSoftwares } from "../../../../interfaces/organisationConfiguration/softwares";

const OrganisationSoftwares = () => {
  const { snackbar } = useSnackbar();
  const [editorContents, setEditorContents] = useState<{ [key: number]: string }>({});
  const { updateOrganisationSoftwares, getOrganisationSortwares } =
    OrganisationSoftwaresService();
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IOrganisationSoftwares>({
    resolver: joiResolver(organisationSoftwaresValidation),
    defaultValues: {
      softwareFields: [
        {
          label: "Code Management",
          description: "",
          name: "",
          url: ""
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "softwareFields",
  });

  const softwaresUsed = useQuery({
    queryKey: ["org-softwares-used"],
    queryFn: () => getOrganisationSortwares(),
  });

  useEffect(() => {
    if (softwaresUsed?.data?.data) {
      const data = softwaresUsed?.data?.data;
      setValue("softwareFields", data);
    }
  }, [softwaresUsed?.data?.data]);

  const onSubmit = async (formData: IOrganisationSoftwares) => {
    try {
      const update = await updateOrganisationSoftwares({
        softwaresUsed: formData.softwareFields,
      });
      snackbar(update.message, "info");
      softwaresUsed.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const handleContentChange = useCallback(
    (index: number, editor: ClassicEditor) => {
      const data = editor.getData();
      setEditorContents(prev => ({ ...prev, [index]: data }));
      setValue(`softwareFields.${index}.description`, data);
      trigger(`softwareFields.${index}.description`);
    },
    [setValue]
  );

  const createDebouncedHandler = useCallback(
    (index: number) =>
      debounce((editor: ClassicEditor) => {
        handleContentChange(index, editor);
      }, 300),
    [handleContentChange]
  );

  const onEditorChange = (index?: number) => (_: EventInfo<string>, editor: ClassicEditor) => {
    if (index !== undefined) {
      createDebouncedHandler(index)(editor);
    }
  };

  useEffect(() => {
    const newEditorContents : { [key: number]: string } = {};
    fields.forEach((field, index) => {
      if (field.description) {
        newEditorContents[index] = field.description;
      }
    });
    setEditorContents(newEditorContents);
  }, [fields]);

  const addNewField = () => {
    append({
      label: "",
      description: "",
      name: "",
      url: ""
    });
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Grid container spacing={4}>
        {fields.map((field, index) => (
          <Grid key={field.id} item xs={12} md={6}>
            <Box
              p={2}
              border={1}
              borderRadius={2}
              borderColor="grey.300"
              position="relative"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                mb="2"
              >
                {fields.length > 1 && (
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => {
                      setEditorContents(prev => {
                        const updated = { ...prev };
                        delete updated[index];
                        Object.keys(updated).forEach(key => {
                          const keyNum = parseInt(key);
                          if (keyNum > index) {
                            updated[keyNum - 1] = updated[keyNum];
                            delete updated[keyNum];
                          }
                        });
                        return updated;
                      });
                      remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>

              <Controller
                control={control}
                name={`softwareFields.${index}.label`}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label={<CustomLabel label="Software Type" required />}
                    placeholder="Enter Software Type"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.softwareFields?.[index]?.label}
                    helperText={errors.softwareFields?.[index]?.label?.message}
                    onChange={onChange}
                    value={value}
                    className="mb-3"
                  />
                )}
              />

              <Typography>
                Description
              </Typography>

              <Controller
                control={control}
                name={`softwareFields.${index}.description`}
                render={() => (
                  <div className="editor-container mr-2 mb-4 mt-1">
                    <CKeditor
                      editorContent={editorContents[index] || ""}
                      onEditorChange={onEditorChange(index)}
                      showDynamicKeywords={false}
                    />
                    {errors.softwareFields?.[index]?.description && (
                      <Typography color="error" variant="body2">
                        {errors.softwareFields?.[index]?.description?.message}
                      </Typography>
                    )}
                  </div>
                )}
              />

              <Controller
                control={control}
                name={`softwareFields.${index}.name`}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label={<CustomLabel label="Software Name" required />}
                    placeholder="Enter Software Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.softwareFields?.[index]?.name}
                    helperText={errors.softwareFields?.[index]?.name?.message}
                    onChange={onChange}
                    value={value}
                    className="mb-3"
                  />
                )}
              />

              <Controller
                control={control}
                name={`softwareFields.${index}.url`}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label={<CustomLabel label="URL" required />}
                    placeholder="Enter Software URL"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.softwareFields?.[index]?.url}
                    helperText={errors.softwareFields?.[index]?.url?.message}
                    onChange={onChange}
                    value={value}
                    className="mb-3"
                  />
                )}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="outlined"
          color="primary"
          className="m-2"
          startIcon={<AddIcon />}
          onClick={addNewField}
      >
        Add New Software
      </Button>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="m-2"
      >
        Save Configuration
      </Button>
    </Box>
    </Box>
  );
};

export default OrganisationSoftwares;
