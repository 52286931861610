import "./style.scss";
import { Button, capitalize, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IBlogAssignment, IErrorResponse } from "../../../../../interfaces";
import { blogAssignmentValidation } from "../../../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
import Select from "../../../../../components/mui/select";
import DateTime from "../../../../../components/mui/date-time";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { blogAssignmentService } from "../../../../../services/blog";

import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { displayName } from "../../../../../utilities/helper";
import useResource from "../../../../../hooks/useResource";
import { UsersService } from "../../../../../services";
import { CMS_ERROR_MESSAGES } from "../../../../../utilities/messages";
import CustomLabel from "../../../../../components/mui/custom-label";
interface props {
  activeAction: boolean;
  setActiveAction: (value: boolean) => void;
}

const Assignments: FC<props> = () => {

  const [searchParams] = useSearchParams();

  const { snackbar } = useSnackbar();
  const { updateBlogAssignment, getBlogAssignment } = blogAssignmentService();
  const { getRoleBasedUser } = UsersService();
  const writerList = useQuery({
    queryKey: ["writer-list"],
    queryFn: () => getRoleBasedUser({ role: ["writer", "reviewer", "content admin"] }),
  });

  const reviewerList = useQuery({
    queryKey: ["reviewer-list"],
    queryFn: () => getRoleBasedUser({ role: ["reviewer", "content admin"] }),
  });

  const designerList = useQuery({
    queryKey: ["illustrator-list"],
    queryFn: () => getRoleBasedUser({ role: "illustrator" }),
  });

  const publisherList = useQuery({
    queryKey: ["publisher-list"],
    queryFn: () => getRoleBasedUser({ role: "publisher" }),
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const blogAssignment = useQuery({
    queryKey: ["blogs-assignment", id],
    queryFn: () => getBlogAssignment({ blogId: id }),
  });
  const { resourceAllocate } = useResource();
  useEffect(() => {
    if (blogAssignment?.data?.data && id !== "new") {
      setValue("writer.userId", blogAssignment?.data?.data?.writer?.userId?._id || "");
      setValue("writer.submissionDate", blogAssignment?.data?.data?.writer?.submissionDate || dayjs().startOf("day").toISOString());
      trigger("writer.submissionDate");
      setValue("writer.notes", blogAssignment?.data?.data?.writer?.notes || "");
      setValue("reviewer.userId", blogAssignment?.data?.data?.reviewer?.userId?._id || "");
      setValue("reviewer.submissionDate", blogAssignment?.data?.data?.reviewer?.submissionDate || dayjs().add(2, "days").startOf("day").toISOString());
      trigger("reviewer.submissionDate");
      setValue("reviewer.notes", blogAssignment?.data?.data?.reviewer?.notes || "");
      setValue("illustrator.userId", blogAssignment?.data?.data?.illustrator?.userId?._id || "");
      setValue("illustrator.submissionDate", blogAssignment?.data?.data?.illustrator?.submissionDate || dayjs().add(4, "days").startOf("day").toISOString());
      trigger("illustrator.submissionDate");
      setValue("illustrator.notes", blogAssignment?.data?.data?.illustrator?.notes || "");
      setValue("publisher.notes", blogAssignment?.data?.data?.publisher?.notes || "");
      setValue("publisher.userId", blogAssignment?.data?.data?.publisher?.userId?._id || "");
      setValue("publisher.submissionDate", blogAssignment?.data?.data?.publisher?.submissionDate || dayjs().add(6, "days").startOf("day").toISOString());
      trigger("publisher.submissionDate");
    }
  }, [blogAssignment?.data?.data]);


  const { handleSubmit, control, getValues, setValue, reset, trigger, formState: { errors } } = useForm<IBlogAssignment>({
    resolver: joiResolver(blogAssignmentValidation),
    defaultValues: {
      writer: {
        userId: "",
        submissionDate: dayjs().startOf("day").toISOString(),
        notes: ""
      },
      reviewer: {
        userId: "",
        submissionDate: dayjs().add(3, "days").startOf("day").toISOString(),
        notes: ""

      },
      illustrator: {
        userId: "",
        submissionDate: dayjs().add(6, "days").startOf("day").toISOString(),
        notes: ""
      },
      publisher: {
        userId: "",
        submissionDate: dayjs().add(9, "days").startOf("day").toISOString(),
        notes: ""
      }
    }
  });

  const changeDate = (e: dayjs.Dayjs | null, name: string) => {
    const newDate = e ? dayjs(e).startOf("day").toISOString() : "";

    if (name === "writer.submissionDate") {
      setValue(name, newDate);
    }
    else if (name === "reviewer.submissionDate") {
      setValue(name, newDate);
    }
    else if (name === "illustrator.submissionDate") {
      setValue(name, newDate);
    }
    else if (name === "publisher.submissionDate") {
      setValue(name, newDate);
    }

  };

  const onSubmit = async (data: IBlogAssignment) => {
    try {
      if (id === "new") {
        snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
      } else {
        const response = await updateBlogAssignment({ ...data, _id: id, blogId: id });
        snackbar(response.message, "info");
        navigate({
          pathname: "/blog",
          search: searchParams.toString()
        });
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };


  return (
    <div id="assignment">

      <Grid container spacing={2}>
        <Grid item xs={12}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Writer details  */}
              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Writer</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="writer.userId"
                        label={<CustomLabel label="Writer" required/>}
                        size={"small"}
                        variant={"outlined"}
                        error={errors["writer"]?.userId ? true : false}
                        helperText={errors["writer"]?.userId?.message}
                      >
                        {(writerList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Submission Date" required/>}
                        name="writer.submissionDate"
                        changeDate={changeDate}
                        value={getValues("writer.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="writer.notes"
                        render={(prop) => <TextField
                          label="Note for Writers"
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["writer"] && errors["writer"]["notes"] ? true : false}
                          helperText={errors["writer"] && errors["writer"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              {/* Reviewer detail  */}
              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Reviewer</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="reviewer.userId"
                        label={<CustomLabel label="Reviewer" required/>}
                        size={"small"}
                        variant={"outlined"}
                        error={errors["reviewer"]?.userId ? true : false}
                        helperText={errors["reviewer"]?.userId?.message}
                      >
                        {(reviewerList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Reviewer Date" required/>}
                        name="reviewer.submissionDate"
                        changeDate={changeDate}
                        value={getValues("reviewer.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="reviewer.notes"
                        render={(prop) => <TextField
                          label="Note for Reviewers"
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["reviewer"] && errors["reviewer"]["notes"] ? true : false}
                          helperText={errors["reviewer"] && errors["reviewer"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* Designer detail  */}
              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Designer</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="illustrator.userId"
                        label={<CustomLabel label="Designer" required/>}
                        size={"small"}
                        variant={"outlined"}
                        error={errors["illustrator"]?.userId ? true : false}
                        helperText={errors["illustrator"]?.userId?.message}
                      >
                        {(designerList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Designer Date" required/>}
                        name="illustrator.submissionDate"
                        changeDate={changeDate}
                        value={getValues("illustrator.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="illustrator.notes"
                        render={(prop) => <TextField
                          label="Note for Designers"
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["illustrator"] && errors["illustrator"]["notes"] ? true : false}
                          helperText={errors["illustrator"] && errors["illustrator"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* Publisher detail  */}
              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Publisher</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="publisher.userId"
                        label={<CustomLabel label="Publisher" required/>}
                        size={"small"}
                        variant={"outlined"}
                        error={errors["publisher"]?.userId ? true : false}
                        helperText={errors["publisher"]?.userId?.message}
                      >
                        {(publisherList?.data?.data || [])?.map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Publisher Date" required/>}
                        name="publisher.submissionDate"
                        changeDate={changeDate}
                        value={getValues("publisher.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="publisher.notes"
                        render={(prop) => <TextField
                          label="Note for Publishers"
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["publisher"] && errors["publisher"]["notes"] ? true : false}
                          helperText={errors["publisher"] && errors["publisher"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <div className="mt-4 save-box">
              <Button
                variant="outlined"
                onClick={() => reset()}
                disabled={!resourceAllocate("cms-assignment.write")}
              >Discard</Button>
              <Button
                type="submit"
                className="ml-2"
                disabled={!resourceAllocate("cms-assignment.write")}
              >Save Changes</Button>
            </div>
          </form>

        </Grid>
      </Grid>
      <Outlet />
    </div>
  );
};

export default Assignments;