import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IErrorResponse, IUser } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomDialog from "../../../components/mui/dialog";
import { skillDevelopmentServices } from "../../../services/skill-development";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomLabel from "../../../components/mui/custom-label";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import SearchSelect from "../../../components/mui/search-select";
import { IOutletProps, ISkillDevelopmentField, ISkillDevelopmentFields } from "../../../interfaces/skill-development";
import { SkillDevelopmentValidation } from "../../../validations/skill-development";
import { capitalize } from "../../../utilities/helper";
import Select from "../../../components/mui/select";
import { courseConfigurationService } from "../../../services/configuration/course";

const ManageSkillDevelopment: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<IOutletProps>();
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
    const { snackbar } = useSnackbar();
    const navigate = useNavigate();
    const { addSkillDevelopment, updateSkillDevelopment, getSkillDevelopment } = skillDevelopmentServices();
    const { getPartialcourseConfiguration } = courseConfigurationService();
    const hitQuery = !(id === "new" || id === "view");
    
    const skillDevelopmentList = useQuery({
        queryKey: [hitQuery],
        queryFn: () => getSkillDevelopment({_id: id}),
        enabled: hitQuery,
    });

    const getCoursesPartial = useQuery({
      queryKey: ["get-course-partial"],
      queryFn: () => getPartialcourseConfiguration()
    });
  
    const coursesPartial = getCoursesPartial.data?.data;

    const { handleSubmit, control, setValue, getValues, formState: { errors }, trigger } = useForm<ISkillDevelopmentField>({
        resolver: joiResolver(SkillDevelopmentValidation),
        defaultValues: {
            "assignTo": "",
            "_mentor": "",
            "course": "",
            "startDate": "",
            "endDate": "",
            "completionDate": "",
            "mentorEfficiency": "",
            "allocatedBy": "",
        }
    });

    useEffect(() => {
        if (id !== "new") {
            if (skillDevelopmentList.data?.data) {
                setValue("assignTo", skillDevelopmentList.data.data.assignTo._id || "");
                trigger("assignTo");
                setValue("_mentor", skillDevelopmentList.data.data._mentor._id || "");
                trigger("_mentor");
                setValue("allocatedBy", skillDevelopmentList.data.data.allocatedBy._id || "");
                trigger("allocatedBy");
                setValue("course", skillDevelopmentList.data.data.course._id || "");
                trigger("course");
                setValue("mentorEfficiency", skillDevelopmentList.data.data.mentorEfficiency || "");
                setValue("completionDate", skillDevelopmentList.data.data.completionDate || "");
                setValue("startDate", skillDevelopmentList.data.data.startDate || "");
                setValue("endDate", skillDevelopmentList.data.data.endDate || "");
            }
        }
    }, [id, skillDevelopmentList.data, setValue]);    

    const onSubmit = async (data: ISkillDevelopmentField) => {
        try {
            const payload = {...data, status: "ASSIGNED"};
            if (id === "new") {
                const add = await addSkillDevelopment(payload);
                snackbar(add.message, "info");
            } else {
                payload["_id"] = id || "";
                const update = await updateSkillDevelopment(payload);
                snackbar(update.message, "info");
            }

            navigate({
                pathname: "/skill-development/assigned-course-list", 
                search: searchParam.toString(),
            });

            outlet?.reFetch && outlet.reFetch();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/skill-development/assigned-course-list",
            search: searchParam.toString(),
        });
    };

    const fields: ISkillDevelopmentFields[] = [
      {
        label: "User Name",
        name: "assignTo",
        type: "multiSelect",
        placeholder: "Enter user name",
        options: [
          ...users.filter((user) => user._id != getValues("_mentor") )
            .map((user) => ({ key: user._id, value: capitalize(user.name) })),
        ],
        displayFieldKey: "value",
        storeFieldKey: "key",
        required: true,
        error: "type",
      },
      {
        label: "Mentor Name",
        name: "_mentor",
        type: "multiSelect",
        placeholder: "Enter Mentor Name",
        options: [
          ...users.filter((user)=>user._id != getValues("assignTo"))
          .map((user) => ({ key: user._id, value: capitalize(user.name) }))
        ],
        displayFieldKey: "value",
        storeFieldKey: "key",
        required: true,
        error: "type",
      },      
      {
        label: "Allocated By",
        name: "allocatedBy",
        type: "multiSelect",
        placeholder: "Enter Allocated By",
        options: [
          ...users.filter((user)=>user._id != getValues("assignTo"))
          .map((user) => ({ key: user._id, value: capitalize(user.name) }))
        ],
        displayFieldKey: "value",
        storeFieldKey: "key",
        required: true,
        error: "type",
      },   
      {
        label: "Course",
        name: "course",
        type: "multiSelect",
        placeholder: "Enter Course",
        options: coursesPartial,
        displayFieldKey: "name",
        storeFieldKey: "_id",
        required: true,
        error: "type",
      },
      {
        label: "Course start date",
        name: "startDate",
        type: "date",
        required: true
      },
      {
        label: "Course due date",
        name: "endDate",
        type: "date",
        required: true
      },
      {
        label: "Completion Date",
        name: "completionDate",
        type: "date",
      },
      {
        label: "Mentor Efficiency",
        name: "mentorEfficiency",
        type: "input",
        placeholder: "Type your mentor efficiency here",
      }
    ];
                                
    const selectDate = (value: Dayjs|null, name: string) => {
      const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
      const keyExist = name === "startDate" || name === "endDate" || name === "completionDate";
      const startDate = dayjs(getValues("startDate"));
      if (keyExist) {
        if(name === "endDate") {
          const newDate = startDate.add(12, "month").toISOString();
          setValue(name, newDate);
          trigger(name);
        }
          setValue(name, date);
          trigger(name);
      }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, field: ISkillDevelopmentFields) => {
    const inputValue = event.target as HTMLInputElement;
    const maxLength = field.name === "score" ? 3 : 100;
    const isLengthValid = inputValue.value.length < maxLength;
    const isNumeric = /^[0-9]*$/.test(inputValue.value + event.key);
  
    if (
      event.key === "Enter" ||
      (field.name === "score" && !isNumeric && !["Backspace", "ArrowLeft", "ArrowRight"].includes(event.key)) ||
      (event.key === " " && inputValue.value.length === 0) ||
      (!isLengthValid && !["Backspace", "ArrowLeft", "ArrowRight"].includes(event.key))
    ) {
      event.preventDefault();
    }
  };

    return (
      <Box>
        <CustomDialog
          size="md"
          title={id !== "new" ? "Edit Skill Development and Training" : "Add Skill Development and Training"}
          isOpen={!!id}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={4}>
            {fields.map((field) => {
              if (field.type === "input") {
                return (
                  <Grid key={field.label} item xs={6} py={2}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label={<CustomLabel label={field.label} required={field.required} />}
                          multiline 
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder={field.placeholder}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                          onChange={onChange}
                          value={value}
                          onKeyDown={(event) => handleKeyDown(event, field)}
                          minRows={5}
                        />
                      )}
                    />
                  </Grid>
                );
              } else if (field.type === "date") {
                return (
                  <Grid key={field.label} item xs={12} md={6} mb={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        onChange={(e) => selectDate(e, field.name)}
                        value={dayjs(getValues(field.name))}
                        slotProps={{
                          textField: {
                            error: !!errors[field.name],
                            helperText: errors[field.name]?.message
                          },
                        }}
                        format="LL"
                      />
                    </LocalizationProvider>
                  </Grid>
                );
              } else if (field.type === "multiSelect") {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <SearchSelect
                      name={field.name}
                      size={"small"}
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field?.required}
                        />
                      }
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      options={field.options}
                      displayFieldKey={
                        field.displayFieldKey ? field.displayFieldKey : ""
                      }
                      storeFieldKey={
                        field.storeFieldKey ? field.storeFieldKey : ""
                      }
                      displayUserName={field.displayUserName}
                      capitalize={field.capitalize}
                      trigger={trigger}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Grid>
                );
              } else if (field.type === "select") {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Select
                      control={control}
                      name={field.name}
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field?.required}
                        />
                      }
                      size="small"
                      variant="outlined"
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                    >
                      {field.children}
                    </Select>
                  </Grid>
                );
              } 
            })} 
          </Grid>
        </CustomDialog>
      </Box>
    );
};

export default ManageSkillDevelopment;