import { Box, Tooltip, IconButton, Checkbox, capitalize } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import ImageViewer from "react-simple-image-viewer";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { createIndex } from "../../../utilities/helper";
import { IErrorResponse, IPagination } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import { AssessmentMediaService } from "../../../services/configuration/assessment-media";
import {
  IAssessmentMedia,
  IAssessmentMediaRow,
  IAssessmentMediaState,
  IImageViewer,
} from "../../../interfaces/configuration/assessment-media";

interface outletProps {
  refetchTests: () => void;
}
const AssessmentMedia = () => {
  let rows: IAssessmentMediaRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAssessmentMediaList, deleteAssessmentMedia } =
    AssessmentMediaService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IAssessmentMediaState>({
    deleteWarning: false,
    multiDeleteWarning: false,
    selectAll: [],
    _media: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
    },
  });

  const assessmentImages = useQuery({
    queryKey: ["all-assessment-images", state.pagination.page, state.filters],
    queryFn: () =>
      getAssessmentMediaList({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    searchRecord.length
      ? setSearchParams((prev) => ({
          ...prev,
          page: 1,
          search: searchRecord,
        }))
      : (searchParams.delete("search"), setSearchParams(searchParams));
  }, [searchRecord]);

  const handleSelect = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = state.selectAll;
      payload.push(id);
    } else {
      payload = state.selectAll.filter((ele) => ele !== id);
    }

    setState((prevState) => ({
      ...prevState,
      selectAll: payload,
    }));
  };

  useEffect(() => {
    assessmentImages.data &&
      assessmentImages.data.meta &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: assessmentImages.data.meta.page,
          totalPages: assessmentImages.data.meta.totalPages,
          totalRecords: assessmentImages.data.meta.totalRecords,
        },
      }));

      const images: string[] = [];
      assessmentImages.data?.data.map(ele => {
        images.push(process.env.REACT_APP_S3_BASE_URL + ele.imageUrl);
      });

      setImageViewer(prev => ({
        ...prev,
        images
      }));
    
  }, [
    assessmentImages.data &&
      assessmentImages.data.meta,
  ]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_media = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _media,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteAssessmentMedia({ ids: [state._media] });
      snackbar(deleted.message, "info");
      handleDelete();
      assessmentImages.refetch();
      outlet.refetchTests && outlet.refetchTests();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  const handleMultiDelete = () =>
    setState((prevState) => ({
      ...prevState,
      multiDeleteWarning: !prevState.multiDeleteWarning,
    }));

  const onMultiDelete = async () => {
    try {
      const faq = await deleteAssessmentMedia({ ids: state.selectAll });
      snackbar(faq.message, "info");
      assessmentImages.refetch();
      setState((prevState) => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: [],
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
    }
  };
  const createColumn = (
    handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void,
    isChecked: boolean,
    isIndeterminateChecked: boolean
  ) => [
    {
      id: "all",
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={isChecked}
          indeterminate={isIndeterminateChecked}
        />
      ),
    },
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "imageUrl",
      label: "Image",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      if (assessmentImages.data?.data.length) {
        payload = assessmentImages.data?.data.map((faq) => faq._id);
      }
    } else {
      payload = [];
    }

    setState((prevState) => ({
      ...prevState,
      selectAll: payload,
    }));
  };

  const isChecked =
    assessmentImages.data?.data?.length &&
    state.selectAll?.length === assessmentImages.data?.data?.length
      ? true
      : false;
  const isIndeterminateChecked =
    state.selectAll.length > 0 &&
    state.selectAll.length < Number(assessmentImages.data?.data.length)
      ? true
      : false;
  const columns = createColumn(
    handleSelectAll,
    isChecked,
    isIndeterminateChecked
  );

  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });

  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };
  const createRow = (
    index: number,
    assessmentImages: IAssessmentMedia,
    pagination: IPagination,
    selectedAll: string[]
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + assessmentImages._id,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("assessment-media.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(assessmentImages._id),
            disabled: !resourceAllocate("assessment-media.remove"),
          },
        ]}
      />
    );

    const description = (
      <Tooltip title={assessmentImages.description || ""}>
        <span>
          <Tooltip title={assessmentImages.description ?? ""}>
            <span>
              {assessmentImages.description &&
              assessmentImages.description.toString().length > 100
                ? `${assessmentImages.description.toString().slice(0, 100)}...`
                : assessmentImages.description}
            </span>
          </Tooltip>
        </span>
      </Tooltip>
    );

    return {
      all: (
        <Checkbox
          onChange={(e) => handleSelect(e, assessmentImages._id)}
          checked={selectedAll.includes(assessmentImages._id)}
        />
      ),
      id: createIndex(pagination, index),
      name: capitalize(assessmentImages.name),
      description,
      imageUrl: (
        <IconButton onClick={() => handleImageViewer(index)}>
          <img className="thumbnail" src={`${process.env.REACT_APP_S3_BASE_URL}${assessmentImages?.imageUrl}`} alt="" />
        </IconButton>
      ),
      action,
    };
  };

  if (
    assessmentImages.data &&
    assessmentImages.data.data &&
    assessmentImages.data.data.length
  ) {
    rows = (
      (assessmentImages.data &&
        assessmentImages.data.data &&
        assessmentImages.data.data) ||
      []
    ).map((data, i) => createRow(i, data, state.pagination, state.selectAll));
  }

  return (
    <>
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="ADD NEW ASSESSMENT IMAGE"
        onDelete={handleMultiDelete}
        isDeleteDisable={!state.selectAll.length}
        onBtnClick={
          resourceAllocate("assessment-media.write")
            ? () => navigate({
              pathname: "new",
              search: searchParams.toString(),
            })
            : undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Assessment Image"
        description="Are you sure you want to delete this assessment image?"
      />

      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All Selected Assessment Images"
        description={`Are you sure you want to delete ${state.selectAll.length} selected assessment image${state.selectAll.length > 1 ? "s" : ""}?`}
      />

      {imageViewer.open && (
        <>
          <ImageViewer
            src={imageViewer.images}
            currentIndex={imageViewer.index}
            disableScroll={true}
            closeOnClickOutside={false}
            onClose={handleImageViewer}
            backgroundStyle={{
              zIndex: 5,
            }}
          />
        </>
      )}
      <Outlet context={{ ...outlet, reFetch: assessmentImages.refetch }} />
    </>
  );
};
export default AssessmentMedia;
