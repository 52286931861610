import { Controller, useForm } from "react-hook-form";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import ScoreDataService from "../../../../services/content/score-data";
import useSnackbar from "../../../../hooks/useSnackbar";
import useResource from "../../../../hooks/useResource";
import { capitalize } from "../../../../utilities/helper";
import { IScoreData, IScoreDataForm, IScoreState } from "../../../../interfaces/content/score-data";
import { IColumn, IErrorResponse } from "../../../../interfaces";
import ErrorMessage from "../../../../components/shared/error-message";
import { AlternativeScoreService } from "../../../../services/alternative-comparison/score";
import { IAlternativeKeywordDensity, IAlternativeKeywordDensityRow, IAlternativeScoreFields } from "../../../../interfaces/alternative-comparison/score";
import { CMS_ERROR_MESSAGES } from "../../../../utilities/messages";
import CustomLabel from "../../../../components/mui/custom-label";
import CustomTable from "../../../../components/mui/table";

const Score = () => {
    const { getContentsScoreDatas } = ScoreDataService();
    const { addAlternativeScore, getAlternativeScore, getAlternativeKeywordDensity } = AlternativeScoreService();
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const { resourceAllocate } = useResource();
    const scoreParameters = useQuery({
        queryKey: ["score-data-parameters"],
        queryFn: () => getContentsScoreDatas({})
    });

    const getScores = useQuery({
        queryKey: ["alternative-score-data"],
        queryFn: () => getAlternativeScore({ _alternativeComparison: id }),
        enabled: id !== "new"
    });

    const getKeywordDensity = useQuery({
        queryKey: ["alternative-keyword-density"],
        queryFn: () => getAlternativeKeywordDensity({ _alternativeComparison: id }),
        enabled: id !== "new"
    });

    const createRows = (data: IAlternativeKeywordDensity) => ({
        keyword_name: capitalize(data?.keyword),
        density: (data?.density !== "" ? data?.density + "%" : "0%"),
        keyword_type: capitalize(data?.type)
    });
    let rows: IAlternativeKeywordDensityRow[] = [];
    if (getKeywordDensity?.data?.data?.keywordsData) {
        rows = getKeywordDensity?.data?.data?.keywordsData?.map(
            (data) =>
                createRows(data)
        );
    }

    const columns: IColumn[] = [
        {
            id: "keyword_name",
            label: "Keyword",
            minWidth: 120
        },
        {
            id: "keyword_type",
            label: "Type",
            minWidth: 160
        },
        {
            id: "density",
            label: "Density"
        },
    ];

    const handleApprove = () => {
            handleSubmitForm((data) => {
                onApprove({ ...data, isApproved: "true "});
            })();
    };

    const onApprove = async (data: IScoreDataForm) => {
        navigate({ pathname: `/comparison/manage/${id}/checklistReviewer`, search: searchParam.toString() }, {
            state: {
                payload: {
                    _alternativeComparison: id,
                    score: (state.parametersData || []).map((field) => ({
                        _scoreId: field._id,
                        scorePoints: data[field.name] || 0,
                    })),
                    isApproved: "true"
                }
            }
        });
    };

    const onSubmit = async (data: IScoreDataForm) => {
        try {
            if (id === "new") {
                snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
                return;
            }
            const scoresArray = (state.parametersData || []).map((field) => ({
                _scoreId: field._id,
                scorePoints: data[field.name] || 0,
            }));
            const payload = {
                _alternativeComparison: id,
                score: scoresArray,
                isApproved: data.isApproved === "true"
            };
            const response = await addAlternativeScore(payload);
            snackbar(response.message, "info");
            navigate(`/comparison/manage/${id}?type=score`);
        } catch (error) {
            const err = error as IErrorResponse;
            navigate(`/comparison/manage/${id}?type=score`);
            snackbar(err?.data?.message, "error");
        }
    };

    const [state, setState] = useState<IScoreState>({
        parametersData: [],
        parameters: {}
    });

    const { handleSubmit: handleSubmitForm, control, setValue, formState: { errors } } = useForm<IScoreDataForm>({
        resolver: joiResolver(Joi.object()
                    .pattern(
                        Joi.string(),
                        Joi.number().required()
                    ),
                  ),
        defaultValues: {
            ...state.parametersData.reduce((acc, field) => {
                acc[field.name] = "";
                return acc;
            }, {} as IScoreDataForm),
        }
    });
    const handleSubmitWithStatus = (status: boolean) => {
        handleSubmitForm((data) => {
            onSubmit({ ...data, isApproved: status === true ? "true" : "false" });
        })();
    };

    useEffect(() => {
        if (scoreParameters.data?.data) {
            const params: IScoreData[] = [];
            scoreParameters.data?.data?.map(parameter => {
                params.push({ name: parameter?.name, placeholder: "Type Scores Here", _id: parameter._id, description: parameter?.description });
            });

            setState(prevState => ({
                ...prevState,
                parametersData: params,
            }));
            params.forEach(field => {
                setValue(field.name, "");
            });
        }
    }, [scoreParameters.data?.data, setValue]);

    useEffect(() => {
        if (getScores?.data?.data !== null) {
            state.parametersData.forEach(field => {
                const matchingScore = getScores?.data?.data?.score.find((score: IAlternativeScoreFields) => score._scoreId === field._id);

                const scorePoints = matchingScore ? matchingScore.scorePoints : "";
                setValue(field.name, scorePoints);

            });
        }
    }, [getScores?.data?.data, state.parametersData]);


    return (
        <div className="blog-content">
            {state?.parametersData?.length ?
                <Grid container spacing={4}>
                    <Grid item xs={8}>
                        <Typography className="title mb-3" variant="body2">Add Score Data</Typography>

                        <form>
                            <Grid container spacing={4}>
                                {(state.parametersData || []).map(field =>
                                    <Grid key={field._id} item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={<CustomLabel label={capitalize(field.name)} required />}
                                                className="disable-text"
                                                variant="outlined"
                                                size="small"
                                                placeholder={field.placeholder}
                                                error={errors[field.name] ? true : false}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}
                                                type="number"
                                                onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                                inputProps={{
                                                    pattern: "[0-9]*",
                                                    inputMode: "numeric",
                                                    maxLength: 4,
                                                    onInput: (e) => {
                                                        const inputValue = e.currentTarget.value;
                                                        const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                                                        const valueAsNumber = parseInt(sanitizedValue, 10);
                                                        if (!isNaN(valueAsNumber) && valueAsNumber >= 1 && valueAsNumber <= 100) {
                                                            e.currentTarget.value = sanitizedValue;
                                                            prop.field.onChange(sanitizedValue);
                                                        } else if (sanitizedValue === "") {
                                                            e.currentTarget.value = sanitizedValue;
                                                            prop.field.onChange(sanitizedValue);
                                                        } else {
                                                            e.currentTarget.value = sanitizedValue.slice(0, -1);
                                                        }
                                                    },
                                                }}
                                            />}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            <Box display="flex" className="mb-2 mt-4" alignItems="center" justifyContent={"space-between"}>
                                    <Typography className="title" variant="body2">Keyword Density</Typography>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1">Total Words:</Typography>
                                    <Typography className="ml-2" variant="body1" >{getKeywordDensity?.data?.data?.totalWords}</Typography>
                                </Box>
                            </Box>

                            <Box marginTop="10px">
                                <CustomTable
                                    columns={columns}
                                    rows={rows}
                                    height="calc(100vh - 538px)"
                                />
                            </Box>

                            <div className="mt-4">
                                <Button
                                    variant="outlined"
                                    style={{ color: "grey", borderColor: "grey" }}
                                    onClick={() => handleSubmitWithStatus(false)}
                                    disabled={!resourceAllocate("cms-alternative-comparison-score.write")}
                                >Changes Suggested</Button>
                                <Button
                                    onClick={() => handleApprove()}
                                    className="ml-2"
                                    color="success"
                                    disabled={!resourceAllocate("cms-alternative-comparison-score.write")}
                                >Approve</Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
                :
                <ErrorMessage Icon={<DonutSmallIcon fontSize="large" className="error-icon" />} fullHeight errorMessage={"Add some data to see"} />
            }
            <Outlet />
        </div>
    );
};

export default Score;
