import HttpService from "../../http";
import { okrRatings } from "../../endpoints";
import { IOKRRatingResponse, IOKRRatingsResponse } from "../../../interfaces/okr/ratings";

const OkrRatingsService = () => {
    const { httpRequest } = HttpService();

    const getRatings = async (search: object): Promise<IOKRRatingsResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRRatingsResponse>(
            "GET",
            `${okrRatings}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getRating = async (search: object): Promise<IOKRRatingResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRRatingResponse>(
            "GET",
            `${okrRatings}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteRating = async (query: object): Promise<IOKRRatingResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRRatingResponse>(
            "DELETE",
            `${okrRatings}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const addRating = async (payload: object): Promise<IOKRRatingResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRRatingResponse>(
            "POST",
            `${okrRatings}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateRating = async (payload: object): Promise<IOKRRatingResponse> => new Promise((resolve, reject) => {
        httpRequest<IOKRRatingResponse>(
            "PUT",
            `${okrRatings}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getRatings, updateRating, getRating, addRating, deleteRating };
};

export { OkrRatingsService };