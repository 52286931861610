import { useState } from "react";
import { ChangeEvent, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, TextField } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { EmployeeReviewService } from "../../../../services";
import { EmployeeReviewValidation } from "../../../../validations";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IEmployeeReviewField, IEmployeeReview, IErrorResponse } from "../../../../interfaces";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import UploadImg from "../../../../assets/images/upload.png";
import HttpService from "../../../../services/http";
interface outletProps {
    reFetch: () => void
}

const ManageEmployeeReview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const { httpFormRequest } = HttpService();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const [imageSelected, setImageSelected] = useState<string | null>(null);
    const characterLimit = 2000;
    const { addEmployeeReviews, getEmployeeReview, updateEmployeeReview } = EmployeeReviewService();
    const emploeeReview = useQuery({ queryKey: [hitQuery], queryFn: () => getEmployeeReview({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, trigger, formState: { errors } } = useForm<IEmployeeReview>({
        resolver: joiResolver(EmployeeReviewValidation),
        defaultValues: {
            image: "",
            name: "",
            position: "",
            review: "",
        }
    });

    useEffect(() => {
        if (id === "new") {
            console.log("new");
        } else {
            if (emploeeReview.data?.data) {
                setValue("image", emploeeReview.data.data.image);
                setValue("name", emploeeReview.data.data.name);
                setValue("position", emploeeReview.data.data?.position);
                setValue("review", emploeeReview.data.data?.review);
                setImageSelected(emploeeReview.data.data.image);
                trigger("image");

            }
        }
    }, [id, emploeeReview.data]);

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
        try {
            if (e.target.files && e.target.files.length > 0) {
                const uploaded = await httpFormRequest<{ data: string }>(
                    e.target.files,
                    e.target.files[0].name,
                    ["png", "jpeg", "jpg"],
                    1
                );
                const keyExist = type === "image";
                if (keyExist) {
                    setValue(type, uploaded.data);
                    setImageSelected(URL.createObjectURL(e.target.files[0]));
                    trigger(type);
                }
            }
        }
        catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log("error in candidate detail upload", error);
        }
    };



    const onSubmit = async (data: IEmployeeReview) => {
        try {
            if (id === "new") {
                const payload = { ...data };
                const add = await addEmployeeReviews(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/careers/employee-review",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            } else {
                const payload = { ...data, _id: id };
                const update = await updateEmployeeReview(id ? id : "", payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/careers/employee-review",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/careers/employee-review",
            search: searchParam.toString()
        });
    };

    const fields: IEmployeeReviewField[] = [
        {
            label: "Name*",
            name: "image",
            type: "upload",
        },
        {
            label: "Name*",
            name: "name",
            type: "input",
            placeholder: "Type employee name here",
        },
        {
            label: "Position*",
            name: "position",
            type: "input",
            placeholder: "Type employee position here",
        },
        {
            label: "Review*",
            name: "review",
            type: "multiline",
            placeholder: "Type employee review here (Characters should not more than 2000)",
            width: 12
        },

    ];

    return (
        <Box>
            <CustomDialog
                title={id !== "new" ? "Edit Review" : "Add Review"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "upload") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Box className="center">
                                        <Box height="100px" width="100px" className="upload-img editable" aria-label="upload picture" component="label">
                                            <img src={imageSelected || UploadImg} alt="review" />
                                            <div className="edit-img ">{imageSelected ? "Edit" : "Add"}</div>
                                            <input hidden accept="image/*" type="file" onChange={e => uploadFile(e, field.name)} />
                                        </Box>
                                    </Box>
                                    {errors[field.name] && <span style={{ color: "#d32f2f", marginLeft: "376px", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                                </Grid>
                                );
                            } else if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else if (field.type === "multiline") {
                                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={
                                                errors[field.name]?.message ||
                                                `${prop.field.value.length}/${characterLimit} characters`
                                            }
                                            multiline
                                            minRows={4}
                                            inputProps={{
                                                maxLength: characterLimit,
                                            }}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                            else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={field.label}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );

};

export default ManageEmployeeReview;