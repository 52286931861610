
import { IGlossaryTimelineResponse } from "../../../interfaces/content-marketing/glossary";
import { glossaryTimeline } from "../../endpoints";
import HttpService from "../../http";


const GlossaryActivityService = () => {
    const { httpRequest } = HttpService();

    const getGlossaryActivities = async (search: object): Promise<IGlossaryTimelineResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryTimelineResponse>(
            "GET",
            `${glossaryTimeline}`,
            {},
            search,
            false
        )
            .then(resolve)
            .catch(reject);
    });

    return { getGlossaryActivities };
};

export default GlossaryActivityService;