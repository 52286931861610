import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";
import useUser from "../../hooks/useUser";
import { a11yProps } from "../../components/shared/mui-tabs";

interface IState {
  redirectionLinks: string[]
}

const SiteMapLayout = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const outlet = useOutletContext();
  const { user } = useUser();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IState>({
    redirectionLinks: []
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (resourceAllocate("cms-sitemap.read")) {
      redirectionLinks.push("sitemap-data");
    }
    if (resourceAllocate("cms-url-bundle.read")) {
      redirectionLinks.push("url-bundles");
    }
    if (resourceAllocate("cms-custom-url.read")) {
      redirectionLinks.push("custom-url");
    }
    if (resourceAllocate("cms-sitemap-setting.read")) {
      redirectionLinks.push("settings");
    }
    setState(prev => ({
      ...prev,
      redirectionLinks
    }));
  }, [user]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };
  
  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {resourceAllocate("cms-sitemap.read") && <Tab className="tabs-space start-tab-space" label="Projects" {...a11yProps(0)} />}
          {resourceAllocate("cms-url-bundle.read") && <Tab className="tabs-space" label="Url Bundles" {...a11yProps(1)} />}
          {resourceAllocate("cms-custom-url.read") && <Tab className="tabs-space" label="Custom Urls" {...a11yProps(2)} />}
          {resourceAllocate("cms-sitemap-setting.read") && <Tab className="tabs-space" label="Settings" {...a11yProps(3)} />}
        </Tabs>
      </Box>
      <Outlet context={outlet} />
    </Box>
  );
};

export default SiteMapLayout;