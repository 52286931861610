import "./style.scss";
import { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IPMSSubParameter, PMSSubParameterField, PMSSubParameterFieldForm } from "../../../../../interfaces/pms/pms-parameter/sub-parameter";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { pmsSubParametersService } from "../../../../../services/pms/pms-parameters/sub-parameters";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces";
import CustomDialog from "../../../../../components/mui/dialog";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import CustomLabel from "../../../../../components/mui/custom-label";
import { capitalize } from "../../../../../utilities/helper";
import { joiResolver } from "@hookform/resolvers/joi";
import { pmsSubParametersValidations } from "../../../../../validations/pms";
import { PMS_ERROR_MESSAGES } from "../../../../../utilities/messages";

interface outletProps {
  refetchSubParameters: () => void;
  pmsSubParameters: IPMSSubParameter[],
  parameterWeightage: number;
  parameterName: string;
}

const ManagePMSSubParameter = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { pmsSubParameters, parameterWeightage, parameterName } = outlet;
  const { snackbar } = useSnackbar();
  const [ searchParams ] = useSearchParams();
  const { id, parameterId } = useParams();
  const { addPmsSubParameter, updatePmsSubParameter } = pmsSubParametersService();
  const [deletedSubParameters, setDeletedSubParameters] = useState<string[]>([]);
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<PMSSubParameterFieldForm>({
    resolver: joiResolver(pmsSubParametersValidations),
    defaultValues: {
      form: [
        {
          _id: "",
          name: "",
          netWeightage: 0,
        },
      ],
    },
  });

  const [parameters, setParameters] = useState<PMSSubParameterField[]>(
    id === "new"
      ? [
          {
            _id: "",
            name: "",
            netWeightage: 0,
          },
        ]
      : pmsSubParameters.map((param) => ({
          _id: param._id || "",
          name: param.name || "",
          weightage: param.weightage || 0,
          netWeightage: param.netWeightage || 0,
        }))
  );

  useEffect(() => {
    setValue("form", parameters);
    trigger("form");
  }, [parameters]);
  const handleAddRow = () => {
    setParameters((prev) => [
      ...prev,
      {
        _id: "",
        name: "",
        weightage: 0,
        netWeightage: 0,
      },
    ]);
  };

  const handleRemoveRow = (index: number) => {
    if (parameters[index]._id.length) {
      setDeletedSubParameters((prev) => [...prev, parameters[index]._id]);
    }
    setParameters((prev) => {
      const updated = prev.filter((_, i) => i !== index);
      setValue("form", updated);
      trigger("form");
      return updated;
    });
  };

  const onSubmit = async (data: PMSSubParameterFieldForm) => {
    let weightageSum = 0;
    data.form.forEach((param) => {
      weightageSum += Number(param.weightage);
    });
    if (weightageSum !== 100) {
      snackbar(PMS_ERROR_MESSAGES.en.invalid_weightage, "warning");
      return;
    }

    try {
      const payload = {
        subParameters: data.form.map((param) => ({
          _id:
            id === "new" ? undefined : param._id.length ? param._id : undefined,
          name: param.name,
          weightage: param.weightage,
          netWeightage: param.netWeightage,
          _parameter: parameterId,
        })),
        deletedSubParameters: id !== "new" ? deletedSubParameters : undefined,
      };
      if (id === "new") {
        const res = await addPmsSubParameter(payload);
        snackbar(res.message, "info");
        navigate({
          pathname: `/pms-parameters/sub-parameters/${parameterId}`,
          search: searchParams.toString(),
        });
        outlet?.refetchSubParameters && outlet.refetchSubParameters();
      } else {
        const res = await updatePmsSubParameter(payload);
        snackbar(res.message, "info");
        navigate({
          pathname: `/pms-parameters/sub-parameters/${parameterId}`,
          search: searchParams.toString(),
        });
        outlet?.refetchSubParameters && outlet.refetchSubParameters();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  return (
    <CustomDialog
      title="Add/Edit Sub Parameters"
      isOpen={true}
      onClose={() => navigate({
        pathname: `/pms-parameters/sub-parameters/${parameterId}`,
        search: searchParams.toString(),
      })}
      onSubmit={handleSubmit(onSubmit)}
      size="md"
    >
      <Box>
            <div className="sub-title-box">
          <Typography className="sub-text" fontWeight={600}>
            {capitalize(parameterName)} (Total: {parameterWeightage}%)
          </Typography>
        </div>
        <div className="note-box">
          <p className="note-text">
              <strong>Note: </strong>Total weightage for sub-parameters (40% + 35% + 25%) = 100% of the parameter’s weightage.
          </p>
        </div>
        {parameters.map((param, index) => (
          <Box key={index} display="flex" alignItems="flex-start" gap={2} mb={2} mt={2}>
            <Grid container spacing={2}>
              <Grid key={`name-${index}`} item xs={4}>
                <Controller
                  defaultValue={param.name}
                  control={control}
                  name={`form.${index}.name`}
                  render={() => (
                    <TextField
                      label={<CustomLabel label={"Sub Parameter name"} required />}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={"Enter name"}
                      error={!!errors.form?.[index]?.name}
                    helperText={errors.form?.[index]?.name?.message}
                      onChange={(e) => {
                        setParameters((prev) => {
                          const updated = [...prev];
                          updated[index].name = e.target.value;
                          return updated;
                        });
                        trigger(`form.${index}.name`);
                      }}
                      value={param.name}
                    />
                  )}
                />
              </Grid>

              <Grid key={`weightage-${index}`} item xs={4}>
                <Controller
                  defaultValue={param.weightage}
                  control={control}
                  name={`form.${index}.weightage`}
                  render={() => (
                    <TextField
                    label={<CustomLabel label={"Weightage (in %)"} required />}
                    className="disable-text"
                    variant="outlined"
                    size="small"
                    placeholder={"Enter weightage in percentage"}
                    type="number"
                    error={!!errors.form?.[index]?.weightage}
                    helperText={errors.form?.[index]?.weightage?.message}

                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || (Number(value) > 0 && Number(value) <= 100)) {
                        setParameters((prev) => {
                          const updated = [...prev];
                          updated[index].weightage = value === undefined ? undefined : Number(value);
                          return updated;
                        });
                        trigger(`form.${index}.weightage`);
                      }
                    }}
                    value={param.weightage || ""}
                  />
                  )}
                />
              </Grid>

              <Grid key={`netWeightage-${index}`} item xs={4}>
                <Controller
                  defaultValue={param.netWeightage}
                  control={control}
                  name={`form.${index}.netWeightage`}
                  render={() => (
                    <TextField
                      label={<CustomLabel label={"Net weightage (in %)"} required />}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={"Enter net weightage"}
                      type="number"
                      disabled
                      error={!!errors.form?.[index]?.netWeightage}
                      helperText={errors.form?.[index]?.netWeightage?.message}
                      value={param.weightage && (parameterWeightage * param.weightage) / 100}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <IconButton
              onClick={() => handleRemoveRow(index)}
              disabled={parameters.length === 1}
              color="error"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        ))}

        <Button
          variant="outlined"
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddRow}
        >
          Add Sub Parameter
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default ManagePMSSubParameter;
