import "../../content-marketing-layout/blog/manage/style.scss";
import backArrowRoundIcon from "../../../assets/images/back-arrow-round.svg";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommentIcon from "../../../assets/images/Comment.svg";
import coloredCommentIcon from "../../../assets/images/Comment-colored.svg";
import timelineIcon from "../../../assets/images/timeline.svg";
import { useQuery } from "@tanstack/react-query";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import { AlternativeComparisonService } from "../../../services/alternative-comparison";
import { a11yProps, TabPanel } from "../../../components/shared/mui-tabs";
import { Summary } from "./summary";
import Assignments from "./assignment";
import Score from "./score";
import AlternativeTimeline from "../alternative-timeline";
import BasicDetail from "./basic-detail";
import AlternativeContent from "./content";
import { CMS_ERROR_MESSAGES } from "../../../utilities/messages";
import ImageSection from "./image-section";

interface SideButtonProps {
  index: number;
  setActiveAction: (e: boolean) => void;
}

const SideButton: FC<SideButtonProps> = ({ index, setActiveAction }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  let label = "";
  let icon = "";
  let hoverIcon = "";
  if (index === 2) {
    label = "Comments";
    icon = CommentIcon;
    hoverIcon = coloredCommentIcon;
  } 
  //will be used once version history is added
  // else if (index === 3) {
  //   label = "Version History";
  //   icon = versionIcon;
  //   hoverIcon = coloredVersionIcon;
  // } 
  else {
    return null;
  }

  return (
    <div
      className="note"
      onClick={() => setActiveAction(true)}
      style={{ cursor: "pointer" }}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <Typography variant="caption">{label}</Typography>
      <img
        className="ml-2"
        src={isHovered && (index === 2 || index === 3) ? hoverIcon : icon}
      />
    </div>
  );
};

interface IState {
  redirectionLinks: string[];
}

const AlternativeComparisonManage = () => {
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const [comparisonAlternativeId, setComparisonAlternativeId] = useState<string | undefined>("");
  const { resourceAllocate } = useResource();
  const [showTimeline, setShowTimeline] = useState(false);
  const { snackbar } = useSnackbar();
  const [activeAction, setActiveAction] = useState<boolean>(false);
  const [commentActiveAction, setCommentActiveAction] =
    useState<boolean>(false);
  const [searchParam, setSearchParam] = useSearchParams();
  const { getComparison } = AlternativeComparisonService();

  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
      if (resourceAllocate("cms-alternative-comparison.read") || resourceAllocate("cms-alternative-comparison.readAssigned")) {
        redirectionLinks.push("basic-detail");
      }
      if (resourceAllocate("cms-alternative-comparison-assignment.read")) {
        redirectionLinks.push("assignment");
      }
      if (resourceAllocate("cms-alternative-comparison-image")) {
        redirectionLinks.push("image");
      }
      if (resourceAllocate("cms-alternative-comparison-content.read")) {
        redirectionLinks.push("content");
      }
      if (resourceAllocate("cms-alternative-comparison-score.read")) {
        redirectionLinks.push("score");
      }
      if (resourceAllocate("cms-alternative-comparison-summary.read")) {
        redirectionLinks.push("summary");
      }

      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }
    const tab = searchParam.get("type")
      ? String(searchParam.get("type"))
      : "basic-detail";

    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);

  const handlePublishClick = async () => {
    navigate({
      pathname: `/comparison/manage/${id}/checklistPublish`,
      search: searchParam.toString(),
    });
  };

  const navigate = useNavigate();

  const hitQuery = !(id === "new" || id === "view");

  const comparisonData = useQuery({
    queryKey: ["comparison-data", id],
    queryFn: () => getComparison({ _id: id }),
    enabled: hitQuery,
  });

  useEffect(() => {
    if (comparisonData.data && comparisonData.data.data) {
      setComparisonAlternativeId(comparisonData?.data?.data?.comparisonAlternativeId);
    }
  }, [comparisonData?.data?.data?.comparisonAlternativeId, id]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (id === "new" && newValue !== 0) {
      snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
      return;
    }
    searchParam.set("type", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
    setActiveAction(false);
  };
  const handleClick = () => {
    searchParam.delete("type");
    navigate({
      pathname: "/comparison",
      search: searchParam.toString()
    });
  };
  const handleTimeLineClick = () => {
    setShowTimeline(true);
  };

  return (
    <div id="blog-manage">
      <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} onClick={handleClick} />
          <Typography className="title" variant="h5">
            {id === "new" ? "Add New Alternative/Comparison" : "Alternative/Comparison Details"}
          </Typography>
        </div>

        <div className="save-box">
          <button
            className="p-2 header-buttons pl-3 pr-3"
          >
            <Typography>{comparisonData && comparisonData.data && comparisonData.data.data && comparisonData.data.data.status.replace("_", " ") || ""}</Typography>
          </button>
          <button
            className="p-2 header-buttons cursor-pointer ml-3 timeline-button"
            onClick={handleTimeLineClick}
          >
            <img
              className="timeline-icon"
              src={timelineIcon}
              alt="Timeline Icon"
            />
            <Typography variant="body1">Timeline</Typography>
          </button>
          {(comparisonData?.data?.data?.status === "IN_PUBLISHING" ||
            comparisonData?.data?.data?.status === "COMPLETED" ||
            comparisonData?.data?.data?.status === "SCHEDULED") && 
            resourceAllocate("cms-alternative-comparison-publish.write") && (
              <Button
                variant="outlined"
                className="ml-3 schedule-button"
                disabled={comparisonData?.data?.data?.isDraft}
                onClick={() =>
                  navigate({
                    pathname: `/comparison/manage/${id}/schedule`,
                    search: searchParam.toString(),
                  })
                }
              >
                Schedule
              </Button>
            )}
          {(comparisonData?.data?.data?.status === "IN_PUBLISHING" ||
            comparisonData?.data?.data?.status === "COMPLETED" ||
            comparisonData?.data?.data?.status === "SCHEDULED") && 
            resourceAllocate("cms-alternative-comparison-publish.write") && (
              <Button
                className="ml-3 publish-button header-buttons"
                disabled={comparisonData?.data?.data?.isDraft} 
                onClick={handlePublishClick}
              >
                Publish
              </Button>
            )}
        </div>
      </header>

      {/* tabs  */}
      <Box width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {state.redirectionLinks &&
              state.redirectionLinks.map((link, index) => (
                <Tab
                  key={index}
                  className="tabs-space"
                  label={link.charAt(0).toUpperCase() + link.slice(1)}
                  {...a11yProps(0)}
                />
              ))}
          </Tabs>
        </Box>

        {state.redirectionLinks &&
          state.redirectionLinks.map(
            (link, index) =>
              link !== "basic-details" &&
              index === value && (
                <div key={index} className="blog-detail">
                  <div>
                    {id === "new" ? (
                      <Typography className="title" variant="body1">
                        Title
                      </Typography>
                    ) : (
                      <Typography className="title" variant="body1">
                        {comparisonData?.data?.data?.title}{" "}
                        {comparisonData?.data?.data?.isDraft && (
                          <span className="rectangle-badge">Draft</span>
                        )}
                      </Typography>
                    )}
                    {id !== "new" && (
                      <Typography
                        className="sub-text"
                        variant="caption"
                      >{`Alternative/Comparison ID : ${comparisonAlternativeId}`}</Typography>
                    )}
                  </div>
                  {link === "image" && (
                    <div
                      className="action"
                      onClick={() => setActiveAction(false)}
                    >
                      <SideButton
                        index={2}
                        setActiveAction={setCommentActiveAction}
                      />
                    </div>
                  )}
                  {link === "content" && (
                    <div style={{ display: "flex" }}>
                      <div
                        className="action"
                        onClick={() => setActiveAction(false)}
                      >
                        <SideButton
                          index={2}
                          setActiveAction={setCommentActiveAction}
                        />
                      </div>

                      <div
                        className="action ml-4"
                        onClick={() => setCommentActiveAction(false)}
                      >
                        <SideButton
                          index={3}
                          setActiveAction={setActiveAction}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
          
        {showTimeline && <AlternativeTimeline setShowTimeline={setShowTimeline} />} 
        
        {state.redirectionLinks &&
          state.redirectionLinks.map((link, index) => (
            <TabPanel key={index} value={value} index={index}>
              {link === "basic-detail" && <BasicDetail />}
              {link === "assignment" && (
                <Assignments
                  activeAction={activeAction}
                  setActiveAction={setActiveAction}
                />
              )}
              {link === "image" && (
                <ImageSection
                  activeAction={commentActiveAction}
                  setActiveAction={setCommentActiveAction}
                />
              )}

              {link === "content" && (
                <AlternativeContent
                  activeAction={activeAction}
                  setActiveAction={setActiveAction}
                  commentActiveAction={commentActiveAction}
                  setCommentActiveAction={setCommentActiveAction}
                />
              )}
              {link === "score" && <Score />}

              {link === "summary" && <Summary />}
            </TabPanel>
          ))}
      </Box>
    </div>
  );
};

export default AlternativeComparisonManage;