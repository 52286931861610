import { glossaryContent } from "../../endpoints";
import HttpService from "../../http";
import { IGlossaryContentResponse, IGlossaryVersionsResponse } from "../../../interfaces/content-marketing/glossary/content";
const GlossaryContentService = () => {

    const { httpRequest } = HttpService();
    const addGlossaryContent = async (payload: object): Promise<IGlossaryContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryContentResponse>(
            "PUT",
            `${glossaryContent}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const updateGlossaryContentStatus = async (payload: object): Promise<IGlossaryContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryContentResponse>(
            "PUT",
            `${glossaryContent}/submit-content`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getGlossaryContent = async (query: object): Promise<IGlossaryContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryContentResponse>(
            "GET",
            `${glossaryContent}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const glossaryContentAutoSave = async (payload: object): Promise<IGlossaryContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryContentResponse>(
            "PUT",
            `${glossaryContent}/auto-save-content`,
            payload,
            {},
            false
        )
            .then(resolve)
            .catch(reject);
    });

    const getGlossaryContentVersions = async (query: object): Promise<IGlossaryVersionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryVersionsResponse>(
            "GET",
            `${glossaryContent}/versions`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateContentVersion = async (payload: object): Promise<IGlossaryVersionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryVersionsResponse>(
            "PUT",
            `${glossaryContent}/set-current-version`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addGlossaryContent, getGlossaryContent, glossaryContentAutoSave, getGlossaryContentVersions, updateGlossaryContentStatus, updateContentVersion };

};

export { GlossaryContentService };