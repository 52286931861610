import { ICourseConfigurationPartialResponse, ICourseConfigurationResponse } from "../../../interfaces";
import { course } from "../../endpoints";
import HttpService from "../../http";

const courseConfigurationService = () => {
    const { httpRequest } = HttpService();
    const addCourseConfiguration = async (payload: object): Promise<ICourseConfigurationResponse> => new Promise((resolve, reject) => {
        httpRequest<ICourseConfigurationResponse>(
            "POST",
            `${course}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialcourseConfiguration = async (): Promise<ICourseConfigurationResponse> => new Promise((resolve, reject) => {
        httpRequest<ICourseConfigurationResponse>(
            "GET",
            `${course}/list/partial`,
            {}
        )
            .then(resolve)
            .catch(reject);
    });

    const getcourseConfigurationList = async (search: object): Promise<ICourseConfigurationResponse> => new Promise((resolve, reject) => {
        httpRequest<ICourseConfigurationResponse>(
            "GET",
            `${course}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });    
    
    const getcourseConfiguration = async (search: object): Promise<ICourseConfigurationPartialResponse> => new Promise((resolve, reject) => {
        httpRequest<ICourseConfigurationPartialResponse>(
            "GET",
            `${course}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCourseConfiguration = async (payload: object): Promise<ICourseConfigurationResponse> => new Promise((resolve, reject) => {
        httpRequest<ICourseConfigurationResponse>(
            "PUT",
            `${course}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCourseConfiguration = async (payload: object): Promise<ICourseConfigurationResponse> => new Promise((resolve, reject) => {
        httpRequest<ICourseConfigurationResponse>(
            "DELETE",
            `${course}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    return { getPartialcourseConfiguration, addCourseConfiguration, getcourseConfiguration, getcourseConfigurationList, updateCourseConfiguration, deleteCourseConfiguration };
};

export { courseConfigurationService };