import { useState, SyntheticEvent, useEffect } from "react";
import {
  useNavigate,
  useLocation,
  Outlet,
  useOutletContext,
} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";
import useUser from "../../hooks/useUser";
import { a11yProps } from "../../components/shared/mui-tabs";

interface IState {
  redirectionLinks: string[];
}

const AssessmentLayout = () => {
  const outlet = useOutletContext();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (resourceAllocate("assessment.read")) {
      redirectionLinks.push("assessments");
    }
    if (resourceAllocate("technical-test-tag.read")) {
      redirectionLinks.push("question-tags");
    }
    if (resourceAllocate("technical-question-languages.read")) {
      redirectionLinks.push("question-languages");
    }
    if (resourceAllocate("technical-question.read")) {
      redirectionLinks.push("technical-questions");
    }
    if (resourceAllocate("assessment-instruction.read")) {
      redirectionLinks.push("assessments-instruction");
    }
    if(resourceAllocate("assessment-media.read")) {
      redirectionLinks.push("assessments-media");
    }
    if (resourceAllocate("assessment-evaluation-rule.read")) {
      redirectionLinks.push("assessments-evaluation");
    }
    if (resourceAllocate("assessment-penality.read")) {
      redirectionLinks.push("assessments-penality");
    }
    if (resourceAllocate("test.read")) {
      redirectionLinks.push("tests");
    }

    setState((prev) => ({
      ...prev,
      redirectionLinks,
    }));
  }, [user]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(
      location.pathname.split("/")[2]
    );
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };
  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            aria-label="basic tabs example"
          >
            {resourceAllocate("assessment.read") && (
              <Tab
                className="tabs-space"
                label="Assessments"
                {...a11yProps(2)}
              />
            )}
            {resourceAllocate("technical-test-tag.read") && (
              <Tab
                className="tabs-space"
                label="Question Tags"
                {...a11yProps(2)}
              />
            )}
            {resourceAllocate("technical-question-languages.read") && (
              <Tab
                className="tabs-space"
                label="Question Languages"
                {...a11yProps(2)}
              />
            )}
            {resourceAllocate("technical-question.read") && (
              <Tab
                className="tabs-space"
                label="Technical Questions"
                {...a11yProps(2)}
              />
            )}
            {resourceAllocate("assessment-instruction.read") && (
              <Tab
                className="tabs-space"
                label="Assessment Instructions"
                {...a11yProps(2)}
              />
            )}
            {resourceAllocate("assessment-media.read") && (
                <Tab
                  className="tabs-space"
                  label="Assessment Media"
                  {...a11yProps(2)}
                />
            )}
            {resourceAllocate("assessment-evaluation-rule.read") && (
              <Tab
                className="tabs-space"
                label="Assessment Evaluation"
                {...a11yProps(2)}
              />
            )}
            {resourceAllocate("assessment-penality.read") && (
              <Tab
                className="tabs-space"
                label="Assessment Penalty"
                {...a11yProps(2)}
              />
            )}
            {resourceAllocate("test.read") && (
              <Tab className="tabs-space" label="MCQ Tests" {...a11yProps(2)} />
            )}
          </Tabs>
        </Box>
      </Box>
      <Outlet context={outlet} />
    </Box>
  );
};
export default AssessmentLayout;
