import { Box, capitalize, Grid, SelectChangeEvent, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomDialog from "../../../../components/mui/dialog";
import MobileNumber from "../../../../components/mui/mobile-number";
import { IErrorResponse, IOnboardUser, IOnboardUserField } from "../../../../interfaces";
import { joiResolver } from "@hookform/resolvers/joi";
import { OnbardUserValidation } from "../../../../validations";
import { ChangeEvent, useEffect, useState } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import UploadImg from "../../../../assets/images/upload.png";
import HttpService from "../../../../services/http";
import { OnboardUserService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../components/mui/select";

interface outletProps {
    reFetch: () => void;
    refetchOnBoardUsers: () => void;
}

const ManageOnboardUser = () => {
    const { id } = useParams();
    const { httpFormRequest } = HttpService();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const navigate = useNavigate();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const outlet = useOutletContext<outletProps>();
    const { addOnBoardUser, getOnBoardUser, updateOnBoardUser } = OnboardUserService();
    const [imageSelected, setImageSelected] = useState<string | null>(null);
    const onboardUser = useQuery({ queryKey: [hitQuery], queryFn: () => getOnBoardUser({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, trigger, formState: { errors } } = useForm<IOnboardUser>({
        resolver: joiResolver(OnbardUserValidation),
        defaultValues: {
            picture: "",
            name: "",
            email: "",
            number: "",
            designation: "",
        }
    });

    const [phone, setPhone] = useState({
        country: "INDIA",
        dialCode: "+91",
        iso2: "IN"
    });

    const onNumberChange = (e: SelectChangeEvent<string>) => {
        const { value } = e.target;
        const data = value.split(":");
        setPhone({
            country: data[0],
            iso2: data[1],
            dialCode: data[2]
        });
    };

    useEffect(() => {
        if (id === "new") {
            console.log("new");
        } else {
            if (onboardUser.data?.data) {
                setValue("picture", (onboardUser.data.data?.picture));
                setImageSelected(onboardUser.data.data?.picture);
                setValue("name", capitalize(onboardUser.data.data?.name));
                setValue("email", onboardUser.data.data?.email);
                setValue("designation", capitalize(onboardUser.data.data?.designation));
                setPhone(onboardUser.data.data?.phone);
                setValue("number", onboardUser.data.data?.phone?.number);
            }
        }
    }, [id, onboardUser.data?.data]);

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
        try {
            if (e.target.files && e.target.files.length > 0) {
                const uploaded = await httpFormRequest<{ data: string }>(
                    e.target.files,
                    e.target.files[0].name,
                    ["png", "jpeg", "jpg"],
                    1
                );
                const keyExist = type === "picture";
                if (keyExist) {
                    setValue(type, uploaded.data);
                    setImageSelected(URL.createObjectURL(e.target.files[0]));
                    trigger(type);
                }
            }
        }
        catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log("error in candidate detail upload", error);
        }
    };
    const onSubmit = async (data: IOnboardUser) => {
        try {
            if (id === "new") {
                const payload = {
                    ...data,
                    phone: {
                        ...phone,
                        number: data.number,
                    },
                };
                delete payload.number;
                const add = await addOnBoardUser(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/configurations/onboard-user",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchOnBoardUsers && outlet.refetchOnBoardUsers();
            } else {
                const payload = {
                    ...data, _id: id,
                    phone: {
                        ...phone,
                        number: data.number,
                    },
                };
                delete payload.number;
                const user = await updateOnBoardUser(payload);
                snackbar(user.message, "info");
            }
            navigate({
                pathname: "/configurations/onboard-user",
                search: searchParam.toString()
            });
            outlet?.reFetch && outlet.reFetch();
            outlet?.refetchOnBoardUsers && outlet.refetchOnBoardUsers();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };



    const onClose = () => {
        navigate({
            pathname: "/configurations/onboard-user",
            search: searchParam.toString()
        });
    };

    const fields: IOnboardUserField[] = [
        {
            label: "Name*",
            name: "picture",
            type: "upload",
        },
        {
            label: "Name*",
            name: "name",
            type: "input",
            placeholder: "Type name here",
        },
        {
            type: "mobile-number",
            name: "number",
            label: "Mobile Number*",
            placeholder: "Type mobile number here"
        },
        {
            type: "input",
            name: "email",
            label: "Email*",
            placeholder: "Type email address here"
        },
        {
            label: "Designation*",
            name: "designation",
            type: "input",
            placeholder: "Type designation here",
        },
    ];

    return (
        <Box>
            <CustomDialog
                title={id !== "new" ? "Edit Onboard User" : "Add Onboard User"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "upload") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Box className="center">
                                      <Box height="100px" width="100px" className="upload-img editable" aria-label="upload picture" component="label">
                                        <img src={imageSelected || UploadImg} alt="review" />
                                        <div className="edit-img ">{imageSelected ? "Edit" : "Add"}</div>
                                        <input hidden accept="image/*" type="file" onChange={e => uploadFile(e, field.name)} />
                                      </Box>
                                    </Box>
                                    {errors[field.name] && <span style={{ color: "#d32f2f", marginLeft: "376px", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                                  </Grid>
                                  );
                            } else if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else if (field.type === "mobile-number") {
                                return <Grid key={field.label} item xs={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <MobileNumber
                                            key={field.label}
                                            NumberFieldLabel={field.label}
                                            dialCodeValue={`${phone?.country}:${phone?.iso2}:${phone?.dialCode}`}
                                            onChange={onNumberChange}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            other={prop.field}
                                            placeholder={field.placeholder}
                                        />}
                                    />
                                </Grid>;
                            } else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={field.label}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageOnboardUser;

