import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { IPagination } from "../../../interfaces";
import {
  capitalize,
  createIndex,
  formatDate,
} from "../../../utilities/helper";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { IVendorService, IVendorServiceRow, IVendorServiceState } from "../../../interfaces/vendor/vendor-service";
import { VendorServiceService } from "../../../services/vendor/vendor-service";
import VendorServiceFilters from "./filter";

interface outletProps {
  refetchServices: () => void;
}

const VendorServices = () => {
  let rows: IVendorServiceRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { deleteVendorServices, getVendorServices} = VendorServiceService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IVendorServiceState>({
    deleteWarning: false,
    _vendorService: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      categories: [],
      createdBy: []
    },
    filterCount: 0,
  });

  const vendorsService = useQuery({
    queryKey: ["allUsers", state.pagination.page, state.filters],
    queryFn: () =>
      getVendorServices({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        createdBy: state.filters.createdBy,
        categories: state.filters.categories,
        startDate: state.filters.date?.startDate,    
        endDate: state.filters.date?.endDate 
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (vendorsService.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: vendorsService.data.meta.page,
          totalPages: vendorsService.data.meta.totalPages,
          totalRecords: vendorsService.data.meta.totalRecords,
        },
      }));
    }
  }, [vendorsService.data?.meta]);

  useEffect(() => {
    if (vendorsService.data && vendorsService.data.data && vendorsService.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [vendorsService.data && vendorsService.data.data]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
      
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const createdBy: { key: string, value: string }[] = searchParams.get("created-by") ? JSON.parse(String(searchParams.get("created-by"))) : [];
    const datee: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];

    filterCount += createdBy?.length ? 1 : 0;
    filterCount += datee?.length ? 1 : 0;
    filterCount += category.length ? 1 : 0;

    let date: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (datee?.length) {
      date = {
        startDate: datee[0]?.startDate,
        endDate: datee[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        categories: category.map(item => item.key),
        date,
        createdBy: createdBy.map(item => item.key)
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_vendorService = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _vendorService,
    }));
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onDelete = async () => {
    try {
      const service = await deleteVendorServices({ _id: state._vendorService });
      snackbar(service.message, "info");
      handleDelete();
      vendorsService.refetch();
      outlet?.refetchServices && outlet.refetchServices();
    } catch (error) {
      console.log("Error in vendor delete", error);
    }
  };

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "service_name",
      label: "Service Name",
    },
    {
      id: "category_name",
      label: "Category"
    },
    {
      id: "created_at",
      label: "Created On",
    },
    {
      id: "created_by",
      label: "Created By",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];


  const createRow = (index: number, service: IVendorService, pagination: IPagination) => {
    const action = 
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({ pathname: `${service._id}`, search: searchParams.toString() }),
            disabled: !resourceAllocate("vendor-service.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(service._id),
            disabled: !resourceAllocate("vendor-service.remove"),
          },
        ]}
      />;

    return {
      id: createIndex(pagination, index),
      service_name: capitalize(service.name),
      category_name: capitalize(service._category.name), 
      created_at: formatDate(service.createdAt),
      created_by: capitalize(service.createdBy.name),
      action,
    };
  };

  if (vendorsService.data?.data.length) {
    rows = vendorsService?.data?.data.map((service, i) =>
      createRow(i, service, state.pagination)
    );
  }

  return (
    <div>
      {/* Add Data  */}
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="Add Service"
        onBtnClick={
          resourceAllocate("vendor-service.write") ? () => navigate({
            pathname: "new",
            search: searchParams.toString()
          }) : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <VendorServiceFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="Add service to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Service"
        description="Are you sure you want to delete this service, if a vendor has this service, it will be deleted from there?"
      />
      
      <Outlet context={{ ...outlet, reFetch: vendorsService.refetch }} />
    </div>
  );
};

export default VendorServices;