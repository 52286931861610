import { Box, capitalize, FormControl, MenuItem, Select, Switch, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  createIndex,
  formatDateTime,
} from "../../../utilities/helper";
import { IErrorResponse, IPagination } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import CustomTypography from "../../../components/mui/max-length-limit";
import useResource from "../../../hooks/useResource";
import { IAssessment, IAssessmentRow, IAssessmentState } from "../../../interfaces/configuration/assessments";
import { AssessmentService } from "../../../services/configuration/assessments";
import AssessmentFilters from "./filter";

interface outletProps {
  refetchTests: () => void;
}
const Assessment = () => {
  let rows: IAssessmentRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAssessments, deleteAssessments, updateAssessmentStatus } = AssessmentService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IAssessmentState>({
    deleteWarning: false,
    _question: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      _tests: [],
      _codingChallenges: [],
      status: [],    
      _instruction: [],
      date: [{
        key: "",
        value: "",
        startDate: "",
        endDate: "",
      }]
    },
    filterCount: 0,
  });
  const assessments = useQuery({
    queryKey: ["all-assessments", state.pagination.page, state.filters, state.sort],
    queryFn: () =>
      getAssessments({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        ...state.sort,
        _tests: state.filters._tests ,
        _codingChallenges: state.filters._codingChallenges,
        status: state.filters.status,
        _instruction: state.filters._instruction,
        startDate: state.filters.date.length ? state.filters.date[0].startDate : undefined,
        endDate: state.filters.date.length ? state.filters.date[0].endDate : undefined,
        minDuration: state.filters.duration && state.filters.duration.startDuration,
        maxDuration: state.filters.duration && state.filters.duration.endDuration
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });

      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    assessments.data &&
      assessments.data.data &&
      assessments.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: assessments.data.meta.page,
          totalPages: assessments.data.meta.totalPages,
          totalRecords: assessments.data.meta.totalRecords,
        },
      }));
  }, [assessments.data && assessments.data.data && assessments.data.meta]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);


  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const _tests: { key: string, value: string }[] = searchParams.get("_tests") ? JSON.parse(String(searchParams.get("_tests"))) : [];
    const _codingChallenges: { key: string, value: string }[] = searchParams.get("_codingChallenges") ? JSON.parse(String(searchParams.get("_codingChallenges"))) : [];
    const cpd : { key: string, value: string }[] = searchParams.get("cpd") ? JSON.parse(String(searchParams.get("cpd"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const _instruction: { key: string, value: string}[] = searchParams.get("_instruction") ? JSON.parse(String(searchParams.get("_instruction"))) : [];
    const duration: { startDuration: number, endDuration: number } = searchParams.get("duration") ? JSON.parse(String(searchParams.get("duration"))) : { startDuration: undefined, endDuration: undefined };
    const dateFilter: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    let date: {
      startDate: string;
      endDate: string;
      key: string;
      value: string;
    }[] = [];
    if (dateFilter?.length) {
      date = [{
        key: dateFilter[0]?.key,
        value: dateFilter[0]?.value,
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      }];
    }
    filterCount += _tests.length ? 1 : 0;
    filterCount += _codingChallenges.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += cpd.length ? 1 : 0;
    filterCount += _instruction.length ? 1 : 0;
    filterCount += duration.startDuration || duration.endDuration ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        search,
        _tests: _tests.map(item => item.key),
        _codingChallenges: _codingChallenges.map(item => item.key),
        status: status.map(item => item.key),
        cpd: cpd.map(item => item.key),
        _instruction: _instruction.map(item => item.key),
        duration: {
          startDuration: duration.startDuration,
          endDuration: duration.endDuration
        },
        date,
      },
      filterCount
    }));
  }, [searchParams]);

  useEffect(() => {
    if (assessments.data && assessments.data.data && assessments.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [assessments.data && assessments.data.data]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_test = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _test,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteAssessments({ ids: [state._question] });
      snackbar(deleted.message, "info");
      handleDelete();
      assessments.refetch();
      outlet.refetchTests && outlet.refetchTests();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  const handleSortChange = (columnId: string, direction: "asc" | "desc" | "none") => {
    setState(prevState => ({
      ...prevState,
      sort: {
        [`${columnId}Sort`]: direction
      }
    }));
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "assessment_title",
      label: "Title",
    },
    {
      id: "assessment_description",
      label: "Description",
    },
    {
      id: "assessment_tests",
      label: "MCQ Tests",
    },
    {
      id: "assessment_coding_challanges",
      label: "Coding Challenges",
    },
    {
      id: "assessment_easy_percentage",
      label: "Easy(%)",
    },
    {
      id: "assessment_medium_percentage",
      label: "Medium(%)",
    },
    {
      id: "assessment_hard_percentage",
      label: "Hard(%)",
    },
    {
      id: "duration",
      label: "Duration",
      sortable: true
    },
    {
      id: "assessment_status",
      label: "Status",
    },
    {
      id: "assessment_archived",
      label: "Archived",
    },
    {
      id: "assessment_createdBy",
      label: "Created By",
    },
    {
      id: "createdAt",
      label: "Created At",
      sortable: true
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const onStatusUpdate = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const status = checked ? "ARCHIVED" : "UNARCHIVED";
    try {
      const test = await updateAssessmentStatus({ _id: id, status });
      snackbar(test.message, "info");
      assessments.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };
  
  const createRow = (index: number, assessment: IAssessment, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              searchParams.set("type", "basic-detail");
              navigate({
                pathname: "" + assessment._id,
                search: searchParams.toString(),
              });
            },
            disabled: resourceAllocate("assessment.write") && assessment.status !== "ARCHIVED" && assessment.status !== "PUBLISHED" ? false : true,
          },
        ]}
      />
    );


    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          value={assessment.status}
          disabled={true}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="CREATED">Created</MenuItem>
          <MenuItem value="ARCHIVED">Archived</MenuItem>
          <MenuItem value="PUBLISHED">Published</MenuItem>
          <MenuItem value="DELETED">Deleted</MenuItem>
        </Select>
      </FormControl>;

    const archived = (
      <Switch
        onChange={(event, checked) => onStatusUpdate(event, checked, assessment._id)}
        checked={assessment.status === "ARCHIVED"}
      />
    );

    const psyTests =
      <Tooltip title={(assessment._tests || []).map(sub => sub.name).join(", ")}>
        <span>
          {assessment && assessment._tests && assessment._tests.length ? (
            <>
              {assessment._tests[0].name}{" "}
              {assessment._tests.length > 1 && (
                <span style={{ color: "var(--primary-color)" }}>+{assessment._tests.length - 1}</span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>;

      const codingChallanges =  <Tooltip title={(assessment._codingChallenges || []).map(sub => sub.title).join(", ")}>
      <span>
        {assessment && assessment._codingChallenges && assessment._codingChallenges.length ? (
          <>
            {assessment._codingChallenges[0].title}{" "}
            {assessment._codingChallenges.length > 1 && (
              <span style={{ color: "var(--primary-color)" }}>+{assessment._codingChallenges.length - 1}</span>
            )}
          </>
        ) : (
          ""
        )}
      </span>
    </Tooltip>;


    return {
      id: createIndex(pagination, index),
      assessment_title: (
        <CustomTypography
          limit={50}
          label={assessment.title ?? ""}
          color="primary"
          onClick={
            (resourceAllocate("assessment.read") || resourceAllocate("assessment.read") || resourceAllocate("technical-session.read"))
              ? () => {
                  navigate({
                    pathname: `assessment-info/${assessment._id}`,
                    search: resourceAllocate("assessment.read")
                      ? "type=psy tests"
                      : resourceAllocate("assessment.read")
                      ? "type=technical questions"
                      : resourceAllocate("technical-session.read")
                      ? "type=responses"
                      : ""
                  });
                }
              : undefined
          }
        />
      ),
      assessment_description:
        <Tooltip title={assessment.description ?? ""}>
          <span>
            {assessment.description && assessment.description.length > 60
              ? `${assessment.description.slice(0, 60)}...`
              : assessment.description}
          </span>
        </Tooltip>,
      assessment_tests: psyTests,
      assessment_coding_challanges: codingChallanges,
      assessment_easy_percentage: assessment?.easyPercentage ?? "N/A",
      assessment_medium_percentage: assessment?.mediumPercentage ?? "N/A",
      assessment_hard_percentage: assessment?.hardPercentage ?? "N/A",
      duration: assessment.duration,
      assessment_status: status,
      assessment_archived: archived,
      assessment_createdBy: capitalize(assessment?.createdBy?.name ?? ""),
      createdAt: formatDateTime(assessment?.createdAt),
      action,
    };
  };

  if (assessments.data && assessments.data.data && assessments.data.data.length) {
    rows = ((assessments.data && assessments.data.data && assessments.data.data) || []).map(
      (test, i) => createRow(i, test, state.pagination)
    );
  }

  return (
    <>
      <Header
        className="my-2"
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD NEW ASSESSMENT"
        onBtnClick={
          resourceAllocate("assessment.write") ?
            () => {
              searchParams.set("type", "basic-detail");
              navigate({ pathname: "new", search: searchParams.toString() });
            } :
            undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <AssessmentFilters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
      </Header>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
          onSortChange={handleSortChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Technical Question"
        description="Are you sure you want to delete this question?"
      />
      <Outlet context={{ ...outlet, reFetch: assessments.refetch }} />
    </>
  );
};
export default Assessment;