import Joi from "joi";
import { required } from "../shared";

export const pmsParametersValidations = Joi.object({
  form: Joi.array()
    .items(
      Joi.object({
        _id: Joi.string()
          .optional()
          .allow(""),
        name: Joi.string()
          .trim()
          .min(3)
          .max(60)
          .required()
          .label("Name")
          .messages({
            "string.empty": required,
          }),
        frequency: Joi.string()
          .required()
          .label("Frequency")
          .messages({
            "string.empty": required,
          }),
        weightage: Joi.number()
          .required()
          .label("Weightage")
          .messages({
            "string.empty": required,
            "any.only": required,
          }),
        fieldType: Joi.string()
          .required()
          .label("Type")
          .messages({
            "string.empty": required,
          }),
        evaluator: Joi.string()
          .label("Evaluator")
          .when("type", {
            is: "INDIVIDUAL",
            then: Joi.required()
              .messages({
                "string.empty": required,
              }),
            otherwise: Joi.optional().allow(""),
          }),
        _roles: Joi.array()
          .label("Roles")
          .when("fieldType", {
            is: "ROLE-BASED",
            then: Joi.array()
              .min(1)
              .label("Roles")
              .messages({
                "string.empty": required,
              }),
            otherwise: Joi.optional().allow(""),
          }),
      })
    )
    .required(),
});


export const pmsSubParametersValidations = Joi.object({
  form: Joi.array()
    .items(
      Joi.object({
        _id: Joi.string().optional().allow(""),
        name: Joi.string()
          .required()
          .trim()
          .min(3)
          .max(60)
          .label("Name")
          .messages({
            "string.empty": required,
          }),
        weightage: Joi.number()
          .required()
          .label("Weightage")
          .messages({
            "string.empty": required,
          }),
        netWeightage: Joi.number()
          .required()
          .label("Net Weightage")
          .messages({
            "string.empty": required,
          }),
      })
    )
    .required(),
});