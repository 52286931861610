import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  capitalize,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MenuItem } from "../../../../../components/shared/filter";
import { checkTimePeriod } from "../../../../../utilities/helper";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { PmsParametersService } from "../../../../../services/pms/pms-parameters";
import { useQuery } from "@tanstack/react-query";
import { ITeamEvaluationFilters } from "../../../../../interfaces/pms/team-evaluation";
import { useSelector } from "react-redux";
import { IJobPosition, IRole } from "../../../../../interfaces";
import { PMS_ERROR_MESSAGES } from "../../../../../utilities/messages";

interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const TeamEvaluationFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<ITeamEvaluationFilters>({
    selectedMenu: 0,
    date: [],
    _parameter: [],
    _role: [],
    _jobPosition: [],
  });
  const { getPmsParameters } = PmsParametersService();
  const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>((state) => state.role.list);
  const designations = useSelector<
    { jobPosition: { list: IJobPosition[] } },
    IJobPosition[]
  >((state) => state.jobPosition.list);
  const pmsParameters = useQuery({
    queryKey: ["all-pms-parameters-list"],
    queryFn: () =>
      getPmsParameters({
        pagination: false,
      }),
  });
  const pmsParametersData =
    (pmsParameters && pmsParameters.data && pmsParameters.data.data) || [];
  const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let date: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [];

    if (state.date.length && period === "start") {
      date = state.date.map((e) => ({
        ...e,
        startDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (state.date.length && period === "end") {
      date = state.date.map((e) => ({
        ...e,
        endDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (!state.date.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59);
      date = [
        {
          key: "custom",
          value: "custom",
          startDate: dayjs(e).startOf("day").toISOString(),
          endDate: currentDate.toISOString(),
        },
      ];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      date = [
        {
          key: "custom",
          value: "custom",
          startDate: currentDate.toISOString(),
          endDate: dayjs(e).endOf("day").toISOString(),
        },
      ];
    }

    setState((prevState) => ({
      ...prevState,
      date,
    }));
  };

  useEffect(() => {
    resetFilter();
  }, []);

  useEffect(() => {
    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];
    const _parameter: { key: string; value: string }[] = searchParams.get(
      "_parameter"
    )
      ? JSON.parse(String(searchParams.get("_parameter")))
      : [];
    const _role: { key: string; value: string }[] = searchParams.get("_role")
      ? JSON.parse(String(searchParams.get("_role")))
      : [];
    const _jobPosition: { key: string; value: string }[] = searchParams.get(
      "_jobPosition"
    )
      ? JSON.parse(String(searchParams.get("_jobPosition")))
      : [];

    setState((prevState) => ({
      ...prevState,
      date,
      _parameter,
      _role,
      _jobPosition,
    }));
  }, [searchParams]);

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const deleteChip = (
    name: "date" | "_parameter" | "_role" | "_jobPosition",
    key: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
      startDate?: string;
      endDate?: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      date: [],
      _parameter: [],
      _role: [],
      _jobPosition: [],
    });
  };

  const handleDateListItem = (name: "date", key: string, value: string) => {
    const date = checkTimePeriod(key);

    const payload: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [
      {
        key,
        value,
        startDate: date.startDate,
        endDate: date.endDate,
      },
    ];

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const onApply = () => {
    if (
      state.date.length &&
      new Date(state.date[0].endDate) < new Date(state.date[0].startDate)
    ) {
      snackbar(PMS_ERROR_MESSAGES.en.invalid_start_end_dates, "warning");
      return;
    }

    searchParams.set("date", JSON.stringify(state.date));
    searchParams.set("_parameter", JSON.stringify(state._parameter));
    searchParams.set("_role", JSON.stringify(state._role));
    searchParams.set("_jobPosition", JSON.stringify(state._jobPosition));
    searchParams.set("page", "1");
    navigate({
      pathname: "/team-evaluation/overview",
      search: searchParams.toString(),
    });
    OnClose();
  };

  const onClose = () => {
    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];
    const _parameter: { key: string; value: string }[] = searchParams.get(
      "_parameter"
    )
      ? JSON.parse(String(searchParams.get("_parameter")))
      : [];
    const _role: { key: string; value: string }[] = searchParams.get("_role")
      ? JSON.parse(String(searchParams.get("_role")))
      : [];

    const _jobPosition: { key: string; value: string }[] = searchParams.get(
      "_jobPosition"
    )
      ? JSON.parse(String(searchParams.get("_jobPosition")))
      : [];
    setState((prevState) => ({
      ...prevState,
      date,
      _parameter,
      _role,
      _jobPosition,
    }));
    OnClose();
  };

  const datesFilters = [
    { key: "yesterday", value: "Yesterday" },
    { key: "today", value: "Today" },
    { key: "thisWeek", value: "Weekly" },
    { key: "thisMonth", value: "Monthly" },
    { key: "thisQuarter", value: "Quarterly" },
  ];

  const handleRightListItem = (
    name: "_parameter" | "_role" | "_jobPosition",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];
    const isExist = !!state[name].find((ele) => ele.key === key);
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={OnClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {state.date.length ||
              state._parameter.length ||
              state._role.length ||
              state._jobPosition.length ? (
                <>
                  {state.date.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("date", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state._parameter.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("_parameter", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state._role.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("_role", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state._jobPosition.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("_jobPosition", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
              ) : (
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filters are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton onClick={onClose} style={{ marginRight: "-10px" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid className="filter-box" container>
            <Grid id="left" item xs={5}>
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Date"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state.date}
                />

                <MenuItem
                  index={1}
                  label="Parameter"
                  selectedMenu={state.selectedMenu === 1}
                  onChange={handleLeftListItem}
                  count={state._parameter}
                />

                <MenuItem
                  index={2}
                  label="Role"
                  selectedMenu={state.selectedMenu === 2}
                  onChange={handleLeftListItem}
                  count={state._role}
                />

                <MenuItem
                  index={3}
                  label="Designation"
                  selectedMenu={state.selectedMenu === 3}
                  onChange={handleLeftListItem}
                  count={state._jobPosition}
                />
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid id="right" item xs={6}>
              <List component="nav">
                {state.selectedMenu === 0 && (
                  <>
                    {datesFilters.map((date) => (
                      <ListItemButton
                        key={date.key}
                        selected={
                          !!state.date.find((ele) => ele.key === date.key)
                        }
                        onClick={() =>
                          handleDateListItem(
                            "date",
                            date.key,
                            capitalize(date.value)
                          )
                        }
                      >
                        <ListItemText primary={date.value} />
                        <Checkbox
                          edge="end"
                          checked={
                            !!state.date.find((ele) => ele.key === date.key)
                          }
                        />
                      </ListItemButton>
                    ))}
                    <Box marginTop={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.date[0]?.startDate
                              ? dayjs(state.date[0]?.startDate)
                              : null
                          }
                          onChange={(e) => handleDate(e, "start")}
                          label="Start Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                      <div className="mt-3" />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.date[0]?.endDate
                              ? dayjs(state.date[0]?.endDate)
                              : null
                          }
                          onChange={(e) => handleDate(e, "end")}
                          label="End Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}

                {state.selectedMenu === 1 &&
                  pmsParametersData.map((i) => (
                    <ListItemButton
                      key={i._id}
                      selected={
                        !!state._parameter.find((ele) => ele.key === i._id)
                      }
                      onClick={() =>
                        handleRightListItem(
                          "_parameter",
                          i._id,
                          capitalize(i.name)
                        )
                      }
                    >
                      <ListItemText primary={capitalize(i.name)} />
                      <Checkbox
                        edge="end"
                        checked={
                          !!state._parameter.find((ele) => ele.key === i._id)
                        }
                      />
                    </ListItemButton>
                  ))}

                {state.selectedMenu === 2 &&
                  roles.map((i) => (
                    <ListItemButton
                      key={i._id}
                      selected={!!state._role.find((ele) => ele.key === i._id)}
                      onClick={() =>
                        handleRightListItem("_role", i._id, capitalize(i.name))
                      }
                    >
                      <ListItemText primary={capitalize(i.name)} />
                      <Checkbox
                        edge="end"
                        checked={!!state._role.find((ele) => ele.key === i._id)}
                      />
                    </ListItemButton>
                  ))}

                {state.selectedMenu === 3 &&
                  designations.map((i) => (
                    <ListItemButton
                      key={i._id}
                      selected={
                        !!state._jobPosition.find((ele) => ele.key === i._id)
                      }
                      onClick={() =>
                        handleRightListItem(
                          "_jobPosition",
                          i._id,
                          capitalize(i.name)
                        )
                      }
                    >
                      <ListItemText primary={capitalize(i.name)} />
                      <Checkbox
                        edge="end"
                        checked={
                          !!state._jobPosition.find((ele) => ele.key === i._id)
                        }
                      />
                    </ListItemButton>
                  ))}
              </List>
            </Grid>
          </Grid>

          <Box className="actions-btn" marginTop="8px" textAlign="end">
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button className="ml-2" onClick={onApply}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default TeamEvaluationFilters;
