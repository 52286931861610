import Joi from "joi";
import { required } from "../shared";

const experienceMinGreaterThanexpMax = (maxExp: string, helpers: Joi.CustomHelpers<string>) => {
  const minExp = helpers.state.ancestors[0].minExp; 
  if (minExp && Number(maxExp) < Number(minExp)) {
      return helpers.error("expMin.greaterThanExpMax");
  }
  return maxExp;
};

export const resourceRequestInput = Joi.object({
  hasrequirement: Joi.string()
    .label("Has Requirement")
    .messages({
      "string.empty": required,
    }),

  _id: Joi.string()
    .label("_id")
    .messages({
      "string.empty": required,
    }),

  requestType: Joi.string()
    .label("Request Type")
    .valid("human", "hardware", "software")
    .required()
    .messages({
      "string.empty": required,
    }),

    priorityDuration: Joi.when("requestType", {
      is: Joi.alternatives()
        .try("human", "hardware", "software"),
      then: Joi.string()
      .label("Duration Of Use")
      .allow("")
      .messages({
        "string.empty": required,
      }), 
      otherwise: Joi.string()
      .allow("")
        .label("Priority Duration")
        .optional()
    }),

    jobTitle: Joi.when("requestType", {
      is: "human",
      then: Joi.string()
        .label("Job Title")
        .required()
        .messages({
          "string.empty": required,
        }),
      otherwise: Joi.string()
        .label("Job Title")
        .allow("")
        .optional()
    }),
    
    resourceType: Joi.string()
      .label("Resource Type")
      .optional()
      .allow(""),

    workLocation: Joi.when("requestType", {
      is: "human",
      then: Joi.string()
        .label("Work Location")
        .messages({
          "string.empty": required,
        }),
      otherwise: Joi.string()
        .label("Work Location")
        .allow("")
        .optional()
    }),

      minExp: Joi.when("requestType", {
        is: "human",
        then: Joi.string()
          .label("Min Experience")
          .required()
          .messages({
            "string.empty": required,
          }), 
      otherwise: Joi.string()
        .label("Experience Required")
        .allow("")
        .optional()
    }),

    maxExp: Joi.when("requestType", {
      is: "human",
      then: Joi.string()
        .label("Max Experience")
        .required()
        .messages({
          "string.empty": required,
        }), 
    otherwise: Joi.string()
      .label("Experience Required")
      .allow("")
      .optional()
  })
  .custom(experienceMinGreaterThanexpMax, "Exp Validation")
  .messages({
      "expMin.greaterThanExpMax" : "Max Experience must be greater than Min Experience" 
  }),
    engagementType: Joi.when("requestType", {
      is: "human",
      then: Joi.string()
        .label("Engagement Type")
        .required()
        .messages({
          "string.empty": required,
        }),
      otherwise: Joi.string()
        .label("Engagement Type")
        .allow("")
        .optional()
    }),

  openings: Joi.when("requestType", {
    is: "human",
    then: Joi.string()
      .label("Number of Openings")
      .required()
      .messages({
        "string.empty": required,
      }), 
  otherwise: Joi.string()
    .label("no of openings Required")
    .allow("")
    .optional()
}),

  skillsetRequired: Joi.when("requestType", {
    is: "human",
    then: Joi.array()
    .label("Required Skill Set")
    .items(Joi.string())
    .required()
    .min(1)
    .messages({
      "array.base": required,
      "array.min": required
    }),
    otherwise: Joi.array()
      .label("Skillset Required")
      .allow("")
      .optional()
  }),

  durationNeeded: Joi.when("requestType", {
    is: "human",
    then: Joi.string()
    .allow("")
    .label("Duration Of Use"),
  }),

  justification: Joi.when("requestType", {
    is: Joi.alternatives().try("human", "hardware" , "software"),
    then: Joi.string()
      .label("Reason")
      .required()
      .min(3)
      .max(100)
      .trim()
      .messages({
        "string.empty": required,
        "string.max": "Reason must be at most 60 characters long.",
      }),
 
  }),

  hardwareDetails: Joi.object({
      purchaseType: Joi.string()
        .label("Purchase Type")
        .allow("")
        .messages({
          "string.empty": required,
        }),

      name: Joi.string()
      .label("Hardware Name")
      .when(Joi.object({ requestType: Joi.string()
          .valid("hardware") }), {
        then: Joi.string()
          .required()
          .max(30)
          .messages({
            "string.empty": required,
            "string.max": "Hardware Name must be at most 30 characters long.",
          }),
        otherwise: Joi.string()
          .allow("")
          .optional(),
      }),
        payment: Joi.object().keys({
          amount: Joi.string()
          .allow("")
          .label("Payment Amount")
          .messages({
            "string.empty": required,
          }),
          currency: Joi.string()
          .label("Payment Currency")
          .allow("")
          .messages({
            "string.empty": required,
          }),
          }),
          durationOfUse: Joi.string()
            .label("Duration Of Use")
            .allow("")
            .messages({
              "string.empty": required,
            }), 
    })
    .when("requestType", {
      is: "hardware",
      then: Joi.object().required(),
      otherwise: Joi.object().allow({}).optional(),
    })
    .label("Hardware Details")
    .messages({
      "object.base": required,
    }),


    softwareDetails: Joi.object({
      purchaseType: Joi.string()
        .label("Purchase Type")
        .allow("")
        .messages({
          "string.empty": required,
        }),
        name: Joi.string()
        .label("Software Name")
        .when(Joi.object({ requestType: Joi.string().valid("software") }), {
          then: Joi.string()
            .required()
            .max(30)
            .messages({
              "string.empty": required,
              "string.max": "Software Name must be at most 30 characters long.",
            }),
          otherwise: Joi.string().allow("").optional(),
        }),
        payment: Joi.object().keys({
          amount: Joi.string()
          .allow("")
          .label("Payment Amount")
          .messages({
            "string.empty": required,
          }),
          currency: Joi.string()
          .label("Payment Currency")
          .allow("")
          .messages({
          "string.empty": "Payment currency is required",
          }),
          }),
      durationOfUse: Joi.string()
        .label("Duration Of Use")
        .allow("")
        .messages({
          "string.empty": required,
        }), 
    })

      .when("requestType", {
        is: "software",
        then: Joi.object().required(),
        otherwise: Joi.object().allow({}).optional(),
      })
      .label("Software Details")
      .messages({
        "object.base": required,
      }),

      ccList: Joi.when("requestType", {
        is: Joi.alternatives().try("human", "software", "hardware"),
        then: Joi.array()
          .label("CC List")
          .required()
          .min(1)
          .items(Joi.string())
          .messages({
            "array.base": required,
            "array.min": required,
            "string.email": required,
          }),
        otherwise: Joi.array()
          .label("CC List")
          .allow("")
          .optional(),
      }),
      ccText: Joi.string()
      .label("ccText")
      .allow("")
});
