import { Controller, useForm } from "react-hook-form";
import "./style.scss";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, capitalize, debounce, Dialog, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { IBlogContent, IErrorResponse } from "../../../../../interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useCallback, useEffect, useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import BlogComment from "../../../../../components/blog-comments";
import { Outlet, useBlocker, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { blogContentService } from "../../../../../services/blog/content";
import { useQuery } from "@tanstack/react-query";
import { formatDate, formatTime } from "../../../../../utilities/helper";
import { IBlogContentVersion, IBlogContentVersionData } from "../../../../../interfaces/content-marketing/blog/content";
import useResource from "../../../../../hooks/useResource";
import { ClassicEditor, EventInfo } from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { formatHistoryDate } from "../../../../../utilities/helper";
import AuthorService from "../../../../../services/content/author";
import CustomLabel from "../../../../../components/mui/custom-label";
import { IAuthorData } from "../../../../../interfaces/content/author";
import { blogImageService } from "../../../../../services/blog";
import useDebounce from "../../../../../hooks/useDebounce";
import { blogScoreService } from "../../../../../services/blog/scores";
import { joiResolver } from "@hookform/resolvers/joi";
import { blogContentValidation } from "../../../../../validations/content-marketing/content";
import CustomDialog from "../../../../../components/mui/dialog";
import { CMS_ERROR_MESSAGES } from "../../../../../utilities/messages";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../../components/shared/mui-tabs";
import CKeditor from "../../../../../components/ck-editor";

interface props {
    activeAction: boolean;
    setActiveAction: (value: boolean) => void;
    commentActiveAction: boolean;
    setCommentActiveAction: (value: boolean) => void;
}

const Content: FC<props> = ({ activeAction, setActiveAction, commentActiveAction, setCommentActiveAction }) => {
    const { control, getValues, setValue, handleSubmit, watch, reset, trigger, formState: { errors } } = useForm<IBlogContent>({
        resolver: joiResolver(blogContentValidation),
        defaultValues: {
            author: "",
            readTime: "",
            content: "",
            metaTitle: "",
            metaDescription: ""
        }
    });
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const navigate = useNavigate();
    const { addBlogContent, blogContentAutoSave, getBlogContent, getBlogContentVersions } = blogContentService();
    const { getBlogKeywordDensity } = blogScoreService();
    const { getContentAuthors } = AuthorService();
    const { resourceAllocate } = useResource();
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [editorInstance, setEditorInstance] = useState<ClassicEditor>();
    const [editorContent, setEditorContent] = useState<string>("");
    const [searchParam] = useSearchParams();
    const searchRecord = useDebounce(editorContent, 1000);

    const getKeywordDensity = useQuery({
        queryKey: ["keyword-density"],
        queryFn: () => getBlogKeywordDensity({ blogId: id }),
    });

    useEffect(() => {
        const saveRecord = async () => {
            if (id === "new") return;
            if (!initialRender && searchRecord !== blogContent?.data?.data?.content) {
                await blogContentAutoSave({ _blog: id, content: searchRecord });
                getKeywordDensity.refetch();
            }
        };
        saveRecord();
    }, [searchRecord]);
    const hitQuery = id !== "new" ? true : false;
    const blogContent = useQuery({
        queryKey: ["blog-content", id],
        queryFn: () => getBlogContent({ _blog: id }),
        enabled: hitQuery
    });

    // Saved For Future Use

    // const blogAssignment = useQuery({
    //     queryKey: ["blogs-assignment", id],
    //     queryFn: () => getBlogAssignment({ blogId: id }),
    // });
    // const submissionDate = blogAssignment?.data?.data?.writer?.submissionDate;
    // const isDateInPast = (dateString: string | undefined) => {
    //     if (!dateString) {
    //         return false;
    //     }
    //     const givenDate = new Date(dateString);
    //     const currentDate = new Date();
    //     return givenDate < currentDate;
    // };
    const blogContentVersions = useQuery({
        queryKey: ["blog-content-versions", id],
        queryFn: () => getBlogContentVersions({ _blog: id, pagination: true }),
        enabled: hitQuery
    });
    const blogContentVersionsData = blogContentVersions && blogContentVersions.data && blogContentVersions.data.data || [];

    const authorList = useQuery({
        queryKey: ["author-list"],
        queryFn: () => getContentAuthors({ blogId: id, pagination: false }),
        enabled: hitQuery
    });

    useEffect(() => {
        if (activeAction) {
            blogContentVersions.refetch();
        }
    }, [activeAction]);
    useEffect(() => {
        if (blogContent?.data?.data && id !== "new") {
            setValue("author", blogContent?.data?.data?.author || "");
            trigger("author");
            setValue("readTime", blogContent?.data?.data?.readTime || "");
            setValue("content", blogContent?.data?.data?.content || "");
            trigger("content");
            setEditorContent(blogContent?.data?.data?.content || "");
            setInitialRender(true);
            setValue("metaTitle", blogContent?.data?.data?.metaTitle || "");
            setValue("metaDescription", blogContent?.data?.data?.metaDescription || "");
            setSubmitDisabled(() => false);
        }
    }, [blogContent?.data?.data]);

    const handleSubmitClick = async () => {
        navigate({
            pathname: `/blog/manage/${id}/checklistWriter`,
            search: searchParam.toString()
        });
    };

    const [fullScreen, setFullScreen] = useState(false);

    const handleFullScreen = () => {
        setFullScreen(true);
    };

    const onSubmit = async (data: IBlogContent) => {
        try {
            if (id === "new") {
                snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
            } else {
                setSaveDisabled(true);
                const payload = {
                    ...data,
                    _blog: id
                };
                if (payload.readTime === "") {
                    delete payload.readTime;
                }
                const response = await addBlogContent(payload);
                snackbar(response.message, "info");
                navigate(`/blog/manage/${id}?type=content`);
            }
            blogContentVersions.refetch();
            blogContent.refetch();
            setInitialRender(true);
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            navigate(`/blog/manage/${id}?type=content`);
            console.log("Error in Adding Content", error);
        }
    };

    const [selectedVersion, setSelectedVersion] = useState<number | null>(null);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [selectedAccordion, setSelectedAccordion] = useState<number | null>(null);
    const [saveDisabled, setSaveDisabled] = useState<boolean | null>(true);
    const [submitDisabled, setSubmitDisabled] = useState<boolean | null>(true);
    const [confirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

        useBlocker(
            () => {
                if (!saveDisabled) {
                    setShowConfirmationDialog(true);
                    return true;
                } else {
                    return false;
                }
            }
        );
    useEffect(() => {
        const subscription = watch((values) => {
            const allFieldsEmpty = Object.values(values).every(value => value === "");
            if (allFieldsEmpty) {
                setSaveDisabled(() => true);
                setSubmitDisabled(() => true);
                return;
            }

            const edited = Object.keys(values).some(name => {
                if (name === "content" || name === "author" || name === "readTime" || name === "metaTitle" || name === "metaDescription") {
                    const fieldValue = values[name] as string;
                    if (blogContent?.data?.data) {
                        const contentFromAPI = blogContent?.data?.data[name] ?? "";
                        return fieldValue !== contentFromAPI;
                    } else {
                        return fieldValue !== "";
                    }
                }
                return false;
            });
            // save for future use.
            // if (user._role.name === "writer") {
            //     const isPastSubmissionDate = isDateInPast(submissionDate);
            //     setSaveDisabled(isPastSubmissionDate || !edited);
            // } else {
            //     setSaveDisabled(() => !edited);
            // }

            setSaveDisabled(() => !edited);
        });
        return () => subscription.unsubscribe();
    }, [watch, blogContent?.data?.data]);

    const handleCloseFullScreen = () => {
        setFullScreen(false);
    };

    const handleClose = () => {
        setSelectedVersion(null);
        setExpandedIndex(null);
        setSelectedAccordion(null);
        setActiveAction(!activeAction);
    };

    const handleAccordionTabClick = (index: number) => {
        setSelectedAccordion(index);
        setExpandedIndex(index !== expandedIndex ? index : null);
    };

    const [currentVersionData, setCurrentVersionData] = useState<string>("");
    const [previousVersionData, setPreviousVersionData] = useState<string>("");

    const getPreviousVersionDoc = (dataArray:IBlogContentVersion[], currentVersion: number) => {
        
        for (const dateEntry of dataArray) {
          const documents = dateEntry.data;
          for (let i = 0; i < documents.length; i++) {
            const previousVersionDoc = documents.find(doc => doc.version === currentVersion - 1);
            if (previousVersionDoc) {
              return previousVersionDoc.content;
            }
          }
        }
        return "";
      };

    const handleAccordionClick = (itemIndex: number, index: number) => {
        setCurrentVersionData(blogContentVersionsData[index]?.data[itemIndex]?.content || "");
        const getPreviosVersion = getPreviousVersionDoc(blogContentVersionsData, blogContentVersionsData[index]?.data[itemIndex].version);
        setPreviousVersionData(getPreviosVersion);
        navigate({ pathname: `/blog/manage/${id}/view-content/${blogContentVersions?.data?.data[index]?.data[itemIndex]?._id}/`, search: searchParam.toString() });
    };

    const handleProductChange = async (
        value: IAuthorData | null,
    ) => {
        if (value) {
            setValue("author", value._id);
            trigger("author");
        }
    };

    const handleFirstInteraction = useCallback(() => {
        if (initialRender) {
            setInitialRender(false);
        }
    }, [initialRender]);

    const handleContentChange = useCallback(
        debounce((editor) => {
            const data = editor.getData();
            setValue("content", data);
            setEditorContent(data);
        }, 300),
        []
    );

    const onEditorReady = (editor: ClassicEditor) => {
        setEditorInstance(editor);
    };
    const onEditorChange = (_: EventInfo<string>, editor: ClassicEditor) => {
        handleFirstInteraction();
        handleContentChange(editor);
    };
    const { getBlogImageList } = blogImageService();
    const blogImageList = useQuery({
        queryKey: ["blog-images-list", id],
        queryFn: () =>
          getBlogImageList({
            blogId: id,
            pagination: false,
          }),
        enabled: id !== "new"
      });
    const images = (blogImageList && blogImageList.data && blogImageList.data.data) ?? blogImageList.data?.data;
    const [showImagesDialog, setShowImagesDialog] = useState(false);

    const handleImageClick = () => {
        setShowImagesDialog(true);
    };

    const addImage = (url: string) => {
        if (editorInstance) {
            editorInstance.model.change(writer => {
                const selection = editorInstance.model.document.selection;
                const position = selection.getFirstPosition();

                if (position) {
                    const imageElement = writer.createElement("imageBlock", {
                        src: url,
                        alt: "Image"
                    });
                    writer.insert(imageElement, position);
                    writer.setAttribute("imageStyle", "full", imageElement);
                } 

            });
            const data = editorInstance.getData();
            setEditorContent(data);
            setValue("content", data);
        }
        setShowImagesDialog(false);
    };

    return (
        <div className="blog-content">

            <Grid container spacing={4}>

                {/* content details  */}
                <Grid item {...((activeAction || commentActiveAction) ? { xs: 8 } : { xs: 12 })}>
                    <div className="sub-title">
                        <Typography className="title mb-2" variant="body2">Basic Details</Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        control={control}
                                        name={"author"}
                                        render={(prop) => <Autocomplete
                                            className="disable-text"
                                            options={authorList?.data?.data?.filter(data => data?._user?.name && data?._user?.name!=="").map(
                                                (data) => (data
                                            )) || []}
                                            clearIcon={null}
                                            getOptionLabel={(option) => capitalize(option?._user?.name ?? "")}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                error={errors["author"] ? true : false}
                                                helperText={errors["author"]?.message}
                                                size={"small"}
                                                variant={"outlined"}
                                                label={<CustomLabel label="Author" required/>}
                                                placeholder={"Please Select Author"}
                                            />}
                                            {...prop.field}
                                            value={authorList?.data?.data?.find(data => data._id === getValues("author")) || null}
                                            onChange={(e, value) => {
                                                handleProductChange(value);
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option?._id}>
                                                    {capitalize(option?._user?.name ?? "")}
                                                </li>
                                            )}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Box display="flex" alignItems="center">
                                        <Controller
                                            control={control}
                                            name={"readTime"}
                                            render={(prop) => <TextField
                                                label={<CustomLabel label="Read Time" required/>}
                                                className="disable-text"
                                                variant="outlined"
                                                size="small"
                                                error={!!errors["readTime"]}
                                                helperText={errors["readTime"]?.message}
                                                onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                                inputProps={{
                                                    pattern: "[0-9]*",
                                                    inputMode: "numeric",
                                                    maxLength: 5,
                                                    onInput: (e) => {
                                                        e.preventDefault();
                                                        const inputValue = e.currentTarget.value;
                                                        const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                                                        const truncatedValue = sanitizedValue.slice(0, 5);
                                                        e.currentTarget.value = truncatedValue;
                                                        prop.field.onChange(truncatedValue);
                                                    },
                                                    required: true,
                                                }}
                                                {...prop.field}
                                            />}
                                        />
                                        <Typography className="ml-3" variant="body2">Minutes</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Typography className="title mt-3 mb-2" variant="body2">Content</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    <div className="editor-container mr-2 mb-2">
                                        <CKeditor 
                                            editorContent = {editorContent} 
                                            onEditorChange = {onEditorChange} 
                                            onEditorReady = {onEditorReady}
                                            showDynamicKeywords = {false} 
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Box className="full-screen-box mr-2">
                                <Box display="flex">
                                    <Typography variant="body1">Total Words:</Typography>
                                    <Typography className="ml-2" variant="body1" >{getKeywordDensity?.data?.data?.totalWords}</Typography>
                                </Box>

                                <Box className="images-box">
                                    <Button variant="outlined" onClick={handleImageClick}>
                                        Add images
                                    </Button>

                                    <Button variant="outlined" onClick={handleFullScreen}>
                                        Edit in full screen
                                    </Button>
                                </Box>

                            </Box>


                            <Typography className="title mt-2 mb-2" variant="body2">SEO</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        control={control}
                                        name={"metaTitle"}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label="Meta Title" required={true} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Type meta title"
                                            error={errors["metaTitle"] ? true : false}
                                            helperText={errors["metaTitle"]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Controller
                                        control={control}
                                        name={"metaDescription"}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label="Meta Description" required={true} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Type meta description"
                                            error={errors["metaDescription"] ? true : false}
                                            helperText={errors["metaDescription"]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                            <div className="mt-4">
                                <Button
                                    variant="outlined"
                                    onClick={() => reset()}
                                    disabled={resourceAllocate("cms-blog-content.write") ? false : true}
                                >Discard</Button>
                                <Button
                                    type="submit"
                                    className="ml-2"
                                    disabled={resourceAllocate("cms-blog-content.write") && !saveDisabled ? false : true}
                                >Save Changes</Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSubmitClick}
                                    className="ml-2"
                                    disabled={resourceAllocate("cms-blog-content.write") && saveDisabled && !submitDisabled ? false : true}
                                >Submit</Button>
                            </div>
                        </form>
                    </div>
                </Grid >
                {/* content vision history */}
                {
                    activeAction && <Grid item xs={4}>
                        <div className="timeline">
                            <header>
                                <Typography variant="body1">Version History</Typography>
                                <IconButton color="primary"><CloseIcon color="action" onClick={() => handleClose()} /></IconButton>
                            </header>

                            <div className="note-container">

                                {blogContentVersions?.data?.data?.map((item: IBlogContentVersion, index: number) => (

                                    <div key={index} className="timelineBox">
                                        <div className="mt-1 mb-2" style={{ marginLeft: "12px" }}>
                                            <Typography variant="caption" className="history" >{formatHistoryDate(item._id)}</Typography>
                                        </div>
                                        <Accordion sx={{ border: "none", boxShadow: "none" }} expanded={expandedIndex === index}>
                                            {item?.data?.map((itemData: IBlogContentVersionData, itemIndex: number) => (
                                                <div key={itemIndex} className="timelineBox" >
                                                    {itemIndex === 0 ?
                                                        <div >
                                                            <AccordionSummary
                                                                sx={{
                                                                    backgroundColor:
                                                                        (index === selectedAccordion && itemIndex === selectedVersion) ||
                                                                            (selectedAccordion === null && selectedVersion === null && itemData?.currentVersion)
                                                                            ? "#f5f5f5"
                                                                            : "white",
                                                                }}
                                                                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} onClick={() => handleAccordionTabClick(index)} />}>
                                                                <div className="accordian-details" onClick={() => handleAccordionClick(itemIndex, index)}>
                                                                    <div className="date">
                                                                        <Typography variant="caption" style={{ fontWeight: "bolder", marginRight: "4px" }}>{formatDate(itemData?.date)}</Typography>
                                                                        { !itemData?.currentVersion && <Typography variant="caption">{formatTime(itemData?.date)}</Typography> }

                                                                    </div>
                                                                {itemData?.currentVersion && <Typography variant="caption" style={{ color: "var(--primary-color)" }}>current Version</Typography>}
                                                                    <Typography variant="caption">
                                                                        {capitalize((itemData?.updatedBy?.name || itemData?.createdBy?.name) ?? "")}
                                                                    </Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                        </div>
                                                        :
                                                        <div
                                                            style={{ backgroundColor: itemIndex === selectedVersion ? "#f5f5f5" : "white", }}>

                                                            <AccordionDetails
                                                                style={{ marginLeft: "20px", cursor: "pointer" }}

                                                            >
                                                                <div style={{ display: "flex", flexDirection: "column" }} onClick={() => handleAccordionClick(itemIndex, index)}>
                                                                    <div style={{ display: "flex", flexDirection: "row" }}>

                                                                        <Typography variant="caption" style={{ fontWeight: "bolder", marginRight: "4px" }}>{formatDate(itemData?.date)}</Typography>
                                                                        <Typography variant="caption">{formatTime(itemData?.date)}</Typography>

                                                                    </div>
                                                                    <Typography variant="caption">
                                                                        {capitalize((itemData?.updatedBy?.name || itemData?.createdBy?.name) ?? "")}
                                                                    </Typography>
                                                                </div>
                                                            </AccordionDetails>

                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid>
                }
                {
                    commentActiveAction && <Grid item xs={4}>
                        <BlogComment
                            commentType="BLOG_CONTENT"
                            activeAction={commentActiveAction}
                            setActiveAction={setCommentActiveAction}
                        />
                    </Grid>
                }
            </Grid >
            <div>
                <Dialog
                    fullScreen
                    open={fullScreen}
                    className="fullScreenEditor"
                    onClose={handleCloseFullScreen}
                >
                    <CKeditor 
                        editorContent = {editorContent} 
                        onEditorChange = {onEditorChange} 
                        onEditorReady = {onEditorReady}
                        showDynamicKeywords = {false}
                    />
                    <Button variant="contained" onClick={handleCloseFullScreen} style={{borderRadius: 0}}>
                        Exit full screen
                    </Button>
                </Dialog>
            </div>
            <BootstrapDialog
                maxWidth="md"
                open={showImagesDialog}
                onClose={() => setShowImagesDialog(false)}
            >
                <BootstrapDialogTitle onClose={() => setShowImagesDialog(false)}>
                    <Box display="flex" alignItems="center">
                        Select Image
                    </Box>
                </BootstrapDialogTitle>
                {(images && images.length) ? 
                <div className="images-blogs-container">
                    <Grid container spacing={2}>
                        {images.map((image, i) => <Grid key={i} item xs={6} sm={4} md={3} lg={3}>
                            <div className="image-blogs-wrapper">
                                <img src={image?.imageId?.imageUrl} className="w-100 h-100 cursor-pointer" onClick={() => addImage(image?.imageId?.imageUrl)} />
                            </div>
                            <Tooltip title={`${image?.imageId?.name} (Alt: ${image.alternativeText})`} arrow>
                                <Typography className="image-name" variant="body1" noWrap textAlign={"center"}>
                                    {`${image?.imageId?.name} (Alt: ${image.alternativeText})`.length > 50
                                    ? `${`${image?.imageId?.name} (Alt: ${image.alternativeText})`.slice(0, 50)}…`
                                    : `${image?.imageId?.name} (Alt: ${image.alternativeText})`}
                                </Typography>
                            </Tooltip>
                        </Grid>)}
                    </Grid> 
                </div>  
                :
                <Box className="center mb-5">Unfortunately, no images are currently available!</Box>
                }
            </BootstrapDialog>
            
            <CustomDialog
                title="Unsaved Changes"
                isOpen={confirmationDialog}
                size = "sm"
                onClose={() => setShowConfirmationDialog(false)}
                disabled = {true}
            >
                <div className="center">You have unsaved changes. Please save the changes before leaving the tab.</div>
            </CustomDialog>
            <Outlet context={{ reFetchContent: blogContent.refetch, reFetchVersions: blogContentVersions.refetch, getPreviousVersionData: previousVersionData, getCurrentVersionData: currentVersionData }} />
        </div >
    );
};

export default Content;