import "./style.scss";
import { useQuery } from "@tanstack/react-query";
import Logo from "../../../../assets/images/logo.png";
import { organisationConfigurationDataService } from "../../../../services/organisation-themes";
import { Box, Typography } from "@mui/material";

const RequestSent = () => {
  const { getOrganisationThemeConfiguration } =
    organisationConfigurationDataService();
  const organisationConfiguration = useQuery({
    queryKey: ["organisation-configuration"],
    queryFn: () => getOrganisationThemeConfiguration(),
  });
  const orgThemeData =
    organisationConfiguration &&
    organisationConfiguration.data &&
    organisationConfiguration.data.data;
  const logo =
    orgThemeData && orgThemeData.logo
      ? String(process.env.REACT_APP_S3_BASE_URL) + orgThemeData.logo
      : Logo;

  return (
    <Box className="request-sent-container">
        <Box className="message-container">
        <Box className="org-logo">
            <img src={logo} alt="Logo" className="org-img" />
        </Box>
        <Typography variant="h5">
            Your request has been sent. Please wait for the response or contact your
            reporting manager to make your account and then try again.{" "}
        </Typography>
        </Box>
    </Box>
  );
};

export default RequestSent;
