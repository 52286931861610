import { IJobField, IJob, IJobTitle, IErrorResponse, IJobForm } from "../../../../interfaces";
import { Box, Grid, TextField, FormHelperText, Chip, Button } from "@mui/material";
import CustomDialog from "../../../../components/mui/dialog";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { IUser, ITeam, IEngagement } from "../../../../interfaces";
import { capitalize } from "../../../../utilities/helper";
import { joiResolver } from "@hookform/resolvers/joi";
import { JobValidation } from "../../../../validations";
import useSnackbar from "../../../../hooks/useSnackbar";
import { JobService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState, KeyboardEvent } from "react";
import CustomLabel from "../../../../components/mui/custom-label";
import SearchSelect from "../../../../components/mui/search-select";
import CKeditor from "../../../../components/ck-editor";
import { ClassicEditor, EventInfo } from "ckeditor5";


interface outletProps {
    reFetch: () => void;
    refetchJobss: () => void;
}

const ManageJob = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [enteredValue, setEnteredValue] = useState<string[]>([]);
    const [searchParam] = useSearchParams();
    const [apiResponse, setApiResponse] = useState("");
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { addJob, generateJobDescription, getJob, updateJob, getJobSlugSugession } = JobService();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const jobTitles = useSelector<{ jobTitle: { list: IJobTitle[] } }, IJobTitle[]>(state => state.jobTitle.list);
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(state => state.team.list);
    const job = useQuery({ queryKey: [hitQuery], queryFn: () => getJob({ _id: id }), enabled: hitQuery });
    const engagementTypes = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(state => state.engagementType.list) || [];
    const { handleSubmit, control, getValues, setValue, resetField, trigger, watch, formState: { errors } } = useForm<IJobForm>({
        resolver: joiResolver(JobValidation),
        defaultValues: {
            title: "",
            department: "",
            assignTo: "",
            minimumExperience: "",
            maximumExperience: "",
            location: "WFO",
            openings: "",
            status: "OPEN",
            type: "",
            shortDescription: "",
            description: "",
            jobCategory: "",
            skill: "",
            urlSlug: "",
            skills: [],
        }
    });

    const [hitSlugQuery, setHitSlugQuery] = useState(false);
    const [editorInstance, setEditorInstance] = useState<ClassicEditor | null>(null); // Store CKEditor instance

    const jobSlugSuggestion = useQuery({ queryKey: ["slug-suggestions"], queryFn: () => getJobSlugSugession({ title: getValues("title").trim(), type: getValues("type").trim(), department: getValues("department").trim() }), enabled: hitSlugQuery });
    useEffect(() => {
        if (getValues("title") !== "" && getValues("type") !== "" && getValues("department") !== "") {
            setHitSlugQuery(true);
            jobSlugSuggestion.refetch();
        } else {
            setHitSlugQuery(false);
        }
    }, [getValues("title"), getValues("type"), getValues("department")]);

    const getJobtileOptions = () => 
        jobTitles.filter(title => title && title._department && title._department._id === getValues("departmentId") && !title.isDeleted);
    

    useEffect(() => {
        if (jobSlugSuggestion?.data?.data) {
            setValue("urlSlug", jobSlugSuggestion?.data?.data ?? "");
        }
    }, [jobSlugSuggestion.data]);

    useEffect(() => () => {
        jobSlugSuggestion.remove();
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "departmentId") {
                const department = teams.find(team => team._id === value.departmentId);
                setValue("department", department?.name || "");
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
            if (id!=="new" && job?.data?.data) {
                setValue("title", job?.data?.data?.titleId?.name ?? "");
                setValue("department", job?.data?.data?.department ?? "");
                setValue("departmentId", job?.data?.data?.departmentId ?? "");
                setValue("assignTo", job?.data?.data?.assignTo?._id ?? "");
                setValue("minimumExperience", job?.data?.data?.minimumExperience ?? "");
                setValue("urlSlug", job?.data?.data?.urlSlug ?? "");
                setValue("maximumExperience", job?.data?.data?.maximumExperience ?? "");
                setValue("location", job?.data?.data?.location ?? "");
                setValue("openings", job?.data?.data?.openings ?? "");
                setValue("type", job?.data?.data?.type ?? "");
                setValue("shortDescription", job?.data?.data?.shortDescription ?? "");
                setValue("description", job?.data?.data?.description ?? "");
                editorInstance && setEditorData(job?.data?.data?.description || "");
                trigger("description");
                setValue("jobCategory", job?.data?.data?.jobCategory ?? "");
        }
    }, [id, job.data, editorInstance]);

    const onSubmit = async (data: IJobForm) => {
        //In below line are doing Object destructuring bcz we are not using all fields of IJob
        /*eslint-disable-next-line*/
        const { skill, skills, ...payload } = data;
        delete payload.urlSlug;
        payload["titleId"] =jobTitles.filter((data)=> data.name ===  getValues("title"))[0]._id;
        try {
            if (id === "new") {
                const add = await addJob(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/careers/jobs",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchJobss && outlet.refetchJobss();
            } else {
                const newPayload = { ...payload, _id: id };
                delete newPayload.urlSlug;
                const update = await updateJob(id ? id : "", newPayload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/careers/jobs",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchJobss && outlet.refetchJobss();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/careers/jobs",
            search: searchParam.toString()
        });
    };

    const [editorData, setEditorData] = useState("");

    const onEditorReady = (editor: ClassicEditor) => {
    setEditorInstance(editor);
    };

    const handleEditorChange = (event: EventInfo<string>, editor: ClassicEditor) => {
    const data = editor.getData();
    setEditorData(data);
    setValue("description", data);
    };

    const fields: IJobField[] = [
        {
            label: "Department",
            name: "departmentId",
            type: "select",
            required: true,
            options: teams,
            displayFieldKey: "name",
            storeFieldKey: "_id",
            capitalize: true
        },
        {
            label: "Job Title",
            name: "title",
            type: "select",
            required: true,
            options: getJobtileOptions(),
            displayFieldKey: "name",
            storeFieldKey: "name",
            capitalize: true,
            disabled: !getValues("departmentId")
        },
        {
            label: "Engagement Type",
            name: "type",
            type: "select",
            required: true,
            options: engagementTypes,
            displayFieldKey: "name",
            storeFieldKey: "name",
            capitalize: true,
        },
        {
            label: "Assign To",
            name: "assignTo",
            type: "select",
            required: true,
            options: users,
            displayFieldKey: "name",
            storeFieldKey: "_id",
            displayUserName: true
        },
        {
            label: "Minimum Experience (Years)",
            name: "minimumExperience",
            type: "input",
            placeholder: "Type minimum experience here",
            required: true
        },
        {
            label: "Maximum Experience (Years)",
            name: "maximumExperience",
            type: "input",
            placeholder: "Type maximum experience here",
            required: true
        },
        {
            label: "Work Location",
            name: "location",
            type: "select",
            options: [
                { key: "WFH", value: "WFH" },
                { key: "WFO", value: "WFO" },
            ],
            displayFieldKey: "value",
            storeFieldKey: "key",
            required: true
        },
        {
            label: "Number Of Openings",
            name: "openings",
            type: "input",
            placeholder: "Type number of opening here",
            required: true
        },
        {
            label: "Short Description ( 0/200 word )",
            name: "shortDescription",
            type: "input",
            placeholder: "Type short description here",
            required: true
        },
        {
            label: "Job Category",
            name: "jobCategory",
            type: "select",
            required: true,
            options: [
                { key: "TECHNICAL", value: "Technical" },
                { key: "NON_TECHNICAL", value: "Non-Technical" },
            ],
            displayFieldKey: "value",
            storeFieldKey: "key"
        },
        {
            label: "Skills",
            name: "skill",
            type: "input",
            placeholder: "Type job title here"
        },
        {
            label: "Slug",
            name: "urlSlug",
            disabled: true,
            type: "input",
            placeholder: "Type url slug here",
            required: true
        },
        {
            label: "Long Description*  (0/3000 word)",
            name: "description",
            type: "text-editor",
        }
    ];


    const addChip = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        if (key === "skill") {
            const enteredJobTitle = getValues(key) ? String(getValues(key)) : "";
            if (enteredJobTitle.trim() !== "" && e.key === "Enter") {
                if (key === "skill") {
                    const prev = getValues("skills") ? getValues("skills") : [];
                    const updatedValue = [...prev, enteredJobTitle];
                    setEnteredValue(updatedValue);
                    setValue("skills", [...new Set(updatedValue)]);
                    resetField(key);
                }
            }
        }
    };


    const removeChip = (value: string) => {
        let payload = getValues("skills");
        payload = payload.filter(skill => skill !== value);
        setValue("skills", payload);
        const items = enteredValue.filter(item => item !== value);
        setEnteredValue(items);
    };

    const isValidField = (fieldName: string, errorMessage: string) => {
        if (!`${getValues(fieldName as keyof IJob)}`.length) {
            snackbar(errorMessage, "warning");
            return false;
        }
        if ((fieldName === "skills" && !getValues("skills").length)) {
            snackbar(errorMessage, "warning");
            return false;
        }
        return true;
    };

    const handleClick = async () => {
        const requiredFields = ["title", "type", "minimumExperience", "maximumExperience", "location", "openings", "skills"];

        for (const field of requiredFields) {
            if (!isValidField(field, `Please provide ${field === "skills" ? "at least one skill" : capitalize(field)}`)) {
                return;
            }
        }
        const payload = {
            jobTitle: getValues("title"),
            jobLocation: getValues("location"),
            jobType: getValues("type"),
            minimumExperienceLevel: getValues("minimumExperience"),
            maximumExperienceLevel: getValues("maximumExperience"),
            numberOfOpenings: getValues("openings"),
            skills: getValues("skills"),
        };

        try {
            const response = await generateJobDescription(payload);
            snackbar("Job Description generated successfully", "info");
            setApiResponse(response?.data);
        } catch (error) {
            const err = error as IErrorResponse;
            console.log("Error in job description generation", err);
            snackbar(err.data.message, "warning");
        }
    };


    return (
        <Box
            onKeyDown={e => {
                const isInputField = (e.target as HTMLElement).tagName === "INPUT";
                if (isInputField && e.key === "Enter") e.preventDefault();
            }}>
            <CustomDialog
                title={id !== "new" ? "Edit Job" : "Add Job"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input" && field.name !== "skill") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            disabled={field?.disabled}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}



                                        />}
                                    />
                                </Grid>
                                );
                            }
                            else if (field.type === "input" && field.name === "skill") {
                                return (
                                    <Grid key={field.label} item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={<CustomLabel label={field.label} required={field?.required} />}
                                                className="disable-text"
                                                variant="outlined"
                                                size="small"
                                                placeholder={field.placeholder}
                                                error={errors[field.name] ? true : false}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setEnteredValue([newValue]);
                                                    prop.field.onChange(e);
                                                }}
                                                onKeyUp={e => addChip(e, field.name)}
                                            />}
                                        />
                                        {
                                            <Box>
                                                {
                                                    field.name === "skill" && getValues("skills") &&
                                                    getValues("skills").map(skill => <Chip
                                                        key={skill}
                                                        label={skill}
                                                        onDelete={() => removeChip(skill)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                    />)
                                                }
                                            </Box>

                                        }
                                    </Grid>
                                );
                            }
                            else if (field.name != "description" && field.type === "select") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <SearchSelect
                                        name={field.name}
                                        label={<CustomLabel label={field.label} required={field?.required} />}
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                        options={field.options}
                                        displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                                        storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                                        displayUserName={field.displayUserName}
                                        capitalize={field.capitalize}
                                        trigger={trigger}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                    {
                        <Grid item xs={12}>
                            <Button onClick={() => handleClick()}>
                                Generate Job Description
                            </Button>
                        </Grid>
                    }
                    {
                        <Grid item xs={12}>
                            <Box className={errors["description"] ? "tiny-error" : ""}>
                                    <CKeditor editorContent={ apiResponse !== "" ? apiResponse : editorData } onEditorChange= {handleEditorChange} onEditorReady={onEditorReady} showDynamicKeywords={false} />
                                {errors["description"] &&
                                    <FormHelperText sx={{ margin: "4px 14px 0px 14px" }}
                                        error={errors["description"] ? true : false}>{errors["description"]?.message}
                                    </FormHelperText>}
                            </Box>
                        </Grid>

                    }

                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageJob;
