import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FC, useState } from "react";
import "./style.scss";

import {
    ClassicEditor,
    Base64UploadAdapter,
    List,
    ListProperties,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    Bold,
    CKBox,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    LinkImage,
    Paragraph,
    PictureEditing,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Underline,
    Undo,
    EventInfo
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { useQuery } from "@tanstack/react-query";
import { MenuItem, Select } from "@mui/material";
import { emailTemplateKeywordsService } from "../../services/configuration/email-template-keywords";
import useResource from "../../hooks/useResource";
interface props {
    editorContent: string | undefined;
    onEditorChange: (event: EventInfo<string>,editor: ClassicEditor) => void;
    onEditorReady?: (editor: ClassicEditor) => void;
    showDynamicKeywords: boolean;
    type?: [string];
}
 

const CKeditor: FC<props> = ({editorContent, onEditorChange, onEditorReady, showDynamicKeywords, type}) => {
  const [editorInstance, setEditorInstance] = useState<ClassicEditor | null>(null);
  const [selectedKeyword, setSelectedKeyword] = useState<string>("");
  const { resourceAllocate } = useResource();

  const { getPartialEmailTemplateKeywords } = emailTemplateKeywordsService();
  const dynamicKeywordsResponse = useQuery({
    queryKey: ["email-template-keyword-partial", type],
    queryFn: () =>
      getPartialEmailTemplateKeywords({
        type: type ? type: []
      }),
    enabled: showDynamicKeywords 
  });

  const dynamicKeywords = dynamicKeywordsResponse?.data?.data;

    const handleEditorReady = (editor: ClassicEditor) => {
      setEditorInstance(editor);
      onEditorReady && onEditorReady(editor);
    };  

  const insertKeyword = () => {
    if (!editorInstance || !selectedKeyword) return;
  
    try {
      editorInstance.model.change(writer => {
        const selection = editorInstance.model.document.selection;
        const position = selection.getFirstPosition();
  
        if (!position) {
          return;
        }
        writer.insertText(selectedKeyword, position);
  
  
        const positionAfterKeyword = position.getShiftedBy(selectedKeyword.length);
        writer.insertText(" ", positionAfterKeyword);
  
        // Move cursor position after the inserted space
        const newPosition = positionAfterKeyword.getShiftedBy(1);
        editorInstance.model.change(writer => {
          writer.setSelection(newPosition);
        });
      });
  
      // Reset the selected keyword
      setSelectedKeyword("");
    } catch (error) {
      console.log("Error inserting keyword:", error);
    }
  };
  
  
return(
    <>
   { resourceAllocate("email-template-keywords.read") && showDynamicKeywords && (
      <div className="dynamicKeyword">
      <Select value={selectedKeyword} onChange={e => {
            setSelectedKeyword(e.target.value as string);
          }}        
          className={"select-style"}
          >
        {dynamicKeywords?.map(keyword => (
          <MenuItem key={keyword.name} value={`{{${keyword.value}}}`}>{keyword.name}</MenuItem>
        ))}
      </Select>
      <button type="button" onClick={insertKeyword} disabled={!selectedKeyword} className="insert-button">
        Insert Keyword
      </button>
    </div>
    )}

      <CKEditor
        editor={ClassicEditor}
        data={editorContent}
        config={{
          toolbar: {
            items: [
              "undo",
              "redo",
              "|",
              "sourceEditing",
              "showBlocks",
              "selectAll",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "underline",
              "code",
              "|",
              "link",
              "insertImage",
              "ckbox",
              "insertTable",
              "codeBlock",
              "htmlEmbed",
              "|",
              "alignment",
              "|",
              "bulletedList",
              "numberedList",
            ],
            shouldNotGroupWhenFull: false,
          },
          plugins: [
            Alignment,
            Autoformat,
            Base64UploadAdapter,
            List,
            ListProperties,
            AutoImage,
            AutoLink,
            Autosave,
            Bold,
            CKBox,
            CloudServices,
            Code,
            CodeBlock,
            Essentials,
            GeneralHtmlSupport,
            Heading,
            HtmlEmbed,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            Italic,
            Link,
            LinkImage,
            Paragraph,
            PictureEditing,
            SelectAll,
            ShowBlocks,
            SourceEditing,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TextTransformation,
            Underline,
            Undo,
          ],
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
          htmlSupport: {
            allow: [
              {
                name: /^.*$/,
                styles: true,
                attributes: true,
                classes: true
              },
            ],
          },
          image: {
            toolbar: [
              "toggleImageCaption",
              "imageTextAlternative",
              "|",
              "imageStyle:inline",
              "imageStyle:wrapText",
              "imageStyle:breakText",
              "|",
              "resizeImage",
              "|",
              "ckboxImageEdit",
            ],
          },
          table: {
            contentToolbar: [
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "tableProperties",
              "tableCellProperties",
            ],
          }
        }}
        
        onChange={onEditorChange}
        onReady = {handleEditorReady}
      />
    </>
);
};

export default CKeditor;