import { FC, useEffect, useState } from "react";
import { Box, Button, capitalize, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { ISelfEvaluationFeedbackFilter } from "../../../../../interfaces/pms/self-evaluation";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MenuItem } from "../../../../../components/shared/filter";
import { checkTimePeriod } from "../../../../../utilities/helper";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { PmsParametersService } from "../../../../../services/pms/pms-parameters";
import { useQuery } from "@tanstack/react-query";
import useResource from "../../../../../hooks/useResource";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const FeedbackParameters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { snackbar } = useSnackbar();
    const { resourceAllocate } = useResource();
    const [searchParams] = useSearchParams();
    const [state, setState] = useState<ISelfEvaluationFeedbackFilter>({ 
        selectedMenu: 0,
        date: [],
        _parameter: [],
    });
    const { getPmsParameters } = PmsParametersService();
    const pmsParameters = useQuery({
      queryKey: ["all-pms-parameters-list"],
      queryFn: () =>
        getPmsParameters({
          pagination: false,
        }),
    });
    const pmsParametersData =
      (pmsParameters && pmsParameters.data && pmsParameters.data.data) || [];
    const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
      const newDoj = e ? dayjs(e).toISOString() : "";
      let date: Array<{
        key: string;
        value: string;
        startDate: string,
        endDate: string,
      }> = [];
  
      if (state.date.length && period === "start") {
        date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
      } else if (state.date.length && period === "end") {
        date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
      } else if (!state.date.length && period === "start") {
        const currentDate = new Date();
        currentDate.setHours(23,59,59);
        date = [{
          key: "custom",
          value: "custom", 
          startDate:  dayjs(e).startOf("day").toISOString(),
          endDate: currentDate.toISOString()
        }];
      } else {
        const currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        date = [{
          key: "custom",
          value: "custom",
          startDate: currentDate.toISOString(),
          endDate: dayjs(e).endOf("day").toISOString(),
        }];
      }
  
      setState(prevState => ({
        ...prevState,
        date
      }));
    };

    useEffect(() => {
        resetFilter(); 
    }, []);


    useEffect(() => {
      const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
      const _parameter: { key: string, value: string }[] = searchParams.get("_parameter") ? JSON.parse(String(searchParams.get("_parameter"))) : [];
      setState(prevState => ({
        ...prevState,
        date,
        _parameter
      }));
    }, [searchParams]);

    const handleLeftListItem = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number,
    ) => {
      setState(prevState => ({
        ...prevState,
        selectedMenu: index
      }));
    };

    const deleteChip = (name:  "date" | "_parameter", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
            startDate?: string,
            endDate?: string,
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            date: [],
            _parameter: [],
        });
    };

    const handleDateListItem = (name: "date", key: string, value: string) => {
      const date = checkTimePeriod(key);
  
      const payload: Array<{
        key: string;
        value: string;
        startDate: string,
        endDate: string,
      }> = [{
        key,
        value,
        startDate: date.startDate,
        endDate: date.endDate,
      }];
  
      setState(prevState => ({
        ...prevState,
        [name]: payload
      }));
    };

    const onApply = () => {

        if (state.date.length && new Date(state.date[0].endDate) < new Date(state.date[0].startDate)) {
            snackbar("End date cannot be earlier than start date.", "warning");
            return; 
        }

        searchParams.set("date", JSON.stringify(state.date));
        searchParams.set("_parameter", JSON.stringify(state._parameter));
        const path = location.pathname;
        searchParams.set("page", "1");
        let navigateLink = "";
        if(path === "/self-evaluation/feedbacks") {
            navigateLink = "/self-evaluation/feedbacks";
        }else if (path.split("/")[1] === "team-evaluation" && path.split("/")[2] !== "feedbacks") {
            navigateLink = `/team-evaluation/${path.split("/")[2]}`;
        }else if (path.split("/")[1] === "team-evaluation" && path.split("/")[2] === "feedbacks") {
            navigateLink = "/team-evaluation/feedbacks";
        }
        navigate({
            pathname: navigateLink,
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        const _parameter: { key: string, value: string }[] = searchParams.get("_parameter") ? JSON.parse(String(searchParams.get("_parameter"))) : [];
        setState(prevState => ({
          ...prevState,
          date,
          _parameter
        }));
        OnClose();
    };


    const handleRightListItem = (name: "_parameter", key: string, value: string) => {
      let payload: Array<{
          key: string;
          value: string;
      }> = [];
      const isExist = !!state[name].find(ele => ele.key === key);
      if (isExist) {
          payload = state[name].filter(ele => ele.key !== key);
      } else {
          payload = state[name];
          payload.push({
              key,
              value
          });
      }


      setState(prevState => ({
          ...prevState,
          [name]: payload
      }));
  };
    return (
      <>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={OnClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box id="filters-container">
            <Box
              className="center mb-3"
              justifyContent="space-between"
              alignItems="start"
            >
              <div className="active-filter mb-1">
                {state.date.length || state._parameter.length ? (
                  <>
                    {state.date.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        color="primary"
                        onDelete={() => deleteChip("date", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}
                    {state._parameter.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        color="primary"
                        onDelete={() => deleteChip("_parameter", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}
                  </>
                ) : (
                  <Box className="mt-2" display="flex" alignItems="center">
                    <FilterAltOffIcon />
                    <Typography className="ml-2">
                      No filters are applied
                    </Typography>
                  </Box>
                )}
              </div>
              <IconButton onClick={onClose} style={{ marginRight: "-10px" }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Grid className="filter-box" container>
              <Grid id="left" item xs={5}>
                <List component="nav">
                  <MenuItem
                    index={0}
                    label="Date"
                    selectedMenu={state.selectedMenu === 0}
                    onChange={handleLeftListItem}
                    count={state.date}
                  />

                  { 
                    location.pathname.split("/")[2] === "feedbacks" && resourceAllocate("pms-parameter.read") &&
                      <MenuItem
                        index={1}
                        label="Parameter"
                        selectedMenu={state.selectedMenu === 1}
                        onChange={handleLeftListItem}
                        count={state._parameter}
                      />
                  }

                </List>
              </Grid>
              <Divider orientation="vertical" />

              <Grid id="right" item xs={6}>
                <List component="nav">
                  {state.selectedMenu === 0 && (
                    <>
                      {[
                        { key: "yesterday", value: "Yesterday" },
                        { key: "today", value: "Today" },
                        { key: "thisWeek", value: "Weekly" },
                        { key: "thisMonth", value: "Monthly" },
                        { key: "thisQuarter", value: "Quarterly" },
                      ]?.map((date) => (
                        <ListItemButton
                          key={date.key}
                          selected={
                            !!state.date.find((ele) => ele.key === date.key)
                          }
                          onClick={() =>
                            handleDateListItem(
                              "date",
                              date.key,
                              capitalize(date.value)
                            )
                          }
                        >
                          <ListItemText primary={date.value} />
                          <Checkbox
                            edge="end"
                            checked={
                              !!state.date.find((ele) => ele.key === date.key)
                            }
                          />
                        </ListItemButton>
                      ))}
                      <Box marginTop={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.date[0]?.startDate
                                ? dayjs(state.date[0]?.startDate)
                                : null
                            }
                            onChange={(e) => handleDate(e, "start")}
                            label="Start Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                        <div className="mt-3" />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.date[0]?.endDate
                                ? dayjs(state.date[0]?.endDate)
                                : null
                            }
                            onChange={(e) => handleDate(e, "end")}
                            label="End Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}

                  {state.selectedMenu === 1 &&
                    pmsParametersData.map((i) => (
                      <ListItemButton
                        key={i._id}
                        selected={
                          !!state._parameter.find((ele) => ele.key === i._id)
                        }
                        onClick={() =>
                          handleRightListItem(
                            "_parameter",
                            i._id,
                            capitalize(i.name)
                          )
                        }
                      >
                        <ListItemText primary={capitalize(i.name)} />
                        <Checkbox
                          edge="end"
                          checked={
                            !!state._parameter.find((ele) => ele.key === i._id)
                          }
                        />
                      </ListItemButton>
                    ))}
                </List>
              </Grid>
            </Grid>

            <Box className="actions-btn" marginTop="8px" textAlign="end">
              <Button
                variant="outlined"
                color="error"
                onClick={() => resetFilter()}
              >
                Clear All
              </Button>
              <Button className="ml-2" onClick={onApply}>
                Apply
              </Button>
            </Box>
          </Box>
        </Menu>
      </>
    );
};

export default FeedbackParameters;