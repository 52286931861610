import Joi from "joi";
import { required } from "../shared";

export const SkillDevelopmentValidation = Joi.object({
  assignTo: Joi.string()
    .required()
    .max(25)
    .label("User Name")
    .messages({
      "string.empty": required,
    }),

  _mentor: Joi.string()
    .required()
    .max(25)
    .label("Mentor Name")
    .messages({
      "string.empty": required,
    }),

  course: Joi.string()
    .required()
    .max(25)
    .label("Course")
    .messages({
      "string.empty": required,
    }),

  startDate: Joi.date()
    .required()
    .label("Start Date")
    .messages({
      "string.empty": required,
    }),


  endDate: Joi.date()
            .greater(Joi.ref("startDate"))
            .required()
            .label("End Date")
            .messages({
              "string.empty": required,
              "date.greater": "End date must be greater than start date", 
            }),

  completionDate: Joi.string()
  .allow("")
  .label("Completion Date"),

  mentorEfficiency: Joi.string()
    .allow("")
    .label("Mentor Efficiency"),


  allocatedBy: Joi.string()
    .required()
    .label("Allocated By")
    .messages({
      "string.empty": required,
    }),

  status: Joi.string()
    .valid("ASSIGNED", "COMPLETED", "RE-ASSIGNED")
    .default("ASSIGNED")
    .label("Status"),
});