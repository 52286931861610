import Joi from "joi";
import { required } from "../../shared";

export const courseConfigurationSubSectionValidation = Joi.object({
    name: Joi.string()
        .required()
        .max(25)
        .label("Name")
        .messages({
            "string.empty": required,
            "string.max": "The name must not exceed 25 characters."
        }),
    description: Joi.string()
        .required()
        .max(100)
        .label("Description")
        .messages({
            "string.empty": required,
            "string.max": "The name must not exceed 100 characters."
        }),
    estimatedDuration: Joi.number()
        .required()
        .greater(0)
        .label("Estimated Duration")
        .messages({
            "number.empty": required,
            "number.greater": "Estimated Duration must be greater than 0."
        }),
    _skillItem: Joi.array()
        .required()
        .min(1)
        .label("Skill Items")
        .messages({
            "array.empty": required,
            "array.min": "Please select at least one value."
        })
});