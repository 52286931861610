import Joi from "joi";
import { required } from "../../shared";

export const okrObjectiveValidation = Joi.object({
  name: Joi.string()
    .min(3)
    .max(60)
    .trim()
    .required()
    .label("Name")
    .messages({
        "string.empty": required,
    }),
  description: Joi.string()
    .trim()
    .required()
    .label("Description")
    .messages({
        "string.empty": required,
    }),
  _department: Joi.string()
    .required()
    .min(3)
    .trim()
    .label("Department")
    .messages({
      "string.empty": required,
    }),
  type: Joi.string()
    .required()
    .label("Type")
    .messages({
        "string.empty": required,
    }),
  frequency: Joi.string()
    .when("type", {
      is: "recurring",
      then: Joi.required().messages({
        "string.empty": required,
      }),
      otherwise: Joi.optional().allow(""),
    })
    .label("Frequency"),
  startDate: Joi.date()
    .required()
    .label("Start Date")
    .messages({
        "string.empty": required,
    }),
  endDate: Joi.date()
    .required()
    .label("End Date")
    .messages({
        "string.empty": required,
    }),
});
