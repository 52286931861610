import { Box, Switch } from "@mui/material";

import { useState } from "react";
import {
  Outlet,
  useParams,
} from "react-router-dom";
import {
  createIndex,
} from "../../../../../utilities/helper";
import { IErrorResponse, IPagination } from "../../../../../interfaces";
import Header from "../../../../../components/header";
import CustomTable from "../../../../../components/mui/table";
import { useQuery } from "@tanstack/react-query";
import useResource from "../../../../../hooks/useResource";

import { IAssessmentCheatingRuleRow, IAssessmentResponsesState } from "../../../../../interfaces/configuration/assessments";

import useSnackbar from "../../../../../hooks/useSnackbar";
import { AssessmentService } from "../../../../../services/configuration/assessments";

const formatKey = (key: string): string => (key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, str => str.toUpperCase()));

const Cheating = () => {
  let rows: IAssessmentCheatingRuleRow[] = [];
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();

  const { getAssessment, updateAssessmentCheatingRule } = AssessmentService();


  const [state] = useState<IAssessmentResponsesState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      createdAt: {
        startDate: "",
        endDate: ""
      },
      _tests: [],
      cpd: [],
      _codingChallenges: [],
      status: [],
      submissionStatus: [],
      submissionDate: [],
    },
    filterCount: 0
  });

  const { assessmentId } = useParams();

  const assessment = useQuery({
    queryKey: ["assessment-cheating-details"],

    queryFn: () =>
      getAssessment({
        _id: assessmentId
      }),
  });
  const description: { [key: string]: string } = {
    leavingTab:"Navigating away from the test window during the assessment is prohibited.",
    pasted:"Copying and pasting from external sources or materials is not allowed.",
  };
  
  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "status",
      label: "Status",
    },
  ];


  const handleChange = async (key: string, checked: boolean) => {
    const updatedCheatingObject = assessment && assessment.data && assessment.data.data && assessment.data.data.cheatingDetection;

    if (updatedCheatingObject) {
      updatedCheatingObject[key] = checked;
      try {
        const updated = await updateAssessmentCheatingRule({
          _id: assessmentId,
          cheatingDetection: updatedCheatingObject
        });
        snackbar(updated.message, "info");
        assessment.refetch();

      }
      catch (error) {
        const err = error as IErrorResponse;
        snackbar(err?.data?.message, "error");
      }
    }
  };
  const createRow = (
    index: number,
    key: string, // "copyPaste" or "LeavingTab"
    value: boolean, // true or false
    pagination: IPagination
  ) => {

    const status = (
      <Switch
        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          handleChange(key, checked);
        }}
        disabled={!resourceAllocate("assessment.write")}
        checked={value}
      />
    );

    return {
      id: createIndex(pagination, index),
      name: formatKey(key),
      description: description[key],
      status,
    };
  };

  const cheatingObject = assessment && assessment.data && assessment.data.data && assessment.data.data.cheatingDetection;

  if (cheatingObject) {
    rows = Object.entries(cheatingObject).map(([key, value], i) =>
      createRow(i, key, value, state.pagination)
    );
  }
  return (
    <div id="cheating-manage">
      <Header
        className="my-2"
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 330px)"
        />
      </Box>
      <Outlet />
    </div>
  );
};

export default Cheating;
