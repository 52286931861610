import { Box, Typography, Checkbox, capitalize, Button } from "@mui/material";
import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import "./style.scss";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { Outlet, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { IColumn, IErrorResponse, IPagination } from "../../../interfaces";
import GetActions from "../../../components/get-actions";
import useSnackbar from "../../../hooks/useSnackbar";
import WarningDialog from "../../../components/mui/warning-dialog";
import { useLocation } from "react-router-dom";
import backArrow from "../../../assets/images/back-arrow.svg";
import frontArrow from "../../../assets/images/front-arrow.svg";

import quarterTargetService from "../../../services/quarter-target";
import { useQuery } from "@tanstack/react-query";
import plannerService from "../../../services/planner";
import backArrowRound from "../../../assets/images/back-arrow-round.svg";
import { handleSelect, handleSelectAll } from "../../content-layout/common/helper";
import { createIndex } from "../../../utilities/helper";
import { IQuarterTarget, IQuarterTargetRow, IQuarterTargetStatus } from "../../../interfaces/planner/quarter-target";
import useResource from "../../../hooks/useResource";
import TypesOfContentService from "../../../services/content/type-of-content";
import PlannerFilters from "./filter";

const Planner = () => {
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { getQuarterTargetList, deleteQuarterTarget } = quarterTargetService();
  const { getQuarter, getPlannerList, freeze } = plannerService();
  const{ getContentTypesOfContents } = TypesOfContentService();
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState<IQuarterTargetStatus>({
    selectAll: [],
    multiDeleteWarning: false,
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    filters: {
      search: "",
      category: [],
      subCategory: []
    },
    filterCount: 0,
    searching: {
      search: "",
    },
    deleteWarning: false,
    _deleteId: "",
  });

  // <!----------------- searching starts here-------------------------------------------------- -->
  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const searchRecord = useDebounce(search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    setState((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: page,
      },
      searching: {
        ...prev.searching,
        search: search,
      },
    }));
  }, [searchParams]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];

    filterCount += category.length ? 1 : 0;
    filterCount += subCategory.length ? 1 : 0;


    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map(item => item.key),
        subCategoryId: subCategory.map(item => item.key),
      },
      filterCount
    }));
  }, [searchParams]);

  // <!----------------- searching ends here-------------------------------------------------- -->

  // <!----------------------------- page handliation starts here here---------------------------------------------->
  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  // <!----------------------------- page handliation ends here ---------------------------------------------->

  // <!----------------- delete function starts here-------------------------------------------------- -->
  const handleDelete = (_deleteId = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !state.deleteWarning,
      _deleteId: _deleteId,
    }));
  };

  const onDelete = async () => {
    try {
      const result = await deleteQuarterTarget({ ids: state._deleteId });
      snackbar(result?.message, "info");
      handleDelete();
      ProjectQuarterTargetList.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in delete of goals", err);
    }
  };

  //   <!------------------- delete function ends here-------------------------------------------------- -->

  const id = location.pathname.split("/")[4];

  const productId = location.pathname.split("/")[2];
  const ProjectQuarterTargetList = useQuery({
    queryKey: [id, state.pagination.page, state.searching, state.filters],
    queryFn: () =>
      getQuarterTargetList({
        quarterId: id,
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.searching,
        ...state.filters,
      }),
  });
  useEffect(() => {
    if (ProjectQuarterTargetList.data && ProjectQuarterTargetList.data.data && ProjectQuarterTargetList.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [ProjectQuarterTargetList.data && ProjectQuarterTargetList.data.data]);
  
  const typeOfContent = useQuery({
    queryKey: ["typeOfContent"],
    queryFn: () => getContentTypesOfContents({})
  });
  // <!----------------- Table creation here-------------------------------------------------- -->
  const dynamicColumns = typeOfContent.data?.data?.map((item) => ({
    id: item._id,
    label: item.name,
  })) || [];

  const isChecked = (ProjectQuarterTargetList.data?.data?.length && state.selectAll?.length === ProjectQuarterTargetList.data?.data?.length) ? true : false;
  const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(ProjectQuarterTargetList.data?.data.length)) ? true : false;
  const columns: IColumn[] = [
    {
      id: "all",
      label: <Checkbox onChange={e => handleSelectAll(e, ProjectQuarterTargetList?.data?.data ? ProjectQuarterTargetList?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />
    },
    {
      id: "_category",
      label: "Category",
    },
    {
      id: "_subCategory",
      label: "Sub Category",
    },
    ...dynamicColumns.map(column => ({
      id: column.id,
      label: (
        <>
          <span>{capitalize(column.label)}</span>
        </>
      ),
    })),
    {
      id: "total",
      label: "Total",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  let rows: IQuarterTargetRow[] = [];
  const QuarterData = useQuery({
    queryKey: [id],
    queryFn: () =>
      getQuarter({
        _id: id
      }),
  });

  const QuartersList = useQuery({
    queryKey: [productId],
    queryFn: () =>
      getPlannerList({
        productId
      }),
  });
  const currentIndex = QuartersList?.data?.data.findIndex((item) => item._id === id);

  const previousQuarter = currentIndex !== undefined && currentIndex > 0 ? QuartersList?.data?.data[currentIndex - 1] : null;
  const nextQuarter = currentIndex !== undefined && currentIndex <= Number(QuartersList?.data?.data?.length) - 1 ? QuartersList?.data?.data[currentIndex + 1] : null;

  const moduleId = location.pathname.split("/")[2];

  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));
  const onMultiDelete = async () => {
    try {
      const result = await deleteQuarterTarget({ ids: state.selectAll });
      snackbar(result.message, "info");
      ProjectQuarterTargetList.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete scores data": error });
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const handleFreeze = async () => {
    try{
      await freeze({_quarter: id});
      QuarterData.refetch();
    }catch(error){
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  /*eslint-disable-next-line*/
  const createRows = (data: IQuarterTarget, selected: string[], index: number, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              navigate({
                pathname: `/planner/${moduleId}/manage/${id}/${data._id}`,
                search: searchParams.toString(),
              });
            },
            disabled: resourceAllocate("cms-quarter-targets.write") && !QuarterData?.data?.data?.isFreeze ? false : true
          },
          { name: "Delete", method: () => handleDelete(data._id), disabled: resourceAllocate("cms-quarter-targets.remove") && !QuarterData?.data?.data?.isFreeze ? false : true },
        ]}
      />
    );

      const dynamicColumnsData = dynamicColumns.map((column ) => {
        const matchingItem = data?.targets?.find((item) => item?._id?._id === column.id);
        const obj:{[key:string]: string}  = {};
        if (matchingItem) {
          obj[column.id] = String(matchingItem.publishedCount) + "/" + String(matchingItem.count);
          return obj;
        } else {
          obj[column.id] = "0/0";
          return obj;
        }
      });

    return {
      all: <Checkbox onChange={e => handleSelect(e, data._id, state, setState)} checked={selected.includes(data._id)} />,
      id: createIndex(pagination, index),
      _category: data?._category?.name,
      _subCategory: data?._subCategory?.name,
      ...Object.assign({}, ...dynamicColumnsData),
      total: String(data?.totalPublishCount) && data?.total ? data.totalPublishCount + "/" + data.total : "0/0",
      action,
    };
  };
  if (ProjectQuarterTargetList?.data?.data?.length) {
    rows = ProjectQuarterTargetList?.data.data.map((planner: IQuarterTarget, index: number) =>
      createRows(planner, state.selectAll, index, state.pagination)
    );
  }

  const handleClick = () => {
    navigate(`/planner/${moduleId}`);
  };
  const openManageComponent = () => {
    navigate(`/planner/${moduleId}/manage/${id}/new`);
  };

  return (
    <div>
      <Box width="100%" display={"flex"} className={"mt-2"}>
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
          onClick={handleClick}
        >
          <img src={backArrowRound} alt="" className="cursor-pointer" />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={"ml-3"}
          height={"34px"}
        >
          <Typography
            variant="h6"
            fontWeight={"600"}
          >
            Quarter Details
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          className="mt-3 mb-4"
        >
          <Box display="flex" alignItems="center">
            <img
              height="24px"
              className={`p-2 mr-3 arrows cursor-pointer ${!previousQuarter ? "disabled" : ""}`}
              onClick={() => {
                previousQuarter && navigate(`/planner/${productId}/manage/${previousQuarter?._id}`);
              }}
              src={backArrow}
              alt=""
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
            <Typography
              className="p-2 custom-typography"
            >
              {String(QuarterData?.data?.data ? QuarterData?.data?.data?.name : "")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img
              height="24px"
              className={`p-2 ml-3 arrows cursor-pointer ${!nextQuarter ? "disabled" : ""}`}
              onClick={() => {
                nextQuarter && navigate(`/planner/${productId}/manage/${nextQuarter?._id}`);
              }}
              src={frontArrow}
              alt=""
            />
          </Box>
        </Box>
        <Box>
          <Button 
              className="ml-3 header-buttons"
              onClick={handleFreeze}
              disabled= {resourceAllocate("cms-quarter.freeze") && !QuarterData?.data?.data?.isFreeze ? false : true}
          >
            {!QuarterData?.data?.data?.isFreeze ? "FREEZE" : "FREEZED"}
          </Button>
        </Box>
      </Box>
      <Outlet context={{ ...Outlet, reFetch: ProjectQuarterTargetList.refetch }} />
      <Header
        className="my-2"
        searchPlaceholder="Search by Category"
        onDelete={resourceAllocate("cms-quarter-targets.remove") ? handleMultiDelete : undefined}
        isDeleteDisable={state.selectAll.length ? false : true}
        onSearch={onSearch}
        btnText="Add New Target"
        onImport={resourceAllocate("cms-quarter-targets.write") && resourceAllocate("cms-quarter-targets.write") ? () => navigate("import") : undefined}
        onBtnClick={resourceAllocate("cms-quarter-targets.write") ? () => openManageComponent() : undefined}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <PlannerFilters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
        </Header>
      <CustomTable
        rows={rows}
        columns={columns}
        height={"calc(100vh - 248px)"}
        pagination={state.pagination}
        onPageChange={onPageChange}
      />

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={() => onDelete()}
        title="Delete Quarter Target"
        description="Are you sure you want to delete this quarter target?"
      />
      {/* Multiple Delete  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All Quarter Targets"
        description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "quarter targets" : "quarter target"}?`}
      />
    </div>
  );
};

export default Planner;