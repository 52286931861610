import dayjs from "dayjs";
import { startOfWeek } from "date-fns";
import { IDataSet, IMergedData, IPagination } from "../interfaces";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { addDays, format } from "date-fns";
import { IAlternativeContent, Section } from "../interfaces/alternative-comparison/content";
dayjs.extend(localizedFormat);

export const capitalize = (text: string) => {
    if (typeof text !== "string") {
        return "";
    }
    let name: string | string[] = text?.trim()?.toLowerCase() || "";
    name = name?.split(" ");
    name = name
        .map((word) => {
            if (["TA", "LA", "HR", "BD", "UI/UX", "CPD"].includes(word?.toUpperCase())) {
                return word?.toUpperCase();
            } else if (word?.includes("-")) {
                const word2 = word?.split("-");
                return word2
                    .map((wrd) => wrd[0] ? wrd[0]?.toUpperCase() + wrd?.substring(1) : "")
                    .join("-");
            } else {
                return word[0] ? word[0]?.toUpperCase() + word?.substring(1) : "";
            }
        })
        .join(" ");

    return name;
};

export const formatCallStatus = (status: string) => {
    switch (status) {
        case "APPLIED":
            return "Applied";
        case "CALL_NA":
            return "Called NA";
        case "TBC_LATER":
            return "TBC Later";
        case "NOT_INTERESTED":
            return "Not Interested";

        default:
            return capitalize(status);
    }
};

export const checkStatusColor = (status: string): "warning" | "success" | "error" => {
    let color: "warning" | "success" | "error" = "warning";

    switch (status.toUpperCase()) {
        case "TA SELECTED":
        case "COMPLETED":
            color = "success";
            break;
        case "TA REJECTED":
        case "PENDING":
            color = "error";
            break;
        case "IN-PROGRESS":
            color = "warning";
            break;
        case "HR SELECTED":
            color = "success";
            break;
        case "HR REJECTED":
            color = "error";
            break;
        case "REVIEW SELECTED":
            color = "success";
            break;
        case "REVIEW REJECTED":
            color = "error";
            break;
    }

    return color;
};

export const fonts = [
    "arial",
    "monospace",
    "sans-serif"
];


export const checkCollegeStatusColor = (isDriveActive: boolean): "success" | "error" =>
    isDriveActive ? "success" : "error";

export const formateEngagementType = (type: string) => {
    let engagementType = "";
    switch (type?.toLowerCase()) {
        case "full-time":
            engagementType = "Full Time";
            break;
        case "internship":
            engagementType = "Intern";
            break;
        default:
            engagementType = type;
            break;
    }
    return capitalize(engagementType?.toLowerCase());
};

export const checkRequestedSkillStatusColor = (status: string): "warning" | "success" | "error" => {
    let color: "warning" | "success" | "error" = "warning";

    switch (status.toUpperCase()) {
        case "APPROVED":
            color = "success";
            break;
        case "REJECTED":
            color = "error";
            break;
        case "PENDING":
            color = "warning";
            break;
        default:
            break;
    }
    return color;
};

export const checkRequestedResourcesStatusColor = (status: string) => {

    switch (status.toUpperCase()) {
        case "HR_APPROVED":
            return "D0F0D2";
        case "HR_REJECTED":
            return "#D32F2F80";
        case "ADMIN_APPROVED":
            return "D0F0D2";
        case "ADMIN_REJECTED":
            return "#D32F2F80";
        case "IN-PROGRESS":
            return "D0F0D2";
        case "COMPLETED":
            return "#D0F0D2";
        default:
            break;
    }
};

export function formatMobileNumber(mobile?: { dialCode: string, number: string, iso2: string, country: string }): string {
    return `${mobile?.dialCode || ""} ${mobile?.number || ""}`;
}

export const createIndex = (pagination: IPagination, index: number) => pagination?.limit * (pagination?.page - 1) + index + 1;

export const formatDateTime = (d: string | Date | undefined) => dayjs(d)
    .format("lll");

export const formatTime = (d: string | Date | undefined) => {
    if (!d) {
        return "";
    }

    return dayjs(d).format("h:mm A");
};

export const formatDate = (d: string | Date | undefined) => {
    if (!d) {
        return "";
    }
    return dayjs(d).format("ll");
};

export function isValidDate(d: Date | string) {
    if (new Date(d).toString() !== "Invalid Date") {
        return new Date(d)?.toISOString();
    } else {
        return false;
    }
}

export const replaceHtmlKeyword = (htmlContent: string, words: { [key: string]: string | string[] | number }) => {
    let processedString = htmlContent?.replace(/<span[^>]*>(<em[^>]*>)?{{\s*([\w.]+)\s*}}(<\/em>)?<\/span>/g, (match, emTagOpen, keyword) => {
        const replacement = words[keyword];
        if (replacement !== undefined)
            return replacement.toString();
        else {
            return `${keyword}`;
        }
    });

    processedString = processedString?.replace(/{{\s*([\w.]+)\s*}}/g, (match, keyword) => {
        const replacement = words[keyword];
        if (replacement !== undefined)
            return replacement.toString();
        else {
            return `${keyword}`;
        }
    });

    return processedString;
};

export const toIndianRupee = (num: number) =>
    num.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
    });

export const excelDateToJsDate = (serial: number) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    const data = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds,
    );

    if (data.toString() !== "Invalid Date") {
        return data?.toISOString();
    } else {
        return undefined;
    }
};

export const displayName = (user: { firstName: string; lastName: string; name?: string }) => {
    if (user?.name) {
        return user?.name;
    }
    const FirstName = (user?.firstName);
    const LastName = (user?.lastName);
    return `${FirstName} ${LastName}`;
};
export const checkTimePeriod = (timePeriod: string) => {
    switch (timePeriod) {
        case "today": {
            return {
                startDate: dayjs()
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("day")
                    .toISOString()
            };
        }
        case "thisWeek": 
        case "thisComparisonWeek": {
            return {
                startDate: dayjs()
                    .startOf("week")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("week")
                    .toISOString()
            };
        }
        case "thisMonth": 
        case "thisComparisonMonth": {
            return {
                startDate: dayjs()
                    .startOf("month")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("month")
                    .toISOString()
            };
        }
        case "yesterday": {
            return {
                startDate: dayjs()
                    .subtract(1, "day")
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .subtract(1, "day")
                    .endOf("day")
                    .toISOString()
            };
        }
        case "lastWeek": {
            return {
                startDate: dayjs()
                    .subtract(1, "weeks")
                    .startOf("week")
                    .toISOString(),
                endDate: dayjs()
                    .subtract(1, "weeks")
                    .endOf("week")
                    .toISOString()
            };
        }
        case "lastMonth": {
            return {
                startDate: dayjs()
                    .subtract(1, "month")
                    .startOf("month")
                    .toISOString(),
                endDate: dayjs()
                    .subtract(1, "month")
                    .endOf("month")
                    .toISOString()
            };
        }
        case "thisQuarter": 
        case "thisComparisonQuarter": {
            return {
                startDate: dayjs()
                    .subtract(2, "months")
                    .startOf("month")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("month")
                    .toISOString()
            };
        }
        default: {
            return {
                startDate: dayjs()
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("day")
                    .toISOString()
            };
        }
    }
};

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const getPreviousTimePeriod = (key: string) => {
    switch (key) {
        case "thisComparisonWeek":
            return {
                startDate: dayjs().subtract(1, "week").startOf("week").toISOString(),
                endDate: dayjs().subtract(1, "week").endOf("week").toISOString(),
            };
        case "thisComparisonMonth":
            return {
                startDate: dayjs().subtract(1, "month").startOf("month").toISOString(),
                endDate: dayjs().subtract(1, "month").endOf("month").toISOString(),
            };
        case "thisComparisonQuarter":
            return {
                startDate: dayjs().subtract(4, "months").startOf("month").toISOString(),
                endDate: dayjs().subtract(2, "months").endOf("month").toISOString(),
            };

        default:
            return {
                startDate: dayjs()
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("day")
                    .toISOString()
            };
    }
};

export const formatHistoryDate = (dateString: Date) => {
    const dateObject = new Date(dateString);
    const currentDate = new Date();
    const isToday = dateObject.toDateString() === currentDate.toDateString();

    return isToday
        ? "Today"
        : `${new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObject)} ${dateObject.getFullYear()}`;
};

export const formatMonthDate = (dateString: Date) => {
    const dateObject = new Date(dateString);
    const currentDate = new Date();
    const isToday = dateObject.toDateString() === currentDate.toDateString();

    return isToday
        ? "Today"
        : dayjs(dateString).format("ll");
};

export const formatMonthTime = (dateString: Date) => {
    const dateObject = new Date(dateString);
    return `${new Intl.DateTimeFormat("en-US", { month: "long" }).format(dateObject)} ${dateObject.getFullYear()}`;
};

export const getGraduationYears = (currentYear: number) => {
    const graduationYears = [];
    for (let year = currentYear - 20; year <= currentYear + 5; year++) {
        graduationYears.push({ value: String(year) });
    }
    return graduationYears;
};

export const formatHtmlString = (htmlString: string) => {
    const parser = new DOMParser();

    const doc = parser.parseFromString(htmlString, "text/html");

    const formattedString = doc.body.textContent || "";

    return formattedString.trim();
};

export const checkTestStatusColor = (status: string): "warning" | "success" | "error" => {
    switch (status && status.toUpperCase()) {
        case "PENDING":
        case "MODERATE":    
        case "MEDIUM":
            return "warning";
        case "SUBMITTED":
        case "HIGH":
        case "EASY":    
            return "success";
        case "LOW":
        case "HARD":            
            return "error";
        default:
            return "warning";
    }
};
export const checkTestStatusBackgroundColor = (status: string) => {
    switch (status && status.toUpperCase()) {
        case "PENDING":
        case "MODERATE":    
        case "MEDIUM":
            return "#FCF5EB";
        case "HIGH":
        case "EASY":    
        case "SUBMITTED":
            return "#D0F0D2";
        case "LOW":
        case "HARD":            
            return "#F9D9DC";
        default:
            return "warning";
    }
};


export const cleanHtmlString = (html: string) => {
    let cleanedHtml = html.replace(/>\s+</g, "><") // Remove spaces between tags
      .replace(/(<br\s*\/?>)+/g, "") // Remove unnecessary <br> tags
      .replace(/<p>(\s*(&nbsp;)?)*<\/p>/g, "")
      .trim(); // Trim the result

    // Trim spaces inside <p> tags
    cleanedHtml = cleanedHtml.replace(/<p>(.*?)<\/p>/g, (match, p1) => `<p>${p1.trim()}</p>`);
    cleanedHtml = cleanedHtml.replace(
        /<img(?![^>]*\bstyle=)([^>]*)>/g,
        "<img style=\"max-width:500px; max-height:400px; width:auto; height:auto; object-fit:contain;\"$1>"
      );


    return cleanedHtml;
  };

  export const increaseDate = (dateString: string , day:number) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + day);
    return date.toISOString();
  };

  export const checkElegibilityChipColor = (status: string): "warning" | "success" | "error" => {
    switch (status && status.toUpperCase()) {
        case "Potential Fit":
            return "warning";
        case "Perfect Match":
        case "Good Match":    
            return "success";
        case "Not a Fit":
            return "error";
        default:
            return "warning";
    }
};

export const mergeMultipleJson = (...dataSets: IDataSet[]): IMergedData[] => {
    const mergedArray: IMergedData[] = [];

    dataSets.forEach(dataSet => {
        const { name, data } = dataSet;
        mergedArray.push({ name, data });
    });

    return mergedArray;
};

export const getDisplayLabel = (perm: string) => {
    if (perm.endsWith("Assigned")) {
        return "Assigned"; // For assigned permissions
    }
    if (perm.includes("read") || perm.includes("edit") || perm.includes("remove")) {
        return "All"; // For read, edit, or remove permissions
    }
    return perm.split(".")[1]; // Default label for other permissions
};

export const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};


const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [
        (bigint >> 16) & 255, // Red
        (bigint >> 8) & 255,  // Green
        bigint & 255          // Blue
    ];
};


const rgbToHex = (r: number, g: number, b:number) => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

const getMonthFromWeek = (year: number, weekNumber: number) => {
    const firstDayOfYear = new Date(year, 0, 1);
     const options = { weekStartsOn: 1 as 0 | 1 | 2 | 3 | 4 | 5 | 6 };
    const startDateOfWeek = startOfWeek(new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() + (weekNumber - 1) * 7)), options);
    return months[startDateOfWeek.getMonth()];
};

export const generateColorPalette = (baseColor: string) => {
    const [r, g, b] = hexToRgb(baseColor);
    
    return {
        "50": rgbToHex(Math.min(r + 200, 255), Math.min(g + 200, 255), Math.min(b + 200, 255)),
        "100": rgbToHex(Math.min(r + 150, 255), Math.min(g + 150, 255), Math.min(b + 150, 255)),
        "200": rgbToHex(Math.min(r + 100, 255), Math.min(g + 100, 255), Math.min(b + 100, 255)),
        "300": rgbToHex(Math.min(r + 50, 255), Math.min(g + 50, 255), Math.min(b + 50, 255)),
        "400": baseColor,
        "500": baseColor,
        "600": rgbToHex(Math.max(r - 50, 0), Math.max(g - 50, 0), Math.max(b - 50, 0)),
        "700": rgbToHex(Math.max(r - 100, 0), Math.max(g - 100, 0), Math.max(b - 100, 0)),
        "800": rgbToHex(Math.max(r - 150, 0), Math.max(g - 150, 0), Math.max(b - 150, 0)),
        "900": rgbToHex(Math.max(r - 200, 0), Math.max(g - 200, 0), Math.max(b - 200, 0)),
        A100: rgbToHex(Math.min(r + 150, 255), Math.min(g + 150, 255), Math.min(b + 150, 255)),
        A200: rgbToHex(Math.min(r + 100, 255), Math.min(g + 100, 255), Math.min(b + 100, 255)),
        A400: rgbToHex(Math.min(r + 50, 255), Math.min(g + 50, 255), Math.min(b + 50, 255)),
        A700: rgbToHex(Math.max(r - 50, 0), Math.max(g - 50, 0), Math.max(b - 50, 0)),
        contrastText: "#ffffff"
    };
};

export const generateWeeklyandQuarterlyData = (data: number[], periodType: "WEEKLY" | "MONTHLY"): string[] => {
    const formattedData: string[] = [];
    const year = new Date().getFullYear();
    const currentYear = new Date().getFullYear();

    if (periodType === "WEEKLY") {
        data.forEach((value) => {
            const month = getMonthFromWeek(year, value);
            formattedData.push(`${month} Week ${value}`);
        });
    } else if (periodType === "MONTHLY") {
        data.forEach((value) => {
            const monthIndex = value % 12;
            const year = currentYear + Math.floor(value / 12);
            formattedData.push(`${months[monthIndex]} ${year}`);
        });
    }
    
    return formattedData;
    };
export const generateEvaluationColumns = (
    startDate: Date,
    endDate: Date,
    frequency: string
  ) => {
    const columns = [];
    let current = startDate;
  
    while (current <= endDate) {
      let label;
      let nextDate;
  
      switch (frequency) {
        case "WEEKLY":
          label = `Week ${format(current, "MMM yyyy")} (${format(
            current,
            "dd MMM"
          )} - ${format(addDays(current, 6), "dd MMM")})`;
          nextDate = addDays(current, 7);
          break;
        case "BI-WEEKLY":
          label = `Bi-Week ${format(current, "MMM yyyy")} (${format(
            current,
            "dd MMM"
          )} - ${format(addDays(current, 13), "dd MMM")})`;
          nextDate = addDays(current, 14);
          break;
        case "MONTHLY":
          label = format(current, "MMMM yyyy");
          nextDate = addDays(current, 30);
          break;
        case "QUARTERLY":
          label = `Q${Math.ceil((current.getMonth() + 1) / 3)} ${current.getFullYear()}`;
          nextDate = addDays(current, 90);
          break;
        default:
          throw new Error("Invalid frequency");
      }
  
      columns.push({ label, startDate: current, endDate: nextDate });
      current = nextDate;
    }
  
    return columns;
  };


export const defaultValuesAlternativeContent = {
    title: "",
    description: "",
    buttonLink: "",
    buttonText: "",
    sections: [
      {
        heading: "",
        paragraph: "",
        imageOne: "",
        type: "dropdowns",
        buttonText: "",
        buttonLink: "",
        items: [{ heading: "", paragraph: "" }],
      },
      {
        heading: "",
        paragraph: "",
        imageOne: "",
        type: "checkMarks",
        buttonText: "",
        buttonLink: "",
        points: [""],
      },
      {
        type: "tableData",
        tableData: {
          data: "",
          heading: "",
          paragraph: "",
          buttonTextOne: "",
          buttonLinkOne: "",
          buttonTextTwo: "",
          buttonLinkTwo: "",
        },
      },
      {
        type: "cards",
        cards: [
          {
            heading: "",
            paragraph: "",
            buttonLink: "",
            buttonText: "",
            image: "",
          },
        ],
      },
      {
        type: "faqs",
        faqs: [
          {
            heading: "",
            paragraph: "",
          },
        ],
      },
      {
        heading: "",
        type: "cta",
        buttonText: "",
        buttonLink: "",
      },
    ],
    metaTitle: "",
    metaDescription: "",
  };

export const areEqual = (obj1: IAlternativeContent, obj2: IAlternativeContent | undefined): boolean => {
    if (!obj2) return false;

    if (
        obj1.title !== obj2.title ||
        obj1.description !== obj2.description ||
        obj1.buttonLink !== obj2.buttonLink ||
        obj1.buttonText !== obj2.buttonText ||
        obj1.metaTitle !== obj2.metaTitle ||
        obj1.metaDescription !== obj2.metaDescription
    ) {
        return false;
    }

    if (obj1.sections.length !== obj2.sections.length) {
        return false;
    }

    return obj1.sections.every((section, index) => {
        const section2 = obj2.sections[index];
        if (
            section.heading !== section2.heading ||
            section.paragraph !== section2.paragraph ||
            section.type !== section2.type ||
            section.buttonText !== section2.buttonText ||
            section.buttonLink !== section2.buttonLink
        ) {
            return false;
        }

        switch (section.type) {
            case "dropdowns":
                return areArraysEqual(section.items, section2.items);
            case "checkMarks":
                return areArraysEqual(section.points, section2.points);
            case "tableData":
                return areTableDataEqual(section.tableData, section2.tableData);
            case "cards":
                return areArraysEqual(section.cards, section2.cards);
            case "faqs":
                return areArraysEqual(section.faqs, section2.faqs);
            default:
                return true;
        }
    });
};

export const isFormEmpty = (values: IAlternativeContent): boolean => {
    const isEmptyValue = (value: IAlternativeContent): boolean => {
        if (Array.isArray(value)) {
            return value.every(isEmptyValue);
        }
        if (typeof value === "object" && value !== null) {
            return Object.values(value).every(isEmptyValue);
        }
        return value === "";
    };
    return Object.values(values).every(isEmptyValue);
};

const areArraysEqual = <T>(arr1: T[] | undefined, arr2: T[] | undefined): boolean => {
    if (!arr1 || !arr2) return arr1 === arr2;
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => {
        if (typeof item === "object" && item !== null) {
            return JSON.stringify(item) === JSON.stringify(arr2[index]);
        }
        return item === arr2[index];
    });
};

const areTableDataEqual = (
    table1: Section["tableData"] | undefined,
    table2: Section["tableData"] | undefined
): boolean => {
    if (!table1 || !table2) return table1 === table2;
    return (
        table1.data === table2.data &&
        table1.heading === table2.heading &&
        table1.paragraph === table2.paragraph &&
        table1.buttonTextOne === table2.buttonTextOne &&
        table1.buttonLinkOne === table2.buttonLinkOne &&
        table1.buttonTextTwo === table2.buttonTextTwo &&
        table1.buttonLinkTwo === table2.buttonLinkTwo
    );
};

export const convertExcelToDate = (serialNumber: number): Date => {
    const baseDate = new Date(1899, 11, 31);
    const daysSinceBase = serialNumber - 1;
    const millisecondsToAdd = daysSinceBase * 24 * 60 * 60 * 1000;
    return new Date(baseDate.getTime() + millisecondsToAdd);
};

export const formatDriveDate = (date: Date): string => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
};

export const isExcelDate = (value: number): boolean => 
    (typeof value === "number" && value > 1 && Number.isInteger(value));

export const parseDateString = (dateString: string): Date | null => {
    const months: { [key: string]: number } = {
        "jan": 0, "feb": 1, "mar": 2, "apr": 3, "may": 4, "jun": 5,
        "jul": 6, "aug": 7, "sep": 8, "oct": 9, "nov": 10, "dec": 11
    };
    

    try {
        const match = dateString.match(/^([a-zA-Z]{3})\s+(\d{1,2}),\s*(\d{4})$/);
        if (match) {
            const month = months[match[1].toLowerCase()];
            const day = parseInt(match[2], 10);
            const year = parseInt(match[3], 10);

            if (month !== undefined && day >= 1 && day <= 31 && year >= 1900) {
                const date = new Date(year, month, day);
                if (date.getMonth() === month) {
                    return date;
                }
            }
        }
    } catch {
        return null;
    }
    return null;
};

export const validateExcelDate = (value:number): Date | null => {
    if (!value) return null;

    if (isExcelDate(value)) {
        try {
            const date = convertExcelToDate(value);
            if (date.getFullYear() >= 1900 && date.getFullYear() <= 2100) {
                return date;
            }
        } catch {
            return null;
        }
    }

    if (typeof value === "string") {
        return parseDateString(value);
    }

    return null;
};