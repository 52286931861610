import "./style.scss";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
} from "@mui/material";
import { addDays, differenceInCalendarDays, endOfMonth, endOfQuarter, format, getMonth, getYear, startOfYear } from "date-fns";
import { FeedbackTableProps } from "../../../../../interfaces/pms/team-evaluation";
import { capitalize } from "../../../../../utilities/helper";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PmsParametersService } from "../../../../../services/pms/pms-parameters";
import ErrorMessage from "../../../../../components/shared/error-message";
import CustomTable from "../../../../../components/mui/table";
import CustomTypography from "../../../../../components/mui/max-length-limit";

export const generateEvaluationPeriods = (
  startDate: Date,
  endDate: Date,
  frequency: string
): { label: string; cycle: number; year: number; periodStart: Date; periodEnd: Date }[] => {
  const periods = [];
  let currentDate = startDate;
  let currentYear = getYear(currentDate);
  let cycle: number;
  switch (frequency) {
    case "MONTHLY":
      cycle = getMonth(startDate) + 1; 
      break;
    case "WEEKLY":
      cycle = Math.ceil(differenceInCalendarDays(startDate, startOfYear(startDate)) / 7) + 1; 
      break;
    case "BI-WEEKLY": {
      const startOfYearDate = startOfYear(startDate);
      const daysFromStartOfYear = differenceInCalendarDays(currentDate, startOfYearDate);
      cycle = Math.ceil(daysFromStartOfYear / 14) + 1; 
      break;
    }
    case "QUARTERLY":
      cycle = Math.ceil((getMonth(startDate) + 1) / 3); 
      break;
    default:
      throw new Error("Invalid frequency");
  }

  while (currentDate <= endDate) {
    let periodEnd;
    let label;

    switch (frequency) {
      case "WEEKLY":
        periodEnd = addDays(currentDate, 6);
        label = `Week ${cycle} (${format(currentDate, "dd MMM")} - ${format(periodEnd, "dd MMM")})`;
        break;

      case "BI-WEEKLY":
        periodEnd = addDays(currentDate, 13);
        label = `Bi-Week ${cycle} (${format(currentDate, "dd MMM")} - ${format(periodEnd, "dd MMM")})`;
        break;

      case "MONTHLY":
        periodEnd = endOfMonth(currentDate);
        label = `${format(currentDate, "MMMM yyyy")}`;
        break;

      case "QUARTERLY":
        periodEnd = endOfQuarter(currentDate);
        label = `Q${cycle} (${format(currentDate, "MMM yyyy")} - ${format(periodEnd, "MMM yyyy")})`;
        break;

      default:
        throw new Error("Invalid frequency");
    }

    if (periodEnd > endDate) {
      periodEnd = endDate;
    }

    periods.push({
      label,
      cycle,
      year: getYear(currentDate),
      periodStart: currentDate,
      periodEnd,
    });

    currentDate = addDays(periodEnd, 1);
    const nextYear = getYear(currentDate);

    
    if (nextYear !== currentYear) {
      cycle = 1;
      currentYear = nextYear;
    } else {
      
      switch (frequency) {
        case "MONTHLY":
          cycle++;
          break;
        case "WEEKLY":
        case "BI-WEEKLY":
          cycle++;
          break;
        case "QUARTERLY":
          cycle++;
          break;
      }
    }
  }

  return periods;
};



const FeedbackTable: React.FC<FeedbackTableProps> = ({
  data,
  startDate,
  endDate,
}) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const { id } = useParams();
  const { getPmsParameter } = PmsParametersService();
  const parameter = useQuery({
    queryKey: ["parameter-data", id],
    queryFn: () =>
      getPmsParameter({
        _id: id,
      }),
  });
  const parameterFrequency =
    parameter && parameter.data && parameter.data.data && parameter.data.data.frequency || "";

    let evaluationPeriods: { label: string; cycle: number, year: number, periodStart: Date, periodEnd: Date }[] = [];
    if (parameterFrequency) {
      evaluationPeriods = generateEvaluationPeriods(start, end, parameterFrequency).filter(
        (period) => period.periodStart >= start && period.periodStart <= end
      );
    }
  const hasData = !!data.length;

  const columns = [
    {
      id: "user",
      label: "User",
    },
    {
      id: "subParameter",
      label: "Sub Parameter",
    }
  ];

  return (
    <>
      { hasData ?
        <TableContainer component={Paper} className="table-feedback-container"> 
          <Table stickyHeader aria-label="sticky table" className="table">
            <TableHead style={{ position: "sticky", zIndex:3 }}>
              <TableRow>
                <TableCell className="fix-width header sticky-cell-1 border">User</TableCell>
                <TableCell className="fix-width header sticky-cell-2 border">Sub Parameter</TableCell>
                {hasData && evaluationPeriods.map((period) => (
                  <TableCell key={`period-${period.cycle}-${period.label}`} align="center" className="fix-width header">
                    {period.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {hasData ? data.map((userFeedback) =>
                userFeedback.subParameters.map((subParam, index) => (
                  <TableRow key={`${userFeedback._user}-${index}`}>
                    {index === 0 && (
                <TableCell rowSpan={userFeedback.subParameters.length} className="border sticky-cell-1">
                    <CustomTypography
                      limit={20}
                      label={capitalize(userFeedback.userName) ?? ""}
                    />
                </TableCell>
              )}
              <TableCell className="border sticky-cell-2">
                <CustomTypography
                  limit={20}
                  label={capitalize(subParam.subParameterName) ?? ""}
                />
              </TableCell>
              {evaluationPeriods.map((period) => {
                const rating = subParam.ratings.find(
                  (r) => r.evaluationCycle === period.cycle && r.year === period.year
                );
                return (
                        <TableCell key={`period-${period.cycle}-${period.label}`} align="center">
                          {rating ? rating.rating : "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : 
              <TableRow>
                  <TableCell colSpan={evaluationPeriods.length + 1} className="bg-white"> 
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: "790px" }} 
                    >
                      <ErrorMessage errorMessage="No ratings found" />
                    </Grid>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        : 
        <Box marginTop="10px">
            <CustomTable
                columns={columns}
                rows={[]}
                height="calc(100vh - 290px)"
                errorMessage="Add ratings to see the data here"
            />
        </Box>
      }
    </>
  );
};

export default FeedbackTable;
