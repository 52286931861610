import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { KrRequestsService } from "../../../../../services";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { capitalize, formatDate } from "../../../../../utilities/helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../../components/shared/mui-tabs";
import { useState } from "react";
import { okrKeyResultsService } from "../../../../../services/okr/objectives/key-results";

const KRRequestTimeline = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const { getApprovedOrRejectedRequestsTimeline } = KrRequestsService();
  const { getKeyResult } = okrKeyResultsService();
  const timeline = useQuery({
    queryKey: ["key-result-timeline", id],
    queryFn: () => getApprovedOrRejectedRequestsTimeline({ _keyResult: id }),
    enabled: id !== "new",
  });
  const kr = useQuery({
    queryKey: ["key-result-name"],
    queryFn: () => getKeyResult({ _id: id }),
    enabled: !!id,
  });
  const krName = kr.data?.data?.name || "Your Evaluation";

  const handleAccordionTabClick = (index: number) => {
    setExpandedIndex(index !== expandedIndex ? index : null);
  };
  const timelineData = timeline.data?.data;
  const fields = [
    {
      label: "Submitted On",
      name: "submittedOn",
      type: "input",
    },
    {
      label: "Target Figure",
      name: "targetFigure",
      type: "input",
    },
    {
      label: "Achieved Value",
      name: "achievedValue",
      type: "input",
    },
    {
      label: "Status",
      name: "status",
      type: "input",
    },
    {
      label: "Note",
      name: "note",
      type: "input",
    },
    {
      label: "Feedback",
      name: "feedback",
      type: "input",
    },
  ];

  const onClose = () => {
    setExpandedIndex(null);
    navigate({
      pathname: "/okr-self-evaluation/approved-rejected",
      search: searchParam.toString(),
    });
  };

  return (
    <BootstrapDialog maxWidth="xl" onClose={onClose} open={true}>
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          {capitalize(krName)}
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {timelineData &&
          timelineData.map((item, index) => (
            <Accordion
              key={item._id}
              className="mb-4"
              expanded={expandedIndex === index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={() => handleAccordionTabClick(index)}
              >
                <Typography>Revision {timelineData.length - index}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  <Grid container spacing={2}>
                    {fields.map((field) => (
                      <Grid key={field.label} item xs={12} lg={4} md={6}>
                        <TextField
                          variant="outlined"
                          label={field.label}
                          fullWidth
                          value={
                            field.name === "status"
                              ? `${capitalize(item.status)} by ${capitalize(
                                  item.actionTakenBy?.name
                                )}`
                              : field.name === "submittedOn"
                              ? formatDate(item.submittedOn)
                              : item[field.name as keyof typeof item]
                          }
                          multiline={
                            field.name === "note" || field.name === "feedback"
                          }
                          minRows={
                            field.name === "note" || field.name === "feedback"
                              ? 3
                              : 1
                          }
                          maxRows={
                            field.name === "note" || field.name === "feedback"
                              ? 3
                              : 1
                          }
                          disabled={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                }
              </AccordionDetails>
            </Accordion>
          ))}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default KRRequestTimeline;
