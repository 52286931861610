import "./style.scss";
import { useEffect, useState, MouseEvent } from "react";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { RequestedSkillService } from "../../services";
import { IPagination, IRequestedSkill, IRequestedSkillRow, IRequestedSkillState } from "../../interfaces/";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Chip } from "@mui/material";
import { capitalize, createIndex, checkRequestedSkillStatusColor, formatDate } from "../../utilities/helper";
import Header from "../../components/header";
import useDebounce from "../../hooks/useDebounce";
import CustomTable from "../../components/mui/table";
import RequestedSkillFilter from "./filters";

const RequestedSkill = () => {
    let rows: IRequestedSkillRow[] = [];
    const { getRequestedSkills } = RequestedSkillService();
    const navigate = useNavigate();
    const [search, setSearch] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState<IRequestedSkillState>({
        deleteWarning: false,
        requestedSkill: "",
        filterDialog: {
            anchorEl: null,
            isOpen: false
        },
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1
        },
        filterCount: 0,
        filters: {
            search: "",
            status: [],
            roles: []
        },
    });

    const getRequestedSkill = useQuery({
        queryKey: ["allRequestedSkill", state.pagination.page, state.filters],
        queryFn: () => getRequestedSkills({
            pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
        })
    });
    const searchRecord = useDebounce(search, 1000);
    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev, 
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }
    }, [searchRecord]);

    useEffect(() => {
        if (getRequestedSkill.data?.data?.length) {
            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: getRequestedSkill.data.meta.page,
                    totalPages: getRequestedSkill.data.meta.totalPages,
                    totalRecords: getRequestedSkill.data.meta.totalRecords
                }
            }));
        }
    }, [getRequestedSkill.data?.meta]);

    useEffect(() => {
        if (getRequestedSkill.data && getRequestedSkill.data.data && getRequestedSkill.data.data.length === 0) {
          setState((prevState) => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
              totalPages: 1,
              totalRecords: 0,
            },
          }));
        }
      }, [getRequestedSkill.data && getRequestedSkill.data.data]);

    useEffect(() => {
        let filterCount = 0;
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const roles: { key: string, value: string }[] = searchParams.get("roles") ? JSON.parse(String(searchParams.get("roles"))) : [];

        filterCount += status.length ? 1 : 0;
        filterCount += roles.length ? 1 : 0;
        setState(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page
            },
            filters: {
                ...prevState.filters,
                search,
                status: status.map(item => item.key),
                roles: roles.map(item => item.key),
            },
            filterCount
        }));
    }, [searchParams]);

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
        ...prevState,
        filterDialog: {
          ...prevState.filterDialog,
          anchorEl: e.currentTarget,
          isOpen: !state.filterDialog.isOpen
        }
      }));
    
      const closeFilter = () => {
        setState(prevState => ({
          ...prevState,
          filterDialog: {
            ...prevState.filterDialog,
            isOpen: false
          }
        }));
      };

    const columns = [
        {
            id: "id",
            label: "S No."
        },
        {
            id: "employee",
            label: "Employee"
        },
        {
            id:"skill",
            label:"Skill"
        },
        {
            id: "role",
            label: "Role"
        },
        {
            id: "requestedOn",
            label: "Requested On"
        },
        {
            id: "status",
            label: "Status"
        },
        {
            id: "action",
            label: "Actions"
        },
    ];

    const createRow = (index: number, requestedSkill: IRequestedSkill, pagination: IPagination) => {

        const action = (
            <span className="primaryColor" onClick={() => navigate({ pathname: "" + requestedSkill?._id, search: searchParams.toString() })} >View</span>
        );

        return {
            id: createIndex(pagination, index),
            employee: capitalize(requestedSkill.createdBy?.name),
            role: capitalize(requestedSkill.role?.name),
            skill:capitalize(requestedSkill?._skillParam?.name),
            requestedOn: formatDate(requestedSkill?.createdAt),
            status: <Chip variant={"outlined"} color={checkRequestedSkillStatusColor(requestedSkill.status)} label={capitalize(requestedSkill.status)} />,
            action,
        };
    };

    if (getRequestedSkill.data?.data?.length) {
        rows = getRequestedSkill?.data?.data.map((requestedSkill, i) => createRow(i, requestedSkill, state.pagination));
    }

    return (
        <>
            {/* Add Data  */}
            <Header
                className='my-2'
                searchPlaceholder="Search by employee"
                onSearch={onSearch}
                onFilter={openFilter}
                filterCount={state.filterCount}
            >
                <RequestedSkillFilter
                    anchorEl={state.filterDialog.anchorEl}
                    isOpen={state.filterDialog.isOpen}
                    OnClose={closeFilter}
                />
            </Header>

            {/* Show Data  */}
            <Box marginTop="10px">
                <CustomTable
                    columns={columns}
                    rows={rows}
                    height="calc(100vh - 198.3px)"
                    pagination={state.pagination}
                    onPageChange={onPageChange}

                />
            </Box>
            <Outlet context={{ reFetch: getRequestedSkill.refetch }} />
        </>
    );

};

export default RequestedSkill;