import { IEvaluationAnalytics, ISelfEvaluationResonse, ITeamReportResponse } from "../../../interfaces/pms/self-evaluation";
import { pmsRatingFeedback } from "../../endpoints";
import HttpService from "../../http";


const SelfEvaluationService = () => {
    const { httpRequest } = HttpService();

    const getSelfEvaluationOverview = async (search: object): Promise<ISelfEvaluationResonse> => new Promise((resolve, reject) => {
        httpRequest<ISelfEvaluationResonse>(
            "GET",
            `${pmsRatingFeedback}/self-evaluation-overview`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getRatingAnalytics = async (search: object): Promise<IEvaluationAnalytics> => new Promise((resolve, reject) => {
        httpRequest<IEvaluationAnalytics> (
            "GET",
            `${pmsRatingFeedback}/self-evaluation-overall`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getSelfEvaluationOverviewParameterWise = async (search: object): Promise<ITeamReportResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamReportResponse>(
            "GET",
            `${pmsRatingFeedback}/self-evaluation-overview-parameter-wise`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { getSelfEvaluationOverview,  getRatingAnalytics, getSelfEvaluationOverviewParameterWise };
};

export { SelfEvaluationService };