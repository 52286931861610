import { ChangeEvent } from "react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../../interfaces";
import { ReviewParameterService } from "../../../services";
import { capitalize, createIndex } from "../../../utilities/helper";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { IReviewParameterRow, IReviewParameterState, IReviewParameter } from "../../../interfaces";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useResource from "../../../hooks/useResource";

const ReviewParameter = () => {
    let rows: IReviewParameterRow[] = [];
    const { snackbar } = useSnackbar(); const [searchParams, setSearchParams] = useSearchParams();
    const { getReviewParameters, deleteReviewParameter } = ReviewParameterService();
    const navigate = useNavigate();
    const { resourceAllocate } = useResource();
    const [search, setSearch] = useState<string>("");
    const [state, setState] = useState<IReviewParameterState>({
        deleteWarning: false,
        _reviewParameter: "",
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1
        },
        filters: {
            search: ""
        }
    });

    const reviewparameters = useQuery({
        queryKey: ["allReviewParameters", state.pagination.page, state.filters],
        queryFn: () => getReviewParameters({
            pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
        })
    });

    const searchRecord = useDebounce(search, 1000);
    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }
    }, [searchRecord]);

    useEffect(() => {
        if (reviewparameters.data?.data.length) {
            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: reviewparameters.data.meta.page,
                    totalPages: reviewparameters.data.meta.totalPages,
                    totalRecords: reviewparameters.data.meta.totalRecords
                }
            }));
        }
    }, [reviewparameters.data?.meta]);

    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

        setState(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page
            },
            filters: {
                ...prevState.filters,
                search
            }
        }));
    }, [searchParams]);

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const handleDelete = (_reviewParameter = "") => {
        setState(prevState => ({
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
            _reviewParameter
        }
        ));
    };

    useEffect(() => {
        if (reviewparameters.data && reviewparameters.data.data && reviewparameters.data.data.length === 0) {
          setState((prevState) => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
              totalPages: 1,
              totalRecords: 0,
            },
          }));
        }
      }, [reviewparameters.data && reviewparameters.data.data]);

    const onDelete = async () => {
        try {
            const deleted = await deleteReviewParameter({ _id: state._reviewParameter });
            snackbar(deleted.message, "info");
            handleDelete();
            reviewparameters.refetch();
        } catch (error) {
            console.log(error);
        }
    };

    const columns = [
        {
            id: "id",
            label: "S No."
        },
        {
            id: "reviewParameter_name",
            label: "Name"
        },
        {
            id: "reviewParameter_jobtitle",
            label: "Job Title"
        },
        {
            id: "reviewParameter_createdBy",
            label: "Created By"
        },
        {
            id: "action",
            label: "Actions"
        },
    ];

    const createRow = (index: number, reviewParameter: IReviewParameter, pagination: IPagination) => {
        const action = <GetActions
            icons={[
                { name: "Edit", method: () => navigate({ pathname: "" + reviewParameter._id, search: searchParams.toString() }), disabled: resourceAllocate("review-parameter.write") ? false : true },
                { name: "Delete", method: () => handleDelete(reviewParameter._id), disabled: resourceAllocate("review-parameter.remove") ? false : true },
            ]}
        />;

        return {
            id: createIndex(pagination, index),
            reviewParameter_name: capitalize(reviewParameter.name),
            reviewParameter_jobtitle: capitalize(reviewParameter.jobTitle),
            reviewParameter_createdBy: capitalize((reviewParameter && reviewParameter.createdBy && reviewParameter.createdBy.name) ?? ""),
            action,
        };
    };

    if (reviewparameters.data?.data.length) {
        rows = reviewparameters.data?.data.map((reviewParameter, i) => createRow(i, reviewParameter, state.pagination));
    }

    return (
        <>
            {/* Add Data  */}
            <Header
                className='my-2'
                searchPlaceholder="Search by name of review parameter"
                onSearch={onSearch}
                btnText="ADD REVIEW PARAMETER"
                onBtnClick={resourceAllocate("review-parameter.write") ? () => navigate("new") : undefined}
            />

            {/* Show Data  */}
            <Box marginTop="10px">
                <CustomTable
                    columns={columns}
                    rows={rows}
                    height="calc(100vh - 248px)"
                    pagination={state.pagination}
                    onPageChange={onPageChange}
                />
            </Box>

            {/* Delete Data  */}
            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete Review Parameter"
                description="Are you sure you want to delete this review parameter?"
            />

            <Outlet context={{ reFetch: reviewparameters.refetch }} />
        </>
    );
};

export default ReviewParameter;