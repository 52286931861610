import "../../blog/bulk assignment/style.scss";
import "../../../../components/shared/filter/style.scss";
import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Box, Button, capitalize, Grid, IconButton, MenuItem, Menu } from "@mui/material";
import Select from "../../../../components/mui/select";
import { IErrorResponse } from "../../../../interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "@tanstack/react-query";
import useSnackbar from "../../../../hooks/useSnackbar";
import useResource from "../../../../hooks/useResource";
import { displayName } from "../../../../utilities/helper";
import DateTime from "../../../../components/mui/date-time";
import { UsersService } from "../../../../services";
import { IGlossaryAssignment, IGlossaryState } from "../../../../interfaces/content-marketing/glossary";
import { GlossaryAssignmentService } from "../../../../services/glossary/assignment";
import { glossaryBulkAssignmentValidation } from "../../../../validations/content-marketing/glossary";
import CustomLabel from "../../../../components/mui/custom-label";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    ids: string[];
    closeDialog: () => void;
    setState: React.Dispatch<React.SetStateAction<IGlossaryState>>
}

const BulkAssignment: FC<props> = ({ anchorEl, isOpen, closeDialog, ids, setState }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getRoleBasedUser } = UsersService();
    const writerList = useQuery({
        queryKey: ["writer-list-for-dropdown"],
        queryFn: () => getRoleBasedUser({ role: ["writer","reviewer","content admin"] }),
    });

    const reviewerList = useQuery({
        queryKey: ["reviewer-list-for-dropdown"],
        queryFn: () => getRoleBasedUser({ role: ["reviewer","content admin"] }),
    });

    const designerList = useQuery({
        queryKey: ["illustrator-list-for-dropdown"],
        queryFn: () => getRoleBasedUser({ role: "illustrator" }),
    });

    const publisherList = useQuery({
        queryKey: ["publisher-list-for-dropdown"],
        queryFn: () => getRoleBasedUser({ role: "publisher" }),
    });

    const { snackbar } = useSnackbar();
    const { bulkGlossaryAssignment } = GlossaryAssignmentService();
    const { id } = useParams();

    const { resourceAllocate } = useResource();

    const { handleSubmit, control, getValues, setValue, reset, formState: { errors } } = useForm<IGlossaryAssignment>({
        resolver: joiResolver(glossaryBulkAssignmentValidation),
        defaultValues: {
            writer: {
                _user: "",
                submissionDate: dayjs().startOf("day").toISOString()
            },
            reviewer: {
                _user: "",
                submissionDate: dayjs().add(2, "days").startOf("day").toISOString()
            },
            illustrator: {
                _user: "",
                submissionDate: dayjs().add(4, "days").startOf("day").toISOString()
            },
            publisher: {
                _user: "",
                submissionDate: dayjs().add(6, "days").startOf("day").toISOString()
            }
        }
    });

    const changeDate = (e: dayjs.Dayjs | null, name: string) => {
        const newDate = e ? dayjs(e).startOf("day").toISOString() : "";

        if (name === "writer.submissionDate") {
            setValue(name, newDate);
        }
        else if (name === "reviewer.submissionDate") {
            setValue(name, newDate);
        }
        else if (name === "illustrator.submissionDate") {
            setValue(name, newDate);
        }
        else if (name === "publisher.submissionDate") {
            setValue(name, newDate);
        }

    };

    const handleClose = () => {
        reset();
        closeDialog();
    };

    const onSubmit = async (data: IGlossaryAssignment) => {
        try {
            if (id === "new") {
                snackbar("Please add basic details first.", "error");
            } else {
                const response = await bulkGlossaryAssignment({ ...data, glossaryIds: ids });
                snackbar(response.message, "info");
                setState((prevState) => ({
                    ...prevState,
                    selectAll: []
                }));
                handleClose();
                navigate({
                    pathname: "/glossary",
                    search: searchParams.toString()
                });
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
        }
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >

            <Box id="filters-container">
                <Box className="center mb-1" display={"flex"} justifyContent="flex-end" alignItems="center">
                    <div className="active-filter mb-1">
                        <IconButton
                            onClick={handleClose}
                            style={{ marginRight: "-10px" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid className="filter-box" container spacing={4}>

                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="writer._user"
                                label={<CustomLabel label="Writer" required />}
                                size={"small"}
                                variant={"outlined"}
                                error={!!errors["writer"]?._user}
                                helperText={errors["writer"]?._user?.message}
                            >
                                {(writerList && writerList.data && writerList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Submission Date" required />}
                                name="writer.submissionDate"
                                changeDate={changeDate}
                                value={getValues("writer.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="reviewer._user"
                                label={<CustomLabel label="Reviewer" required />}
                                size={"small"}
                                variant={"outlined"}
                                error={!!errors["reviewer"]?._user}
                                helperText={errors["reviewer"]?._user?.message}
                            >
                                {(reviewerList && reviewerList.data && reviewerList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}

                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Reviewer Date" required />}
                                name="reviewer.submissionDate"
                                changeDate={changeDate}
                                value={getValues("reviewer.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="illustrator._user"
                                label={<CustomLabel label="Designer" required />}
                                size={"small"}
                                variant={"outlined"}
                                error={!!errors["illustrator"]?._user}
                                helperText={errors["illustrator"]?._user?.message}
                            >
                                {(designerList && designerList.data && designerList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}

                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Designer Date" required />}
                                name="designer.submissionDate"
                                changeDate={changeDate}
                                value={getValues("illustrator.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Select
                                control={control}
                                className="disable-text"
                                name="publisher._user"
                                label={<CustomLabel label="Publisher" required />}
                                size={"small"}
                                variant={"outlined"}
                                error={!!errors["publisher"]?._user}
                                helperText={errors["publisher"]?._user?.message}
                            >
                                {(publisherList && publisherList.data && publisherList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}

                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <DateTime
                                label={<CustomLabel label="Publisher Date" required />}
                                name="publisher.submissionDate"
                                changeDate={changeDate}
                                value={getValues("publisher.submissionDate")}
                                disablePastDate
                            />
                        </Grid>

                    </Grid>

                    <div className="mt-4 save-box">

                        <Button
                            type="submit"
                            disabled={!resourceAllocate("cms-glossary-assignment.write")}
                        >Save</Button>
                    </div>
                </form>
            </Box>

        </Menu>
    </>;
};

export default BulkAssignment;