import { useState, SyntheticEvent, useEffect, useRef } from "react";
import { useNavigate, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../../hooks/useResource";
import "./style.scss";
import { IState } from "../../../interfaces/shared/tab-panel";
import useUser from "../../../hooks/useUser";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface outletProps {
  refetchOrganisationConfiguration: () => void;
  reFetch: () => void,
}

const OrganisationConfigurationLayout = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const location = useLocation();
  const {resourceAllocate} = useResource();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useUser();
  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
    if(resourceAllocate("organisation-configuration.read")){
      redirectionLinks.push("theme");
      redirectionLinks.push("softwares-used");
    }

      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }
  }, [user]);
  

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider", position: "sticky", top: 0, zIndex: 10, backgroundColor: "white"}}>  
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {resourceAllocate("organisation-configuration.read") && <Tab className="tabs-space start-tab-space" label="Basic Details" {...a11yProps(0)} />}
        {resourceAllocate("organisation-configuration.read") && <Tab className="tabs-space" label="Softwares" {...a11yProps(0)} />}
        </Tabs>
      </Box>
      <Outlet context={{ inputRef , ...outlet}} />
    </Box>
  );
};

export {OrganisationConfigurationLayout};