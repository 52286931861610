import { useEffect, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { useSearchParams, Outlet } from "react-router-dom";
import useSnackbar from "../../../hooks/useSnackbar";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { IPagination } from "../../../interfaces";
import { skillDevelopmentServices } from "../../../services/skill-development";
import { capitalize, createIndex, formatDate } from "../../../utilities/helper";
import {
  ISkillDevelopment,
  ISkillDevelopmentRow,
  ISkillDevelopmentState,
} from "../../../interfaces/skill-development";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import SkillDevelopmentFilter from "../../skill-development-training/list/filters";
import useUser from "../../../hooks/useUser";
const SkillDevelopmentList = () => {
  let rows: ISkillDevelopmentRow[] = [];
  const { resourceAllocate } = useResource();
  const { user } = useUser();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    getSkillDevelopmentList,
    deleteSkillDevelopment,
  } = skillDevelopmentServices();

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<ISkillDevelopmentState>({
    skillDevelopment: "",
    pagination: { page: 1, limit: 20, totalPages: 1 },
    multiDeleteWarning: false,
    selectAll: [],
    filterDialog: { anchorEl: null, isOpen: false },
    filters: {
      search: "",
      completionDate: [],
      courseStartDate: [],
      courseEndDate: [],
      course: [],
      status: [],
    },
    deleteWarning: false,
    filterCount: 0,
    searching: "",
  });
  
  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "assignTo",
      label: "User",
    },
    {
      id: "_mentor",
      label: "Mentor",
    },
    {
      id: "course",
      label: "Course",
    },
    {
      id: "startDate",
      label: "Start Date",
    },
    {
      id: "endDate",
      label: "End Date",
    },
    {
      id: "completionDate",
      label: "Completion Date",
    },
    { id: "status", label: "Status" },
    {
      id: "score",
      label: "Score(%)",
    },
    {
      id: "action",
      label: "Action",
    }
  ];

  const searchRecord = useDebounce(state.filters.search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const getSkillDevelopmentTraining = useQuery({
    queryKey: [
      "SkillDevelopmentList",
      state.pagination.page,
      state.searching,
      state.filters,
    ],
    queryFn: () =>
      getSkillDevelopmentList({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.filters,
      }),
  });

  useEffect(() => {
    if (
      getSkillDevelopmentTraining.data &&
      getSkillDevelopmentTraining.data.data &&
      getSkillDevelopmentTraining.data.data.length
    ) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getSkillDevelopmentTraining.data.meta.page,
          totalPages: getSkillDevelopmentTraining.data.meta.totalPages,
          totalRecords: getSkillDevelopmentTraining.data.meta.totalRecords,
        },
      }));
    }
  }, [getSkillDevelopmentTraining.data?.meta]);

  useEffect(() => {
    if (getSkillDevelopmentTraining.data && getSkillDevelopmentTraining.data.data && getSkillDevelopmentTraining.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [getSkillDevelopmentTraining.data && getSkillDevelopmentTraining.data.data]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const course: { key: string; value: string }[] = searchParams.get(
      "course"
    )
      ? JSON.parse(String(searchParams.get("course")))
      : [];
    const status: { key: string; value: string }[] = searchParams.get("status")
      ? JSON.parse(String(searchParams.get("status")))
      : [];
    const courseStartDate: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("courseStartDate")
      ? JSON.parse(String(searchParams.get("courseStartDate")))
      : [];
    const courseEndDate: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("courseEndDate")
      ? JSON.parse(String(searchParams.get("courseEndDate")))
      : [];
    const completionDate: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("completionDate")
      ? JSON.parse(String(searchParams.get("completionDate")))
      : [];

    filterCount += course.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += courseStartDate.length ? 1 : 0;
    filterCount += completionDate.length ? 1 : 0;
    filterCount += courseEndDate.length ? 1 : 0;

    let courseCompletionDateFinal:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (completionDate && completionDate.length) {
      courseCompletionDateFinal = {
        startDate: completionDate && completionDate[0].startDate,
        endDate: completionDate && completionDate[0].endDate,
      };
    }

    let courseTrainingStartDate:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (courseStartDate && courseStartDate.length) {
      courseTrainingStartDate = {
        startDate: courseStartDate && courseStartDate[0].startDate,
        endDate: courseStartDate && courseStartDate[0].endDate,
      };
    }

    let courseTrainingEndDate:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (courseEndDate && courseEndDate.length) {
      courseTrainingEndDate = {
        startDate: courseEndDate && courseEndDate[0].startDate,
        endDate: courseEndDate && courseEndDate[0].endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        course: course.map((item) => item.key),
        status: status.map((item) => item.key),
        courseCompletionDateFinal: courseCompletionDateFinal,
        courseTrainingStartDate: courseTrainingStartDate,
        courseTrainingEndDate: courseTrainingEndDate,
      },
      filterCount,
    }));
  }, [searchParams]);

  const handleDelete = (skillDevelopment = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      skillDevelopment,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteSkillDevelopment({
        _id: state.skillDevelopment,
      });
      snackbar(deleted.message, "info");
      handleDelete();
      getSkillDevelopmentTraining.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search: e.target.value,
      },
    }));

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const createRow = (
    index: number,
    skillDevelopment: ISkillDevelopment,
    pagination: IPagination
  ) => {
    const isDisabled = !(
      resourceAllocate("course-assignment.edit") ||
      (resourceAllocate("course-assignment.editAssigned") && 
       String(skillDevelopment._mentor._id) === String(user._id))
    );   
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: `${skillDevelopment._id}`,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("course-assignment.edit"),
          },
          {
            name: "Edit Score",
            method: () =>
              navigate({
                pathname: `edit-score/${skillDevelopment._id}`,
                search: searchParams.toString(),
              }),
            disabled: isDisabled },
          {
            name: "View Analytics",
            method: () => {
              const skillParameters = skillDevelopment?.course?._skillParameter || [];
              const transformedParameters = skillParameters.map(param => ({
                key: param._id,
                value: param.name
              }));
              const timeArray = [{
                key: "Custom",
                startDate: skillDevelopment.startDate,
                endDate: skillDevelopment.endDate
              }];
              const searchParams = new URLSearchParams({
                time: JSON.stringify(timeArray),
                parameter: JSON.stringify(transformedParameters),
                designation: JSON.stringify([]),
                employee: JSON.stringify([{ key: skillDevelopment.assignTo._id , value: skillDevelopment.assignTo.name }]),
              });
          
              navigate({
                pathname: "/skill-analytics",
                search: searchParams.toString(),
              });
            },
            disabled: !(resourceAllocate("skill-matrix-analytics.read"))
          },

          {
            name: "Delete",
            method: () => handleDelete(skillDevelopment?._id),
            disabled: !(resourceAllocate("course-assignment.remove") || resourceAllocate("course-assignment.removeAssigned"))
          },
        ]}
      />
    );
 

    return {
      id: createIndex(pagination, index),
      assignTo: capitalize(skillDevelopment?.assignTo?.name || "") || "N/A",
      _mentor: capitalize(skillDevelopment?._mentor?.name || "") || "N/A",
      course: capitalize(skillDevelopment?.course.name) || "N/A",
      startDate: formatDate(skillDevelopment?.startDate) || "N/A",
      endDate: formatDate(skillDevelopment?.endDate) || "N/A",
      completionDate: formatDate(skillDevelopment?.completionDate) || "pending",
      score: skillDevelopment.score || "Pending",
      status: capitalize(skillDevelopment.status) || "N/A",
      action,
    };
  };
  
  if (
    getSkillDevelopmentTraining.data &&
    getSkillDevelopmentTraining.data.data &&
    getSkillDevelopmentTraining.data.data.length
  ) {
    rows =
      getSkillDevelopmentTraining.data.data.map((skillDevelopment, i) =>
        createRow(i, skillDevelopment, state.pagination)
      );
  }

  return (
    <>
      <Header
        className="my-2"
        btnText="Assign Course Training"
        searchPlaceholder="Search by course name"
        onSearch={onSearch}
        onBtnClick={
          resourceAllocate("course-assignment.write")
            ? () =>
                navigate({ pathname: "new", search: searchParams.toString() })
            : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <SkillDevelopmentFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnCloseFilter={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 250px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Skill Development Training"
        description="Are you sure you want to delete this skill development training?"
      />

      <Outlet context={{ reFetch: getSkillDevelopmentTraining.refetch }} />
    </>
  );
};
export default SkillDevelopmentList;
