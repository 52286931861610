import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams, Outlet } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { a11yProps, BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../components/shared/mui-tabs";
import useResource from "../../../../hooks/useResource";
import ManageAssessment from "./basic-details";
import ManageAssessmentComposition from "./composition";
import useSnackbar from "../../../../hooks/useSnackbar";
import { CMS_ERROR_MESSAGES } from "../../../../utilities/messages";

interface outletProps {
    reFetch: () => void
}


interface IState {
    redirectionLinks: string[];
}

const ManageAssessmentLayout = () => {
    const navigate = useNavigate();
    const { resourceAllocate } = useResource();
    const outlet = useOutletContext<outletProps>();
    const { id } = useParams();
    const [searchParam, setSearchParam] = useSearchParams();
    const [value, setValue] = useState(0);
    const { snackbar } = useSnackbar();
    const [state, setState] = useState<IState>({
        redirectionLinks: [],
    });

    useEffect(() => {
        const redirectionLinks = [...state.redirectionLinks];
        if (!redirectionLinks.length) {
            if (resourceAllocate("assessment.write")) {
                redirectionLinks.push("basic-detail");
                redirectionLinks.push("composition");
            }

            setState((prev) => ({
                ...prev,
                redirectionLinks,
            }));
        }

        const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
        setValue(redirectionLinks.indexOf(tab));
    }, [state.redirectionLinks, searchParam]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        if (id === "new" && newValue !== 0) {
            snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
            return;
        }
        searchParam.set("type", state.redirectionLinks[newValue] ?? "basic-detail");
        setSearchParam(searchParam);
    };

    const onClose = () => {
        searchParam.delete("type");
        navigate({ pathname: "/assessment/assessments", search: searchParam.toString() });
    };

    return <div>
        <BootstrapDialog
            maxWidth="xl"
            onClose={onClose}
            open={!!id}
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    Assessment Details
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ height: "65vh" }}>
                <Box width="100%" height="100%">
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>

                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            {resourceAllocate("assessment.write") && <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />}
                            {resourceAllocate("assessment.write") && <Tab className="tabs-space" label="Composition" {...a11yProps(1)} />}
                        </Tabs>
                    </Box>

                    {/* Basic detail  */}
                    <TabPanel
                        style={{
                            overflow: "auto",
                            height: "calc(100% - 120px)",
                            marginTop: 20
                        }}
                        value={value}
                        index={state.redirectionLinks.indexOf("basic-detail")}>
                        <ManageAssessment />
                    </TabPanel>

                    <TabPanel
                        style={{
                            height: "calc(100% - 120px)",
                            overflow: "auto",
                        }}
                        value={value}
                        index={state.redirectionLinks.indexOf("composition")}>
                        < ManageAssessmentComposition />
                    </TabPanel>

                </Box>
            </DialogContent>
        </BootstrapDialog>

        <Outlet context={{ ...outlet }} />
    </div>;
};

export default ManageAssessmentLayout;
