import { IOrganisationSoftwaresResponse } from "../../../interfaces/organisationConfiguration/softwares";
import { orgConfig } from "../../endpoints";
import HttpService from "../../http";


const OrganisationSoftwaresService = () => {
  const { httpRequest } = HttpService();

  const getOrganisationSortwares =
    async (): Promise<IOrganisationSoftwaresResponse> =>
      new Promise((resolve, reject) => {
        httpRequest<IOrganisationSoftwaresResponse>("GET", `${orgConfig}/softwares`)
          .then(resolve)
          .catch(reject);
      });

  const updateOrganisationSoftwares = async (
    payload: object
  ): Promise<IOrganisationSoftwaresResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<IOrganisationSoftwaresResponse>("PUT", `${orgConfig}/softwares`, payload)
        .then(resolve)
        .catch(reject);
    });

  return { getOrganisationSortwares, updateOrganisationSoftwares };
};

export { OrganisationSoftwaresService };
