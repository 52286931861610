import { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { UsersService } from "../../../../services";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  IErrorResponse,
  IJobPosition,
  IRole,
  ITeam,
  IUser,
  IUserField,
  IUserValues,
} from "../../../../interfaces";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { userValidation } from "../../../../validations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomDialog from "../../../../components/mui/dialog";
import MobileNumber from "../../../../components/mui/mobile-number";
import useSnackbar from "../../../../hooks/useSnackbar";
import { capitalize } from "../../../../utilities/helper";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomLabel from "../../../../components/mui/custom-label";
import SearchSelect from "../../../../components/mui/search-select";
import { BusinessUnitsService } from "../../../../services/configuration/business-unit";
import UploadImg from "../../../../assets/images/upload.png";
import HttpService from "../../../../services/http";
import { UserRequestsService } from "../../../../services/user-requests";

interface outletProps {
  reFetch: () => void;
  refetchUsers: () => void;
}

const ManageUserRequest = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { httpFormRequest } = HttpService();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { addUser } = UsersService();
  const { getUserRequest, updateUserRequest } = UserRequestsService();
  const [showPassword, setShowPassword] = useState(false);
  const [imageSelected, setImageSelected] = useState<string | null>(null);
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(
    (state) => state.user.list
  );
  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
    (state) => state.team.list
  );
  const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>(
    (state) => state.role.list
  );
  const userRequest = useQuery({
    queryKey: ["user-request", id],
    queryFn: () => getUserRequest({ _id : id })
  });
  const { getBusinessUnits } = BusinessUnitsService();
  const businessUnits = useQuery({
    queryKey: ["all-business-units"],
    queryFn: () =>
      getBusinessUnits({
        pagination: false,
      }),
  });
  const {
    handleSubmit,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<IUserValues>({
    resolver: joiResolver(userValidation),
    defaultValues: {
      email: "",
      gender: "MALE",
      departmentId: "",
      designation: "",
      number: "",
      roleId: "",
      password: "",
      status: "ACTIVE",
      firstName: "",
      lastName: "",
      dob: "",
      joinedDate: "",
      address: "",
      legalEntry: "",
      employeeNumber: "",
      dateOfLeaving: "",
      extensionPhoneNumber: "",
      image: "",
      linkedinUrl: "",
    },
  });

  const designations = useSelector<
    { jobPosition: { list: IJobPosition[] } },
    IJobPosition[]
  >((state) => state.jobPosition.list);

  const getDesignationOptions = () =>
    designations.filter(
      (title) =>
        title &&
        title._department &&
        title._department._id === getValues("departmentId")
    );

  const getRolesOptions = () =>
    roles.filter(
      (title) =>
        title &&
        title._department &&
        title._department._id === getValues("departmentId")
    );
  const [phone, setPhone] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  const [extensionNumber, setExtensionNumber] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  useEffect(() => {
    if (userRequest.data?.data) {
      setValue("email", userRequest.data?.data?.email);
      setValue("firstName", userRequest.data?.data?.firstName);
      setValue("lastName", userRequest.data?.data?.lastName);
      setValue("image", userRequest.data?.data?.image);
      setImageSelected(userRequest.data?.data?.image);
    }
  }, [id, userRequest.data?.data]);

  const selectDate = (
    value: string | number | Date | dayjs.Dayjs | null | undefined,
    name: string
  ) => {
    const date =
      value && dayjs(value).toString() !== "Invalid Date"
        ? dayjs(value)?.toISOString()
        : undefined;
    const keyExist =
      name === "dob" || name === "joinedDate" || name === "dateOfLeaving";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  const onSubmit = async (data: IUserValues) => {
    try {
      const payload = {
        ...data,
        phone: {
          ...phone,
          number: data.number,
        },
        extensionNumber: {
          ...extensionNumber,
          number: data.extensionPhoneNumber,
        },
        _role: data.roleId,
        department: data.departmentId,
        name: `${data?.firstName} ${data?.lastName}`,
        location: {
          formattedAddress: data.address || undefined,
        },
      };
      delete payload.extensionPhoneNumber;
      delete payload.roleId;
      delete payload.number;
      delete payload.departmentId;
      delete payload.address;
      const add = await addUser(payload).then(
        async () => await updateUserRequest({ _id: id, status: "APPROVED" })
      );
      snackbar(add.message, "info");
      navigate({
        pathname: "/user-requests",
        search: searchParam.toString(),
      });
      outlet?.reFetch && outlet.reFetch();
      outlet?.refetchUsers && outlet.refetchUsers();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onNumberChange = (e: SelectChangeEvent<string>, name: string) => {
    const { value } = e.target;
    const data = value.split(":");
    if (name === "number") {
      setPhone({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    } else {
      setExtensionNumber({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/user-requests",
      search: searchParam.toString(),
    });
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["png", "jpeg", "jpg", "webp"],
          1
        );
        const keyExist = type === "image";
        if (keyExist) {
          setValue(type, uploaded?.data);
          setImageSelected(URL.createObjectURL(e.target.files[0]));
          trigger(type);
        }
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const fields: IUserField[] = [
    {
      label: "Image*",
      name: "image",
      type: "upload",
      disabled: userRequest.data?.data?.image !== ""
    },
    {
      label: "First Name",
      name: "firstName",
      type: "input",
      placeholder: "Type first name here",
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "input",
      placeholder: "Type last name here",
      required: true,
    },
    {
      label: "Employee Number",
      name: "employeeNumber",
      type: "input",
      placeholder: "Type employee number here",
    },
    {
      label: "Number",
      name: "number",
      type: "mobile-number",
      required: true,
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      placeholder: "Type your email here",
      required: true,
      disabled: true,
    },
    {
      label: "Password",
      name: "password",
      type: "input",
      placeholder: "Type your password here",
      required: true
    },
    {
      label: "Gender",
      name: "gender",
      type: "select",
      options: [
        {
          key: "MALE",
          value: "Male",
        },
        {
          key: "FEMALE",
          value: "Female",
        },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      required: true,
    },
    {
      type: "select",
      name: "reportingTo",
      label: "Reporting Manager",
      required: true,
      options: users.map((i) => ({
        key: i._id,
        value: capitalize(i.name),
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "DOB",
      name: "dob",
      type: "date",
    },
    {
      label: "Joined Date",
      name: "joinedDate",
      type: "date",
      required: true,
    },
    {
      label: "Date of leaving",
      name: "dateOfLeaving",
      type: "date",
    },
    {
      label: "Department",
      name: "departmentId",
      type: "select",
      required: true,
      options: teams.map((i) => ({
        key: i._id,
        value: capitalize(i.name),
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Role",
      name: "roleId",
      type: "select",
      required: true,
      options: getRolesOptions().map((i) => ({
        key: i._id,
        value: capitalize(i.name),
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Legal Entry",
      name: "legalEntry",
      type: "input",
      placeholder: "Type legal entry here",
    },
    {
      label: "Business Unit",
      name: "businessUnit",
      type: "select",
      options:
        (businessUnits &&
          businessUnits.data &&
          businessUnits.data.data &&
          businessUnits.data.data.map((i) => ({
            key: i._id,
            value: capitalize(i.name),
          }))) ||
        [],
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Designation",
      name: "designation",
      type: "select",
      options: getDesignationOptions().map((i) => ({
        key: i._id,
        value: capitalize(i.name),
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
      required: true,
    },
    {
      label: "Employee Address",
      name: "address",
      type: "input",
      placeholder: "Type employee address here",
    },
    {
      label: "Extension Number",
      name: "extensionPhoneNumber",
      type: "mobile-number",
    },
    {
      label: "Linked Profile Url",
      name: "linkedinUrl",
      type: "input",
      placeholder: "Type linkedin profile url here",
    },
  ];
  const onChangeDepartment = () => {
    setValue("roleId", "");
    setValue("designation", "");
  };

  return (
    <Box>
      <CustomDialog
        title={"Add User"}
        isOpen={!!id}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        confirmText="Add User"
      >
        <Grid container spacing={4}>
          {fields.map((field) => {
            if (field.type === "input" && field.name === "password") { 
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        className="disable-text"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="new-password"
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "input") {
              return (
                field.name !== "password" && (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={
                            <CustomLabel
                              label={field.label}
                              required={field?.required}
                            />
                          }
                          disabled={field.disabled}
                          className="disable-text"
                          placeholder={field.placeholder}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                          autoComplete={
                            field.name === "email" ? "username" : ""
                          }
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                )
              );
            } else if (field.type === "mobile-number") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <MobileNumber
                        key={field.label}
                        NumberFieldLabel={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        dialCodeValue={
                          field.name === "number"
                            ? `${phone?.country}:${phone?.iso2}:${phone?.dialCode}`
                            : `${extensionNumber?.country}:${extensionNumber?.iso2}:${extensionNumber?.dialCode}`
                        }
                        onChange={(event) => onNumberChange(event, field.name)}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        other={prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "date") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field?.required}
                        />
                      }
                      onChange={(e) => selectDate(e, field.name)}
                      value={dayjs(getValues(field.name))}
                      slotProps={{
                        textField: {
                          error: !!errors[field.name],
                          helperText: errors[field.name]?.message,
                        },
                      }}
                      format="LL"
                    />
                  </LocalizationProvider>
                </Grid>
              );
            } else if (field.type === "upload") {
              return (
                <Grid key={field.label} item xs={12}>
                  <Box className="center">
                    <Box
                      height="100px"
                      width="100px"
                      className={"upload-img editable"}
                      aria-label="upload picture"
                      component="label"
                    >
                      <img src={imageSelected || UploadImg} alt="review" />
                      {
                        <div className="edit-img ">
                          {imageSelected ? "Edit" : "Add"}
                        </div>
                      }
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        disabled={field.disabled}
                        onChange={(e) => uploadFile(e, field.name)}
                      />
                    </Box>
                  </Box>
                  {errors[field.name] && (
                    <span
                      style={{
                        color: "#d32f2f",
                        marginLeft: "380px",
                        fontWeight: 400,
                        fontSize: "0.85rem",
                      }}
                    >
                      {errors[field.name]?.message}
                    </span>
                  )}
                </Grid>
              );
            } else {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <SearchSelect
                    name={field.name}
                    label={
                      <CustomLabel
                        label={field.label}
                        required={field?.required}
                      />
                    }
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    options={field.options}
                    displayFieldKey={
                      field.displayFieldKey ? field.displayFieldKey : ""
                    }
                    storeFieldKey={
                      field.storeFieldKey ? field.storeFieldKey : ""
                    }
                    displayUserName={field.displayUserName}
                    capitalize={field.capitalize}
                    trigger={trigger}
                    setValue={setValue}
                    getValues={getValues}
                    onChange={() => {
                      if (field.name === "departmentId") {
                        onChangeDepartment();
                      }
                    }}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageUserRequest;
