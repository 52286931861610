import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { IGoal, IGoalRow, IGoalStatus } from "../../../interfaces/content/goals";
import { useQuery } from "@tanstack/react-query";
import GoalsService from "../../../services/content/goals";
import { IColumn, IErrorResponse } from "../../../interfaces";
import GetActions from "../../../components/get-actions";
import useSnackbar from "../../../hooks/useSnackbar";
import WarningDialog from "../../../components/mui/warning-dialog";
import { Box, Checkbox, Typography } from "@mui/material";
import { handleSelect, handleSelectAll } from "../common/helper";
import useResource from "../../../hooks/useResource";

const Goals = () => {

    const [search, setSearch] = useState("");
    const { getContentsGoals, deleteContentGoal } = GoalsService();
    const { snackbar } = useSnackbar();
    const { resourceAllocate } = useResource();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState<IGoalStatus>({
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1,
            totalRecords: 0
        },
        multiDeleteWarning: false,
        selectAll: [],
        searching: {
            search: ""
        },
        deleteWarning: false,
        _deleteId: "",
    });


    const [openState, setOpenState] = useState(false);
    const navigate = useNavigate();
    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
    const searchRecord = useDebounce(search, 1000);


    const GoalsListData = useQuery({
        queryKey: ["Goals", state.pagination.page, state.searching],
        queryFn: () => getContentsGoals({
            pagination: true, page: state.pagination.page, limit: state.pagination.limit, ...state.searching
        })
    });

    useEffect(() => {
        if (GoalsListData?.data?.data?.length) {
            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: GoalsListData?.data?.meta?.page,
                    totalPages: GoalsListData?.data?.meta?.totalPages,
                    totalRecords: GoalsListData?.data?.meta?.totalRecords
                }
            }));
        }
    }, [GoalsListData?.data?.meta]);

    useEffect(() => {
        if (GoalsListData.data && GoalsListData.data.data && GoalsListData.data.data.length === 0) {
          setState((prevState) => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
              totalPages: 1,
              totalRecords: 0,
            },
          }));
        }
      }, [GoalsListData.data && GoalsListData.data.data]);

    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }

    }, [searchRecord]);


    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prev => ({
            ...prev,
            searching: {
                ...prev.searching,
                search: search,
            },
            pagination: {
                ...prev.pagination,
                page: page
            }
        }));

    }, [searchParams]);

    const isChecked = (GoalsListData.data?.data?.length && state.selectAll?.length === GoalsListData.data?.data?.length) ? true : false;
    const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(GoalsListData.data?.data.length)) ? true : false;
    const columns: IColumn[] = [
        {
            id: "all",
            label: <Checkbox onChange={e => handleSelectAll(e, GoalsListData?.data?.data ? GoalsListData?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />,
            maxWidth: 0
        },
        {
            id: "name",
            label: "Goal",
            minWidth: 200
        },
        {
            id: "action",
            label: "Actions",
            align: "right"
        }
    ];

    const handleDelete = (_deleteId = "") => {
        setState(prevState => ({
            ...prevState,
            deleteWarning: !state.deleteWarning,
            _deleteId: _deleteId
        }));
    };

    const onDelete = async () => {
        try {
            const response = await deleteContentGoal({ ids: [state._deleteId] });
            setState(prevState => ({
                ...prevState,
                selectAll : prevState.selectAll.filter((del) => del !== state._deleteId)
            }));
            snackbar(response?.message, "info");
            handleDelete();
            GoalsListData.refetch();
            navigate(
                {pathname : "/content/goals",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            console.log("Error in delete of goals", err);
        }
    };

    const handleMultiDelete = () => setState(prevState => ({
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning
    }));
    const onMultiDelete = async () => {
        try {
            const goal = await deleteContentGoal({ ids: state.selectAll });
            snackbar(goal.message, "info");
            GoalsListData.refetch();
            setState(prevState => ({
                ...prevState,
                multiDeleteWarning: false,
                selectAll: []
            }));
            navigate(
                {pathname : "/content/goals",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            handleMultiDelete();
            console.log({ "Error in delete category": error });
        }
    };


    const createRows = (goal: IGoal, selectedAll: string[]) => {
        const action = <GetActions
            icons={[
                {
                    name: "Edit", method: () => {
                        setOpenState(true);
                        navigate({
                            pathname: "" + goal._id,
                            search: searchParams.toString()
                        });
                    },
                    disabled: resourceAllocate("cms-goal.write") ? false : true
                },
                { name: "Delete", method: () => handleDelete(goal._id), disabled: resourceAllocate("cms-goal.remove") ? false : true },
            ]}
        />;
        return {
            all: <Checkbox onChange={e => handleSelect(e, goal._id, state, setState)} checked={selectedAll.includes(goal._id)} />,
            name: goal?.name,
            action
        };
    };

    let rows: IGoalRow[] = [];
    if (GoalsListData?.data?.data.length) {
        rows = GoalsListData.data.data.map((goal: IGoal) => createRows(goal, state.selectAll));
    }

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };


    const openManageComponent = () => {
        setOpenState(true);
        navigate("/content/goals/new");
    };


    return (
        <div>
            {openState && <Outlet context={{ ...Outlet, reFetch: GoalsListData.refetch }} />}
            <Header
                className="my-2"
                btnText="Add Goals"
                onDelete={resourceAllocate("cms-goal.remove") ? handleMultiDelete : undefined}
                isDeleteDisable={state.selectAll.length ? false : true}
                onBtnClick={resourceAllocate("cms-goal.write") ? () => openManageComponent() : undefined}
                onSearch={onSearch}
                searchPlaceholder="Search by name"

            />
            <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1">Total Goals:</Typography>
                    <Typography className="ml-3" variant="body1" >{GoalsListData?.data?.meta?.totalRecords}</Typography>
                </Box>
            </Box>
            <CustomTable
                columns={columns}
                rows={rows}
                height={"calc(100vh - 285px)"}
                pagination={state.pagination}
                onPageChange={onPageChange}
            />

            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete Goal"
                description="Are you sure you want to delete this goal?"

            />

            {/* Multiple Delete  */}
            <WarningDialog
                isOpen={state.multiDeleteWarning}
                onClose={() => handleMultiDelete()}
                onConfirm={onMultiDelete}
                title="Delete All Goals"
                description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "goals" : "goal"}?`}
            />
        </div>
    );
};

export default Goals;