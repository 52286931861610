import HttpService from "../../http";
import { urlBundle } from "../../endpoints";
import { IUrlBundleResponse, IUrlBundlesResponse } from "../../../interfaces/site-map/url-bundles";

const UrlBundleService = () => {
    const { httpRequest } = HttpService();

    const getUrlBundles = async (search: object): Promise<IUrlBundlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IUrlBundlesResponse>(
            "GET",
            `${urlBundle}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getUrlBundle = async (search: object): Promise<IUrlBundleResponse> => new Promise((resolve, reject) => {
        httpRequest<IUrlBundleResponse>(
            "GET",
            `${urlBundle}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteUrlBundle = async (query: object): Promise<IUrlBundlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IUrlBundlesResponse>(
            "DELETE",
            `${urlBundle}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const addUrlBundles = async (payload: object): Promise<IUrlBundlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IUrlBundlesResponse>(
            "POST",
            `${urlBundle}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateUrlBundles = async (payload: object): Promise<IUrlBundlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IUrlBundlesResponse>(
            "PUT",
            `${urlBundle}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getUrlBundle, deleteUrlBundle, addUrlBundles, updateUrlBundles, getUrlBundles };
};

export { UrlBundleService };