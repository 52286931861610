import "./style.scss";
import { Box, Button, Chip, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import Header from "../../../../../components/header";
import CustomTable from "../../../../../components/mui/table";
import { IErrorResponse, IPagination, IColumn, IMediaImageData } from "../../../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import ImageViewer from "react-simple-image-viewer";
import { ChangeEvent, FC, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UploadIcon from "@mui/icons-material/Upload";
import HttpService from "../../../../../services/http";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { Outlet, useParams } from "react-router-dom";
import Select from "../../../../../components/mui/select";
import GetActions from "../../../../../components/get-actions";
import { capitalize, createIndex } from "../../../../../utilities/helper";
import { useQuery } from "@tanstack/react-query";
import useResource from "../../../../../hooks/useResource";
import WarningDialog from "../../../../../components/mui/warning-dialog";
import CustomDialog from "../../../../../components/mui/dialog";
import useDebounce from "../../../../../hooks/useDebounce";
import { MediaImageService } from "../../../../../services";
import { IGlossaryImage, IGlossaryImageField, IGlossaryImageForm, IGlossaryImageRow, IGlossaryImageState, IImageViewer } from "../../../../../interfaces/content-marketing/glossary/image";
import { GlossaryImageService } from "../../../../../services/glossary";
import GlossaryComment from "../../../../../components/glossary-comments";
import { CMS_ERROR_MESSAGES } from "../../../../../utilities/messages";
import { joiResolver } from "@hookform/resolvers/joi";
import { blogImagesValidation } from "../../../../../validations";
import CustomLabel from "../../../../../components/mui/custom-label";


const initiateFields: IGlossaryImageField[] = [{
  label1: "Image Type",
  name1: "imageType",
  type1: "input",
  placeholder1: "Type your image type",
  width1: 4,
  disabled1: false,
  label2: "Select Option",
  name2: "optionSelect",
  type2: "select",
  placeholder2: "Select your option",
  width2: 4,
  disabled2: false,
  label3: "Upload Image",
  name3: "imageUrl",
  type3: "input",
  placeholder3: "Upload your image",
  width3: 8,
  disabled3: false,
  label4: "Name",
  name4: "name",
  type4: "input",
  placeholder4: "Type image name",
  width4: 8,
  disabled4: false,
  label5: "Alternative Text",
  name5: "alternativeText",
  type5: "input",
  placeholder5: "Type alternative Text",
  width5: 8,
  disabled5: false
}];

interface props {
  activeAction: boolean;
  setActiveAction: (value: boolean) => void;
}

const GlossaryImage: FC<props> = ({ activeAction, setActiveAction }) => {
  const [state, setState] = useState<IGlossaryImageState>({
    fields: initiateFields,
    shownManageBox: false,
    uploadedFileNames: [],
    type: "",
    selectAll: [],
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    updateId: "",
    deleteWarning: false,
    deleteId: ""
  });
  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });
  const { httpFormRequest } = HttpService();
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const { addGlossaryImage, getGlossaryImageList, getGlossaryImage, updateGlossaryImage, deleteGlossaryImage } = GlossaryImageService();
  const { getMediaImageList } = MediaImageService();
  const { resourceAllocate } = useResource();
  const [showImagesDialog, setShowImagesDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [images, setImages] = useState<IMediaImageData[]>([]);
  const { handleSubmit, getValues, setValue, unregister, control, watch, formState: { errors } } = useForm<IGlossaryImageForm>({
    resolver: joiResolver(blogImagesValidation),
    defaultValues: {
      form: [{
        imageType: "",
        imageUrl: "",
        optionSelect: "",
        name: "",
        isEdit: false,
        imageId: "",
        imageName: "",
        alternativeText: ""
      }]
    },
  });
  const hitQuery = state.updateId !== "";
  const debouncedSearchQuery = useDebounce(searchQuery);

  const fetchImages = async (page: number, searchQuery = "") => {
    if (loading)return;
    setLoading(true);
    try {
      const response = await getMediaImageList({ page, pagination: true, limit:20, search: searchQuery });
      const newImages = response.data;
      if (newImages.length < 20) {
        setHasMoreImages(false); 
      }
      setImages((prevImages) => [...prevImages, ...newImages]);
      setPage(page + 1);
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if(showImagesDialog){
      setImages([]);
      setPage(1);
      setHasMoreImages(true);
      fetchImages(1, debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, showImagesDialog]); 
  
  const glossaryImageList = useQuery({
    queryKey: ["glossary-images", id],
    queryFn: () =>
      getGlossaryImageList({
        _glossary: id,
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit
      }),
    enabled: id !== "new"
  });

  useEffect(() => {
    if (glossaryImageList.data && glossaryImageList.data.data) {
      setImageViewer(prev => ({
        ...prev,
        images: glossaryImageList.data.data.map(item => item && item.imageId && item.imageId.imageUrl),
      }));
    }
  }, [glossaryImageList.data]);

  const imageData = useQuery({
    queryKey: ["glossary-image", state.updateId],
    queryFn: () =>
      getGlossaryImage({ 
        _id: state.updateId 
      }),
    enabled: hitQuery
  });

  useEffect(() => {
    if (state.updateId !== "" && imageData.data) {
      const data = imageData.data.data;
      setValue("form.0.imageType", data.imageType || "");
      setValue("form.0.imageUrl", data.imageId && data.imageId.imageUrl || "");
      setValue("form.0.name", data.imageId && data.imageId.name || "");
      setValue("form.0.alternativeText", data.alternativeText || "");
      setValue("form.0.isEdit", true);
      setState(prev => ({ ...prev, uploadedFileNames: [data.name] }));
    }
  }, [state.updateId, imageData.data]);

  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const scrollHeight = target.scrollHeight;
    const scrollTop = target.scrollTop;
    const clientHeight = target.clientHeight;
    const nearBottom = scrollHeight - scrollTop - clientHeight <= 50;
    if (hasMoreImages && nearBottom) {
      fetchImages(page);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchQuery(searchValue);
  };

  const onSubmit = async (data: IGlossaryImageForm) => {
    try {
      const payload = data.form.map(item => {
        delete item.imageName;
        delete item.isEdit;
        return { ...item, _glossary: id };
    });
  
      if (id === "new") {
        snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
      }
      else if (state.type === "edit" && state.updateId !== "") {
        setState(prev => ({ ...prev, shownManageBox: false, uploadedFileNames: [] }));
        const response = await updateGlossaryImage({ _id: state.updateId, imageType: data.form[0].imageType, _glossary: id, alternativeText: data.form[0].alternativeText });
        snackbar(response.message, "info");
        toggleManageBox(false, "","");
        glossaryImageList.refetch();
      }
      else {
        const response = await addGlossaryImage({...payload, _glossary: id});
        snackbar(response.message, "info");
        setState(prev => ({ ...prev, shownManageBox: false, uploadedFileNames: [] }));
        toggleManageBox(false, "","");
        glossaryImageList.refetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      toggleManageBox(false, "","");
      setState(prev => ({ ...prev, shownManageBox: false, uploadedFileNames: [] }));
    }
  };

  const handleDelete = (deleteId = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      deleteId,
    }));
  };

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S. No."
    },
    {
      id: "glossary_image",
      label: "Image",
      minWidth: 120
    },
    {
      id: "image_type",
      label: "Image Type",
      minWidth: 160
    },
    {
      id: "alternative_text",
      label: "Alternative Text",
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const toggleManageBox = (isOpen: boolean, type: string, id?: string) => {
    setState(prev => {
      if (isOpen) {
        return { ...prev, shownManageBox: true, fields: initiateFields, type, updateId: id };
      }
      const index = state.fields.length;
      for (let i = 0; i < index; i++) {
        setValue(`form.${i}.imageType`, "");
        setValue(`form.${i}.imageUrl`, "");
        setValue(`form.${i}.name`, "");
        setValue(`form.${i}.optionSelect`, "");
        setValue(`form.${i}.imageId`, "");
        setValue(`form.${i}.alternativeText`, "");
        setValue(`form.${i}.isEdit`, false);
      }

      return { ...prev, shownManageBox: false, type, updateId: id, uploadedFileNames: [] };
    });
  };

  const addField = () => {
    const index = state.fields.length;
    setState(prev => ({ ...prev, fields: [...prev.fields, ...initiateFields] }));
    setValue(`form.${index}.imageType`, "");
    setValue(`form.${index}.imageUrl`, "");
    setValue(`form.${index}.name`, "");
    setValue(`form.${index}.optionSelect`, "");
    setValue(`form.${index}.imageId`, "");
    setValue(`form.${index}.alternativeText`, "");
    setValue(`form.${index}.isEdit`, false);
  };

  const removeField = (index: number) => {
    const fieldPayload = state.fields;
    fieldPayload.splice(index, 1);
    setState((prev) => ({
      ...prev,
      fields: fieldPayload,
    }));
    const payload = getValues();
    const size = payload.form.length;
    for (let i = index; i < size - 1; i++) {
      setValue(`form.${i}.name`, payload.form[i + 1].name);
      setValue(`form.${i}.imageUrl`, "");
      setValue(`form.${i}.imageId`, payload.form[i + 1]?.imageId ?? "");
      setValue(`form.${i}.optionSelect`, payload.form[i + 1]?.optionSelect ?? "");
      setValue(`form.${i}.alternativeText`, payload.form[i + 1]?.alternativeText ?? "");
      setValue(`form.${i}.imageType`, payload.form[i + 1]?.imageType ?? "");
    }
    unregister(`form.${size - 1}`);
    const formPayload = getValues().form.filter((val) => val !== undefined);
    setValue("form", formPayload);
  };
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string, index: number) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["webp"],
          1
        );
        const keyExist = type === "imageUrl";
        if (keyExist) {
          setValue(`form.${index}.imageUrl`, uploaded?.data);
          setValue(`form.${index}.name`, e?.target?.files && e?.target?.files[0]?.name);
          setValue(`form.${index}.alternativeText`, e?.target?.files && e?.target?.files[0]?.name);
          setState((prev) => {
            const uploadedFileNames = [...prev.uploadedFileNames];
            uploadedFileNames[index] = (e?.target?.files && e?.target?.files[0]?.name) ? e?.target?.files[0]?.name : "";
            return { ...prev, uploadedFileNames };
          });
        }
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err && err.data && err.data.message, "error");
    }
  };

  const onDownloadClick = (url: string) => {
    window.open(url, "_blank");
  };

  const onDelete = async () => {
    try {
      const response = await deleteGlossaryImage({ _id: state.deleteId });
      snackbar(response.message, "info");
      handleDelete();
      glossaryImageList.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const createRows = (
    index: number,
    data: IGlossaryImage,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Preview", method: () => onDownloadClick(data?.imageId?.imageUrl),
          },
          {
            name: "Edit",
            method: () => {
              toggleManageBox(true, "edit", data?._id);
            },
            disabled: !resourceAllocate("cms-glossary-image.write"),
          },
          { name: "Delete", method: () => handleDelete(data._id), disabled: !resourceAllocate("cms-glossary-image.remove") },
        ]}
      />
    );


    return {
      id: createIndex(pagination, index),
      glossary_image: <IconButton onClick={() => handleImageViewer(index)}><img className="thumbnail" src={data?.imageId?.imageUrl} alt="" /></IconButton>,
      image_type: data?.imageType,
      alternative_text: (
        <Tooltip title={data?.alternativeText ?? ""}>
          <span>
            {data?.alternativeText && data?.alternativeText?.length > 60
              ? `${capitalize(data?.alternativeText).slice(0, 60)}...`
              : data?.alternativeText ?? "--"}
          </span>
        </Tooltip>
      ),
      action,
    };
  };

  let rows: IGlossaryImageRow[] = [];
  if (glossaryImageList && glossaryImageList.data && glossaryImageList.data.data) {
    const data = glossaryImageList.data.data;
    rows = data.map(
      (data, i) =>
        createRows(i, data, state.pagination)
    );
  }

  const handleImageClick = (image: IMediaImageData) => {
    if (currentIndex !== null) {
      setValue(`form.${currentIndex}.imageId`, image._id);
      setValue(`form.${currentIndex}.imageName`, image.name);
      setValue(`form.${currentIndex}.alternativeText`,image.name);
      setShowImagesDialog(false);
      setSearchQuery("");
    }
  };

  return (
    <div className="glossary-image">
      <Grid container spacing={4}>
        <Grid item {...(activeAction ? { xs: 8 } : { xs: 12 })}>
          {
            state.shownManageBox &&
            <>
            <div className="form-border mb-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                  {
                    state.fields.map((field, index) => {
                      if (field.type1 === "input") {
                        return (
                          <>
                            <Grid key={index} item xs={5}>
                              <Select
                                control={control}
                                className="disable-text"
                                name={`form.${index}.${field.name1}`}
                                label={<CustomLabel label={"Image Type"} required/>}
                                size={"small"}
                                variant={"outlined"}
                                error={(errors["form"] && errors["form"][index] && errors["form"][index]?.[field.name1]) ? true : false}
                                helperText={(errors["form"] && errors["form"][index]) && errors["form"][index]?.[field.name1]?.message}
                              >
                                {[{ key: 1, value: "FEATURED" }, { key: 2, value: "OTHERS" }].map((data) => (
                                  <MenuItem key={data.key} value={data.value}>
                                    {data.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            {state.type === "new" && <Grid key={index} item xs={4}>
                              <Select
                                control={control}
                                className="disable-text"
                                name={`form.${index}.${field.name2}`}
                                label={<CustomLabel label={field.label2} required/>}
                                size={"small"}
                                variant={"outlined"}
                                error={(errors["form"] && errors["form"][index] && errors["form"][index]?.[field.name2]) ? true : false}
                                helperText={(errors["form"] && errors["form"][index]) && errors["form"][index]?.[field.name2]?.message}
                                onChange={(e) => {
                                  if (e?.target?.value === "media") {
                                    setCurrentIndex(index);
                                    setShowImagesDialog(true);
                                  }
                                  setValue(`form.${index}.imageId`, "");
                                  setValue(`form.${index}.imageUrl`, "");
                                  setValue(`form.${index}.name`, "");
                                }}
                              >
                                {[{ key: "upload", value: "Upload Image" }, { key: "media", value: "Select from Media" }].map((data) => (
                                  <MenuItem key={data.key} value={data.key}>
                                    {data.value}
                                  </MenuItem>
                                ))}
                              </Select>
                              {watch(`form.${index}.imageId`) !== "" &&
                                <Chip
                                  color="primary"
                                  variant="outlined"
                                  label={`${watch(`form.${index}.imageName`)}`}
                                  onDelete={() => {
                                    setValue(`form.${index}.imageId`, "");
                                    setValue(`form.${index}.optionSelect`, "");
                                  }}
                                  sx={{ margin: "5px" }}
                                />}
                            </Grid>
                            }
                            {(getValues(`form.${index}.${field.name2}`) === "upload" || state.type === "edit") &&
                              <>
                                <Grid key={field.label3} item xs={5}>
                                  <Box display="flex" alignItems="start" >
                                    <div className="w-100" onClick={() => {
                                      const fileInput = document.getElementById(`fileInput-${index}-${field.name3}`);
                                      if (fileInput && !(state.type === "edit")) {
                                        fileInput.click();
                                      }
                                    }}>
                                      <Controller
                                        control={control}
                                        name={`form.${index}.${field.name3}`}
                                        disabled={state.type === "edit"}
                                        render={(prop) => <TextField
                                          label={<CustomLabel label={field.label3} required/>}
                                          className="disable-text"
                                          variant="outlined"
                                          size="small"
                                          placeholder={field.placeholder3}
                                          style={{ cursor: "pointer" }}
                                          {...prop.field}
                                          value={prop.field.value}
                                          error={(errors["form"] && errors["form"][index] && errors["form"][index]?.[field.name3]) ? true : false}
                                          helperText={(errors["form"] && errors["form"][index]) && errors["form"][index]?.[field.name3]?.message}
                                          InputProps={
                                            {
                                              endAdornment:
                                                <>
                                                  <Tooltip title="Upload">
                                                    <IconButton
                                                      color="primary"
                                                      component="label"
                                                      disabled={state.type === "edit"}
                                                    >
                                                      <UploadIcon color="action" />

                                                    </IconButton>
                                                  </Tooltip>
                                                </>
                                            }
                                          }
                                        />}
                                      />
                                      <input
                                        id={`fileInput-${index}-${field.name3}`}
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none", cursor: "pointer" }}
                                        disabled={state.type === "edit"}
                                        onChange={e => { uploadFile(e, field.name3, index); }}
                                      />
                                    </div>
                                  </Box>
                                </Grid>
                                <Grid key={field.label4} item xs={state.type === "edit" ? 5 : 4}>
                                  <Controller
                                    control={control}
                                    name={`form.${index}.${field.name4}`}
                                    render={(prop) => <TextField
                                      label={<CustomLabel label={field.label4} required/>}
                                      variant="outlined"
                                      size="small"
                                      placeholder={field.placeholder4}
                                      disabled={state.type === "edit"}
                                      {...prop.field}
                                      value={prop.field.value || ""}
                                      error={(errors["form"] && errors["form"][index] && errors["form"][index]?.[field.name4]) ? true : false}
                                      helperText={(errors["form"] && errors["form"][index]) && errors["form"][index]?.[field.name4]?.message}
                                    />}
                                  />
                                </Grid>
                              </>
                            }
                            {
                              state.type === "edit" && 
                              <Grid key={field.label5} item xs={5}>
                                <Controller
                                  control={control}
                                  name={`form.${index}.${field.name5}`}
                                  render={(prop) => <TextField
                                    label={field.label5}
                                    variant="outlined"
                                    size="small"
                                    placeholder={field.placeholder5}
                                    {...prop.field}
                                    value={prop.field.value || ""}
                                    error={!!(errors["form"] && errors["form"][index] && errors["form"][index]?.[field.name5])}
                                    helperText={(errors["form"] && errors["form"][index]) && errors["form"][index]?.[field.name5]?.message}
                                  />}
                                />
                              </Grid>
                            }
                            {
                              state.type === "new" &&
                              <Grid item xs={2} className="add-more">
                                {state.fields.length > 1 &&
                                  <Button className="mr-3" variant="outlined" color="error" onClick={() => removeField(index)}><RemoveCircleIcon color="error" /></Button>
                                }
                                
                                <Button 
                                variant="outlined"
                                 color="primary"
                                 sx={{
                                  visibility:
                                    index !== state.fields.length - 1
                                      ? "hidden"
                                      : "visible",
                                }} 
                                 onClick={addField} ><AddCircleIcon color="primary" /></Button>
                              </Grid>
                            }
                            <CustomDialog
                              title="Select Image"
                              isOpen={showImagesDialog}
                              onClose={() => {
                                setShowImagesDialog(false);
                                setSearchQuery("");
                                setValue(`form.${currentIndex}.${field.name2}`, "");
                              }}
                              disabled={true}
                            >
                              <TextField
                                fullWidth
                                label="Search Images"
                                variant="outlined"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="search-bar"
                              />

                              {images?.length ? (
                                <div className="images-container" onScroll={handleScroll}>
                                  <Grid container spacing={2}>
                                    {images.map((image, i) => (
                                      <Grid key={i} item xs={6} sm={4} md={3} lg={3}>
                                        <div
                                          className="image-wrapper"
                                          onClick={() => handleImageClick(image)}
                                        >
                                          <img
                                            src={image.imageUrl}
                                            alt={`Image ${i}`}
                                          />
                                          <div className="hover-overlay">
                                            Select
                                          </div>
                                        </div>
                                        <Tooltip title={image.name} arrow>
                                          <Typography className="image-name" variant="body1" noWrap>
                                            {image.name.length > 50 ? `${image.name.slice(0, 50)}…` : image.name}
                                          </Typography>
                                        </Tooltip>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </div>
                              ) : (
                                <div className="no-images">
                                  <p>
                                    Unfortunately, no images are currently available!
                                  </p>
                                </div>
                              )}
                            </CustomDialog>
                          </>
                        );
                      }
                    })
                  }
                </Grid>
                <div className="form-action-btn">
                  <Button variant="outlined" onClick={() => toggleManageBox(false, "close", "")}>Discard</Button>
                  <Button type="submit" className="ml-2">{state.updateId !== "" ? "Update Glossary Image" : "Add Glossary Image"}</Button>
                </div>
              </form>
            </div>
          </>
          }

          <Header
            btnText="Add Image"
            onBtnClick={resourceAllocate("cms-glossary-image.write") ? () => toggleManageBox(true, "new", "") : undefined}
          />
          <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="body1">Total Images:</Typography>
              <Typography className="ml-3" variant="body1" >{glossaryImageList?.data?.meta?.totalRecords}</Typography>
            </Box>
          </Box>
          <WarningDialog
            isOpen={state.deleteWarning}
            onClose={() => handleDelete()}
            onConfirm={onDelete}
            title="Delete Glossary Image"
            description="Are you sure you want to delete this glossary image?"
          />
          {/* Show Data  */}
          <Box marginTop="10px">
            <CustomTable
              columns={columns}
              rows={rows}
              height="calc(100vh - 338px)"
            />
          </Box>
        </Grid>
        {imageViewer.open && (
          <>
            <ImageViewer
              src={imageViewer.images}
              currentIndex={imageViewer.index}
              disableScroll={true}
              closeOnClickOutside={false}
              onClose={handleImageViewer}
              backgroundStyle={
                {
                  zIndex: 5
                }
              }
            />
          </>
        )}
        {activeAction && <Grid item xs={4}>
          <GlossaryComment
            commentType="GLOSSARY_IMAGE"
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          />
        </Grid>
        }
      </Grid>
      <Outlet />
    </div>
  );
};

export default GlossaryImage;