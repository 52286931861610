import "../../../blog/manage/content/style.scss";
import { Controller, useForm } from "react-hook-form";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, capitalize, debounce, Dialog, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { IErrorResponse } from "../../../../../interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useCallback, useEffect, useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { useQuery } from "@tanstack/react-query";
import { formatDate, formatTime } from "../../../../../utilities/helper";
import useResource from "../../../../../hooks/useResource";
import { ClassicEditor, EventInfo } from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { formatHistoryDate } from "../../../../../utilities/helper";
import CustomLabel from "../../../../../components/mui/custom-label";
import useUser from "../../../../../hooks/useUser";
import useDebounce from "../../../../../hooks/useDebounce";
import { joiResolver } from "@hookform/resolvers/joi";
import { IGlossaryContent, IGlossaryContentVersion } from "../../../../../interfaces/content-marketing/glossary/content";
import { glossaryContentValidation } from "../../../../../validations/content-marketing/glossary";
import { GlossaryAssignmentService, GlossaryContentService, GlossaryImageService, GlossaryScoreService } from "../../../../../services/glossary";
import GlossaryComment from "../../../../../components/glossary-comments";
import { CMS_ERROR_MESSAGES } from "../../../../../utilities/messages";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../../components/shared/mui-tabs";
import CKeditor from "../../../../../components/ck-editor";

interface props {
    activeAction: boolean;
    setActiveAction: (value: boolean) => void;
    commentActiveAction: boolean;
    setCommentActiveAction: (value: boolean) => void;
}

const Content: FC<props> = ({ activeAction, setActiveAction, commentActiveAction, setCommentActiveAction }) => {
    const { control, setValue, handleSubmit, watch, reset, trigger, formState: { errors } } = useForm<IGlossaryContent>({
        resolver: joiResolver(glossaryContentValidation),
        defaultValues: {
            content: "",
            metaTitle: "",
            metaDescription: ""
        }
    });
    const { user } = useUser();
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const { getGlossaryAssignment } = GlossaryAssignmentService();
    const navigate = useNavigate();
    const { addGlossaryContent, glossaryContentAutoSave, getGlossaryContent, getGlossaryContentVersions } = GlossaryContentService();
    const { getGlossaryKeywordDensity } = GlossaryScoreService();
    const { resourceAllocate } = useResource();
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [editorInstance, setEditorInstance] = useState<ClassicEditor>();
    const [editorContent, setEditorContent] = useState<string>("");
    const [searchParam] = useSearchParams();
    const searchRecord = useDebounce(editorContent, 1000);

    const getKeywordDensity = useQuery({
        queryKey: ["glossary-keyword-density-data"],
        queryFn: () => getGlossaryKeywordDensity({ _glossary: id }),
    });

    useEffect(() => {
        const saveRecord = async () => {
            if (id === "new") return;
            if (!initialRender) {
                await glossaryContentAutoSave({ _glossary: id, content: searchRecord });
                getKeywordDensity.refetch();
            }
        };
        saveRecord();
    }, [searchRecord]);
    const hitQuery = id !== "new";

    const glossaryContent = useQuery({
        queryKey: ["glossart-content", id],
        queryFn: () => getGlossaryContent({ _glossary: id }),
        enabled: hitQuery
    });
    const glossaryAssignment = useQuery({
        queryKey: ["glossary-assignment", id],
        queryFn: () => getGlossaryAssignment({ _glossary: id }),
    });
    const submissionDate = glossaryAssignment?.data?.data?.writer?.submissionDate;
    const isDateInPast = (dateString: string | undefined) => {
        if (!dateString) {
            return false;
        }
        const givenDate = new Date(dateString);
        const currentDate = new Date();
        return givenDate < currentDate;
    };
    const glossaryContentVersions = useQuery({
        queryKey: ["glossary-content-versions", id],
        queryFn: () => getGlossaryContentVersions({ _glossary: id, pagination: true }),
        enabled: hitQuery
    });
    const blogContentVersionsData = glossaryContentVersions && glossaryContentVersions.data && glossaryContentVersions.data.data || [];

    useEffect(() => {
        if (activeAction) {
            glossaryContentVersions.refetch();
        }
    }, [activeAction]);
    useEffect(() => {
        if (glossaryContent?.data?.data && id !== "new") {
            setValue("content", glossaryContent?.data?.data?.content || "");
            trigger("content");
            setInitialRender(true);
            setEditorContent(glossaryContent?.data?.data?.content || "");
            setValue("metaTitle", glossaryContent?.data?.data?.metaTitle || "");
            setValue("metaDescription", glossaryContent?.data?.data?.metaDescription || "");
            setSubmitDisabled(() => false);
        }
    }, [glossaryContent?.data?.data]);

    const handleSubmitClick = async () => {
        navigate({
            pathname: `/glossary/manage/${id}/checklistWriter`,
            search: searchParam.toString()
        });
    };

    const [fullScreen, setFullScreen] = useState(false);

    const handleFullScreen = () => {
        setFullScreen(true);
    };

    const handleFirstInteraction = useCallback(() => {
        if (initialRender) {
            setInitialRender(false);
        }
    }, [initialRender]);

    const handleContentChange = useCallback(
        debounce((editor) => {
            const data = editor.getData();
            setValue("content", data);
            setEditorContent(data);
        }, 300),
        []
    );

    const onEditorReady = (editor: ClassicEditor) => {
        setEditorInstance(editor);
    };
    const onEditorChange = (_: EventInfo<string>, editor: ClassicEditor) => {
        handleFirstInteraction();
        handleContentChange(editor);
    };

    const onSubmit = async (data: IGlossaryContent) => {
        try {
            if (id === "new") {
                snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
            } else {
                const payload = {
                    ...data,
                    _glossary: id
                };
                const response = await addGlossaryContent(payload);
                snackbar(response.message, "info");
                navigate({
                    pathname: `/glossary/manage/${id}`,
                    search: searchParam.toString()
                });
            }
            glossaryContentVersions.refetch();
            glossaryContent.refetch();
            setInitialRender(true);
            setSaveDisabled(true);
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            navigate({
                pathname: `/glossary/manage/${id}`,
                search: searchParam.toString()
            });
        }
    };

    const [selectedVersion, setSelectedVersion] = useState<number | null>(null);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [selectedAccordion, setSelectedAccordion] = useState<number | null>(null);
    const [saveDisabled, setSaveDisabled] = useState<boolean | null>(true);
    const [submitDisabled, setSubmitDisabled] = useState<boolean | null>(true);


    useEffect(() => {
        const subscription = watch((values) => {
            const allFieldsEmpty = Object.values(values).every(value => value === "");
            if (allFieldsEmpty) {
                setSaveDisabled(() => true);
                setSubmitDisabled(() => true);
                return;
            }

            const edited = Object.keys(values).some(name => {
                if (name === "content" || name === "metaTitle" || name === "metaDescription") {
                    const fieldValue = values[name] as string;
                    if (glossaryContent?.data?.data) {
                        const contentFromAPI = glossaryContent?.data?.data[name] ?? "";
                        return fieldValue !== contentFromAPI;
                    } else {
                        return fieldValue !== "";
                    }
                }
                return false;
            });
            if (user._role.name === "writer") {
                const isPastSubmissionDate = isDateInPast(submissionDate);
                setSaveDisabled(isPastSubmissionDate || !edited);
            } else {
                setSaveDisabled(() => !edited);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, glossaryContent?.data?.data]);

    const handleCloseFullScreen = () => {
        setFullScreen(false);
    };

    const handleClose = () => {
        setSelectedVersion(null);
        setExpandedIndex(null);
        setSelectedAccordion(null);
        setActiveAction(!activeAction);
    };

    const handleAccordionTabClick = (index: number) => {
        setSelectedAccordion(index);
        setExpandedIndex(index !== expandedIndex ? index : null);
    };

    const [currentVersionData, setCurrentVersionData] = useState<string>("");
    const [previousVersionData, setPreviousVersionData] = useState<string>("");

    const getPreviousVersionDoc = (dataArray:IGlossaryContentVersion[], currentVersion: number) => {
        
        for (const dateEntry of dataArray) {
          const documents = dateEntry.data;
          for (let i = 0; i < documents.length; i++) {
            const previousVersionDoc = documents.find(doc => doc.version === currentVersion - 1);
            if (previousVersionDoc) {
              return previousVersionDoc.content;
            }
          }
        }
        return "";
      };

    const handleAccordionClick = (itemIndex: number, index: number) => {
        setCurrentVersionData(blogContentVersionsData[index]?.data[itemIndex]?.content || "");
        const getPreviosVersion = getPreviousVersionDoc(blogContentVersionsData, blogContentVersionsData[index]?.data[itemIndex].version);
        setPreviousVersionData(getPreviosVersion);
        navigate({ pathname: `/glossary/manage/${id}/view-content/${glossaryContentVersions?.data?.data[index]?.data[itemIndex]?._id}/`, search: searchParam.toString() });
    };

    const { getGlossaryImageList } = GlossaryImageService();
    const glossaryImageList = useQuery({
        queryKey: ["glossary-images-list", id],
        queryFn: () =>
            getGlossaryImageList({
            _glossary: id,
            pagination: false,
          }),
        enabled: id !== "new"
      });
    const images = (glossaryImageList && glossaryImageList.data && glossaryImageList.data.data) || [];
    const [showImagesDialog, setShowImagesDialog] = useState(false);

    const handleImageClick = () => {
        setShowImagesDialog(true);
    };

    const addImage = (url: string) => {
        if (editorInstance) {
            editorInstance.model.change(writer => {
                const selection = editorInstance.model.document.selection;
                const position = selection.getFirstPosition();

                if (position) {
                    const imageElement = writer.createElement("imageBlock", {
                        src: url,
                        alt: "Image"
                    });
                    writer.insert(imageElement, position);
                    writer.setAttribute("imageStyle", "full", imageElement);
                }
            });
            const data = editorInstance.getData();
            setEditorContent(data);
            setValue("content", data);
        }    
        setShowImagesDialog(false);
    };

    return (
        <div className="blog-content">

            <Grid container spacing={4}>
                <Grid item {...((activeAction || commentActiveAction) ? { xs: 8 } : { xs: 12 })}>
                    <div className="sub-title">
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <Typography className="title mt-3 mb-2" variant="body2">Content</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    <div className="editor-container mr-2 mb-2">
                                        <CKeditor 
                                            editorContent = {editorContent} 
                                            onEditorChange = {onEditorChange} 
                                            onEditorReady = {onEditorReady}
                                            showDynamicKeywords = {false} 
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Box className="full-screen-box mr-2">
                                <Box display="flex">
                                    <Typography variant="body1">Total Words:</Typography>
                                    <Typography className="ml-2" variant="body1" >{getKeywordDensity?.data?.data?.totalWords}</Typography>
                                </Box>

                                <Box className="images-box">
                                    <Button variant="outlined" onClick={handleImageClick}>
                                        Add images
                                    </Button>

                                    <Button variant="outlined" onClick={handleFullScreen}>
                                        Edit in full screen
                                    </Button>
                                </Box>

                            </Box>


                            <Typography className="title mt-2 mb-2" variant="body2">SEO</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        control={control}
                                        name={"metaTitle"}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label="Meta Title" required />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Type meta title"
                                            error={!!errors["metaTitle"]}
                                            helperText={errors["metaTitle"]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Controller
                                        control={control}
                                        name={"metaDescription"}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label="Meta Description" required/>}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Type meta description"
                                            error={!!errors["metaDescription"]}
                                            helperText={errors["metaDescription"]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                            <div className="mt-4">
                                <Button
                                    variant="outlined"
                                    onClick={() => reset()}
                                    disabled={!resourceAllocate("cms-glossary-content.write")}
                                >Discard</Button>
                                <Button
                                    type="submit"
                                    className="ml-2"
                                    disabled={!(resourceAllocate("cms-glossary-content.write") && !saveDisabled)}
                                >Save Changes</Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSubmitClick}
                                    className="ml-2"
                                    disabled={!(resourceAllocate("cms-glossary-content.write") && saveDisabled && !submitDisabled)}
                                >Submit</Button>
                            </div>
                        </form>
                    </div>
                </Grid >
                {
                    activeAction && <Grid item xs={4}>
                        <div className="timeline">
                            <header>
                                <Typography variant="body1">Version History</Typography>
                                <IconButton color="primary"><CloseIcon color="action" onClick={() => handleClose()} /></IconButton>
                            </header>

                            <div className="note-container">

                                {glossaryContentVersions?.data?.data?.map((item, index) => (

                                    <div key={index} className="timelineBox">
                                        <div className="mt-1 mb-2" style={{ marginLeft: "12px" }}>
                                            <Typography variant="caption" className="history" >{formatHistoryDate(item._id)}</Typography>
                                        </div>
                                        <Accordion sx={{ border: "none", boxShadow: "none" }} expanded={expandedIndex === index}>
                                            {item?.data?.map((itemData, itemIndex) => (
                                                <div key={itemIndex} className="timelineBox" >
                                                    {itemIndex === 0 ?
                                                        <div >
                                                            <AccordionSummary
                                                                sx={{
                                                                    backgroundColor:
                                                                        (index === selectedAccordion && itemIndex === selectedVersion) ||
                                                                            (selectedAccordion === null && selectedVersion === null && itemData?.currentVersion)
                                                                            ? "#f5f5f5"
                                                                            : "white",
                                                                }}
                                                                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} onClick={() => handleAccordionTabClick(index)} />}>
                                                                <div className="accordian-details" onClick={() => handleAccordionClick(itemIndex, index)}>
                                                                    <div className="date">
                                                                        <Typography variant="caption" style={{ fontWeight: "bolder", marginRight: "4px" }}>{formatDate(itemData?.date)}</Typography>
                                                                        { !itemData?.currentVersion && <Typography variant="caption">{formatTime(itemData?.date)}</Typography> }

                                                                    </div>
                                                                    {itemData?.currentVersion && <Typography variant="caption" style={{ color: "var(--primary-color)" }}>current Version</Typography>}
                                                                    <Typography variant="caption">{capitalize(itemData?.createdBy?.name)}</Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                        </div>
                                                        :
                                                        <div
                                                            style={{ backgroundColor: itemIndex === selectedVersion ? "#f5f5f5" : "white", }}>

                                                            <AccordionDetails
                                                                style={{ marginLeft: "20px", cursor: "pointer" }}

                                                            >
                                                                <div style={{ display: "flex", flexDirection: "column" }} onClick={() => handleAccordionClick(itemIndex, index)}>
                                                                    <div style={{ display: "flex", flexDirection: "row" }}>

                                                                        <Typography variant="caption" style={{ fontWeight: "bolder", marginRight: "4px" }}>{formatDate(itemData?.date)}</Typography>
                                                                        <Typography variant="caption">{formatTime(itemData?.date)}</Typography>

                                                                    </div>
                                                                    <Typography variant="caption">{capitalize(itemData?.createdBy?.name)}</Typography>
                                                                </div>
                                                            </AccordionDetails>

                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid>
                }
                {
                    commentActiveAction && <Grid item xs={4}>
                        <GlossaryComment
                            commentType="GLOSSARY_CONTENT"
                            activeAction={commentActiveAction}
                            setActiveAction={setCommentActiveAction}
                        />
                    </Grid>
                }
            </Grid >
            <div>
                <Dialog
                    fullScreen
                    open={fullScreen}
                    className="fullScreenEditor"
                    onClose={handleCloseFullScreen}
                >
                    <CKeditor 
                        editorContent = {editorContent} 
                        onEditorChange = {onEditorChange} 
                        onEditorReady = {onEditorReady}
                        showDynamicKeywords = {false}
                    />
                    <Button variant="contained" onClick={handleCloseFullScreen} style={{borderRadius: 0}}>
                        Exit full screen
                    </Button>
                </Dialog>
            </div>
            <BootstrapDialog
                maxWidth="md"
                open={showImagesDialog}
                onClose={() => setShowImagesDialog(false)}
            >
                <BootstrapDialogTitle onClose={() => setShowImagesDialog(false)}>
                    <Box display="flex" alignItems="center">
                        Select Image
                    </Box>
                </BootstrapDialogTitle>
                {(images && images.length) ? 
                <div className="images-blogs-container">
                    <Grid container spacing={2}>
                        {images.map((image, i) => <Grid key={i} item xs={6} sm={4} md={3} lg={3}>
                            <div className="image-blogs-wrapper">
                                <img src={image?.imageId?.imageUrl} className="w-100 h-100 cursor-pointer" onClick={() => addImage(image?.imageId?.imageUrl)} />
                            </div>
                            <Tooltip title={`${image?.imageId?.name} (Alt: ${image.alternativeText})`} arrow>
                                <Typography className="image-name" variant="body1" noWrap textAlign={"center"}>
                                    {`${image?.imageId?.name} (Alt: ${image.alternativeText})`.length > 50
                                    ? `${`${image?.imageId?.name} (Alt: ${image.alternativeText})`.slice(0, 50)}…`
                                    : `${image?.imageId?.name} (Alt: ${image.alternativeText})`}
                                </Typography>
                            </Tooltip>
                        </Grid>)}
                    </Grid> 
                </div>  
                :
                <Box className="center mb-5">Unfortunately, no images are currently available!</Box>
                }
            </BootstrapDialog>
            <Outlet context={{ reFetchContent: glossaryContent.refetch, reFetchVersions: glossaryContentVersions.refetch, getPreviousVersionData: previousVersionData, getCurrentVersionData: currentVersionData }} />
        </div >
    );
};

export default Content;