import { Button, capitalize, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import { UsersService } from "../../../../services";
import { ComparisionAssignmentService } from "../../../../services/alternative-comparison/assignments";
import useResource from "../../../../hooks/useResource";
import { comparisonAssignmentValidation } from "../../../../validations/content-marketing/alternatives/assignment";
import { IErrorResponse } from "../../../../interfaces";
import Select from "../../../../components/mui/select";
import CustomLabel from "../../../../components/mui/custom-label";
import DateTime from "../../../../components/mui/date-time";
import { displayName } from "../../../../utilities/helper";
import { CMS_ERROR_MESSAGES } from "../../../../utilities/messages";
import { IComparisonAssignment } from "../../../../interfaces/alternative-comparison/assignment";
interface props {
  activeAction: boolean;
  setActiveAction: (value: boolean) => void;
}

const Assignments: FC<props> = () => {
  const [searchParams] = useSearchParams();
  const { snackbar } = useSnackbar();
  const { updateComparisionAssignment, getComparisionAssignment } = ComparisionAssignmentService();
  const { getRoleBasedUser } = UsersService();
  const writerList = useQuery({
    queryKey: ["writer-list-data"],
    queryFn: () => getRoleBasedUser({ role: ["writer", "reviewer", "content admin"] }),
  });

  const reviewerList = useQuery({
    queryKey: ["reviewer-list-data"],
    queryFn: () => getRoleBasedUser({ role: ["reviewer", "content admin"] }),
  });

  const designerList = useQuery({
    queryKey: ["illustrator-list-data"],
    queryFn: () => getRoleBasedUser({ role: "illustrator" }),
  });

  const publisherList = useQuery({
    queryKey: ["publisher-list-data"],
    queryFn: () => getRoleBasedUser({ role: "publisher" }),
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const comparisonAssignment = useQuery({
    queryKey: ["comparison-assignment-data", id],
    queryFn: () => getComparisionAssignment({ 
      populate: ["writer._user", "reviewer._user", "illustrator._user", "publisher._user"],
      _alternativeComparison: id 
    }),
  });
  const { resourceAllocate } = useResource();
  useEffect(() => {
    if (comparisonAssignment?.data?.data && id !== "new") {
      const writerData = comparisonAssignment.data.data && comparisonAssignment.data.data.writer;
      const illustratorData = comparisonAssignment.data.data && comparisonAssignment.data.data.illustrator;
      const publisherData = comparisonAssignment.data.data && comparisonAssignment.data.data.publisher;
      const reviewerData = comparisonAssignment.data.data && comparisonAssignment.data.data.reviewer;
      setValue("writer._user", writerData &&writerData._user && writerData._user._id || "");
      setValue("writer.submissionDate", writerData && writerData.submissionDate || dayjs().startOf("day").toISOString());
      trigger("writer.submissionDate");
      setValue("writer.notes", writerData && writerData.notes || "");
      setValue("reviewer._user", reviewerData && reviewerData._user && reviewerData._user._id || "");
      setValue("reviewer.submissionDate", reviewerData &&reviewerData.submissionDate || dayjs().add(2, "days").startOf("day").toISOString());
      trigger("reviewer.submissionDate");
      setValue("reviewer.notes", reviewerData && reviewerData.notes || "");
      setValue("illustrator._user", illustratorData && illustratorData._user && illustratorData._user._id || "");
      setValue("illustrator.submissionDate", illustratorData && illustratorData.submissionDate || dayjs().add(4, "days").startOf("day").toISOString());
      trigger("illustrator.submissionDate");
      setValue("illustrator.notes", illustratorData && illustratorData.notes || "");
      setValue("publisher.notes", publisherData && publisherData.notes || "");
      setValue("publisher._user", publisherData && publisherData._user && publisherData._user._id || "");
      setValue("publisher.submissionDate", publisherData && publisherData.submissionDate || dayjs().add(6, "days").startOf("day").toISOString());
      trigger("publisher.submissionDate");
    }
  }, [comparisonAssignment?.data?.data]);


  const { handleSubmit, control, getValues, setValue, reset, trigger, formState: { errors } } = useForm<IComparisonAssignment>({
    resolver: joiResolver(comparisonAssignmentValidation),
    defaultValues: {
      writer: {
        _user: "",
        submissionDate: dayjs().startOf("day").toISOString(),
        notes: ""
      },
      reviewer: {
        _user: "",
        submissionDate: dayjs().add(3, "days").startOf("day").toISOString(),
        notes: ""

      },
      illustrator: {
        _user: "",
        submissionDate: dayjs().add(6, "days").startOf("day").toISOString(),
        notes: ""
      },
      publisher: {
        _user: "",
        submissionDate: dayjs().add(9, "days").startOf("day").toISOString(),
        notes: ""
      }
    }
  });

  const changeDate = (e: dayjs.Dayjs | null, name: string) => {
    const newDate = e ? dayjs(e).startOf("day").toISOString() : "";

    if (name === "writer.submissionDate") {
      setValue(name, newDate);
    }
    else if (name === "reviewer.submissionDate") {
      setValue(name, newDate);
    }
    else if (name === "illustrator.submissionDate") {
      setValue(name, newDate);
    }
    else if (name === "publisher.submissionDate") {
      setValue(name, newDate);
    }

  };

  const onSubmit = async (data: IComparisonAssignment) => {
    try {
      if (id === "new") {
        snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
      } else {
        const response = await updateComparisionAssignment({ ...data, _id: comparisonAssignment?.data?.data?._id, _alternativeComparison: id });
        snackbar(response.message, "info");
        navigate({
          pathname: "/comparison",
          search: searchParams.toString()
        });
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };


  return (
    <div id="assignment">
      <Grid container spacing={2}>
        <Grid item xs={12}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Writer</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="writer._user"
                        label={<CustomLabel label="Writer" required />}
                        size={"small"}
                        variant={"outlined"}
                        error={!!errors["writer"]?._user}
                        helperText={errors["writer"]?._user?.message}
                      >
                        {(writerList && writerList.data && writerList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Writer Submission Date" required/>}
                        name="writer.submissionDate"
                        changeDate={changeDate}
                        value={getValues("writer.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="writer.notes"
                        render={(prop) => <TextField
                          label={<CustomLabel label="Notes For Writer" />}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["writer"] && !!errors["writer"]["notes"]}
                          helperText={errors["writer"] && errors["writer"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Reviewer</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="reviewer._user"
                        label={<CustomLabel label="Reviewer" required />}
                        size={"small"}
                        variant={"outlined"}
                        error={!!errors["reviewer"]?._user}
                        helperText={errors["reviewer"]?._user?.message}
                      >
                        {(reviewerList && reviewerList.data && reviewerList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Review Submission Date" required/>}
                        name="reviewer.submissionDate"
                        changeDate={changeDate}
                        value={getValues("reviewer.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="reviewer.notes"
                        render={(prop) => <TextField
                          label={<CustomLabel label="Notes For Reviewer" />}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["reviewer"] && !!errors["reviewer"]["notes"]}
                          helperText={errors["reviewer"] && errors["reviewer"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Designer</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="illustrator._user"
                        label={<CustomLabel label="Designer" required />}
                        size={"small"}
                        variant={"outlined"}
                        error={!!errors["illustrator"]?._user}
                        helperText={errors["illustrator"]?._user?.message}
                      >
                        {(designerList && designerList.data && designerList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Designer Submission Date" required/>}
                        name="illustrator.submissionDate"
                        changeDate={changeDate}
                        value={getValues("illustrator.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="illustrator.notes"
                        render={(prop) => <TextField
                          label={<CustomLabel label="Notes For Designer" />}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["illustrator"] && !!errors["illustrator"]["notes"]}
                          helperText={errors["illustrator"] && errors["illustrator"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="info-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>Publisher</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        control={control}
                        className="disable-text"
                        name="publisher._user"
                        label={<CustomLabel label="Publisher" required />}
                        size={"small"}
                        variant={"outlined"}
                        error={!!errors["publisher"]?._user}
                        helperText={errors["publisher"]?._user?.message}
                      >
                        {(publisherList && publisherList.data && publisherList.data.data || []).map(user => <MenuItem key={user._id} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <DateTime
                        label={<CustomLabel label="Publisher Submission Date" required/>}
                        name="publisher.submissionDate"
                        changeDate={changeDate}
                        value={getValues("publisher.submissionDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="publisher.notes"
                        render={(prop) => <TextField
                          label={<CustomLabel label="Notes For Publisher" />}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder="Type Notes"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={errors["publisher"] && !!errors["publisher"]["notes"]}
                          helperText={errors["publisher"] && errors["publisher"]["notes"]?.message}
                          {...prop.field}
                        />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <div className="mt-4 save-box">
              <Button
                variant="outlined"
                onClick={() => reset()}
                disabled={!resourceAllocate("cms-alternative-comparison-assignment.write")}
              >Discard</Button>
              <Button
                type="submit"
                className="ml-2"
                disabled={!resourceAllocate("cms-alternative-comparison-assignment.write")}
              >Save Changes</Button>
            </div>
          </form>

        </Grid>
      </Grid>
      <Outlet />
    </div>
  );
};

export default Assignments;