import Joi from "joi";
import { required } from "../../shared";

export const evaluationValidation = Joi.object({
  _keyResult: Joi.string().required().label("Key Result").messages({
    "string.empty": required,
  }),

  endDate: Joi.string().required().label("Timeline").messages({
    "string.empty": required,
  }),
  note: Joi.string().min(3).trim().required().label("Note").messages({
    "string.empty": required,
  }),
  feedback: Joi.string().min(3).trim().required().label("Feedback").messages({
    "string.empty": required,
  }),
  _assignee: Joi.string().required().label("Responsible Person").messages({
    "string.empty": required,
  }),
  createdBy: Joi.string().required().label("Created By").messages({
    "string.empty": required,
  }),
  targetFigure: Joi.number().required().label("Target Value").messages({
    "string.empty": required,
  }),
  achievedValue: Joi.number().required().label("Achieved Value").messages({
    "string.empty": required,
  }),
  qualityOfCompletion: Joi.string()
    .required()
    .label("Quality of Completion")
    .messages({
      "string.empty": required,
    }),
  isCompleted: Joi.boolean().required().label("Mark As Completed").messages({
    "string.empty": required,
  }),
});
