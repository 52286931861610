import Joi from "joi";
import { required } from "../../../shared";

export const glossaryContentValidation = Joi.object({
    content: Joi.string()
        .required()
        .trim()
        .label("Content")
        .messages({
            "string.empty": required,
        }),
    metaTitle: Joi.string()
        .required()
        .label("Meta Title")
        .min(3)
        .trim()
        .max(60)
        .messages({
            "string.empty": required,
        }),
    metaDescription: Joi.string()
        .required()
        .min(3)
        .trim()
        .max(165)
        .label("Meta Description")
        .messages({
            "string.empty": required,
        }),
});
