import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { IUser, TUserContextType } from "../interfaces";
import { UsersService } from "../services";
import { useQuery } from "@tanstack/react-query";

const UserContext = createContext<TUserContextType | unknown>({});

interface ContextProps {
    children: ReactNode;
}

const UserProvider: FC<ContextProps> = ({ children }) => {
    const [user, setUser] = useState<IUser>();
    const { myProfile } = UsersService();
    const myProfileDetail = useQuery({ queryKey: ["myProfile", localStorage.getItem("currentUserToken")], queryFn: myProfile, enabled: !!localStorage.getItem("currentUserToken") });

    useEffect(() => {
        if (myProfileDetail.data?.success) {
            setUser(myProfileDetail.data.data);
        }
    }, [myProfile, localStorage.getItem("currentUserToken")]);

    return (
        <UserContext.Provider value={{ user, setUser, userRefetch: myProfileDetail.refetch }}>
            {children}
        </UserContext.Provider>
    );
};

const useUser = () => useContext(UserContext) as TUserContextType;

export { UserProvider };
export default useUser;