
import { IGlossaryImageResponse, IGlossaryImageResponses } from "../../../interfaces/content-marketing/glossary/image";
import { glossaryImage } from "../../endpoints";
import HttpService from "../../http";
const GlossaryImageService = () => {

    const { httpRequest } = HttpService();
    const getGlossaryImageList = async (search: object): Promise<IGlossaryImageResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryImageResponses>(
                "GET",
                `${glossaryImage}/list`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addGlossaryImage = async (payload: object): Promise<IGlossaryImageResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryImageResponse>(
            "POST",
            `${glossaryImage}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getGlossaryImage = async (query: object): Promise<IGlossaryImageResponse> => new Promise((resolve, reject) => {
        httpRequest<IGlossaryImageResponse>(
            "GET",
            `${glossaryImage}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteGlossaryImage = async (payload: object): Promise<IGlossaryImageResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryImageResponse>(
                "DELETE", 
                `${glossaryImage}`, 
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    const updateGlossaryImage = async (payload: object): Promise<IGlossaryImageResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IGlossaryImageResponse>(
                "PUT", 
                `${glossaryImage}`, 
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    return { addGlossaryImage, getGlossaryImageList, getGlossaryImage, deleteGlossaryImage, updateGlossaryImage };

};

export { GlossaryImageService };