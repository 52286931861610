import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../components/shared/mui-tabs";
import { AlternativeImageService } from "../../../../services/alternative-comparison/image";
import { useQuery } from "@tanstack/react-query";
interface props {
  onClose: () => void;
  addImage: (image: string, imageId: string, type: string) => void;
  _alternativeComparison: string;
  type: string;
}

const ImageDialog: FC<props> = ({
  onClose,
  addImage,
  _alternativeComparison,
  type,
}) => {
  const { getAlternativeImageList } = AlternativeImageService();
  const alternativeImage = useQuery({
    queryKey: ["alternative-images-list", _alternativeComparison],
    queryFn: () =>
      getAlternativeImageList({
        _alternativeComparison,
        pagination: false,
      }),
  });
  const images =
    (alternativeImage && alternativeImage.data && alternativeImage.data.data) ??
    alternativeImage.data?.data;
  return (
    <BootstrapDialog maxWidth="md" open={true} onClose={onClose}>
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          Select Image
        </Box>
      </BootstrapDialogTitle>
      {images && images.length ? (
        <div className="images-container">
          <Grid container spacing={2}>
            {images.map((image, i) => (
              <Grid key={i} item xs={6} sm={4} md={3} lg={3}>
                <div className="image-wrapper">
                  <img
                    src={image?.imageId?.imageUrl}
                    className="w-100 h-100 cursor-pointer"
                    onClick={() =>
                      addImage(image?.imageId?.imageUrl, image?._id, type)
                    }
                  />
                </div>
                <Tooltip title={`${image?.imageId?.name} (Alt: ${image.alternativeText})`} arrow>
                  <Typography className="image-name" variant="body1" noWrap>
                    {`${image?.imageId?.name} (Alt: ${image.alternativeText})`.length > 50
                      ? `${`${image?.imageId?.name} (Alt: ${image.alternativeText})`.slice(0, 50)}…`
                      : `${image?.imageId?.name} (Alt: ${image.alternativeText})`}
                  </Typography>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <Box className="center mb-5">
          Unfortunately, no images are currently available!
        </Box>
      )}
    </BootstrapDialog>
  );
};

export default ImageDialog;
