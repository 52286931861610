import { useQuery } from "@tanstack/react-query";
import { MouseEvent } from "react";
import { useState, useEffect } from "react";
import {
  Outlet,
  useSearchParams,
  useParams,
} from "react-router-dom";
import Header from "../../../../components/header";
import FeedbackParameters from "../../self-evaluation/feedback/filter";
import { FeedbackRatingService } from "../../../../services/pms/feedback";
import { IFeedbackTableState } from "../../../../interfaces/pms/team-evaluation";
import { Box } from "@mui/material";
import FeedbackTable from "./feedback-table";
import { format } from "date-fns";
import { formatDate } from "../../../../utilities/helper";

const SelfEvaluationParameterWise = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IFeedbackTableState>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filters: {
      search: "",
      date: {
        startDate: "",
        endDate: "",
      }
    },
    filterCount: 0,
  });

  const { getAllRatings } = FeedbackRatingService();

    const allFeedbacks = useQuery({
      queryKey: ["all-feedback-data", id, state.filters],
      queryFn: () =>
        getAllRatings({
          _parameter: id,
          startDate: state && state.filters.date && state.filters.date.startDate,
          endDate: state && state.filters.date && state.filters.date.endDate
        }),
    });
    const allFeedbacksData =
        allFeedbacks && allFeedbacks.data && allFeedbacks.data.data;

    useEffect(() => {
      if (!searchParams.get("date")) {
        const today = new Date();
        const lastYear = new Date();
        lastYear.setFullYear(today.getFullYear() - 1);
  
        const defaultDateFilter = [
          {
            key: "custom",
            value: "Custom",
            startDate: lastYear.toISOString(),
            endDate: today.toISOString(),
          },
        ];
  
        searchParams.set("date", JSON.stringify(defaultDateFilter));
        setSearchParams(searchParams);
      }
    }, []);

  useEffect(() => {
    let filterCount = 0;
    const dateFilter: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : []; 
    filterCount += dateFilter.length ? 1 : 0;
    let date: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (dateFilter?.length) {
      date = {
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      };
    }
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        date
      },
      filterCount,
    }));
  }, [searchParams]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  return (
    <>
      <Header
        className="my-2"
        onFilter={openFilter}
        filterCount={state.filterCount}
        filterStartDate={formatDate(state.filters.date?.startDate)}
        filterEndDate={formatDate(state.filters.date?.endDate)}
      >
        <FeedbackParameters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>
        <Box>
          <FeedbackTable data={allFeedbacksData || []} startDate={format(new Date(state.filters.date?.startDate || new Date()), "yyyy-MM-dd")} endDate={format(new Date(state.filters.date?.endDate || new Date()), "yyyy-MM-dd")} />
        </Box>
      <Outlet />
    </>
  );
};

export default SelfEvaluationParameterWise;
