import "./style.scss";
import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../../components/header";
import useDebounce from "../../../../hooks/useDebounce";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import CustomTable from "../../../../components/mui/table";
import Tag from "../components/tags";
import { IBroadcastAnalytics, IBroadcastAnalyticsRow, IBroadcastAnalyticsState } from "../../../../interfaces";
import PersonIcon from "@mui/icons-material/Person";
import CheckIcon from "@mui/icons-material/Check";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useQuery } from "@tanstack/react-query";
import { broadcastService } from "../../../../services";
import { capitalize, formatMobileNumber } from "../../../../utilities/helper";
import CustomTypography from "../../../../components/mui/max-length-limit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

const BroadcastAnalytics = () => {
    let rows: IBroadcastAnalyticsRow[] = [];
    const { id, broadcastId } = useParams();
    const [search, setSearch] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const { getBroadcastEvents, getBroadcastInsights } = broadcastService();
    const [state, setState] = useState<IBroadcastAnalyticsState>({
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1
        },
        filters: {
            search: ""
        }
    });
    const navigate = useNavigate();
    const broadcastEvents = useQuery({
        queryKey: ["broadcast-event", state.pagination.page, state.filters],
        queryFn: () => getBroadcastEvents({
            pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters, broadcastId: broadcastId
        })
    });

    useEffect(() => {
        if (broadcastEvents.data?.data.length) {
          setState(prevState => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              page: broadcastEvents.data.meta.page,
              totalPages: broadcastEvents.data.meta.totalPages,
              totalRecords: broadcastEvents.data.meta.totalRecords
            }
          }));
        }
      }, [broadcastEvents.data?.data]);

    const broadcastInsights = useQuery({
        queryKey: ["broadcast-insight", state.pagination.page, state.filters],
        queryFn: () => getBroadcastInsights({ broadcastId: broadcastId })
    });
    const eventType = searchParams.get("eventType") || "email";

    const searchRecord = useDebounce(search, 1000);
    useEffect(() => {
        if (searchRecord.length) {
            const prevParams: { [index: string]: string } = {};
            searchParams.forEach((value, key) => {
                prevParams[key] = value;
            });
            setSearchParams(prev => ({
                ...prevParams,
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }
    }, [searchRecord]);


    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page
            },
            filters: {
                ...prevState.filters,
                search
            }
        }));
    }, [searchParams]);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const columns = [
        {
            id: "name",
            label: "Name"
        },
        {
            id: "status",
            label: "Status"
        },
        {
            id: "error_message",
            label: "Message"
        },
    ];

    if (eventType === "email") {
        columns.splice(1, 0, {
            id: "email",
            label: "Email"
        });
    } else if (eventType === "whatsapp") {
        columns.splice(1, 0, {
            id: "number",
            label: "Whatsapp Number"
        });
    }

    const createRow = (broadcast: IBroadcastAnalytics) => {
        const error_message = <Box display="flex" alignItems="center">
            {["READ", "SENT", "DELIVERED"].includes(broadcast.status) && <CheckCircleOutlineIcon color="success" className="mr-2" fontSize="small" />}
            {["FAILED"].includes(broadcast.status) && <HighlightOffIcon color="error" className="mr-2" fontSize="small" />}
            <CustomTypography
                limit={60}
                label={
                    broadcast?.message ||
                    (broadcast.status === "FAILED" && "Failed to send") ||
                    (["READ", "SENT", "DELIVERED"].includes(broadcast.status) && "Message has been successfully sent") ||
                    ""}
            />
        </Box>;

        return {
            name: (capitalize(broadcast?.leadDetails.name || "")),
            ...(eventType === "email" && { email: (broadcast?.leadDetails.email || "") }),
            ...(eventType === "whatsapp" && { number: formatMobileNumber(broadcast?.leadDetails.mobileNumber || "") }),
            status: capitalize(broadcast?.status || ""),
            error_message: error_message
        };
    };

    useEffect(() => {
        if (broadcastEvents.data && broadcastEvents.data.data && broadcastEvents.data.data.length === 0) {
          setState((prevState) => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
              totalPages: 1,
              totalRecords: 0,
            },
          }));
        }
      }, [broadcastEvents.data && broadcastEvents.data.data]);

    if (broadcastEvents.data?.data.length) {
        rows = broadcastEvents.data?.data.map((broadcast) => createRow(broadcast));
    }    

    return (
        <div className="broadcast-analytics">
            {/* insight  */}
            <Box className="tags" marginBottom="10px">
                <Box className="head">
                    <Tooltip title="Back">
                        <IconButton className="back mr-1" onClick={() => navigate(`/broadcast-group/${id}/broadcast`)}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography className="head" variant="body1" style={{ fontWeight: 700 }}>Insights</Typography>
                </Box>

                <Box className="overview-tabs">
                    <Tag
                        name="Total Contacts"
                        count={broadcastInsights.data?.data?.totalEvents || 0}
                        icon={<PersonIcon color="primary" />}
                        iconColor="blue"
                        className={eventType === "whatsapp" ? "tot-box-5" : "tot-box-3"}
                    />
                    <Tag
                        name="Sent"
                        count={
                            eventType === "whatsapp" ?
                                (
                                    (broadcastInsights.data?.data?.DELIVERED && broadcastInsights.data?.data?.READ)
                                        ?
                                        (broadcastInsights.data?.data?.DELIVERED + broadcastInsights.data?.data?.READ)
                                        : 0)
                                :
                                (broadcastInsights.data?.data?.SENT || 0)
                        }
                        icon={<CheckIcon color="primary" />}
                        iconColor="blue"
                        className={eventType === "whatsapp" ? "tot-box-5" : "tot-box-3"}
                    />
                    <Tag
                        name="Failed"
                        count={broadcastInsights.data?.data?.FAILED || 0}
                        icon={<SmsFailedIcon color="error" />}
                        iconColor="red"
                        className={eventType === "whatsapp" ? "tot-box-5" : "tot-box-3"}
                    />
                    {
                        eventType === "whatsapp" &&
                        <Tag
                            name="Delivered"
                            count={broadcastInsights.data?.data?.DELIVERED || 0}
                            icon={<DoneAllIcon color="primary" />}
                            iconColor="blue"
                            className={eventType === "whatsapp" ? "tot-box-5" : "tot-box-3"}
                        />
                    }
                    {
                        eventType === "whatsapp" &&
                        <Tag
                            name="Read"
                            count={broadcastInsights.data?.data?.READ || 0}
                            icon={<DoneAllIcon color="success" />}
                            iconColor="green"
                            className={eventType === "whatsapp" ? "tot-box-5 last-count-box" : "tot-box-3 last-count-box"}
                        />
                    }
                </Box>
            </Box>

            {/* Add Data  */}
            <Header
                searchPlaceholder="Search by email or number"
                onSearch={onSearch}
            />

            {/* Show Data  */}
            <Box marginTop="10px">
                <CustomTable
                    columns={columns}
                    rows={rows}
                    height="calc(100vh - 365px)"
                    pagination={state.pagination}
                    onPageChange={onPageChange}
                />
            </Box>
        </div>
    );
};

export default BroadcastAnalytics;
