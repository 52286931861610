import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useSnackbar from "../../../../../hooks/useSnackbar";
import {
  IKrRequestData,
  IKrRequestFields,
} from "../../../../../interfaces/okr";
import { IErrorResponse } from "../../../../../interfaces";
import { okrKeyResultsService } from "../../../../../services/okr/objectives/key-results";
import CustomLabel from "../../../../../components/mui/custom-label";
import { selfAssessmentValidation } from "../../../../../validations/okr/self-assessment";
import { KrRequestsService } from "../../../../../services";
import { capitalize } from "../../../../../utilities/helper";
import { OKR_ERROR_MESSAGES } from "../../../../../utilities/messages";
interface outletProps {
  reFetch: () => void;
}

const ManagePendingKRs = () => {
  const { krId, reqId } = useParams();
  const location = useLocation();
  const { snackbar } = useSnackbar();
  const [searchParam] = useSearchParams();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const {
    addRequest,
    getPendingSelfAssessment,
    updateRequest,
  } = KrRequestsService();
  const { getKeyResult } = okrKeyResultsService();
  const req = useQuery({
    queryKey: [krId],
    queryFn: () => getPendingSelfAssessment({ _id: reqId, _keyResult: krId }),
    enabled: !!krId,
  });
  const kr = useQuery({
    queryKey: [krId, "key-result"],
    queryFn: () => getKeyResult({ _id: krId }),
    enabled: !!krId,
  });
  const krData = kr.data?.data;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IKrRequestData>({
    resolver: joiResolver(selfAssessmentValidation),
    defaultValues: {
      _id: "",
      _keyResult: "",
      _objective: "",
      note: "",
      targetFigure: 0,
      achievedValue: 0,
    },
  });

  const onClose = () => {
    navigate({
      pathname: location.pathname.split("/").slice(0, 3).join("/"),
      search: searchParam.toString(),
    });
  };

  const onSubmit = async (data: IKrRequestData) => {
    if(data?.achievedValue > data?.targetFigure){
      return snackbar(OKR_ERROR_MESSAGES.en.invalid_achieved_value, "warning");
    }
    try {
      const payload = {
        ...data,
        targetFigure: Number(krData?.targetFigure),
        _keyResult: krData?._id,
        _objective: krData?._objective._id,
        _approver: krData?._approver._id,
      };
      if(!data?._id?.length){
        delete payload._id;
      }
      if (!req.data?.data?._id) {
        const add = await addRequest(payload);
        snackbar(add.message, "info");
      } else {
        const editPayload = { ...payload, _id: req?.data?.data?._id };
        const update = await updateRequest(editPayload);
        snackbar(update.message, "info");
      }
      outlet?.reFetch && outlet.reFetch();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  useEffect(() => {
    setValue("_objective", krData?._objective?.name || "");
    setValue("_keyResult", krData?.name || "");
    setValue("targetFigure", krData?.targetFigure || 0);
  }, [krData]);

  useEffect(() => {
    setValue("note", req?.data?.data?.note || "");
    setValue("achievedValue", req?.data?.data?.achievedValue || 0);
  }, [req.data]);

  const fields: IKrRequestFields[] = [
    {
      label: "Key Result",
      name: "_keyResult",
      type: "input",
      disabled: true,
      required: true,
      value: krData?.name
    },
    {
      label: "Objective",
      name: "_objective",
      type: "input",
      disabled: true,
      required: true,
      value: krData?._objective?.name
    },
    {
      label: "Target Value",
      name: "targetFigure",
      type: "input",
      disabled: true,
      required: true,
      value: krData?.targetFigure,
    },
    {
      label: "Achieved Value",
      name: "achievedValue",
      type: "input",
      placeholder: "Type achieved figure here",
      required: true,
      value: req.data?.data?.achievedValue,
    },
    {
      label: "Note",
      name: "note",
      type: "input",
      placeholder: "Type note here",
      required: true,
      value: req.data?.data?.note,
    },
  ];

  return (
    <Drawer
      anchor="right"
      open={!!krId}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "25%",
          padding: "1.5rem 1rem",
        },
      }}
    >
      <Box>
        <Typography variant="h6" component="h6" gutterBottom>
          {capitalize(krData?.name || "Self Assessment")}
        </Typography>
        <Divider />
      </Box>
      <Grid container spacing={2} mt={2}>
        {fields.map((field) => {
          if (field.type === "input") {
            return (
              <Grid key={field.name} item xs={12}>
                <Controller
                  control={control}
                  name={field.name}
                  render={({ field: controllerField }) => (
                    <TextField
                      {...controllerField}
                      name={field.name}
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field.required}
                        />
                      }
                      disabled={field.disabled}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      value={getValues(field.name)}
                      helperText={errors[field.name]?.message}
                      multiline={field.name === "note"}
                      minRows={field.name === "note" ? 3 : 1}
                      maxRows={field.name === "note" ? 3 : 1}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        onWheel: (e) => {
                          (e.target as HTMLElement).blur();
                          e.preventDefault();
                        },
                      }}
                      type={
                        field.name === "note" ||
                        field.name === "_keyResult" ||
                        field.name === "_objective"
                          ? "text"
                          : "number"
                      }
                      onKeyDown={(e) => {
                        if (field.name === "achievedValue" || field.name === "targetFigure") {
                          if (["e", "E", "-"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            );
          }
        })}
      </Grid>
      <Box
        display={"flex"}
        justifyContent="flex-end"
        mt={2}
        gap={1}
        marginTop={
          errors.achievedValue || errors.note
            ? "calc(100vh - 520px)"
            : "calc(100vh - 450px)"
        }
      >
        <Button variant="outlined" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {req.data?.data?._id ? "Update" : "Submit"}
        </Button>
      </Box>
    </Drawer>
  );
};

export default ManagePendingKRs;
