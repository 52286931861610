import Joi from "joi";
import { required } from "../../../shared";
import { COMMON_ERROR_MESSAGES } from "../../../../utilities/messages/common";

const keyResultSchema = Joi.object({
  _id: Joi.string().allow(""),
  name: Joi.string().trim().min(3).max(60).required().label("Name").messages({
    "string.empty": required
  }),
  description: Joi.string().required().trim().min(3).label("Description").messages({
    "string.empty": required
  }),
  _assignee: Joi.string().required().label("Responsible Person").messages({
    "string.empty": required
  }),
  startDate: Joi.string().required().label("Start Date").messages({
    "string.empty": required
  }),
  endDate: Joi.string()
    .required()
    .label("End Date")
    .messages({ "string.empty": required, "endDate.beforeStartDate": COMMON_ERROR_MESSAGES.en.invalid_start_end_dates })
    .custom((value, helpers) => {
      const { startDate } = helpers.state.ancestors[0];
      if (new Date(value) < new Date(startDate)) {
        return helpers.error("endDate.beforeStartDate");
      }
      return value;
    }),
  figureType: Joi.string().required().label("Figure Type").messages({
    "string.empty": required
  }),
  priority: Joi.string().required().label("Priority").messages({
    "string.empty": required
  }),
  currentFigure: Joi.number().required().label("Current Figure").messages({
    "any.empty": required
  }),
  targetFigure: Joi.number().required().min(1).label("Target Figure").messages({
    "any.empty": required
  }),
  weightage: Joi.number().required().label("Weightage").messages({
    "any.empty": required
  }),
  _approver: Joi.string().required().label("Approver").messages({
    "string.empty": required
  })
});

export const keyResultStateValidation = Joi.object({
  keyResults: Joi.array().items(keyResultSchema).min(1).messages({
    "array.min": required
  })
});