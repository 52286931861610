import { IUserRequestResponse, IUserRequestsResponse } from "../../interfaces/user-requests";
import { userRequest } from "../endpoints";
import HttpService from "../http";


const UserRequestsService = () => {
    const { httpRequest } = HttpService();
    const addUserRequests = async (payload: object): Promise<IUserRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserRequestResponse>(
            "POST",
            `${userRequest}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getUserRequests = async (search: object): Promise<IUserRequestsResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserRequestsResponse>(
            "GET",
            `${userRequest}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getUserRequest = async (query: object): Promise<IUserRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserRequestResponse>(
            "GET",
            userRequest,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateUserRequest = async (query: object): Promise<IUserRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserRequestResponse>(
            "PUT",
            userRequest,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteUserRequest = async (query: object): Promise<IUserRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserRequestResponse>(
            "DELETE",
            `${userRequest}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return {
        addUserRequests,
        getUserRequests,
        getUserRequest,
        updateUserRequest,
        deleteUserRequest
    };
};

export { UserRequestsService };