
import { IBlogResponse } from "../../../interfaces";
import { IBlogSampleResponse } from "../../../interfaces/content-marketing/blog/import";
import { blog, blogImport, blogImportSample } from "../../endpoints";
import HttpService from "../../http";

const blogImportService = () => {

    const { httpRequest } = HttpService();
    const getBlogSample = async (search: object): Promise<IBlogSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogSampleResponse>(
                "GET",
                `${blogImportSample}`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    const importBlog = async (payload: object): Promise<IBlogResponse> =>
        new Promise((resolve, reject) => {
            // eslint-disable-next-line
            httpRequest<IBlogResponse>(
                "POST",
                `${blogImport}`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    const getKeywordUpdateImportSample = async (search: object): Promise<IBlogSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogSampleResponse>(
                "GET",
                `${blog}/update-keyword-sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
    });

    const keywordUpdateImport = async (payload: object): Promise<IBlogResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogResponse>(
                "PUT",
                `${blog}/update-keyword-import`,
                payload
            )
                .then(resolve)
                .catch(reject);
    });

    return { getBlogSample, importBlog, getKeywordUpdateImportSample, keywordUpdateImport };

};

export { blogImportService };