import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import { IKrRequest, IKrRequestState } from "../../../../interfaces/okr";
import useDebounce from "../../../../hooks/useDebounce";
import CustomTypography from "../../../../components/mui/max-length-limit";
import { IEvaluationsRow } from "../../../../interfaces/okr/evaluation";
import { capitalize, formatDate } from "../../../../utilities/helper";
import { columns } from "../helper";
import { EvaluationsService } from "../../../../services/okr/evaluations";
import OkrEvaluationsFilters from "../filters";

interface outletProps {
  refetchPendingKRs: () => void;
}

const PendingEvaluations = () => {
  let rows: IEvaluationsRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { getEvaluations } = EvaluationsService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IKrRequestState>({
    filterCount: 0,
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    pagination: { 
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
      date: undefined,
      _assignee: []
    }
  });

  const pendingEvaluations = useQuery({
    queryKey: ["all-pending-evaluations", state.pagination.page, state.filters],
    queryFn: () =>
      getEvaluations({
        type: "pending",
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        startDate: state.filters.date?.startDate,
        endDate: state.filters.date?.endDate,
        _assignee: state.filters?._assignee
      }),
  });
  const data = pendingEvaluations.data?.data;
  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (pendingEvaluations.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: pendingEvaluations.data.meta.page,
          totalPages: pendingEvaluations.data.meta.totalPages,
          totalRecords: pendingEvaluations.data.meta.totalRecords,
        },
      }));
    }
  }, [pendingEvaluations.data?.meta]);

  useEffect(() => {
    if (data?.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    let filterCount = 0;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const _assignee: { key: string; value: string }[] = searchParams.get(
      "_assignee"
    )
      ? JSON.parse(String(searchParams.get("_assignee")))
      : [];

    const dateFilter: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];

    filterCount += _assignee.length ? 1 : 0;
    filterCount += dateFilter.length ? 1 : 0;

    let date:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (dateFilter?.length) {
      date = {
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      filters: {
        search,
        _assignee: _assignee.map((item) => item.key),
        date,
      },
      filterCount,
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const createRow = (index: number, data: IKrRequest) => {
    const keyResult = <CustomTypography
        limit={80}
        label={capitalize(data?._keyResult?.name || "")}
        onClick={() => navigate({ pathname: "" + data?._id, search: searchParams.toString() })}
        color={"primary"}
    />;
    return {
      keyResult,
      responsibility: capitalize(data?._assignee?.name || ""), 
      objective: capitalize(data?._objective?.name),
      createdBy: capitalize(data?.createdBy?.name),
      targetFigure: data?.targetFigure,
      achievedValue: data?.achievedValue,
      endDate: formatDate(data?._keyResult?.endDate),
      submittedOn: formatDate(data?.submittedOn)
    };

  };

  if (data?.length) {
    rows = data.map((data, i) =>
      createRow(i, data)
    );
  }

  return (
    <>

      <Header
        className="my-2"
        searchPlaceholder="Search by KR or Objective name"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <OkrEvaluationsFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns.filter((column) => column.id !== "status")}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="There are no pending evaluations."
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      
      <Outlet context={{ ...outlet, reFetch: pendingEvaluations.refetch }} />
    </>
  );
};

export default PendingEvaluations;
