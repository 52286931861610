import { Box, IconButton, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DialogContent from "@mui/material/DialogContent";
import ManageSkillItemCategory from "..";
import { TabPanel } from "../../../cpd-layout/drives/drive-detail";
import { a11yProps, BootstrapDialog, BootstrapDialogTitle } from "../../../../components/shared/mui-tabs";
import UserTimeline from "../../../organisation/organisation-users/manage-user/timeline";
import useResource from "../../../../hooks/useResource";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";
import { capitalize } from "../../../../utilities/helper";
import EditIcon from "@mui/icons-material/Edit";

interface IState {
  redirectionLinks: string[];
}

const ManageSkillCategoryLayout = () => {
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(true);
  const { resourceAllocate } = useResource();
  const { categoryId, id } = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const { getSkillItemCategory } = SkillItemCategoryService();
  const [value, setValue] = useState(0);
  const skillItemCategory = useQuery({ queryKey: ["Skill-Matrix-Category"], queryFn: () => getSkillItemCategory({ _id: categoryId }), enabled: !!categoryId });
  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });
  const isTimeline = searchParam.get("type") === "timeline";

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
    if(resourceAllocate("skill-matrix-category.read")){
      redirectionLinks.push("basic-detail");
      redirectionLinks.push("timeline");
    }

      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }

    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);
  

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
  };

  const onClose = () => {
    searchParam.delete("type");
    navigate({ pathname: "/skill-matrix/manage/"+id, search: searchParam.toString() });
  };

  const name = skillItemCategory?.data?.data?.name ? capitalize(skillItemCategory?.data?.data?.name) : "";

return <div>
    <BootstrapDialog
      maxWidth="md"
      onClose={onClose}
      open={!!id}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          {name}  { isDisable && !isTimeline && <IconButton 
          className="ml-2" color="primary" onClick={() => setIsDisable(prev => !prev)} ><EditIcon /></IconButton>}
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: isTimeline ? "65vh" : "30vh" }}>
        <Box width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {(resourceAllocate("skill-matrix-category.read"))&& <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />}
                {resourceAllocate("skill-matrix-category.read") && <Tab className="tabs-space" label="Timeline" {...a11yProps(2)} />}
              </Tabs>    
          </Box>

          {/* Basic detail  */}
          <TabPanel
            style={{
              height: "calc(100% - 360px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={state.redirectionLinks.indexOf("basic-detail")}>
              <ManageSkillItemCategory data={skillItemCategory?.data?.data} isDisabled = {isDisable} />
          </TabPanel>

          {/* Timeline details  */}
          <TabPanel
            style={{
              height: "calc(100% - 60px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={state.redirectionLinks.indexOf("timeline")}>
            <UserTimeline _skillCategory={categoryId || ""} />
          </TabPanel>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  </div>;
};

export default ManageSkillCategoryLayout;