import "./style.scss";
import { FC, ReactNode } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
        backgroundColor: "#fff"
    }
}));

export interface DialogTitleProps {
    id: string;
    children?: ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 10,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface props {
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    title: string;
    paragraph?: string;
    isOpen: boolean;
    disabled?: boolean;
    confirmText?: string;
    cancelText?: string;
    onClose: () => void;
    onConfirm?: () => void;
    onSubmit?: () => void;
    onSelectAll?: () => void;
    children: ReactNode;
    hideDiscard?: boolean;
    hideSubmit?: boolean;
}

const CustomDialog: FC<props> = ({ size, title,paragraph, isOpen, disabled, confirmText, cancelText, onClose, onConfirm, onSubmit, children, hideDiscard, hideSubmit=false, onSelectAll }) => (
    <div>
        <BootstrapDialog
            maxWidth={size}
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            scroll='paper'
            disableEnforceFocus
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                {title}
                {
                    paragraph &&
                    <Box className="center" justifyContent="space-between">
                        <Typography variant="body2" style={{ color: "#999999", fontSize: "14px" }}>{paragraph}</Typography>
                    </Box>
                }
            </BootstrapDialogTitle>
            <form id="dialog-form" onSubmit={onSubmit}>
                <DialogContent >
                    {children}
                </DialogContent>           
                {
                    !disabled &&
                    <DialogActions id="dialog-action">
                        {!hideDiscard && <Button variant='outlined' size='medium'onClick={onClose}>
                            { cancelText ? cancelText : "Discard"}
                        </Button>}
                        {onSelectAll && <Button variant='outlined' size='medium' onClick={onSelectAll}>
                            {"Select All"}
                        </Button>}
                        {!hideSubmit && <Button size='medium' type="submit" autoFocus onClick={() => onConfirm && onConfirm()}>
                            { confirmText ? confirmText : "Save changes"}
                        </Button>}

                    </DialogActions>
                }
            </form>
        </BootstrapDialog>
    </div>
);

CustomDialog.defaultProps = {
    size: "md",
    disabled: false
};

export default CustomDialog;
