import { IErrorResponse, IProjectData } from "../../../../interfaces";
import { Autocomplete, capitalize, Grid, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { joiResolver } from "@hookform/resolvers/joi";
import CustomLabel from "../../../../components/mui/custom-label";
import { SiteMapSettingService } from "../../../../services/sitemap";
import { sitemapSettingsValidation } from "../../../../validations/sitemap";
import { useSelector } from "react-redux";
import { ISitemapSetting, ISitemapSettingBasicFields } from "../../../../interfaces/site-map/settings";
interface outletProps {
    reFetch: () => void;
    refetchSiteMapSetting: () => void;
}

const ManageSitemapSetting = () => {
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = !(id === "new");
    const navigate = useNavigate();
    const { addSiteMapSetting, updateSiteMapSetting, getSiteMapSetting} = SiteMapSettingService();
    const setting = useQuery({ queryKey: [hitQuery], queryFn: () => getSiteMapSetting({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, getValues, formState: { errors } } = useForm<ISitemapSetting>({
        resolver: joiResolver(sitemapSettingsValidation),
        defaultValues: {
          _product: "",
          hostname: ""
        }
    });

    const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
        (state) => state.cmsProduct.list
    ) || [];
    useEffect(() => {
        if (id !== "new" && setting.data) {
          const data = setting.data.data;
          setValue("hostname", data.hostname);
          setValue("_product", data._product._id);
        }
    }, [id, setting.data]);

    const onSubmit = async (data: ISitemapSetting) => {
        try {
            if (id === "new") {
                const payload = { ...data };

                const add = await addSiteMapSetting(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/sitemap/settings",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchSiteMapSetting && outlet.refetchSiteMapSetting();
            } else {
                const payload = { ...data, _id: id};
                const update = await updateSiteMapSetting(payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/sitemap/settings",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchSiteMapSetting && outlet.refetchSiteMapSetting();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/sitemap/settings",
            search: searchParam.toString()
        });
    };

    const fields: ISitemapSettingBasicFields[] = [
        {
            label: "Product Name",
            name: "_product",
            type: "auto-complete",
            placeholder: "Select product name",
            required: true,
        },
        {
            label: "Hostname",
            name: "hostname",
            type: "input",
            placeholder: "Type hostname",
            required: true,
        }
    ];

    return (
            <CustomDialog
            
                title={id !== "new" ? "Edit Setting" : "Add Setting"}
                isOpen={!!id}
                onClose={onClose}
                confirmText={id !== "new" ? "Edit Setting" : "Add Setting"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => 
                                        <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }else if(field.type === "auto-complete"){
                                return (
                                  <Grid xs={12} md={6} key={field.label} item>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={(prop) => <Autocomplete
                                        className="disable-text"
                                        options={productList.map(
                                          (data) => data
                                        ) || []}
                                        clearIcon={null}
                                        getOptionLabel={(option) => capitalize(option.name)}
                                        renderInput={(params) => <TextField
                                          {...params}
                                          error={!!errors[field.name]}
                                          helperText={errors[field.name]?.message}
                                          size={"small"}
                                          variant={"outlined"}
                                          label={<CustomLabel label="Product" required={field?.required} />}
                                          placeholder={field.placeholder}
                                        />
                                      }
                                        {...prop.field}
                                        value={productList.find(data => data._id === getValues(field.name)) || null}
                                        onChange={(e, value) => {
                                          setValue(field.name, value?._id || "");
                                        }}
                                        renderOption={(props, option) => (
                                          <li {...props} key={option._id}>
                                            {capitalize(option.name)}
                                          </li>
                                        )}
                                      />}
                                    />
                                  </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
    );

};

export default ManageSitemapSetting;