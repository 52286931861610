
import { IAlternativeImageResponse, IAlternativeImageResponses } from "../../../interfaces/alternative-comparison/image";
import { alternativeImage } from "../../endpoints";
import HttpService from "../../http";
const AlternativeImageService = () => {

    const { httpRequest } = HttpService();
    const getAlternativeImageList = async (search: object): Promise<IAlternativeImageResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IAlternativeImageResponses>(
                "GET",
                `${alternativeImage}/list`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addAlternativeImage = async (payload: object): Promise<IAlternativeImageResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeImageResponse>(
            "POST",
            `${alternativeImage}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getAlternativeImage = async (query: object): Promise<IAlternativeImageResponse> => new Promise((resolve, reject) => {
        httpRequest<IAlternativeImageResponse>(
            "GET",
            `${alternativeImage}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteAlternativeImage = async (payload: object): Promise<IAlternativeImageResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IAlternativeImageResponse>(
                "DELETE", 
                `${alternativeImage}`, 
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    const updateAlternativeImage = async (payload: object): Promise<IAlternativeImageResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IAlternativeImageResponse>(
                "PUT", 
                `${alternativeImage}`, 
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    return { addAlternativeImage, getAlternativeImageList, getAlternativeImage, deleteAlternativeImage, updateAlternativeImage };

};

export { AlternativeImageService };