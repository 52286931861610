import { IAllGivenFeedbacksResponse, IFeedbackStatusResonse, ISelfEvaluationFeedbackResonse, ISelfEvaluationResonse } from "../../../interfaces/pms/self-evaluation";
import { pmsRatingFeedback } from "../../endpoints";
import HttpService from "../../http";


const FeedbackRatingService = () => {
    const { httpRequest } = HttpService();

    const addFeedbackRating = async (payload: object): Promise<ISelfEvaluationResonse> => new Promise((resolve, reject) => {
        httpRequest<ISelfEvaluationResonse>(
            "POST",
            `${pmsRatingFeedback}`,
            payload
        )
            .then(resolve)
            .catch(reject); 
    });

    const getFeedbackStatus = async (query: object): Promise<IFeedbackStatusResonse> => new Promise((resolve, reject) => {
        httpRequest<IFeedbackStatusResonse>(
            "GET",
            `${pmsRatingFeedback}/status`,
            {},
            query
        )
            .then(resolve)
            .catch(reject); 
    });

    const getAllGivenFeedbacks = async (query: object): Promise<IAllGivenFeedbacksResponse> => new Promise((resolve, reject) => {
        httpRequest<IAllGivenFeedbacksResponse>(
            "GET",
            `${pmsRatingFeedback}/user-feedbacks`,
            {},
            query
        )
            .then(resolve)
            .catch(reject); 
    });

    const getSelfFeedbacks = async (search: object): Promise<ISelfEvaluationFeedbackResonse> => new Promise((resolve, reject) => {
        httpRequest<ISelfEvaluationFeedbackResonse>(
            "GET",
            `${pmsRatingFeedback}/feedback-list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getAllRatings = async (query: object): Promise<IAllGivenFeedbacksResponse> => new Promise((resolve, reject) => {
        httpRequest<IAllGivenFeedbacksResponse>(
            "GET",
            `${pmsRatingFeedback}/my-feedbacks`,
            {},
            query
        )
            .then(resolve)
            .catch(reject); 
    });

    return { addFeedbackRating, getFeedbackStatus, getAllGivenFeedbacks, getSelfFeedbacks, getAllRatings };
};

export { FeedbackRatingService };