import { useState, useEffect, ChangeEvent } from "react";
import { capitalize, createIndex } from "../../../utilities/helper";
import { Box} from "@mui/material";
import useSnackbar from "../../../hooks/useSnackbar";
import { Outlet, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import WarningDialog from "../../../components/mui/warning-dialog";
import { IUrlBundlerData, IUrlBundleRow, IUrlBundleState } from "../../../interfaces/site-map/url-bundles";
import { useQuery } from "@tanstack/react-query";
import { UrlBundleService } from "../../../services/sitemap";
import GetActions from "../../../components/get-actions";
import { IErrorResponse, IPagination } from "../../../interfaces";

interface outletProps {
  refetchUrlBundles: () => void;
}

const SiteMapUrlBundle = () => {
  let rows: IUrlBundleRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getUrlBundles, deleteUrlBundle } = UrlBundleService();
  const [state, setState] = useState<IUrlBundleState>({
    deleteWarning: false,
    _urlBundle: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    }
  });

  const urlBundle = useQuery({
    queryKey: ["allUrlBundles", state.pagination.page],
    queryFn: () =>
      getUrlBundles({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page
      }),
  });

  useEffect(() => {
    if (urlBundle.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: urlBundle.data.meta.page,
          totalPages: urlBundle.data.meta.totalPages,
          totalRecords: urlBundle.data.meta.totalRecords,
        },
      }));
    }
  }, [urlBundle.data?.meta]);

  useEffect(() => {
    if (urlBundle.data && urlBundle.data.data && urlBundle.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [urlBundle.data && urlBundle.data.data]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleDelete = (_urlBundle = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _urlBundle,
    }));
  };

  const onDelete = async () => {
    try {
      const category = await deleteUrlBundle({ _id: state._urlBundle });
      snackbar(category.message, "info");
      handleDelete();
      urlBundle.refetch();
      outlet?.refetchUrlBundles && outlet.refetchUrlBundles();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "type",
      label: "Type",
    },
    {
      id: "product",
      label: "Product"
    },
    {
      id: "langcode",
      label: "Last Modified",
    },
    {
      id: "pattern",
      label: "Pattern",
    },
    {
      id: "action",
      label: "Actions",
    }
  ];


  const createRow = (index: number, bundle: IUrlBundlerData, pagination: IPagination) => {
    const action = 
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({ pathname:`${bundle._id}`, search: searchParams.toString() }),
            disabled: !resourceAllocate("cms-url-bundle.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(bundle._id),
            disabled: !resourceAllocate("cms-url-bundle.remove"),
          },
        ]}
      />;

    return {
      id: createIndex(pagination, index),
      product: capitalize(bundle._product.name),
      type: capitalize(bundle.type),
      langcode: (bundle.lastMod).toString(),
      pattern: bundle.pattern,
      action,
    };
  };

  if (urlBundle && urlBundle.data && urlBundle.data.data && urlBundle.data.data.length) {
    rows = urlBundle.data.data.map((bundle, i) =>
      createRow(i, bundle, state.pagination)
    );
  }

  return (
    <>
      <Header
        className="my-2"
        btnText="Add Url Bundle"
        onBtnClick={
          resourceAllocate("cms-url-bundle.write") ? () => navigate({ pathname: "new", search: searchParams.toString()}) : undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="Add url bundle to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Url Bundle"
        description="Are you sure you want to delete this url bundle?"
      />
      
      <Outlet context={{ ...outlet, reFetch: urlBundle.refetch }} />
    </>
  );
};

export default SiteMapUrlBundle;