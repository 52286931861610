import Joi from "joi";
import { required } from "../shared";
export const MediaImageValidation = Joi.object({
  name: Joi.string()
  .trim()
  .required()
  .label("Name")
  .messages({
      "string.empty":required
  }),
  imageUrl: Joi.string()
  .required()
  .label("Image Url")
  .messages({
      "string.empty":required
  }),
});
