import { useQuery } from "@tanstack/react-query";
import { Box, IconButton } from "@mui/material";
import { ChangeEvent, useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  IColumn,
  IErrorResponse,
  IPagination
} from "../../../interfaces";
import {
  capitalize,
  createIndex
} from "../../../utilities/helper";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WarningDialog from "../../../components/mui/warning-dialog";
import CustomTypography from "../../../components/mui/max-length-limit";
import {
  IUserRequest,
  IUserRequestsRow,
  IUserRequestState,
} from "../../../interfaces/user-requests";
import { UserRequestsService } from "../../../services/user-requests";

interface outletProps {
  refetchUsers: () => void;
}

const UserRequests = () => {
  let rows: IUserRequestsRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getUserRequests, deleteUserRequest } = UserRequestsService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IUserRequestState>({
    deleteWarning: false,
    _userRequest: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
    },
  });

  const userRequests = useQuery({
    queryKey: ["allUserRequests", state.pagination.page, state.filters],
    queryFn: () =>
      getUserRequests({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        ...state.filters,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (userRequests.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: userRequests.data.meta.page,
          totalPages: userRequests.data.meta.totalPages,
          totalRecords: userRequests.data.meta.totalRecords,
        },
      }));
    }
  }, [userRequests.data?.meta]);

  useEffect(() => {
    if (
      userRequests.data &&
      userRequests.data.data &&
      userRequests.data.data.length === 0
    ) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page:
            prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [userRequests.data && userRequests.data.data]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_userRequest = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _userRequest,
    }));
  };

  const onDelete = async () => {
    try {
      const user = await deleteUserRequest({ _id: state._userRequest });
      snackbar(user.message, "info");
      handleDelete();
      userRequests.refetch();
      outlet?.refetchUsers && outlet.refetchUsers();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const onCopy = (label: string, text: string) => {
    navigator.clipboard.writeText(text);
    snackbar(`${capitalize(label)} ID copied`, "info");
  };

  const columns : IColumn[] = [
    {
      id: "id",
      label: "S No.",
      maxWidth: 10,
      minWidth: 10
    },
    {
      id: "user_name",
      label: "Name",
    },
    {
      id: "user_email",
      label: "Email",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const createRow = (
    index: number,
    user: IUserRequest,
    pagination: IPagination
  ) => {
    const actions = (
      <GetActions
        icons={[
          {
            name: "Approve",
            method: () =>
              navigate({
                pathname: "" + user._id,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("user.write"),
          },
          {
            name: "Reject",
            method: () => handleDelete(user._id),
            disabled: !resourceAllocate("user.remove"),
          },
        ]}
      />
    );

    const email = (
      <Box display="flex" alignItems="center">
        <IconButton
          className="ml-2"
          onClick={() => onCopy("email", user.email)}
          size="small"
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
        <CustomTypography limit={20} label={user.email} />
      </Box>
    );

    return {
      id: createIndex(pagination, index),
      user_email: email,
      user_name: user.name,
      actions,
    };
  };

  if (userRequests.data?.data.length) {
    rows = userRequests.data?.data.map((user, i) =>
      createRow(i, user, state.pagination)
    );
  }

  return (
    <div>
      <Header
        className="my-2"
        searchPlaceholder="Search by email"
        onSearch={onSearch}
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 200px)"
          errorMessage="There are no user requests."
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Reject User Request"
        description="Are you sure you want to reject this user request?"
      />
      <Outlet context={{ ...outlet, reFetch: userRequests.refetch }} />
    </div>
  );
};

export default UserRequests;
