import { useEffect, useState, MouseEvent } from "react";
import { Box, FormControl, IconButton, Typography, Select, MenuItem, SelectChangeEvent, Chip, Tooltip, Avatar } from "@mui/material";
import { ChangeEvent } from "react";
import { CollegeService } from "../../../services";
import { useQuery } from "@tanstack/react-query";
import { IErrorResponse, IPagination } from "../../../interfaces";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { capitalize, createIndex, displayName, checkCollegeStatusColor } from "../../../utilities/helper";
import { ICollegeRow, ICollegeState, ICollege, IUser } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import CollegeFilters from "../Components/college-filter";
import useUser from "../../../hooks/useUser";
import useResource from "../../../hooks/useResource";
import ImageViewer from "react-simple-image-viewer"; 


interface outletProps {
  refetchColleges: () => void;
}

interface IImageViewer {
  open: boolean,
  index: number,
  images: string[],
}

const College = () => {
  let rows: ICollegeRow[] = [];
  const { snackbar } = useSnackbar();
  const { allowPermission, resourceAllocate } = useResource();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getColleges, deleteCollege, updateCollege } = CollegeService();
  const navigate = useNavigate();
  const { user } = useUser();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  const userIds = users.map(user => user._id);
  const outlet = useOutletContext<outletProps>();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<ICollegeState>({
    deleteWarning: false,
    _college: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      assignTo: [],
      status: [],
      category: [],
      college: []
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });

  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });

  const colleges = useQuery({
    queryKey: ["allColleges", state.pagination.page, state.filters],
    queryFn: () => getColleges({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const actionNavigate = (_id: string, type: string) => {
    searchParams.set("type", type);
    navigate({ pathname: "action/" + _id, search: searchParams.toString() });
  };

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });

      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (colleges.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: colleges.data.meta.page,
          totalPages: colleges.data.meta.totalPages,
          totalRecords: colleges.data.meta.totalRecords
        }
      }));
      const images: string[] = [];
      colleges.data?.data.map(picture => {
        images.push(picture.image);
      });

      setImageViewer(prev => ({
        ...prev,
        images
      }));
    }
  }, [colleges.data?.meta]);

  useEffect(() => {
    if (colleges.data && colleges.data.data && colleges.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [colleges.data && colleges.data.data]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const college: { key: string, value: string }[] = searchParams.get("college") ? JSON.parse(String(searchParams.get("college"))) : [];
    filterCount += assignTo.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += category.length ? 1 : 0;
    filterCount += college.length ? 1 : 0;
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        assignTo: assignTo.map(assign => assign.key),
        status: status.map(status => status.key),
        category: category.map(category => category.key),
        college: college.map(college => college.key),
      },
      filterCount
    }));
  }, [searchParams]);

  useEffect(() => {
    const fullName = `${(displayName(user))}`;
    searchParams.set("assignTo", JSON.stringify([{ key: user?._id, value: fullName }]));

    setSearchParams(searchParams);
  }, []);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const onUpdate = async (event: SelectChangeEvent<string | IUser | "none">, _id: string, shortName: string, city: string, state: string, category: string, name: string) => {
    const { name: targetName, value } = event.target;
    try {
      const payload = {
        _id,
        name,
        shortName,
        city,
        state,
        category,
        [targetName]: value
      };

      const updated = await updateCollege(_id, payload);
      snackbar(updated.message, "info");
      colleges.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const handleDelete = (_college = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _college
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteCollege({ _id: state._college });
      snackbar(deleted.message, "info");
      handleDelete();
      colleges.refetch();
      outlet?.refetchColleges && outlet.refetchColleges();
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };

  const onCopy = (label: string) => {
    navigator.clipboard.writeText(label);
    snackbar(`${(label)} ID copied`, "info");
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "college_image",
      label: "Image"
    },
    {
      id: "college_name",
      label: "Name"
    },
    {
      id: "college_city",
      label: "City"
    },
    {
      id: "college_state",
      label: "State"
    },
    {
      id: "college_category",
      label: "Category"
    },
    {
      id: "college_mobileNo",
      label: "Mobile No."
    },
    {
      id: "college_contactPersonName",
      label: "Contact Person Name"
    },
    {
      id: "college_drive",
      label: "Drive"
    },
    {
      id: "college_assign_to",
      label: "Assign To"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];


  const createRow = (index: number, college: ICollege, pagination: IPagination) => {
    const collegeId =
      <Box className="center">
        <Tooltip title={(college.collegeId)}>
          <IconButton className="ml-2" onClick={() => onCopy(college?.collegeId)} size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton >
        </Tooltip>
      </Box>;

    const collegeName = (
      <Tooltip title={capitalize(college.name)}>
        <Typography
          onClick={() => navigate({ pathname: "manage/" + college._id, search: searchParams.toString() })}
          variant="body2"
          color="primary"
          className="link-none-underline"
        >
          {(college.shortName.toUpperCase())}
        </Typography>
      </Tooltip>
    );

    let chipStatusColor = checkCollegeStatusColor(college.isDriveActive);
    const chipLabel = college.isDriveActive ? "Active" : "Create";
    let chipClickable = true;

    if (college.isDriveActive) {
      chipStatusColor = "success";
      chipClickable = false;
    }

    const collegeDrive = (
      <Chip
        variant="outlined"
        color={chipStatusColor}
        label={chipLabel}
        onClick={chipClickable ? () => navigate({ pathname: "/cpd/drives/new", search: `collegeId=${college._id}` }) : undefined}
      />
    );

    const assignTo =
      <FormControl
        sx={{ width: 150 }}>
        <Select
          size="small"
          disabled={allowPermission(college.assignTo) ? false : true}
          onChange={e => onUpdate(e, college?._id, college.shortName, college.city, college.state, college.category, college.name)}
          name="assignTo"
          value={
            (userIds.includes(college?.assignTo))
              ?
              college.assignTo
              :
              "none"
          }
        >
          <MenuItem disabled value="none">Select</MenuItem>
          {
            users.map((user, i) => <MenuItem key={i} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)
          }
        </Select>
      </FormControl>;

    const action =
      (<Box sx={{ display: "flex", alignItems: "center" }}>
        {collegeId}
        <GetActions
          icons={[
            {
              name: "Mail", method: () => actionNavigate(college._id, "mail"),
              disabled: resourceAllocate("college.write") ?
                ((allowPermission(college.assignTo)) ?
                  false :
                  true)
                : true
            },
            {
              name: "Delete",
              method: () => handleDelete(college._id),
              disabled: resourceAllocate("college.remove") ?
                ((allowPermission(college.assignTo)) ?
                  false :
                  true)
                : true
            },
          ]}
        />
      </Box>);

    const contactPersonArray = Array.isArray(college?.contactPersons) ? college?.contactPersons : [college?.contactPersons];
    const collegeContactPersonNames = contactPersonArray.map(person => capitalize(person?.name)).join(",");
    const firstContactPersonName = capitalize(contactPersonArray[0]?.name);
    const shouldShowTooltip = contactPersonArray.length > 1;
    const collegeNameWithTooltip = (
      <>
        {shouldShowTooltip ? (
          <Tooltip title={collegeContactPersonNames} placement="bottom-start">
            <Typography variant="body2" className="link-none-underline">
              {firstContactPersonName}
              {contactPersonArray.length > 1 ? `+${contactPersonArray.length - 1}` : ""}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="body2" className="link-none-underline">
            {firstContactPersonName}
          </Typography>
        )}
      </>
    );

    return {
      id: createIndex(pagination, index),
      college_image: <IconButton onClick={() => handleImageViewer(index)}><Avatar className="thumbnail" src={college?.image} alt="" /></IconButton>,
      college_name: collegeName,
      college_city: capitalize(college.city),
      college_state: capitalize(college.state),
      college_category: capitalize(college.category),
      college_mobileNo: college.mobileNumber,
      college_contactPersonName: collegeNameWithTooltip,
      college_drive: collegeDrive,
      college_assign_to: assignTo,
      action,
    };
  };

  if (colleges.data?.data.length) {
    rows = colleges.data?.data.map((college, i) => createRow(i, college, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by name or city name"
        onSearch={onSearch}
        btnText="ADD COLLEGE"
        onBtnClick={resourceAllocate("college.write") ? () => navigate({
          pathname: "new",
          search: searchParams.toString()
        }): undefined}
        onImport={() => navigate("import")}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >

        <CollegeFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete College Name"
        description="Are you sure you want to delete this college name?"
      />

      {/* Preview Image */}
      {imageViewer.open && (
        <>
          <ImageViewer
            src={imageViewer.images}
            currentIndex={imageViewer.index}
            disableScroll={true}
            closeOnClickOutside={false}
            onClose={handleImageViewer}
            backgroundStyle={
              {
                color: "white",
                zIndex: 5
              }
            }
          />
        </>
      )}

      <Outlet context={{ ...outlet, reFetch: colleges.refetch }} />
    </>
  );
};
export default College;
