import "./style.scss";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import {
  capitalize,
  formatDate,
} from "../../../utilities/helper";
import { Box, Tooltip, Typography } from "@mui/material";
import CustomTypography from "../../../components/mui/max-length-limit";
import {
  IOKRObjectiveData,
  IOkrObjectivesRow,
  IOkrObjectiveState,
} from "../../../interfaces/okr";
import { OkrObjectivesService } from "../../../services/okr";
import useSnackbar from "../../../hooks/useSnackbar";
import WarningDialog from "../../../components/mui/warning-dialog";
import GetActions from "../../../components/get-actions";
import OkrObjectiveFilters from "./filter";
import { IErrorResponse } from "../../../interfaces";
import { getRecurringObjectiveNames, ProgressBar, StatusChip } from "../../../utilities/helper1";

interface outletProps {
  refetchObjectives: () => void;
}

const OKRObjectives = () => {
  let rows: IOkrObjectivesRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const { getObjectives, deleteObjective } = OkrObjectivesService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IOkrObjectiveState>({
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filters: {
      search: "",
      type: [],
      frequency: [],
      _department: [],
      status: [],
    },
    filterCount: 0,
    deleteWarning: false,
    _objective: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
  });

  const okrObjectives = useQuery({
    queryKey: ["all-okr-objectives", state.filters, state.pagination.page],
    queryFn: () =>
      getObjectives({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        startDate: state.filters.date?.startDate,
        endDate: state.filters.date?.endDate,
        type: state.filters.type,
        _department: state.filters._department,
        status: state.filters.status,
        frequency: state.filters.frequency,
        search: state.filters.search,
      }),
  });
  const okrObjectivesData =
    (okrObjectives && okrObjectives.data && okrObjectives.data.data);
    
  const searchRecord = useDebounce(search, 1000);

  useEffect(() => {
    if (okrObjectives.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: okrObjectives.data.meta.page,
          totalPages: okrObjectives.data.meta.totalPages,
          totalRecords: okrObjectives.data.meta.totalRecords,
        },
      }));
    }
  }, [okrObjectives.data?.meta]);

  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    let filterCount = 0;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;

    const type: { key: string; value: string }[] = searchParams.get("type")
      ? JSON.parse(String(searchParams.get("type")))
      : [];
    const frequency: { key: string; value: string }[] = searchParams.get(
      "frequency"
    )
      ? JSON.parse(String(searchParams.get("frequency")))
      : [];

    const _department: { key: string; value: string }[] = searchParams.get(
      "_department"
    )
      ? JSON.parse(String(searchParams.get("_department")))
      : [];

    const status: { key: string; value: string }[] = searchParams.get("status")
      ? JSON.parse(String(searchParams.get("status")))
      : [];

    const dateFilter: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];

    filterCount += type.length ? 1 : 0;
    filterCount += frequency.length ? 1 : 0;
    filterCount += _department.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += dateFilter.length ? 1 : 0;

    let date:
      | {
          startDate: string;
          endDate: string;
        }
      | undefined = undefined;
    if (dateFilter?.length) {
      date = {
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        search,
        type: type.map((item) => item.key),
        frequency: frequency.map((item) => item.key),
        _department: _department.map((item) => item.key),
        status: status.map((item) => item.key),
        date,
      },
      filterCount,
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const columns = [
    {
      id: "name",
      label: "Objective",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "_department",
      label: "Department",
    },
    {
      id: "createdBy",
      label: "Created By",
    },
    {
      id: "type",
      label: "Type",
    },
    {
      id: "frequency",
      label: "Frequency",
    },
    {
      id: "startDate",
      label: "Start Date",
    },
    {
      id: "endDate",
      label: "End Date/ETC",
    },
    {
      id: "progress",
      label: "Progress",
    },
    {
      id: "completionDate",
      label: "Completion Date",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "rating",
      label: "Rating",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const createRow = (index: number, objective: IOKRObjectiveData) => {
    const name = (
      <CustomTypography
        limit={50}
        label={objective?.type === "one-time" ? capitalize(objective.name) : capitalize(getRecurringObjectiveNames(objective?.name, objective?.frequency, objective?.startDate, objective?.endDate))}
        color="primary"
        onClick={
          resourceAllocate("okr-key-results.read")
            ? () => {
                navigate({
                  pathname: `key-results/${objective._id}`,
                  search: searchParams.toString(),
                });
              }
            : undefined
        }
      />
    );

    const status = <StatusChip status={objective.status} />;
    const progress = <ProgressBar progress={Number((objective.progress).toFixed(3))} />;
    
    const description = (
      <Tooltip title={objective?.description ?? ""} placement="bottom-start">
        <Typography fontSize={14}>
          {objective?.description && objective?.description?.length > 60
            ? `${objective?.description.slice(0, 60)}...`
            : objective?.description ?? "--"}
        </Typography>
      </Tooltip>
    );

    const actions = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + objective._id,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("okr-objectives.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(objective._id),
            disabled: !resourceAllocate("okr-objectives.remove"),
          },
        ]}
      />
    );

    return {
      name,
      _department:
        objective._department && objective?._department.name
          ? capitalize(objective._department.name)
          : "",
      createdBy:
        objective.createdBy && objective.createdBy.name
          ? capitalize(objective.createdBy.name)
          : "",
      progress,
      frequency: capitalize(objective?.frequency || "-"),
      type: capitalize(objective?.type || ""),
      startDate: formatDate(objective?.startDate),
      endDate: formatDate(objective?.endDate),
      completionDate: formatDate(objective?.completionDate),
      status,
      description,
      rating: objective.rating,
      actions,
    };
  };

  if (okrObjectivesData &&okrObjectivesData.length) {
    rows = okrObjectivesData.map((obj, i) => createRow(i, obj));
  }

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (okrObjectivesData?.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page:
            prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [okrObjectivesData]);
  const onDelete = async () => {
    try {
      const deleted = await deleteObjective({ _id: state._objective });
      snackbar(deleted.message, "info");
      handleDelete();
      okrObjectives.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };
  const handleDelete = (_objective = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _objective,
    }));
  };

  return (
    <div>
      <Header
        className="my-2"
        searchPlaceholder="Search by objective name"
        onSearch={onSearch}
        btnText="Add Objective"
        onBtnClick={
          resourceAllocate("okr-objectives.write")
            ? () => navigate({
              pathname: "new",
              search: searchParams.toString(),
            })
            : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <OkrObjectiveFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 200px)"
          errorMessage="Add objectives to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Objective"
        description="Are you sure you want to delete this objective (All the key results and related data will also be deleted)?"
      />

      <Outlet
        context={{
          ...outlet,
          refetchObjectives: okrObjectives.refetch,
        }}
      />
    </div>
  );
};

export default OKRObjectives;
