import { IContentCategoriesResponse, IContentCategoryResponse } from "../../interfaces/category-content";
import { categoryContent } from "../endpoints";
import HttpService from "../http";


const ContentCategoryService = () => {
    const { httpRequest } = HttpService();

    const getContentCategories = async (search: object): Promise<IContentCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<IContentCategoriesResponse>(
            "GET",
            `${categoryContent}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getContentCategory = async (search: object): Promise<IContentCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<IContentCategoryResponse>(
            "GET",
            `${categoryContent}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteContentCategory = async (payload: object): Promise<IContentCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<IContentCategoriesResponse>(
            "DELETE",
            `${categoryContent}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const addContentCategory = async (payload: object): Promise<IContentCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<IContentCategoriesResponse>(
            "POST",
            `${categoryContent}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateContentCategory = async (payload: object): Promise<IContentCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<IContentCategoriesResponse>(
            "PUT",
            `${categoryContent}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateContentCategoryStatus = async (payload: object): Promise<IContentCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<IContentCategoriesResponse>(
            "PUT", 
            `${categoryContent}/status`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getContentCategory, deleteContentCategory, addContentCategory, updateContentCategory, getContentCategories, updateContentCategoryStatus };
};

export { ContentCategoryService };