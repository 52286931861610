import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { capitalize } from "../../../../utilities/helper";
import { useQuery } from "@tanstack/react-query";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";
import useResource from "../../../../hooks/useResource";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
    deletedCategoryId: string;
}
export interface ISkillFilter {
    selectedMenu: number;
    _skillCategory: {
        key: string,
        value: string
    }[];
}

const SkillCategoryFilters: FC<props> = ({ anchorEl, isOpen, OnClose, deletedCategoryId }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const { resourceAllocate } = useResource();
    const { getPartialSkillItemCategory } = SkillItemCategoryService();
    const skillCategoriesData = useQuery({
        queryKey: ["skill-categories-data-list", deletedCategoryId],
        queryFn: () => getPartialSkillItemCategory({
          _skillParameter: id,
        })
    });
    const skillData = skillCategoriesData && skillCategoriesData.data && skillCategoriesData.data.data || [];
   

    const categoriesData = skillData.map(item => ({
        _id: item._id,
        name: item.name
      }));

    const [state, setState] = useState<ISkillFilter>({ 
        selectedMenu: 0,
        _skillCategory: [],
    });

    useEffect(() => {
        resetFilter(); 
    }, []);


    useEffect(() => {
        const _skillCategory: { key: string, value: string }[] = searchParams.get("_skillCategory") ? JSON.parse(String(searchParams.get("_skillCategory"))) : [];

        setState(prevState => ({
            ...prevState,
            _skillCategory,
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "_skillCategory", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name:  "_skillCategory", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const [shouldUpdateParams, setShouldUpdateParams] = useState(false);

    useEffect(() => {
        if(deletedCategoryId) {
            if(state._skillCategory.filter(ele => ele.key === deletedCategoryId).length > 0){
                deleteChip("_skillCategory", deletedCategoryId);
                setState(prevState => ({
                    ...prevState,
                    _skillCategory: state._skillCategory.filter(ele => ele.key !== deletedCategoryId)
                }));
                setShouldUpdateParams(true);
            }
        }
    }, [deletedCategoryId]);

    useEffect(() => {
        searchParams.set("_skillCategory", JSON.stringify(state._skillCategory));
        setSearchParams(searchParams);
    }, [state._skillCategory && shouldUpdateParams]);

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            _skillCategory: []
        });
    };

    const onApply = () => {
        searchParams.set("_skillCategory", JSON.stringify(state._skillCategory));

        searchParams.set("page", "1");
        navigate({
            pathname: `/skill-matrix/manage/${id}`,     
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const _skillCategory: { key: string, value: string }[] = searchParams.get("_skillCategory") ? JSON.parse(String(searchParams.get("_skillCategory"))) : [];
       
        setState(prevState => ({
            ...prevState,
            _skillCategory,
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state._skillCategory.length) ?
                                <>

                                    {state._skillCategory.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_skillCategory", ele.key)} label={ele.value} variant="outlined" />)}
                  
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton 
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {
                    resourceAllocate("skill-matrix-category.read") ? 
                    <>
                        <Grid className="filter-box" container>
                            <Grid id="left" item xs={5}>
                                <List component="nav">
                                    {
                                        resourceAllocate("skill-matrix-category.read") && 
                                        <MenuItem
                                            index={0}
                                            label="Category"
                                            selectedMenu={state.selectedMenu === 0}
                                            onChange={handleLeftListItem}
                                            count={state._skillCategory}
                                        />
                                    }

                                </List>
                            </Grid>
                            <Divider orientation="vertical" />

                            <Grid id="right" item xs={6}>
                                <List component="nav">
                                    {
                                        state.selectedMenu === 0 &&
                                        categoriesData && categoriesData.map(category => <ListItemButton
                                            key={category?._id}
                                            selected={!!state._skillCategory.find(ele => ele.key === category?._id)}
                                            onClick={() => handleRightListItem("_skillCategory", category?._id, category.name)}
                                        >
                                            <ListItemText primary={capitalize(category.name)} />
                                            <Checkbox edge="end" checked={!!state._skillCategory.find(ele => ele.key === category?._id)} />
                                        </ListItemButton>)
                                    }
                                </List>

                            </Grid>
                        </Grid>

                        <Box className="actions-btn" marginTop="8px" textAlign="end">
                            <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                            <Button className="ml-2" onClick={onApply}>Apply</Button>
                        </Box>
                    </> : 
                    <Box display="flex" alignItems="center" justifyContent="center" height="80%">
                        No filters to apply.
                    </Box>
                }

            </Box>
        </Menu>
    </>;
};

export default SkillCategoryFilters;