import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, TextField } from "@mui/material";
import { IErrorResponse, ITeam } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
import SearchSelect from "../../../../components/mui/search-select";
import CustomLabel from "../../../../components/mui/custom-label";
import { OkrObjectivesService } from "../../../../services/okr";
import { IOKRObjective, IOKRObjectiveFields } from "../../../../interfaces/okr";
import { okrObjectiveValidation } from "../../../../validations/okr";
import { COMMON_ERROR_MESSAGES } from "../../../../utilities/messages/common";
interface outletProps {
  reFetch: () => void;
  refetchObjectives: () => void;
}

const ManageObjective = () => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const [searchParam] = useSearchParams();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { addObjective, getObjective, updateObjective } =
    OkrObjectivesService();
  const objective = useQuery({
    queryKey: [id],
    queryFn: () => getObjective({ _id: id }),
    enabled: id !== "new",
  });
  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
    (state) => state.team.list
  );
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useForm<IOKRObjective>({
    resolver: joiResolver(okrObjectiveValidation),
    defaultValues: {
      name: "",
      description: "",
      _department: "",
      startDate: "",
      endDate: "",
      type: "",
      frequency: "",
    },
  });

  useEffect(() => {
    if (id !== "new" && objective.data?.data) {
      const data = objective.data.data;
      setValue("name", capitalize(data.name));
      setValue("startDate", data.startDate);
      setValue("endDate", data.endDate);
      setValue("description", data.description);
      setValue("type", data.type);
      setValue("frequency", data.frequency);
      setValue("_department", data._department);
    }
  }, [id, objective.data]);

  const onClose = () => {
    navigate({
      pathname: "/okr-objectives",
      search: searchParam.toString(),
    });
  };

  const onSubmit = async (data: IOKRObjective) => {
    if (new Date(data.endDate) < new Date(data.startDate)) {
      snackbar(COMMON_ERROR_MESSAGES.en.invalid_start_end_dates, "warning");
      return;
    }
    try {
      if (id === "new") {
        const payload = { ...data };
        if(payload.type === "one-time") {
            delete payload.frequency;
        }
        const add = await addObjective(payload);
        snackbar(add.message, "info");
      } else {
        const payload = { ...data, _id: id };
        if(payload.type === "one-time") {
          delete payload.frequency;
        }
        const update = await updateObjective(payload);
        snackbar(update.message, "info");
      }
      outlet?.reFetch && outlet.reFetch();
      outlet?.refetchObjectives && outlet.refetchObjectives();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const fields: IOKRObjectiveFields[] = [
    {
      label: "Name",
      name: "name",
      type: "input",
      placeholder: "Type objective name here",
      required: true,
    },
    {
      label: "Description",
      name: "description",
      type: "input",
      placeholder: "Type objective description here",
      required: true,
    },
    {
      label: "Department",
      name: "_department",
      type: "select",
      required: true,
      options: teams.map((i) => ({
        key: i._id,
        value: capitalize(i.name),
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
      disabled: id !== "new",
    },
    {
      label: "Type",
      name: "type",
      type: "select",
      required: true,
      options: [
        { key: "one-time", value: "One Time" },
        { key: "recurring", value: "Recurring" },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      disabled: id !== "new",
    },
    {
      label: "Frequency",
      name: "frequency",
      type: "select",
      required: true,
      options: [
        { key: "daily", value: "Daily" },
        { key: "weekly", value: "Weekly" },
        { key: "monthly", value: "Monthly" },
        { key: "quarterly", value: "Quarterly" },
        { key: "yearly", value: "Yearly" },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      disabled: id !== "new",
    },
    {
      label: "Start Date",
      name: "startDate",
      type: "date",
      required: true,
      disabled: id !== "new",
    },
    {
      label: watch("type") === "one-time" ? "ETC" : "End Date",
      name: "endDate",
      type: "date",
      required: true,
      disabled: watch("type") === "recurring" || id !== "new",
    },
  ];

  const filteredFields = fields.filter((field) => {
    if (watch("type") === "one-time") {
      return !["frequency"].includes(field.name);
    } else {
      return true;
    }
  });

  useEffect(() => {
    if (
      watch("type") === "recurring" &&
      watch("startDate") &&
      watch("frequency")
    ) {
      let endDate = dayjs(watch("startDate"));
      switch (watch("frequency")) {
        case "daily":
          endDate = endDate.endOf("day");
          break;
        case "weekly":
          endDate = endDate.add(6, "day").endOf("day");
          break;
        case "monthly":
          endDate = endDate.endOf("month");
          break;
        case "quarterly":
          endDate = endDate.add(3, "month").subtract(1, "day").endOf("day");
          break;
        case "yearly":
          endDate = endDate.add(1, "year").subtract(1, "day").endOf("day");
          break;
        default:
          break;
      }
      setValue("endDate", endDate.toISOString());
    }
  }, [watch("startDate"), watch("frequency"), watch("type")]);

  const selectDate = (
    value: string | number | Date | dayjs.Dayjs | null | undefined,
    name: string
  ) => {
    const date =
      value && dayjs(value).toString() !== "Invalid Date"
        ? (name === "startDate" ? dayjs(value)?.startOf("day")?.toISOString() : dayjs(value)?.endOf("day")?.toISOString())
        : undefined;
    const keyExist = name === "startDate" || name === "endDate";
    if (keyExist && date) {
      setValue(name, date);
      trigger(name);
    }
  };

  return (
    <CustomDialog
      size="md"
      title={id !== "new" ? "Edit Objective" : "Add Objective"}
      confirmText={id !== "new" ? "Edit Objective" : "Add Objective"}
      isOpen={!!id}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={4}>
        {filteredFields.map((field) => {
          if (field.type === "input") {
            return (
              <Grid key={field.label} item xs={6}>
                <Controller
                  control={control}
                  name={field.name}
                  render={(prop) => (
                    <TextField
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field.required}
                        />
                      }
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      multiline={field.name === "description"}
                      minRows={field.name === "description" ? 3 : 1}
                      maxRows={field.name === "description" ? 3 : 1}
                      {...prop.field}
                    />
                  )}
                />
              </Grid>
            );
          } else if (field.type === "date") {
            return (
              <Grid key={field.label} item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label={
                      <CustomLabel
                        label={field.label}
                        required={field?.required}
                      />
                    }
                    shouldDisableDate={(date) => {
                      const frequency = getValues("frequency") || "none";
                      const currentDate = dayjs(date);
                      if (frequency === "weekly") {
                        return currentDate.day() !== 1;
                      }else if (frequency === "monthly") {
                        return currentDate.date() !== 1;
                      }else if (frequency === "quarterly") {
                        const month = currentDate.month();
                        const quarterFirstMonths = [0, 3, 6, 9];
                        return !(quarterFirstMonths.includes(month) && currentDate.date() === 1);
                      }else if (frequency === "yearly") {
                        return !(currentDate.month() === 0 && currentDate.date() === 1);
                      }else{
                        return false;
                      }
                    }}
                    onChange={(e) => selectDate(e, field.name)}
                    value={dayjs(getValues(field.name))}
                    disabled={field?.disabled}
                    slotProps={{
                      textField: {
                        error: !!errors[field.name],
                        helperText: errors[field.name]?.message,
                      },
                    }}
                    format="LL"
                  />
                </LocalizationProvider>
              </Grid>
            );
          } else {
            return (
              <Grid key={field.label} item xs={6}>
                <SearchSelect
                  name={field.name}
                  label={
                    <CustomLabel
                      label={field.label}
                      required={field.required}
                    />
                  }
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  options={field.options}
                  displayFieldKey={
                    field.displayFieldKey ? field.displayFieldKey : ""
                  }
                  storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                  displayUserName={field.displayUserName}
                  capitalize={field.capitalize}
                  trigger={trigger}
                  setValue={setValue}
                  getValues={getValues}
                  disabled={field?.disabled}
                  onChange={
                    field.name === "frequency"
                      ? () => {setValue("startDate", ""); setValue("endDate", "");} 
                      : undefined
                  }
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </CustomDialog>
  );
};

export default ManageObjective;
